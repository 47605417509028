import { sharedConstants, TransferInfo, TransferMedicationDetail, TransferMedicationResponse, TransferParams } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface TransferService {
  createTransferRequest(transferDetailsData: TransferParams, customerToken: string, patientToken: string): Promise<string>
  getTransferRequestByToken(transferRequestToken: string, customerToken: string, patientToken: string): Promise<TransferMedicationDetail[]>
  fetchTransferMedicationsHistory(vpharmCustomerToken: string, truepillPatientToken: string): Promise<TransferMedicationResponse>
  fetchTransfers(vpharmCustomerToken: string, patientToken: string): Promise<TransferInfo[]>
}

class TransferApiServer implements TransferService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/transfer`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async createTransferRequest(transferDetailsData: TransferParams, customerToken: string, patientToken: string): Promise<string> {
    if (!customerToken || !patientToken) {
      throw new Error('Missing required tokens')
    }

    const url = `${this.baseUrl}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    const createTransferResponse = await axiosAuth.post(url, transferDetailsData, config)

    return createTransferResponse.data.transfer_batch_id
  }

  async getTransferRequestByToken(transferRequestToken: string, customerToken: string, patientToken: string): Promise<TransferMedicationDetail[]> {
    if (!customerToken || !patientToken) {
      throw new Error('Missing required tokens')
    }

    const url = `${this.baseUrl}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    const getTransferResponse = await axiosAuth.get(`${url}/${transferRequestToken}`, config)

    return getTransferResponse.data
  }

  async fetchTransferMedicationsHistory(vpharmCustomerToken: string, truepillPatientToken: string): Promise<TransferMedicationResponse> {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: truepillPatientToken,
      },
    }

    const url = `${this.baseUrl}/medication/history`
    const response = await axiosAuth.get<TransferMedicationResponse>(url, config)
    return response.data
  }

  async fetchTransfers(vpharmCustomerToken: string, patientToken: string): Promise<TransferInfo[]> {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    const url = `${this.baseUrl}`
    const response = await axiosAuth.get<TransferInfo[]>(url, config)
    return response.data
  }
}

export const transferService: TransferService = new TransferApiServer()
