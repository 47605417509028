import { TransferMedicationDetail } from '@vpharm-platform/shared'
import { useCallback, useEffect, useState } from 'react'

import { transferService } from '../services/transferService'
import { useShowError } from './useShowError'

interface ReturnValues {
  loading: boolean
  transferDetails?: TransferMedicationDetail[]
}

export const usePatientTransfer = (transferToken: string, customerToken: string, patientToken: string): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showError } = useShowError()
  const [transferDetails, setTransferDetails] = useState<TransferMedicationDetail[]>()

  const getTransferRequestList = useCallback(async () => {
    try {
      setLoading(true)
      const transferRequestList = await transferService.getTransferRequestByToken(transferToken, customerToken, patientToken)

      setTransferDetails(transferRequestList)
    } catch (e) {
      showError()
    } finally {
      setLoading(false)
    }
  }, [customerToken, patientToken, showError, transferToken])

  useEffect(() => {
    getTransferRequestList()
  }, [getTransferRequestList])

  return {
    transferDetails,
    loading,
  }
}
