import { SurveyResult } from '@vpharm-platform/shared'
import { CheckoutFlowData } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { GetHealthHistoryDto, HealthHistoryOptions, surveyService } from 'services'

import { LACTATION_ANSWER, PregnancyStatus, PROCREATIVE_MANAGEMENT_ANSWER, SEPARATE_URAC_SURVEY_PREGNANCY_FIELD } from '../constants'
import { useLDFlagsWithLocalStorage } from './useLDFlagsWithLocalStorage'

const mapPregnancyResponseToHealthHistoryOptions = (survey: string, separateUracSurveyPregnancyFieldFF: boolean): HealthHistoryOptions => {
  switch (survey) {
    case 'YES':
      return HealthHistoryOptions.Yes
    case 'NO':
      return HealthHistoryOptions.No
    case 'UNSURE':
      return separateUracSurveyPregnancyFieldFF ? HealthHistoryOptions.No : HealthHistoryOptions.NotSure
    default:
      return separateUracSurveyPregnancyFieldFF ? HealthHistoryOptions.No : HealthHistoryOptions.NotSure
  }
}

const parseSurvey = (data: SurveyResult, separateUracSurveyPregnancyFieldFF: boolean): GetHealthHistoryDto | null => {
  if (Object.keys(data).length === 0) {
    return null
  }
  const { allergies, medications, health_conditions, pregnancy, created_at } = data

  const specialConditions = [LACTATION_ANSWER, PROCREATIVE_MANAGEMENT_ANSWER]
  const healthConditions =
    health_conditions.length > 0 ? health_conditions.split(',').filter((condition) => !specialConditions.includes(condition)) : []
  const hasAllergies = allergies.length > 0
  const hasMedications = medications.length > 0
  const hasHealthConditions = healthConditions.length > 0
  const hasLactation = health_conditions.split(',').includes(LACTATION_ANSWER)
  const hasProcreativeManagement = health_conditions.split(',').includes(PROCREATIVE_MANAGEMENT_ANSWER)
  const pregnant = mapPregnancyResponseToHealthHistoryOptions(pregnancy, separateUracSurveyPregnancyFieldFF)

  const base = {
    allergies: hasAllergies ? HealthHistoryOptions.Yes : HealthHistoryOptions.No,
    allergiesDetails: hasAllergies ? allergies.split(',') : [],
    healthConditions: hasHealthConditions ? HealthHistoryOptions.Yes : HealthHistoryOptions.No,
    medicationsAndSupplements: hasMedications ? HealthHistoryOptions.Yes : HealthHistoryOptions.No,
    medicationsAndSupplementsDetails: hasMedications ? medications.split(',') : [],
    createdAt: created_at,
  }

  const pregnancyStatus = []
  if (pregnant === HealthHistoryOptions.Yes) {
    pregnancyStatus.push(PregnancyStatus.Pregnant)
    if (hasLactation) {
      pregnancyStatus.push(PregnancyStatus.Lactating)
    }
  } else if (hasLactation) {
    pregnancyStatus.push(PregnancyStatus.Lactating)
    if (hasProcreativeManagement) {
      pregnancyStatus.push(PregnancyStatus.Planning)
    }
  } else if (hasProcreativeManagement) {
    pregnancyStatus.push(PregnancyStatus.Planning)
  } else {
    pregnancyStatus.push(PregnancyStatus.None)
  }

  if (separateUracSurveyPregnancyFieldFF) {
    return {
      ...base,
      healthConditionsDetails: hasHealthConditions ? healthConditions : [],
      pregnantOrLactating: pregnant,
      pregnancyStatus,
      pregnant,
      lactating: hasLactation ? HealthHistoryOptions.Yes : pregnant === HealthHistoryOptions.No ? HealthHistoryOptions.No : undefined,
      procreativeManagement: hasProcreativeManagement ? HealthHistoryOptions.Yes : hasLactation ? undefined : HealthHistoryOptions.No,
    }
  } else {
    return {
      ...base,
      healthConditionsDetails: hasHealthConditions ? health_conditions.split(',') : [],
      pregnantOrLactating: mapPregnancyResponseToHealthHistoryOptions(pregnancy, separateUracSurveyPregnancyFieldFF),
    }
  }
}

interface UseHealthSurvey {
  isLoading: boolean
  healthSurvey: CheckoutFlowData
  error: string
  setHealthSurvey: React.Dispatch<React.SetStateAction<CheckoutFlowData>>
}

export const useHealthSurvey = (vpharmCustomerToken: string, patientToken: string): UseHealthSurvey => {
  const [isLoading, setIsLoading] = useState(false)
  const [healthSurvey, setHealthSurvey] = useState<CheckoutFlowData>({ patientHealthSurvey: null })
  const [error, setError] = useState('')

  const { [SEPARATE_URAC_SURVEY_PREGNANCY_FIELD]: separateUracSurveyPregnancyFieldFF } = useLDFlagsWithLocalStorage([
    SEPARATE_URAC_SURVEY_PREGNANCY_FIELD,
  ])

  useEffect(() => {
    const fetchPatientHealthSurvey = async (): Promise<void> => {
      try {
        setIsLoading(true)
        const patientHealthSurvey = await surveyService.getPatientSurvey(vpharmCustomerToken, patientToken)

        if (!patientHealthSurvey) {
          setHealthSurvey({ patientHealthSurvey: null })
          return
        }

        setHealthSurvey({ patientHealthSurvey: parseSurvey(patientHealthSurvey, separateUracSurveyPregnancyFieldFF) })
      } catch (e) {
        setError('Unable to fetch patient health survey')
      } finally {
        setIsLoading(false)
      }
    }
    fetchPatientHealthSurvey()
  }, [vpharmCustomerToken, patientToken, separateUracSurveyPregnancyFieldFF])

  return {
    healthSurvey,
    isLoading,
    error,
    setHealthSurvey,
  }
}
