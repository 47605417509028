import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'

const StyledLinkWrapper = styled.li<ThemedComponent>`
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ vpTheme }) => vpTheme.colors['primary-100']};
  }
`

const StyledButton = styled(ThemedButton)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1.75rem;
  color: inherit;
  border: none;
  background: none;
  font-weight: normal;
  width: 100%;

  &&:hover {
    background: none;
  }
`

const StyledSpan = styled.span<ThemedComponent>`
  margin-left: 1.25rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
  font-family: Lato;
  line-height: 1.5rem;
`

export { StyledButton, StyledLinkWrapper, StyledSpan }
