import styled from 'styled-components/macro'

type CardContainerProps = {
  theme: {
    padding: string
  }
}

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props: CardContainerProps) => props.theme.padding ?? '1rem'};

  border: 1px solid #e9ecf0;
  box-sizing: border-box;
  border-radius: 8px;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 16px 0px;
`

export const AccordianButtonContainer = styled.div`
  padding-bottom: 16px;
`

export const TextWithArrowContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowIconContainer = styled.span`
  margin-left: 16px;
`
