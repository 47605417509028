import { AvailableShippingOptionsRequest, AvailableShippingOptionsResponse, sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

export interface ShippingOptionService {
  getAvailableShippingOptions(
    accessToken: string,
    customerToken: string,
    patientToken: string,
    body: AvailableShippingOptionsRequest,
  ): Promise<AvailableShippingOptionsResponse[]>
}

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants
class ShippingOptionApiService implements ShippingOptionService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async getAvailableShippingOptions(
    accessToken: string,
    customerToken: string,
    patientToken: string,
    body: AvailableShippingOptionsRequest,
  ): Promise<AvailableShippingOptionsResponse[]> {
    try {
      const url = `${this.baseUrl}/shipping_options/available`
      const config: AxiosRequestConfig = {
        headers: {
          ...this.defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          [VPHARM_CUSTOMER_HEADER]: customerToken,
          [VPHARM_PATIENT_HEADER]: patientToken,
        },
      }

      const result = await axiosAuth.post<AvailableShippingOptionsResponse[]>(url, body, config)

      return result.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
}

export const shippingOptionService: ShippingOptionService = new ShippingOptionApiService()
