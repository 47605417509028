import * as SmartySDK from 'smartystreets-javascript-sdk'
import utils from 'smartystreets-javascript-sdk-utils'

const SmartyCore = SmartySDK.core
const Lookup = SmartySDK.usStreet.Lookup
const credentials = new SmartyCore.SharedCredentials(process.env.REACT_APP_SMARTY_FE_KEY ?? '')
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-core-cloud'])
const client = clientBuilder.buildUsStreetApiClient()

export const addressVerification = async (address: string): Promise<boolean> => {
  const lookup = new Lookup(address)
  try {
    const result = await client.send(lookup)
    return utils.isValid(result.lookups[0])
  } catch (e: unknown) {
    const err = e as Error
    console.error(`Address Verification Service Error: ${err.message}`)
    return false
  }
}
