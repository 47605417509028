import { sharedConstants } from '@vpharm-platform/shared'
import axios, { AxiosRequestConfig } from 'axios'

// adds access tokens in authorized api requests
export const axiosAuth = axios.create()

const { VPHARM_USER_HEADER } = sharedConstants

// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently: () => Promise<string>): void => {
  axiosAuth.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently()
    const recoil = JSON.parse(sessionStorage.getItem('recoil-persist') || 'false')
    const isAdmin = recoil && recoil.adminLogin
    const impersonatedUser = recoil && recoil?.impersonatedUserToken

    if (config && config.headers) {
      if (isAdmin && impersonatedUser) {
        config.headers[VPHARM_USER_HEADER] = impersonatedUser
      }
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  axiosAuth.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config as AxiosRequestConfig
      if (error.response.status === 401 && !originalRequest.headers?._retry) {
        originalRequest.headers ? (originalRequest.headers._retry = true) : (originalRequest.headers = { _retry: true })
        const access_token = await getAccessTokenSilently()
        axios.defaults.headers.common.Authorization = 'Bearer ' + access_token
        return axiosAuth(originalRequest)
      }
      return Promise.reject(error)
    },
  )
}
