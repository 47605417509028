import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '../../context/auth-context'
import { useAnalytics, VpAnalyticsEventType } from '../../hooks/analytics-context'
import { SignUpFields } from './types'

export interface UseSignUp {
  signUp: (signUpFields: SignUpFields) => Promise<void>
  handleConsentChange: (consent: boolean) => void
  hasConsented: boolean
  registrationToken: string | null
  isAuthenticated: boolean
  signUpErrorMsg: string
  handleErrorMsgChange: (msg: string) => void
}

export const useSignUp = (): UseSignUp => {
  const {
    authState: { isAuthenticated },
    signup,
  } = useAuth()
  const [hasConsented, setHasConsented] = useState(false)
  const [signUpErrorMsg, setSignUpErrorMsg] = useState('')
  const location = useLocation()
  const registrationToken = new URLSearchParams(location.search).get('registration_token')
  const { trackButtonClickEvent, trackErrorShownEvent, trackCheckboxToggleClickEvent, trackEvent } = useAnalytics()

  const signUp = async (values: SignUpFields): Promise<void> => {
    trackButtonClickEvent('sign up', 'submit', 'create patient account')
    if (!registrationToken) {
      setSignUpErrorMsg('Something went wrong please try again later.')
      trackErrorShownEvent('Unable to sign up', 'create patient account')
      return
    }

    try {
      await signup(values.email, values.password, registrationToken)
      trackEvent(VpAnalyticsEventType.UserSignUp)
    } catch (e) {
      if (e instanceof Error) {
        setSignUpErrorMsg(e.message)
        trackErrorShownEvent('Unable to sign up', 'create patient account')
      }
    }
  }

  const handleConsentChange = (consent: boolean) => {
    trackCheckboxToggleClickEvent('sign-up-consent', consent)
    setHasConsented(consent)
  }

  const handleErrorMsgChange = (error: string) => {
    setSignUpErrorMsg(error)
    trackErrorShownEvent('Unable to sign up', 'create patient account')
  }

  return {
    signUp,
    hasConsented,
    handleConsentChange,
    registrationToken,
    isAuthenticated,
    signUpErrorMsg,
    handleErrorMsgChange,
  }
}
