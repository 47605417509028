import { defaultTheme } from '@vpharm-platform/shared'
import { media } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'

const IdDocumentContainer = styled.div<ThemedComponent>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-500'] ?? defaultTheme.colors['gray-500']};
  box-sizing: border-box;
  border-radius: 8px;
  height: 30vh;
  max-height: 30vh;
  & > * {
    max-width: 100%;
    max-height: 100%;
    padding: 10px;
  }
  overflow: hidden;
  @media (max-width: ${media.tablet}) {
    height: 40vh;
    max-height: 40vh;
    border: none;
  }
`
const PhotoButton = styled(ThemedButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover svg path {
    stroke: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  }
`
const PhotoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${media.tablet}) {
    flex-direction: column;
    gap: 4px;
  }
`

const UploadedImage = styled.img`
  max-height: 30vh;
`

export { IdDocumentContainer, PhotoButton, PhotoButtonContainer, UploadedImage }
