import { Card, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

import { media } from '../../../../common/styles/variables'

const StyledHeaderCard = styled(Card)`
  width: 100%;
  line-height: 2.25rem;
  font-size: 0.85rem;
  background-color: ${theme.colors['gray-100'].computedValue};
  padding: 0 2.2rem;

  @media (max-width: ${media.tablet}) {
    padding: 0 1rem;
  }
`

const StyledBodyCard = styled(Card)`
  width: 100%;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  @media (max-width: ${media.tablet}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSpan = styled.span`
  margin-left: 1rem;
  font-family: Lato;
  font-size: 1rem;
  line-height: 1.5rem;
`
const PharmacyInfo = styled.div`
  display: flex;
`
const TransferDetailsLabel = styled.div`
  font-weight: 700;
  line-height: 1.5rem;
  text-align: left;
  color: ${theme.colors['typography-medium'].computedValue};
`

const TransferDetailsValue = styled.div`
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
`

const TransferItemGrid = styled.div<{ displayAllColumns: boolean }>`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: ${(props) => {
    if (props.displayAllColumns) return 'auto auto auto auto'
    return '1fr 1fr'
  }};
  gap: 1rem;
  justify-content: space-between;

  @media (max-width: ${media.mobile}) {
    grid-template-columns: auto auto;
  }
`

const TransferItem = styled.div``

export const StyledTransferDetailsCard = {
  TransferItem,
  TransferItemGrid,
  TransferDetailsValue,
  TransferDetailsLabel,
  PharmacyInfo,
  StyledSpan,
  StyledBodyCard,
  StyledHeaderCard,
}
