import { PrescriptionDisplayStatus } from '../../enums/prescriptions/PrescriptionStatus'
import InsuranceInfo from '../insurances/insurancesInfo'
import { EversanaSavingsCardConfig, ManualEntrySavingsCardConfig } from '../savingCards/savingsCardConfig'

export interface AppliedSavingsCardInfo {
  isSavingsCardEditable: boolean
}

export interface MedicationInfoSavingsCard {
  appliedSavingsCard: AppliedSavingsCardInfo | null
  potentialPriceWithSavingsCard: number | null
  savingsCardConfig: EversanaSavingsCardConfig | ManualEntrySavingsCardConfig | null
}

export interface MedicationInventory {
  availableDate: string | null
}

export enum PrescriptionCopayStatusType {
  SUCCESS,
  REJECTED,
  TRIAGED,
  PENDING,
}

export interface RejectedPrescriptionCopay {
  additional_message_information: string | null
  error_code: string | null
  message: string | null
  type: PrescriptionCopayStatusType.REJECTED
  error_message: string | null
}

export interface TriagedPrescriptionCopay {
  description: string | null
  message: string | null
  error_code: string | null
  type: PrescriptionCopayStatusType.TRIAGED
}

export interface SuccessPrescriptionCopay {
  amount: number
  type: PrescriptionCopayStatusType.SUCCESS
}

export type PrescriptionCopay = RejectedPrescriptionCopay | TriagedPrescriptionCopay | SuccessPrescriptionCopay

export interface MedicationInfo {
  prescriptionStatus: PrescriptionDisplayStatus
  prescriptionToken: string
  medicationForm: string | null
  medicationStrength: string
  medicationSig: string
  quantity?: number
  quantityUnits: string
  daysSupply: number | null
  rxNumber: string
  prescriptionExpirationDate: string
  prescriptionNdc: string | null
  fillable: boolean
  refillsRemaining: number
  nextRefillDate: string | null
  lastOrder: string | null
  prescribedBy: string
  prescribedDate: string | null
  isAutoRefillEligible: boolean
  isAutoRefillEnabled: boolean
  isControlledSubstance: boolean
  isDaw: boolean | null
  coldChain: boolean
  form: string | null
  brandDisplayName: string
  genericDisplayName: string | null
  unitPrice: number | null
  savingsCard: MedicationInfoSavingsCard
  retailPrice: number | null
  insurancePrice: number | null
  totalSavings: number | null
  totalPriceAfterSavings: number | null
  inventory: MedicationInventory | null
  copay: PrescriptionCopay | null
  replacedInsurances?: InsuranceInfo[]
  replacedSavingsCard?: InsuranceInfo[]
  enrolledRestockNotification: boolean
  writtenName: string
}
