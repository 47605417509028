import { PutSavingCardParams } from '@vpharm-platform/shared'
import React, { useState } from 'react'

import ToastMessage from '../../../../Components/ToastMessage'
import { ToastState } from '../../../../constants/toastConstants'
import { useSavingsCard } from '../../../../hooks/useSavingsCard'
import { PatientSavingsCardSubmitPayload } from '../../../../interfaces/PatientSavingsCard'
import SavingsCardModal from '../../../AccountManagement/SavingsCard/SavingsCardForm'

export interface ManualAddSavingsCardProps {
  handleManualSubmitSavingsCard: () => void
  handleCancel: () => void
  prescriptionDisplayName: string | null
  prescriptionToken: string
  prescriptionNdc: string | null
  refreshInsurance?: () => void
}

const ManualAddSavingsCard = ({
  handleManualSubmitSavingsCard,
  handleCancel,
  prescriptionDisplayName,
  prescriptionToken,
  prescriptionNdc,
  refreshInsurance,
}: ManualAddSavingsCardProps): React.ReactElement => {
  const [submitSavingCardLoading, setSubmitSavingCardLoading] = useState<boolean>(false)
  const [showSuccessToastMessage, setShowSuccessToastMessage] = useState(false)
  const [showFailureToastMessage, setShowFailureToastMessage] = useState(false)
  const [, setError] = useState('')

  const { uploadSavingCardImage, isUploadingSavingCardImage, submitSavingCard, toastMessage } = useSavingsCard()

  const handleSubmitSavingsCard = async (values: PatientSavingsCardSubmitPayload): Promise<void> => {
    try {
      setSubmitSavingCardLoading(true)
      const submitSavingsCardPayload: PutSavingCardParams = {
        ndc: prescriptionNdc || '',
        pcn: values.pcn || undefined,
        cardholder_id: values.cardholder_id,
        rx_bin: values.rx_bin,
        rx_group: values.rx_group || undefined,
        savings_card_image_key: values.savings_card_image_key,
      }
      await submitSavingCard(submitSavingsCardPayload, prescriptionToken)
      setShowSuccessToastMessage(true)
      refreshInsurance?.()
    } catch (e) {
      setShowFailureToastMessage(true)
    } finally {
      setSubmitSavingCardLoading(false)
      handleManualSubmitSavingsCard()
    }
  }

  return (
    <>
      {toastMessage && showSuccessToastMessage && (
        <ToastMessage
          timeout={3000}
          onDismiss={() => {
            setShowSuccessToastMessage(false)
          }}
          state={ToastState.SUCCESS}
          visible={true}
          onTimeout={() => {
            setShowSuccessToastMessage(false)
          }}
        >
          {toastMessage}
        </ToastMessage>
      )}
      {toastMessage && showFailureToastMessage && (
        <ToastMessage
          timeout={3000}
          onDismiss={() => {
            setShowFailureToastMessage(false)
          }}
          state={ToastState.ERROR}
          visible={true}
          onTimeout={() => {
            setShowFailureToastMessage(false)
          }}
        >
          {toastMessage}
        </ToastMessage>
      )}
      <SavingsCardModal
        handleSubmitForm={handleSubmitSavingsCard}
        uploadSavingCardImage={uploadSavingCardImage}
        isUploadingSavingCardImage={isUploadingSavingCardImage}
        savingCardModalHeaderName={prescriptionDisplayName || 'Your prescription'}
        handleCancelForm={handleCancel}
        isSubmittingForm={submitSavingCardLoading}
        prescriptionToken={prescriptionToken}
        setError={setError}
      />
    </>
  )
}

export default ManualAddSavingsCard
