import { DateTime, Info } from 'luxon'

import { GenderResponses } from './sections/types'

export type OptionType = { label: string; value: number }
export type GenderOptionType = { label: string; value: GenderResponses }

function generateDaysOptions() {
  const days = []
  for (let i = 1; i <= 31; i++) {
    days.push({
      label: String(i),
      value: i,
    })
  }
  return days
}

function generateYearOptions() {
  const years = []
  const currentYear = DateTime.now().year
  for (let i = 1900; i <= currentYear; i++) {
    years.push({
      label: String(i),
      value: i,
    })
  }

  return years.reverse()
}

export const monthOptions: OptionType[] = Info.months().map((value, index) => ({
  label: value,
  value: index + 1,
}))

export const daysOptions: OptionType[] = generateDaysOptions()

export const yearsOptions: OptionType[] = generateYearOptions()

export const genderOptions: GenderOptionType[] = [
  {
    label: 'Male',
    value: GenderResponses.MALE,
  },
  {
    label: 'Female',
    value: GenderResponses.FEMALE,
  },
]
