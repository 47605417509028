import { Header, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { Modal } from '../../common/styledComponents/StyledModal'
import { ThemedComponent } from '../../common/styledComponents/types'
import { breakpoint, mediaLargerThan } from '../../common/styles/variables'

const IconWrapper = styled.span`
  align-items: center;
  display: flex;
  margin: 0 0.25rem;
`

const StyledMobileTooltip = styled.div`
  display: none;

  ${breakpoint.tablet} {
    display: flex;
  }
`

const StyledMobileTooltipHeader = styled(Header)`
  && {
    max-width: 274px;
    font-family: lato;
    left: 1.5rem;
    line-height: 2rem;
    position: absolute;
    top: 1.5rem;
  }
`

const StyledMobileTooltipContent = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  margin-left: -0.5rem;
  padding-top: 2.5rem;
  a:visited {
    text-decoration: none;
    color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  }
`

const StyledToolTipLabel = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-700']};
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  text-decoration: none;
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
`

const StyledModal = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 1.7rem !important;
  }
  align-items: center;
  display: flex;
  [data-cap-modal-close-button='true'] {
    top: 1.25rem;
    right: 0.8rem;
  }
`

const StyledMobileTooltipDetails = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  margin-left: -0.5rem;
  padding-top: 1.5rem;
  a:visited {
    text-decoration: none;
    color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  }
`

const DetailsTextStyle = styled(Text)<{ fontWeight?: string; header?: string }>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: 14px;
  padding-top: 0;
  ${mediaLargerThan.mobile} {
    padding-top: ${(props) => (props.header ? '20px' : 0)};
  }
`

const StyledDesktopTooltip = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  whitespace: 'pre-line';

  ${breakpoint.tablet} {
    display: none;
  }
`

const TooltipContainer = styled.div``

const StyledTooltipContent = styled.div<ThemedComponent>`
  font-family: Lato;
  font-size: 0.875rem;
  line-height: 1.25rem;
  a:visited {
    text-decoration: none;
    color: ${({ vpTheme }) => vpTheme.colors.grayWhite ?? defaultTheme.colors.grayWhite};
  }
`

const ContentTextContainer = styled.div<{ paddingTop?: string }>`
  display: flex;
  gap: 0.25rem;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 0)};
`

export {
  ContentTextContainer,
  DetailsTextStyle,
  IconWrapper,
  StyledDesktopTooltip,
  StyledMobileTooltip,
  StyledMobileTooltipContent,
  StyledMobileTooltipDetails,
  StyledMobileTooltipHeader,
  StyledModal,
  StyledTooltipContent,
  StyledToolTipLabel,
  TooltipContainer,
}
