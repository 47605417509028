export const decideWhichIcon = (brand: string): string | undefined => {
  const normalizedBrand = brand.toLowerCase()
  switch (normalizedBrand) {
    case 'amex':
      return `${process.env.PUBLIC_URL}/amex_logo.svg`
    case 'discover':
      return `${process.env.PUBLIC_URL}/discover_logo.svg`
    case 'mastercard':
      return `${process.env.PUBLIC_URL}/mc_logo.svg`
    case 'visa':
      return `${process.env.PUBLIC_URL}/visa_logo.svg`
    case 'unionpay':
      return `${process.env.PUBLIC_URL}/unionpay_logo.svg`
    case 'jcb':
      return `${process.env.PUBLIC_URL}/jcb_logo.svg`
    case 'diners':
      return `${process.env.PUBLIC_URL}/diners_logo.svg`
  }
}
