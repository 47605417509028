import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const LoginIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.66667 10.8333L9 7.49996L5.66667 4.16663M9 7.5H1M9 1.5H11.6667C12.0203 1.5 12.3594 1.64048 12.6095 1.89052C12.8595 2.14057 13 2.47971 13 2.83333V12.1667C13 12.5203 12.8595 12.8594 12.6095 13.1095C12.3594 13.3595 12.0203 13.5 11.6667 13.5H9'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
