import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

import { CART_PATH, CHECKOUT_PATH, ID_UPLOAD_PATH, URAC_PATH } from '../../constants'
import { useAuth } from '../../context/auth-context'
import { useContentfulHeaderContent, usePatientProfile } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { haveControlledSubstance, numCartItems } from '../../persistRecoil'
import { employerDetails, showSlidingCart } from '../../recoil/atoms'
import { CartPreview, cartPreviewState } from '../../recoil/atoms/cartPreviewState'

export interface UseHeader {
  numberOfCartItems: number
  cartPreview: CartPreview
  setCartPreview: (val: CartPreview) => void
  resetCartPreview: () => void

  employerInfo: {
    name: string
    logo: string
  }

  handleCheckoutButtonClick: () => void
  handleViewCartButtonClick: () => void
  handleNavigationDrawerToggle: () => void
  handlePreviewCartModalDismiss: () => void
  handleHeaderBannerClosed: () => void
  handleCartClick: (e: React.MouseEvent<HTMLAnchorElement>) => void

  isNavigationDrawerOpen: boolean

  headerBanner: {
    desktopText?: any
    mobileText?: any
    showHeaderBanner: () => boolean
  }

  isAuthenticated: boolean
}

export const useHeader = (): UseHeader => {
  const [isHeaderBannerClosed, setIsHeaderBannerClosed] = useState(false)
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false)
  const { trackButtonClickEvent, trackLinkClickEvent } = useAnalytics()

  const [previewCartInfo, setCartPreviewState] = useRecoilState(cartPreviewState)
  const resetPreviewCart = useResetRecoilState(cartPreviewState)
  const numberOfCartItems = useRecoilValue(numCartItems)
  const { employerName, employerLogo } = useRecoilValue(employerDetails)
  const orderHasControlledSubstance = useRecoilValue(haveControlledSubstance)

  const history = useHistory()
  const { patientProfile } = usePatientProfile()
  const {
    authState: { isAuthenticated },
  } = useAuth()
  const { content: headerContent, loading: headerContentLoading } = useContentfulHeaderContent()

  const setShowSlidingCart = useSetRecoilState(showSlidingCart)
  const { pathname } = useLocation()

  const handleCartClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    trackLinkClickEvent('Cart', 'Cart', LinkOrigin.Header)
    if ([URAC_PATH, CHECKOUT_PATH, ID_UPLOAD_PATH].map((path) => path.toString()).includes(pathname)) {
      setShowSlidingCart(true)
      e.preventDefault()
    }
  }
  const setCartPreview = (val: CartPreview) => {
    setCartPreviewState(val)
  }

  const handleCheckoutButtonClick = useCallback(() => {
    trackButtonClickEvent('checkout-from-header-cart', 'Checkout')
    resetPreviewCart()
    history.push(!patientProfile?.idUploaded && orderHasControlledSubstance ? ID_UPLOAD_PATH : URAC_PATH)
  }, [trackButtonClickEvent, resetPreviewCart, history, patientProfile?.idUploaded, orderHasControlledSubstance])

  const handleViewCartButtonClick = useCallback(() => {
    trackButtonClickEvent('view-cart-from-header-cart', 'View cart')
    resetPreviewCart()
    history.push(CART_PATH)
  }, [history, resetPreviewCart, trackButtonClickEvent])

  const handleNavigationDrawerToggle = () => {
    setIsNavigationDrawerOpen(!isNavigationDrawerOpen)
    if (isNavigationDrawerOpen === false) {
      trackButtonClickEvent('menu', '[Open from hamburger menu]')
    }
  }
  const handleHeaderBannerClosed = () => setIsHeaderBannerClosed(true)

  const handlePreviewCartModalDismiss = useCallback(() => {
    trackButtonClickEvent('dismiss-preview-cart-modal', 'x')
    resetPreviewCart()
  }, [resetPreviewCart, trackButtonClickEvent])

  const showHeaderBanner = () => isAuthenticated && !isHeaderBannerClosed && !headerContentLoading && !!headerContent?.bannerEnabled

  return {
    numberOfCartItems: numberOfCartItems,
    cartPreview: previewCartInfo,
    setCartPreview: setCartPreview,
    resetCartPreview: resetPreviewCart,
    employerInfo: {
      name: employerName,
      logo: employerLogo,
    },
    handleCheckoutButtonClick,
    handleViewCartButtonClick,
    handleNavigationDrawerToggle,
    handlePreviewCartModalDismiss,
    handleHeaderBannerClosed,
    handleCartClick,

    isNavigationDrawerOpen,

    headerBanner: {
      desktopText: headerContent?.headerBannerDesktopText,
      mobileText: headerContent?.headerBannerMobileText,
      showHeaderBanner,
    },
    isAuthenticated,
  }
}
