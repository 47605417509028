import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { mediaLargerThan } from '../../common/styles/variables'

const OrdersContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0 24px;

  ${mediaLargerThan.tablet} {
    max-width: 744px;
    width: 100%;
    flex-grow: 1;
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 0;
`

const TryAgainButton = styled(ThemedButton)`
  margin-top: 2.5rem;
`

const FetchMoreLoader = styled.div`
  position: relative;
  height: 100px;
`

export { ContentWrapper, FetchMoreLoader, OrdersContainer, TryAgainButton }
