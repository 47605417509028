import { Spacer, Text } from '@truepill/react-capsule'
import { mediaLargerThan } from 'common/styles/variables'
import { EntryFields } from 'contentful'
import { ReactElement } from 'react'
import styled from 'styled-components/macro'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { useContentfulTheme } from '../../hooks'

interface CheckoutPageProps {
  header: string | EntryFields.RichText
  instructions: string | EntryFields.RichText
  children: React.ReactNode
}

const CheckoutPage = ({ header, instructions, children }: CheckoutPageProps): ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <StyledCheckoutContainer>
      <Spacer size='xl' />
      <StyledCheckoutPage>
        <ThemedHeader vpTheme={theme} variant='4xl'>
          {header}
        </ThemedHeader>
        <Spacer size='md' />
        <Text>{instructions}</Text>
        <Spacer size='xl' />
        {children}
        <Spacer size='3xl' />
      </StyledCheckoutPage>
    </StyledCheckoutContainer>
  )
}

const StyledCheckoutContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledCheckoutPage = styled.div`
  max-width: 620px;
  margin: 0rem 1rem;

  ${mediaLargerThan.mobile} {
    margin: 3rem 1.5rem 0rem;
  }

  ${mediaLargerThan.tablet} {
    width: 100%;
  }

  ${mediaLargerThan.laptop} {
    width: 100%;
    max-width: 744px;
  }
`
export default CheckoutPage
