import { Header, Spacer } from '@truepill/react-capsule'
import { FormularyMedication, PrescriptionDetailsFormValues } from '@vpharm-platform/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'

import { TRANSFER_IN_PAGE_ENHANCEMENTS } from '../../../constants/feature-flags'
import { useContentfulTheme, useCustomerProfile } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { FormularyService } from '../../../services/FormularyService'
import { AutocompleteState, MedicationDetails, TransferFormFields } from '.'
import AutocompleteDropdown, { FormularyMedicationWithFormattedName } from './AutocompleteDropdown'
import { AddPrescriptionButton, ButtonFieldContainer, FormGroup, PrescriptionInfo, TextFieldContainer } from './styledComponents'
import TransferMedicationList from './TransferMedicationList'

interface Props {
  onAddPrescription: (formData: PrescriptionDetailsFormValues) => void
  onRemovePrescription: (medicationName: string) => void
  prescriptions: PrescriptionDetailsFormValues[]
  formController: UseFormReturn<TransferFormFields>
}

const PrescriptionInformation: React.FC<Props> = ({ formController, onAddPrescription, onRemovePrescription, prescriptions }) => {
  const {
    control,
    resetField,
    setValue,
    getValues,
    formState: { errors },
  } = formController
  const { customerProfile } = useCustomerProfile()
  const [formularyMedications, setFormularyMedications] = useState<FormularyMedicationWithFormattedName[]>([])

  const [isDrugSelected, setIsDrugSelected] = useState<AutocompleteState>('default')

  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const { [TRANSFER_IN_PAGE_ENHANCEMENTS]: enhancementsFeatureFlag } = useLDFlagsWithLocalStorage([TRANSFER_IN_PAGE_ENHANCEMENTS])

  useEffect(() => {
    const fetchFormulary = (): Promise<FormularyMedication[]> => {
      const formularyService = new FormularyService()
      return formularyService.getFormulary(customerProfile.vpharmCustomerToken)
    }

    fetchFormulary().then((formularyMedications) => {
      const medications = formularyMedications.map((item) => {
        const formattedName = enhancementsFeatureFlag
          ? item.drugName?.split(' ')[0] + (item.strength && item.strengthUnitOfMeasure ? ` ${item.strength} ${item.strengthUnitOfMeasure}` : '')
          : item.drugName + (item.genericName ? ` (${item.genericName})` : '')

        const formularyMedicationWithFormattedName: FormularyMedicationWithFormattedName = {
          ...item,
          formattedName: formattedName,
          drugName: item.drugName,
          genericName: item.genericName,
        }
        return formularyMedicationWithFormattedName
      })

      const uniqueMedications = medications.filter((value, index, self) => index === self.findIndex((t) => t.formattedName === value.formattedName))

      if (enhancementsFeatureFlag) setFormularyMedications(uniqueMedications)
      else setFormularyMedications(medications)
    })
  }, [customerProfile.vpharmCustomerToken, enhancementsFeatureFlag])

  const isPrescriptionAddable = () => {
    const formValues = getValues()
    return !errors.medicationDetails && formValues.medicationDetails?.medication && formValues.medicationDetails?.medication.itemName
  }

  const handleAddPrescriptionButtonClick = useCallback(
    (prescription: MedicationDetails) => {
      trackButtonClickEvent('transfer_request_add_prescription', 'Add to Transfer')
      onAddPrescription({
        medication_name: prescription.medication.formattedName,
        medication_strength: prescription.medicationStrength,
        generic_name: prescription.medication.genericName,
        drug_name: prescription.medication.drugName,
      })
      resetField('medicationDetails.medication', { defaultValue: '' })
      setIsDrugSelected('default')
    },
    [onAddPrescription, resetField, trackButtonClickEvent],
  )

  const handleRemovePrescription = (medicationName: string) => {
    onRemovePrescription(medicationName)
  }

  const handleMedicationChange = (
    field: ControllerRenderProps<TransferFormFields, 'medicationDetails.medication'>,
    value: FormularyMedication | null,
  ) => {
    const medication = value
    if (medication === null) {
      setIsDrugSelected('default')
      setValue('medicationDetails.medicationStrength', '')
    } else {
      setIsDrugSelected('complete')

      const selectedMedication = formularyMedications.find((m) => m.ndc === medication.ndc)
      if (selectedMedication && selectedMedication.strength && selectedMedication.strengthUnitOfMeasure) {
        setValue('medicationDetails.medicationStrength', `${selectedMedication.strength} ${selectedMedication.strengthUnitOfMeasure}`)
      }

      field.onChange(value)
    }
  }

  return (
    <>
      <Header>Prescription Information</Header>
      <Spacer size='md' />
      <PrescriptionInfo vpTheme={theme}>
        <FormGroup>
          <TextFieldContainer>
            <Controller
              name='medicationDetails.medication'
              control={control}
              render={({ field }) => (
                <AutocompleteDropdown
                  drugNames={formularyMedications.filter((f) => f.drugName)}
                  errors={!!errors.medicationDetails?.medication}
                  onChange={(value: FormularyMedication | null) => handleMedicationChange(field, value)}
                  value={field.value}
                  stateDrugSelected={isDrugSelected}
                />
              )}
            />
          </TextFieldContainer>
        </FormGroup>
        <ButtonFieldContainer>
          <AddPrescriptionButton
            data-testid='add-prescription-button'
            variant='primary-outline'
            disabled={!isPrescriptionAddable()}
            onClick={() => handleAddPrescriptionButtonClick(getValues('medicationDetails'))}
            vpTheme={theme}
          >
            Add to transfer
          </AddPrescriptionButton>
        </ButtonFieldContainer>
      </PrescriptionInfo>
      <TransferMedicationList onDelete={handleRemovePrescription} prescriptions={prescriptions} data-testid='transfer-medication-list' />
    </>
  )
}

export default PrescriptionInformation
