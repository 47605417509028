export const VPHARM_VALIDATION_PATIENT_INSURANCE: {
  cardholder_id: RegExp
  rx_bin: RegExp
  rx_group: RegExp
  pcn: RegExp
} = {
  cardholder_id: /^[a-zA-Z0-9]{0,25}$/,
  rx_bin: /^[0-9]{6}$/,
  rx_group: /^[a-zA-Z0-9]{0,20}$/,
  pcn: /^[a-zA-Z0-9]{0,15}$/,
}
