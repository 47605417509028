import { ThemedComponent } from '../../common/styledComponents/types'

export const ArrowLoop = ({ vpTheme }: ThemedComponent): React.ReactElement => (
  <svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.51 7.00008C4.01717 5.56686 4.87913 4.28548 6.01547 3.27549C7.1518 2.26551 8.52547 1.55984 10.0083 1.22433C11.4911 0.888827 13.0348 0.934417 14.4952 1.35685C15.9556 1.77928 17.2853 2.56479 18.36 3.64008L23 8.00008M1 12.0001L5.64 16.3601C6.71475 17.4354 8.04437 18.2209 9.50481 18.6433C10.9652 19.0657 12.5089 19.1113 13.9917 18.7758C15.4745 18.4403 16.8482 17.7346 17.9845 16.7247C19.1209 15.7147 19.9828 14.4333 20.49 13.0001M1 18.0001V12.0001H7M23 2.00012V8.00012H17'
      stroke={vpTheme.colors.iconDefault}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
