import React from 'react'
import SmartySDK from 'smartystreets-javascript-sdk'

import { useAddressAutocomplete } from '../../hooks'
import Suggestions from '../Autocomplete/Suggestions'

interface AddressInputFieldAutocomplete {
  input: string
  hasSecondaries: boolean
}

interface AddressAutocompleteProps {
  queryObject: AddressInputFieldAutocomplete
  handleSuggestionSelect: (e: React.SyntheticEvent, suggestion: SmartySDK.usAutocompletePro.Suggestion) => void
}

const AddressAutocomplete = ({ queryObject, handleSuggestionSelect }: AddressAutocompleteProps): React.ReactElement => {
  const [addressSuggestions] = useAddressAutocomplete(queryObject)

  const formatSuggestion = (suggestion: SmartySDK.usAutocompletePro.Suggestion): string => {
    const street = suggestion.streetLine ? `${suggestion.streetLine} ` : ''
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : ''
    const entries = suggestion?.entries > 1 ? `(${suggestion.entries} more entries) ` : ''
    const city = suggestion?.city ? `${suggestion.city}, ` : ''
    const state = suggestion?.state ? `${suggestion.state}, ` : ''
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : ''

    return street + secondary + entries + city + state + zip
  }

  return (
    <Suggestions
      data-testid='autocomplete-options'
      suggestions={addressSuggestions.result}
      handleSuggestionSelect={handleSuggestionSelect}
      suggestionFormatter={formatSuggestion}
    />
  )
}

export default AddressAutocomplete
