import styled from 'styled-components'

const Container = styled.div``

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;

  p {
    margin: 0;
  }
`

export const StyledPrescriptionSelector = {
  Container,
  OptionContainer,
}
