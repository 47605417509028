import { Divider, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

const Container = styled.div`
  max-width: 744px;
  width: 100%;
`

const StepHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const StepNumber = styled.div<ThemedComponent>`
  height: 32px;
  width: 32px;
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaLargerThan.tablet} {
    flex-direction: row-reverse;
    justify-content: end;
  }
`

const BasePharmacyMessage = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;

  p {
    display: inline-block;
  }

  svg {
    display: none;
  }

  ${mediaLargerThan.tablet} {
    display: flex;
    gap: 1rem;
    align-items: center;

    svg {
      flex-shrink: 0;
      display: inline-block;
    }
  }
`

const EligiblePharmacyMessage = styled(BasePharmacyMessage)<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? defaultTheme.colors['functional-info-light']};
  svg {
    height: 20px;
    width: 20px;
  }
`

const IneligiblePharmacyMessage = styled(BasePharmacyMessage)<{ backgroundColor?: string; borderColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? defaultTheme.colors['functional-warning-light']};
  border: 1px solid ${({ borderColor }) => borderColor ?? defaultTheme.colors['functional-warning-dark']};
`

const PharmacyRow = styled.div`
  display: flex;
  gap: 0.25rem;
`

const PharamcyDivider = styled(Divider)<{ $borderColor?: string }>`
  border-color: ${({ $borderColor }) => $borderColor ?? defaultTheme.colors['gray-300']};
  padding: 0 8px;
`

const RequiredText = styled.span<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['functional-error-dark']};
`

const PhoneNumber = styled.a<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['functional-info-dark']};
`

const NumberText = styled(Text)`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
`

export const StyledTransferOut = {
  Container,
  StepHeaderContainer,
  StepNumber,
  ButtonContainer,
  EligiblePharmacyMessage,
  IneligiblePharmacyMessage,
  PharmacyRow,
  PharamcyDivider,
  RequiredText,
  PhoneNumber,
  NumberText,
}
