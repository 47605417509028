import { LoginButton } from 'Components/Auth'
import SignUp from 'Components/SignupButton'
import { ABOUT_PATH, CONTACT_PATH, FAQ_PATH, SELF_ENROLLMENT_PATH } from 'constants/navigation-links'
import { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { useCustomerConfigContext } from '../CustomerConfigProvider'
import { SignedOutActionableContainer, SignedOutNavLinksContainer, StyledLink, StyledNavItem } from './styledComponents'

const SignedOutNavLinks = (): ReactElement => {
  const { pharmCustomer } = useCustomerConfigContext()
  const { trackLinkClickEvent } = useAnalytics()
  const displayAboutLink = pharmCustomer?.componentConfiguration?.fields?.footer?.fields.displayAboutLink
  const displayFaqLink = pharmCustomer?.componentConfiguration?.fields?.footer?.fields.displayFaqLink
  const faqLink = pharmCustomer?.customerSupport?.fields?.faqLink

  const links = []

  if (displayAboutLink) {
    links.push({ text: 'About', to: ABOUT_PATH })
  }

  links.push({ text: 'Contact', to: CONTACT_PATH })

  if (displayFaqLink) {
    links.push({ text: 'Help Center', to: faqLink || FAQ_PATH, target: faqLink ? '_blank' : '_self' })
  }

  return (
    <>
      <StyledNavItem>
        {links.map((link) => {
          const testId = `${link.to.toLowerCase()}-nav-url`
          return (
            <StyledLink
              key={link.to}
              onClick={() => trackLinkClickEvent(link.text, link.to, LinkOrigin.Header)}
              to={{ pathname: link.to }}
              target={link.target}
              data-testid={testId}
            >
              {link.text}
            </StyledLink>
          )
        })}
      </StyledNavItem>
    </>
  )
}

function shouldShowSignInSignUp(pathname: string) {
  return ![SELF_ENROLLMENT_PATH.split('/')[1]].includes(pathname.split('/')[1])
}

const SignedOutNav = ({
  isSelfEnrollmentEnabled,
  isCustomerTerminated,
}: {
  isSelfEnrollmentEnabled: boolean
  isCustomerTerminated: boolean
}): ReactElement => {
  const { pathname } = useLocation()

  return (
    <SignedOutNavLinksContainer>
      <SignedOutNavLinks />
      {shouldShowSignInSignUp(pathname) && !isCustomerTerminated && (
        <SignedOutActionableContainer>
          <LoginButton />
          {isSelfEnrollmentEnabled && <SignUp />}
        </SignedOutActionableContainer>
      )}
    </SignedOutNavLinksContainer>
  )
}

export { SignedOutNav }
