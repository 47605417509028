import { Text } from '@truepill/react-capsule'
import { InfoTooltip } from 'Components/Tooltip'
import { ReactElement } from 'react'

import { Container, LabelContainer, LabelText } from './styledComponents'

interface GeneralInformationItemProps {
  label: string
  text: string
  tooltipLabel?: string
  tooltip?: string | JSX.Element
  testId: string
}

export const GeneralInformationItem = ({ label, text, testId, tooltip, tooltipLabel }: GeneralInformationItemProps): ReactElement => {
  return (
    <Container>
      <LabelContainer>
        <LabelText bold data-testid={testId + '-label'}>
          {label}
        </LabelText>
        {tooltip && tooltipLabel ? (
          <>
            <InfoTooltip testId={testId} label={tooltipLabel} position='right' text={tooltip} />
          </>
        ) : null}
      </LabelContainer>

      <Text data-testid={testId + '-text'}>{text}</Text>
    </Container>
  )
}
