import { SEO } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import Compliance from 'pages/Compliance/Compliance'
import styled from 'styled-components/macro'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { useContentfulTheme } from '../../hooks'

const HIPAAPractices: React.FC = () => {
  const { theme } = useContentfulTheme()
  const { pharmCustomer } = useCustomerConfigContext()

  return (
    <>
      <SEO title={`${pharmCustomer?.displayName} - HIPPA Practices`} useDefaults />
      <Compliance />
      <HIPAA_imgsContainer>
        <HIPAA_imgs>
          <BadgeContainer>
            <BadgeLink href='https://accreditnet.urac.org/directory/#/accreditation/MSP-50/info' target='_blank' rel='noreferrer'>
              <Badge alt='logo1' src='https://storage.googleapis.com/vpp-static-assets/logoSpecialty.png' />
            </BadgeLink>
            <BadgeInfo>
              <BadgeLabel color={theme.colors['primary-900']}>Acredited</BadgeLabel>
              <BoldBadgeLabel color={theme.colors['primary-900']}>Mail service Pharmacy</BoldBadgeLabel>
              <BadgeLabel>Expires 02/01/2026</BadgeLabel>
            </BadgeInfo>
          </BadgeContainer>
          <BadgeContainer>
            <BadgeLink href='https://accreditnet.urac.org/directory/#/accreditation/SPP-336/info' target='_blank' rel='noreferrer'>
              <Badge alt='logo2' src='https://storage.googleapis.com/vpp-static-assets/logoSpecialty.png' />
            </BadgeLink>
            <BadgeInfo>
              <BadgeLabel>Acredited</BadgeLabel>
              <BoldBadgeLabel>Specialty service pharmacy</BoldBadgeLabel>
              <BadgeLabel>Expires 04/01/2026</BadgeLabel>
            </BadgeInfo>
          </BadgeContainer>
          <BadgeContainer>
            <WideBadge alt='logo3' src='https://storage.googleapis.com/vpp-static-assets/logoNABP.png' />
          </BadgeContainer>
        </HIPAA_imgs>
      </HIPAA_imgsContainer>
    </>
  )
}

const Badge = styled.img`
  width: 4.75rem;
`
const BadgeLink = styled.a`
  width: 4.75rem;
`

const WideBadge = styled.img`
  width: 14.75rem;
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  &:last-child {
    width: 14.75rem;
    margin-left: -0.3rem;
  }
`

const BadgeInfo = styled.div`
  margin-left: 0.75rem;
`

const BadgeLabel = styled.div`
  color: ${({ color }) => color ?? defaultTheme.colors['primary-900']};
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
`

const BoldBadgeLabel = styled.div`
  color: ${({ color }) => color ?? defaultTheme.colors['primary-900']};
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  text-wrap: nowrap;
`

const HIPAA_imgs = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      padding: 0.875rem;
    }
  }
`

const HIPAA_imgsContainer = styled.div`
  display: flex;
  width: 45%;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export default HIPAAPractices
