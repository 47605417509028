import { PrescriptionCopayStatusType } from '@vpharm-platform/shared'
import { atom, DefaultValue, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { CartData, Medication } from '../interfaces'
import { DEFAULT_CART } from '../mocks'
import { mixedCartAtom } from './mixedCart'
import { OrderMode, orderModeAtom } from './orderMode'
import { selectedPatientTokenAtom } from './selectedPatientToken'

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
})

export const cartData = atom<CartData>({
  key: 'cartData',
  default: { ...DEFAULT_CART },
  effects_UNSTABLE: [persistAtom],
})

/**
 * A simple selector which returns medications grouped against the insurance which covers them.
 */

export const medicationsInCart = selector({
  key: 'medicationsInCart',
  get: ({ get }): Medication[] => get(cartData).medications.filter(({ wasRemovedFromCart }) => !wasRemovedFromCart),
})

export const numCartItems = selector({
  key: 'numCartItems',
  get: ({ get }): number => get(medicationsInCart).length,
})

export type PriceDetailsType = {
  subTotal: number
  estimatedShipping: number
  estimatedTax: number
  orderTotal: number
}

export const pricingDetails = selector({
  key: 'pricingDetails',
  set: ({ get, set }, newPriceDetails: PriceDetailsType | DefaultValue) => {
    const oldCartData = get(cartData)
    const newCartState = {
      ...oldCartData,
      price: {
        ...oldCartData.price,
        ...newPriceDetails,
      },
    }

    return set(cartData, newCartState)
  },
  get: ({ get }): PriceDetailsType => {
    const cartState = get(cartData)
    const numItems = get(numCartItems)
    const { orderMode } = get(orderModeAtom)
    const selectedPatientToken = get(selectedPatientTokenAtom)
    const isMixedCartEnabled = get(mixedCartAtom)

    if (!numItems || !selectedPatientToken) {
      return {
        subTotal: 0,
        estimatedShipping: 0,
        estimatedTax: 0,
        orderTotal: 0,
      }
    }

    const subTotal = cartState.medications
      .filter((med) => !med.wasRemovedFromCart)
      .reduce((totalAmount, { copay, unitPrice, retailPrice, selectedPriceOption }) => {
        const priceToAdd =
          isMixedCartEnabled && selectedPriceOption === 'cash'
            ? Number(retailPrice)
            : orderMode === OrderMode.CASH
            ? Number(unitPrice)
            : copay?.type === PrescriptionCopayStatusType.SUCCESS
            ? Number(copay?.amount)
            : null
        if (!priceToAdd && priceToAdd !== 0) {
          throw new Error('No copay price found')
        }
        return totalAmount + priceToAdd
      }, 0)

    const {
      price: { estimatedShipping = 0, estimatedTax = 0 },
    } = cartState

    return {
      subTotal,
      estimatedShipping: estimatedShipping,
      estimatedTax: estimatedTax,
      orderTotal: subTotal + estimatedShipping + estimatedTax,
    }
  },
})

export const medicationNameByRx = selector({
  key: 'medicationNameByRx',
  get:
    ({ get }) =>
    (rxNumber: string): string => {
      const medication = get(cartData).medications.find((med) => med.rxNumber === rxNumber)
      return medication?.brandDisplayName ?? ''
    },
})

export const haveControlledSubstance = selector({
  key: 'haveControlledSubstance',
  get: ({ get }): boolean => {
    const { medications } = get(cartData)

    return medications.some((m) => m.isControlledSubstance && !m.wasRemovedFromCart)
  },
})

export const haveColdChainMedication = selector({
  key: 'haveColdChainMedication',
  get: ({ get }): boolean => {
    const { medications } = get(cartData)

    return medications.some((m) => m.coldChain && !m.wasRemovedFromCart)
  },
})

export const medicationsRemoved = selector({
  key: 'medicationsRemoved',
  get: ({ get }): Medication[] => {
    const { medications } = get(cartData)

    return medications.filter((m) => m.wasRemovedFromCart)
  },
})
