import { PaymentCard } from '@vpharm-platform/shared'
import { useEffect, useState } from 'react'

import { userService } from '../services'
import { useCustomerProfile } from '.'

export interface PaymentCardType extends PaymentCard {
  id?: number
  saved: boolean
}

export const INITIAL_CARD: PaymentCardType = {
  brand: '',
  last4: '',
  default: false,
  paymentMethodId: '',
  name: '',
  expirationMonth: 1,
  expirationYear: 2022,
  postalCode: '',
  saved: false,
  fingerprint: '',
}

export const useUserPayment = (): {
  isError?: boolean
  isLoading: boolean
  selectedPayment: PaymentCardType
  setDefaultPaymentMethod: (paymentMethod: string) => Promise<void>
  setIsError: (error: boolean) => void
  setSelectedPayment: (payment: PaymentCardType) => void
} => {
  const { customerProfile } = useCustomerProfile()
  const [selectedPayment, setSelectedPayment] = useState<PaymentCardType>(INITIAL_CARD)

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isError) {
      setSelectedPayment(INITIAL_CARD)
      setIsLoading(false)
    }
  }, [isError, setSelectedPayment])

  const setDefaultPaymentMethod = async (paymentMethodId: string) => {
    await userService.setDefaultPaymentMethod(customerProfile.vpharmCustomerToken, paymentMethodId)
  }

  return {
    isError,
    isLoading,
    selectedPayment,
    setDefaultPaymentMethod,
    setIsError,
    setSelectedPayment,
  }
}
