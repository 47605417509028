// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Text, TextField } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const FormContainer = styled.div``

const StyledForm = styled.form`
  input {
    font-weight: 600;
  }
`

const ZipWrapper = styled.div`
  flex: 0 0 100%;
  ${mediaLargerThan.mobile} {
    flex: 1;
  }
`

const CvvExpWrapper = styled.div`
  flex: 1;
`

const TextFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  ${mediaLargerThan.mobile} {
    flex-flow: row;
  }
`

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

const LoadingAnimationWrapper = styled.div<ThemedComponent>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  opacity: 0.2;
  z-index: 1000;
`

const OptionalSaveContainer = styled.div<ThemedComponent>`
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
  border-radius: 0.5rem;

  > div {
    padding: 1rem;
  }

  ${mediaLargerThan.tablet} {
    grid-template-columns: 8fr 4fr;
  }
`

const StyledSavePaymentContainer = styled.div<ThemedComponent>`
  display: flex;
  align-items: center;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
  border-radius: 0.5rem;
`

const StyledToggleContainer = styled.div<ThemedComponent>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  border-radius: 0.5rem;
`

const ReimbursementText = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
`

const PaymentTextField = styled(TextField)<ThemedComponent>`
  box-shadow: none !important;

  ${({ vpTheme, state }) => `
      ${state === 'default' ? `
        background-color: ${vpTheme.colors.fieldBgDefault};
        border: 1px solid ${vpTheme.colors.fieldBorderDefault};
      ` : ''}
      ${state === 'error' ? `
        color: #eb1c26;
        background-color: ${vpTheme.colors['functional-error-light']};
        border: 1px solid ${vpTheme.colors['functional-error-dark']};
      ` : ''}
      &:focus {
        ${state === 'default' ? `border: 3px solid ${vpTheme.colors.fieldBorderDefault};` : ''}
        ${state === 'error' ? `border: 3px solid ${vpTheme.colors['functional-error-dark']};` : ''}
      }
    `}
`

export {
  ButtonGroup,
  CvvExpWrapper,
  FormContainer,
  LoadingAnimationWrapper,
  OptionalSaveContainer,
  PaymentTextField,
  ReimbursementText,
  StyledCheckbox,
  StyledForm,
  StyledSavePaymentContainer,
  StyledToggleContainer,
  TextFieldContainer,
  ZipWrapper,
}
