export const formatToPhone = (value: string): string => {
  const input = value.replace(/\D/g, '').substring(0, 10)
  const areaCode = input.substring(0, 3)
  const middle = input.substring(3, 6)
  const last = input.substring(6, 10)

  if (input.length > 6) {
    return `(${areaCode}) ${middle}-${last}`
  }
  if (input.length > 3) {
    return `(${areaCode}) ${middle}`
  }
  if (input.length > 0) {
    return `(${areaCode}`
  }
  return `${areaCode}`
}

export const unformatPhone = (value: string): string => {
  let unformatted = ''

  for (let i = 0; i < value.length; i++) {
    const c = value.charAt(i)
    if (c !== '(' && c !== ')' && c !== '-' && c !== ' ') {
      unformatted += c
    }
  }

  return unformatted
}
