import { useState } from 'react'

import { useAuth } from '../../context/auth-context'
import { useCustomerProfile } from '../../hooks'
import { CustomerProfile } from '../../interfaces'
import { ForgotPasswordFields } from './types'

export interface UseForgotPassword {
  customerProfile: CustomerProfile
  isAuthenticated: boolean
  changePasswordErrMsg: string
  passwordRequestSuccessMsg: string
  requestForgotPassword: (values: ForgotPasswordFields) => Promise<void>
}

export const useForgotPassword = (): UseForgotPassword => {
  const { customerProfile } = useCustomerProfile()
  const {
    authState: { isAuthenticated },
    requestChangePassword,
  } = useAuth()
  const [changePasswordErrMsg, setChangePasswordErrMsg] = useState('')
  const [passwordRequestSuccessMsg, setPasswordRequestSuccessMsg] = useState('')

  const requestForgotPassword = async (forgotPasswordFields: ForgotPasswordFields): Promise<void> => {
    setChangePasswordErrMsg('')
    try {
      await requestChangePassword(forgotPasswordFields.email)
      setPasswordRequestSuccessMsg("We've just sent you an email to reset your password.")
    } catch (e) {
      if (e instanceof Error) {
        setChangePasswordErrMsg(e.message)
      }
    }
  }

  return {
    customerProfile,
    isAuthenticated,
    changePasswordErrMsg,
    requestForgotPassword,
    passwordRequestSuccessMsg,
  }
}
