import { Header } from '@truepill/react-capsule'
import { ReactElement } from 'react'

import { accountLinks, AccountPage, PrescriptionManagementPage } from '../../constants'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { NavigationLink } from '../../interfaces/Header'
import SideNavigation from './SideNavigation'
import { SideBarDiv, StyledMain, StyledRow } from './styledComponents'

export interface LayoutProps {
  children: React.ReactNode
  selected: AccountPage | PrescriptionManagementPage
  sidebarContent?: React.ReactElement
  navigationLinks: NavigationLink[]
}

type Props = { title: string } & LayoutProps

const PageLayoutWithSidebar = ({ children, title, navigationLinks, selected, sidebarContent }: Props): ReactElement => {
  const { customerProfile } = useCustomerProfile()
  const sidebarLinks =
    navigationLinks.length > 0 && customerProfile.cashOnly ? navigationLinks.filter((link) => link.name !== 'Insurance') : navigationLinks
  const links = sidebarLinks.map((link) => ({ ...link, selected: link.name === selected }))

  return (
    <StyledRow>
      <SideBarDiv data-testid='am-side-bar'>
        <Header bold variant='2xl'>
          {title}
        </Header>
        <SideNavigation links={links} />
        {sidebarContent}
      </SideBarDiv>
      <StyledMain>{children}</StyledMain>
    </StyledRow>
  )
}

export const AccountLayout = ({ children, selected }: Omit<LayoutProps, 'navigationLinks'>): ReactElement => {
  const { theme } = useContentfulTheme()
  return PageLayoutWithSidebar({ title: 'Account Management', navigationLinks: accountLinks(theme).slice(1), children, selected })
}

export const PrescriptionManagementLayout = ({ children, selected, sidebarContent, navigationLinks }: LayoutProps): ReactElement =>
  PageLayoutWithSidebar({
    title: 'Prescription manager',
    navigationLinks,
    children,
    selected,
    sidebarContent,
  })

export default AccountLayout
