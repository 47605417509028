import styled from 'styled-components'

import { ThemedComponent } from '../../common/styledComponents/types'
import { breakpoint } from '../../common/styles/variables'

const Container = styled.div`
  width: 100%;
  max-width: 670px;
  padding: 0 24px;
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  margin-top: 10rem;

  ${breakpoint.mobile} {
    margin-top: 2rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`

const LoadingSpinnerWrapper = styled.div<ThemedComponent>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  div.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  }
`

export { ButtonContainer, Container, LoadingSpinnerWrapper }
