import React from 'react'

import { Container } from './styledComponents'

type ComponentProps = React.PropsWithChildren<{
  'data-testid'?: string
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ContainerWrapper: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
  return (
    <Container className={props.className} data-testid={props['data-testid']}>
      {props.children}
    </Container>
  )
}

export default ContainerWrapper
