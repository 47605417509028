import { VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'

const LockIconWrapper = styled.span`
  align-items: center;
  display: flex;
  margin: 0;
  padding-right: 0.5rem;
`

const CheckoutButton = styled(ThemedButton)<{ fullWidth?: boolean; vpTheme: VpTheme }>`
  align-items: center;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  margin: 0;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto ')};

  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors.grayWhite} !important;
  color: ${({ vpTheme }) => vpTheme.colors.grayWhite};

  && {
    padding: 0.625rem 1.375rem;
  }
`

export { CheckoutButton, LockIconWrapper }
