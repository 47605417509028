import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedComponent } from '../../common/styledComponents/types'

const ColdChainNotificationContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-300'] ?? defaultTheme.colors['primary-300']};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0.75rem 1rem 1rem 1rem;
`

const TextContainer = styled.div<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  min-width: 2rem;
`

export { ColdChainNotificationContainer, IconContainer, TextContainer }
