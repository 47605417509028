import { Header, Spacer } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import { InfoTooltip } from 'Components/Tooltip'
import { ReactElement } from 'react'
import { Lock, Mail, Smartphone } from 'react-feather'

import { useContentfulTheme } from '../../../hooks'
import StyledHeader from '../StyledHeader'
import {
  AccountSettingTabButton,
  HeaderToolTipContainer,
  IconContainer,
  InformationContainer,
  InformationHeaderText,
  InformationTab,
  PasswordContainer,
  TabHeaderContainer,
} from './styledComponents'

interface AccountInformationContentProps {
  email: string | undefined
  phoneNumber: string | undefined
  contentfulPhoneToolTipInfo: string | undefined
  editEmailHandler: () => void
  changePasswordHandler: () => void
  editPhoneNumberHandler: () => void
}

const AccountInformationContent = ({
  email,
  phoneNumber,
  contentfulPhoneToolTipInfo,
  editEmailHandler,
  changePasswordHandler,
  editPhoneNumberHandler,
}: AccountInformationContentProps): ReactElement => {
  const { theme } = useContentfulTheme()

  const phoneToolTipInfo =
    contentfulPhoneToolTipInfo || 'We will only use this to contact you in case there is an urgent issue with your prescriptions or orders.'

  return (
    <>
      <StyledHeader>
        <Header bold variant='xl' data-testid='address-title'>
          Account Information
        </Header>
      </StyledHeader>
      <InformationContainer>
        <InformationTab vpTheme={theme}>
          <IconContainer>
            <Mail />
          </IconContainer>
          <PasswordContainer>
            <TabHeaderContainer>
              <Header variant='xl'>Email</Header>
              <InformationHeaderText color={theme.colors['typography-medium']}>{email || 'Unavailable'}</InformationHeaderText>
            </TabHeaderContainer>
            <AccountSettingTabButton aria-label='editEmail' role='button' onClick={editEmailHandler} variant='primary-text' vpTheme={theme}>
              Edit
            </AccountSettingTabButton>
          </PasswordContainer>
        </InformationTab>
        <Spacer size='md' />
        <InformationTab vpTheme={theme}>
          <IconContainer>
            <Lock />
          </IconContainer>
          <PasswordContainer>
            <TabHeaderContainer>
              <Header variant='xl'>Password</Header>
            </TabHeaderContainer>
            <AccountSettingTabButton role='button' aria-label='changePassword' onClick={changePasswordHandler} variant='primary-text' vpTheme={theme}>
              Change password
            </AccountSettingTabButton>
          </PasswordContainer>
        </InformationTab>
        <Spacer size='md' />
        <InformationTab vpTheme={theme}>
          <IconContainer>
            <Smartphone />
          </IconContainer>
          <PasswordContainer>
            <TabHeaderContainer>
              <HeaderToolTipContainer>
                <Header variant='xl'>Mobile phone</Header>
                <InfoTooltip label='Mobile Number' text={phoneToolTipInfo} position='right' testId='phone-number' />
              </HeaderToolTipContainer>
              <InformationHeaderText color={theme.colors['typography-medium']}>
                {phoneNumber ? formatToPhone(phoneNumber) : 'Unavailable'}
              </InformationHeaderText>
            </TabHeaderContainer>
            <AccountSettingTabButton aria-label='editPhone' role='button' onClick={editPhoneNumberHandler} variant='primary-text' vpTheme={theme}>
              Edit
            </AccountSettingTabButton>
          </PasswordContainer>
        </InformationTab>
      </InformationContainer>
    </>
  )
}

export default AccountInformationContent
