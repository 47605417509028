import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const PhoneIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      stroke={vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']}
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='square'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6 1H18C18.5304 1 19.0391 1.21071 19.4142 1.58579C19.7893 1.96086 20 2.46957 20 3V21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23H6C5.46957 23 4.96086 22.7893 4.58579 22.4142C4.21071 22.0391 4 21.5304 4 21V3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086 1.21071 5.46957 1 6 1V1Z' />
      <path
        d='M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z'
        fill={vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']}
      />
    </svg>
  )
}
