import { yupResolver } from '@hookform/resolvers/yup'
import { Radio, Spacer } from '@truepill/react-capsule'
import { breakpoint } from 'common/styles/variables'
import ErrorMessage from 'Components/ErrorMessage'
import CheckoutPage from 'pages/CheckoutFlow/CheckoutPage'
import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { parseTextFieldStateForCapsule } from 'utils'
import { LegalGuardianValidation } from 'utils/validation'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../hooks'

type GuardianFormValues = {
  guardianFirstName: string
  guardianLastName: string
}

const ParentRelationshipConfirmation: React.FC = () => {
  const history = useHistory()
  const [showGuardianForm, setShowGuardianForm] = useState<boolean>()
  const { theme } = useContentfulTheme()

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields, isValid },
  } = useForm<GuardianFormValues>({
    resolver: yupResolver(LegalGuardianValidation),
    mode: 'onChange',
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (formData: GuardianFormValues) => {
    history.push('/checkout/patient-urac')
  }

  const backButton = (
    <ThemedButton type='button' variant='primary-text' onClick={() => history.goBack()} vpTheme={theme}>
      Back
    </ThemedButton>
  )

  return (
    <CheckoutPage
      header='What is your relationship with the patient?'
      instructions='Because this prescription is for a minor, we require a little more information.'
    >
      <Fragment>
        <Fragment>
          <Radio
            onChange={() => setShowGuardianForm(true)}
            name='guardian'
            value='guardian'
            label='I am their legal guardian.'
            checked={showGuardianForm !== null && showGuardianForm}
          />
          <Spacer size='md' axis='vertical' />
          <Radio
            onChange={() => setShowGuardianForm(false)}
            name='patient'
            value='patient'
            label='I am the patient.'
            checked={showGuardianForm !== null && !showGuardianForm}
          />
          <Spacer size='md' axis='vertical' />
        </Fragment>
        {!showGuardianForm && (
          <ButtonsContainer>
            <Spacer size='lg' axis='vertical' />
            {backButton}
            <ThemedButton disabled={showGuardianForm == null} type='button' vpTheme={theme}>
              Continue
            </ThemedButton>
          </ButtonsContainer>
        )}
        {showGuardianForm && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <GuardianFieldsContainer>
              <GuardianField>
                <ThemedTextField
                  label='Guardian first name'
                  {...register('guardianFirstName')}
                  required
                  showRequiredIndicator
                  state={parseTextFieldStateForCapsule(errors.guardianFirstName, dirtyFields.guardianFirstName)}
                  aria-labelledby='guardianFirstNameError'
                  vpTheme={theme}
                />
                {errors.guardianFirstName && <ErrorMessage text={`Please enter Guardian's first name`} id='guardianFirstNameError' />}
              </GuardianField>
              <Spacer size='md' axis='horizontal' />
              <GuardianField>
                <ThemedTextField
                  label='Guardian last name'
                  {...register('guardianLastName')}
                  required
                  showRequiredIndicator
                  state={parseTextFieldStateForCapsule(errors.guardianLastName, dirtyFields.guardianLastName)}
                  aria-labelledby='guardianLastNameError'
                  vpTheme={theme}
                />
                {errors.guardianLastName && <ErrorMessage text={`Please enter Guardian's last name`} id='guardianLastNameError' />}
              </GuardianField>
            </GuardianFieldsContainer>
            <Spacer size='sm' axis='vertical' />
            <ButtonsContainer>
              {backButton}
              <ThemedButton disabled={!isValid} type='submit' vpTheme={theme}>
                Continue
              </ThemedButton>
            </ButtonsContainer>
          </form>
        )}
      </Fragment>
    </CheckoutPage>
  )
}

const GuardianFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${breakpoint.tablet} {
    flex-direction: column;
  }
`

const GuardianField = styled.div`
  margin-bottom: 0.75rem;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`

export default ParentRelationshipConfirmation
