import { OrderDetails, OrderRejectionInfo, OrderTriageInfo } from '@vpharm-platform/shared'

import { parseRejectionMessage, parseTriageMessage, PrescriptionDisplayInfo } from '../../../constants/orders'
import { MicroCopyResourceType } from '../../../hooks/contentful/types/microcopy'
import { useContentfulErrorMessage } from '../../../hooks/contentful/useContentfulErrorMessage'
import { OrderErrorMessageMicrocopy, OrderTriageMessageMicrocopy } from '../microcopy'

interface useRejectionAndTriageInfoParams {
  triageInfo?: OrderTriageInfo
  rejectionInfo?: OrderRejectionInfo
  status: OrderDetails['status']
}

export const useRejectionAndTriageInfo = ({
  triageInfo,
  rejectionInfo,
  status,
}: useRejectionAndTriageInfoParams): PrescriptionDisplayInfo | Record<string, never> => {
  const { microcopy: triageCopies } = useContentfulErrorMessage<OrderTriageMessageMicrocopy>(MicroCopyResourceType.OrderTriageMessage)
  const { microcopy: rejectionCopies } = useContentfulErrorMessage<OrderErrorMessageMicrocopy>(MicroCopyResourceType.OrderErrorMessage)
  if (status === 'PENDING') {
    return parseTriageMessage(triageInfo, triageCopies)
  } else if (status === 'FAILED') {
    return parseRejectionMessage(rejectionInfo, rejectionCopies)
  } else return {}
}
