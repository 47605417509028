import styled from 'styled-components/macro'

const AcceptTermsContainer = styled.div`
  line-height: 1.5rem;
  > a {
    cursor: pointer;
    text-decoration: underline;
  }
`

const PatientConsentContainer = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 1rem;
`

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`

export { AcceptTermsContainer, PatientConsentContainer, SubmitButtonContainer }
