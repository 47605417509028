import { Text } from '@truepill/react-capsule'
import React from 'react'

import { useCheckoutContext } from '../CheckoutProvider'

export const AddressInfoView: React.FC = () => {
  const { selectedAddress } = useCheckoutContext()

  return selectedAddress ? (
    <>
      <Text bold>Address</Text>
      <Text>{selectedAddress.name}</Text>
      <Text>{`${selectedAddress.address1} ${selectedAddress.address2 ?? ''}`}</Text>
      <Text>{`${selectedAddress.city}, ${selectedAddress.state}. ${selectedAddress.zip}`}</Text>
    </>
  ) : null
}
