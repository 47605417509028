import { MedicationInfo, PrescriptionAvailabilityResponse } from '@vpharm-platform/shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilStateLoadable } from 'recoil'

import { ThemedSelect } from '../../common/styledComponents/ThemedSelect'
import { InsurancePageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import { usePatientInsurance } from '../../hooks/usePatientInsurance'
import { prescriptionsByAvailability } from '../../recoil/atoms'
import { isSavingsCardAvailable } from '../../utils/savingsCardUtils'
import OptionComponent, { BaseOptions } from './AddCardModalOptions'

interface PropTypes {
  setSelectedPrescription: (prescription: MedicationInfo | undefined) => void
}

interface SavingsCardOption extends BaseOptions {
  value: MedicationInfo
}

const AddCardPrescriptionSelect: React.FC<PropTypes> = ({ setSelectedPrescription }) => {
  const { theme } = useGetPageContent<InsurancePageMicrocopy>('insurancePage')
  const [prescriptionListLoadable] = useRecoilStateLoadable(prescriptionsByAvailability)
  const { patientInsuranceList } = usePatientInsurance()

  const savingsCardOptions = useMemo<SavingsCardOption[]>(() => {
    const prescriptions = prescriptionListLoadable.contents as PrescriptionAvailabilityResponse
    if (!prescriptions) return []
    const availablePrescriptions = [
      ...prescriptions.availableToOrder.filter((prescriptions) => isSavingsCardAvailable(prescriptions, !!patientInsuranceList?.length)),
      ...prescriptions.unavailableToOrder.filter((prescriptions) => isSavingsCardAvailable(prescriptions, !!patientInsuranceList?.length)),
    ]
    return availablePrescriptions.map((prescription) => ({
      label: prescription.brandDisplayName,
      value: prescription,
      vpTheme: theme,
    }))
  }, [patientInsuranceList?.length, prescriptionListLoadable.contents, theme])

  const isPrescriptionOptionDisabled = ({ value }: SavingsCardOption) => {
    return !!value.savingsCard?.appliedSavingsCard
  }

  const [selectedPrescriptionOption, setSelectedPrescriptionOption] = useState<SavingsCardOption | undefined>(
    () => savingsCardOptions?.filter((op) => !isPrescriptionOptionDisabled(op))?.[0],
  )

  useEffect(() => {
    setSelectedPrescription(selectedPrescriptionOption?.value)
  }, [selectedPrescriptionOption, setSelectedPrescription])

  return (
    <ThemedSelect
      options={savingsCardOptions}
      label='For which medication should this savings card be applied?'
      selectedKey='label'
      value={selectedPrescriptionOption}
      optionComponent={OptionComponent}
      onChange={setSelectedPrescriptionOption}
      isOptionDisabled={isPrescriptionOptionDisabled}
    />
  )
}

export default AddCardPrescriptionSelect
