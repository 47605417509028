import { datadogRum } from '@datadog/browser-rum'
import { ErrorBoundary } from 'datadog-react'

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed'

const retryPageLoading = () => {
  const pageHasBeenForceRefreshed = JSON.parse(window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false') as boolean

  if (!pageHasBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'true')

    return window.location.reload()
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'false')
  }
}

export class VpErrorBoundary extends ErrorBoundary {
  componentDidCatch(error: Error): void {
    if (error.message.includes('chunk')) {
      datadogRum.addAction('refreshing-chunk-error', {
        scope: this.props.scope,
      })
      retryPageLoading()
    } else {
      super.componentDidCatch(error)
    }
  }
}
