import { Header, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import PillBannerLayout from '../../../Components/Banner/PillBannerLayout'
import { ContentContainer, HeaderContainer, Icon, InsuranceBannerContainer } from './styledComponents'

export type InsuranceBannerProps = {
  theme: VpTheme
  iconUrl?: string
  mainHeader?: string
  backgroundColor?: string
  subtext?: string
}

const InsuranceBanner = ({ theme, mainHeader, subtext, iconUrl, backgroundColor }: InsuranceBannerProps): React.ReactElement => {
  return (
    <InsuranceBannerContainer>
      <PillBannerLayout isPillReversed backgroundColor={backgroundColor} maxWidth='625px'>
        <HeaderContainer vpTheme={theme}>
          {iconUrl && <Icon src={iconUrl} />}
          {mainHeader && (
            <Header variant='2xl' bold>
              {mainHeader}
            </Header>
          )}
        </HeaderContainer>
        <ContentContainer vpTheme={theme}>{subtext && <Text>{<div dangerouslySetInnerHTML={{ __html: subtext }} />}</Text>}</ContentContainer>
      </PillBannerLayout>
    </InsuranceBannerContainer>
  )
}

export default InsuranceBanner
