import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { Modal } from '../../../common/styledComponents/StyledModal'
import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { media } from '../../../common/styles/variables'

const AccountEditEmailModalContentContainer = styled.div``

const DisclaimerText = styled(Text)<{ color: string }>`
  margin-top: 1.5rem;
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const EmailInfoContainer = styled.div`
  margin-top: 1.5rem;
`

const FormWrapper = styled.form`
  margin-top: 1.5rem;

  input[type='email'] {
    -webkit-appearance: none;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 3rem;
`

const AccountEditPhoneModalContentContainer = styled.div``

const PhoneNumberInfoContainer = styled.div`
  margin-top: 1.5rem;
`

const EditPhoneFormWrapper = styled.form`
  margin: 1.5rem 0 2rem 0;

  input[type='tel'] {
    -webkit-appearance: none;
  }
`

const UserEmailContainer = styled.div`
  display: flex;
  width: 100%;
`

const InformationContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`

const InformationTab = styled.div<ThemedComponent>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 8px;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  padding: 1rem;
  min-height: 5.5rem;
`

const PasswordContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const AccountSettingTabButton = styled(ThemedButton)`
  padding: 0;
`

const IconContainer = styled.div``

const InformationHeaderText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const HeaderToolTipContainer = styled.div`
  display: flex;
`

const TabHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  @media (min-width: ${media.tablet}) {
    & > ${InformationHeaderText} {
      margin-left: 1rem;
    }
    align-items: center;
    flex-direction: row;
  }
`

const AccountSmsContainer = styled.div<ThemedComponent>`
  display: flex;
  width: 100%;
  padding: 1rem;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 0.5rem;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  gap: 1.5rem;

  @media (min-width: ${media.tablet}) {
    justify-content: space-between;
  }
`

const StyledModal = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 1.25rem;
    right: 0.8rem;
  }
`

const PasswordFormWrapper = styled.form`
  input[type='password'] {
    -webkit-appearance: none;
  }
`

const StyledText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const PasswordButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 3rem;
`

const ErrorContainer = styled.div<{ color?: string; background?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['functional-error-dark']};
  background-color: ${({ background }) => background ?? defaultTheme.colors['functional-error-light']};
  border: 1px solid ${({ color }) => color ?? defaultTheme.colors['functional-error-dark']};
  padding: 1rem;
`

export {
  AccountEditEmailModalContentContainer,
  AccountEditPhoneModalContentContainer,
  AccountSettingTabButton,
  AccountSmsContainer,
  ButtonsContainer,
  DisclaimerText,
  EditPhoneFormWrapper,
  EmailInfoContainer,
  ErrorContainer,
  FormWrapper,
  HeaderToolTipContainer,
  IconContainer,
  InformationContainer,
  InformationHeaderText,
  InformationTab,
  PasswordButtonsContainer,
  PasswordContainer,
  PasswordFormWrapper,
  PhoneNumberInfoContainer,
  StyledModal,
  StyledText,
  TabHeaderContainer,
  UserEmailContainer,
}
