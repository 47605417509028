import { theme } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

const Wrapper = styled.div<{ isLoading: boolean }>`
  position: ${({ isLoading }) => (isLoading ? 'relative' : 'static')};
  width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 0;
`

const TextLink = styled.a`
  color: ${theme.colors['functional-info-dark'].computedValue};
  cursor: pointer;
`

export const StyledPrescriptionManagement = {
  TextLink,
  ContentWrapper,
  Wrapper,
}
