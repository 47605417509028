import { Spacer } from '@truepill/react-capsule'
import { TransferMedicationDetail } from '@vpharm-platform/shared'
import React, { useState } from 'react'

import { UpDownChevron } from '../../../../Components/AddressForms/upDownChevron'
import { TRANSFER_IN_PAGE_ENHANCEMENTS } from '../../../../constants/feature-flags'
import { useContentfulTheme } from '../../../../hooks'
import { useAnalytics } from '../../../../hooks/analytics-context'
import { useLDFlagsWithLocalStorage } from '../../../../hooks/useLDFlagsWithLocalStorage'
import TransferDetailsCard from '../TransferDetailsCard/TransferDetailsCard'
import { StyledTransferHeaderCard } from './styledComponents'

export interface BarProps {
  status?: string
  open?: boolean
}

interface Props {
  medication: TransferMedicationDetail
}

const TransferHeaderCard: React.FC<Props> = (props) => {
  const { medication } = props
  const [seeMore, setShowMore] = useState<boolean>(false)
  const getMedicationGenericName = (medicationName: string) => {
    const genericName = medicationName.match(/\(([^)]+)\)/g) || []
    return genericName[0]?.slice(1, -1)?.trim() || ''
  }
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()
  const { [TRANSFER_IN_PAGE_ENHANCEMENTS]: enhancementsFeatureFlag } = useLDFlagsWithLocalStorage([TRANSFER_IN_PAGE_ENHANCEMENTS])

  return (
    <>
      <StyledTransferHeaderCard.StyledCard
        shadow='sm'
        padding='md'
        status={medication.medicationStatus}
        open={seeMore}
        data-testid='transfer-card'
        vpTheme={theme}
      >
        <div>
          <StyledTransferHeaderCard.TitleContainer data-testid='transfer-card-med-title'>
            {enhancementsFeatureFlag ? medication.medicationName + '®' : medication.medicationName.split('®')[0] + '®'}
          </StyledTransferHeaderCard.TitleContainer>
          {medication.medicationGenericName ? (
            <StyledTransferHeaderCard.SubTitle data-testid='transfer-card-med-subtitle'>
              Generic: {medication.medicationGenericName}
            </StyledTransferHeaderCard.SubTitle>
          ) : (
            getMedicationGenericName(medication.medicationName) && (
              <StyledTransferHeaderCard.SubTitle data-testid='transfer-card-med-subtitle'>
                Generic: {getMedicationGenericName(medication.medicationName)}
              </StyledTransferHeaderCard.SubTitle>
            )
          )}
        </div>
        <StyledTransferHeaderCard.ShowMoreButton
          type='button'
          variant='primary-text'
          onClick={() => {
            trackButtonClickEvent('transfer-card-more-button', seeMore ? 'Hide Details' : 'Show Details', 'Show/Hide Rx Details')
            setShowMore(!seeMore)
          }}
          data-testid='transfer-card-more-button'
          vpTheme={theme}
        >
          {seeMore ? 'Hide Details' : 'Show Details'}
          <Spacer size='xs' />
          <UpDownChevron flipped={seeMore}></UpDownChevron>
        </StyledTransferHeaderCard.ShowMoreButton>
      </StyledTransferHeaderCard.StyledCard>
      {seeMore && <TransferDetailsCard medication={medication}></TransferDetailsCard>}
    </>
  )
}

export default TransferHeaderCard
