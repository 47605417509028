import { Text } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledHeader = styled.div`
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
`

const PrescriptionOptionText = styled(Text)<ThemedComponent & { isDisabled: boolean }>`
  color: ${({ vpTheme, isDisabled }) => (isDisabled ? vpTheme.colors.buttonDisabledLabel : 'inherit')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.6' : '1')};
`

export { ButtonsContainer, Container, PrescriptionOptionText, StyledHeader }
