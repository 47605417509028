import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const MaintenanceIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}>
    <path d='M15 44h-3V30H8v14H5a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zm28 0h-3V30h-4v14h-3a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zM12 6V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3h4zm28 0V3a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v3h4zm6 2H2a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h44a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-1 11l-9-9h9v9zm-25-9h8l16 16h-8L20 10zm8 16h-8L4 10h8l16 16zM3 17l9 9H3v-9z' />
  </svg>
)
