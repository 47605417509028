import { ThemedComponent } from '../../common/styledComponents/types'

export const CameraIcon = ({ vpTheme }: ThemedComponent): JSX.Element => (
  <svg width={17} height={17} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)' stroke={vpTheme.colors.grayWhite} strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M15.833 13.47a1.333 1.333 0 0 1-1.333 1.334h-12a1.334 1.334 0 0 1-1.333-1.333V6.138A1.333 1.333 0 0 1 2.5 4.804h2.667l1.333-2h4l1.333 2H14.5a1.333 1.333 0 0 1 1.333 1.334v7.333Z' />
      <path d='M8.5 12.137a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill={vpTheme.colors.grayWhite} transform='translate(.5 .804)' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
