import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from './common/styledComponents/types'

const BackgroundColor = styled.div<{ background?: string }>`
  width: 100%;
  background-color: ${({ background }) => background ?? defaultTheme.colors['gray-100']};
`

const WidthLimiter = styled.div<ThemedComponent>`
  max-width: 1440px;
  margin: 0 auto;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
`

export { BackgroundColor, WidthLimiter }
