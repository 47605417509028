import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const SignUpIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_18950_9226)'>
      <g clipPath='url(#clip1_18950_9226)'>
        <path
          d='M10.666 14V12.6667C10.666 11.9594 10.3851 11.2811 9.88497 10.781C9.38487 10.281 8.70659 10 7.99935 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14'
          stroke={vpTheme.colors.grayWhite}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M13.334 5.33301V9.33301' stroke={vpTheme.colors.grayWhite} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15.334 7.33301H11.334' stroke={vpTheme.colors.grayWhite} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z'
          stroke={vpTheme.colors.grayWhite}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_18950_9226'>
        <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
      </clipPath>
      <clipPath id='clip1_18950_9226'>
        <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
      </clipPath>
    </defs>
  </svg>
)
