import { SEO, Text, Toast } from '@truepill/react-capsule'
import Hero from 'Components/MarketingPages/Hero'
import { CONTACT_PATH } from 'constants/navigation-links'
import { useContentfulDynamicPage, useContentfulTheme } from 'hooks/contentful'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import MultilevelAccordion from '../../Components/Faq/MultilevelAccordion'
import TutorialVideo from '../../Components/Faq/TutorialVideo'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { MicroCopyResourceType, VideoTutorialLinksMicrocopy, VideoTutorialsMicrocopy } from '../../hooks/contentful/types/microcopy'
import { mapReferenceValuesToFields, useContentfulMicrocopy } from '../../hooks/contentful/useContentfulMicrocopy'
import { IHeroFields } from '../../types/generated/contentful'
import {
  ContactButtonContainer,
  ContactSection,
  ContactText,
  HeaderLink,
  HeaderLinks,
  MainContainer,
  StyledHeader,
  StyledSubHeader,
  StyledTutorialAccordion,
  TutorialAccordion,
  TutorialAccordionContent,
  TutorialAccordionItem,
  TutorialAccordionTrigger,
  VideosGrid,
} from './styledComponents'

const FAQPage: React.FC = () => {
  const { pageContent, error } = useContentfulDynamicPage('faq')
  const microcopy = mapReferenceValuesToFields(pageContent?.microcopy || [])
  const { microcopy: videoTutorialsMicrocopy } = useContentfulMicrocopy<VideoTutorialsMicrocopy>(MicroCopyResourceType.VideoTutorials)
  const { trackErrorShownEvent, trackLinkClickEvent } = useAnalytics()
  const [viewFAQ, setViewFAQ] = useState(true)
  const { theme } = useContentfulTheme()

  const hero = useMemo(
    () => pageContent?.content.find((ribbon) => ribbon.component.sys.contentType.sys.id === 'hero')?.component.fields as IHeroFields,
    [pageContent],
  )

  const sections = useMemo(() => pageContent?.content.filter((ribbon) => ribbon.component.sys.contentType.sys.id === 'resourceSet'), [pageContent])

  useEffect(() => {
    if (error) {
      trackErrorShownEvent('faq_page_load')
    }
  }, [error, pageContent, trackErrorShownEvent])

  const mapVideoTutorialToComponent = (tutorials: Record<string, VideoTutorialLinksMicrocopy>) => {
    const tutorialsArray = []
    for (const title in tutorials) {
      if (tutorials[title].embeddedUrl && tutorials[title].buttonImageUrl) {
        tutorialsArray.push(
          <TutorialVideo
            key={title}
            title={title}
            videoId={tutorials[title].embeddedUrl as string}
            buttonImageUrl={tutorials[title].buttonImageUrl as string}
          />,
        )
      }
    }
    return tutorialsArray
  }

  const TutorialsAccoridion = (tutorials?: VideoTutorialsMicrocopy) => {
    const tutorialSectionsArray = []
    if (!tutorials) return null
    for (const title in tutorials) {
      tutorialSectionsArray.push(
        <TutorialAccordionItem value={title} key={title}>
          <TutorialAccordionTrigger vpTheme={theme}>{title}</TutorialAccordionTrigger>
          <TutorialAccordionContent>
            <VideosGrid>{mapVideoTutorialToComponent(tutorials[title])}</VideosGrid>
          </TutorialAccordionContent>
        </TutorialAccordionItem>,
      )
    }
    return (
      <StyledTutorialAccordion vpTheme={theme}>
        <TutorialAccordion type='single' collapsible vpTheme={theme}>
          {tutorialSectionsArray}
        </TutorialAccordion>
      </StyledTutorialAccordion>
    )
  }

  return sections ? (
    <>
      <Toast
        borderLeft={true}
        color='pastel'
        className='toast-message'
        icon={true}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={'error'}
        visible={error}
      >
        <div>An error occurred while providing the required information</div>
      </Toast>
      {pageContent?.seo?.title && <SEO title={pageContent.seo.title} description={pageContent.seo.description} useDefaults />}
      {hero && <Hero {...hero} />}
      <MainContainer>
        <StyledHeader vpTheme={theme}>{microcopy.faqHeader}</StyledHeader>
        <StyledSubHeader vpTheme={theme}>{microcopy.faqSubheader}</StyledSubHeader>
        <HeaderLinks>
          <HeaderLink variant={'primary-text'} selected={viewFAQ} onClick={() => setViewFAQ(true)} vpTheme={theme}>
            FAQ
          </HeaderLink>
          {videoTutorialsMicrocopy && (
            <HeaderLink variant={'primary-text'} selected={!viewFAQ} onClick={() => setViewFAQ(false)} vpTheme={theme}>
              Video Tutorials
            </HeaderLink>
          )}
        </HeaderLinks>
        {viewFAQ ? <MultilevelAccordion sections={sections} /> : TutorialsAccoridion(videoTutorialsMicrocopy)}
        <ContactSection>
          <ContactText vpTheme={theme}>Still have questions?</ContactText>
          <ContactButtonContainer vpTheme={theme}>
            <Link to={CONTACT_PATH}>
              <ThemedButton variant={'primary'} onClick={() => trackLinkClickEvent('Get in touch', CONTACT_PATH, LinkOrigin.Body)} vpTheme={theme}>
                Get in touch
              </ThemedButton>
            </Link>
          </ContactButtonContainer>
        </ContactSection>
      </MainContainer>
    </>
  ) : (
    <Text>Loading...</Text>
  )
}

export default FAQPage
