import { useContentfulTheme } from '../../hooks'
import { StyledSuggestions } from './styledComponents'
import Suggestion from './Suggestion'

interface SuggestionsProps<T> {
  suggestionFormatter: (suggestion: T) => string
  suggestions: T[]
  handleSuggestionSelect: (e: React.SyntheticEvent, suggestion: T) => void
}

const Suggestions = <T,>({ suggestions, handleSuggestionSelect, suggestionFormatter }: SuggestionsProps<T>): React.ReactElement => {
  const { theme } = useContentfulTheme()
  return (
    <StyledSuggestions vpTheme={theme} data-testid='autocomplete-suggestions' className='suggestions'>
      {suggestions.map((suggestion, key) => (
        <Suggestion key={key} suggestion={suggestion} handleSuggestionSelect={handleSuggestionSelect} suggestionFormatter={suggestionFormatter} />
      ))}
    </StyledSuggestions>
  )
}

export default Suggestions
