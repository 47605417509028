import { datadogRum } from '@datadog/browser-rum'
import App from 'App'
import React from 'react'
import { render } from 'react-dom'
import { RecoilRoot as GlobalState } from 'recoil'
import RecoilNexus from 'recoil-nexus'

if (process.env.REACT_APP_DD_TOKEN) {
  const ddHigherEnvironmentRegex = /https:\/\/(?!.*auth)[a-z-]+\.(?:vpp\.)?(truepill|stagingpill|falsepill)\.com/g
  const ddLocalDevRegex = /http:\/\/localhost:3000/g

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID || '',
    clientToken: process.env.REACT_APP_DD_TOKEN || '',
    env: process.env.REACT_APP_VPP_ENV || 'local',
    service: process.env.REACT_APP_DD_SERVICE || 'vpharm',
    site: process.env.REACT_APP_DD_SITE || 'us5.datadoghq.com',
    version: process.env.REACT_APP_API_VERSION || undefined,

    allowedTracingUrls: [
      { match: process.env.NODE_ENV === 'development' ? ddLocalDevRegex : ddHigherEnvironmentRegex, propagatorTypes: ['tracecontext'] },
    ],
    enableExperimentalFeatures: ['feature-flags'],
    compressIntakeRequests: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    trackViewsManually: true,
  })
}

render(
  <React.StrictMode>
    <GlobalState>
      <RecoilNexus />
      <App />
    </GlobalState>
  </React.StrictMode>,
  document.getElementById('root'),
)
