import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const InfoCircleIcon = ({ ariaLabel, vpTheme }: { ariaLabel?: string } & ThemedComponent): React.ReactElement => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' aria-label={ariaLabel}>
      <g clipPath='url(#clip0_28089_7544)'>
        <g clipPath='url(#clip1_28089_7544)'>
          <path
            d='M8.00024 10.6674V8.00073M8.00024 5.33398H8.00691M14.6668 8.00065C14.6668 11.6825 11.6821 14.6673 8.00016 14.6673C4.31826 14.6673 1.3335 11.6825 1.3335 8.00065C1.3335 4.31875 4.31826 1.33398 8.00016 1.33398C11.6821 1.33398 14.6668 4.31875 14.6668 8.00065Z'
            stroke={vpTheme.colors.buttonPrimary}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_28089_7544'>
          <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
        </clipPath>
        <clipPath id='clip1_28089_7544'>
          <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
        </clipPath>
      </defs>
    </svg>
  )
}
