import { atom } from 'recoil'

export type GenericError = {
  show: boolean
}

export const genericError = atom<GenericError>({
  key: 'genericError',
  default: { show: false },
})
