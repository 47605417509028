import { FieldError } from 'react-hook-form'

export type CapsuleInputState = 'error' | 'complete' | 'default'
export type CapsuleCheckboxState = 'error' | 'default'

export const parseTextFieldStateForCapsule = (
  error: FieldError | undefined,
  isDirty: boolean | undefined,
  customError?: boolean,
): CapsuleInputState => {
  if (isDirty) {
    if (error || customError) {
      return 'error'
    } else {
      return 'complete'
    }
  }
  return 'default'
}

export const parseAddressTextFieldStateForCapsule = (
  error: FieldError | undefined,
  isDirty: boolean | undefined,
  isTouched: boolean | undefined,
): CapsuleInputState => {
  if (error) {
    return 'error'
  } else if (!error && isDirty && isTouched) {
    return 'complete'
  }
  return 'default'
}

export const parseCheckboxStateForCapsule = (error: FieldError | undefined): CapsuleCheckboxState => {
  if (error) {
    return 'error'
  }

  return 'default'
}
