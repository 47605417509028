import { FormularyMedication, GetFormularyResponse, sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

const { VPHARM_CUSTOMER_HEADER } = sharedConstants

export class FormularyService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async getFormulary(customerToken: string): Promise<FormularyMedication[]> {
    const url = `${this.baseUrl}/formulary`
    const config: AxiosRequestConfig = {
      headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
    }

    const response = await axiosAuth.get<GetFormularyResponse>(url, config)
    return response.data.formularyMedications
  }
}
