import styled from 'styled-components/macro'

export interface ISpinnerProps {
  size?: 'sm' | 'md' | 'lg' | 'auto'
  variant?: 'primary' | 'white'
  wrapperStyle?: {
    height?: string
    width?: string
  }
  backgroundColor?: string
  borderWidth?: string
}

const LoadingSpinnerWrapper = styled.div<{ wrapperStyle?: ISpinnerProps['wrapperStyle']; backgroundColor?: string; borderWidth?: string }>`
  width: ${({ wrapperStyle }) => (wrapperStyle?.width ? `${wrapperStyle.width}` : `60px`)};
  height: ${({ wrapperStyle }) => (wrapperStyle?.height ? `${wrapperStyle.height}` : `60px`)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
  z-index: 11;
  ${({ borderWidth }) =>
    borderWidth &&
    `
    div {
      border-width: ${borderWidth};
    }
  `}
`

export { LoadingSpinnerWrapper }
