import { keys } from 'constants/keyboard'
import React, { RefObject, useEffect, useRef, useState } from 'react'

import { useAnalytics } from '../../hooks/analytics-context'
import { StyledDropdown, StyledWrapper } from './styledComponents'

interface DropdownNavigationProps {
  Button: ({ onClick, isDarkMode }: { onClick: () => void; isDarkMode?: boolean }) => React.ReactElement
  NavComponent: ({ onLinkClick }: { itemColor: string; onLinkClick?: () => void }) => React.ReactElement
  background: string
  itemColor: string
}

interface NavigationDropdownProps {
  onClick: (e: MouseEvent) => void
  onKeyDown: (e: KeyboardEvent) => void
  onClose: () => void
  NavComponent: ({ onLinkClick, itemColor }: { itemColor: string; onLinkClick?: () => void }) => React.ReactElement
  background: string
  itemColor: string
}

/**
 * Component which displays navigation dropdown list when the menu button is clicked by the user.
 */
const DropdownNavigation: React.FC<DropdownNavigationProps> = ({ Button, NavComponent, background, itemColor }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef: RefObject<HTMLDivElement> = useRef(null)
  const { trackButtonClickEvent } = useAnalytics()

  const handleClose = () => setIsOpen(false)

  const handleOpen = () => {
    setIsOpen(true)
    trackButtonClickEvent('Open account management menu', '[Open from avatar]')
  }

  const onKeyDown = (e: KeyboardEvent) => e.key === keys.ESCAPE && handleClose()

  const onClick = (e: MouseEvent) => {
    const isTargetOutsideDropdown = !dropdownRef?.current?.contains(e.target as Node)

    isTargetOutsideDropdown && handleClose()
  }

  return (
    <StyledWrapper ref={dropdownRef}>
      <Button onClick={handleOpen} />
      {isOpen && (
        <NavigationDropdown
          onClick={onClick}
          onKeyDown={onKeyDown}
          onClose={handleClose}
          NavComponent={NavComponent}
          background={background}
          itemColor={itemColor}
        />
      )}
    </StyledWrapper>
  )
}

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({ onClick, onKeyDown, onClose, NavComponent, background, itemColor }) => {
  useEffect(() => {
    document.addEventListener('click', onClick)
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('click', onClick)
      document.removeEventListener('keydown', onKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledDropdown background={background}>
      <NavComponent onLinkClick={onClose} itemColor={itemColor} />
    </StyledDropdown>
  )
}

export default DropdownNavigation
