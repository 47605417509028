import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const RxIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.09771 17.8676C8.70638 17.8676 9.19543 17.3918 9.19543 16.8073V12.9944L12.5941 16.2772L10.6168 18.1768C10.1841 18.5914 10.1841 19.2609 10.6098 19.6789C10.6133 19.6823 10.6133 19.6823 10.6168 19.6857C11.0461 20.1036 11.7392 20.1036 12.1719 19.6924C12.1754 19.6891 12.1754 19.6891 12.179 19.6857L14.1457 17.7758L16.1124 19.6857C16.5417 20.1036 17.2348 20.1036 17.6675 19.6924C17.671 19.6891 17.671 19.6891 17.6746 19.6857C18.1073 19.2711 18.1073 18.6016 17.6816 18.1836C17.6781 18.1802 17.6781 18.1802 17.6746 18.1768L15.6973 16.2772L17.6746 14.3775C18.1073 13.9595 18.1073 13.2867 17.6746 12.8687C17.2418 12.4507 16.5452 12.4507 16.1124 12.8687L14.1457 14.7785L10.747 11.4957H11.3944C13.822 11.4957 15.7923 9.5927 15.7923 7.24787C15.7923 4.90305 13.8255 3 11.3979 3H8.09771C7.48905 3 7 3.47576 7 4.06027V16.8039C7 17.3918 7.48905 17.8676 8.09771 17.8676ZM9.19543 5.12394H11.3944C12.6082 5.12394 13.5933 6.07546 13.5933 7.24787C13.5933 8.42029 12.6082 9.37181 11.3944 9.37181H9.19543V5.12394Z'
      fill={vpTheme.colors.iconDefault}
    />
  </svg>
)
