import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const NasalPowder = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19220_405948)'>
        <path d='M64.8952 35.22H50.0952V51.25H64.8952V35.22Z' fill='#ffffff' />
        <path d='M65.4052 51.74H49.5952V34.72H65.3952V51.75L65.4052 51.74ZM50.6052 50.74H64.4052V35.71H50.5952V50.74H50.6052Z' fill='#0f1215' />
        <path d='M62.3548 51.2798H52.6548V57.3698H62.3548V51.2798Z' fill='#ffffff' />
        <path d='M62.855 57.8698H52.145V50.7798H62.855V57.8698ZM53.155 56.8698H61.855V51.7798H53.145V56.8698H53.155Z' fill='#0f1215' />
        <path
          d='M57.5649 12.6299C59.0449 12.6299 60.2449 13.8299 60.2449 15.3099V31.5199H54.7549V15.4399C54.7549 13.8899 56.0149 12.6299 57.5649 12.6299Z'
          fill='#ffffff'
        />
        <path
          d='M60.7449 32.0199H54.2549V15.4399C54.2549 13.6199 55.7349 12.1299 57.5649 12.1299C59.3949 12.1299 60.7449 13.5599 60.7449 15.3099V32.0199ZM55.2549 31.0199H59.7449V15.3099C59.7449 14.1099 58.7649 13.1299 57.5649 13.1299C56.2949 13.1299 55.2549 14.1699 55.2549 15.4399V31.0199Z'
          fill='#0f1215'
        />
        <path d='M66.855 31.6199H48.145V35.2199H66.855V31.6199Z' fill='#ffffff' />
        <path d='M67.355 35.7199H47.645V31.1199H67.355V35.7199ZM48.645 34.7199H66.355V32.1199H48.645V34.7199Z' fill='#0f1215' />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke='#ffffff' />
      <defs>
        <clipPath id='clip0_19220_405948'>
          <rect width='19.71' height='45.74' fill='#ffffff' transform='translate(47.645 12.1299)' />
        </clipPath>
      </defs>
    </svg>
  )
}
