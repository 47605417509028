import { SEO } from '@truepill/react-capsule'
import Ribbon from 'Components/MarketingPages/Ribbon'
import StyledToastMessage from 'Components/ToastMessage'
import { useContentfulDynamicPage } from 'hooks/contentful/useContentfulDynamicPage'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'

import Hero from '../../Components/MarketingPages/Hero'
import { useAnalytics } from '../../hooks/analytics-context'
import { IHeroFields, INotFoundPage } from '../../types/generated/contentful'

const NotFoundPage: React.FC = () => {
  const { pageContent, error } = useContentfulDynamicPage('not-found')
  const { trackErrorShownEvent } = useAnalytics()

  const hero = useMemo(
    () => pageContent?.content.find((ribbon) => ribbon.component.sys.contentType.sys.id === 'hero')?.component.fields as IHeroFields,
    [pageContent],
  )

  const sections = useMemo(
    () =>
      pageContent?.content
        .filter((ribbon) => ribbon.component.sys.contentType.sys.id !== 'hero')
        .map((ribbon) => ribbon.component as unknown as INotFoundPage),
    [pageContent],
  )

  useEffect(() => {
    if (error) {
      trackErrorShownEvent('not_found_page_load')
    }
  }, [error, trackErrorShownEvent])

  return (
    <>
      <StyledToastMessage state={'error'} visible={error}>
        <div>An error occurred while loading the site. Please try again later.</div>
      </StyledToastMessage>
      {hero && <Hero {...hero} />}
      {pageContent && (
        <MainContainer>
          {pageContent.seo?.title && <SEO title={pageContent.seo.title} description={pageContent.seo?.description} useDefaults />}
          {sections && sections.map((component, index) => <Ribbon key={`ribbon-${index}`} component={component} />)}
        </MainContainer>
      )}
    </>
  )
}

const MainContainer = styled.div`
  align-self: start;
  width: 100%;
  min-height: calc(100vh - 9.5rem);
`

export default NotFoundPage
