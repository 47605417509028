import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

const MainContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;

  ${mediaLargerThan.laptop} {
    width: 46.5rem;
    margin: auto;
  }
`

const Description = styled(Text)<ThemedComponent>`
  font-size: 1.5rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
`

const ContactContainer = styled.div<ThemedComponent>`
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-500'] ?? defaultTheme.colors['gray-500']};
`

const ContactTitle = styled(Text)<ThemedComponent>`
  font-size: 1.5rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
`

const ContactDescription = styled(Text)<ThemedComponent>`
  font-size: 1.25rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
`

const ContactOptionContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`

const ContactOption = styled.a<ThemedComponent>`
  font-size: 1rem;
  margin-left: 0.5rem;
  text-decoration: none;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};

  ${mediaLargerThan.tablet} {
    font-size: 1.25rem;
    margin-left: 1.125rem;
  }
`

const StyledHeader = styled.header<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  ${mediaLargerThan.desktopSm} {
    padding: 12px 156px;
  }
`

interface StyledLogoProps {
  maxHeight?: number
  maxHeightMobile?: number
}

const StyledLogo = styled.img<StyledLogoProps>`
  max-height: ${(props) => props.maxHeightMobile || '30'}px;
  max-width: 280px;
  object-fit: contain;

  ${mediaLargerThan.mobile} {
    max-height: ${(props) => props.maxHeight || '30'}px;
  }
`

const StyledFooter = styled.footer<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  border-top: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
`

const StyledReservedRights = styled.div`
  padding: 1.5rem;
  text-align: center;

  ${mediaLargerThan.tablet} {
    text-align: left;
  }

  ${mediaLargerThan.laptop} {
    padding: 2rem 0;
    margin: 0 9.75rem;
  }
`

const StyledTitle = styled.div`
  padding: 0 24px;

  ${mediaLargerThan.desktopSm} {
    padding: 12px 156px;
  }
`

export {
  ContactContainer,
  ContactDescription,
  ContactOption,
  ContactOptionContainer,
  ContactTitle,
  Description,
  MainContainer,
  StyledFooter,
  StyledHeader,
  StyledLogo,
  StyledReservedRights,
  StyledTitle,
}
