import { Text } from '@truepill/react-capsule'
import { PrescriptionDetailsFormValues } from '@vpharm-platform/shared'
import { ReactElement } from 'react'

import { useContentfulTheme } from '../../../hooks/contentful'
import { CloseButtonIcon, MedicationListContainer, StyledButton, StyledMedicationContainer } from './styledComponents'

interface Props {
  onDelete: (medicationName: string) => void
  prescriptions: PrescriptionDetailsFormValues[]
}

const TransferMedicationList = ({ onDelete, prescriptions }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <MedicationListContainer>
      {prescriptions.map((prescription) => (
        <StyledMedicationContainer key={prescription.medication_name} data-testid='medication-list-item' vpTheme={theme}>
          <Text css={{ alignSelf: 'center', color: theme.colors['functional-info-dark'], fontSize: '14px', paddingLeft: '1rem' }}>
            <strong>{`${prescription.medication_name}`}</strong> prescription will be transferred
          </Text>
          <StyledButton
            data-testid='delete-prescription-button'
            css={{ color: theme.colors['functional-info-dark'] }}
            variant='primary-text'
            onClick={(e) => {
              e.preventDefault()
              onDelete(prescription.medication_name)
            }}
            vpTheme={theme}
          >
            <CloseButtonIcon />
          </StyledButton>
        </StyledMedicationContainer>
      ))}
    </MedicationListContainer>
  )
}

export default TransferMedicationList
