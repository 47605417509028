import { Radio } from '@truepill/react-capsule'
import { defaultTheme, GetTransferPharmaciesResponse } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../common/styles/variables'
import { InfoTooltip } from '../../../Components/Tooltip'
import { useContentfulTheme } from '../../../hooks'
import { ToggleManualPharmacyEntry } from './styledComponents'

interface Props {
  pharmacies: GetTransferPharmaciesResponse[]
  selectedPharmacy: string | undefined
  onSelectPharmacy: (selectedPharmacy: GetTransferPharmaciesResponse) => void
}

export interface truepillPharmacyDetails {
  address: string
  npi: string
  ncpdp: string
}

const TransferInClosedPharmacy: React.FC<Props> = ({ pharmacies, onSelectPharmacy, selectedPharmacy }) => {
  const pharmacyDetails: truepillPharmacyDetails = {
    address: '3100 Diablo Ave, Hayward, CA, 94545',
    npi: '1023699550',
    ncpdp: '5674747',
  }

  const tooltipContent =
    'If your pharmacy is not listed, please have your provider send a new prescription to us directly. When asked, provide the following details, along with the name of the prescription(s):'
  const { theme } = useContentfulTheme()

  return (
    <>
      {pharmacies.map((pharmacy, index) => {
        const { pharmacy_name } = pharmacy
        return (
          <TransferPharmacyContainer
            key={index}
            ischecked={selectedPharmacy === pharmacy.pharmacy_name}
            onClick={() => onSelectPharmacy(pharmacy)}
            checkedColor={theme.colors['primary-100']}
            checkedBorder={theme.colors['primary-500']}
          >
            <PharmacyDetails>
              <PharmacyInfo>
                <StyledRadio value={pharmacy_name} label={pharmacy_name} checked={selectedPharmacy === pharmacy.pharmacy_name} />
              </PharmacyInfo>
            </PharmacyDetails>
          </TransferPharmacyContainer>
        )
      })}
      <ToggleManualPharmacyEntry type='button' variant='primary-text' vpTheme={theme}>
        <InfoTooltip
          label='Don’t see your pharmacy?'
          text={tooltipContent}
          position='right'
          testId='phone-number'
          truepillPharmacyInfo={pharmacyDetails}
          displayLabel={true}
        />
      </ToggleManualPharmacyEntry>
    </>
  )
}

const PharmacyDetails = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 150%;
  width: 100%;
`

const PharmacyInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
  }
`

const StyledRadio = styled(Radio)`
  display: flex;
`

const TransferPharmacyContainer = styled.div<{ ischecked: boolean; checkedColor?: string; checkedBorder?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ ischecked, checkedColor }) => (ischecked ? checkedColor ?? defaultTheme.colors['primary-100'] : defaultTheme.colors.white)};
  border: 1px solid
    ${({ ischecked, checkedBorder }) => (ischecked ? checkedBorder ?? defaultTheme.colors['primary-500'] : defaultTheme.colors['gray-500'])};
`

export default TransferInClosedPharmacy
