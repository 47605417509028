import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const SlashIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_31291_24835)'>
      <g clipPath='url(#clip1_31291_24835)'>
        <path
          d='M8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301C4.31826 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31826 14.6663 8.00016 14.6663Z'
          stroke={vpTheme.colors['functional-warning-dark']}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.28662 3.28711L12.7133 12.7138'
          stroke={vpTheme.colors['functional-warning-dark']}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_31291_24835'>
        <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
      </clipPath>
      <clipPath id='clip1_31291_24835'>
        <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
      </clipPath>
    </defs>
  </svg>
)
