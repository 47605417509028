import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const TransferInArrow = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='100' height='69' viewBox='0 0 100 69' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.4873 46C55.1333 55.4446 45.5822 62 34.5 62C19.3122 62 7 49.6878 7 34.5C7 19.3122 19.3122 7 34.5 7C45.1867 7 54.4497 13.0958 59.0014 22H66.6658C61.6589 9.12522 49.1451 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69C49.5215 69 62.3007 59.3997 67.0369 46H59.4873Z'
        fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1165 35.745C18.7528 34.9813 18.7528 33.0186 20.1165 32.2549L39.0232 21.6672C40.3564 20.9206 42.0004 21.8842 42.0004 23.4122V30H87.5004C89.9857 30 92.0004 32.0147 92.0004 34.5C92.0004 36.9853 89.9857 39 87.5004 39H42.0005V44.5877C42.0005 46.1157 40.3564 47.0793 39.0232 46.3327L20.1165 35.745Z'
        fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
      />
    </svg>
  )
}
