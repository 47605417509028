import { defaultTheme } from '@vpharm-platform/shared'
import LinkWithIcon from 'Components/LinkWithIcon'
import { NavigationLink } from 'interfaces/Header'

import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { NavigationClick } from '../NavigationClick'
import { StyledList, StyledTitle } from './styledComponents'

interface NavigationLinksProps {
  links: NavigationLink[]
  title?: string
  itemColor: string
  onLinkClick?: () => void
}

const renderNavigationLink = (link: NavigationLink, itemColor: string, onLinkClick?: () => void): JSX.Element => {
  switch (link.type) {
    case 'NavigationClick':
      return <NavigationClick key={link.name} link={link} />
    case 'NavigationPath':
      return <LinkWithIcon linkName={link.name} key={link.name} role='menuitem' link={link} onLinkClick={onLinkClick} textColor={itemColor} />
  }
}

/**
 * NavigationLinks component which displays a list of navigation links used by the user to navigate
 * to different parts of the site.
 */
const NavigationLinks: React.FC<NavigationLinksProps> = ({ links, title, onLinkClick, itemColor }) => {
  const { customerProfile } = useCustomerProfile()
  const { theme } = useContentfulTheme()
  const navigationLinks = links.length > 0 && customerProfile.cashOnly ? links.filter((link) => link.name !== 'Insurance') : links
  return (
    <>
      {title && (
        <StyledTitle color={theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']} variant='body-sm'>
          {title}
        </StyledTitle>
      )}
      <StyledList role='menu' color={theme.colors.navItem ?? defaultTheme.colors['primary-700']}>
        {navigationLinks.length > 0 && navigationLinks.map((link: NavigationLink) => renderNavigationLink(link, itemColor, onLinkClick))}
      </StyledList>
    </>
  )
}

export default NavigationLinks
