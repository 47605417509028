import { useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import type { IHeaderComponentFields } from '../../types/generated/contentful'

type ReturnValues = {
  loading: boolean
  error: boolean
  content?: IHeaderComponentFields
}

export const useContentfulHeaderContent = (): ReturnValues => {
  const [content, setContent] = useState<IHeaderComponentFields | undefined>()
  const { pharmCustomer, error, loading } = useCustomerConfigContext()

  useEffect(() => {
    const data = pharmCustomer?.componentConfiguration?.fields.header?.fields
    setContent(data)
  }, [pharmCustomer])

  return {
    loading,
    error,
    content,
  }
}
