import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethod } from '@stripe/stripe-js'
import { GetPaymentResponse, PaymentCard } from '@vpharm-platform/shared'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { userService } from '../services'
import PaymentService from '../services/PaymentService'
import { decideWhichIcon } from '../utils'
import { useCustomerProfile } from '.'

export interface CreatePaymentData {
  cardholderName: string
  zip: string
  isDefault: boolean
  isSaved: boolean
}

export interface UsePaymentValue {
  isError: boolean
  isLoading: boolean
  paymentMethods: Payment[]
  addPaymentMethod: (data: CreatePaymentData) => Promise<PaymentMethod | undefined>
  setDefaultPaymentMethod: (paymentMethodId: string) => Promise<boolean>
  deletePaymentMethod: (paymentMethodId: string) => Promise<boolean>
}

export type Payment = PaymentCard & { iconUrl?: string }

const useUserPayments = (): UsePaymentValue => {
  const { customerProfile } = useCustomerProfile()
  const stripe = useStripe()
  const elements = useElements()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState<Payment[]>([])

  const paymentService = useMemo(
    () => (stripe ? new PaymentService(stripe, userService, customerProfile.vpharmCustomerToken) : null),
    [customerProfile.vpharmCustomerToken, stripe],
  )

  const sortPaymentMethods = (payments: GetPaymentResponse): Payment[] => {
    return payments
      .map((payment) => ({
        ...payment,
        iconUrl: decideWhichIcon(payment.brand),
      }))
      .sort((a, b) => Number(b.default) - Number(a.default))
  }

  const fetchPayments = useCallback(async () => {
    if (!paymentService) {
      return
    }

    setIsError(false)
    setIsLoading(true)

    try {
      const payments = await paymentService.getPaymentMethods()
      setPaymentMethods(sortPaymentMethods(payments))
    } catch (err) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [paymentService])

  const addPaymentMethod = useCallback(
    async (data: CreatePaymentData) => {
      const { cardholderName, zip, isDefault, isSaved } = data
      try {
        if (!paymentService || !elements) {
          return
        }

        const card = elements.getElement('cardNumber')
        if (!card) {
          return
        }

        const paymentMethod = await paymentService.createAndAttachCardPaymentMethod(cardholderName, zip, isDefault, isSaved, card)
        fetchPayments()

        return paymentMethod
      } catch (err) {
        console.error(err)
      }
    },
    [paymentService, elements, fetchPayments],
  )

  const setDefaultPaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      setIsLoading(true)
      let result = true
      try {
        if (!paymentService) {
          return false
        }

        await paymentService.setPaymentAsDefault(paymentMethodId)
        await fetchPayments()
      } catch (err) {
        result = false
      } finally {
        setIsLoading(false)
      }
      return result
    },
    [fetchPayments, paymentService],
  )

  const deletePaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      setIsLoading(true)
      let result = true
      try {
        if (!paymentService) {
          return false
        }

        await paymentService.deletePaymentMethod(paymentMethodId)
        await fetchPayments()
      } catch (err) {
        result = false
      } finally {
        setIsLoading(false)
      }
      return result
    },
    [fetchPayments, paymentService],
  )

  useEffect(() => {
    fetchPayments()
  }, [fetchPayments])

  return {
    isLoading,
    isError,
    paymentMethods,
    addPaymentMethod,
    setDefaultPaymentMethod,
    deletePaymentMethod,
  }
}

export default useUserPayments
