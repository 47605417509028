import { AutoRefillMedicationInfo, CreateAutoRefillParams, sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface AutoRefillService {
  enroll(autoRefillParams: CreateAutoRefillParams, customerToken: string, patientToken: string): Promise<void>
  disenroll(prescriptionToken: string, customerToken: string, patientToken: string): Promise<void>
  getEligibleRxs(customerToken: string, patientToken: string): Promise<AutoRefillMedicationInfo[]>
}

class AutoRefillApiServer implements AutoRefillService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/auto_refill`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async enroll(autoRefillParams: CreateAutoRefillParams, customerToken: string, patientToken: string): Promise<void> {
    const url = new URL(`${this.baseUrl}`)

    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    await axiosAuth.post(url.toString(), autoRefillParams, config)
  }

  async disenroll(prescriptionToken: string, customerToken: string, patientToken: string): Promise<void> {
    const url = new URL(`${this.baseUrl}/${prescriptionToken}`)

    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    await axiosAuth.delete(url.toString(), config)
  }

  async getEligibleRxs(customerToken: string, patientToken: string): Promise<AutoRefillMedicationInfo[]> {
    const url = new URL(`${this.baseUrl}/eligible_prescriptions`)
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    const response = await axiosAuth.get<AutoRefillMedicationInfo[]>(url.toString(), config)
    return response.data
  }
}

export const autoRefillService: AutoRefillService = new AutoRefillApiServer()
