import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const TransferOutArrow = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='100' height='69' viewBox='0 0 100 69' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.4873 46C55.1333 55.4446 45.5822 62 34.5 62C19.3122 62 7 49.6878 7 34.5C7 19.3122 19.3122 7 34.5 7C45.1867 7 54.4497 13.0958 59.0014 22H66.6658C61.6589 9.12522 49.1451 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69C49.5215 69 62.3007 59.3997 67.0369 46H59.4873Z'
        fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.8839 35.745C100.248 34.9813 100.248 33.0186 98.8839 32.2549L79.9772 21.6672C78.644 20.9206 77 21.8842 77 23.4122V30H35C32.5147 30 30.5 32.0147 30.5 34.5C30.5 36.9853 32.5147 39 35 39H77V44.5877C77 46.1157 78.644 47.0793 79.9772 46.3327L98.8839 35.745Z'
        fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
      />
    </svg>
  )
}
