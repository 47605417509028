import LogRocket from 'logrocket'
import { useEffect, useMemo, useState } from 'react'
import { core, usAutocompletePro } from 'smartystreets-javascript-sdk'
const Lookup = usAutocompletePro.Lookup

const SmartyCore = core
const credentials = new SmartyCore.SharedCredentials(process.env.REACT_APP_SMARTY_FE_KEY ?? '')

const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud'])
const client = clientBuilder.buildUsAutocompleteProClient()

interface queryObject {
  input: string
  hasSecondaries: boolean
}

export const useAddressAutocomplete = (queryObject: queryObject): Array<usAutocompletePro.Lookup> => {
  const initValue = useMemo(() => new Lookup(''), [])
  const [addressSuggestions, setAddressSuggestions] = useState<usAutocompletePro.Lookup>(initValue)

  const queryForSuggestions = async (query: string, hasSecondaries = false): Promise<void> => {
    const lookup = new Lookup(query)
    if (hasSecondaries) {
      lookup.selected = query
    }

    try {
      const results = await client.send(lookup)
      setAddressSuggestions(results)
    } catch (error) {
      LogRocket.log('Error retrieving Address suggestions', error)
    }
  }

  useEffect(() => {
    const clearSuggestions = () => {
      setAddressSuggestions(initValue)
    }
    if (queryObject.input.length > 0) {
      queryForSuggestions(queryObject.input, queryObject.hasSecondaries)
    } else {
      clearSuggestions()
    }
  }, [queryObject, initValue])

  return [addressSuggestions, initValue]
}
