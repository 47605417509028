import { Document } from '@contentful/rich-text-types'
import { Text } from '@truepill/react-capsule'
import { OrderDetails } from '@vpharm-platform/shared'
import { useState } from 'react'

import RichTextRenderer from '../../../../Components/RichTextRenderer'
import { useContentfulTheme } from '../../../../hooks'
import {
  AdditionalMessageDetails,
  ExpandableContainer,
  Heading,
  HeadingContainer,
  IssueDetailContainer,
  Line,
  MainContainer,
  ShowLessContainer,
  ShowMoreLessButton,
} from './styledComponents'

export interface OrderMessageProps {
  text?: string
  title: string
  additionalDetails?: string
  issue?: string
  status: OrderDetails['status']
  richTextMessage?: Document
}

const OrderMessage = ({ text, title, issue, additionalDetails, status, richTextMessage }: OrderMessageProps): React.ReactElement => {
  const [showAll, setShowAll] = useState(false)
  const { theme } = useContentfulTheme()

  const showAllMessage = () => {
    setShowAll(!showAll)
  }

  const showMoreButton = text || issue || additionalDetails

  return (
    <MainContainer status={status}>
      <Line status={status} />
      <ExpandableContainer>
        <HeadingContainer>
          <Heading variant='body'>{title}</Heading>
          {showMoreButton && (
            <ShowMoreLessButton type='button' variant='primary-text' onClick={showAllMessage} vpTheme={theme}>
              <Text variant='body-sm'> {showAll ? 'Show less' : 'Show more'}</Text>
            </ShowMoreLessButton>
          )}
        </HeadingContainer>

        {showAll ? (
          <ShowLessContainer vpTheme={theme}>
            {richTextMessage ? (
              <RichTextRenderer document={richTextMessage} />
            ) : (
              <Text style={{ display: 'inline', paddingTop: '2rem' }} variant='body'>
                {text}
              </Text>
            )}

            {issue && (
              <IssueDetailContainer>
                <Text style={{ display: 'inline' }} bold variant='body'>
                  Reason:
                </Text>
                <Text style={{ display: 'inline', paddingLeft: '0.2rem' }} variant='body'>
                  {issue}
                </Text>
              </IssueDetailContainer>
            )}
            {additionalDetails && (
              <AdditionalMessageDetails>
                <Text style={{ display: 'inline' }} bold variant='body'>
                  Additional Details:
                </Text>
                <Text style={{ display: 'inline', paddingLeft: '0.2rem' }} variant='body'>
                  {additionalDetails}
                </Text>
              </AdditionalMessageDetails>
            )}
          </ShowLessContainer>
        ) : (
          <></>
        )}
      </ExpandableContainer>
    </MainContainer>
  )
}

export default OrderMessage
