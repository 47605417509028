import { Theme } from '@truepill/react-capsule'
import { useContentfulTheme } from 'hooks/contentful/useContentfulTheme'
import React from 'react'

const ThemeProvider: React.FunctionComponent = ({ children }) => {
  const { theme } = useContentfulTheme()

  return (
    <Theme
      overrides={{
        // Tooltip will have a default z-index of 11, all modal across the site have a z-index of 10
        // TODO: Revisit this implementation, or fix the capsule package because the css property on the component itself isn't propagating down.
        // NOTE: Styles for Capsule components do propagate down.
        tooltip: {
          zIndex: 11,
          backgroundColor: '$primary-700',
          color: theme.colors.grayWhite,
        },
        loadingSpinner: {
          '[data-spinner-background]': {
            borderWidth: 8,
            width: '60px',
            height: '60px',
          },
          '[data-spinner-animated-bar]': {
            borderWidth: 8,
            width: '60px',
            height: '60px',
          },
        },
        radioIndicatorContainer: {
          borderColor: '$primary-500',
          '&:checked ~ .radio-indicator': {
            borderColor: '$primary-500',
          },
        },
        radioIndicator: {
          '&:after': {
            backgroundColor: '$primary-500',
          },
        },
        checkboxIndicator: {
          backgroundColor: '$primary-500',
        },
        toggleSwitch: {
          '&[data-enabled="true"]': {
            backgroundColor: '$primary-500',
          },
        },
      }}
      theme={theme}
    >
      {children}
    </Theme>
  )
}

export default ThemeProvider
