import { CorePatient, sharedConstants } from '@vpharm-platform/shared'
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface PharmacyService {
  updatePatient(patient: PatientUpdatePayload, vpharmCustomerToken: string, vpharmPatientToken: string): Promise<void>
  getPatient(vpharmCustomerToken: string, truepillPatientToken: string): Promise<CorePatient>
  isEmailAddressAvailable(emailAddress: string, vpharmCustomerToken: string, vpharmPatientToken: string): Promise<boolean>
  emailUpdatingRequest(newEmailAddress: string, vpharmCustomerToken: string, vpharmPatientToken: string): Promise<void>
  emailUpdatingConfirmation(token: string, dob: string, vpharmCustomerToken: string): Promise<void>
}

export interface PatientUpdatePayload {
  city?: string
  communications_opt_in?: boolean
  company?: string
  email?: string
  id_image?: string
  language_preference?: 'English' | 'Spanish'
  mbi?: string
  phone?: string
  ssn_serial?: string
  ssn?: string
  state?: string
  street1?: string
  street2?: string
  zip?: string
}

class PharmacyApiService implements PharmacyService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async updatePatient(patient: PatientUpdatePayload, vpharmCustomerToken: string, vpharmPatientToken: string): Promise<void> {
    const url = `${this.baseUrl}/patient`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: vpharmPatientToken,
      },
    }

    await axiosAuth.put<void>(url, patient, config)
  }

  async getPatient(vpharmCustomerToken: string, truepillPatientToken: string): Promise<CorePatient> {
    const url = `${this.baseUrl}/patient`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: truepillPatientToken,
      },
    }
    const patientRes = await axiosAuth.get<CorePatient>(url, config)
    return patientRes.data
  }

  async isEmailAddressAvailable(emailAddress: string, vpharmCustomerToken: string, vpharmPatientToken: string): Promise<boolean> {
    const params = new URLSearchParams()
    params.set('email', emailAddress)
    const url = `${this.baseUrl}/patient/is-email-address-available?${params.toString()}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: vpharmPatientToken,
      },
    }

    const patientRes = await axiosAuth.get<{ is_available: boolean }>(url, config)
    return patientRes.data.is_available
  }

  async emailUpdatingRequest(newEmailAddress: string, vpharmCustomerToken: string, vpharmPatientToken: string): Promise<void> {
    const url = `${this.baseUrl}/patient/email-updating/request`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: vpharmPatientToken,
      },
    }

    await axiosAuth.post<void>(url, { newEmailAddress }, config)
  }

  async emailUpdatingConfirmation(token: string, dob: string, vpharmCustomerToken: string): Promise<void> {
    const url = `${this.baseUrl}/patient/email-updating/confirmation`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
      },
    }

    await axios.post<void>(url, { token, dob }, config)
  }
}

export const pharmacyService: PharmacyService = new PharmacyApiService()
