import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import { useCustomerProfile } from '../hooks'
import { adminLogin } from '../persistRecoil'
import AuthService from '../services/Auth0Service'

interface AuthUser {
  sub: string
  email: string
}

interface AuthState {
  user: AuthUser | null
  isLoading: boolean
  isAuthenticated: boolean
}

interface AuthProviderProps {
  children: React.ReactNode
}

interface GetTokenSilentlyParams {
  cacheMode?: boolean
}

export interface AuthContextProps {
  authState: AuthState
  isLogoutTrigger: boolean
  getAccessTokenSilently: (options?: GetTokenSilentlyParams) => Promise<string>
  signup: (email: string, password: string, registrationToken: string) => Promise<void>
  login: (username: string, password: string) => Promise<void>
  logout: () => void
  requestChangePassword: (email: string) => Promise<void>
  checkPassword: (password: string) => Promise<void>
}

const AuthStateContext = createContext<AuthContextProps | undefined>(undefined)

const UNAUTHENTICATED_STATE: AuthState = {
  user: null,
  isAuthenticated: false,
  isLoading: false,
}

const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement => {
  const { customerProfile } = useCustomerProfile()
  const [isAdminLogin] = useRecoilState(adminLogin)

  const getAuthParams = (): { domain: string; clientId: string; audience: string; realm: string } => {
    if (isAdminLogin) {
      if (!(process.env.REACT_APP_ADMIN_AUTH_DOMAIN && process.env.REACT_APP_ADMIN_AUTH_CLIENT_ID && process.env.REACT_APP_ADMIN_AUTH_AUDIENCE)) {
        throw new Error('Admin parameters not set')
      }

      return {
        domain: process.env.REACT_APP_ADMIN_AUTH_DOMAIN,
        clientId: process.env.REACT_APP_ADMIN_AUTH_CLIENT_ID,
        audience: process.env.REACT_APP_ADMIN_AUTH_AUDIENCE,
        // Environment variable because staging realm has a different name
        realm: process.env.REACT_APP_ADMIN_AUTH_REALM || 'Username-Password-Authentication',
      }
    }

    return {
      domain: customerProfile.auth0Domain,
      clientId: customerProfile.auth0ClientId,
      audience: customerProfile.auth0Audience,
      realm: 'Username-Password-Authentication',
    }
  }

  const { domain, clientId, audience, realm } = getAuthParams()

  const authService = useMemo(() => new AuthService(domain, clientId, audience, realm), [domain, clientId, audience, realm])
  const [authState, updateAuthState] = useState<AuthState>(UNAUTHENTICATED_STATE)
  const [isLogoutTrigger, setIsLogoutTrigger] = useState(false)

  useEffect(() => {
    const checkSession = async () => {
      updateAuthState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      try {
        await authService.getAccessTokenSilently()
        updateAuthState((prevState) => ({
          ...prevState,
          isLoading: false,
          isAuthenticated: true,
          user: authService.getUser(),
        }))
      } catch (e) {
        // User is not authenticated, or unable to refresh their access token
        updateAuthState(UNAUTHENTICATED_STATE)
      }
    }
    checkSession()
  }, [authService])

  const login = async (username: string, password: string): Promise<void> => {
    try {
      await authService.login(username, password)
      updateAuthState((prevState) => ({
        ...prevState,
        isLoading: false,
        isAuthenticated: true,
        user: authService.getUser(),
      }))
    } catch (e) {
      updateAuthState(UNAUTHENTICATED_STATE)
      throw e
    } finally {
      setIsLogoutTrigger(false)
    }
  }

  const checkPassword = async (password: string): Promise<void> => {
    const email = authService.getUser()?.email
    if (!email) {
      throw new Error('User is not authenticated')
    }

    await authService.login(email, password)
  }

  const requestChangePassword = async (email: string): Promise<void> => {
    await authService.requestChangePassword(email)
  }

  const logout = () => {
    authService.logout()
    setIsLogoutTrigger(true)
    updateAuthState(UNAUTHENTICATED_STATE)
  }

  const signup = async (username: string, password: string, registrationToken: string) => {
    await authService.signup(username, password, registrationToken)
    setIsLogoutTrigger(false)
    updateAuthState((prevState) => ({
      ...prevState,
      isAuthenticated: true,
      isLoading: false,
      user: authService.getUser(),
    }))
  }

  const getAccessTokenSilently = useCallback(
    async (options?: GetTokenSilentlyParams): Promise<string> => {
      try {
        const token = await authService.getAccessTokenSilently({
          cacheMode: options?.cacheMode ?? true,
        })

        updateAuthState((prevState) => {
          const previousUser = prevState.user
          const currentUser = authService.getUser()

          if (JSON.stringify(previousUser) === JSON.stringify(currentUser)) {
            return prevState
          }

          return {
            ...prevState,
            user: authService.getUser(),
          }
        })
        return token
      } catch (e) {
        updateAuthState(UNAUTHENTICATED_STATE)
        throw e
      }
    },
    [authService],
  )

  const value = { authState, isLogoutTrigger, login, getAccessTokenSilently, logout, signup, requestChangePassword, checkPassword }
  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
}

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('useAuth must be used inside an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
