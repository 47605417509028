import { Document } from '@contentful/rich-text-types'
import { useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { IErrorMessages, IErrorMessagesFields, IResource, IResourceFields, IResourceSet } from '../../types/generated/contentful'
import { MicroCopyResourceType } from './types/microcopy'

interface ReturnValueMicroCopy<T> {
  isError: boolean
  isLoadingContent: boolean
  microcopy: T | undefined
}

function isIResource(obj: IResourceFields | IErrorMessagesFields) {
  return 'key' in obj && ('value' in obj || 'richValue' in obj || 'referenceValue' in obj)
}

function isIErrorMessage(obj: IResourceFields | IErrorMessagesFields) {
  return 'key' in obj && 'message' in obj
}

const mapReducer = (microFields: Record<string, string | Document | IResource[] | undefined | any>, currentResource: IResource | IErrorMessages) => {
  if (isIErrorMessage(currentResource.fields)) {
    const fields = currentResource.fields as IErrorMessagesFields

    microFields[currentResource.fields.key || ''] = {
      displayDescription: fields.displayDescription,
      displayAdditionalInformation: fields.displayAdditionalInformation,
      displayMessage: fields.displayMessage,
      details: fields.details,
      message: fields.message,
      richTextMessage: fields.richTextMessage,
    }
  } else if (isIResource(currentResource.fields)) {
    const fields = currentResource.fields as IResourceFields

    microFields[currentResource.fields.key || ''] = {
      displayDescription: false,
      displayAdditionalInformation: false,
      displayMessage: false,
      message: fields.value,
    }
  }

  return microFields
}

const mapResourceSetToFields = (resourceSet: IResourceSet | undefined): any => {
  if (!resourceSet) {
    return
  }

  return resourceSet.fields.resources?.reduce(mapReducer, {})
}

export const useContentfulErrorMessage = <T>(type: MicroCopyResourceType): ReturnValueMicroCopy<T> => {
  const [microcopy, setMicrocopy] = useState<T | undefined>()
  const { pharmCustomer, error, loading: isLoadingContent } = useCustomerConfigContext()

  useEffect(() => {
    if (pharmCustomer) {
      const targetMicrocopy = pharmCustomer.microcopy?.find((resourceSet) => resourceSet.fields.type === type)
      setMicrocopy(mapResourceSetToFields(targetMicrocopy) as T)
    }
  }, [pharmCustomer, type])

  return {
    isLoadingContent,
    microcopy,
    isError: error,
  }
}
