import { Divider, Header, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../../common/styledComponents/types'

const HealthProfileContainer = styled.div`
  max-width: 744px;
  width: 100%;
  flex-grow: 1;
`
const HealthProfileSection = styled.div`
  max-width: 744px;
  width: 100%;
  padding-top: 1.25rem;
  flex-grow: 1;
`

const BorderBox = styled.div<ThemedComponent>`
  box-sizing: border-box;

  padding: 0;
  margin: 1rem 0;

  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
`

const GeneralInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.625rem 1.25rem;
  width: 100%;

  flex: none;
  order: 1;
  flex-grow: 1;
`

const Separator = styled(Divider)<{ border?: string }>`
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  width: 100%;

  border: 0.5px solid ${({ border }) => border ?? defaultTheme.colors['gray-300']};
`

const LastUpdatedText = styled(Text)<{ color: string }>`
  padding-top: 1.25rem;
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
`

const SurveyInfoBox = styled(BorderBox)<{ background?: string }>`
  background-color: ${({ background }) => background ?? defaultTheme.colors['gray-100']};
  padding: 1rem;
  line-height: 1.5em;
`

const BorderBoxHeader = styled(Header)<{ color: string; background: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-dark']};

  padding: 0.625rem 1.5rem;
  background: ${({ background }) => background ?? defaultTheme.colors['gray-300']};
`

const BorderBoxContent = styled.div`
  width: 100%;
  padding: 1.875rem 1.5rem;
`

const Container = styled.div`
  padding: 1rem;
  width: 100%;
`
const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const LabelText = styled(Text)`
  display: flex;
  padding-right: 0.125rem;
`

export {
  BorderBox,
  BorderBoxContent,
  BorderBoxHeader,
  Container,
  GeneralInformationContainer,
  HealthProfileContainer,
  HealthProfileSection,
  LabelContainer,
  LabelText,
  LastUpdatedText,
  Separator,
  SurveyInfoBox,
}
