import { PregnancyStatus } from '../../constants'

export enum HealthHistoryOptions {
  Yes = 'yes',
  No = 'no',
  NotSure = 'not sure',
}

export type PostHealthHistoryDto = {
  allergies: HealthHistoryOptions
  allergiesDetails: Array<string>
  healthConditions: HealthHistoryOptions
  healthConditionsDetails: Array<string>
  medicationsAndSupplements: HealthHistoryOptions
  medicationsAndSupplementsDetails: Array<string>
  pregnantOrLactating: HealthHistoryOptions
  pregnant?: HealthHistoryOptions
  lactating?: HealthHistoryOptions
  procreativeManagement?: HealthHistoryOptions
  pregnancyStatus?: PregnancyStatus[]
}

export type GetHealthHistoryDto = {
  id?: string
  createdAt?: string
  allergies: HealthHistoryOptions
  allergiesDetails: Array<string>
  healthConditions: HealthHistoryOptions
  healthConditionsDetails: Array<string>
  medicationsAndSupplements: HealthHistoryOptions
  medicationsAndSupplementsDetails: Array<string>
  pregnantOrLactating?: HealthHistoryOptions
  pregnant?: HealthHistoryOptions
  lactating?: HealthHistoryOptions
  procreativeManagement?: HealthHistoryOptions
  pregnancyStatus?: PregnancyStatus[]
}

export interface HealthProfile {
  dateOfBirth: string
  firstName: string
  gender: string
  id: string
  lastName: string
  patientToken: string
  phoneNumber: string
}

export interface HealthProfileResponseDto {
  healthProfiles: HealthProfile[]
}
