import { PropsWithChildren, ReactElement } from 'react'

import { useContentfulTheme } from '../../../hooks'
import { StripeElementContainerProps, StripeElementContainerStyles } from './styledComponents'

const StripeElementContainer = (props: PropsWithChildren<StripeElementContainerProps>): ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <StripeElementContainerStyles isDisabled={props.isDisabled} error={props.error} isFocus={props.isFocus} vpTheme={theme}>
      {props.children}
    </StripeElementContainerStyles>
  )
}

export default StripeElementContainer
