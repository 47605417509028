import { Text } from '@truepill/react-capsule'
import { OrderDetails } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../../common/styledComponents/types'
import { parseColorFromStatus } from '../../../../constants/orders'

const MainContainer = styled.div<{ status: OrderDetails['status'] }>`
  p {
    color: ${({ status }) => parseColorFromStatus(status)};
  }
  display: flex;
  gap: 1rem;
  margin-top: 0.625rem;
`

const Line = styled.div<{ status: OrderDetails['status'] }>`
  min-width: 0.25rem;
  background: ${({ status }) => parseColorFromStatus(status)};
  border-radius: 0.25rem;
`

const ExpandableContainer = styled.div`
  width: 100%;
`

const ShowLessContainer = styled.div<ThemedComponent>`
  button p {
    -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
  }
`
const HeadingContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  justify-content: space-between;
`

const Heading = styled(Text)`
  font-weight: 700;
`

const ShowMoreLessButton = styled(ThemedButton)`
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  align-items: center;
  font-weight: 400;
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
  width: 100px;
`

const AdditionalMessageDetails = styled.div`
  margin-top: 1.25rem;
`

const IssueDetailContainer = styled.div`
  margin-top: 1rem;
`

export {
  AdditionalMessageDetails,
  ExpandableContainer,
  Heading,
  HeadingContainer,
  IssueDetailContainer,
  Line,
  MainContainer,
  ShowLessContainer,
  ShowMoreLessButton,
}
