import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { Modal } from '../../common/styledComponents/StyledModal'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'
import { breakpoint, mediaLargerThan } from '../../common/styles/variables'

const SectionTitle = styled.div`
  display: flex;
  align-items: end;
  font-size: 1.25rem;
  font-weight: 700;

  ${mediaLargerThan.tablet} {
    padding-bottom: 1.5;
  }

  img {
    margin-right: 1rem;

    ${breakpoint.tablet} {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  ${mediaLargerThan.tablet} {
    font-size: 1.75rem;
    font-weight: 600;
  }
`

const StyledTutorialAccordion = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  padding: 2rem 2rem 5rem;
  ${mediaLargerThan.tablet} {
    padding: 3.5rem 8rem 5rem;
  }
`

const SectionAccordion = styled(Accordion)<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  // TODO: Theme color doesnt exist
  border-image: linear-gradient(to right, #00255f, #4f12d1) 100;
`

const SectionAccordionItem = styled(AccordionItem)`
  // TODO: Theme color doesnt exist
  border-image: linear-gradient(to right, #00255f, #4f12d1) 100;
`

const SectionAccordionTrigger = styled(AccordionTrigger)<ThemedComponent>`
  padding: 2rem 1.5rem 2rem 0;

  &:active {
    background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']} !important;
  }
`

const SectionAccordionContent = styled(AccordionContent)`
  padding: 0rem 0rem 2rem;
  ${mediaLargerThan.tablet} {
    padding: 0rem 1.5rem 2rem;
  }
`

const StyledAccordionTrigger = styled(AccordionTrigger)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration: underline;
  padding-left: 0rem;
  &:active {
    background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']} !important;
  }
  svg polyline {
    stroke: none;
  }

  ${mediaLargerThan.tablet} {
    font-size: 1.25rem;
  }
`

const StyledAccordionContent = styled(AccordionContent)`
  padding: 0.5rem 1.5rem 0;
  font-size: 1.25rem;

  ${mediaLargerThan.tablet} {
    padding: 0.5rem 2.5rem 0;
  }
`

const FaqContent = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  // TODO: Theme color doesnt exist
  border-image: linear-gradient(to right, #00255f, #4f12d1) 100;
`

const StyledModal = styled(Modal)`
  [data-reach-dialog-content] {
    max-width: 70rem;
  }
  [data-reach-dialog-content] {
    padding: 2.5rem;
    background-color: black;
    border-radius: 0;
  }
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 37.5rem;
  border: none;
`

const StyledButton = styled(ThemedButton)`
  background-color: ${({ vpTheme }) => vpTheme.colors.black};

  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  border-radius: 0.5rem;

  img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
  }
`

const VideoDiv = styled.div`
  display: grid;
  row-gap: 1rem;
`

const TitleButton = styled(ThemedButton)`
  height: 2rem !important;
  text-align: left !important;
  padding: 0;
  border: none;
  text-decoration: underline;
  font-size: 1.25rem;
  line-height: 1.5rem;

  ${mediaLargerThan.tablet} {
    font-size: 1.25rem;
  }
`

export {
  FaqContent,
  SectionAccordion,
  SectionAccordionContent,
  SectionAccordionItem,
  SectionAccordionTrigger,
  SectionTitle,
  StyledAccordionContent,
  StyledAccordionTrigger,
  StyledButton,
  StyledIframe,
  StyledModal,
  StyledTutorialAccordion,
  TitleButton,
  VideoDiv,
}
