import { theme } from '@truepill/react-capsule'
import { mediaLargerThan } from 'common/styles/variables'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { NavigationPath } from '../../interfaces/Header'

interface LinkWithIconProps {
  link: NavigationPath
  textColor: string
  onLinkClick?: () => void
  linkName: string
  role?: string
  testId?: string
  key?: string
}

/**
 * LinkWithIcon component to display a link along with an icon to navigate to the
 * given link.
 */
const LinkWithIcon: React.FC<LinkWithIconProps> = ({ link, textColor, onLinkClick, role, testId, linkName }: LinkWithIconProps) => {
  const { trackLinkClickEvent } = useAnalytics()
  return (
    <StyledLinkWrapper>
      <StyledLink
        onClick={() => {
          trackLinkClickEvent(linkName, link.path, LinkOrigin.Header)
          onLinkClick && onLinkClick()
        }}
        role={role}
        to={{ pathname: link.path }}
        target={link.target}
        data-testid={testId}
        aria-label={linkName}
      >
        <React.Fragment>
          {link.icon}
          <StyledSpan color={textColor}>{link.name}</StyledSpan>
        </React.Fragment>
      </StyledLink>
    </StyledLinkWrapper>
  )
}

const StyledLinkWrapper = styled.li`
  margin-top: 8px;
  border-radius: 4px;

  &:hover {
    background-color: ${theme.colors['primary-100'].computedValue};
  }
`

const StyledLink = styled(Link)`
  ${mediaLargerThan.laptop} {
    ${(props) => (props.to === '/cart' ? 'display:none' : 'display:flex')}
  }
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 8px 28px;
  color: inherit;
`

const StyledSpan = styled.span<{ color: string }>`
  margin-left: 20px;
  color: ${(props) => props.color};
  font-family: Lato;
  font-size: 16px;
  line-height: 24px;
`

export default LinkWithIcon
