import { Text } from '@truepill/react-capsule'
import { upperFirst } from 'lodash'
import React from 'react'

import { decideWhichIcon } from '../../../utils'
import CardholderAndExpiration from '../../CardholderNameExpiration'
import { ItemCard, ItemCardWrapper } from '../../Fragments/ItemCard'
import { useCheckoutContext } from '../CheckoutProvider'

export const ViewMode: React.FC = () => {
  const { selectedPayment, priceDetails } = useCheckoutContext()

  if (priceDetails.orderTotal === 0) {
    return <Text bold>Payment method is not required for $0 copay orders, however, your insurance will be billed.</Text>
  }

  if (!selectedPayment) {
    return null
  }

  return (
    <ItemCardWrapper>
      <ItemCard
        itemSubject='selected-payment-card'
        heading={`${upperFirst(selectedPayment.brand)} ending in ${selectedPayment.last4}`}
        line1={
          <CardholderAndExpiration name={selectedPayment.name} expMonth={selectedPayment.expirationMonth} expYear={selectedPayment.expirationYear} />
        }
        iconUrl={decideWhichIcon(selectedPayment.brand)}
      />
    </ItemCardWrapper>
  )
}
