export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const replaceHandlebars = (templateData: { [key: string]: string | string[] }, stringToReplace: string): string => {
  let builtString = stringToReplace

  Object.keys(templateData).forEach((objectKey: string) => {
    const templateKeyValue = templateData[objectKey]
    const search = `{{${objectKey}}}`
    const replacer = new RegExp(search, 'g')

    if (typeof templateKeyValue === 'string') {
      builtString = builtString.replace(replacer, templateKeyValue)
    }

    if (Array.isArray(templateKeyValue)) {
      const templateValue = templateKeyValue
      builtString = builtString.replace(replacer, templateValue.join(', '))
    }
  })

  return builtString
}
