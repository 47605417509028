import { Header, Text } from '@truepill/react-capsule'

import PillBannerLayout from '../../../Components/Banner/PillBannerLayout'
import { StyledRxManagerInfoBanner } from './styledComponents'

interface RxManagerInfoBannerProps {
  backgroundColor?: string
  subtext?: string
  mainHeader?: string
  iconUrl?: string
  maxWidth?: string
}

const RxManagerInfoBanner = ({ mainHeader, subtext, iconUrl, backgroundColor, maxWidth }: RxManagerInfoBannerProps): React.ReactElement => (
  <StyledRxManagerInfoBanner.RxManagerInfoBannerContainer>
    <PillBannerLayout maxWidth={maxWidth} backgroundColor={backgroundColor}>
      <StyledRxManagerInfoBanner.HeaderContainer>
        {iconUrl && <StyledRxManagerInfoBanner.Icon src={iconUrl} />}
        {mainHeader && (
          <Header variant='2xl' bold css={{ lineHeight: '1.9rem' }}>
            {mainHeader}
          </Header>
        )}
      </StyledRxManagerInfoBanner.HeaderContainer>
      <StyledRxManagerInfoBanner.ContentContainer>{subtext && <Text>{subtext}</Text>}</StyledRxManagerInfoBanner.ContentContainer>
    </PillBannerLayout>
  </StyledRxManagerInfoBanner.RxManagerInfoBannerContainer>
)

export default RxManagerInfoBanner
