export const savingsCardAccountPageDefaults = {
  cardholderIdLabel: 'Cardholder ID',
  rxBinLabel: 'Rx BIN',
  rxGroupLabel: 'Rx Group (optional)',
  rxPcnLabel: 'Rx PCN (optional)',
  addSavingsCardModalTitleText: 'Add savings card',
  addSavingsCardCardholderIdPlaceholder: 'B03100136180',
  addSavingsCardRxBinPlaceholder: '601341',
  addSavingsCardRxPcnPlaceholder: 'OHCP',
  addSavingsCardRxGroupPlaceholder: 'OH9007041',
  addSavingsCardHolderIdInlineErrorMessage: 'Cardholder ID is too long',
  addSavingsCardRxBinInlineErrorMessage: 'RX BIN is too long',
  addSavingsCardRxPcnInlineErrorMessage: 'RX PCN is too long',
  addSavingsCardRxGroupInlineErrorMessage: 'RX Group is too long',
  savingsCardModalEditModeDescriptionText: 'Add or modify the details from your prescription savings card below.',
  savingsCardModalAddNewDescriptionText: 'Add the details from your prescription savings card below.',
  savingsCardModalNonEditableDescriptionText: 'Your prescription savings card details can be found below.',
  whereToFindSavingsCardInfoHeader: 'Where can I find this?',
  whereToFindSavingsCardDetails:
    'Your savings card should include 3-4 lines of printed text, similar to those found on an insurance card. Input all available details into the corresponding fields above.',
  savingsCardOptionalText: 'Optional: Upload a photo of your savings card',
  savingsCardNotEditableText: 'The values above are system generated and cannot be modified.',
}
