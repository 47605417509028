import { defaultTheme } from '@vpharm-platform/shared'
import styled, { css } from 'styled-components/macro'

import { ThemedComponent } from '../../../common/styledComponents/types'
import { Props, TagType } from './ItemCard'

const Icon = styled.img`
  align-self: flex-start;
  margin-bottom: 0.5rem;
  min-width: 32px;
  max-height: 20px;
  width: 2rem;
  display: block;
`

const ItemCardContainer = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 720px) {
    padding-right: 1.5rem;
  }
  margin-bottom: 1.5rem;
`

export interface StyleProps {
  isDefault?: Props['isDefault']
  isSelected?: Props['isSelected']
  isHighlighted?: Props['isHighlighted']
  activeBorderKey?: Props['activeBorderKey']
}

const MainWrapper = styled.div<StyleProps & ThemedComponent>`
  background: ${(props) => (props.isDefault ? `${props.vpTheme.colors['primary-100']}` : `${props.vpTheme.colors['gray-100']}`)};
  border-radius: 8px;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
  border: ${(props) => {
    const isActive = props.activeBorderKey === 'isSelected' ? props.isSelected : props.isDefault
    const borderStyle = isActive ? `1px solid ${props.vpTheme.colors['functional-info-dark']}` : `1px solid ${props.vpTheme.colors['gray-300']}`
    return borderStyle
  }};
  ${(props) => {
    if (props.isHighlighted) {
      return {
        border: `2px solid ${props.vpTheme.colors['functional-info-dark']}`,
        'box-shadow': `0px 0px 15px 0px ${props.vpTheme.colors['functional-info-dark']}`,
      }
    }
  }}
`

const Chip = styled.div<ThemedComponent>`
  color: ${(props) => props.vpTheme.colors['functional-info-dark']};
  background: ${(props) => props.vpTheme.colors['functional-info-light']};
  border: 1px solid ${(props) => props.vpTheme.colors['primary-500']};
  border-radius: 100px;
  font-size: 11px;
  display: flex;
  font-weight: 700;
  align-items: center;
  padding: 8px;
  height: 2rem;
`

const Wrapper = styled.div<{ clickable: boolean }>`
  padding: 1rem 1rem 0;
  display: flex;
  width: 100%;
  ${(props) => props.clickable && 'cursor: pointer;'}
`

const Details = styled.div`
  padding: 0.25rem 0;
  width: 100%;
`
const IsDefaultBadge = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
`

const Options = styled.div<ThemedComponent>`
  display: flex;
  padding: 0.5rem 0.875rem;
  width: 100%;
  background: ${(props) => props.vpTheme.colors.grayWhite};
  font-size: 14px;
  border-top: 1px solid ${(props) => props.vpTheme.colors['gray-300']};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const OptionsWrap = styled.div`
  width: 70%;
`

const ButtonStyle = css`
  all: unset;
  &:focus-visible {
    outline: auto 2px -webkit-focus-ring-color;
  }
  padding: 2px;
`
const DefaultButtonStyle = css<StyleProps & ThemedComponent>`
  cursor: ${(props) => (props.isDefault ? 'not-allowed' : 'pointer')};
  margin-right: 1rem;
  color: ${(props) => (props.isDefault ? props.vpTheme.colors['gray-500'] : props.vpTheme.colors['functional-info-dark'])};
`

const DefaultButton = styled.button`
  ${ButtonStyle}
  ${DefaultButtonStyle}
`

const EditButton = styled.button<ThemedComponent>`
  ${ButtonStyle}
  cursor: pointer;
  justify-content: flex-start;
  color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
`

const DeleteWrap = styled.div`
  display: flex;
  width: 30%;
  justify-content: flex-end;
`

const DeleteButton = styled.button<StyleProps & ThemedComponent>`
  ${ButtonStyle}
  cursor: pointer;
  color: ${({ vpTheme }) => vpTheme.colors['functional-error-dark']};
`

const DetailHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CheckIconContainer = styled.div`
  margin-left: 4px;
`

const HeaderSelectedAndDefaultContainer = styled.div`
  height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SelectedAndDefaultContainer = styled.div`
  display: flex;
  align-items: center;
`

const TypeChip = styled.div<ThemedComponent & { type: TagType | undefined }>`
  padding: 4px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 700;
  display: inline-block;
  ${({ vpTheme, type = TagType.PRIMARY }) => {
    switch (type) {
      case TagType.PRIMARY:
        return `
          background-color: ${vpTheme.colors.buttonPrimary ?? defaultTheme.colors.buttonPrimary};
          color: ${vpTheme.colors.buttonPrimaryLabel ?? defaultTheme.colors.buttonPrimaryLabel};
        `
      case TagType.SECONDARY:
        return `
          background-color: ${vpTheme.colors.buttonSecondary ?? defaultTheme.colors.buttonSecondary};
          color: ${vpTheme.colors.buttonSecondaryLabel ?? defaultTheme.colors.buttonSecondaryLabel};
        `
      case TagType.SAVINGS:
        return `
          background-color: ${vpTheme.colors.rxmSavingsCardRibbonBackground ?? defaultTheme.colors.rxmSavingsCardRibbonBackground};
          color: ${vpTheme.colors.rxmSavingsCardRibbon ?? defaultTheme.colors.rxmSavingsCardRibbon};
        `
    }
  }};
`

export {
  CheckIconContainer,
  Chip,
  DefaultButton,
  DeleteButton,
  DeleteWrap,
  DetailHeaderContainer,
  Details,
  EditButton,
  HeaderSelectedAndDefaultContainer,
  Icon,
  IsDefaultBadge,
  ItemCardContainer,
  MainWrapper,
  Options,
  OptionsWrap,
  SelectedAndDefaultContainer,
  TypeChip,
  Wrapper,
}
