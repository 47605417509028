import { debounce, throttle } from 'lodash'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import SmartySDK from 'smartystreets-javascript-sdk'

import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { SMARTY_API_DEBOUNCE, SMARTY_API_THROTTLE } from '../../../config'
import { useContentfulTheme } from '../../../hooks'
import { PatientAddress } from '../../../interfaces'
import { formatAddressToSingleLine } from '../../../pages/AccountManagement/Addresses/data/addressSettings'
import { parseTextFieldStateForCapsule } from '../../../utils'
import AddressAutocomplete from '../../AddressAutocomplete'
import { AddressAutocompleteContainer } from './styledComponents'

interface AddressInputFieldAutocomplete {
  input: string
  hasSecondaries: boolean
}

interface Props {
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>
}

const AutoCompleteAddressFields = ({ setIsModified }: Props): ReactElement | null => {
  const { theme } = useContentfulTheme()

  const [addressQueryObject, setAddressQueryObject] = useState<AddressInputFieldAutocomplete>({
    input: '',
    hasSecondaries: false,
  })

  const {
    register,
    trigger,
    setValue,
    resetField,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useFormContext<PatientAddress>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetAddressQueryObject = useCallback(throttle(setAddressQueryObject, SMARTY_API_THROTTLE), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAPITrigger = useCallback(debounce(trigger, SMARTY_API_DEBOUNCE), [])

  useEffect(() => {
    return () => {
      throttledSetAddressQueryObject.cancel()
    }
  }, [throttledSetAddressQueryObject])

  useEffect(() => {
    resetField('fullAddress', { defaultValue: '' })
  }, [resetField])

  const formatSuggestion = (suggestion: SmartySDK.usAutocompletePro.Suggestion): string => {
    const street = suggestion.streetLine ? `${suggestion.streetLine} ` : ''
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : ''
    const entries = suggestion?.entries !== 0 ? `(${suggestion.entries}) ` : ''
    const city = suggestion?.city ? `${suggestion.city} ` : ''
    const state = suggestion?.state ? `${suggestion.state}, ` : ''
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : ''

    return street + secondary + entries + city + state + zip
  }

  const handleSuggestionSelect = (e: React.SyntheticEvent, suggestion: SmartySDK.usAutocompletePro.Suggestion): void => {
    e.preventDefault()
    if (suggestion.entries > 1) {
      setAddressQueryObject({
        input: formatSuggestion(suggestion),
        hasSecondaries: true,
      })
    } else {
      const addressFields = {
        address1: suggestion.streetLine,
        address2: suggestion.secondary ?? '',
        city: suggestion.city,
        state: suggestion.state,
        zip: suggestion.zipcode,
      }
      const formattedAddress = formatAddressToSingleLine(addressFields as PatientAddress)
      setValue('address1', addressFields.address1, { shouldDirty: true })
      setValue('address2', addressFields.address2, { shouldDirty: true })
      setValue('city', addressFields.city, { shouldDirty: true })
      setValue('state', addressFields.state, { shouldDirty: true })
      setValue('zip', addressFields.zip, { shouldDirty: true })
      setValue('fullAddress', formattedAddress, { shouldValidate: true })
      setAddressQueryObject({ input: '', hasSecondaries: false })
      setIsModified(true)
    }
  }

  const handlePatientAddressInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    throttledSetAddressQueryObject({
      input: e.target.value,
      hasSecondaries: false,
    })
  }

  return (
    <AddressAutocompleteContainer>
      <ThemedTextField
        data-testid='autocomplete-address-value'
        label='Street address'
        placeholder='Begin typing an address...'
        state={parseTextFieldStateForCapsule(errors.fullAddress, dirtyFields.fullAddress)}
        aria-labelledby='fullAddressError'
        autoComplete='off'
        helperText={errors.fullAddress ? 'Please start typing and select an address from the dropdown' : ''}
        required
        showRequiredIndicator
        {...register('fullAddress', {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            clearErrors('fullAddress')
            handlePatientAddressInput(e)
          },
          onBlur: async () => {
            await debouncedAPITrigger('fullAddress')
          },
        })}
        vpTheme={theme}
      />
      <AddressAutocomplete queryObject={addressQueryObject} handleSuggestionSelect={handleSuggestionSelect} />
    </AddressAutocompleteContainer>
  )
}

export default AutoCompleteAddressFields
