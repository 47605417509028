import { PregnancyResponses, SurveyResult } from '@vpharm-platform/shared'

import { LACTATION_ANSWER, PregnancyStatus, PROCREATIVE_MANAGEMENT_ANSWER } from '../../../../constants'
import { DATE_FORMAT_UI } from '../../../../constants/dateConstants'
import { HealthHistoryOptions } from '../../../../services'
import { toUtcString } from '../../../../utils/dateUtilities'

export type UracSettings = {
  allergies: string
  healthConditions: string
  medicationsAndSupplements: string
  pregnantOrLactating: string
  lastUpdated: string | null
  pregnancyStatus?: PregnancyStatus[]
}

export function buildUracSettings(healthHistory: SurveyResult | null, separateUracSurveyPregnancyFieldFF: boolean): UracSettings | null {
  if (!healthHistory) {
    return null
  }

  const lastUpdated: string = healthHistory.created_at || ''

  const specialConditions = [LACTATION_ANSWER, PROCREATIVE_MANAGEMENT_ANSWER]
  const healthConditions =
    healthHistory.health_conditions.length > 0
      ? healthHistory.health_conditions
          .split(',')
          .filter((condition) => !specialConditions.includes(condition))
          .join(',')
      : ''

  const result: UracSettings = {
    allergies: formatMessage(healthHistory.allergies),
    healthConditions: formatMessage(healthConditions),
    medicationsAndSupplements: formatMessage(healthHistory.medications),
    pregnantOrLactating: formatPregnancyResponses(healthHistory.pregnancy as PregnancyResponses, separateUracSurveyPregnancyFieldFF),
    lastUpdated: toUtcString(lastUpdated, DATE_FORMAT_UI),
  }

  if (separateUracSurveyPregnancyFieldFF) {
    const lactating = healthHistory.health_conditions.split(',').some((condition) => condition === LACTATION_ANSWER)
    const planning = healthHistory.health_conditions.split(',').some((condition) => condition === PROCREATIVE_MANAGEMENT_ANSWER)
    const pregnancyStatus = []
    if (healthHistory.pregnancy === HealthHistoryOptions.Yes) {
      pregnancyStatus.push(PregnancyStatus.Pregnant)
      if (lactating) {
        pregnancyStatus.push(PregnancyStatus.Lactating)
      }
    } else if (lactating) {
      pregnancyStatus.push(PregnancyStatus.Lactating)
      if (planning) {
        pregnancyStatus.push(PregnancyStatus.Planning)
      }
    } else if (planning) {
      pregnancyStatus.push(PregnancyStatus.Planning)
    } else {
      pregnancyStatus.push(PregnancyStatus.None)
    }
    result.pregnancyStatus = pregnancyStatus
  }

  return result
}

function formatMessage(answer: string): string {
  if (answer.length <= 0) {
    return 'No'
  }

  const details = answer.split(',')

  const result = `Yes - ${details.join(', ')}`

  return result
}

function formatPregnancyResponses(answer: PregnancyResponses, separateUracSurveyPregnancyFieldFF: boolean): string {
  switch (answer) {
    case PregnancyResponses.yes:
      return 'Yes'
    case PregnancyResponses.no:
      return 'No'
    case PregnancyResponses.notSure:
      return separateUracSurveyPregnancyFieldFF ? 'No' : 'Not sure'
  }
}
