import React from 'react'

import { ThemedComponent } from '../../../common/styledComponents/types'

export const CheckedCircle = ({ ariaLabel, vpTheme }: { ariaLabel?: string } & ThemedComponent): React.ReactElement => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' aria-label={ariaLabel}>
      <g clipPath='url(#clip0_19436_307568)'>
        <path
          d='M16.0013 29.3334C23.3651 29.3334 29.3346 23.3639 29.3346 16.0001C29.3346 8.63628 23.3651 2.66675 16.0013 2.66675C8.63751 2.66675 2.66797 8.63628 2.66797 16.0001C2.66797 23.3639 8.63751 29.3334 16.0013 29.3334Z'
          fill='#006FC3'
        />
        <path
          d='M21.3346 12.3333L14.0013 19.6666L10.668 16.3333'
          stroke={vpTheme.colors.grayWhite}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_19436_307568'>
          <rect width='32' height='32' fill={vpTheme.colors.grayWhite} />
        </clipPath>
      </defs>
    </svg>
  )
}
