import styled from 'styled-components/macro'

const StyledTooltipContainer = styled.div`
  margin-bottom: 0.5rem;
`

const CenteredElements = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

export { CenteredElements, StyledTooltipContainer }
