import { WebAuth } from 'auth0-js'

interface IAuthService {
  login: (username: string, password: string) => Promise<void>
  getAccessTokenSilently: () => Promise<string>
  getUser: () => User | null
  logout: () => void
  signup: (username: string, password: string, registrationToken: string) => Promise<void>
  requestChangePassword: (email: string) => Promise<void>
}

const AUTH_TOKENS_KEY = 'auth_tokens'

interface AccessToken {
  exp: number
}

interface IdToken {
  sub: string
  email: string
}

interface User {
  sub: string
  email: string
}

interface SuccessOAuthResponse {
  accessToken: string
  refreshToken: string
  idToken: string
}

interface GetTokenParams {
  cacheMode: boolean
}

function parseJwt<T>(token: string): T {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

class NoAccessTokenError extends Error {
  constructor(msg: string) {
    super(msg)
    this.name = 'NoAccessTokenError'
  }
}

class ExchangeRefreshTokenError extends Error {
  constructor(msg: string) {
    super(msg)
    this.name = 'ExchangeRefreshTokenError'
  }
}

class NoRefreshTokenError extends Error {
  constructor(msg: string) {
    super(msg)
    this.name = 'NoRefreshTokenError'
  }
}

class SignUpEmailExistsError extends Error {
  constructor(msg: string) {
    super(msg)
    this.name = 'SignUpEmailExistsError'
  }
}

const ONE_MINUTE_IN_MILLISECONDS = 60000

class AuthService implements IAuthService {
  private authClient: WebAuth

  constructor(domain: string, clientId: string, audience: string, private realm: string) {
    this.authClient = new WebAuth({
      domain: domain,
      clientID: clientId,
      audience,
      responseType: 'token id_token',
      scope: 'openid offline_access profile email',
    })
  }

  getUser(): User | null {
    const idToken = this.getSession()?.idToken

    if (!idToken) {
      return null
    }

    const userInfo = parseJwt<IdToken>(idToken)

    return {
      sub: userInfo.sub,
      email: userInfo.email,
    }
  }

  async getAccessTokenSilently(getTokenParams?: GetTokenParams): Promise<string> {
    const accessToken = this.getSession()?.accessToken

    if (!accessToken) {
      throw new NoAccessTokenError('Unable to get access token silently, missing access token')
    }

    const tokenInfo = parseJwt<AccessToken>(accessToken)
    const tokenExpirationTime = tokenInfo.exp * 1000 - ONE_MINUTE_IN_MILLISECONDS
    const isTokenExpired = new Date().getTime() > tokenExpirationTime

    const shouldGetTokenFromCache = getTokenParams?.cacheMode ?? true

    if (isTokenExpired || !shouldGetTokenFromCache) {
      let oauthResponse: SuccessOAuthResponse
      const refreshToken = this.getSession()?.refreshToken

      if (!refreshToken) {
        throw new NoRefreshTokenError('No refresh token to exchange')
      }

      try {
        oauthResponse = await this.exchangeRefreshTokenForAuth(refreshToken)
        this.setSession(oauthResponse.accessToken, oauthResponse.refreshToken, oauthResponse.idToken)
        return oauthResponse.accessToken
      } catch (e: unknown) {
        this.removeSession()
        throw new ExchangeRefreshTokenError('Unable to exchange refresh token')
      }
    }

    return accessToken
  }

  async login(username: string, password: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.authClient.client.login(
        {
          username,
          password,
          realm: this.realm,
        },
        (err: unknown, result: SuccessOAuthResponse) => {
          if (err) {
            const auth0Error = err as { description: string }
            reject(new Error(auth0Error.description))
          } else {
            this.setSession(result.accessToken, result.refreshToken, result.idToken)
            resolve()
          }
        },
      )
    })
  }

  async requestChangePassword(email: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.authClient.changePassword({ email, connection: this.realm }, (err: unknown) => {
        if (err) {
          reject(new Error('Something went wrong with requesting a password change, please try again'))
        } else {
          resolve()
        }
      })
    })
  }

  async signup(email: string, password: string, registrationToken: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.authClient.signupAndAuthorize(
        {
          connection: this.realm,
          email,
          password,
          userMetadata: { identityVerificationToken: registrationToken },
        },
        (err: unknown, result: SuccessOAuthResponse) => {
          if (err) {
            if ((err as { code: string }).code === 'user_exists') {
              reject(
                new SignUpEmailExistsError(
                  'An account has already been created with this email address. If you are signing up for the first time, please use a different email address.',
                ),
              )
            } else {
              reject(new Error('Unable to create account, please try again later.'))
            }
          } else {
            this.setSession(result.accessToken, result.refreshToken, result.idToken)
            resolve()
          }
        },
      )
    })
  }

  logout(): void {
    this.removeSession()
  }

  private setSession(accessToken: string, refreshToken: string, idToken: string): void {
    localStorage.setItem(
      AUTH_TOKENS_KEY,
      JSON.stringify({
        accessToken,
        refreshToken,
        idToken,
      }),
    )
  }

  private removeSession(): void {
    localStorage.removeItem(AUTH_TOKENS_KEY)
  }

  private getSession(): SuccessOAuthResponse | null {
    const stringifiedTokenKeys = localStorage.getItem(AUTH_TOKENS_KEY)
    if (!stringifiedTokenKeys) {
      return null
    }

    return JSON.parse(stringifiedTokenKeys)
  }

  private async exchangeRefreshTokenForAuth(refreshToken: string): Promise<SuccessOAuthResponse> {
    return new Promise((resolve, reject) => {
      this.authClient.client.oauthToken(
        {
          refresh_token: refreshToken,
          grantType: 'refresh_token',
        },
        (error: unknown, result: SuccessOAuthResponse) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        },
      )
    })
  }
}

export default AuthService
