import { Box, Header, Spacer, Text } from '@truepill/react-capsule'
import React from 'react'
import styled from 'styled-components/macro'

import { IGalleryCardFields } from '../../../types/generated/contentful'

interface IGalleryCardProps extends IGalleryCardFields {
  width?: string
  height?: string
}

const GalleryCard: React.FC<IGalleryCardProps> = ({ name, image, title, subtitle, borderRadius, width, height }) => {
  return (
    <Box
      key={name}
      css={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center',
        height: height,
        width: width,
      }}
    >
      <Spacer size='xl' />
      <ImageContainer imageUrl={image.fields.file.url} borderRadius={borderRadius || 0}></ImageContainer>
      <Spacer />
      {title && <Header>{title}</Header>}
      {subtitle && <Text>{subtitle}</Text>}
    </Box>
  )
}

const ImageContainer = styled.div`
  background: url(${(props: { imageUrl: string; borderRadius: number }) => props.imageUrl}) center center;
  display: flex;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: ${(props: { imageUrl: string; borderRadius: number }) => props.borderRadius}px;
`

export default GalleryCard
