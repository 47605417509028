import { OrderInfo } from '@vpharm-platform/shared'
import { useCallback, useEffect, useState } from 'react'

import { orderService } from '../services'

interface UseOrdersProps {
  customerToken: string
  patientToken: string
}

interface UseOrdersReturnType {
  orders: OrderInfo[]
  totalOrders: number
  isLoading: boolean
  isFetchingMore: boolean
  showErrorToast: boolean
  showError: boolean
  setShowErrorToast: (show: boolean) => void
  handleRetryButtonClick: () => void
  fetchMoreOrders: () => void
}

const LIMIT = 10

export const useOrders = ({ customerToken, patientToken }: UseOrdersProps): UseOrdersReturnType => {
  const [orders, setOrders] = useState<OrderInfo[]>([])
  const [totalOrders, setTotalOrders] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)
  const [error, setError] = useState<{ showToast: boolean; message: string | null }>({
    showToast: false,
    message: null,
  })

  const fetchOrders = useCallback(
    async ({ skip = 0, limit = LIMIT, isInitialLoad = false }) => {
      const requestParams = { customerToken, patientToken, skip, limit }
      if (isInitialLoad) setIsLoading(true)
      else setIsFetchingMore(true)

      try {
        const { orders: newOrders, totalOrders: newTotal } = await orderService.getOrders(requestParams)
        setOrders((prevOrders) => (isInitialLoad ? newOrders : [...prevOrders, ...newOrders]))
        setTotalOrders(newTotal)
        setError({ showToast: false, message: null })
      } catch (error) {
        setError({ showToast: true, message: 'Error retrieving orders' })
      } finally {
        if (isInitialLoad) setIsLoading(false)
        else setIsFetchingMore(false)
      }
    },
    [customerToken, patientToken],
  )

  useEffect(() => {
    fetchOrders({ isInitialLoad: true })
  }, [fetchOrders])

  const handleRetryButtonClick = useCallback(() => {
    fetchOrders({ isInitialLoad: true })
  }, [fetchOrders])

  const fetchMoreOrders = useCallback(() => {
    if (!isFetchingMore && orders.length < totalOrders) {
      fetchOrders({ skip: orders.length, limit: 10 })
    }
  }, [fetchOrders, isFetchingMore, orders.length, totalOrders])

  return {
    orders,
    totalOrders,
    isLoading,
    isFetchingMore,
    showErrorToast: error.showToast,
    showError: Boolean(error.message),
    setShowErrorToast: (show: boolean) => setError((prev) => ({ ...prev, showToast: show })),
    handleRetryButtonClick,
    fetchMoreOrders,
  }
}
