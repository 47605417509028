import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { useAuth } from '../../context/auth-context'
import { useResetBeforeLogout } from '../../hooks/useResetBeforeLogout'

export interface UseSessionTimeout {
  showModal: boolean
  showToastMessage: boolean

  closeToastMessage: () => void
  closeModalHandler: () => void
}

const minutesToMilliseconds = (minutes: number) => {
  return minutes * 60 * 1000
}

const LOGOUT_IN_MINUTES = minutesToMilliseconds(20)
const PROMPT_BEFORE_LOGOUT_IN_MINUTES = minutesToMilliseconds(2)

export const useSessionTimeout = (): UseSessionTimeout => {
  const {
    authState: { isAuthenticated },
    logout,
  } = useAuth()

  const [showModal, setShowModal] = useState(false)
  const [showToastMessage, setShowToastMessage] = useState(false)
  const { resetBeforeLogout } = useResetBeforeLogout()

  const onPrompt = () => {
    setShowModal(true)
  }

  const onIdle = () => {
    setShowToastMessage(true)
    setShowModal(false)
    resetBeforeLogout()
    logout()
  }

  const { activate: resetTimer } = useIdleTimer({
    timeout: LOGOUT_IN_MINUTES,
    promptBeforeIdle: PROMPT_BEFORE_LOGOUT_IN_MINUTES,
    disabled: !isAuthenticated,
    onPrompt,
    onIdle,
    crossTab: true,
    syncTimers: 200,
  })

  const closeModalHandler = () => {
    setShowModal(false)
    resetTimer()
  }

  const closeToastMessage = () => {
    setShowToastMessage(false)
  }

  return {
    showModal,
    showToastMessage,
    closeModalHandler,
    closeToastMessage,
  }
}
