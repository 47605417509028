import { RumRoute } from 'datadog-react'
import { ComponentType } from 'react'

import withAuthRequired from '../Components/Hoc/withAuthRequired'

interface ProtectedRouteProps {
  component: ComponentType<any>
}

const ProtectedRoute: any = ({ component, ...args }: ProtectedRouteProps) => <RumRoute component={withAuthRequired(component)} {...args} />

export default ProtectedRoute
