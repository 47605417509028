import { useWindowDimensions } from 'hooks/useWindowDimensions'
import React from 'react'
import { Link } from 'react-router-dom'

import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { IHeroFields } from '../../types/generated/contentful'
import { CallToAction, ContentContainer, Headline, HeroContainer, Image, ImageContainer, Subtitle } from './styledComponents'

const Hero: React.FC<IHeroFields> = ({ headline, image, subtitle, callToActionPath, callToActionText }) => {
  const { isLargerThanLaptop, width: windowWidth } = useWindowDimensions()
  const url = `${process.env.PUBLIC_URL}/contact_us_header.svg`
  const { trackLinkClickEvent } = useAnalytics()
  const { theme: vpTheme } = useContentfulTheme()

  return (
    <HeroContainer backgroundImage={url}>
      {/* First column */}
      {image?.fields.file.url && (
        <ImageContainer>
          <Image imageUrl={`https:${image?.fields.file.url}`} windowWidth={windowWidth} />
        </ImageContainer>
      )}

      {/* Second column */}
      <ContentContainer hasImage={image?.fields.file.url}>
        <>
          <Headline variant={isLargerThanLaptop ? '6xl' : '5xl'} vpTheme={vpTheme}>
            {headline}
          </Headline>
          {subtitle && <Subtitle variant={isLargerThanLaptop ? '4xl' : '2xl'}>{subtitle}</Subtitle>}
          {callToActionPath && callToActionText && (
            <Link to={callToActionPath || ''}>
              <CallToAction onClick={() => trackLinkClickEvent(callToActionText, callToActionPath, LinkOrigin.Header)} vpTheme={vpTheme}>
                {callToActionText}
              </CallToAction>
            </Link>
          )}
        </>
      </ContentContainer>
    </HeroContainer>
  )
}

export default Hero
