import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

export const IdCardIcon = ({ vpTheme }: ThemedComponent): JSX.Element => (
  <IconContainer>
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' x={0} y={0} height={'100%'} viewBox='0 0 824 500' xmlSpace='preserve'>
      <style>{`.st1{fill:${vpTheme.colors['gray-500'] ?? defaultTheme.colors['gray-500']}}`}</style>
      <path
        d='M36.68-.17h750.65c20.22 0 36.62 16.4 36.62 36.62v426.76c0 20.22-16.4 36.62-36.62 36.62H36.68c-20.22 0-36.62-16.4-36.62-36.62V36.45C.06 16.23 16.45-.17 36.68-.17z'
        style={{
          fill: vpTheme.colors['gray-500'] ?? defaultTheme.colors['gray-500'],
        }}
      />
      <path
        className='st1'
        d='M.06 36.45C.06 16.23 16.46-.17 36.68-.17h750.65c20.23 0 36.62 16.4 36.62 36.62v68.64H.06V36.45zM46.61 167.85h251.01v251.01H46.61zM342.16 188.09h412.96v32.39H342.16zM344.18 248.82h281.38v32.39H344.18zM342.16 317.64h412.96v32.39H342.16zM344.18 378.37h366.4v32.39h-366.4z'
      />
    </svg>
  </IconContainer>
)

const IconContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
