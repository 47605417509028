import IdcCodes from './idcCodes'
import PrescriberAddress from './prescriberAddress'

export default class PrescriptionInfo {
  date_written!: string
  expiration_date!: string
  days_supply!: string
  is_refill!: string
  last_filled_date?: string
  medication_sig!: string
  number_of_refills_allowed!: string
  origin!: string
  prescribed_brand_name!: string
  prescribed_drug_strength!: string
  prescribed_generic_name!: string
  prescribed_ndc!: string
  prescribed_quantity!: string
  prescribed_written_name!: string
  prescriber!: string
  prescriber_address!: PrescriberAddress
  prescriber_npi!: string
  prescriber_order_number!: string
  quantity_remaining!: string
  refills_remaining!: string
  rx_number!: string
  prescription_token!: string
  notes!: string
  icd_codes!: IdcCodes
  is_daw!: string
  fillable!: string
  dea_schedule!: number
  original_prescribed_ndc!: string
  date_filled_utc!: string
}
