import { BannerStateNoError, Header, Spacer, Text } from '@truepill/react-capsule'
import { CheckIcon } from 'assets/Icons'
import { mediaLargerThan } from 'common/styles/variables'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { governmentIDImage } from 'recoil/atoms'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'
import { BannerUploadId, BannerUploadIdProps } from './BannerUploadId'
import { UploadImageOrTakePhoto } from './UploadImageOrTakePhoto'

const GovernmentUploadId = (): JSX.Element => {
  const { theme } = useContentfulTheme()
  const history = useHistory()

  const [image, setImage] = useRecoilState(governmentIDImage)

  const [isBannerVisible, SetIsBannerVisible] = useState(false)
  const [bannerText, setBannerText] = useState('')
  const [bannerState, setBannerState] = useState('success' as BannerStateNoError)

  const bannerUploadIdProps: BannerUploadIdProps = {
    isBannerVisible,
    setIsBannerVisible: SetIsBannerVisible,
    bannerText,
    setBannerText,
    bannerState,
    setBannerState,
  }

  const backButtonPressed = () => {
    history.push('/cart')
  }

  const nextButtonPressed = () => {
    history.push('/checkout/patient-urac')
  }

  return (
    <>
      <GovernmentUploadIdContainer>
        <BannerUploadId {...bannerUploadIdProps} />
        <Spacer size='lg' />
        <Header variant='4xl'>Government ID</Header>
        <Spacer size='md' />
        <Text variant='body'>
          We require a clear photo of your U.S. government-issued ID to verify your identity when dispensing controlled substances. Please upload a
          .png or .jpg file or take a photo.
        </Text>
        <Spacer size='md' />
        <UploadImageOrTakePhoto image={image} setImage={setImage} bannerUploadIdProps={bannerUploadIdProps} />
        <Spacer size='lg' />
        <Header bold variant='xl'>
          Please confirm:
        </Header>
        <Spacer size='sm' axis='horizontal' />
        <CheckIconAndContent>This ID is valid and unexpired</CheckIconAndContent>
        <CheckIconAndContent>This ID is a driver’s license, passport, military ID, or permanent resident card </CheckIconAndContent>
        <CheckIconAndContent>The name, photograph, and date of birth are clear and legible</CheckIconAndContent>
        <Spacer size='lg' axis='vertical' />
        <ButtonsContainer>
          <ThemedButton type='button' variant='primary-text' onClick={backButtonPressed} vpTheme={theme}>
            Back
          </ThemedButton>
          <ThemedButton disabled={image === ''} type='button' onClick={nextButtonPressed} vpTheme={theme}>
            Continue
          </ThemedButton>
        </ButtonsContainer>
        <Spacer size='xl' />
      </GovernmentUploadIdContainer>
    </>
  )
}

const GovernmentUploadIdContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;

  ${mediaLargerThan.tablet} {
    width: 46.5rem;
  }
`

const CheckIconAndContent = ({ children }: { children: string }): JSX.Element => {
  const { theme } = useContentfulTheme()
  return (
    <CheckIconAndContentContainer>
      <CheckIcon vpTheme={theme} />
      <Spacer size='xs' />
      <Text>{children}</Text>
    </CheckIconAndContentContainer>
  )
}

const CheckIconAndContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  svg {
    flex-shrink: 0;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`
export default GovernmentUploadId
