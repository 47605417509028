import React from 'react'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'
import { useContentfulTheme } from '../../hooks'

const Layout: React.FunctionComponent = ({ children }) => {
  const { theme } = useContentfulTheme()
  return (
    <>
      <Main vpTheme={theme}>{children}</Main>
    </>
  )
}

export const Main = styled.div<ThemedComponent>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(100vh - 4.5rem);
  background-color: ${({ vpTheme }) => vpTheme.colors.pageBackground};
`

export default Layout
