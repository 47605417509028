import { PaymentMethod } from '@stripe/stripe-js'
import { BannerAlert, Checkbox, Spacer, Text, Toggle } from '@truepill/react-capsule'
import { useState } from 'react'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../hooks'
import type { CreatePaymentData } from '../../../hooks/useUserPayments'
import CreditCardCvv from '../../CreditCard/CreditCardCvv'
import CreditCardExpiration from '../../CreditCard/CreditCardExpiration'
import CreditCardNumber from '../../CreditCard/CreditCardNumber'
import LoadingAnimation from '../../LoadingAnimation'
import {
  ButtonGroup,
  CvvExpWrapper,
  FormContainer,
  LoadingAnimationWrapper,
  OptionalSaveContainer,
  PaymentTextField,
  ReimbursementText,
  StyledCheckbox,
  StyledForm,
  StyledSavePaymentContainer,
  StyledToggleContainer,
  TextFieldContainer,
  ZipWrapper,
} from './styledComponents'
import usePaymentForm from './usePaymentForm'

interface Props {
  forceDefault?: boolean
  askDefault?: boolean
  askShouldSave?: boolean
  onCancel?: () => void
  onSubmit: (data: CreatePaymentData) => Promise<PaymentMethod | undefined>
}

const PaymentForm: React.FC<Props> = ({ forceDefault, askDefault, askShouldSave, onCancel, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const {
    allCompleted,
    cardholderName,
    nameIsValid,
    isDefault,
    isSaved,
    zip,
    zipIsValid,
    handleBlur,
    onDefaultCheckedChange,
    onSavedCheckedChange,
    onNameChange,
    onZipChange,
    setCardFieldCompleted,
  } = usePaymentForm()

  const { theme } = useContentfulTheme()

  const handleSubmitForm = async () => {
    setIsError(false)
    setIsSubmitting(true)
    const paymentMethod = await onSubmit({ cardholderName: cardholderName.trim(), zip, isDefault: isDefault || (!!forceDefault && isSaved), isSaved })
    if (!paymentMethod) {
      setIsError(true)
      setIsSubmitting(false)
    }
  }

  return (
    <FormContainer>
      {isSubmitting && (
        <LoadingAnimationWrapper vpTheme={theme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      {isError && (
        <div>
          <BannerAlert state='error'>
            <Text>There was an error saving payment method</Text>
          </BannerAlert>
          <Spacer size='lg' />
        </div>
      )}
      <StyledForm
        data-testid='add-payment-form'
        noValidate={true}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <CreditCardNumber label='Card number' onChange={(isComplete: boolean) => setCardFieldCompleted(isComplete, 'ccNumber')} />
        <Spacer size='md' />
        <PaymentTextField
          aria-label='cardholder name'
          data-testid='cardholder-name-text-field'
          label='Cardholder name'
          onBlur={() => handleBlur('cardholderName')}
          onChange={onNameChange}
          placeholder='Cardholder name'
          required
          showRequiredIndicator
          state={nameIsValid ? 'default' : 'error'}
          helperText={!nameIsValid ? 'Invalid name' : ''}
          value={cardholderName}
          vpTheme={theme}
        />
        <Spacer size='md' />
        <TextFieldContainer>
          <CvvExpWrapper>
            <CreditCardExpiration onChange={(isComplete: boolean) => setCardFieldCompleted(isComplete, 'expDate')} />
          </CvvExpWrapper>
          <CvvExpWrapper>
            <CreditCardCvv onChange={(isComplete: boolean) => setCardFieldCompleted(isComplete, 'cvv')} />
          </CvvExpWrapper>
          <ZipWrapper>
            <PaymentTextField
              aria-label='zip'
              data-testid='zip-text-field'
              label='ZIP code'
              onBlur={() => handleBlur('zip')}
              onChange={onZipChange}
              placeholder='ZIP code'
              required
              showRequiredIndicator
              state={zipIsValid ? 'default' : 'error'}
              helperText={!zipIsValid ? 'Invalid ZIP code' : ''}
              value={zip}
              vpTheme={theme}
            />
          </ZipWrapper>
        </TextFieldContainer>
        {askShouldSave ? (
          <>
            <Spacer size='lg' />
            <OptionalSaveContainer vpTheme={theme}>
              <StyledSavePaymentContainer vpTheme={theme}>
                <Checkbox
                  aria-label='save payment method'
                  disabled={isDefault}
                  checked={isSaved}
                  onCheckedChange={onSavedCheckedChange}
                  indicatorCss={{
                    backgroundColor: '$primary-500',
                  }}
                />
                <Text variant='body'>Save payment method for a future purchase</Text>
              </StyledSavePaymentContainer>
              {askDefault && !forceDefault && (
                <StyledToggleContainer vpTheme={theme}>
                  <Text variant='body'>Set as default</Text>
                  <Toggle onChange={onDefaultCheckedChange} checked={isDefault} />
                </StyledToggleContainer>
              )}
            </OptionalSaveContainer>
          </>
        ) : (
          askDefault &&
          !forceDefault && (
            <>
              <Spacer size='lg' />
              <StyledCheckbox>
                <Checkbox
                  aria-label='set as default payment'
                  onCheckedChange={onDefaultCheckedChange}
                  indicatorCss={{
                    backgroundColor: '$primary-500',
                  }}
                />
                <Text>Set as default payment method</Text>
              </StyledCheckbox>
            </>
          )
        )}
        <Spacer size='xl' />
        <ReimbursementText vpTheme={theme}>
          Not all FSA/HSA cards are accepted, however you will have access to an order receipt that can be used for reimbursement.
        </ReimbursementText>
        <Spacer size='xl' />
        <ButtonGroup>
          {onCancel && (
            <ThemedButton data-testid='payment-modal-cancel' type='button' name='cancel' variant='primary-text' onClick={onCancel} vpTheme={theme}>
              Cancel
            </ThemedButton>
          )}
          <ThemedButton
            type='submit'
            name='save'
            disabled={!allCompleted}
            data-testid='payment-modal-save'
            onClick={handleSubmitForm}
            vpTheme={theme}
          >
            Save
          </ThemedButton>
        </ButtonGroup>
      </StyledForm>
    </FormContainer>
  )
}

export default PaymentForm
