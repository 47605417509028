import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const PillsIcon = ({ vpTheme, colorOverride }: ThemedComponent & { colorOverride?: string }): React.ReactElement => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      stroke={colorOverride ?? vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
      strokeWidth='1.75'
      strokeMiterlimit='10'
    >
      <path d='M18.451 29.206l10.706-11.863' />
      <path d='M8.453 7.953l6.562 6.562m8.797-3.5A7 7 0 0 0 12.551 3.05l-9 9a7 7 0 0 0 7.965 11.262' strokeLinecap='square' />
      <path d='M23.5 31a8 8 0 1 0 0-16 8 8 0 1 0 0 16z' />
    </svg>
  )
}
