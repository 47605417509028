import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Text } from '@truepill/react-capsule'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import StyledToastMessage from '../../Components/ToastMessage'
import {
  HIPAA_PRACTICES_PATH,
  INFORMED_CONSENT_PATH,
  PRESCRIPTION_MANAGEMENT_PATH,
  PRIVACY_POLICY_PATH,
  SIGN_UP_VALIDATE_ON_SUBMIT,
  TERMS_OF_SERVICE_PATH,
} from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import { parseTextFieldStateForCapsule } from '../../utils'
import { SignUpInfo } from '../../utils/validation'
import { StyledSignUp } from './styledComponent'
import { SignUpFields } from './types'
import { useSignUp } from './useSignUp'

interface PasswordSpecsState {
  atLeastEightCharacters: boolean
  hasLowerCase: boolean
  hasUpperCase: boolean
  hasNumber: boolean
  hasSpecialCharacter: boolean
}

const hasUpperCaseRegex = /[A-Z]/
const hasLowerCaseRegex = /[a-z]/
const hasNumberRegex = /[0-9]/
const hasSpecialSymbolRegex = /[^A-Za-z0-9]/

const SignUp = (): React.ReactElement => {
  const { [SIGN_UP_VALIDATE_ON_SUBMIT]: validateOnSubmit } = useLDFlagsWithLocalStorage([SIGN_UP_VALIDATE_ON_SUBMIT])

  if (validateOnSubmit) {
    return <SignUpNew />
  } else {
    return <SignUpOld />
  }
}

const SignUpOld = (): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields, isValid: isSignUpFormValid, touchedFields, isSubmitting },
    watch,
    clearErrors,
    trigger,
  } = useForm<SignUpFields>({
    delayError: 200,
    resolver: yupResolver(SignUpInfo),
    defaultValues: {
      password: '',
      email: '',
      confirmEmail: '',
      confirmPassword: '',
    },
    mode: 'onBlur',
  })
  const { signUp, handleConsentChange, hasConsented, registrationToken, isAuthenticated, signUpErrorMsg, handleErrorMsgChange } = useSignUp()
  const [password, email, confirmEmail, confirmPassword] = watch(['password', 'email', 'confirmEmail', 'confirmPassword'])

  const passwordSpecs: PasswordSpecsState = {
    atLeastEightCharacters: password.length >= 8,
    hasLowerCase: hasLowerCaseRegex.test(password),
    hasUpperCase: hasUpperCaseRegex.test(password),
    hasNumber: hasNumberRegex.test(password),
    hasSpecialCharacter: hasSpecialSymbolRegex.test(password),
  }

  const checkIfPasswordPassesThreeConditions = () => {
    let conditionCounter = 0

    if (passwordSpecs.hasLowerCase) conditionCounter++

    if (passwordSpecs.hasUpperCase) conditionCounter++

    if (passwordSpecs.hasSpecialCharacter) conditionCounter++

    if (passwordSpecs.hasNumber) conditionCounter++

    return conditionCounter >= 3
  }

  const isPasswordValid = () => {
    if (!passwordSpecs.atLeastEightCharacters) {
      return false
    }

    return checkIfPasswordPassesThreeConditions()
  }

  useEffect(() => {
    if ((password && confirmPassword && touchedFields.confirmPassword) || touchedFields.confirmPassword) {
      trigger('confirmPassword')
    }
  }, [touchedFields.confirmPassword, trigger, password, confirmPassword])

  useEffect(() => {
    if ((email && confirmEmail && touchedFields.confirmEmail) || touchedFields.confirmEmail) {
      trigger('confirmEmail')
    }
  }, [touchedFields.confirmEmail, trigger, email, confirmEmail])

  if (!registrationToken || isAuthenticated) {
    return <Redirect to={PRESCRIPTION_MANAGEMENT_PATH} />
  }

  const clearEmailValidationError = () => {
    clearErrors('confirmEmail')
  }

  const clearPasswordValidationError = () => {
    clearErrors('confirmPassword')
  }

  return (
    <StyledSignUp.MainContainer>
      <StyledToastMessage
        timeout={5000}
        onDismiss={() => {
          handleErrorMsgChange('')
        }}
        state={'error'}
        visible={!!signUpErrorMsg}
        onTimeout={() => {
          handleErrorMsgChange('')
        }}
      >
        <Text>{signUpErrorMsg}</Text>
      </StyledToastMessage>
      <StyledSignUp.MainHeader vpTheme={theme} variant='4xl'>
        Create your account
      </StyledSignUp.MainHeader>
      <StyledSignUp.Form onSubmit={handleSubmit(signUp)}>
        <ThemedTextField
          label='Email address'
          placeholder='Enter your email'
          state={parseTextFieldStateForCapsule(errors.email, email !== '')}
          required
          showRequiredIndicator
          helperText={errors.email?.message ?? ''}
          {...register('email', {
            onChange: () => {
              clearErrors('email')
            },
          })}
          vpTheme={theme}
        />
        <ThemedTextField
          autoComplete='off'
          label='Confirm email'
          placeholder='Confirm your email'
          state={parseTextFieldStateForCapsule(errors.confirmEmail, confirmEmail !== '')}
          required
          showRequiredIndicator
          helperText={errors.confirmEmail?.message ?? ''}
          onPaste={(e) => {
            e.preventDefault()
            return false
          }}
          {...register('confirmEmail')}
          onChange={clearEmailValidationError}
          vpTheme={theme}
        />
        <ThemedTextField
          label='Password'
          placeholder='Enter your password'
          state={parseTextFieldStateForCapsule(errors.password, dirtyFields.password)}
          required
          showRequiredIndicator
          type='password'
          showToggle
          {...register('password')}
          vpTheme={theme}
        />
        <StyledSignUp.PasswordRequirementContainer>
          <Text>Your password must contain:</Text>
          <StyledSignUp.PasswordRequirementList>
            <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.atLeastEightCharacters}>
              At least 8 characters
            </StyledSignUp.PasswordRequirementListItem>
            <StyledSignUp.PasswordRequirementListItem theme={theme} passed={checkIfPasswordPassesThreeConditions()}>
              At least 3 of the following
            </StyledSignUp.PasswordRequirementListItem>
            <StyledSignUp.PasswordRequirementList>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasLowerCase}>
                Lower case letters (a-z)
              </StyledSignUp.PasswordRequirementListItem>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasUpperCase}>
                Upper case letters (A-Z)
              </StyledSignUp.PasswordRequirementListItem>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasNumber}>
                Numbers (0-9)
              </StyledSignUp.PasswordRequirementListItem>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasSpecialCharacter}>
                Special characters (e.g. !@#$%^&*)
              </StyledSignUp.PasswordRequirementListItem>
            </StyledSignUp.PasswordRequirementList>
          </StyledSignUp.PasswordRequirementList>
        </StyledSignUp.PasswordRequirementContainer>
        <ThemedTextField
          label='Confirm password'
          placeholder='Confirm your password'
          state={parseTextFieldStateForCapsule(errors.confirmPassword, dirtyFields.confirmPassword)}
          required
          showRequiredIndicator
          type='password'
          showToggle
          helperText={errors.confirmPassword?.message ?? ''}
          {...register('confirmPassword')}
          onChange={clearPasswordValidationError}
          vpTheme={theme}
        />
        <StyledSignUp.CheckboxContainer vpTheme={theme}>
          <Checkbox id='consent' name='consent' onCheckedChange={handleConsentChange} checked={hasConsented} />
          <label htmlFor='consent'>
            I agree to Truepill&apos;s{' '}
            <a href={window.location.origin + HIPAA_PRACTICES_PATH} target='_blank' rel='noreferrer'>
              HIPAA Practices
            </a>
            ,{' '}
            <a href={window.location.origin + PRIVACY_POLICY_PATH} target='_blank' rel='noreferrer'>
              Privacy Policy
            </a>
            ,{' '}
            <a href={window.location.origin + INFORMED_CONSENT_PATH} target='_blank' rel='noreferrer'>
              Informed Consent of Pharmacy Services
            </a>
            , and{' '}
            <a href={window.location.origin + TERMS_OF_SERVICE_PATH} target='_blank' rel='noreferrer'>
              Terms of Service
            </a>
            .
          </label>
        </StyledSignUp.CheckboxContainer>
        <StyledSignUp.SignUpButtonContainer>
          <ThemedButton
            role='button'
            type='submit'
            disabled={!isSignUpFormValid || !hasConsented || !isPasswordValid() || isSubmitting}
            vpTheme={theme}
          >
            Sign Up
          </ThemedButton>
        </StyledSignUp.SignUpButtonContainer>
      </StyledSignUp.Form>
    </StyledSignUp.MainContainer>
  )
}

const SignUpNew = (): React.ReactElement => {
  const { theme } = useContentfulTheme()

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields, isSubmitting },
    watch,
    setValue,
  } = useForm<SignUpFields>({
    delayError: 200,
    resolver: yupResolver(SignUpInfo),
    defaultValues: {
      password: '',
      email: '',
      confirmEmail: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
  })
  const { signUp, handleConsentChange, hasConsented, registrationToken, isAuthenticated, signUpErrorMsg, handleErrorMsgChange } = useSignUp()
  const [password, email, confirmEmail] = watch(['password', 'email', 'confirmEmail', 'confirmPassword'])

  const passwordSpecs: PasswordSpecsState = {
    atLeastEightCharacters: password.length >= 8,
    hasLowerCase: hasLowerCaseRegex.test(password),
    hasUpperCase: hasUpperCaseRegex.test(password),
    hasNumber: hasNumberRegex.test(password),
    hasSpecialCharacter: hasSpecialSymbolRegex.test(password),
  }

  const checkIfPasswordPassesThreeConditions = () => {
    let conditionCounter = 0

    if (passwordSpecs.hasLowerCase) conditionCounter++

    if (passwordSpecs.hasUpperCase) conditionCounter++

    if (passwordSpecs.hasSpecialCharacter) conditionCounter++

    if (passwordSpecs.hasNumber) conditionCounter++

    return conditionCounter >= 3
  }

  const isPasswordValid = (): boolean => {
    if (!passwordSpecs.atLeastEightCharacters) {
      return false
    }

    return checkIfPasswordPassesThreeConditions()
  }

  const submitWithPasswordValidation = async (signupFields: SignUpFields) => {
    if (isPasswordValid()) {
      await signUp(signupFields)
    } else {
      handleErrorMsgChange('Password does not meet requirements.')
    }
  }

  if (!registrationToken || isAuthenticated) {
    return <Redirect to={PRESCRIPTION_MANAGEMENT_PATH} />
  }

  return (
    <StyledSignUp.MainContainer>
      <StyledToastMessage
        timeout={5000}
        onDismiss={() => {
          handleErrorMsgChange('')
        }}
        state={'error'}
        visible={!!signUpErrorMsg}
        onTimeout={() => {
          handleErrorMsgChange('')
        }}
      >
        <Text>{signUpErrorMsg}</Text>
      </StyledToastMessage>
      <StyledSignUp.MainHeader vpTheme={theme} variant='4xl'>
        Create your account
      </StyledSignUp.MainHeader>
      <StyledSignUp.Form onSubmit={handleSubmit(submitWithPasswordValidation)}>
        <ThemedTextField
          label='Email address'
          type='email'
          placeholder='Enter your email'
          state={parseTextFieldStateForCapsule(errors.email, email !== '')}
          required
          showRequiredIndicator
          helperText={errors.email?.message ?? ''}
          {...register('email', {
            onChange: (e) => {
              setValue('email', e.target.value as string)
            },
          })}
          autoComplete='email'
          vpTheme={theme}
        />
        <ThemedTextField
          label='Confirm email'
          type='email'
          placeholder='Confirm your email'
          state={parseTextFieldStateForCapsule(errors.confirmEmail, confirmEmail !== '')}
          required
          showRequiredIndicator
          helperText={errors.confirmEmail?.message ?? ''}
          onPaste={(e) => {
            e.preventDefault()
            return false
          }}
          {...register('confirmEmail', {
            onChange: (e) => {
              setValue('confirmEmail', e.target.value as string)
            },
          })}
          autoComplete='email'
          vpTheme={theme}
        />
        <ThemedTextField
          label='Password'
          placeholder='Enter your password'
          state={parseTextFieldStateForCapsule(errors.password, dirtyFields.password, !isPasswordValid())}
          required
          showRequiredIndicator
          type='password'
          showToggle
          {...register('password', {
            onChange: (e) => {
              setValue('password', e.target.value as string)
            },
          })}
          autoComplete='new-password'
          vpTheme={theme}
        />
        <StyledSignUp.PasswordRequirementContainer>
          <Text>Your password must contain:</Text>
          <StyledSignUp.PasswordRequirementList>
            <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.atLeastEightCharacters}>
              At least 8 characters
            </StyledSignUp.PasswordRequirementListItem>
            <StyledSignUp.PasswordRequirementListItem theme={theme} passed={checkIfPasswordPassesThreeConditions()}>
              At least 3 of the following
            </StyledSignUp.PasswordRequirementListItem>
            <StyledSignUp.PasswordRequirementList>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasLowerCase}>
                Lower case letters (a-z)
              </StyledSignUp.PasswordRequirementListItem>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasUpperCase}>
                Upper case letters (A-Z)
              </StyledSignUp.PasswordRequirementListItem>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasNumber}>
                Numbers (0-9)
              </StyledSignUp.PasswordRequirementListItem>
              <StyledSignUp.PasswordRequirementListItem theme={theme} passed={passwordSpecs.hasSpecialCharacter}>
                Special characters (e.g. !@#$%^&*)
              </StyledSignUp.PasswordRequirementListItem>
            </StyledSignUp.PasswordRequirementList>
          </StyledSignUp.PasswordRequirementList>
        </StyledSignUp.PasswordRequirementContainer>
        <ThemedTextField
          label='Confirm password'
          placeholder='Confirm your password'
          state={parseTextFieldStateForCapsule(errors.confirmPassword, dirtyFields.confirmPassword)}
          required
          showRequiredIndicator
          type='password'
          showToggle
          helperText={errors.confirmPassword?.message ?? ''}
          {...register('confirmPassword', {
            onChange: (e) => {
              setValue('confirmPassword', e.target.value as string)
            },
          })}
          autoComplete='new-password'
          vpTheme={theme}
        />
        <StyledSignUp.CheckboxContainer vpTheme={theme}>
          <Checkbox id='consent' name='consent' onCheckedChange={handleConsentChange} checked={hasConsented} />
          <label htmlFor='consent'>
            I agree to Truepill&apos;s{' '}
            <a href={window.location.origin + HIPAA_PRACTICES_PATH} target='_blank' rel='noreferrer'>
              HIPAA Practices
            </a>
            ,{' '}
            <a href={window.location.origin + PRIVACY_POLICY_PATH} target='_blank' rel='noreferrer'>
              Privacy Policy
            </a>
            ,{' '}
            <a href={window.location.origin + INFORMED_CONSENT_PATH} target='_blank' rel='noreferrer'>
              Informed Consent of Pharmacy Services
            </a>
            , and{' '}
            <a href={window.location.origin + TERMS_OF_SERVICE_PATH} target='_blank' rel='noreferrer'>
              Terms of Service
            </a>
            .
          </label>
        </StyledSignUp.CheckboxContainer>
        <StyledSignUp.SignUpButtonContainer>
          <ThemedButton role='button' type='submit' disabled={!hasConsented || isSubmitting} vpTheme={theme}>
            Sign Up
          </ThemedButton>
        </StyledSignUp.SignUpButtonContainer>
      </StyledSignUp.Form>
    </StyledSignUp.MainContainer>
  )
}

export default SignUp
