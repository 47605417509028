import { atom, DefaultValue, selector } from 'recoil'

import { PatientInsurance } from '../interfaces'
import { HydratedSavingsCard } from '../utils/savingsCardUtils'
import { selectedPatientTokenAtom } from './selectedPatientToken'

export const patientInsuranceState = atom<Record<string, PatientInsurance[]>>({
  key: 'patientInsuranceState',
  default: {},
})

export const patientSavingsCardState = atom<Record<string, HydratedSavingsCard[]>>({
  key: 'patientSavingsCardState',
  default: {},
})

export const patientInsurance = selector({
  key: 'patientInsurance',
  get: ({ get }): PatientInsurance[] | undefined => {
    const patientInsurances = get(patientInsuranceState)
    const selectedPatientToken = get(selectedPatientTokenAtom)
    return patientInsurances[selectedPatientToken]
  },
  set: ({ get, set }, newPatientInsuranceList: PatientInsurance[] | DefaultValue | undefined) => {
    const patientInsurances = { ...get(patientInsuranceState) }
    const selectedPatientToken = get(selectedPatientTokenAtom)
    patientInsurances[selectedPatientToken] = newPatientInsuranceList as PatientInsurance[]
    set(patientInsuranceState, patientInsurances)
  },
})

export const patientSavingsCards = selector({
  key: 'patientSavingsCard',
  get: ({ get }): HydratedSavingsCard[] | undefined => {
    const patientSavingsCard = get(patientSavingsCardState)
    const selectedPatientToken = get(selectedPatientTokenAtom)
    return patientSavingsCard[selectedPatientToken]
  },
  set: ({ get, set }, newSavingsCardList: HydratedSavingsCard[] | DefaultValue | undefined) => {
    const patientSavingsCard = { ...get(patientSavingsCardState) }
    const selectedPatientToken = get(selectedPatientTokenAtom)
    patientSavingsCard[selectedPatientToken] = newSavingsCardList as HydratedSavingsCard[]
    set(patientSavingsCardState, patientSavingsCard)
  },
})
