import { Spacer, Text } from '@truepill/react-capsule'
import ToastMessage from 'Components/ToastMessage'
import { impersonatedUserToken, patientWithUsers as patientUsers, selectedPatientTokenAtom, SelectedUserPatientWithUsersState } from 'persistRecoil'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { ArrowUpIcon } from '../../assets/Icons'
import { ArrowDownIcon } from '../../assets/Icons/ArrowDownIcon'
import { SearchIcon } from '../../assets/Icons/SearchIcon'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { PRESCRIPTION_MANAGEMENT_PATH } from '../../constants'
import { DATE_FORMAT_CORE_API, SIMPLIFIED_DATE_FORMAT_UI } from '../../constants/dateConstants'
import { useAuth } from '../../context/auth-context'
import { useContentfulTheme, useCustomerProfile, useUpdateCart } from '../../hooks'
import User from '../../interfaces/User'
import { patientService } from '../../services'
import { toUtcString } from '../../utils/dateUtilities'
import {
  ElementHeader,
  SearchBarWrapper,
  SearchContainer,
  SearchResultContainer,
  SelectedPatientDetails,
  SelectedPatientInfo,
  StyledDropdownButton,
  StyledDropdownContainer,
  StyledDropdownElement,
  TextLink,
} from './styledComponents'

const AdminSearchBar: React.FC = () => {
  const history = useHistory()
  const {
    authState: { isAuthenticated },
  } = useAuth()

  const { customerProfile } = useCustomerProfile()
  const [selectedPatientToken, setSelectedPatientTokenAndUpdateProfile] = useRecoilState(selectedPatientTokenAtom)

  const { clearCart } = useUpdateCart()

  const [isSearching, setIsSearching] = useState(false)

  const [patientToken, setPatientToken] = useState<string>(localStorage.getItem('patientToken') || '')
  const [patientWithUsersState, setPatientWithUsers] = useRecoilState<SelectedUserPatientWithUsersState | undefined>(patientUsers)

  const setImpersonatedUserToken = useSetRecoilState(impersonatedUserToken)

  const [showToastMessage, setShowToastMessage] = useState(false)
  const showToast = showToastMessage && isAuthenticated
  const [showDropdown, setShowDropdown] = useState(false)
  const onMainPage = window.location.pathname === PRESCRIPTION_MANAGEMENT_PATH

  const { theme } = useContentfulTheme()

  useEffect(() => {
    setImpersonatedUserToken(patientWithUsersState?.selectedUser?.user_token || '')
    clearCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientWithUsersState?.selectedUser?.user_token, selectedPatientToken])

  const getPatientUsers = async () => {
    if (!patientToken) return
    setIsSearching(true)
    try {
      const patientUsers = await patientService.getPatientUsers(patientToken.trim(), customerProfile.vpharmCustomerToken)
      setImpersonatedUserToken(patientUsers.users[0].user_token)
      setPatientWithUsers({ patientWithUsers: patientUsers, selectedUser: patientUsers.users[0] })
      setSelectedPatientTokenAndUpdateProfile(patientToken)

      history.push(PRESCRIPTION_MANAGEMENT_PATH)
    } catch (error) {
      setSelectedPatientTokenAndUpdateProfile('')
      setPatientWithUsers(undefined)
      setShowToastMessage(true)
    } finally {
      setIsSearching(false)
    }
  }

  const handleTokenChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('patientToken', event.target.value)
    setPatientToken(event.target.value)
  }

  const handleUserSelected = (user: User) => {
    setImpersonatedUserToken(user.user_token)
    setPatientWithUsers({ patientWithUsers: patientWithUsersState?.patientWithUsers, selectedUser: user })
    clearCart()
    setShowDropdown(false)

    history.push(PRESCRIPTION_MANAGEMENT_PATH)
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getPatientUsers()
    }
  }

  const patientFullName = `${patientWithUsersState?.selectedUser?.first_name || '-'} ${patientWithUsersState?.selectedUser?.last_name || '-'}`
  const isRegistered = `${patientWithUsersState?.selectedUser?.registered}`

  return isAuthenticated ? (
    <SearchBarWrapper vpTheme={theme}>
      <SearchContainer>
        <ThemedTextField
          endAdornment={SearchIcon}
          placeholder='Search patient token'
          onKeyDown={handleOnKeyDown}
          onChange={handleTokenChanged}
          value={patientToken}
          label='Patient Search'
          vpTheme={theme}
        />
        <ThemedButton disabled={isSearching} onClick={getPatientUsers} vpTheme={theme}>
          Search
        </ThemedButton>
      </SearchContainer>
      {patientWithUsersState?.patientWithUsers && (
        <SearchResultContainer>
          <SelectedPatientInfo>
            <SelectedPatientDetails>
              <Text bold>{patientFullName}</Text>
              <Spacer />
              <Text bold>DOB:</Text>
              <Spacer size='2xs' />
              <Text>{toUtcString(patientWithUsersState.patientWithUsers.dob, SIMPLIFIED_DATE_FORMAT_UI, DATE_FORMAT_CORE_API)}</Text>
              <Spacer />
              <Text bold>Registered:</Text>
              <Spacer size='2xs' />
              <Text>{isRegistered}</Text>
            </SelectedPatientDetails>
            <Text variant='body-sm'>
              {`Viewing as user ${patientWithUsersState.selectedUser?.email} (${patientWithUsersState.selectedUser?.relationship_type}) `}
            </Text>
            <Spacer />
          </SelectedPatientInfo>
          {showDropdown && (
            <>
              <StyledDropdownContainer spacing='none'>
                <Text bold>
                  User List
                  <Spacer />
                </Text>
                <StyledDropdownElement mobile={4} tablet={8} desktop={12} vpTheme={theme}>
                  <Spacer size='xs' />
                  <ElementHeader>
                    <Text variant='body-sm' bold>
                      Name
                    </Text>
                    <Text variant='body-sm' bold>
                      Relationship
                    </Text>
                  </ElementHeader>
                  <Spacer size='xs' />
                </StyledDropdownElement>
                {patientWithUsersState ? (
                  patientWithUsersState.patientWithUsers.users.map((u) => (
                    <StyledDropdownElement key={u.user_token} mobile={4} tablet={8} desktop={12} vpTheme={theme}>
                      <ElementHeader>
                        <div>
                          <Text>{`${u.first_name} ${u.last_name}`}</Text>
                          <Text variant='caption'>{u.email}</Text>
                        </div>
                        <Text>{u.relationship_type}</Text>
                        <TextLink onClick={() => handleUserSelected(u)} vpTheme={theme}>
                          Select User
                        </TextLink>
                      </ElementHeader>
                    </StyledDropdownElement>
                  ))
                ) : (
                  <StyledDropdownElement mobile={4} tablet={8} desktop={12} vpTheme={theme}>
                    <Text>{`No users found for token '${patientToken}'`}</Text>
                  </StyledDropdownElement>
                )}
              </StyledDropdownContainer>
            </>
          )}
          <StyledDropdownButton onClick={() => setShowDropdown(!showDropdown)} vpTheme={theme}>
            <TextLink vpTheme={theme}>{showDropdown ? 'Hide user list' : onMainPage ? 'Show user list' : 'Change user'}</TextLink>
            {showDropdown ? <ArrowUpIcon vpTheme={theme} /> : <ArrowDownIcon vpTheme={theme} />}
          </StyledDropdownButton>
        </SearchResultContainer>
      )}

      {showToast && (
        <ToastMessage
          borderLeft={true}
          color='pastel'
          className='toast-message'
          icon={true}
          onDismiss={() => setShowToastMessage(false)}
          position={{ vertical: 'top', horizontal: 'center' }}
          state='error'
          visible={showToast}
          timeout={5000}
          onTimeout={() => {
            setShowToastMessage(false)
          }}
        >
          <Text>Patient token not found. Please try a different patient token.</Text>
        </ToastMessage>
      )}
    </SearchBarWrapper>
  ) : (
    <></>
  )
}

export default AdminSearchBar
