import { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useAuth } from '../context/auth-context'
import { Patient, UserProfile } from '../interfaces'
import { selectedPatientTokenAtom, userProfileState } from '../persistRecoil'
import { adminLogin } from '../persistRecoil/adminLogin'
import { impersonatedUserToken } from '../persistRecoil/impersonatedUserToken'
import { profileService } from '../services/profileService'
import { useCustomerProfile } from '.'
import { useAnalytics } from './analytics-context'

type ReturnValues = {
  error: boolean
  loading: boolean
  fetchUserProfile: () => Promise<void>
} & UserProfile

const findPrimaryPatientToken = (authorizedPatients: Record<string, Patient>): string => {
  for (const patientToken in authorizedPatients) {
    if (authorizedPatients[patientToken].primary) {
      return patientToken
    }
  }

  // This is not a great fallback
  const firstPatientToken = Object.keys(authorizedPatients)[0]

  return firstPatientToken
}

export const useUserProfile = (): ReturnValues => {
  const { customerProfile } = useCustomerProfile()
  const [selectedPatientToken, setSelectedPatientTokenAndUpdateProfile] = useRecoilState(selectedPatientTokenAtom)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const [userState, setUserState] = useRecoilState<UserProfile | undefined>(userProfileState)
  const userToImpersonate = useRecoilValue(impersonatedUserToken)
  const isAdminLogin = useRecoilValue(adminLogin)
  const { identifyAnalyticsUser } = useAnalytics()

  const {
    authState: { isAuthenticated },
  } = useAuth()

  const selectedPatientBelongsToUser = !!userState?.authorizedPatients?.[selectedPatientToken]

  const impersonationNeedsUserProfileUpdate = isAdminLogin && isAuthenticated && userToImpersonate && !selectedPatientBelongsToUser

  const fetchUserProfileAndSelectPrimaryPatient = useCallback(async () => {
    try {
      setLoading(true)
      const userProfileResponse = await profileService.fetchUserProfile(customerProfile.vpharmCustomerToken)
      setUserState(userProfileResponse)
      const primaryPatientToken = findPrimaryPatientToken(userProfileResponse.authorizedPatients as Record<string, Patient>)
      setSelectedPatientTokenAndUpdateProfile(primaryPatientToken)
      identifyAnalyticsUser(primaryPatientToken)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [customerProfile.vpharmCustomerToken, identifyAnalyticsUser, setSelectedPatientTokenAndUpdateProfile, setUserState])

  useEffect(() => {
    if ((!isAdminLogin && isAuthenticated) || impersonationNeedsUserProfileUpdate) {
      fetchUserProfileAndSelectPrimaryPatient().catch((err) => console.error('Error on initial user data fetch', err))
    } else if (isAdminLogin) {
      setLoading(false)
      localStorage.setItem('isAdminLogin', isAdminLogin ? 'true' : 'false')
    }
  }, [isAdminLogin, isAuthenticated, fetchUserProfileAndSelectPrimaryPatient, impersonationNeedsUserProfileUpdate])

  return {
    error,
    loading,
    fetchUserProfile: fetchUserProfileAndSelectPrimaryPatient,
    authorizedPatients: userState?.authorizedPatients,
  }
}
