import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { DEVELOPMENT_SUBDOMAIN } from '../constants'
import { CustomerProfile } from '../interfaces'
import { profileService } from '../services'
import { getSubdomain } from '../utils'

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
})

export const customerProfileQuery = selector({
  key: 'customerProfileQuery',
  get: async () => {
    const subdomain = getSubdomain()
    const subdomainOrDev = process.env.NODE_ENV === 'development' && subdomain === '' ? DEVELOPMENT_SUBDOMAIN : subdomain
    return profileService.fetchCustomer(subdomainOrDev)
  },
})

export const customerProfileAtom = atom<CustomerProfile>({
  key: 'CustomerProfileAtom',
  default: customerProfileQuery,
  effects_UNSTABLE: [persistAtom],
})
