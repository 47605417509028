import React from 'react'

import { IHorizontalImageFields } from '../../types/generated/contentful'
import { HorizontalImageContainer, HorizontalImageWrapper } from './styledComponents'

const HorizontalImage: React.FC<IHorizontalImageFields> = ({ image, maxWidth, maxHeight }) => {
  return (
    <HorizontalImageWrapper>
      <HorizontalImageContainer imageUrl={image.fields.file.url} maxWidth={maxWidth} maxHeight={maxHeight}></HorizontalImageContainer>
    </HorizontalImageWrapper>
  )
}

export default HorizontalImage
