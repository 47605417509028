import { PrescriptionDisplayStatus } from '@vpharm-platform/shared'
import { PrescriptionResponseDTO } from 'services'

export const prescriptionMockData: PrescriptionResponseDTO[] = [
  {
    id: 'mock-id-1',
    healthProfileId: 'mock-healthprofile-id-1',
    ndc: 'mock-ndc-1',
    prescriptionToken: 'prescription-token-1',
    quantity: 1,
    daysSupply: '90',
    prescriber: 'Dr Jamie Smith, MD',
    prescribedOn: 'March 24, 2020',
    autoRefill: false,
    refillsRemaining: 7,
    lastRefill: 'June 22, 2021',
    rxNumber: '2375146',
    expiryDate: 'June 22, 2021',
    instructions: 'Take 2 tablets per day',
    nextFillDate: 'September 22, 2021',
    status: PrescriptionDisplayStatus.COPAY_PRICE_AVAILABLE,
    medicationDetails: {
      autorefill: 'Y',
      bundledProducts: [],
      created: '2021-09-06',
      form: 'Tablet',
      formPlural: 'Tablets',
      label: 'Esomeprazole 10mg',
      lastModification: '2021-09-23',
      strength: '10mg',
      name: 'Esomeprazole',
      manufacturingCost: '100',
      markup: '2',
      metadescription: '',
      metatitle: '',
      ndc: 'mock-ndc-1',
      outOfStockThreshold: 100,
      pillNonPill: 'Pill',
      pricePerUnit: '10',
      productType: 'pharmacy',
      sku: 'mock-ndc-1',
      status: PrescriptionDisplayStatus.COPAY_PRICE_AVAILABLE,
      taxCode: 'PH050102',
      treatmentCategory1: 'Monkeys',
      treatmentCategory2: 'Squirrels',
      treatmentCategory3: 'Badgers',
      variants: [{ sku: '1234' }],
      variationOf: 'ParentEsomeprazole',
      slug: 'esomeprazole-tablet-10mg',
    },
    parentMedication: {
      brandGeneric: 'Generic',
      brandName: 'Nexium',
    },
  },
  {
    id: 'mock-id-2',
    healthProfileId: 'mock-healthprofile-id-2',
    ndc: 'mock-ndc-2',
    prescriptionToken: 'prescription-token-2',
    quantity: 1,
    daysSupply: '90',
    prescriber: 'Dr Jamie Smith, MD',
    prescribedOn: 'March 24, 2020',
    autoRefill: false,
    refillsRemaining: 2,
    lastRefill: null,
    rxNumber: '2375147',
    expiryDate: 'June 22, 2021',
    instructions: 'Take 1 tablet per day',
    status: PrescriptionDisplayStatus.COPAY_PRICE_AVAILABLE,
    medicationDetails: {
      autorefill: 'Y',
      bundledProducts: [],
      created: '2021-09-06',
      form: 'Tablet',
      formPlural: 'Tablets',
      label: 'Alendronate 10mg',
      lastModification: '2021-09-23',
      strength: '10mg',
      name: 'Alendronate',
      manufacturingCost: '100',
      markup: '2',
      metadescription: '',
      metatitle: '',
      ndc: 'mock-ndc-2',
      outOfStockThreshold: 100,
      pillNonPill: 'Pill',
      pricePerUnit: '10',
      productType: 'pharmacy',
      sku: 'mock-ndc-2',
      status: PrescriptionDisplayStatus.FILL_IN_PROGRESS,
      taxCode: 'PH050102',
      treatmentCategory1: 'Mental Health',
      variants: [{ sku: '4321' }],
      variationOf: 'ParentAlendronate',
      slug: 'alendronate-tablet-10mg',
    },
    parentMedication: {
      brandGeneric: 'Generic',
      brandName: 'Fosamax',
    },
  },
  {
    id: 'mock-id-3',
    healthProfileId: 'mock-healthprofile-id-3',
    ndc: 'mock-ndc-3',
    prescriptionToken: 'prescription-token-3',
    quantity: 1,
    daysSupply: '90',
    prescriber: 'Dr Jamie Smith, MD',
    prescribedOn: 'March 24, 2020',
    autoRefill: false,
    refillsRemaining: 2,
    lastRefill: 'June 22, 2021',
    rxNumber: '2375148',
    expiryDate: 'June 22, 2021',
    instructions: 'Take 1 puff orally as needed',
    status: PrescriptionDisplayStatus.FILL_IN_PROGRESS,
    medicationDetails: {
      autorefill: 'N',
      bundledProducts: [],
      created: '2021-09-06',
      form: 'Inhaler',
      formPlural: 'Inhalers',
      label: 'Albuterol Sulfate HFA 8.5mg',
      lastModification: '2021-09-23',
      strength: '8.5mg',
      name: 'Albuterol Sulfate HFA',
      manufacturingCost: '100',
      markup: '2',
      metadescription: '',
      metatitle: '',
      ndc: 'mock-ndc-3',
      outOfStockThreshold: 100,
      pillNonPill: 'Pill',
      pricePerUnit: '10',
      productType: 'pharmacy',
      sku: 'mock-ndc-3',
      status: PrescriptionDisplayStatus.COPAY_PRICE_AVAILABLE,
      taxCode: 'PH050102',
      treatmentCategory1: 'Asthma',
      variants: [{ sku: '9999' }],
      variationOf: 'ParentAlbuterolSulfateHFA',
      slug: 'albuterol-inhaler-8.5mg',
    },
    parentMedication: {
      brandGeneric: 'Generic',
      brandName: 'Fosamax',
    },
  },
  {
    id: 'mock-id-4',
    healthProfileId: 'mock-healthprofile-id-4',
    ndc: 'mock-ndc-4',
    prescriptionToken: 'prescription-token-4',
    quantity: 1,
    daysSupply: '90',
    prescriber: 'Dr Jamie Smith, MD',
    prescribedOn: 'March 24, 2020',
    autoRefill: false,
    refillsRemaining: 2,
    lastRefill: 'June 22, 2021',
    rxNumber: '2375149',
    expiryDate: 'June 22, 2021',
    instructions: 'Take 1 tablet per day',
    status: PrescriptionDisplayStatus.OUT_OF_REFILL,
    medicationDetails: {
      autorefill: 'Y',
      bundledProducts: [],
      created: '2021-09-06',
      form: 'Tablet',
      formPlural: 'Tablets',
      label: 'Alendronate 20mg',
      lastModification: '2021-09-23',
      strength: '20mg',
      name: 'Alendronate',
      manufacturingCost: '100',
      markup: '2',
      metadescription: '',
      metatitle: '',
      ndc: 'mock-ndc-4',
      outOfStockThreshold: 100,
      pillNonPill: 'Pill',
      pricePerUnit: '10',
      productType: 'pharmacy',
      sku: 'mock-ndc-4',
      status: PrescriptionDisplayStatus.COPAY_PRICE_AVAILABLE,
      taxCode: 'PH050102',
      treatmentCategory1: 'Cheese',
      variants: [{ sku: '9999' }],
      variationOf: 'ParentAlendronate',
      slug: 'alendronate-tablet-20mg',
    },
    parentMedication: {
      brandGeneric: 'Generic',
      brandName: 'Fosamax',
    }, // as ParentMedication,
  },
]
