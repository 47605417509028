import { atom, DefaultValue, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { UserProfile } from '../interfaces'
import { subdomainState } from './subdomainState'

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
})

export const userProfileAtom = atom<Record<string, UserProfile>>({
  key: 'UserProfileAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const userProfileState = selector({
  key: 'userProfileState',
  get: ({ get }): UserProfile | undefined => {
    const subdomain: string = get(subdomainState)
    const profiles = get(userProfileAtom)
    return profiles[subdomain]
  },
  set: ({ get, set }, newProfile: UserProfile | undefined | DefaultValue) => {
    const subdomain: string = get(subdomainState)
    const profiles = { ...get(userProfileAtom) }
    profiles[subdomain] = newProfile as UserProfile
    set(userProfileAtom, profiles)
  },
})
