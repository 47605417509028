import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const CheckmarkShield = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='64' height='65' viewBox='0 0 64 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M56 34.3144C56 52.981 32 60.981 32 60.981C32 60.981 8 52.981 8 34.3144V8.98104L32 3.64771L56 8.98104V34.3144Z'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M21.333 31.6476L29.333 39.6476L45.333 20.981'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  )
}
