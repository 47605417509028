import { BannerAlert } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

import { Modal } from '../../common/styledComponents/StyledModal'
import { media } from '../../common/styles/variables'

const CheckoutContainer = styled.div`
  position: relative;

  @media (max-width: ${media.tablet}) {
    width: 100%;
  }
`

const AccordionWrapper = styled.div``

const ErrorMessage = styled(BannerAlert)`
  max-width: 46.5rem;
  margin: 0.5rem;
  position: fixed;
  top: 10%;
  z-index: 100;
`

const ModalContainer = styled(Modal)`
  > div:first-child {
    max-width: 42rem;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
`

export { AccordionWrapper, CheckoutContainer, ErrorMessage, ModalContainer }
