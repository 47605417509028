import { ContentfulClientApi, createClient, Entry } from 'contentful'

import { contentfulConfig } from '../../config'
import { ContentfulSessionKeys } from '../../constants'
import { ContentType } from '../../constants/contentType'
import type {
  CONTENT_TYPE,
  ICustomerFields,
  IDynamicPage,
  IOrdersPageFields,
  IPageConfigurationFields,
  IPageFields,
  IResource,
  IRibbonFields,
  ISeoFields,
} from '../../types/generated/contentful'

export interface ContentfulPage<T> {
  name: string
  content: T
  seo?: ISeoFields
  microcopy?: IResource[]
}

const { SPACE_ID, ACCESS_TOKEN, ENVIRONMENT } = contentfulConfig

const preview_access_token = sessionStorage.getItem(ContentfulSessionKeys.ContentfulPreviewToken)

export const contentfulClient: ContentfulClientApi = createClient({
  space: SPACE_ID,
  accessToken: preview_access_token || ACCESS_TOKEN,
  environment: ENVIRONMENT,
  host: preview_access_token ? 'preview.contentful.com' : undefined,
})

export const getPage = async <T extends IPageFields['pageContent']>(
  slug: string,
  customerName: string,
  pageContentType?: CONTENT_TYPE,
): Promise<ContentfulPage<T>> => {
  let params: Record<string, string | number> = {
    limit: 1,
    include: 10,
    content_type: ContentType.PAGE,
    'fields.slug': `${customerName}-${slug}`,
  }

  if (pageContentType) {
    params = {
      ...params,
      'fields.pageContent.sys.contentType.sys.id': pageContentType,
    }
  }

  const pageEntries = await contentfulClient.getEntries<IPageFields>(params)
  const [page] = pageEntries.items

  if (!page) {
    throw new Error(`Page not found with slug ${customerName}-${slug}`)
  }

  return {
    name: page.fields.name,
    seo: page.fields.seo?.fields,
    content: page.fields.pageContent as T,
    microcopy: page.fields.microcopy,
  }
}

export const getDynamicPage = async (slug: string, customerName: string): Promise<ContentfulPage<IRibbonFields[]>> => {
  const { seo, name, content, microcopy } = await getPage<IDynamicPage>(slug, customerName, 'dynamicPage')
  const ribbons = content.fields.ribbons.map((ribbon) => ribbon.fields)

  return {
    seo,
    name,
    content: ribbons,
    microcopy,
  }
}

export const getCustomer = async (slug: string | undefined): Promise<Entry<ICustomerFields> | null> => {
  const customers = await contentfulClient.getEntries<ICustomerFields>({
    'fields.slug': slug,
    content_type: ContentType.CUSTOMER,
    select: [
      'fields.displayName',
      'fields.companyName',
      'fields.companyDescription',
      'fields.favicon',
      'fields.initialCopyrightYear',
      'fields.componentConfiguration',
      'fields.themeConfiguration',
      'fields.customerSupport',
      'fields.microcopy',
    ].join(','),
    include: 10,
  })

  if (customers.items.length > 0) {
    return customers.items[0]
  }
  return null
}

export const getOrdersPage = async (): Promise<Entry<IOrdersPageFields> | null> => {
  const ordersPage = await contentfulClient.getEntries<IOrdersPageFields>({
    content_type: ContentType.ORDERS_PAGE,
    include: 1,
  })

  if (ordersPage.items.length > 0) {
    return ordersPage.items[0]
  }
  return null
}

export const getPageConfiguration = async <T extends keyof Omit<IPageConfigurationFields, 'name' | 'slug'>>(
  slug: string,
  page: T,
): Promise<IPageConfigurationFields[T] | null> => {
  const pages = await contentfulClient.getEntries<IPageConfigurationFields>({
    'fields.slug': slug,
    content_type: ContentType.PAGE_CONFIGURATION,
    select: `fields.${page}`,
    include: 10,
  })

  if (pages.items.length > 0) {
    return pages.items[0].fields[page]
  }
  return null
}
