import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const PlusCircleSmall = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_19984_61131)'>
        <g clipPath='url(#clip1_19984_61131)'>
          <path
            d='M7.9987 14.6667C11.6806 14.6667 14.6654 11.6819 14.6654 8.00001C14.6654 4.31811 11.6806 1.33334 7.9987 1.33334C4.3168 1.33334 1.33203 4.31811 1.33203 8.00001C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667Z'
            stroke={vpTheme.colors['primary-700']}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M8 5.33334V10.6667' stroke={vpTheme.colors['primary-700']} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M5.33203 8H10.6654' stroke={vpTheme.colors['primary-700']} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_19984_61131'>
          <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
        </clipPath>
        <clipPath id='clip1_19984_61131'>
          <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
        </clipPath>
      </defs>
    </svg>
  )
}
