import { Text, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../../common/styles/variables'

interface StyledHeaderProps {
  allowPriceTransparency: boolean
}

const CheckoutPriceHeader = styled.header<StyledHeaderProps>`
  font-size: ${(props) => (props.allowPriceTransparency ? '20px' : '30px')};
  font-weight: bold;
  line-height: 24px;

  ${mediaLargerThan.tablet} {
    font-size: ${(props) => (props.allowPriceTransparency ? '24px' : '36px')};
    line-height: 30px;
  }
`

const CheckoutPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    font-size: '14';
    font-weight: bold;
    line-height: 20px;
  }
`

const RetailPriceText = styled(Text)`
  -webkit-text-fill-color: ${theme.colors['typography-medium'].computedValue};
  font-weight: 400;
`

export const StyledPrescriptionPrice = {
  CheckoutPriceHeader,
  RetailPriceText,
  CheckoutPriceContainer,
}
