import { useMemo } from 'react'

import { getSubdomain } from '../../utils'

const DEFAULT_CONTENTFUL_CUSTOMER_NAME = 'truepill'

export const useGetCustomerNameFromSubdomain = (): string => {
  const subdomain = useMemo(() => getSubdomain(), [])

  const subdomainWithDevDefault =
    process.env.NODE_ENV === 'development' || subdomain === '' || subdomain === 'vpharm' || subdomain === 'vpharm-qa'
      ? DEFAULT_CONTENTFUL_CUSTOMER_NAME
      : subdomain

  return subdomainWithDevDefault
}
