import { useCallback, useEffect, useState } from 'react'

import { patientService } from '../services'
import { useShowError } from './useShowError'

interface UsePatientRegistration {
  patientRegistered: boolean
  loading: boolean
  error: boolean
  checkPatientRegistration: () => Promise<void>
}

function usePatientRegistration(): UsePatientRegistration {
  const [patientRegistered, setPatientRegistered] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { showError } = useShowError()

  const checkPatientRegistration = useCallback(async () => {
    setLoading(true)

    try {
      const registered = await patientService.checkPatientRegistered()
      setPatientRegistered(!!registered?.registered)
    } catch (err) {
      showError()
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [showError])

  useEffect(() => {
    checkPatientRegistration()
  }, [checkPatientRegistration])

  return {
    loading,
    error,
    patientRegistered,
    checkPatientRegistration,
  }
}

export default usePatientRegistration
