import { ReactElement, useState } from 'react'

import { useContentfulTheme } from '../../hooks'
import { StyledButton, StyledIframe, StyledModal, TitleButton, VideoDiv } from './styledComponents'

interface TutorialVideoProps {
  title: string
  videoId: string
  buttonImageUrl: string
}

const TutorialVideo = ({ title, videoId, buttonImageUrl }: TutorialVideoProps): ReactElement => {
  const [showVideo, setShowVideo] = useState(false)
  const { theme: vpTheme } = useContentfulTheme()

  return (
    <div>
      <VideoDiv>
        <StyledButton onClick={() => setShowVideo(true)} vpTheme={vpTheme}>
          <img src={buttonImageUrl} alt='Play Video' />
        </StyledButton>
        <TitleButton variant={'secondary-text'} onClick={() => setShowVideo(true)} vpTheme={vpTheme}>
          {title}
        </TitleButton>
      </VideoDiv>
      {showVideo && (
        <StyledModal isOpen={showVideo} onDismiss={() => setShowVideo(false)}>
          <StyledIframe
            src={videoId}
            title='Embedded Video Tutorial'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          ></StyledIframe>
        </StyledModal>
      )}
    </div>
  )
}

export default TutorialVideo
