import React from 'react'

import OutlinedCard, { OutlinedCardProps } from '../../../Components/OutlinedCard'
import { IContactChannelsSectionFields } from '../../../types/generated/contentful'
import { ContactChannelContainer } from './styledComponents'

const ContactChannels = (props: IContactChannelsSectionFields): React.ReactElement => {
  const { channels } = props
  return (
    <ContactChannelContainer spacing='4xl' {...props}>
      {channels?.map((channel, index) => (
        <OutlinedCard {...(channel.fields as OutlinedCardProps)} key={`outlined-card-${index}`} />
      ))}
    </ContactChannelContainer>
  )
}

export default ContactChannels
