import { Header, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import { IconCoupon } from '../../../../assets/Icons'
import { VP_5903_INSURANCE_MGMT_UPDATE } from '../../../../constants'
import { useLDFlagsWithLocalStorage } from '../../../../hooks/useLDFlagsWithLocalStorage'
import { StyledSavingsCardBanner } from './styledComponents'
import { useSavingsCardBanner } from './useSavingsCardBanner'

interface SavingsCardBannerProps {
  theme: VpTheme
}

const SavingsCardBanner = ({ theme }: SavingsCardBannerProps): React.ReactElement => {
  const { onBannerClick } = useSavingsCardBanner()

  const { [VP_5903_INSURANCE_MGMT_UPDATE]: insuranceMgmtUpdate } = useLDFlagsWithLocalStorage([VP_5903_INSURANCE_MGMT_UPDATE])

  return (
    <StyledSavingsCardBanner.Container role='banner' onClick={onBannerClick} vpTheme={theme}>
      <IconCoupon color={theme.colors['primary-500']} />
      <StyledSavingsCardBanner.TextContainer vpTheme={theme}>
        <Header variant='2xl'>
          {insuranceMgmtUpdate ? 'Need to apply for a new savings card?' : 'Add your savings card on Prescription Manager'}
        </Header>
        <Text>
          {insuranceMgmtUpdate
            ? 'Look for the “savings card available” label in your Prescription Manager'
            : 'Look for the “savings card available” label'}
        </Text>
      </StyledSavingsCardBanner.TextContainer>
    </StyledSavingsCardBanner.Container>
  )
}

export default SavingsCardBanner
