import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil'

import { useUpdateCart } from '../../../hooks'
import { Medication } from '../../../interfaces'
import {
  haveControlledSubstance,
  medicationNameByRx,
  medicationsInCart,
  medicationsRemoved,
  PriceDetailsType,
  pricingDetails,
} from '../../../persistRecoil'
import { OrderMode, orderModeAtom } from '../../../persistRecoil/orderMode'

export interface UseCartReturnValue {
  medicationsInCart: Medication[]
  medsRemoved: Medication[]
  removeMed: (rxNumber: string) => void
  undoRemoveMed: (rxNumber: string) => void
  deleteRemovedMeds: () => void
  getMedicationByRxNumber: (rxNumber: string) => string
  orderMode: OrderMode
  priceDetails: PriceDetailsType
  setPriceDetails: SetterOrUpdater<PriceDetailsType>
  orderHasControlledSubstance: boolean
}

export const useCart = (): UseCartReturnValue => {
  const addedMedicationsInCart = useRecoilValue(medicationsInCart)
  const [priceDetails, setPriceDetails] = useRecoilState(pricingDetails)
  const { orderMode } = useRecoilValue(orderModeAtom)
  const medsRemoved = useRecoilValue(medicationsRemoved)
  const { remove, undoRemove, deleteRemovedItems } = useUpdateCart()
  const getMedicationByRxNumber = useRecoilValue(medicationNameByRx)
  const orderHasControlledSubstance = useRecoilValue(haveControlledSubstance)

  return {
    medicationsInCart: addedMedicationsInCart,
    priceDetails,
    setPriceDetails,
    orderMode,
    medsRemoved,
    undoRemoveMed: undoRemove,
    deleteRemovedMeds: deleteRemovedItems,
    removeMed: remove,
    getMedicationByRxNumber,
    orderHasControlledSubstance,
  }
}
