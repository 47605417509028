export enum SavingsCardProcess {
  EVERSANA = 'eversana',
  MANUAL_ENTRY = 'manual_entry'
}

export interface EversanaSavingsCardConfig {
  savingsCardProcess: SavingsCardProcess.EVERSANA
  savingsCardCashEnabled: boolean
  savingsCardInsuranceEnabled: boolean
  savingsCardMedContentId: string | null
  savingsCardGroupCode: string | null
}

export interface ManualEntrySavingsCardConfig {
  savingsCardProcess: SavingsCardProcess.MANUAL_ENTRY
  savingsCardCashEnabled: boolean
  savingsCardInsuranceEnabled: boolean
  externalSavingsCardCashUrl: string | null
  externalSavingsCardInsuranceUrl: string | null
}
