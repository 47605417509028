import { CustomerProfileResponse, PatientProfileResponse, sharedConstants, UserProfileResponse } from '@vpharm-platform/shared'
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { DEVELOPMENT_SUBDOMAIN } from '../constants'
import { axiosAuth } from '../httpClient'
import { CustomerProfile, PatientProfile, UserProfile } from '../interfaces'
import { transformProfileResponse } from './dto'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface ProfileService {
  fetchCustomer(customerSubdomain?: string): Promise<CustomerProfile>
  fetchPatientProfile(vpharmCustomerToken: string, truepillPatientToken: string): Promise<PatientProfile>
  fetchUserProfile(vpharmCustomerToken: string): Promise<UserProfile>
}

class ProfileApiService implements ProfileService {
  // don't forget to create a proxy route for production logging
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/profiles`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async fetchCustomer(customerSubdomain: string): Promise<CustomerProfile> {
    const subdomainWithDevDefault =
      process.env.NODE_ENV === 'development' || customerSubdomain === '' || customerSubdomain === 'vpharm-qa'
        ? DEVELOPMENT_SUBDOMAIN
        : customerSubdomain
    const url = `${this.baseUrl}/customer?customer_subdomain=${subdomainWithDevDefault}`
    const config: AxiosRequestConfig = { headers: this.defaultHeaders }
    const response = await axios.get<CustomerProfileResponse>(url, config)
    const customerProfileRes = response.data
    return transformProfileResponse.customerProfile(customerProfileRes)
  }

  async fetchPatientProfile(vpharmCustomerToken: string, truepillPatientToken: string): Promise<PatientProfile> {
    const url = `${this.baseUrl}/patient`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: truepillPatientToken,
      },
    }
    const patientProfileRes = await axiosAuth.get<PatientProfileResponse>(url, config)
    return transformProfileResponse.patientProfile(patientProfileRes.data)
  }

  async fetchUserProfile(vpharmCustomerToken: string): Promise<UserProfile> {
    const url = `${this.baseUrl}/user`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
      },
    }
    const userProfileRes = await axiosAuth.get<UserProfileResponse>(url, config)
    return transformProfileResponse.userProfile(userProfileRes.data)
  }
}

export const profileService: ProfileService = new ProfileApiService()
