import styled from 'styled-components'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const ShowMoreLessButton = styled(ThemedButton)`
  cursor: pointer;
  padding: 0;
  font-size: '0.875rem';
  text-decoration: underline;
  align-items: center;
  font-weight: 400;
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
`

const ExpanderContainer = styled.div`
  padding-right: 41px;
  padding-bottom: 24px;

  ${mediaLargerThan.tablet} {
    padding-bottom: 4px;
  }
`

const ShowMoreContainer = styled.div<ThemedComponent>`
  button p {
    -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
  }
`

const ShowLessContainer = styled.div<ThemedComponent>`
  button p {
    -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
  }
`

export { ExpanderContainer, ShowLessContainer, ShowMoreContainer, ShowMoreLessButton }
