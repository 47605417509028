import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const ChatMessage = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='64' height='65' viewBox='0 0 64 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M50.6673 15.6475H56.0007C57.4151 15.6475 58.7717 16.2094 59.7719 17.2096C60.7721 18.2098 61.334 19.5663 61.334 20.9808V43.6475C61.334 45.0619 60.7721 46.4185 59.7719 47.4187C58.7717 48.4189 57.4151 48.9808 56.0007 48.9808H50.6673V59.6475L34.6673 48.9808H29.334'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M45.3337 4.98096H8.00033C6.58584 4.98096 5.22928 5.54286 4.22909 6.54305C3.2289 7.54325 2.66699 8.8998 2.66699 10.3143V32.981C2.66699 34.3954 3.2289 35.752 4.22909 36.7522C5.22928 37.7524 6.58584 38.3143 8.00033 38.3143H13.3337V51.6476L32.0003 38.3143H45.3337C46.7481 38.3143 48.1047 37.7524 49.1049 36.7522C50.1051 35.752 50.667 34.3954 50.667 32.981V10.3143C50.667 8.8998 50.1051 7.54325 49.1049 6.54305C48.1047 5.54286 46.7481 4.98096 45.3337 4.98096Z'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  )
}
