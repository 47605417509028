export const SHIPPING_STATES = [
  { id: 1, state_name: 'Alabama', state_abbreviation: 'AL' },
  { id: 2, state_name: 'Alaska', state_abbreviation: 'AK' },
  { id: 3, state_name: 'American Samoa', state_abbreviation: 'AS' },
  { id: 4, state_name: 'Arizona', state_abbreviation: 'AZ' },
  { id: 5, state_name: 'Arkansas', state_abbreviation: 'AR' },
  { id: 6, state_name: 'California', state_abbreviation: 'CA' },
  { id: 7, state_name: 'Colorado', state_abbreviation: 'CO' },
  { id: 8, state_name: 'Connecticut', state_abbreviation: 'CT' },
  { id: 9, state_name: 'Delaware', state_abbreviation: 'DE' },
  { id: 10, state_name: 'District Of Columbia', state_abbreviation: 'DC' },
  { id: 11, state_name: 'Federated States Of Micronesia', state_abbreviation: 'FM' },
  { id: 12, state_name: 'Florida', state_abbreviation: 'FL' },
  { id: 13, state_name: 'Georgia', state_abbreviation: 'GA' },
  { id: 14, state_name: 'Guam', state_abbreviation: 'GU' },
  { id: 15, state_name: 'Hawaii', state_abbreviation: 'HI' },
  { id: 16, state_name: 'Idaho', state_abbreviation: 'ID' },
  { id: 17, state_name: 'Illinois', state_abbreviation: 'IL' },
  { id: 18, state_name: 'Indiana', state_abbreviation: 'IN' },
  { id: 19, state_name: 'Iowa', state_abbreviation: 'IA' },
  { id: 20, state_name: 'Kansas', state_abbreviation: 'KS' },
  { id: 21, state_name: 'Kentucky', state_abbreviation: 'KY' },
  { id: 22, state_name: 'Louisiana', state_abbreviation: 'LA' },
  { id: 23, state_name: 'Maine', state_abbreviation: 'ME' },
  { id: 24, state_name: 'Marshall Islands', state_abbreviation: 'MH' },
  { id: 25, state_name: 'Maryland', state_abbreviation: 'MD' },
  { id: 26, state_name: 'Massachusetts', state_abbreviation: 'MA' },
  { id: 27, state_name: 'Michigan', state_abbreviation: 'MI' },
  { id: 28, state_name: 'Minnesota', state_abbreviation: 'MN' },
  { id: 29, state_name: 'Mississippi', state_abbreviation: 'MS' },
  { id: 30, state_name: 'Missouri', state_abbreviation: 'MO' },
  { id: 31, state_name: 'Montana', state_abbreviation: 'MT' },
  { id: 32, state_name: 'Nebraska', state_abbreviation: 'NE' },
  { id: 33, state_name: 'Nevada', state_abbreviation: 'NV' },
  { id: 34, state_name: 'New Hampshire', state_abbreviation: 'NH' },
  { id: 35, state_name: 'New Jersey', state_abbreviation: 'NJ' },
  { id: 36, state_name: 'New Mexico', state_abbreviation: 'NM' },
  { id: 37, state_name: 'New York', state_abbreviation: 'NY' },
  { id: 38, state_name: 'North Carolina', state_abbreviation: 'NC' },
  { id: 39, state_name: 'North Dakota', state_abbreviation: 'ND' },
  { id: 40, state_name: 'Northern Mariana Islands', state_abbreviation: 'MP' },
  { id: 41, state_name: 'Ohio', state_abbreviation: 'OH' },
  { id: 42, state_name: 'Oklahoma', state_abbreviation: 'OK' },
  { id: 43, state_name: 'Oregon', state_abbreviation: 'OR' },
  { id: 44, state_name: 'Palau', state_abbreviation: 'PW' },
  { id: 45, state_name: 'Pennsylvania', state_abbreviation: 'PA' },
  { id: 46, state_name: 'Puerto Rico', state_abbreviation: 'PR' },
  { id: 47, state_name: 'Rhode Island', state_abbreviation: 'RI' },
  { id: 48, state_name: 'South Carolina', state_abbreviation: 'SC' },
  { id: 49, state_name: 'South Dakota', state_abbreviation: 'SD' },
  { id: 50, state_name: 'Tennessee', state_abbreviation: 'TN' },
  { id: 51, state_name: 'Texas', state_abbreviation: 'TX' },
  { id: 52, state_name: 'Utah', state_abbreviation: 'UT' },
  { id: 53, state_name: 'Vermont', state_abbreviation: 'VT' },
  { id: 54, state_name: 'Virgin Islands', state_abbreviation: 'VI' },
  { id: 55, state_name: 'Virginia', state_abbreviation: 'VA' },
  { id: 56, state_name: 'Washington', state_abbreviation: 'WA' },
  { id: 57, state_name: 'West Virginia', state_abbreviation: 'WV' },
  { id: 58, state_name: 'Wisconsin', state_abbreviation: 'WI' },
  { id: 59, state_name: 'Wyoming', state_abbreviation: 'WY' },
]
