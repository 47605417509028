import React, { Fragment, useState } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { PregnancyStatus } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { AnswerOption, FormMode, PatientHealthSurveyQuestion } from '../../interfaces'
import { HealthHistoryOptions } from '../../services'
import { mapToLabel } from '../../utils/pregnancyQuestionHelpers'
import {
  StyledCompletedAnswerContainer,
  StyledPregnancyAnswerButton,
  StyledPregnancyShortAnswerButtons,
  StyledSaveButtonContainer,
} from './styledComponents'

export interface PregnancyStatusQuestionProps {
  data: PatientHealthSurveyQuestion
  mode: FormMode
  onSaveAnswer: (id: string, answerDetails: Array<string>) => void
  onCompleteForm: (id: string) => void
  onChangeMode?: (id: string) => void
  isDisabled: (mode: boolean) => void
}

const PregnancyStatusQuestion: React.FC<PregnancyStatusQuestionProps> = ({
  data,
  mode = FormMode.Edit,
  onSaveAnswer,
  onCompleteForm,
  onChangeMode,
}) => {
  const { theme } = useContentfulTheme()

  const { answer, answerOptions, id } = data

  const [selectedOption, setSelectedOption] = useState<PregnancyStatus[]>(answer as PregnancyStatus[])

  const isSelected = (option: AnswerOption) => {
    return isPregnancyStatusOption(option.value) && selectedOption?.includes(option.value)
  }

  const changeSelectedOption = (option: AnswerOption) => {
    if (isSelected(option)) {
      setSelectedOption(selectedOption.filter((item) => item !== option.value))
    } else {
      let vals: PregnancyStatus[] = []

      switch (option.value) {
        case PregnancyStatus.Pregnant:
          vals = vals.filter((item) => item === PregnancyStatus.Lactating)
          break
        case PregnancyStatus.Lactating:
          vals = vals.filter((item) => item === PregnancyStatus.Pregnant || item === PregnancyStatus.Planning)
          break
        case PregnancyStatus.Planning:
          vals = vals.filter((item) => item === PregnancyStatus.Lactating)
          break
        case PregnancyStatus.None:
          vals = []
          break
      }

      isPregnancyStatusOption(option.value) && vals.push(option.value)

      setSelectedOption(vals)
    }
  }

  const submitAnswer = () => {
    if (selectedOption?.length && selectedOption?.length > 0) {
      onSaveAnswer(id, selectedOption)
      onChangeMode && onChangeMode(id)
      onCompleteForm(id)
    }
  }

  const isPregnancyStatusOption = (value: PregnancyStatus | HealthHistoryOptions | undefined): value is PregnancyStatus => {
    return (
      value === PregnancyStatus.Pregnant ||
      value === PregnancyStatus.Lactating ||
      value === PregnancyStatus.Planning ||
      value === PregnancyStatus.None
    )
  }

  return (
    <Fragment>
      {mode === FormMode.View ? (
        <StyledCompletedAnswerContainer>
          <span>{mapToLabel(selectedOption)}</span>
        </StyledCompletedAnswerContainer>
      ) : (
        <Fragment>
          <StyledPregnancyShortAnswerButtons>
            {answerOptions.map((option) => {
              return (
                <StyledPregnancyAnswerButton
                  key={`${option.value}-${isSelected(option) ? 'selected' : 'deselected'}`}
                  data-testid={`${option.value}_answer_button`}
                  onClick={() => changeSelectedOption(option)}
                  variant={isSelected(option) ? 'primary' : 'primary-outline'}
                  vpTheme={theme}
                >
                  {option.label}
                </StyledPregnancyAnswerButton>
              )
            })}
          </StyledPregnancyShortAnswerButtons>
          <StyledSaveButtonContainer>
            <ThemedButton data-testid='save_continue_button' onClick={() => submitAnswer()} disabled={selectedOption?.length === 0} vpTheme={theme}>
              Save
            </ThemedButton>
          </StyledSaveButtonContainer>
        </Fragment>
      )}
    </Fragment>
  )
}

export default PregnancyStatusQuestion
