import { AccordionContent, AccordionTrigger, Chip, Header, Text, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0 1.5rem;
`

const Title = styled(Header)`
  text-align: center;
  margin-top: 3rem;
`

const StyledAccordionHeader = styled(Header)`
  text-align: center;
  font-size: 20px;
  -webkit-text-fill-color: ${theme.colors['typography-medium'].computedValue} !important;
  margin-bottom: 0;
`

const Subtitle = styled(Text)`
  text-align: center;
  margin-top: 1rem;
`

const TransferTokenContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TransferToken = styled(Chip)`
  margin-top: 2rem;
  font-weight: bold;
  color: ${theme.colors['primary-700'].computedValue};
`

const TransferDetailsContainer = styled.div`
  margin-top: 2.25rem;
`
const StyledHeader = styled(Header)`
  margin-left: 0.625rem;
  margin-bottom: 2rem;
`

const FlexContainer = styled.div`
  display: flex;
`

const FaqSection = styled.div`
  margin-top: 3.188rem;
  margin-bottom: 5.563rem;
`

const StyledAccordionTrigger = styled(AccordionTrigger)`
  svg polyline {
    stroke: ${theme.colors['primary-500'].computedValue};
  }
`

const StyledAccordionContent = styled(AccordionContent)`
  max-width: fit-content;
  font-size: 20px;
  padding: 0.5rem 0.5rem 0.75rem 2.5rem;
  -webkit-text-fill-color: ${theme.colors['typography-medium'].computedValue} !important;
`

export const StyledTransferConfirmation = {
  Container,
  Title,
  StyledAccordionHeader,
  Subtitle,
  TransferTokenContainer,
  TransferToken,
  TransferDetailsContainer,
  StyledHeader,
  FlexContainer,
  FaqSection,
  StyledAccordionTrigger,
  StyledAccordionContent,
}
