import { Header, Spacer, Text, theme } from '@truepill/react-capsule'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import { mediaLargerThan } from '../../common/styles/variables'
import CustomerCopyChange from '../../Components/CustomerCopyChange'
import LoadingAnimation from '../../Components/LoadingAnimation'
import { LOGIN_PATH, PRESCRIPTION_MANAGEMENT_PATH } from '../../constants'
import { useAuth } from '../../context/auth-context'
import { useCustomerProfile } from '../../hooks'
import { MicroCopyResourceType, SelfEnrollmentBannerMicrocopy, SelfEnrollmentPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../hooks/contentful/useContentfulMicrocopy'
import PatientInfoForm from './sections/PatientInfo'
import { GenderResponses } from './sections/types'
import SelfEnrollmentBanner from './SelfEnrollmentBanner'

export interface SelfEnrollmentState {
  firstName: string
  lastName: string
  gender: GenderResponses | null
  middleInitial: string
  suffix: string
  dob: {
    month: number | null
    day: number | null
    year: number | null
  }
  patientPhoneNumber: string
  enrollCommunications: boolean
  address1: string
  address2: string
  city: string
  state: string
  zip: string
}

const SelfEnrollment = (): React.ReactElement => {
  const bannerScrollRef = useRef<HTMLDivElement>(null)
  const { customerProfile } = useCustomerProfile()
  const {
    authState: { user },
  } = useAuth()
  const { microcopy: bannerMicrocopy, isLoadingContent: isLoadingBannerContent } = useContentfulMicrocopy<SelfEnrollmentBannerMicrocopy>(
    MicroCopyResourceType.SelfEnrollmentBanner,
  )
  // TODO: This should be encompassed inside the component it's being used in
  const { microcopy: selfEnrollmentPageMicrocopy, isLoadingContent: isLoadingPageContent } = useContentfulMicrocopy<SelfEnrollmentPageMicrocopy>(
    MicroCopyResourceType.SelfEnrollmentPage,
  )
  const history = useHistory()

  useEffect(() => {
    if (!customerProfile.allowSelfEnrollment) {
      history.push(LOGIN_PATH)
    }

    if (user) {
      history.push(PRESCRIPTION_MANAGEMENT_PATH)
    }
  }, [history, customerProfile.allowSelfEnrollment, user])

  const redirectToSignIn = () => {
    history.push(LOGIN_PATH)
  }

  if (isLoadingBannerContent || isLoadingPageContent) {
    return <LoadingAnimation size='sm' />
  }

  return (
    <SelfEnrollmentLayout>
      {bannerMicrocopy && <SelfEnrollmentBanner bannerScrollRef={bannerScrollRef} {...bannerMicrocopy} />}
      <MainLayout>
        <StyledPatientInfoHeader ref={bannerScrollRef} variant='4xl'>
          Patient information
        </StyledPatientInfoHeader>
        <Text css={{ color: '#535762' }}>We will need some personal information to help create your health profile.</Text>
        <Spacer size='sm' />
        <SignupContainer onClick={redirectToSignIn}>
          Already have an account?&nbsp;
          <SignupLink href=''>Sign in here</SignupLink>
        </SignupContainer>
        <Spacer size='lg' />
        <PatientInfoForm />
        {selfEnrollmentPageMicrocopy?.customerCopyChangeText && (
          <CustomerCopyChange copyChangeText={selfEnrollmentPageMicrocopy.customerCopyChangeText} />
        )}
      </MainLayout>
    </SelfEnrollmentLayout>
  )
}

const StyledPatientInfoHeader = styled(Header)`
  && {
    line-height: 3rem;
  }
`

const SelfEnrollmentLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 32px auto;
  min-height: calc(100vh - 128px - 9.5rem);
  justify-content: center;

  ${mediaLargerThan.tablet} {
    align-items: start;
    justify-content: start;
    max-width: 70rem;
    gap: 3rem;
    flex-direction: row;
    padding: 0 1rem;
  }
`

const MainLayout = styled.main`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-top: 5px;
  margin: 0 24px;
  ${mediaLargerThan.tablet} {
    max-width: 679px;
  }
`

const SignupContainer = styled.div`
  height: 56px;
  left: 0;
  top: 128px;
  background: ${theme.colors['gray-300'].computedValue};
  border-radius: 4px;
  line-height: 3.2em;
  font-size: 16px;
  padding-left: 16px;
`

const SignupLink = styled.a`
  color: black;
  text-decoration-line: underline;
`

export default SelfEnrollment
