import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../common/styles/variables'

const Container = styled.div`
  ${mediaLargerThan.tablet} {
    max-width: 744px;
  }
`

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > p {
    margin: 0;
  }
`

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;

  p {
    margin: 0;
  }
`

const OptionLabelContainer = styled.div``

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
`

const GroupedLabel = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    gap: 0.2rem;
  }
`
const SearchIconContainer = styled.div<{ strokeColor?: string }>`
  svg {
    height: 18px;
    width: 18px;
  }

  svg path {
    stroke: ${({ strokeColor }) => strokeColor ?? defaultTheme.colors['typography-medium']};
  }
`

export const StyledPharmacyFinder = {
  Container,
  PlaceholderContainer,
  OptionContainer,
  OptionLabelContainer,
  GroupedLabel,
  LabelContainer,
  SearchIconContainer,
}
