import { AvailableShippingOptionsResponse } from '@vpharm-platform/shared'
import { useCallback, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { useAuth } from '../context/auth-context'
import { selectedPatientTokenAtom } from '../persistRecoil'
import { shippingOptionService } from '../services'
import { useCustomerProfile } from '.'

export interface AvailableShippingOptionsRequest {
  controlled_substance: boolean
  delivery_address: string
  delivery_state_code: string
  cold_chain: boolean
  prescription_tokens: string[]
}

interface UseShippingMethods {
  isError: boolean
  isLoading: boolean
  shippingOptions: AvailableShippingOptionsResponse[]
  fetchShippingOptions: (requestData: AvailableShippingOptionsRequest) => Promise<void>
}

export const useShippingMethods = (): UseShippingMethods => {
  const { getAccessTokenSilently } = useAuth()
  const { customerProfile } = useCustomerProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shippingOptions, setShippingOptions] = useState<AvailableShippingOptionsResponse[]>([])

  const fetchShippingOptions = useCallback(
    async (requestData: AvailableShippingOptionsRequest) => {
      setIsLoading(true)
      try {
        if (requestData.delivery_address !== '' && requestData.delivery_state_code !== '') {
          const accessToken = await getAccessTokenSilently()
          const result = await shippingOptionService.getAvailableShippingOptions(
            accessToken,
            customerProfile.vpharmCustomerToken,
            selectedPatientToken,
            requestData,
          )
          if (result.length === 0) {
            setIsError(true)
          }
          setShippingOptions(result)
        }
      } catch (error) {
        setIsError(true)
        setShippingOptions([])
      } finally {
        setIsLoading(false)
      }
    },
    [customerProfile.vpharmCustomerToken, selectedPatientToken, getAccessTokenSilently],
  )

  return { isError, isLoading, shippingOptions, fetchShippingOptions }
}
