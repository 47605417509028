import { theme } from '@truepill/react-capsule'
import React from 'react'
import styled from 'styled-components/macro'

interface ErrorMessageProps {
  text: string
  id: string
  hideScreenReaderAlert?: boolean
}

const ErrorMessage = ({ text, id, hideScreenReaderAlert }: ErrorMessageProps): React.ReactElement => {
  const props = hideScreenReaderAlert ? { id: id } : { id: id, role: 'alert' }

  return (
    <>
      <StyledErrorMessage {...props}>{text}</StyledErrorMessage>
    </>
  )
}

const StyledErrorMessage = styled.div`
  color: ${theme.colors['functional-error-dark'].computedValue};
  font-family: Lato;
  font-size: 14px;
  margin: 8px 0px 0px 2px;
  line-height: 20px;
`

export default ErrorMessage
