import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, Hyperlink, INLINES, MARKS, NodeData } from '@contentful/rich-text-types'
import { CSS } from '@truepill/capsule-utils'
import { Header, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'
import { useContentfulTheme } from '../../hooks'

interface Props {
  document: any
  renderOptions?: Options
  openLinksInSameWindow?: boolean | undefined
  css?: CSS
}

const RichTextRenderer = ({
  document,
  renderOptions,
  openLinksInSameWindow,
  css = { marginBottom: '20px', color: defaultTheme.colors['primary-900'] },
}: Props): React.ReactElement => {
  const { theme } = useContentfulTheme()

  const defaultRenderOptions = {
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <Bold as='span'>{text}</Bold>,
      [MARKS.ITALIC]: (text: ReactNode) => <Italic as='span'>{text}</Italic>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <Underline as='span'>{text}</Underline>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: NodeData, children: any) => (
        <Text css={css} variant='body'>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_1]: (node: NodeData, children: any) => <Header variant='6xl'>{children}</Header>,
      [BLOCKS.HEADING_2]: (node: NodeData, children: any) => <Header variant='5xl'>{children}</Header>,
      [BLOCKS.HEADING_3]: (node: NodeData, children: any) => <Header variant='4xl'>{children}</Header>,
      [BLOCKS.HEADING_4]: (node: NodeData, children: any) => <Header variant='3xl'>{children}</Header>,
      [BLOCKS.HEADING_5]: (node: NodeData, children: any) => <Header variant='2xl'>{children}</Header>,
      [BLOCKS.HEADING_6]: (node: NodeData, children: any) => <Header variant='xl'>{children}</Header>,
      [BLOCKS.UL_LIST]: (node: NodeData, children: any) => <UList>{children}</UList>,
      [BLOCKS.OL_LIST]: (node: NodeData, children: any) => <OList>{children}</OList>,
      [BLOCKS.LIST_ITEM]: (node: NodeData, children: any) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node: NodeData, children: any) => {
        const nodeData = node as Hyperlink
        const isWebLink = nodeData.data.uri.startsWith('http')

        // openLinksInSameWindow prop takes priority if explicitly set
        let target
        if (typeof openLinksInSameWindow === 'boolean') {
          if (openLinksInSameWindow) {
            target = '_self'
          } else {
            target = '_blank'
          }
        } else {
          if (isWebLink) {
            target = '_blank'
          } else {
            target = '_self'
          }
        }

        return (
          <StyledLink vpTheme={theme} href={nodeData.data.uri} target={target} rel='noreferrer'>
            {children}
          </StyledLink>
        )
      },
    },
    renderText: (text: string): ReactNode => (text === '' ? <br /> : text),
  }
  return <>{documentToReactComponents(document as Document, renderOptions || defaultRenderOptions)}</>
}

export const Bold = styled(Text)`
  font-weight: bold;
`

export const Underline = styled(Text)`
  text-decoration: underline;
`

export const Italic = styled(Text)`
  font-style: italic;
`

const UList = styled.ul`
  list-style-type: disc;
  margin-left: 36px;
`

const OList = styled.ul`
  list-style-type: number;
  margin-left: 36px;
`

const StyledLink = styled.a<ThemedComponent>`
  cursor: pointer;
  color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']};
`

interface IProps {
  children: any
}

export const ListItem = ({ children }: IProps): React.ReactElement => (
  <li>
    <Text>{children}</Text>
  </li>
)

export default RichTextRenderer
