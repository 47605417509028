import { ThemedComponent } from '../../common/styledComponents/types'

export const UploadIcon = ({ vpTheme }: ThemedComponent): JSX.Element => (
  <svg width={17} height={17} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)' stroke={vpTheme.colors.iconDefault} strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M10.667 11.47 8 8.805l-2.667 2.667M8 8.804v6' />
      <path d='M13.593 13.064A3.333 3.333 0 0 0 12 6.804h-.84A5.333 5.333 0 1 0 2 11.671' />
      <path d='M10.667 11.47 8 8.805l-2.667 2.667' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill={vpTheme.colors.grayWhite} transform='translate(0 .804)' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
