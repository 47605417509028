import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const TransferLeftRight = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='64' height='65' viewBox='0 0 64 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M36 19.6475H4' stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']} strokeWidth='2.75' strokeMiterlimit='10' />
      <path
        d='M28 46.3145H60'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
      />
      <path
        d='M14.6667 30.3143L4 19.6476L14.6667 8.98096'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M49.3333 56.9808L59.9999 46.3141L49.3333 35.6475'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='2.75'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  )
}
