import { Card } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { media, mediaLargerThan } from 'common/styles/variables'
import SecureCheckoutButton from 'Components/SecureCheckoutButton'
import { Link } from 'react-router-dom'
import SlidingPane from 'react-sliding-pane'
import styled from 'styled-components/macro'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'

const CartContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 1.5rem 6rem;
  width: 100%;

  && p {
    font-family: Lato, sans-serif;
  }

  @media (max-width: ${media.laptop}) {
    flex-direction: column;
    padding: 2rem 1.5rem 4rem;
    width: 100%;
  }
`

const CartDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 744px;
  width: 100%;

  ${mediaLargerThan.laptop} {
    padding-right: 1.5rem;
  }
`

const OrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`

const StyledBackLink = styled(Link)<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
  font-family: Lato, sans-serif;
  line-height: 1.5rem;
  text-decoration: none;
`

const StyledHeader = styled(ThemedHeader)<ThemedComponent>`
  font-weight: bold;
  padding: 1rem 0 0.5rem;

  && {
    font-size: 2.25rem;
    line-height: 2.5rem;

    @media (max-width: ${media.laptop}) {
      font-size: 1.875rem;
    }
  }
`
const StyledCheckoutButton = styled(SecureCheckoutButton)<{
  hideForMobile?: boolean
}>`
  margin: 1rem 0;

  @media (max-width: ${media.laptop}) {
    display: ${(props) => (props.hideForMobile ? 'none' : 'inherit')};
  }
`

const ActionButton = styled.button<{ color?: string }>`
  background: transparent;
  border: none;
  color: ${({ color }) => color ?? defaultTheme.colors['functional-info-dark']};
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
`

const StyledMedicationRemoved = styled.div<{ background?: string }>`
  background: ${({ background }) => background ?? defaultTheme.colors['gray-300']};
  display: flex;
  padding: 16px;
  margin-bottom: 16px;
  border: ${({ background }) => background ?? defaultTheme.colors['gray-300']};

  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  && p,
  span,
  button,
  a {
    font-family: Lato, sans-serif;
  }
`

const StyledMedicationRemovedText = styled.div`
  flex: 1;
`

const UndoButtonWrapper = styled.div`
  text-align: right;
`

const UndoButton = styled(ActionButton)`
  text-decoration-line: underline;

  ${mediaLargerThan.laptop} {
    text-decoration-line: none;
  }
`

const StyledPane = styled(SlidingPane)`
  position: relative;
  ${mediaLargerThan.laptop} {
    width: 500px !important;
  }
`

const CloseIconContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`

const StyledCloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

const SlidingCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
`

const SlidingCartDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SlidingCartOrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MedsContainer = styled.div`
  overflow-y: auto;
`
const StyledCard = styled(Card)<ThemedComponent>`
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 8px;
`

const MedPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

export {
  CartContainer,
  CartDetailsContainer,
  CloseIconContainer,
  MedPriceContainer,
  MedsContainer,
  OrderSummaryContainer,
  SlidingCartContainer,
  SlidingCartDetailsContainer,
  SlidingCartOrderSummaryContainer,
  StyledBackLink,
  StyledCard,
  StyledCheckoutButton,
  StyledCloseButton,
  StyledHeader,
  StyledMedicationRemoved,
  StyledMedicationRemovedText,
  StyledPane,
  UndoButton,
  UndoButtonWrapper,
}
