import { AvailableShippingOptionsResponse } from '@vpharm-platform/shared'
import { createContext, useCallback, useContext, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { PaymentCardType, useCustomerProfile } from '../../hooks'
import { CartData, Medication, PatientAddress } from '../../interfaces'
import { useCart } from '../../pages/Cart/hooks/useCart'
import { cartData, haveColdChainMedication, OrderMode, orderModeAtom, PriceDetailsType, selectedPatientTokenAtom } from '../../persistRecoil'
import { Address, TaxService } from '../../services/taxService'
import { useLDContextWithLocalStorage } from '../LDProvider'

export interface CheckoutContextValue {
  checkoutStep: 'shipping' | 'payment' | 'review'
  selectedAddress: PatientAddress | null
  selectedShippingMethod: AvailableShippingOptionsResponse | null
  isCalculatingTax: boolean
  isErrorCalculatingTax: boolean
  orderHasControlledSubstance: boolean
  orderHasColdChainMedication: boolean
  medicationsInCart: Medication[]
  cartItems: CartData
  priceDetails: PriceDetailsType
  selectedPayment: PaymentCardType | null
  orderMode: OrderMode
  setCheckoutStep: React.Dispatch<React.SetStateAction<'shipping' | 'payment' | 'review'>>
  calculateTotalTax: () => Promise<number | undefined>
  setSelectedAddress: React.Dispatch<React.SetStateAction<PatientAddress | null>>
  setSelectedShippingMethod: (selectedMethod: AvailableShippingOptionsResponse | null) => void
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentCardType | null>>
}

export const CheckoutContext = createContext<CheckoutContextValue | undefined>(undefined)

export const useCheckoutContext = (): CheckoutContextValue => {
  const cxt = useContext(CheckoutContext)

  if (cxt === undefined) {
    throw new Error('Attempting to read CheckoutContext outside a Provider heirarchy')
  }

  return cxt
}

const CheckoutProvider: React.FunctionComponent = ({ children }) => {
  const { customerProfile } = useCustomerProfile()
  const [checkoutStep, setCheckoutStep] = useState<'shipping' | 'payment' | 'review'>('shipping')
  const [selectedAddress, setSelectedAddress] = useState<PatientAddress | null>(null)
  const [selectedShippingMethod, setSelectedShippingOption] = useState<AvailableShippingOptionsResponse | null>(null)
  const [isCalculatingTax, setIsCalculatingTax] = useState<boolean>(false)
  const [isErrorCalculatingTax, setIsErrorCalculatingTax] = useState<boolean>(false)
  const { priceDetails, setPriceDetails, medicationsInCart, orderHasControlledSubstance } = useCart()
  const patientToken = useRecoilValue(selectedPatientTokenAtom)
  const orderHasColdChainMedication = useRecoilValue(haveColdChainMedication)
  const [selectedPayment, setSelectedPaymentMethod] = useState<PaymentCardType | null>(null)
  const cartItems = useRecoilValue(cartData)
  const { orderMode } = useRecoilValue(orderModeAtom)
  const { enableInsuranceTaxOrders } = useLDContextWithLocalStorage()

  const calculateTotalTax = async (): Promise<number | undefined> => {
    if (!selectedAddress) {
      return
    }

    const taxService = new TaxService()
    const prescriptionTokens = medicationsInCart.map((prescription) => prescription.prescriptionToken)
    const address: Address = {
      address1: selectedAddress.address1,
      city: selectedAddress.city,
      state: selectedAddress.state,
      zip: selectedAddress.zip,
    }

    try {
      if (orderMode === OrderMode.CASH || enableInsuranceTaxOrders) {
        setIsCalculatingTax(true)
        const totalTax = await taxService.calculateTotalTax(prescriptionTokens, address, customerProfile.vpharmCustomerToken, patientToken, orderMode)
        setPriceDetails({
          estimatedTax: totalTax,
          estimatedShipping: priceDetails.estimatedShipping,
          subTotal: priceDetails.subTotal,
          orderTotal: priceDetails.orderTotal,
        })
        setIsCalculatingTax(false)
        return totalTax
      }
      return 0
    } catch (e) {
      setIsErrorCalculatingTax(true)
    } finally {
      setIsCalculatingTax(false)
    }
  }

  const setSelectedShippingMethod = useCallback(
    (selectedMethod: AvailableShippingOptionsResponse | null) => {
      setSelectedShippingOption(selectedMethod)
      setPriceDetails((prevState) => ({
        ...prevState,
        estimatedShipping: selectedMethod?.shipping_price ?? 0,
      }))
    },
    [setSelectedShippingOption, setPriceDetails],
  )

  const value = {
    checkoutStep,
    selectedAddress,
    selectedShippingMethod,
    isCalculatingTax,
    isErrorCalculatingTax,
    orderHasControlledSubstance,
    orderHasColdChainMedication,
    medicationsInCart,
    cartItems,
    priceDetails,
    selectedPayment,
    orderMode,
    setCheckoutStep,
    calculateTotalTax,
    setSelectedAddress,
    setSelectedShippingMethod,
    setSelectedPaymentMethod,
  }

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>
}

export default CheckoutProvider
