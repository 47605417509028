import { Spacer } from '@truepill/react-capsule'
import { LogoutButton } from 'Components/Auth'
import React from 'react'

import { accountLinks } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import NavigationLinks from './NavigationLinks'

interface Props {
  itemColor: string
  onLinkClick?: () => void
}

const AccountNavigation = ({ onLinkClick, itemColor }: Props): React.ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <>
      <NavigationLinks title='Account' onLinkClick={onLinkClick} links={accountLinks(theme)} itemColor={itemColor} />
      <Spacer size='2xl' />
      <LogoutButton onClick={onLinkClick} />
    </>
  )
}

export default AccountNavigation
