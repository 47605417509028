export enum InsuranceType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export interface InsuranceParams {
  cardholder_id: string
  rx_group?: string
  rx_bin: string
  pcn?: string
  insurance_image_name?: string | null
  relationship_to_primary_cardholder?: 'Cardholder' | 'Spouse' | 'Child' | 'Other' | 'Unknown'
  type?: InsuranceType | null
}
