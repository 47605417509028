import { theme } from '@truepill/react-capsule'
import { mediaLargerThan } from 'common/styles/variables'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  padding-top: 2.25rem;
  h5 {
    -webkit-text-fill-color: ${theme.colors['primary-700'].computedValue};
  }

  ${mediaLargerThan.tablet} {
    display: flex;
    flex-direction: column;
    max-width: 230px;

    h5 {
      font-size: 1.5rem;
      padding: 0 0 0.5rem 0;
    }
  }
`
const ContentContainer = styled.div`
  padding-bottom: 2.15rem;
  p {
    max-width: 245px;
  }
`

const Icon = styled(ReactSVG)`
  svg {
    max-width: 39px;
  }
`

const RxManagerInfoBannerContainer = styled.div``

export const StyledRxManagerInfoBanner = {
  RxManagerInfoBannerContainer,
  Icon,
  ContentContainer,
  HeaderContainer,
}
