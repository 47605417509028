import { EversanaSavingsCardConfig, ManualEntrySavingsCardConfig, PutSavingCardParams, SavingsCardProcess } from '@vpharm-platform/shared'
import { useCallback, useState } from 'react'

import { useSavingsCard } from '../../../hooks/useSavingsCard'
import { PatientSavingsCardSubmitPayload } from '../../../interfaces/PatientSavingsCard'
import { HydratedSavingsCard } from '../../../utils/savingsCardUtils'

interface UseModalOptions {
  refreshPrescriptionList: () => void
  setErrorMessage: (err: string) => void
}

interface UseModalsValues {
  handleDeleteSavingsCardClick: (card: HydratedSavingsCard) => void
  handleManageSavingsCardClick: (card: HydratedSavingsCard) => void
  uploadSavingCardImage: (image: File) => Promise<string>
  handleDeleteSavingsCard: (ndc: string | null) => Promise<void>
  selectedSavingsCard?: HydratedSavingsCard
  isDeleteSavingsCardModalOpen: boolean
  isManageSavingsCardModalOpen: boolean
  isDeletingSavingsCard: boolean
  isSubmittingSavingsCard: boolean
  isUploadingSavingCardImage: boolean
  handleSubmitSavingCard: (values: PatientSavingsCardSubmitPayload) => Promise<void>
  closeModals: () => void
  selectedSavingsCardConfig?: EversanaSavingsCardConfig | ManualEntrySavingsCardConfig | null
  cardDisplayName?: string
  savingsCardUrl?: string | null
}

export default ({ refreshPrescriptionList, setErrorMessage }: UseModalOptions): UseModalsValues => {
  const [selectedSavingsCard, setSelectedSavingsCard] = useState<HydratedSavingsCard>()
  const [isDeleteSavingsCardModalOpen, setIsDeleteSavingsCardModalOpen] = useState(false)
  const [isManageSavingsCardModalOpen, setIsManageSavingsCardModalOpen] = useState(false)
  const [isDeletingSavingsCard, setIsDeleteSavingsCard] = useState(false)
  const [isSubmittingSavingsCard, setIsSubmittingSavingsCard] = useState(false)

  const { uploadSavingCardImage, submitSavingCard, deleteSavingCard, isUploadingSavingCardImage } = useSavingsCard()

  const handleDeleteSavingsCard = useCallback(async () => {
    if (!selectedSavingsCard?.prescription?.prescriptionToken || !selectedSavingsCard.ndc) return
    setIsDeleteSavingsCard(true)
    await deleteSavingCard(selectedSavingsCard?.prescription?.prescriptionToken, selectedSavingsCard.ndc)
    setIsDeleteSavingsCard(false)
    setSelectedSavingsCard(undefined)
    refreshPrescriptionList()
  }, [selectedSavingsCard, refreshPrescriptionList, deleteSavingCard])

  const handleDeleteSavingsCardClick = (card: HydratedSavingsCard) => {
    setSelectedSavingsCard(card)
    setIsDeleteSavingsCardModalOpen(true)
  }

  const handleManageSavingsCardClick = (card: HydratedSavingsCard) => {
    setSelectedSavingsCard(card)
    setIsManageSavingsCardModalOpen(true)
  }

  const closeModals = () => {
    setIsDeleteSavingsCardModalOpen(false)
    setIsManageSavingsCardModalOpen(false)
  }

  const savingsCardConfig = selectedSavingsCard?.prescription?.savingsCard?.savingsCardConfig

  const handleSubmitSavingCard = async (values: PatientSavingsCardSubmitPayload): Promise<void> => {
    try {
      if (!selectedSavingsCard?.prescription?.prescriptionToken) return
      setIsSubmittingSavingsCard(true)
      const submitSavingsCardPayload: PutSavingCardParams = {
        ndc: selectedSavingsCard?.ndc || '',
        pcn: values.pcn || undefined,
        cardholder_id: values.cardholder_id,
        rx_bin: values.rx_bin,
        rx_group: values.rx_group || undefined,
        savings_card_image_key: values.savings_card_image_key,
      }
      await submitSavingCard(submitSavingsCardPayload, selectedSavingsCard?.prescription?.prescriptionToken)
    } catch (e) {
      setErrorMessage('Error submitting savings card')
    } finally {
      closeModals()
      setIsSubmittingSavingsCard(false)
      refreshPrescriptionList()
    }
  }

  return {
    handleDeleteSavingsCardClick,
    handleManageSavingsCardClick,
    isDeleteSavingsCardModalOpen,
    isManageSavingsCardModalOpen,
    selectedSavingsCard,
    isDeletingSavingsCard,
    isSubmittingSavingsCard,
    handleDeleteSavingsCard,
    uploadSavingCardImage,
    isUploadingSavingCardImage,
    handleSubmitSavingCard,
    closeModals,
    selectedSavingsCardConfig: selectedSavingsCard?.prescription?.savingsCard?.savingsCardConfig,
    cardDisplayName: selectedSavingsCard?.prescription?.brandDisplayName + (selectedSavingsCard?.prescription?.genericDisplayName ?? ''),
    savingsCardUrl:
      savingsCardConfig?.savingsCardProcess === SavingsCardProcess.MANUAL_ENTRY
        ? savingsCardConfig.externalSavingsCardInsuranceUrl ?? savingsCardConfig.externalSavingsCardCashUrl ?? null
        : null,
  }
}
