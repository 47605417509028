import { useAuth } from '../../context/auth-context'

interface UseSideNavigation {
  isAuthenticated: boolean
}

export const useSideNavigation = (): UseSideNavigation => {
  const {
    authState: { isAuthenticated },
  } = useAuth()

  return {
    isAuthenticated,
  }
}
