import { Text } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

interface TextContentStyleProps {
  space?: string
}

export const B = styled.a`
  font-weight: bold;
`
export const SpacedText = styled(Text)`
  margin-bottom: ${(props: TextContentStyleProps) => props.space ?? '1rem'};
`
