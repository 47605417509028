import { PrescriptionAvailabilityResponse } from '@vpharm-platform/shared'
import { adminLogin, impersonatedUserToken, selectedPatientTokenAtom } from 'persistRecoil'
import { atom, selector } from 'recoil'
import { prescriptionService } from 'services/prescriptionService'

import { customerProfileAtom } from '../../persistRecoil/customerProfile'

export const fetchPrescriptionsByAvailability = selector<PrescriptionAvailabilityResponse>({
  key: 'fetchPrescriptionsByAvailability',
  get: async ({ get }) => {
    const customerProfile = get(customerProfileAtom)
    const selectedPatientToken = get(selectedPatientTokenAtom)
    const customerToken = customerProfile.vpharmCustomerToken
    const userToImpersonate = get(impersonatedUserToken)
    const isAdmin = get(adminLogin)
    if (!customerToken || !selectedPatientToken || (isAdmin && !userToImpersonate)) {
      return {
        availableToOrder: [],
        unavailableToOrder: [],
      }
    }
    return await prescriptionService.fetchPrescriptionsGroupedByAvailability(customerToken, selectedPatientToken)
  },
})

export const prescriptionsByAvailability = atom<PrescriptionAvailabilityResponse>({
  key: 'prescriptionsByAvailability',
  default: fetchPrescriptionsByAvailability,
})
