import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Header, Spacer } from '@truepill/react-capsule'
import { CheckIcon } from 'assets/Icons'
import { keys } from 'constants/keyboard'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { FormContainer, StyledEditButton, StyledHeaderContainer, StyledQuestionLabel } from './styledComponents'

interface AccordionFormProps {
  children?: React.ReactNode
  header: string
  subheader?: string
  id: string
  isOpen: boolean
  isSubmitted: boolean
  editVisibility?: boolean
  onEdit?: (id: string) => void
}

const AccordionForm: React.FC<AccordionFormProps> = ({ children, header, subheader, id, isOpen, isSubmitted, onEdit, editVisibility = true }) => {
  const { theme } = useContentfulTheme()

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    ;[keys.ENTER, keys.SPACE].includes(e.key) && onEdit && onEdit(id)
    e.preventDefault()
  }

  const title = (
    <StyledHeaderContainer>
      <StyledQuestionLabel>
        {isSubmitted && (
          <>
            <CheckIcon vpTheme={theme} />
            <Spacer size='xs' />
          </>
        )}
        <div>
          <Header bold variant='2xl'>
            {header}
          </Header>
          {isOpen && !!subheader && <p>{subheader}</p>}
        </div>
      </StyledQuestionLabel>
      {isSubmitted && editVisibility && onEdit && (
        <StyledEditButton role='button' onKeyDown={handleKeyDown} onClick={() => onEdit(id)}>
          Edit
        </StyledEditButton>
      )}
    </StyledHeaderContainer>
  )

  const open = isOpen || isSubmitted

  return (
    <Accordion type='single' style={{ width: '100%' }} value={open ? '0' : '1'}>
      <AccordionItem value='0'>
        <AccordionTrigger
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
          }}
          withChevron={false}
          css={{ borderRadius: !open ? '8px' : '8px 8px 0px 0px', backgroundColor: theme.colors['gray-300'] }}
        >
          {title}
        </AccordionTrigger>
        <AccordionContent css={{ fontFamily: '$base' }}>
          <FormContainer vpTheme={theme}>{children}</FormContainer>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default AccordionForm
