import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'

import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'

interface Props {
  children?: React.ReactNode
  href?: string
  style?: React.CSSProperties
}

const SupportLink = (props: Props): ReactElement => {
  const { children = 'customer support', href = '/contact', style } = props
  const { trackLinkClickEvent } = useAnalytics()

  return (
    <StyledSupportLink style={style} href={href} onClick={() => trackLinkClickEvent('contact_support', 'navigate user to support', LinkOrigin.Body)}>
      {children}
    </StyledSupportLink>
  )
}

const StyledSupportLink = styled.a`
  color: inherit;
`

export default SupportLink
