import { GetHealthHistoryDto, HealthHistoryOptions } from 'services/dto'

export const patientHealthHistory: GetHealthHistoryDto = {
  allergies: HealthHistoryOptions.No,
  allergiesDetails: [],
  healthConditions: HealthHistoryOptions.Yes,
  healthConditionsDetails: ['Glaucoma', 'Diabetes', 'Neuropathy'],
  medicationsAndSupplements: HealthHistoryOptions.Yes,
  medicationsAndSupplementsDetails: ['Novolin R', 'Lipitor'],
  pregnantOrLactating: HealthHistoryOptions.No,
  pregnant: HealthHistoryOptions.No,
}
