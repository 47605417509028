import { Spacer, Text } from '@truepill/react-capsule'
import { MedicationInfo } from '@vpharm-platform/shared'
import React, { useState } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedSelect } from '../../common/styledComponents/ThemedSelect'
import { InsurancePageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import OptionComponent, { BaseOptions } from './AddCardModalOptions'
import AddCardPrescriptionSelect from './AddCardPrescriptionSelect'
import { ButtonsContainer, Container, StyledHeader } from './styledComponents'

interface PropTypes {
  handleAddInsurance: () => void
  handleAddSavingsCard: (selectedMedication: MedicationInfo) => void
  onDismiss: () => void
  insuranceDisabled: boolean
  savingsCardDisabled: boolean
}

enum CardType {
  INSURANCE = 'insurance',
  SAVINGS = 'savings',
}
interface CardTypeOption extends BaseOptions {
  value: CardType
}

// This component is a work in progress and should be used within another component as the first step in the flow
const AddCardForm: React.FC<PropTypes> = ({ handleAddInsurance, handleAddSavingsCard, onDismiss, insuranceDisabled, savingsCardDisabled }) => {
  const { theme } = useGetPageContent<InsurancePageMicrocopy>('insurancePage')

  const [selectedPrescription, setSelectedPrescription] = useState<MedicationInfo>()

  const cardTypeOptions = [
    { label: 'Insurance', value: CardType.INSURANCE, vpTheme: theme },
    { label: 'Savings card', value: CardType.SAVINGS, vpTheme: theme },
  ]

  const [cardType, setCardType] = useState<CardTypeOption>(cardTypeOptions[0])

  const handleContinue = () => {
    const { value } = cardType
    value === CardType.INSURANCE && handleAddInsurance()
    value === CardType.SAVINGS && selectedPrescription && handleAddSavingsCard(selectedPrescription)
    onDismiss()
  }

  const isCardTypeOptionDisabled = ({ value }: CardTypeOption) => {
    if (value === CardType.INSURANCE) return insuranceDisabled
    if (value === CardType.SAVINGS) return savingsCardDisabled
  }

  const canContinue = cardType.value === CardType.INSURANCE || (cardType && selectedPrescription)

  return (
    <Container>
      <StyledHeader>
        <ThemedHeader vpTheme={theme} bold variant='3xl'>
          Add Insurance or savings Card
        </ThemedHeader>
        <Spacer size='md' />
        <Text>Use primary or secondary insurance and savings cards to pay for your prescriptions.</Text>
      </StyledHeader>
      <Spacer size='xl' />
      <ThemedSelect
        options={cardTypeOptions}
        label='Card type'
        selectedKey='label'
        value={cardType}
        onChange={setCardType}
        optionComponent={OptionComponent}
        isOptionDisabled={isCardTypeOptionDisabled}
      />
      {cardType.value === CardType.SAVINGS && (
        <>
          <Spacer size='lg' />
          <AddCardPrescriptionSelect setSelectedPrescription={setSelectedPrescription} />
        </>
      )}
      <Spacer size='md' />
      <Spacer size='lg' />
      <ButtonsContainer>
        <ThemedButton onClick={handleContinue} vpTheme={theme} disabled={!canContinue}>
          Continue
        </ThemedButton>
      </ButtonsContainer>
    </Container>
  )
}

export default AddCardForm
