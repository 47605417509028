import { Divider, Header, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { breakpoint, mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

const OrderSummaryContainer = styled.div`
  min-width: 270px;
  && {
    font-family: Lato, sans-serif;
  }
`

const TitleContainer = styled(Header)`
  font-family: Lato, sans-serif;
  padding-bottom: 1rem;
  background-color: transparent;

  && {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`

const OrderDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`

const StyledText = styled(Text)<{ $textColor: string; $textLabel?: boolean }>`
  display: flex;
  font-family: Lato, sans-serif;
  white-space: nowrap;
  color: ${({ $textColor }) => $textColor ?? defaultTheme.colors['typography-medium']};
  text-align: right;

  ${mediaLargerThan.tablet} {
    padding-right: ${(props) => (props.$textLabel ? '3.75rem' : '0')};
  }
`

const StyledHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.5rem;
`

const StyledQuestionLabel = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`

const StyledEditButton = styled.div`
  font-weight: 700;
  text-decoration: underline;
  margin-left: 1em;

  && {
    border: 0px;
    padding: 0px;
  }
`

const FormContainer = styled.div<ThemedComponent>`
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 0px 0px 0.5rem 0.5rem;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  padding: 2rem 1.5rem;
  width: 100%;
`

interface DetailProps {
  $columnType: boolean
  $responsiveInRow?: boolean
}

const DetailWrapper = styled.div<DetailProps>`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.$columnType ? 'column' : 'row')};
  width: 100%;
  padding-bottom: 0.5rem;

  ${breakpoint.tablet} {
    flex-direction: ${(props) => (props.$responsiveInRow && !props.$columnType ? 'row' : 'column')};
    padding-bottom: 1rem;
  }
`

const LabelDetail = styled(Text)<DetailProps>`
  width: ${(props) => (props.$columnType ? '100%' : '30%')};
  padding-left: ${(props) => (props.$columnType ? '1rem' : '0')};
  color: ${(props) => props.$columnType && defaultTheme.colors.black};
  font-weight: ${(props) => props.$columnType && 'bold'};

  ${breakpoint.tablet} {
    width: 100%;
  }
`

const ValueDetail = styled(Text)<DetailProps>`
  width: ${(props) => (props.$columnType ? '100%' : '70%')};
  padding-left: ${(props) => (props.$columnType ? '1rem' : '0')};
  color: ${(props) => props.$columnType && 'black'};
  ${breakpoint.tablet} {
    width: 100%;
  }
`

const Separator = styled(Divider)`
  margin: 1rem 0 1rem 0;
`

const OptionTitleWrapper = styled.div`
  display: flex;
`

const DefaultOption = styled(Text)<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['primary-500']};
`

interface StripeElementLabelProps {
  required: boolean
}

interface StripeElementContainerProps {
  isDisabled: boolean
  isComplete: boolean
  isFocus: boolean
  error: string
}

const StripeElementLabel = styled(Text)<StripeElementLabelProps & ThemedComponent>`
  position: relative;
  margin-bottom: 0.5rem;

  ${({ required, vpTheme }) =>
    required &&
    `::after {
      color: ${vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};
      bottom: 0;
      content: '*';
      position: absolute;
      padding-left: 1px;
    }`}

  .label-expanded {
    font-size: 1rem;

    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 0.875rem;
    }
  }
`
const TextSize = styled.div`
  div:first-child {
    padding-bottom: 0.3125rem;
  }
`
const LabelWithIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const StripeElementContainer = styled.div<StripeElementContainerProps & ThemedComponent>`
  align-items: center;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-700'] ?? defaultTheme.colors['gray-700']};
  border-radius: 8px;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding: 1.025rem 0.75rem;
  outline: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  width: 100%;

  > img {
    display: none;
    height: 1.5rem;
    width: 1.5rem;
  }

  > div {
    width: 100%;
    opacity: 1;
  }

  ${({ isComplete }) =>
    isComplete &&
    `> img {
      display: flex;
    }`}

  ${({ error, isDisabled, vpTheme }) =>
    error &&
    !isDisabled &&
    `border: 1px solid ${vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};
    background: ${vpTheme.colors['functional-error-light'] ?? defaultTheme.colors['functional-error-light']};`}

  ${({ isFocus, vpTheme }) => isFocus && `border: 3px solid ${vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};`}

  ${({ error, isFocus, vpTheme }) =>
    error && isFocus && `border: 3px solid ${vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};`}
`

const CvvLabelContainer = styled.div`
  display: flex;
`

export {
  CvvLabelContainer,
  DefaultOption,
  DetailWrapper,
  FormContainer,
  LabelDetail,
  LabelWithIcon,
  OptionTitleWrapper,
  OrderDetailItem,
  OrderSummaryContainer,
  Separator,
  StripeElementContainer,
  StripeElementLabel,
  StyledEditButton,
  StyledHeaderContainer,
  StyledQuestionLabel,
  StyledText,
  TextSize,
  TitleContainer,
  ValueDetail,
}
