import styled from 'styled-components/macro'

import { ThemedSelect } from '../../common/styledComponents/ThemedSelect'
import { mediaLargerThan } from '../../common/styles/variables'

const DobWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.5rem;
  ${mediaLargerThan.mobile} {
    column-gap: 1rem;
  }
`

const TextFieldContainer = styled.div`
  label {
    font-weight: normal;
  }
`

const StyledSelect = styled((props) => <ThemedSelect {...props} />)`
  ul {
    max-height: 10rem;
  }
  button {
    padding: 0 12px;
  }
  button:focus,
  button:focus-visible,
  button:active:enabled,
  button[data-active='true'] {
    padding: 0 10px;
  }
  @media (min-width: 375px) {
    button > div > div:first-child {
      width: auto;
    }
    svg {
      flex-shrink: 0;
    }
  }
`

export { DobWrapper, StyledSelect, TextFieldContainer }
