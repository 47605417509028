import { Divider, Spacer, Text } from '@truepill/react-capsule'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { mediaLargerThan, MOBILE_BREAK_POINT } from '../../../common/styles/variables'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'
import { IGalleryCard, IGalleryFields } from '../../../types/generated/contentful'
import GalleryCard from './GalleryCard'

const getDynamicallWidth = (isLargerThanLaptop: boolean, isLargerThanTablet: boolean, isLargerThanMobile: boolean, width: number) => {
  switch (true) {
    case isLargerThanLaptop:
      return '32%'
    case isLargerThanTablet:
      return '31%'
    case isLargerThanMobile:
      return '30%'
    case width >= 395 && width < MOBILE_BREAK_POINT:
      return '100%'
    default:
      return '100%'
  }
}

const getDynamicalHeight = (isLargerThanLaptop: boolean, isLargerThanTablet: boolean, isLargerThanMobile: boolean, width: number) => {
  switch (true) {
    case isLargerThanLaptop:
      return '400px'
    case isLargerThanTablet:
      return '300px'
    case isLargerThanMobile:
      return '250px'
    case width >= 395 && width < 470:
      return '400px'
    case width >= 470 && width < MOBILE_BREAK_POINT:
      return '450px'
    default:
      return '330px'
  }
}

const getGalleryCardsByRows = (
  galleryCards: IGalleryCard[],
): {
  firstRow: IGalleryCard[]
  secondRow: IGalleryCard[]
  thirdRow: IGalleryCard[]
} => {
  const firstRow = galleryCards.slice(0, 3)
  const secondRow = galleryCards.slice(3, 6)
  const thirdRow = galleryCards.slice(6, 9)
  return { firstRow, secondRow, thirdRow }
}

const Gallery: React.FC<IGalleryFields> = ({ name, headerText, headerTextColor, galleryCards }) => {
  const { isLargerThanMobile, isLargerThanTablet, isLargerThanLaptop, width } = useWindowDimensions()
  const [seeMore, setSeeMore] = useState(true)
  const [displaySeeMoreButton, setDisplaySeeMoreButton] = useState(false)
  const rows = getGalleryCardsByRows(galleryCards)

  useEffect(() => {
    if (width >= 395 && width < 577) {
      setDisplaySeeMoreButton(true)
      setSeeMore(false)
    } else {
      setDisplaySeeMoreButton(false)
      setSeeMore(true)
    }
  }, [width])

  const handleSeeMoreButtonClick = useCallback(() => setSeeMore(!seeMore), [seeMore])

  return (
    <Container key={name}>
      <Text bold css={{ color: headerTextColor }}>
        {headerText}
      </Text>
      <DividerContainer>
        <Divider size={'2px'} css={{ borderColor: headerTextColor }} />
      </DividerContainer>
      <GalleryCardContainer>
        {rows.firstRow.map((item) => {
          return (
            <GalleryCard
              key={item.fields.name}
              name={item.fields.name}
              image={item.fields.image}
              title={item.fields.title}
              subtitle={item.fields.subtitle}
              height={getDynamicalHeight(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
              width={getDynamicallWidth(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
              borderRadius={item.fields.borderRadius}
            ></GalleryCard>
          )
        })}
      </GalleryCardContainer>
      {rows.secondRow.length > 0 && seeMore && (
        <GalleryCardContainer>
          {rows.secondRow.map((item) => {
            return (
              <GalleryCard
                key={item.fields.name}
                name={item.fields.name}
                image={item.fields.image}
                title={item.fields.title}
                subtitle={item.fields.subtitle}
                height={getDynamicalHeight(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
                width={getDynamicallWidth(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
                borderRadius={item.fields.borderRadius}
              ></GalleryCard>
            )
          })}
        </GalleryCardContainer>
      )}
      {rows.thirdRow.length > 0 && seeMore && (
        <GalleryCardContainer>
          {rows.thirdRow.map((item) => {
            return (
              <GalleryCard
                key={item.fields.name}
                name={item.fields.name}
                image={item.fields.image}
                title={item.fields.title}
                subtitle={item.fields.subtitle}
                height={getDynamicalHeight(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
                width={getDynamicallWidth(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
                borderRadius={item.fields.borderRadius}
              ></GalleryCard>
            )
          })}
        </GalleryCardContainer>
      )}
      {displaySeeMoreButton && (
        <>
          <Spacer />
          <SeeLessButton color={headerTextColor} variant='body-sm' onClick={handleSeeMoreButtonClick}>
            {seeMore ? 'See less' : 'See more'}
          </SeeLessButton>
        </>
      )}
      <Spacer />
    </Container>
  )
}

const Container = styled.div`
  with: 100%;
  padding: 0 2rem;
  ${mediaLargerThan.tablet} {
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
  }
`
const GalleryCardContainer = styled.div`
  display: inline;
  align-self: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  ${mediaLargerThan.mobile} {
    display: flex;
    align-self: center;
  }
`

const DividerContainer = styled.div`
  witdh: 273px;
  ${mediaLargerThan.tablet} {
    width: 193.54px;
  }
`
const SeeLessButton = styled(Text)`
  color: ${(props: { color: string }) => props.color};
  cursor: pointer;
  margin-top: 0.25rem;
  text-decoration: underline;
`

export default Gallery
