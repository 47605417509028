import { useMemo, useState } from 'react'

/**
 * Returns a **boolean** indicating If the screen size has, at least, the minimum width specified as parameter.
 * @param minWidth Minimum screen width in **pixels**
 */
const useMatchMinMediaQuery = (minWidth: number): boolean => {
  const mediaQueryDesktop = useMemo(() => window.matchMedia(`(min-width: ${minWidth.toString()}px)`), [minWidth])
  const [isMinWidth, setIsMinWidth] = useState(mediaQueryDesktop.matches)

  mediaQueryDesktop.onchange = () => setIsMinWidth((prevState) => !prevState)

  return isMinWidth
}

export { useMatchMinMediaQuery }
