import { Header, Text } from '@truepill/react-capsule'
import styled from 'styled-components'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

const SelfEnrollmentBannerContainer = styled.div`
  position: relative;
  width: 90%;
  padding-bottom: 3rem;

  ${mediaLargerThan.tablet} {
    max-width: 360px;
    padding-bottom: 0;
  }
`

const HeaderContainer = styled.div<ThemedComponent>`
  padding: 36px 28px 25px 24px;
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-300']};
  border-radius: 12px 12px 0 0;
`

const TextContainer = styled.div`
  position: relative;
  z-index: 1;
`

const StyledMainHeader = styled(Header)`
  max-width: 230px;

  ${mediaLargerThan.laptop} {
    max-width: 250px;
  }

  ${mediaLargerThan.desktopSm} {
    max-width: 265px;
  }
`

const StyledSubHeader = styled(Header)<ThemedComponent>`
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
  font-size: 1.125rem;
`

const StyledInfoOne = styled(Header)<ThemedComponent>`
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['primary-700']};
  max-width: 240px;

  ${mediaLargerThan.laptop} {
    max-width: 250px;
  }

  ${mediaLargerThan.desktopSm} {
    max-width: 265px;
  }
`

const ContentContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-700']};
  padding: 20px 24px 36px 24px;
  border-radius: 0 0 12px 12px;
  position: relative;
  z-index: 1;
`

const ContentHeader = styled(Header)<ThemedComponent>`
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
`

const ContentStepContainer = styled.div<ThemedComponent>`
  padding: 0.75rem 0;
  h6,
  p {
    -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  }
`

const ContentSubtext = styled(Text)`
  font-weight: 400;
`

const Rectangle = styled.div`
  width: 245px;
  height: 318px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  border-radius: 77px;
  z-index: 0;
  transform: rotate(46.6deg);
  top: -6rem;
  left: -4rem;
`

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;

  ${mediaLargerThan.tablet} {
    display: none;
  }
`

const CtaButton = styled(ThemedButton)`
  &:enabled {
    background-color: ${({ vpTheme }) => vpTheme.colors['primary-100']};
    color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
    &:hover {
      background-color: ${({ vpTheme }) => vpTheme.colors['primary-100']};
      color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const EditButton = styled(ThemedButton)`
  text-decoration: underline;
  margin-right: 1rem;
`

export {
  ButtonContainer,
  ContentContainer,
  ContentHeader,
  ContentStepContainer,
  ContentSubtext,
  CtaButton,
  CtaContainer,
  EditButton,
  HeaderContainer,
  Rectangle,
  SelfEnrollmentBannerContainer,
  StyledInfoOne,
  StyledMainHeader,
  StyledSubHeader,
  TextContainer,
}
