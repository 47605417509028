import { Header, Spacer, Text } from '@truepill/react-capsule'
import React from 'react'
import { AlertOctagon } from 'react-feather'
import { Link } from 'react-router-dom'

import { useContentfulTheme } from '../../hooks'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { INotFoundPageFields } from '../../types/generated/contentful'
import { StyledIcon, StyledSubTitle, StyledTitle } from './styledComponents'

const NotFound: React.FC<INotFoundPageFields> = ({ title, subTitle, icon }) => {
  const { isLargerThanMobile } = useWindowDimensions()
  const { theme } = useContentfulTheme()

  return (
    <>
      {isLargerThanMobile ? <Spacer size='2xl' /> : <Spacer size='xl' />}
      <StyledIcon>{icon?.fields.file.url ? <img src={`https:${icon.fields.file.url}`} alt={'Icon'} /> : <AlertOctagon size='42px' />}</StyledIcon>
      <Spacer size='xl' />
      <StyledTitle>
        <Header variant='5xl'>{title}</Header>
      </StyledTitle>
      <Spacer size='md' />
      <StyledSubTitle vpTheme={theme}>
        <Text>
          {subTitle || `The page you’re looking for might have been removed or is temporarily unavailable.`}
          <Spacer size='xl' />
          <Link to='/'>Home</Link>{' '}
        </Text>
      </StyledSubTitle>
      {isLargerThanMobile ? <Spacer size='7xl' /> : <Spacer size='xl' />}
    </>
  )
}

export default NotFound
