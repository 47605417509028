import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

const StyledEmptyCartMessageContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-100']};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 1rem;
  font-family: Lato;
  padding: 4rem 0;
`

const StyledEmptyCartMessage = styled.div<ThemedComponent>`
  font-size: 1.25rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
  line-height: 1.625rem;
  font-weight: 700;
`

const StyledLink = styled(Link)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  line-height: 1.5rem;
  font-weight: 400;
  padding-top: 0.75rem;
`

export { StyledEmptyCartMessage, StyledEmptyCartMessageContainer, StyledLink }
