import { PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import { Text } from '@truepill/react-capsule'
import { OrderParams, ShippingAddressParams, SubmitOrderResponse } from '@vpharm-platform/shared'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ORDER_CONFIRMATION_PATH } from '../../../constants'
import { usePatientProfile, useRefreshPrescriptionList } from '../../../hooks'
import { CheckoutPageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../../hooks/contentful/useGetPageContent'
import { OrderMode } from '../../../persistRecoil'
import { orderService } from '../../../services'
import { useCheckoutContext } from '../CheckoutProvider'

interface Props {
  patientToken: string
  customerToken: string
}

const ApplePay: React.FC<Props> = ({ patientToken, customerToken }) => {
  const { cartItems, selectedAddress, selectedPayment, orderMode, priceDetails, selectedShippingMethod } = useCheckoutContext()
  const { patientProfile } = usePatientProfile()
  const { theme } = useGetPageContent<CheckoutPageMicrocopy>('checkoutPage')
  const stripe = useStripe()
  const elements = useElements()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)
  const [submitOrder, setSubmitOrder] = useState<boolean>(false)
  const [orderToken, setOrderToken] = useState<string>('')
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const history = useHistory()

  const goToOrderConfirmation = useCallback(() => {
    try {
      refreshPrescriptionList()
    } catch (e) {}
    history.replace(ORDER_CONFIRMATION_PATH.replace(':order_id', orderToken), { prevPath: '/checkout' })
  }, [history, orderToken, refreshPrescriptionList])

  useEffect(() => {
    if (!stripe || !elements) return

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Apple Pay Demo',
        amount: Math.round(priceDetails.orderTotal * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr)
      }
    })

    const preparePayload = () => {
      if (!selectedAddress) {
        throw new Error('Missing shipping address')
      }

      if (!selectedShippingMethod) {
        throw new Error('Missing shipping method')
      }

      if (!selectedPayment && priceDetails.orderTotal !== 0) {
        throw new Error('Missing payment method')
      }

      const shippingAddress: ShippingAddressParams = {
        existing_address_id: selectedAddress.id ?? undefined,
        name: selectedAddress.name,
        address1: selectedAddress.address1,
        address2: selectedAddress.address2 ?? undefined,
        zip: selectedAddress.zip,
        state: selectedAddress.state,
        city: selectedAddress.city,
      }

      const prescriptionTokens = cartItems.medications.map((m) => m.prescriptionToken).filter((p) => !!p || p !== '')

      const data: OrderParams = {
        orderType: orderMode === OrderMode.INSURANCE ? 'insurance' : 'cash',
        shipping: {
          shipping_state_customer_id: selectedShippingMethod.id,
          shipping_address: shippingAddress,
        },
        prescriptions: prescriptionTokens.length > 0 ? prescriptionTokens : [],
        ...(priceDetails.orderTotal > 0 && {
          payment: {
            payment_method_id: selectedPayment?.paymentMethodId,
          },
        }),
        contact: {
          email: patientProfile?.email || '',
          phone_number: patientProfile?.phone || '',
        },
      }

      return { data, prescriptionTokens, shippingAddress }
    }

    pr.on('paymentmethod', async (e) => {
      // when a new payment method is available create payment intent
      const { data } = preparePayload()
      const updatedOrderParam: OrderParams = {
        ...data,
        payment: {
          payment_method_id: e.paymentMethod.id,
          payment_intent_id: undefined,
        },
      }
      let res: SubmitOrderResponse = await orderService.submitOrder(customerToken, patientToken, updatedOrderParam)
      while (!('orderToken' in res)) {
        const result = res
        if (!stripe) {
          throw new Error('Stripe not defined')
        }
        const actionResult = await stripe.handleCardAction(result.clientSecret)
        res = await orderService.submitOrder(customerToken, patientToken, {
          ...data,
          payment: {
            ...updatedOrderParam.payment,
            payment_intent_id: actionResult.paymentIntent?.id,
          },
        })
      }
      e.complete('success')
      if (res.orderToken) {
        setOrderToken(res.orderToken)
        setSubmitOrder(true)
      }
    })
  })

  return (
    <>
      <Text bold> Apple Pay NOTE: </Text>
      <Text> If you are testing using your personal apple pay wallet, you will NOT get charged.</Text>
      {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }}></PaymentRequestButtonElement>}
      {submitOrder && (
        <SubmitContainer>
          <ThemedButton type='submit' onClick={goToOrderConfirmation} vpTheme={theme}>
            {'Confirm'}
          </ThemedButton>
        </SubmitContainer>
      )}
    </>
  )
}

const SubmitContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: end;
`

export default ApplePay
