import { Accordion } from '@truepill/react-capsule'
import { FAQ_PATH } from 'constants/navigation-links'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import React, { useState } from 'react'

import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { IFaqSectionFields } from '../../types/generated/contentful'
import FAQActionButton from './FAQActionButton'
import FAQQuestion from './FAQQuestion'
import { FaqSectionContainer, HeaderWithButton, HeaderWithUnderline, SectionTitle, StyledHeader, Underline } from './styledComponents'

enum SeeMoreButtonLocation {
  BOTTOM = 'Bottom',
  TOP_RIGHT = 'Top Right',
}

const FAQSection: React.FC<IFaqSectionFields> = (props) => {
  const { questions, title, icon, displayTitleUnderline, seeMoreButtonLocation, headerVariant } = props

  const [lastOpenedQuestion, setLastOpenedQuestion] = useState<Record<string, string>>({})
  const { isLargerThanTablet } = useWindowDimensions()

  const { trackAccordionChangeEvent } = useAnalytics()
  const { theme: vpTheme } = useContentfulTheme()

  const defaultHeaderSize = isLargerThanTablet ? '4xl' : '2xl'
  const displayTopRightButton = seeMoreButtonLocation === SeeMoreButtonLocation.TOP_RIGHT && isLargerThanTablet
  const displayBottomButton = seeMoreButtonLocation === SeeMoreButtonLocation.BOTTOM || !isLargerThanTablet

  const onToggleQuestion = (question: string) => {
    const isOpen = !!question
    trackAccordionChangeEvent('faq_question_toggle', 'question', isOpen)
    if (isOpen) {
      setLastOpenedQuestion({ ...lastOpenedQuestion, [title]: question })
    }
  }

  return (
    <FaqSectionContainer {...props}>
      <SectionTitle>
        {icon && <img src={icon.fields.file.url} alt={icon.fields.title} />}
        <HeaderWithButton>
          <HeaderWithUnderline>
            <StyledHeader variant={headerVariant || defaultHeaderSize} vpTheme={vpTheme}>
              {title}
            </StyledHeader>
            {displayTitleUnderline && <Underline vpTheme={vpTheme} />}
          </HeaderWithUnderline>
          {displayTopRightButton && <FAQActionButton caption='See all' path={FAQ_PATH} variant='primary' fullWidth={false} usePadding={false} />}
        </HeaderWithButton>
      </SectionTitle>
      <Accordion type='single' collapsible onValueChange={(question) => onToggleQuestion(question)}>
        {questions.map((question, index) => (
          <FAQQuestion key={index} {...question} />
        ))}
      </Accordion>
      {displayBottomButton && (
        <FAQActionButton
          caption='See all FAQ'
          path={FAQ_PATH}
          variant={seeMoreButtonLocation === SeeMoreButtonLocation.TOP_RIGHT ? 'secondary-text' : 'primary-outline'}
        />
      )}
    </FaqSectionContainer>
  )
}

export default FAQSection
