import { sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'
import { PatientInsurance, PatientInsuranceSubmitPayload } from '../interfaces'

export interface PatientInsuranceService {
  getPatientInsurances(customerToken: string, patientToken: string): Promise<PatientInsurance[]>
  submitPatientInsurance(customerToken: string, patientToken: string, values: PatientInsuranceSubmitPayload, id?: string): void
  deletePatientInsurance(customerToken: string, patientToken: string, id: string): Promise<void>
}

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

class PatientInsuranceApiService implements PatientInsuranceService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async getPatientInsurances(customerToken: string, patientToken: string): Promise<PatientInsurance[]> {
    try {
      const url = `${this.baseUrl}/patient_insurances`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      const result = await axiosAuth.get<PatientInsurance[]>(url, config)

      return result.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async submitPatientInsurance(
    customerToken: string,
    patientToken: string,
    patientInsuranceDetails: PatientInsuranceSubmitPayload,
    truepillInsuranceToken?: string,
  ): Promise<void> {
    try {
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      if (truepillInsuranceToken) {
        const url = `${this.baseUrl}/patient_insurances/${truepillInsuranceToken}`
        await axiosAuth.put<PatientInsuranceSubmitPayload>(url, patientInsuranceDetails, config)
        return
      }

      const url = `${this.baseUrl}/patient_insurances`
      await axiosAuth.post<PatientInsuranceSubmitPayload>(url, patientInsuranceDetails, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async deletePatientInsurance(customerToken: string, patientToken: string, truepillInsuranceToken: string): Promise<void> {
    try {
      const url = `${this.baseUrl}/patient_insurances/${truepillInsuranceToken}`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }
      await axiosAuth.delete<void>(url, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
}

export const patientInsuranceService: PatientInsuranceApiService = new PatientInsuranceApiService()
