import React from 'react'
import styled from 'styled-components/macro'

import { media } from '../../../common/styles/variables'

type ComponentProps = React.PropsWithChildren<{
  'data-testid'?: string
  mobileBreakpoint?: string
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ItemCardWrapper = styled.div<{ mobileBreakpoint?: string }>`
  display: grid;
  width: 100%;
  @media (min-width: ${({ mobileBreakpoint }) => mobileBreakpoint || media.tablet}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  grid-template-columns: repeat(1, minmax(0, 1fr));
`

const ItemCardWrapperComponent: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
  return (
    <ItemCardWrapper className={props.className} data-testid={props['data-testid']} mobileBreakpoint={props.mobileBreakpoint}>
      {props.children}
    </ItemCardWrapper>
  )
}

export default ItemCardWrapperComponent
