import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const RightLeftArrow = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path d='M7 17H22' stroke={vpTheme.colors.iconDefault} strokeWidth='1.75' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M18 21L22 17L18 13' stroke={vpTheme.colors.iconDefault} strokeWidth='1.75' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M18 7H2' stroke={vpTheme.colors.iconDefault} strokeWidth='1.75' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M6 11L2 7L6 3' stroke={vpTheme.colors.iconDefault} strokeWidth='1.75' strokeMiterlimit='10' strokeLinecap='round' />
    </svg>
  )
}
