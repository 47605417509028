import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'
import { breakpoint } from '../../common/styles/variables'

const MainContainer = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`

const MainHeader = styled(ThemedHeader)<ThemedComponent>`
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${breakpoint.mobile} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

const Form = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  input[type='password'] {
    -webkit-appearance: none;
  }
`

const SignUpButtonContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`

const CheckboxContainer = styled.div<ThemedComponent>`
  display: flex;
  color: ${(props) => props.theme?.colors?.['typography-medium'] ?? defaultTheme.colors['typography-medium']};
  margin-top: 1rem;

  a {
    color: ${(props) => props.theme?.colors?.['typography-medium'] ?? defaultTheme.colors['typography-medium']};
  }
`

const PasswordRequirementContainer = styled.div``

const PasswordRequirementListItem = styled.li<{ passed: boolean; theme: VpTheme }>`
  position: relative;
  margin: 0.5rem 0;
  color: ${(props) =>
    props.passed
      ? props.theme?.colors?.['functional-success-dark'] ?? defaultTheme.colors['functional-success-dark']
      : props.theme?.colors?.['typography-medium'] ?? defaultTheme.colors['typography-medium']};
  &:before {
    position: absolute;
    left: -1rem;
    ${(props) => (props.passed ? `content: "✓";` : `content: "•";`)}
  }
`

const PasswordRequirementList = styled.ul`
  margin-left: 1rem;
`

export const StyledSignUp = {
  MainContainer,
  MainHeader,
  Form,
  SignUpButtonContainer,
  CheckboxContainer,
  PasswordRequirementContainer,
  PasswordRequirementListItem,
  PasswordRequirementList,
}
