import { Text } from '@truepill/react-capsule'

import { OrderMode } from '../../../../persistRecoil/orderMode'
import { StyledPrescriptionPrice } from './styledComponents'

interface PrescriptionPriceProps {
  checkoutPrice: number
  retailPrice: number | null
  savingsCardPrice: number | null
  orderMode: OrderMode
  isMixedCartEnabled?: boolean
  selectedPriceOption?: 'cash' | 'insurance'
  // below prop will be removed after we remove ff for price transparency
  allowPriceTransparency: boolean
}

interface InsurancePriceViewProps {
  checkoutPrice: number
  // below prop will be removed after we remove ff for price transparency
  allowPriceTransparency: boolean
}

interface CashPriceViewProps {
  checkoutPrice: number
  savingsCardPrice: number | null
  retailPrice: number | null
  // below prop will be removed after we remove ff for price transparency
  allowPriceTransparency: boolean
}

const InsurancePriceView = ({ checkoutPrice, allowPriceTransparency }: InsurancePriceViewProps): React.ReactElement => (
  <StyledPrescriptionPrice.CheckoutPriceHeader data-testid='insurancePrice' allowPriceTransparency={allowPriceTransparency}>
    ${checkoutPrice.toFixed(2)}
  </StyledPrescriptionPrice.CheckoutPriceHeader>
)

const CashPriceView = ({ checkoutPrice, savingsCardPrice, retailPrice, allowPriceTransparency }: CashPriceViewProps): React.ReactElement => {
  // No savings card was applied to the medication
  const isMedPriceEqualToRetailPrice = checkoutPrice === retailPrice

  if (!savingsCardPrice || !isMedPriceEqualToRetailPrice) {
    return (
      <StyledPrescriptionPrice.CheckoutPriceHeader allowPriceTransparency={allowPriceTransparency}>
        ${checkoutPrice.toFixed(2)}
      </StyledPrescriptionPrice.CheckoutPriceHeader>
    )
  }

  return (
    <>
      <StyledPrescriptionPrice.CheckoutPriceHeader allowPriceTransparency={allowPriceTransparency}>
        ${savingsCardPrice.toFixed(2)}
      </StyledPrescriptionPrice.CheckoutPriceHeader>
      <Text variant='body' bold>
        with savings card*
      </Text>
      <StyledPrescriptionPrice.RetailPriceText variant='body'>{`cash price: $${retailPrice.toFixed(2)}`}</StyledPrescriptionPrice.RetailPriceText>
    </>
  )
}

const PrescriptionPrice = ({
  checkoutPrice,
  orderMode,
  retailPrice,
  savingsCardPrice,
  allowPriceTransparency,
  isMixedCartEnabled,
  selectedPriceOption,
}: PrescriptionPriceProps): React.ReactElement => {
  const renderPriceView = () => {
    if (isMixedCartEnabled && selectedPriceOption === 'insurance') {
      return <InsurancePriceView checkoutPrice={checkoutPrice} allowPriceTransparency={allowPriceTransparency} />
    }

    if (isMixedCartEnabled && selectedPriceOption === 'cash') {
      return (
        <CashPriceView
          checkoutPrice={checkoutPrice}
          retailPrice={retailPrice}
          savingsCardPrice={savingsCardPrice}
          allowPriceTransparency={allowPriceTransparency}
        />
      )
    }

    switch (orderMode) {
      case OrderMode.INSURANCE:
        return <InsurancePriceView checkoutPrice={checkoutPrice} allowPriceTransparency={allowPriceTransparency} />
      case OrderMode.CASH:
        return (
          <CashPriceView
            checkoutPrice={checkoutPrice}
            retailPrice={retailPrice}
            savingsCardPrice={savingsCardPrice}
            allowPriceTransparency={allowPriceTransparency}
          />
        )
    }
  }

  return <div data-testid='prescription-price'>{renderPriceView()}</div>
}

export const PrescriptionPriceV2 = ({
  checkoutPrice,
  orderMode,
  allowPriceTransparency,
  isMixedCartEnabled,
  selectedPriceOption,
}: PrescriptionPriceProps): React.ReactElement => {
  const isCash = (isMixedCartEnabled && selectedPriceOption === 'cash') || orderMode === OrderMode.CASH
  const labelText = isCash ? 'cash price' : 'insurance price'

  return (
    <div>
      <StyledPrescriptionPrice.CheckoutPriceContainer>
        <StyledPrescriptionPrice.CheckoutPriceHeader data-testid='prescription-price' allowPriceTransparency={allowPriceTransparency}>
          ${checkoutPrice.toFixed(2)}
        </StyledPrescriptionPrice.CheckoutPriceHeader>
        <p data-testid={isCash ? 'cash-price-label' : 'insurance-price-label'}>{labelText}</p>
      </StyledPrescriptionPrice.CheckoutPriceContainer>
    </div>
  )
}

export default PrescriptionPrice
