import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { breakpoint, mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { Modal } from '../../../common/styledComponents/StyledModal'
import { ThemedButton } from '../../../common/styledComponents/ThemedButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
`

const StyledDeleteActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-top: 1.5rem;

  ${mediaLargerThan.mobile} {
    flex-flow: row;
    justify-content: flex-end;
    button {
      width: 50%;
    }
  }
`

const StyledModal = styled(Modal)`
  [data-reach-dialog-content] {
    max-width: 50rem;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const StyledOrderedList = styled.ol`
  list-style: outside;
  margin-left: 1.5rem;
  counter-reset: li-counter;

  li {
    counter-increment: li-counter;
    line-height: 1.5rem;
  }

  li::marker {
    content: '' counter(li-counter) '.  ';
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
`

const EditableButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const IconWithTextButton = styled(ThemedButton)`
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  align-items: end;
  padding: 0;
  > svg {
    margin-right: 0.5em;
  }
`

const DeleteActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DeletePhotoButton = styled(ThemedButton)`
  padding: 1rem;

  ${mediaLargerThan.tablet} {
    max-width: 8.875rem;
  }
`

const StyledActionContainer = styled.div<{ isEditMode: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ isEditMode }) => (isEditMode ? 'space-between' : 'end')};

  gap: 0.5rem;
  margin-top: 2rem;

  align-items: end;
  flex-direction: column-reverse;
  > *:first-child {
    margin-top: 2em;
  }
  ${mediaLargerThan.tablet} {
    align-items: baseline;
    flex-direction: row;
    > *:first-child {
      margin-top: 0;
    }
  }
`

const UploadSavingCardImageInput = styled.input`
  display: none;
`

const SavingsCardInfoContainer = styled.div<{ background?: string }>`
  display: grid;
  grid-template-columns: 1.5rem auto;
  margin-top: 2.25rem;
  padding: 1.33rem 1.5rem 1.33rem 1.5rem;
  align-items: start;
  border-radius: 0.5rem;
  background-color: ${({ background }) => background ?? defaultTheme.colors['primary-300']};
  text-align: center;
  gap: 0.5rem;
`

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;

  ${mediaLargerThan.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

const SavingsCardText = styled.div`
  text-align: left;
`

const StyledAnchor = styled.a<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['primary-700']};
  border: none;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  margin-left: 0.25rem;
  text-align: left;
`

const ImageButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  > button {
    padding: 1rem;
  }

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    justify-content: end;
  }
`

const SavingsCardImageUploadContainer = styled.div<{ background?: string; border?: string }>`
  background-color: ${({ background }) => background ?? defaultTheme.colors['gray-100']};
  border: 1px solid ${({ border }) => border ?? defaultTheme.colors['gray-300']};
  padding: 1rem;
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;
  border-radius: 8px;
`

const ImageButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 1rem;
  width: 100%;

  > div {
    width: 100%;
  }

  > div > button {
    width: 100%;
  }

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

const UploadSavingsCardImageButton = styled(ThemedButton)`
  ${mediaLargerThan.tablet} {
    margin-top: 0;
  }
`

const SavingsCard = styled.div`
  display: inline-block;
  position: relative;
`

const TakePhotoSavingsCardImageButton = styled(ThemedButton)`
  ${mediaLargerThan.tablet} {
    margin-top: 0;
  }
`

const TextOptional = styled(Text)`
  display: flex;
  gap: 0.125rem;

  ${mediaLargerThan.tablet} {
    align-self: center;
    display: flex;
    margin: 0;
  }
`

const UploadedImage = styled.img`
  justify-self: center;
  max-height: 25vh;
`

const UploadedImageContainer = styled.div<{ showImage: boolean }>`
  margin: 0 auto 1rem auto;
  ${({ showImage }) => (showImage ? 'display: block;' : 'display: none;')}
`

const ImageActionableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
  }
`

const InfoBox = styled.div`
  flex: none;
`

const SampleCardLink = styled(ThemedButton)`
  text-decoration: underline;
  padding-top: 1rem;
  padding-left: 0;
`

const SubHeaderPdfLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
  svg {
    height: 1rem;
    width: auto;
  }
  ${breakpoint.tablet} {
    flex-direction: column;
    align-items: baseline;
    button {
      margin-top: 1em;
    }
  }
`

const SpinnerContainer = styled.div`
  position: relative;
  height: 3.75rem;
  display: flex;
  width: 100%;
  justify-content: center;
`

const StyleLoadingAnimationWrapper = styled.div`
  height: 12.5rem;
`

export {
  ButtonContainer,
  DeleteActionContainer,
  DeletePhotoButton,
  EditableButtonContainer,
  FormGrid,
  IconWithTextButton,
  ImageActionableContainer,
  ImageButtonContainer,
  ImageButtons,
  InfoBox,
  SampleCardLink,
  SavingsCard,
  SavingsCardImageUploadContainer,
  SavingsCardInfoContainer,
  SavingsCardText,
  SpinnerContainer,
  StyledActionContainer,
  StyledAnchor,
  StyledDeleteActionContainer,
  StyledModal,
  StyledOrderedList,
  StyleLoadingAnimationWrapper,
  SubHeaderPdfLinkContainer,
  TakePhotoSavingsCardImageButton,
  TextOptional,
  UploadedImage,
  UploadedImageContainer,
  UploadSavingCardImageInput,
  UploadSavingsCardImageButton,
  Wrapper,
}
