import { Grid, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedSelect } from '../../../common/styledComponents/ThemedSelect'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'
import { IContactChannelsSectionFields, IContactFormSectionFields } from '../../../types/generated/contentful'

const Container = styled.div<IContactFormSectionFields & ThemedComponent>`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  padding-top: ${(props) => props.paddingTopMobile || `1.5rem`};
  padding-left: ${(props) => props.paddingLeftMobile || `1.5rem`};
  padding-right: ${(props) => props.paddingRightMobile || `1.5rem`};
  padding-bottom: ${(props) => props.paddingBottomMobile || `1.5rem`};
  ${mediaLargerThan.tablet} {
    padding-top: ${(props) => props.paddingTopLargeDevices || `1.5rem`};
    padding-left: ${(props) => props.paddingLeftLargeDevices || `5rem`};
    padding-right: ${(props) => props.paddingRightLargeDevices || `5rem`};
    padding-bottom: ${(props) => props.paddingBottomLargeDevices || `1.5rem`};
    grid-template-columns: 4fr 8fr;
    grid-column-gap: 2rem;
  }
`

const Form = styled.form`
  margin-top: 2rem;
`

const Subtitle = styled(Text)`
  margin-top: 1rem;
`

const TextFieldWrapper = styled.div`
  margin-bottom: 1rem;
`

const TextFieldContainer = styled.div``

const TextArea = styled(ThemedTextField)`
  height: 12.5rem !important;
`

const ContactUsSelect = styled(ThemedSelect)<ThemedComponent>`
  label {
    color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  }
  p {
    color: ${({ vpTheme }) => vpTheme.colors.black};
  }

  > button {
    padding: 0 0.65rem 0 0.75rem;
  }

  svg:nth-child(3) {
    min-width: 1.5rem;
  }
`

const SubmitButton = styled(ThemedButton)`
  background-color: ${({ vpTheme }) => vpTheme.colors.buttonSecondary};
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;

  &:enabled {
    &:hover {
      background-color: ${({ vpTheme }) => vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']};
    }
  }

  ${mediaLargerThan.tablet} {
    width: 18.75rem;
  }
`

const ContactFormContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']};
  padding: 2rem;
  margin-top: 0.625rem;
  color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  h3 {
    -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  }

  ${mediaLargerThan.tablet} {
    border-radius: 0.75rem;
    margin-top: 1rem;
  }
`

const ContactFormBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  gap: 2rem;

  ${mediaLargerThan.tablet} {
    padding: 0;
    gap: 3rem;
    margin-top: 1rem;
  }
`

const ContactChannelContainer = styled(Grid)<IContactChannelsSectionFields>`
  padding-top: ${(props) => props.paddingTopMobile || `2rem`};
  padding-right: ${(props) => props.paddingRightMobile || `1.5rem`};
  padding-bottom: ${(props) => props.paddingBottomMobile || `2rem`};
  padding-left: ${(props) => props.paddingLeftMobile || `1.5rem`};
  ${mediaLargerThan.tablet} {
    padding-top: ${(props) => props.paddingTopLargeDevices || `5rem`};
    padding-right: ${(props) => props.paddingRightLargeDevices || `8rem`};
    padding-bottom: ${(props) => props.paddingBottomLargeDevices || `5rem`};
    padding-left: ${(props) => props.paddingLeftLargeDevices || `8rem`};
  }
`

export {
  ContactChannelContainer,
  ContactFormBoxesContainer,
  ContactFormContainer,
  ContactUsSelect,
  Container,
  Form,
  SubmitButton,
  Subtitle,
  TextArea,
  TextFieldContainer,
  TextFieldWrapper,
}
