import { mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

interface Props {
  name?: string | null
  rx_number: string | null
  quantity: number
  days_supply: string | null
  patient_name: string
  quantity_unit: string | null
}

const OrderMedication = ({ name, rx_number, quantity, quantity_unit, days_supply, patient_name }: Props): React.ReactElement => {
  if (!name) {
    name = 'Medication name not available'
  }
  if (!rx_number) {
    rx_number = '-'
  }
  if (!quantity) {
    quantity = 0
  }
  if (!days_supply) {
    days_supply = '-'
  }
  if (!patient_name || patient_name === 'undefined undefined') {
    patient_name = '-'
  }

  return (
    <OrderItemCardContainer data-testid='order-item-card'>
      <OrderItemCardHeader data-testid='order-item-card-name'>
        <OrderItemName>{name ?? '-'}</OrderItemName>
      </OrderItemCardHeader>
      <OrderItemCardDetails>
        <OrderDetailBlock data-testid='order-item-card-quantity'>
          Quantity: {quantity ?? '-'} {quantity_unit || ''}
        </OrderDetailBlock>
        <OrderDetailBlock data-testid='order-item-card-supply'>Days supply: {days_supply ?? '-'}</OrderDetailBlock>
        <OrderDetailBlock data-testid='order-item-card-rx'>Rx number: {rx_number ?? '-'}</OrderDetailBlock>
        <OrderDetailBlock data-testid='order-item-card-patient'>Patient: {patient_name ?? '-'}</OrderDetailBlock>
      </OrderItemCardDetails>
    </OrderItemCardContainer>
  )
}

const OrderItemName = styled.h5`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
`

const OrderItemCardContainer = styled.div`
  width: 100%;
  margin-left: 16px;
  display: flex;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
  flex-direction: column;
`
const OrderItemCardHeader = styled.div`
  padding: 16px;
  width: 100%;
  min-height: 56px;
`
const OrderItemCardDetails = styled.div`
  display: grid;
  grid-template-columns: auto;
  width: 100%;
  padding-left: 16px;
  padding-bottom: 16px;
  row-gap: 1rem;

  ${mediaLargerThan.tablet} {
    grid-template-columns: 200px auto;
    padding-bottom: 40px;
  }
`

const OrderDetailBlock = styled.span``

export default OrderMedication
