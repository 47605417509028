import { Text, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

interface CardholderNameExpirationProps {
  name: string
  expMonth: number | string
  expYear: number | string
}

const CardholderAndExpiration = ({ name, expMonth, expYear }: CardholderNameExpirationProps): React.ReactElement => (
  <CardholderNameAndExpirationContainer>
    <Text variant='body-sm'>{`${name}`}</Text>
    <Text variant='body-sm'>{`Exp: ${expMonth}/${expYear}`}</Text>
  </CardholderNameAndExpirationContainer>
)

export default CardholderAndExpiration

const CardholderNameAndExpirationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors['typography-medium'].value};
`
