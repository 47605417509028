import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedComponent } from '../../common/styledComponents/types'

const StyledContainer = styled.div<ThemedComponent>`
  align-items: center;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  padding: 2px 2px 2px 16px;
  transition: box-shadow ease-in-out 0.25s;

  &:hover {
    box-shadow: 0px 8px 16px rgba(24, 39, 75, 0.08), 0px 6px 8px rgba(24, 39, 75, 0.12);
  }
`

const StyledProfileButton = styled.button<ThemedComponent>`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  div {
    background: ${({ vpTheme }) => vpTheme.colors.navAvatarBg ?? defaultTheme.colors.navAvatarBg};

    span {
      color: ${({ vpTheme }) => vpTheme.colors.navAvatarText ?? defaultTheme.colors.navAvatarText};
    }
  }
`

export { StyledContainer, StyledProfileButton }
