export const isPostOfficeBoxAddress = (address: string): boolean => {
  const regex = /\b(?:p\.?\s*o\.?|post\s+office|post\s+box)(\s+)?(?:box|[0-9]*)?\b/gim
  return regex.test(address)
}

export const extractSubdomain = (hostname: string, domains: string[]): string => {
  let result = ''
  const domain = domains.find((item) => hostname.includes(item))
  if (domain) {
    result = hostname.replace(domain, '')
  }
  return result
}

export const sortStrings = (a: string, b: string, asc: boolean): number => {
  const stringA = a.toUpperCase()
  const stringB = b.toUpperCase()
  if (stringA === stringB) {
    return 0
  }
  if (!asc) {
    if (stringA > stringB) {
      return -1
    }
    return 1
  }
  if (stringA < stringB) {
    return -1
  }
  return 1
}

export const sortDates = (a: Date | null, b: Date | null, asc: boolean): number => {
  const dateA = a ? a.getTime() : new Date(3000, 12, 31).getTime()
  const dateB = b ? b.getTime() : new Date(3000, 12, 31).getTime()
  if (asc) {
    return dateA - dateB
  }
  return dateB - dateA
}

export const addDaysToDate = (date: Date, days: number): Date => new Date(date.setDate(date.getDate() + days))

export const formatCurrencyValue = (value: string | number, locale = 'en-US', currency = 'USD'): string => {
  const num = typeof value === 'string' ? Number(value) : value
  if (isNaN(num)) {
    throw new Error(`Invalid currency value: ${value}`)
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  return formatter.format(num)
}

export const formatDateToString = (date: Date): string => date.toLocaleString('us', { weekday: 'long', day: 'numeric', month: 'long' })

export const formatDateToStringShort = (date: Date): string => date.toLocaleString('us', { day: 'numeric', month: 'long' })

export const formatStringToLongDateString = (inputDate: string): string => {
  const year = inputDate.substring(0, 4)
  const month = inputDate.substring(4, 6)
  const day = inputDate.substring(6, 8)

  const date = new Date(`${year}-${month}-${day}`)

  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  })

  return formattedDate
}
