import { Spacer } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../../hooks'
import { CancelOrderConfirmationText, StyledActionContainer, StyledHeader } from './styledComponent'

interface Props {
  cancel: () => void
}

const CancelOrderFailure = ({ cancel }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  return (
    <>
      <StyledHeader vpTheme={theme} variant='4xl'>
        This order could not be cancelled
      </StyledHeader>
      <Spacer size='lg' />
      <CancelOrderConfirmationText vpTheme={theme}>
        We were unable to cancel your order as it has already been processed for fulfillment. Please contact our Support Team, or visit the Help
        Center if you have any additional questions.
      </CancelOrderConfirmationText>
      <Spacer size='2xl' />
      <StyledActionContainer>
        <ThemedButton data-testid='cancel-order-failure-btn' size='lg' onClick={handleCancel} vpTheme={theme}>
          Close
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default CancelOrderFailure
