import { PREGNANCY_STATUS_LABELS, PregnancyStatus } from '../constants'

function arraysEqual(a1: any[], a2: any[]) {
  return JSON.stringify(a1) === JSON.stringify(a2)
}

export const mapToLabel = (selectedOption: PregnancyStatus[]): string => {
  let labels: any[] = []

  switch (true) {
    case arraysEqual(selectedOption, [PregnancyStatus.Pregnant]):
      labels = [PREGNANCY_STATUS_LABELS[PregnancyStatus.Pregnant]]
      break
    case arraysEqual(selectedOption, [PregnancyStatus.Lactating]):
      labels = [PREGNANCY_STATUS_LABELS[PregnancyStatus.None], PREGNANCY_STATUS_LABELS[PregnancyStatus.Lactating]]
      break
    case arraysEqual(selectedOption, [PregnancyStatus.Planning]):
      labels = [PREGNANCY_STATUS_LABELS[PregnancyStatus.None], PREGNANCY_STATUS_LABELS[PregnancyStatus.Planning]]
      break
    case arraysEqual(selectedOption, [PregnancyStatus.Pregnant, PregnancyStatus.Lactating]):
    case arraysEqual(selectedOption, [PregnancyStatus.Lactating, PregnancyStatus.Pregnant]):
      labels = [PREGNANCY_STATUS_LABELS[PregnancyStatus.Pregnant], PREGNANCY_STATUS_LABELS[PregnancyStatus.Lactating]]
      break
    case arraysEqual(selectedOption, [PregnancyStatus.Lactating, PregnancyStatus.Planning]):
    case arraysEqual(selectedOption, [PregnancyStatus.Planning, PregnancyStatus.Lactating]):
      labels = [PREGNANCY_STATUS_LABELS[PregnancyStatus.Lactating], PREGNANCY_STATUS_LABELS[PregnancyStatus.Planning]]
      break
    case arraysEqual(selectedOption, [PregnancyStatus.None]):
      labels = [PREGNANCY_STATUS_LABELS[PregnancyStatus.None]]
      break
  }

  return labels.join(', ')
}
