import type { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useMemo } from 'react'

import { VP_5792_DYNAMIC_STRIPE_KEY } from '../constants'
import { useCustomerProfile } from './useCustomerProfile'
import { useLDFlagsWithLocalStorage } from './useLDFlagsWithLocalStorage'

interface PaymentInstance {
  stripePromise: Promise<Stripe | null>
  key: string
}

loadStripe.setLoadParameters({ advancedFraudSignals: false })

export const usePaymentInstance = (): PaymentInstance => {
  const { customerProfile } = useCustomerProfile()
  const { [VP_5792_DYNAMIC_STRIPE_KEY]: enableDynamicStripeKey } = useLDFlagsWithLocalStorage()
  const paymentInstance: PaymentInstance = useMemo(() => {
    if (enableDynamicStripeKey) {
      return {
        key: customerProfile.paymentClientKey || '',
        stripePromise: loadStripe(customerProfile.paymentClientKey || ''),
      }
    }
    return {
      key: process.env.REACT_APP_STRIPE_PK || '',
      stripePromise: loadStripe(process.env.REACT_APP_STRIPE_PK || ''),
    }
  }, [enableDynamicStripeKey, customerProfile.paymentClientKey])

  return paymentInstance
}
