import React from 'react'

import { orderHistoryLinks } from '../../constants/navigation-links'
import { useContentfulTheme } from '../../hooks'
import NavigationLinks from './NavigationLinks'

interface Props {
  itemColor: string
  onLinkClick?: () => void
}

const OrdersNavigation = ({ onLinkClick, itemColor }: Props): React.ReactElement => {
  const { theme } = useContentfulTheme()

  return <NavigationLinks title='Order history' onLinkClick={onLinkClick} links={orderHistoryLinks(theme)} itemColor={itemColor} />
}

export default OrdersNavigation
