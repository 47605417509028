import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Spacer, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Webcam from 'react-webcam'

import { FileText, IconCoupon, TrashIcon } from '../../../assets/Icons'
import { ArrowIconContainer, TextWithArrowContainer } from '../../../common/styledComponents'
import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { TABLET_BREAK_POINT } from '../../../common/styles/variables'
import { UpDownChevron } from '../../../Components/AddressForms/upDownChevron'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import ToastMessage from '../../../Components/ToastMessage'
import { savingsCardAccountPageDefaults } from '../../../constants/savingsCard'
import { ToastState } from '../../../constants/toastConstants'
import { useContentfulTheme, useRefreshPrescriptionList, useUpdateCart } from '../../../hooks'
import { MicroCopyResourceType, SavingCardFormMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../../hooks/contentful/useContentfulMicrocopy'
import { useMatchMinMediaQuery } from '../../../hooks/useMatchMediaQuery'
import { useSavingsCard } from '../../../hooks/useSavingsCard'
import { PatientSavingsCardSubmitPayload } from '../../../interfaces/PatientSavingsCard'
import { dataUrlToFile, parseTextFieldStateForCapsule, processImageCompression } from '../../../utils'
import { SavingsCardFieldValidationSchema } from '../../../utils/validation'
import DeleteSavingsCard from './DeleteSavingsCardModal'
import {
  DeleteActionContainer,
  DeletePhotoButton,
  EditableButtonContainer,
  FormGrid,
  IconWithTextButton,
  ImageActionableContainer,
  ImageButtonContainer,
  ImageButtons,
  InfoBox,
  SampleCardLink,
  SavingsCard,
  SavingsCardImageUploadContainer,
  SavingsCardInfoContainer,
  SavingsCardText,
  SpinnerContainer,
  StyledActionContainer,
  StyledAnchor,
  StyledModal,
  StyleLoadingAnimationWrapper,
  SubHeaderPdfLinkContainer,
  TakePhotoSavingsCardImageButton,
  TextOptional,
  UploadedImage,
  UploadedImageContainer,
  UploadSavingCardImageInput,
  UploadSavingsCardImageButton,
} from './styledComponents'

interface SavingCardUploadToastState {
  toastType: 'error' | 'success'
  toastMessage: string
}

// Note on editability:
// isEditMode (from props) - Displays form in "View/Edit Existing Card" view vs. "Add New Card" view
// isEditable (from fetch savings card) - Enable/Disable editing when in "View/Edit Existing Card" view. Used to disable editing cards auto-added through the internal sign-up flow.
// isDeletable (from fetch savings card) - Enable/Disable deleting when in "View/Edit Existing Card" view. Used to disable deleting cards auto-added through the internal sign-up flow.

interface Props {
  handleSubmitForm: (val: PatientSavingsCardSubmitPayload) => void
  handleCancelForm: () => void
  savingCardModalHeaderName: string
  subTitle?: string
  optionalLabelText?: string
  isEditMode?: boolean
  prescriptionNdc?: string | null
  prescriptionToken?: string
  savingsCardUrl?: string | null
  setError: (error: string) => void
  isSubmittingForm: boolean
  isUploadingSavingCardImage: boolean
  uploadSavingCardImage: (image: File) => Promise<string>
}

interface SavingsCardFormFields {
  cardholder_id: string
  rx_bin: string
  pcn?: string | null
  rx_group?: string
  savings_card_image_key?: string | null
}

const SavingsCardModal = ({
  savingCardModalHeaderName,
  subTitle,
  handleCancelForm,
  handleSubmitForm,
  isEditMode,
  prescriptionNdc,
  prescriptionToken,
  setError,
  savingsCardUrl,
  isSubmittingForm,
  isUploadingSavingCardImage,
  uploadSavingCardImage,
}: Props): ReactElement => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors, isValid },
  } = useForm<SavingsCardFormFields>({
    resolver: yupResolver(SavingsCardFieldValidationSchema),
    mode: 'onBlur',
    delayError: 1000,
  })
  const { fetchSavingsCard, deleteSavingCard, toastMessage } = useSavingsCard()
  const { microcopy: savingCardMicrocopy, isLoadingContent: isMicroCopyLoading } = useContentfulMicrocopy<SavingCardFormMicrocopy>(
    MicroCopyResourceType.SavingCardForm,
  )
  const webcamRef = useRef<Webcam>(null)
  const savingCardImageNameRef = useRef<HTMLInputElement | null>(null)

  const [cardHolderID, rxBin, pcn, rxGroup] = watch(['cardholder_id', 'rx_bin', 'pcn', 'rx_group'])

  const [isEditable, setIsEditable] = useState<boolean>(true)
  const [isDeletable, setIsDeletable] = useState<boolean>(true)
  const [savingCardToastState, setSavingCardToastState] = useState<SavingCardUploadToastState | null>(null)
  const [image, setImage] = useState<string>('')
  const [seeMoreInfo, setSeeMoreInfo] = useState(false)
  const [isFetchingSavingsCard, setIsFetchingSavingsCard] = useState(false)
  const [isLoadingUploadedImage, setIsLoadingUploadedImage] = useState(false)
  const [takePhoto, setTakePhoto] = useState(false)
  const [isWebcamLoading, setIsWebcamLoading] = useState(false)
  const [openDeleteSavingsCardModal, setOpenDeleteSavingsCardModal] = useState(false)
  const [isDeletingSavingsCard, setIsDeletingSavingsCard] = useState(false)
  const [currentNdc, setCurrentNdc] = useState<string | undefined | null>(prescriptionNdc)
  const [showSuccessToastMessage, setShowSuccessToastMessage] = useState(false)
  const updateCart = useUpdateCart()
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const { theme } = useContentfulTheme()

  const isSavingCardImageLoading = isLoadingUploadedImage || isWebcamLoading || isUploadingSavingCardImage

  const isTablet = useMatchMinMediaQuery(TABLET_BREAK_POINT)

  const handleCancelFormWrapper = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCancelForm()
  }

  const handleDeleteSavingsCardModal = (e: React.MouseEvent) => {
    e.preventDefault()
    setOpenDeleteSavingsCardModal(true)
  }

  const handleSavingCardImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setTakePhoto(false)
    setImage('')
    setValue('savings_card_image_key', '')
    try {
      if (e.target.files?.[0]) {
        const savingCardFileImage = e.target.files[0]
        const base64Image = await processImageCompression(savingCardFileImage)
        const compressedSavingCardImageFile = await dataUrlToFile(base64Image, savingCardFileImage.name)
        const savingCardImageKey = await uploadSavingCardImage(compressedSavingCardImageFile)
        setImage(base64Image)
        setValue('savings_card_image_key', savingCardImageKey)
        setSavingCardToastState({ toastMessage: 'Image successfully uploaded', toastType: 'success' })
      }
    } catch (e) {
      setSavingCardToastState({ toastMessage: 'Unable to upload saving card image', toastType: 'error' })
    }
  }

  const handleDeleteSavingCardImage = () => {
    setImage('')
    setValue('savings_card_image_key', null)
  }

  const handleSubmitFormWrapper = (values: SavingsCardFormFields) => {
    const savingCardSubmitPayload: PatientSavingsCardSubmitPayload = {
      cardholder_id: values.cardholder_id,
      rx_bin: values.rx_bin,
      pcn: values.pcn,
      rx_group: values.rx_group,
      savings_card_image_key: values.savings_card_image_key,
    }
    handleSubmitForm(savingCardSubmitPayload)
    updateCart.clearCart()
  }

  const handleTakeSavingCardImagePhoto = async () => {
    setTakePhoto(!takePhoto)
    setImage('')
    setValue('savings_card_image_key', '')
    if (takePhoto) {
      const screenshot = webcamRef.current?.getScreenshot()
      if (screenshot) {
        try {
          const savingCardFileImage = await dataUrlToFile(screenshot, 'savingCard.jpeg')
          const savingCardImageKey = await uploadSavingCardImage(savingCardFileImage)
          setImage(screenshot)
          setValue('savings_card_image_key', savingCardImageKey)
        } catch (e) {
          setSavingCardToastState({ toastMessage: 'Unable to upload saving card image', toastType: 'error' })
        }
      }
    } else {
      setIsWebcamLoading(true)
    }
  }

  const handleDeleteSavingsCard = async () => {
    try {
      setIsDeletingSavingsCard(true)
      setCurrentNdc(prescriptionNdc)
      if (prescriptionNdc && prescriptionToken) {
        await deleteSavingCard(prescriptionToken, prescriptionNdc)
        setCurrentNdc(null)
        setShowSuccessToastMessage(true)
      }
    } catch (e) {
      setError('Unable to delete savings card')
    } finally {
      setIsDeletingSavingsCard(false)
      setOpenDeleteSavingsCardModal(false)
      handleCancelForm()
      refreshPrescriptionList()
    }
  }

  const handleOpenPdf = () => {
    window.open(image, '_blank', 'noopener noreferrer')
  }

  useEffect(() => {
    const getSavingsCard = async (ndc: string) => {
      if (currentNdc) {
        setIsFetchingSavingsCard(true)
        try {
          const savingCard = await fetchSavingsCard(ndc)
          setIsEditable(!!savingCard.isEditable)
          setIsDeletable(!!savingCard.isDeletable)
          setValue('cardholder_id', savingCard.cardholderId ?? '')
          setValue('pcn', savingCard.pcn ?? '')
          setValue('rx_bin', savingCard.rxBin ?? '')
          setValue('rx_group', savingCard.rxGroup ?? '')

          if (savingCard.savingCardImage?.url) {
            setImage(savingCard.savingCardImage.url)
          }
        } catch (e) {
          setError('Unable to load savings card information')
          handleCancelForm()
        } finally {
          setIsFetchingSavingsCard(false)
        }
      }
    }

    if (isEditMode && prescriptionNdc) {
      getSavingsCard(prescriptionNdc)
    }
  }, [isEditMode, prescriptionNdc, fetchSavingsCard, setValue, setError, currentNdc, handleCancelForm])

  if (isFetchingSavingsCard || isSubmittingForm || isDeletingSavingsCard || isMicroCopyLoading) {
    return (
      <StyleLoadingAnimationWrapper>
        <LoadingAnimation />
      </StyleLoadingAnimationWrapper>
    )
  }

  return (
    <div>
      {!isEditMode || !isEditable ? (
        <>
          <ThemedHeader vpTheme={theme} id='savings_card-modal-title' variant='3xl' css={{ marginRight: '2rem' }}>
            {savingCardModalHeaderName}
          </ThemedHeader>
        </>
      ) : (
        <ThemedHeader vpTheme={theme} id='savings_card-modal-title' variant='3xl' css={{ marginRight: '2rem' }}>
          {`Edit savings card for ${savingCardModalHeaderName}`}
        </ThemedHeader>
      )}
      {savingsCardUrl && !isEditMode && (
        <SavingsCardInfoContainer background={theme.colors['primary-300']}>
          <SavingsCard>
            <IconCoupon />
          </SavingsCard>
          <SavingsCardText>
            <Text css={{ fontSize: '1rem' }}>
              {savingCardMicrocopy?.savingsCardUrlPretext || 'A savings card is available for this medicine.'}
              <StyledAnchor
                href={savingsCardUrl}
                target='_blank'
                rel='noopener noreferrer'
                role='button'
                aria-label='Learn more about savings cards (opens in new tab)'
                color={theme.colors['primary-700']}
              >
                {savingCardMicrocopy?.savingsCardHyperLinkText || 'Learn more'}
              </StyledAnchor>
            </Text>
          </SavingsCardText>
        </SavingsCardInfoContainer>
      )}

      <Spacer size='lg' />
      <SubHeaderPdfLinkContainer>
        <Text
          variant='body-sm'
          css={{ color: `${theme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']}`, lineHeight: 1.5, fontSize: '1rem' }}
        >
          {isEditMode
            ? isEditable
              ? savingsCardAccountPageDefaults.savingsCardModalEditModeDescriptionText
              : savingsCardAccountPageDefaults.savingsCardModalNonEditableDescriptionText
            : savingsCardAccountPageDefaults.savingsCardModalAddNewDescriptionText}
        </Text>
        {isEditMode && !isEditable && image && (
          <IconWithTextButton data-testid='remove-btn' variant='primary-text' onClick={handleOpenPdf} css={{ fontWeight: 400 }} vpTheme={theme}>
            <FileText vpTheme={theme} />
            {'View PDF'}
          </IconWithTextButton>
        )}
      </SubHeaderPdfLinkContainer>
      <Spacer size='lg' />
      <Header variant='xl'>{subTitle}</Header>
      <Spacer size='md' />

      <form data-testid='add-savings_card-form' onSubmit={handleSubmit(handleSubmitFormWrapper)}>
        <FormGrid>
          <ThemedTextField
            label={savingsCardAccountPageDefaults.cardholderIdLabel}
            {...register('cardholder_id', {
              onChange: () => {
                clearErrors('cardholder_id')
              },
            })}
            data-testid='cardholder-id-text-field'
            placeholder={savingsCardAccountPageDefaults.addSavingsCardCardholderIdPlaceholder}
            helperText={errors.cardholder_id?.message}
            state={parseTextFieldStateForCapsule(errors.cardholder_id, cardHolderID !== '')}
            aria-label={savingsCardAccountPageDefaults.cardholderIdLabel}
            required
            showRequiredIndicator
            disabled={!isEditable}
            vpTheme={theme}
          />
          <ThemedTextField
            label={savingsCardAccountPageDefaults.rxBinLabel}
            {...register('rx_bin', {
              onChange: () => {
                clearErrors('rx_bin')
              },
            })}
            css={{
              '.test::after': {
                content: 'Type your text here',
                display: 'block',
              },
            }}
            className='test'
            data-testid='rx-bin-text-field'
            placeholder={savingsCardAccountPageDefaults.addSavingsCardRxBinPlaceholder}
            helperText={errors.rx_bin?.message}
            state={parseTextFieldStateForCapsule(errors.rx_bin, rxBin !== '')}
            aria-label={savingsCardAccountPageDefaults.rxBinLabel}
            required
            showRequiredIndicator
            maxLength={6}
            disabled={!isEditable}
            vpTheme={theme}
          />
          <ThemedTextField
            label={savingsCardAccountPageDefaults.rxPcnLabel}
            {...register('pcn', {
              onChange: () => {
                clearErrors('pcn')
              },
            })}
            data-testid='pcn-text-field'
            placeholder={savingsCardAccountPageDefaults.addSavingsCardRxPcnPlaceholder}
            helperText={errors.pcn && savingsCardAccountPageDefaults.addSavingsCardRxPcnInlineErrorMessage}
            state={parseTextFieldStateForCapsule(errors.pcn, pcn !== '')}
            aria-label={savingsCardAccountPageDefaults.rxPcnLabel}
            disabled={!isEditable}
            vpTheme={theme}
          />
          <ThemedTextField
            label={savingsCardAccountPageDefaults.rxGroupLabel}
            {...register('rx_group', {
              onChange: () => {
                clearErrors('rx_group')
              },
            })}
            data-testid='rx_group-text-field'
            placeholder={savingsCardAccountPageDefaults.addSavingsCardRxGroupPlaceholder}
            helperText={errors.rx_group?.message}
            state={parseTextFieldStateForCapsule(errors.rx_group, rxGroup !== '')}
            aria-label={savingsCardAccountPageDefaults.addSavingsCardRxGroupInlineErrorMessage}
            disabled={!isEditable}
            vpTheme={theme}
          />
        </FormGrid>
        <Spacer size='xs' />
        {isEditable ? (
          <>
            <InfoBox>
              <SampleCardLink
                vpTheme={theme}
                variant='secondary-text'
                aria-label={`${savingsCardAccountPageDefaults.whereToFindSavingsCardInfoHeader}`}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  seeMoreInfo ? setSeeMoreInfo(false) : setSeeMoreInfo(true)
                  e.preventDefault()
                }}
              >
                {renderAccordionText(seeMoreInfo, `${savingsCardAccountPageDefaults.whereToFindSavingsCardInfoHeader}`)}
              </SampleCardLink>
            </InfoBox>
            {seeMoreInfo && (
              <>
                <Text css={{ color: `${theme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']}`, lineHeight: '180%' }}>
                  {savingsCardAccountPageDefaults.whereToFindSavingsCardDetails}
                </Text>
              </>
            )}
            <Spacer size='xl' />
            <UploadSavingCardImageInput accept='image/*' type='file' ref={savingCardImageNameRef} onChange={handleSavingCardImageUpload} />
            <SavingsCardImageUploadContainer border={theme.colors['gray-300']} background={theme.colors['gray-100']}>
              {isSavingCardImageLoading && (
                <SpinnerContainer>
                  <LoadingAnimation size='auto' borderWidth='0.5rem' backgroundColor='none' />
                </SpinnerContainer>
              )}
              <UploadedImageContainer showImage={!isLoadingUploadedImage && !isUploadingSavingCardImage && !!image}>
                <UploadedImage onLoad={() => setIsLoadingUploadedImage(false)} src={image} />
              </UploadedImageContainer>
              {takePhoto && (
                <Webcam
                  videoConstraints={{
                    facingMode: 'environment',
                  }}
                  onUserMedia={() => setIsWebcamLoading(false)}
                  style={{ maxHeight: '25vh', justifySelf: 'center', paddingBottom: '1rem' }}
                  ref={webcamRef}
                />
              )}
              <ImageActionableContainer>
                <ImageButtonContainer>
                  {image ? (
                    <DeleteActionContainer>
                      <DeletePhotoButton
                        disabled={isSavingCardImageLoading}
                        onClick={handleDeleteSavingCardImage}
                        dangerOutline={true}
                        vpTheme={theme}
                      >
                        Delete photo
                      </DeletePhotoButton>
                    </DeleteActionContainer>
                  ) : (
                    <div>
                      <TextOptional>{savingsCardAccountPageDefaults.savingsCardOptionalText}</TextOptional>
                    </div>
                  )}
                  <ImageButtons>
                    <TakePhotoSavingsCardImageButton
                      size={'sm'}
                      onClick={handleTakeSavingCardImagePhoto}
                      type='button'
                      variant='primary-outline'
                      disabled={isSavingCardImageLoading}
                      vpTheme={theme}
                    >
                      {image ? 'Take a new photo' : 'Take photo'}
                    </TakePhotoSavingsCardImageButton>
                    <UploadSavingsCardImageButton
                      onClick={() => {
                        savingCardImageNameRef.current?.click()
                      }}
                      size={'sm'}
                      type='button'
                      variant='primary-outline'
                      disabled={isSavingCardImageLoading}
                      vpTheme={theme}
                    >
                      {image ? 'Upload new' : 'Upload photo'}
                    </UploadSavingsCardImageButton>
                  </ImageButtons>
                </ImageButtonContainer>
              </ImageActionableContainer>
            </SavingsCardImageUploadContainer>
          </>
        ) : (
          <Text variant='body-sm' css={{ color: theme.colors['gray-700'], fontSize: '0.875rem' }}>
            {savingsCardAccountPageDefaults.savingsCardNotEditableText}
          </Text>
        )}

        <StyledActionContainer isEditMode={!!isEditMode}>
          {isEditMode && isDeletable && (
            <IconWithTextButton
              data-testid='remove-btn'
              disabled={isUploadingSavingCardImage || isSubmittingForm}
              variant='primary-text'
              onClick={(e) => handleDeleteSavingsCardModal(e)}
              css={{ fontWeight: 400 }}
              tabIndex={isTablet ? 0 : 2}
              role='button'
              vpTheme={theme}
            >
              <TrashIcon role='img' vpTheme={theme} />
              {'Remove card'}
            </IconWithTextButton>
          )}

          {isEditable ? (
            <EditableButtonContainer>
              <ThemedButton
                data-testid='cancel-btn'
                disabled={isUploadingSavingCardImage || isSubmittingForm}
                variant='primary-text'
                onClick={(e) => handleCancelFormWrapper(e)}
                vpTheme={theme}
              >
                {'Cancel'}
              </ThemedButton>
              <ThemedButton data-testid='save-btn' type='submit' disabled={!isValid || isSubmittingForm || isSavingCardImageLoading} vpTheme={theme}>
                {'Save changes'}
              </ThemedButton>
            </EditableButtonContainer>
          ) : (
            <ThemedButton css={{ marginLeft: 'auto' }} onClick={(e) => handleCancelFormWrapper(e)} vpTheme={theme}>
              {'Done'}
            </ThemedButton>
          )}
        </StyledActionContainer>

        <StyledModal
          isOpen={openDeleteSavingsCardModal}
          onDismiss={() => setOpenDeleteSavingsCardModal(false)}
          aria-label='delete savings card modal'
        >
          <DeleteSavingsCard
            medicationName={savingCardModalHeaderName}
            ndc={prescriptionNdc}
            isDeletingSavingsCard={isDeletingSavingsCard}
            cancel={() => setOpenDeleteSavingsCardModal(false)}
            handleDeleteSavingsCard={handleDeleteSavingsCard}
          />
        </StyledModal>
      </form>
      {savingCardToastState && (
        <ToastMessage
          state={savingCardToastState.toastType}
          visible={!!savingCardToastState}
          timeout={3000}
          onTimeout={() => setSavingCardToastState(null)}
          onDismiss={() => setSavingCardToastState(null)}
        >
          {savingCardToastState.toastMessage}
        </ToastMessage>
      )}
      {toastMessage && showSuccessToastMessage && (
        <ToastMessage
          timeout={6000}
          onDismiss={() => {
            setShowSuccessToastMessage(false)
          }}
          state={ToastState.SUCCESS}
          visible={true}
          onTimeout={() => {
            setShowSuccessToastMessage(false)
          }}
        >
          {toastMessage}
        </ToastMessage>
      )}
    </div>
  )
}

const renderAccordionText = (seeMoreInfo: boolean, accordionText: string): JSX.Element => {
  return (
    <TextWithArrowContainer role='button' aria-label='Where can I find my savings card information?'>
      {accordionText}
      <ArrowIconContainer aria-hidden>
        <UpDownChevron role='img' flipped={!seeMoreInfo} />
      </ArrowIconContainer>
    </TextWithArrowContainer>
  )
}

export default SavingsCardModal
