// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Autocomplete } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from './types'

const ThemedAutoComplete = styled(Autocomplete)<ThemedComponent & { [key: string]: any }>`
  ${({ vpTheme, state = '' }) => `
  input {
      ${selectBackgroundColor(vpTheme, state)}
      &:focus, &:focus-visible, &:active {
        box-shadow: inset 0px 0px 0px 2px ${selectBorderColor(vpTheme, state)};
      }
    }
    `}
`

const selectBorderColor = (vpTheme: VpTheme, state: string) => {
  switch (state) {
    case 'complete':
      return `${vpTheme.colors.fieldBorderComplete};`
    case 'default':
      return `${vpTheme.colors.fieldBorderDefault};`
    case 'error':
      return `${vpTheme.colors['functional-error-dark']};`
    default:
      return ''
  }
}

const selectBackgroundColor = (vpTheme: VpTheme, state: string) => {
  switch (state) {
    case 'complete':
      return `background-color: ${vpTheme.colors.fieldBgComplete};`
    case 'default':
      return `background-color: ${vpTheme.colors.fieldBgDefault};`
    case 'error':
      return `background-color: ${vpTheme.colors['functional-error-light']};`
    default:
      return ''
  }
}

export { ThemedAutoComplete }
