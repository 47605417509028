import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../../common/styledComponents/types'

export interface StripeElementContainerProps {
  isDisabled: boolean
  isFocus: boolean
  error: boolean
}

export interface StripeElementLabelProps {
  required: boolean
}

const StripeElementContainerStyles = styled.div<StripeElementContainerProps & ThemedComponent>`
  align-items: center;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors.fieldBorderDefault};
  background: ${({ vpTheme }) => vpTheme.colors.fieldBgDefault};
  border-radius: 0.5rem;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding: 1.025rem 0.75rem;
  outline: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  width: 100%;

  > img {
    display: none;
    height: 1.5rem;
    width: 1.5rem;
  }

  > div {
    width: 100%;
    opacity: 1;
  }

  ${({ error, isDisabled, vpTheme }) =>
    error &&
    !isDisabled &&
    `border: 1px solid ${vpTheme.colors['functional-error-dark']};
    background: ${vpTheme.colors['functional-error-light']};`}

  ${({ isFocus, vpTheme }) => isFocus && `border: 3px solid ${vpTheme.colors.fieldBorderDefault};`}

  ${({ error, isFocus, vpTheme }) => error && isFocus && `border: 3px solid ${vpTheme.colors['functional-error-dark']};`}
`

const FieldContainerStyle = styled.div`
  display: inline-block;
  width: 100%;
`

const StripeElementLabelStyle = styled(Text)<StripeElementLabelProps>`
  position: relative;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  ${({ required, vpTheme }) =>
    required &&
    `
    ::after {
      color: ${vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};
      bottom: 0;
      content: '*';
      position: absolute;
      padding-left: 0.2rem;
    }
  `}
`

const FieldErrorStyle = styled.div<ThemedComponent>`
  display: inline-flex;
  margin-top: var(--cap-space-xs);

  div {
    display: flex;
    align-items: flex-start;
  }
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    color: ${({ vpTheme }) => vpTheme.colors['functional-error-dark']};
  }
  p {
    color: ${({ vpTheme }) => vpTheme.colors['functional-error-dark']};
    margin-left: 0.125rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: var(--cap-fonts-base);
    display: block;
    align-self: center;
  }
`

export { FieldContainerStyle, FieldErrorStyle, StripeElementContainerStyles, StripeElementLabelStyle }
