import { ReactElement } from 'react'

import { useContentfulTheme } from '../../../hooks'
import { FieldErrorStyle } from './styledComponents'

interface Props {
  text: string
}

const FieldError = ({ text }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <FieldErrorStyle vpTheme={theme}>
      <div>
        <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' data-testid='alertIcon'>
          <circle cx='12' cy='12' r='10' fill='#E81A33'></circle>
          <path d='M12 8.00006V12.0001M12 16.0001H12.01' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path>
        </svg>
      </div>
      <p>{text}</p>
    </FieldErrorStyle>
  )
}

export default FieldError
