import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const StepOneIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill={vpTheme.colors['primary-500']} />
      <path
        d='M12.69 22.15H15.62V13.72C15.62 13.3933 15.63 13.05 15.65 12.69L13.57 14.43C13.4833 14.5033 13.3933 14.5533 13.3 14.58C13.2133 14.6067 13.1267 14.62 13.04 14.62C12.9067 14.62 12.7833 14.5933 12.67 14.54C12.5633 14.48 12.4833 14.4133 12.43 14.34L11.65 13.27L16.06 9.52H18.09V22.15H20.69V24H12.69V22.15Z'
        fill={vpTheme.colors.grayWhite}
      />
    </svg>
  )
}
