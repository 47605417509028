import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const Generic = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19713_419292)'>
        <path
          d='M43.5851 19.335L36.4351 26.485C37.3251 27.245 38.4651 27.725 39.7251 27.725C42.5351 27.725 44.8151 25.445 44.8151 22.635C44.8151 21.375 44.3351 20.235 43.5751 19.345L43.5851 19.335Z'
          fill='#ffffff'
        />
        <path
          d='M43.025 18.7749C42.135 18.0149 40.995 17.5349 39.735 17.5349C36.925 17.5349 34.645 19.8149 34.645 22.6249C34.645 23.8849 35.125 25.0249 35.885 25.9149L43.035 18.7649L43.025 18.7749Z'
          fill='#ffffff'
        />
        <path
          d='M43.6851 18.675C41.5051 16.495 37.9551 16.495 35.7751 18.675C33.5951 20.855 33.5951 24.405 35.7751 26.585C36.8351 27.645 38.2351 28.225 39.7351 28.225C41.2351 28.225 42.6351 27.645 43.6951 26.585C45.8751 24.405 45.8751 20.855 43.6951 18.675H43.6851ZM36.4851 19.385C37.3851 18.485 38.5551 18.045 39.7351 18.045C40.7551 18.045 41.7551 18.405 42.5951 19.075L36.1651 25.505C34.7251 23.705 34.8151 21.065 36.4851 19.395V19.385ZM42.9851 25.885C42.1151 26.755 40.9651 27.235 39.7351 27.235C38.6751 27.235 37.6851 26.865 36.8751 26.205L43.3051 19.775C44.7451 21.575 44.6551 24.215 42.9851 25.885Z'
          fill='#034D83'
        />
        <path
          d='M57.605 22.9548H77.985V55.9248C77.985 56.7548 77.315 57.4248 76.485 57.4248H59.105C58.275 57.4248 57.605 56.7548 57.605 55.9248V22.9548Z'
          fill='#e9ecf0'
        />
        <path
          d='M76.485 57.9248H59.105C58.005 57.9248 57.105 57.0248 57.105 55.9248V22.4548H78.485V55.9248C78.485 57.0248 77.585 57.9248 76.485 57.9248ZM58.105 23.4548V55.9248C58.105 56.4748 58.555 56.9248 59.105 56.9248H76.485C77.035 56.9248 77.485 56.4748 77.485 55.9248V23.4548H58.105Z'
          fill='#034D83'
        />
        <path
          d='M79.195 12.575H56.395C56.1189 12.575 55.895 12.7988 55.895 13.075V23.255C55.895 23.5311 56.1189 23.755 56.395 23.755H79.195C79.4712 23.755 79.695 23.5311 79.695 23.255V13.075C79.695 12.7988 79.4712 12.575 79.195 12.575Z'
          fill='#ffffff'
        />
        <path
          d='M79.195 24.255H56.395C55.845 24.255 55.395 23.805 55.395 23.255V13.075C55.395 12.525 55.845 12.075 56.395 12.075H79.195C79.745 12.075 80.195 12.525 80.195 13.075V23.255C80.195 23.805 79.745 24.255 79.195 24.255ZM56.395 13.075V23.255H79.195V13.075H56.395Z'
          fill='#034D83'
        />
        <path
          d='M42.6464 32.5757L32.7964 42.4257C30.8087 44.4135 30.8087 47.6363 32.7964 49.6241L32.8035 49.6311C34.7913 51.6189 38.0141 51.6189 40.0019 49.6311L49.8519 39.7811C51.8396 37.7934 51.8396 34.5706 49.8519 32.5828L49.8448 32.5757C47.857 30.588 44.6342 30.588 42.6464 32.5757Z'
          fill='#ffffff'
        />
        <path
          d='M37.5852 37.635L32.7952 42.425C30.8052 44.415 30.8052 47.635 32.7952 49.625C34.7852 51.615 38.0052 51.615 39.9952 49.625L44.7752 44.845L37.5752 37.645L37.5852 37.635Z'
          fill='#e9ecf0'
        />
        <path
          d='M49.8453 32.575C47.8553 30.585 44.6353 30.585 42.6453 32.575L37.9053 37.315L45.1053 44.515L49.8453 39.775C51.8353 37.785 51.8353 34.565 49.8453 32.575Z'
          fill='#ffffff'
        />
        <path
          d='M50.1951 32.2248C48.0151 30.0448 44.4651 30.0448 42.2851 32.2248L32.4451 42.0648C30.2651 44.2448 30.2651 47.7948 32.4451 49.9748C33.5351 51.0648 34.9651 51.6148 36.4051 51.6148C37.8451 51.6148 39.2751 51.0748 40.3651 49.9748L50.2051 40.1248C52.3851 37.9448 52.3851 34.3948 50.2051 32.2148L50.1951 32.2248ZM39.6451 49.2748C37.8551 51.0648 34.9351 51.0648 33.1451 49.2748C31.3551 47.4848 31.3551 44.5648 33.1451 42.7748L37.7151 38.2048L44.2151 44.7048L39.6451 49.2748ZM49.4851 39.4248L44.9151 43.9948L38.4151 37.4948L42.9851 32.9248C43.8851 32.0248 45.0551 31.5848 46.2351 31.5848C47.4151 31.5848 48.5851 32.0348 49.4851 32.9248C51.2751 34.7148 51.2751 37.6348 49.4851 39.4248Z'
          fill='#034D83'
        />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke='#e9ecf0' />
      <defs>
        <clipPath id='clip0_19713_419292'>
          <rect width='53' height='46' fill='#ffffff' transform='translate(31 12)' />
        </clipPath>
      </defs>
    </svg>
  )
}
