import { unformatPhone } from '@vpharm-platform/shared'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { useCustomerProfile, useRefreshPrescriptionList } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { selectedPatientTokenAtom } from '../../persistRecoil'
import { transferOutService } from '../../services/transferOutService'
import { useCart } from '../Cart/hooks/useCart'

export interface UseTransferOutReturnValues {
  onPharmacySelect: (pharmacy: SelectedPharmacy | null) => void
  selectedPharmacy: SelectedPharmacy | null
  isPharmacyEligibleForAutoTransfer: boolean | null
  patientToken: string
  customerToken: string
  onPrescriptionSelect: (selectedPrescription: SelectedPrescription | null) => void
  isTransferOutReadyToSubmit: boolean
  submitTransferOut: () => Promise<void>
  isConfirmationModalOpen: boolean
  handleTransferOutModal: (isOpen: boolean) => void
  isSubmitting: boolean
  errorMessage: string
  handleTransferOutErrorTimeout: () => void
  preselectedRxToken?: string
}

export interface SelectedPharmacy {
  name: string
  street1: string
  street2?: string
  city: string
  zip: string
  state: string
  phone?: string
}

export interface SelectedPrescription {
  prescriptionToken: string
  rxNumber: string
  medName?: string
}

export const useTransferOut = (): UseTransferOutReturnValues => {
  const { customerProfile } = useCustomerProfile()
  const location = useLocation<{ preSelectedRxToken?: string }>()
  const patientToken = useRecoilValue(selectedPatientTokenAtom)
  const [selectedRx, setSelectedRx] = useState<SelectedPrescription | null>(null)
  const [selectedPharmacy, setSelectedPharmacy] = useState<SelectedPharmacy | null>(null)
  const [externalPharmacyLocationId, setExternalPharmacyLocationId] = useState<string | null>(null)
  const [isPharmacyEligibleForAutoTransfer, setIsPharmacyEligibleForAutoTransfer] = useState<boolean | null>(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const { removeMed } = useCart()
  const { trackTransferOutPharmacySearchEvent, trackTransferSubmittedEvent, trackErrorShownEvent, trackButtonClickEvent } = useAnalytics()

  const isTransferOutReadyToSubmit = !!selectedRx && !!externalPharmacyLocationId && !!isPharmacyEligibleForAutoTransfer && !!selectedPharmacy

  const resetSelectedPharmacy = () => {
    setExternalPharmacyLocationId(null)
    setIsPharmacyEligibleForAutoTransfer(null)
  }

  useEffect(() => {
    const verifyPharmacyForTransferOut = async (selectedPharmacy: SelectedPharmacy) => {
      try {
        const externalLocationId = await transferOutService.verifyTransferOutPharmacy(
          {
            street1: selectedPharmacy.street1,
            street2: selectedPharmacy.street2,
            city: selectedPharmacy.city,
            state: selectedPharmacy.state,
            zip: selectedPharmacy.zip,
          },
          customerProfile.vpharmCustomerToken,
        )
        setExternalPharmacyLocationId(externalLocationId)
        setIsPharmacyEligibleForAutoTransfer(true)
        trackTransferOutPharmacySearchEvent(true)
      } catch (e) {
        const aError = e as { response: { status: number } }
        if (aError.response.status === 400) {
          setIsPharmacyEligibleForAutoTransfer(false)
          setExternalPharmacyLocationId(null)
          trackTransferOutPharmacySearchEvent(false)
        } else {
          resetSelectedPharmacy()
        }
      }
    }

    if (selectedPharmacy) {
      verifyPharmacyForTransferOut(selectedPharmacy)
    } else {
      resetSelectedPharmacy()
    }
  }, [selectedPharmacy, customerProfile.vpharmCustomerToken, trackTransferOutPharmacySearchEvent])

  const handleSelectedPharmacy = (selectedPharmacy: SelectedPharmacy | null) => {
    if (!selectedPharmacy) {
      setSelectedPharmacy(null)
      return
    }

    setSelectedPharmacy({
      name: selectedPharmacy.name,
      street1: selectedPharmacy.street1,
      street2: selectedPharmacy.street2,
      city: selectedPharmacy.city,
      zip: selectedPharmacy.zip,
      state: selectedPharmacy.state,
      phone: selectedPharmacy.phone,
    })
  }

  const handleSelectedPrescription = (selectedPrescription: SelectedPrescription | null) => {
    setSelectedRx(selectedPrescription)
  }

  const handleTransferOutModal = (isOpen: boolean) => {
    if (isOpen) {
      trackButtonClickEvent('open_transfer_out_confirmation_modal', 'Transfer prescription')
    } else {
      trackButtonClickEvent('close_transfer_out_confirmation_modal', 'Transfer prescription')
    }

    setIsConfirmationModalOpen(isOpen)
  }

  const handleTransferOutErrorTimeout = () => {
    setErrorMessage('')
  }

  const submitTransferOut = async (): Promise<void> => {
    setErrorMessage('')
    if (!externalPharmacyLocationId || !selectedRx) {
      console.error('Unable to submit transfer out, missing external pharmacy location or prescription token')
      return
    }

    if (externalPharmacyLocationId && selectedRx && selectedPharmacy) {
      try {
        setIsSubmitting(true)
        await transferOutService.createTransferOut(
          {
            externalLocationId: externalPharmacyLocationId,
            prescriptionToken: selectedRx.prescriptionToken,
            pharmacyAddress: {
              street1: selectedPharmacy.street1,
              street2: selectedPharmacy.street2 || undefined,
              city: selectedPharmacy.city,
              state: selectedPharmacy.state,
              zip: selectedPharmacy.zip,
              name: selectedPharmacy.name,
              phoneNumber: selectedPharmacy.phone ? unformatPhone(selectedPharmacy.phone) : undefined,
            },
          },
          customerProfile.vpharmCustomerToken,
          patientToken,
        )
        removeMed(selectedRx.rxNumber)
        refreshPrescriptionList()
        trackTransferSubmittedEvent('transfer_out', selectedRx.medName || 'undefined', selectedPharmacy.name)
      } catch (e) {
        setIsConfirmationModalOpen(false)
        setErrorMessage('Something went wrong. Please refresh your browser or try again later.')
        trackErrorShownEvent('Unable to submit transfer out request')
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return {
    onPharmacySelect: handleSelectedPharmacy,
    selectedPharmacy,
    isPharmacyEligibleForAutoTransfer,
    customerToken: customerProfile.vpharmCustomerToken,
    patientToken,
    onPrescriptionSelect: handleSelectedPrescription,
    isTransferOutReadyToSubmit,
    submitTransferOut,
    isConfirmationModalOpen,
    handleTransferOutModal,
    isSubmitting,
    errorMessage,
    handleTransferOutErrorTimeout,
    preselectedRxToken: location?.state?.preSelectedRxToken ?? undefined,
  }
}
