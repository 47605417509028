import { AutoRefillMedicationInfo, VpTheme } from '@vpharm-platform/shared'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { useCustomerProfile, useRefreshPrescriptionList } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { AutoRefillPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import { CustomerProfile } from '../../interfaces'
import { OrderMode, orderModeAtom, selectedPatientTokenAtom } from '../../persistRecoil'
import { autoRefillService } from '../../services'
import { ISeo } from '../../types/generated/contentful'

export interface UseAutoRefillPageReturnValues {
  customerProfile: CustomerProfile
  autoRefillMeds: AutoRefillMedicationInfo[]
  isLoading: boolean
  error: string
  setError: (error: string) => void
  autoRefillMicrocopy?: AutoRefillPageMicrocopy
  theme: VpTheme
  seo?: ISeo
  submitEnrollAutoRefill: (prescriptionToken: string) => Promise<void>
  submitDisenrollAutoRefill: (prescriptionToken: string) => Promise<void>
  isCashOnly: boolean
}

export const useAutoRefillPage = (): UseAutoRefillPageReturnValues => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [autoRefillMeds, setAutoRefillMeds] = useState<AutoRefillMedicationInfo[]>([])
  const { customerProfile } = useCustomerProfile()
  const { trackToggleEvent } = useAnalytics()
  const { orderMode, cashOnly } = useRecoilValue(orderModeAtom)
  const { content: autoRefillMicrocopy, theme, seo } = useGetPageContent<AutoRefillPageMicrocopy>('autoRefillPage')
  const patientToken = useRecoilValue(selectedPatientTokenAtom)
  const { trackErrorShownEvent } = useAnalytics()
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const isCashOnly = orderMode === OrderMode.CASH && cashOnly

  const fetchEligibleAutoRefillRxs = useCallback(async () => {
    try {
      setIsLoading(true)
      const autoRefillMeds = await autoRefillService.getEligibleRxs(customerProfile.vpharmCustomerToken, patientToken)
      setAutoRefillMeds(autoRefillMeds)
    } catch (e) {
      trackErrorShownEvent('Unable to load auto refill medication list')
      setError('An unexpected error, failed to retrieve auto refill medication list')
    } finally {
      setIsLoading(false)
    }
  }, [customerProfile.vpharmCustomerToken, patientToken, trackErrorShownEvent])

  useEffect(() => {
    fetchEligibleAutoRefillRxs()
  }, [fetchEligibleAutoRefillRxs])

  const submitEnrollAutoRefill = async (prescriptionToken: string): Promise<void> => {
    try {
      await autoRefillService.enroll({ prescriptionTokens: [prescriptionToken] }, customerProfile.vpharmCustomerToken, patientToken)
      setAutoRefillMeds((prevAutoRefillMeds) => {
        const updatedAutoRefillMeds = prevAutoRefillMeds.map((med) => {
          if (med.prescriptionToken === prescriptionToken) {
            med.isAutoRefillEnabled = true
            const prescriptionNdc: string = med.ndc
            trackToggleEvent('auto_refills', true, prescriptionNdc)
            return med
          }

          return med
        })

        return updatedAutoRefillMeds
      })
      refreshPrescriptionList()
    } catch (e) {
      setError('Unable to enroll prescription into auto refill. Please try again later.')
    }
  }

  const submitDisenrollAutoRefill = async (prescriptionToken: string): Promise<void> => {
    try {
      await autoRefillService.disenroll(prescriptionToken, customerProfile.vpharmCustomerToken, patientToken)
      setAutoRefillMeds((prevAutoRefillMeds) => {
        const updatedAutoRefillMeds = prevAutoRefillMeds.map((med) => {
          if (med.prescriptionToken === prescriptionToken) {
            med.isAutoRefillEnabled = false
            const prescriptionNdc: string = med.ndc
            trackToggleEvent('auto_refills', false, prescriptionNdc)
            return med
          }

          return med
        })

        return updatedAutoRefillMeds
      })
      refreshPrescriptionList()
    } catch (e) {
      setError('Something went wrong trying to disenroll prescription into auto refill. Please try again later')
    }
  }

  return {
    customerProfile,
    autoRefillMeds,
    isLoading,
    error,
    setError,
    autoRefillMicrocopy,
    theme,
    seo,
    submitEnrollAutoRefill,
    submitDisenrollAutoRefill,
    isCashOnly,
  }
}
