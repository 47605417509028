import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

interface ICloseIcon {
  /**
   * Optional property, if TRUE then the wraper componen should define the 'color' style property indicating the desided color to use.
   */
  customColor?: boolean
}

/**
 * Returns a SVG image that can be configured with a custom stroke color.
 * @example
 * <span style={{ color: 'white' }}>
 *    <CloseButton customColor={true} />
 * </span>
 */
export const CloseButton = ({ customColor }: ICloseIcon): React.ReactElement => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18 6L6 18M6 6L18 18'
      stroke={customColor ? 'currentColor' : defaultTheme.colors.buttonPrimary}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
