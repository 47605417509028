import { KeyValuePair } from 'interfaces'
import { useFlags } from 'launchdarkly-react-client-sdk'

export function useLDFlagsWithLocalStorage(flagsToOverrideFromLocalStorage: string[] = []): Record<string, boolean> {
  const flags = useFlags()
  const overriddenValues = flagsToOverrideFromLocalStorage.reduce((curr, flag) => {
    const localStorageValue = localStorage.getItem(flag)

    if (localStorageValue === null) {
      return curr
    }

    try {
      curr[flag] = JSON.parse(localStorageValue) === true
    } catch (e) {
      return curr
    }

    return curr
  }, {} as KeyValuePair<boolean>)

  return { ...flags, ...overriddenValues }
}
