import { Spacer, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import { ReactElement, useCallback } from 'react'

import { FFSwitch } from '../../common/helpers/FFSwitch/FFSwitch'
import { LDContextReader } from '../../common/helpers/FFSwitch/LDContextReader'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { VP_5903_INSURANCE_MGMT_UPDATE } from '../../constants'
import { PatientInsurance } from '../../interfaces'
import LoadingAnimation from '../LoadingAnimation'
import { LoadingAnimationWrapper, StyledActionContainer, StyledCancelContainer, StyledHeader, StyledHeaderV2, Wrapper } from './styledComponents'

interface Props {
  theme: VpTheme
  currentInsurance: PatientInsurance
  isDeletingInsurance: boolean
  cancel: () => void
  handleUpdateInsurance: (insurance: PatientInsurance) => void
  handleDeleteInsurance: (insurance: PatientInsurance) => Promise<void>
}

export const DeleteInsuranceV1 = ({ theme, currentInsurance, isDeletingInsurance, cancel, handleDeleteInsurance }: Props): ReactElement => {
  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleDelete = useCallback(() => {
    handleDeleteInsurance(currentInsurance)
  }, [currentInsurance, handleDeleteInsurance])

  return (
    <>
      {isDeletingInsurance && (
        <LoadingAnimationWrapper vpTheme={theme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      <StyledHeader vpTheme={theme} variant='4xl'>
        Are you sure you want to delete your insurance?
      </StyledHeader>
      <Spacer size='lg' />
      <Wrapper vpTheme={theme}>
        <Text bold>{`Cardholder Id: ${currentInsurance.cardholder_id}`}</Text>
        <Text>{`Rx BIN: ${currentInsurance.rx_bin}`}</Text>
        <Text>{`Rx PCN: ${currentInsurance.pcn}`}</Text>
        <Text>{`Rx Group: ${currentInsurance.rx_group}`}</Text>
      </Wrapper>
      <Spacer size='lg' />
      <Text css={{ color: theme.colors['typography-medium'] }}>
        Deleting this will permanently remove it from your account. You can always add this again later.
      </Text>
      <Spacer size='md' />
      <StyledActionContainer>
        <ThemedButton role='button' variant='dark-text' size='lg' onClick={handleCancel} disabled={isDeletingInsurance} vpTheme={theme}>
          Cancel
        </ThemedButton>
        <Spacer />
        <ThemedButton role='button' danger={true} size='lg' onClick={handleDelete} disabled={isDeletingInsurance} vpTheme={theme}>
          Yes, delete insurance
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export const DeleteInsuranceV2 = ({
  theme,
  currentInsurance,
  isDeletingInsurance,
  cancel,
  handleUpdateInsurance,
  handleDeleteInsurance,
}: Props): ReactElement => {
  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleUpdate = useCallback(() => {
    handleUpdateInsurance(currentInsurance)
  }, [currentInsurance, handleUpdateInsurance])

  const handleDelete = useCallback(() => {
    handleDeleteInsurance(currentInsurance)
  }, [currentInsurance, handleDeleteInsurance])

  return (
    <>
      {isDeletingInsurance && (
        <LoadingAnimationWrapper vpTheme={theme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      <StyledHeaderV2 vpTheme={theme} variant='4xl'>
        Are you sure you want to delete your insurance?
      </StyledHeaderV2>
      <Spacer size='lg' />
      <Text css={{ color: theme.colors['typography-medium'] }}>
        Deleting this insurance will permanently remove it from your account, and will impact your prescription price. If you have added manufacturer
        savings cards, and this is the only insurance on your account, the savings cards will also be removed. To retain your savings cards, update
        your insurance details.
      </Text>
      <Spacer size='md' />
      <Wrapper vpTheme={theme}>
        <Text bold>{`Cardholder Id: ${currentInsurance.cardholder_id}`}</Text>
        <Text>{`Rx BIN: ${currentInsurance.rx_bin}`}</Text>
        <Text>{`Rx PCN: ${currentInsurance.pcn}`}</Text>
        <Text>{`Rx Group: ${currentInsurance.rx_group}`}</Text>
      </Wrapper>
      <Spacer size='lg' />
      <StyledActionContainer>
        <StyledCancelContainer>
          <ThemedButton role='button' variant='dark-text' size='md' onClick={handleCancel} disabled={isDeletingInsurance} vpTheme={theme}>
            Cancel
          </ThemedButton>
        </StyledCancelContainer>
        <Spacer />
        <ThemedButton role='button' variant='secondary-outline' size='lg' onClick={handleUpdate} disabled={isDeletingInsurance} vpTheme={theme}>
          Update insurance details
        </ThemedButton>
        <Spacer />
        <ThemedButton role='button' danger={true} size='lg' onClick={handleDelete} disabled={isDeletingInsurance} vpTheme={theme}>
          Yes, delete insurance
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default FFSwitch(DeleteInsuranceV2, DeleteInsuranceV1, new LDContextReader(VP_5903_INSURANCE_MGMT_UPDATE))
