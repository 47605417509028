import { ReactElement, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { PRESCRIPTION_MANAGEMENT_PATH } from '../../constants'
import { adminLogin } from '../../persistRecoil/adminLogin'

const Admin = (): ReactElement => {
  const [isAdminLogin, setIsAdminLogin] = useRecoilState(adminLogin)

  useEffect(() => {
    if (isAdminLogin) {
      window.location.replace(PRESCRIPTION_MANAGEMENT_PATH)
    }
  }, [isAdminLogin])

  useEffect(() => {
    setIsAdminLogin(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default Admin
