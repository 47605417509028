import React from 'react'
import styled from 'styled-components/macro'

interface UpDownChevronProps {
  ariaLabel?: string
  flipped?: boolean
  rotated?: boolean
  role?: string
  onClick?: () => void
}

export const UpDownChevron = ({ ariaLabel, flipped = false, rotated = false, role, onClick }: UpDownChevronProps): React.ReactElement => {
  return (
    <FlippableSvg
      width='10'
      height='6'
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label={ariaLabel}
      flipped={flipped}
      data-testid='up-down-chevron'
      rotated={rotated}
      role={role}
      onClick={onClick}
    >
      <path d='M1 1L5 5L9 1' stroke='#034D83' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </FlippableSvg>
  )
}

const FlippableSvg = styled.svg<{ flipped: boolean; rotated: boolean }>`
  ${({ flipped }) => flipped && 'transform: rotate(-180deg);'}
  ${({ rotated }) => rotated && 'transform: rotate(-90deg);'}
  transition: all 0.5s ease-out;
`
