import styled from 'styled-components'

import { ThemedComponent } from '../../../../common/styledComponents/types'
import { breakpoint } from '../../../../common/styles/variables'

const RoutingOptionDetails = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 150%;

  ${breakpoint.mobile} {
    flex-direction: column;
  }
`

const StyledContinueButton = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2.25rem;

  ${breakpoint.tablet} {
    > button {
      width: 100%;
    }
  }
`

interface RoutingOptionsContainerProps {
  ischecked: boolean
}

const RoutingOptionContainer = styled.div<RoutingOptionsContainerProps & ThemedComponent>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;

  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ ischecked, vpTheme }) => (ischecked ? vpTheme.colors['functional-info-light'] : vpTheme.colors.grayWhite)};
  border: 1px solid ${({ ischecked, vpTheme }) => (ischecked ? vpTheme.colors.buttonSecondary : vpTheme.colors['gray-500'])};
`

export { RoutingOptionContainer, RoutingOptionDetails, StyledContinueButton }
