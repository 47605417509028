import { yupResolver } from '@hookform/resolvers/yup'
import { SEO, Text } from '@truepill/react-capsule'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import SelfEnrollmentLink from '../../Components/SelfEnrollmentLink'
import StyledToastMessage from '../../Components/ToastMessage'
import { FORGOT_PASSWORD_PATH, PRESCRIPTION_MANAGEMENT_PATH } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { parseTextFieldStateForCapsule } from '../../utils'
import { LoginInfo } from '../../utils/validation'
import { ErrorContainer, Form, LinkStyles, MainContainer, MainHeader, SignInButtonContainer, Subtext } from './styledComponents'
import { LoginFormFields } from './types'
import { DEFAULT_TOAST_STATE, useLogin } from './useLogin'

const Login = (): React.ReactElement => {
  const { signIn, isAuthenticated, loginErrorMsg, customerProfile, handleInfoToastMsgChange, toastState, isAdminLogin, redirectTo } = useLogin()
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<LoginFormFields>({
    resolver: yupResolver(LoginInfo),
    delayError: 200,
  })
  const { theme } = useContentfulTheme()
  const { pharmCustomer } = useCustomerConfigContext()

  if (isAuthenticated) {
    return <Redirect to={redirectTo || PRESCRIPTION_MANAGEMENT_PATH} />
  }

  return (
    <MainContainer>
      <SEO title={`${pharmCustomer?.displayName} - Sign In`} useDefaults />
      <StyledToastMessage
        state={toastState.type}
        timeout={5000}
        onTimeout={() => handleInfoToastMsgChange(DEFAULT_TOAST_STATE)}
        visible={!!toastState.toastMessage}
        onDismiss={() => handleInfoToastMsgChange(DEFAULT_TOAST_STATE)}
        position={{ vertical: 'top' }}
      >
        <Text>{toastState.toastMessage}</Text>
      </StyledToastMessage>
      <MainHeader vpTheme={theme}>Sign in</MainHeader>
      <Subtext vpTheme={theme}>Please sign in to access your prescription manager & purchase your medication</Subtext>
      <Form onSubmit={handleSubmit(signIn)}>
        <ThemedTextField
          label='Email address'
          placeholder='Your email address'
          state={parseTextFieldStateForCapsule(errors.email, dirtyFields.email)}
          required
          showRequiredIndicator
          helperText={errors.email?.message ?? ''}
          {...register('email')}
          css={{
            backgroundColor: theme.colors.grayWhite,
          }}
          vpTheme={theme}
        />
        <ThemedTextField
          label='Password'
          placeholder='Your password'
          state={parseTextFieldStateForCapsule(errors.password, dirtyFields.password)}
          required
          showRequiredIndicator
          type='password'
          helperText={errors.password?.message ?? ''}
          {...register('password')}
          vpTheme={theme}
        />
        <Link to={FORGOT_PASSWORD_PATH} style={LinkStyles(theme)}>
          <Text css={{ color: theme.colors['primary-500'] }}>Forgot your password?</Text>
        </Link>
        {customerProfile.allowSelfEnrollment && !isAdminLogin && <SelfEnrollmentLink />}
        {loginErrorMsg && (
          <ErrorContainer vpTheme={theme}>
            <Text>{loginErrorMsg}</Text>
          </ErrorContainer>
        )}
        <SignInButtonContainer>
          <ThemedButton role='button' type='submit' disabled={isSubmitting} vpTheme={theme}>
            {isAdminLogin ? 'Sign In Admin' : 'Sign in'}
          </ThemedButton>
        </SignInButtonContainer>
      </Form>
    </MainContainer>
  )
}

export default Login
