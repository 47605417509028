import { atom, selector } from 'recoil'

import { customerProfileAtom } from './customerProfile'
import { patientProfileAtom } from './patientProfile'

export enum OrderMode {
  CASH = 'CASH',
  INSURANCE = 'INSURANCE',
}

export interface IOrderMode {
  orderMode: OrderMode
  cashOnly: boolean
}

export const evaluateOrderModeSelector = selector({
  key: 'orderModeState',
  get: ({ get }): IOrderMode => {
    const patientProfile = get(patientProfileAtom)
    const customerProfile = get(customerProfileAtom)

    if (customerProfile.cashOnly) {
      return { orderMode: OrderMode.CASH, cashOnly: true }
    }

    const result = { orderMode: OrderMode.INSURANCE, cashOnly: false }

    if (patientProfile !== undefined) {
      result.orderMode = patientProfile.patientHasInsurance ? OrderMode.INSURANCE : OrderMode.CASH
    }

    return result
  },
})

export const orderModeAtom = atom<IOrderMode>({
  default: evaluateOrderModeSelector,
  key: 'orderType',
})
