import { Spacer, Text } from '@truepill/react-capsule'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedSelect } from '../../common/styledComponents/ThemedSelect'
import { ACCOUNT_PAYMENTS_PATH } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { Payment } from '../../hooks/useUserPayments'
import { capitalizeFirstLetter } from '../../utils/stringUtilities'
import { StyledModal } from './styledComponents'

interface Props {
  paymentMethods: Payment[]
  onSubmit: (payment?: Payment) => void
}

type PaymentMethod = Payment & { label?: string }

export const DefaultPaymentModal: React.FC<Props> = ({ paymentMethods, onSubmit }) => {
  const defaultPayment = paymentMethods.find((a) => a.default)
  const [selectedPayment, setSelectedPayment] = useState<Payment | undefined>(defaultPayment)
  const { theme } = useContentfulTheme()

  const currentValue: PaymentMethod = useMemo(
    () =>
      selectedPayment
        ? {
            ...selectedPayment,
            label: `${capitalizeFirstLetter(selectedPayment.brand)} ${selectedPayment.last4}`,
          }
        : ({ label: 'Select a default payment' } as PaymentMethod),
    [selectedPayment],
  )

  const currentOptions: PaymentMethod[] = useMemo(
    () =>
      paymentMethods.map((option) => ({
        ...option,
        label: `${capitalizeFirstLetter(option.brand)} ${option.last4}`,
      })),
    [paymentMethods],
  )

  const handleChange = (address?: PaymentMethod) => {
    setSelectedPayment(address)
  }

  const handleSubmit = () => {
    onSubmit(selectedPayment)
  }

  return (
    <>
      <ThemedHeader vpTheme={theme} variant='4xl'>
        Update default payment method
      </ThemedHeader>
      <Spacer size='md' />
      <Text style={{ color: theme.colors['typography-medium'] }}>
        Select a payment option. Go to{' '}
        <Link style={{ color: theme.colors['primary-700'] }} to={ACCOUNT_PAYMENTS_PATH}>
          your profile
        </Link>{' '}
        to add a new payment option.
      </Text>
      <Spacer size='lg' />
      <ThemedSelect options={currentOptions} value={currentValue} selectedKey='label' label='Default payment' onChange={handleChange} />
      <Spacer size='lg' />
      <StyledModal.ActionContainer>
        <ThemedButton onClick={handleSubmit} vpTheme={theme} disabled={!selectedPayment}>
          Save
        </ThemedButton>
      </StyledModal.ActionContainer>
    </>
  )
}
