import { Spacer, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import React, { Fragment, useState } from 'react'
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather'

import { useContentfulTheme } from '../../hooks'
import { IValuePropsBlocksFields } from '../../types/generated/contentful'
import InfoCard from './InfoCard'
import { DotIndicator, SectionDots, ValuePropsCardInfoContainer, ValuePropsContentContainer, ValuePropsTitle } from './styledComponents'

// Note: This component's colors are set in the "Value Props Blocks"  Contentful model

const ValuePropsSection: React.FC<IValuePropsBlocksFields> = ({
  title,
  cardInfo,
  backgroundColor,
  secondaryBackgroundColor,
  titleTextBackgroundColor,
}) => {
  const { theme } = useContentfulTheme()
  const { isLargerThanMobile } = useWindowDimensions()
  const [carouselIndex, setCarouselIndex] = useState(0)

  const CAROUSEL_ITEMS = isLargerThanMobile ? 1 : 4

  // TODO: If these pages are used in the future, we should add analytics accordingly
  const onClickSectionCarousel = (index: number) => {
    setCarouselIndex(index)
  }

  const onClickRightArrows = () => {
    if (carouselIndex < cardInfo.length - 1) {
      setCarouselIndex(carouselIndex + 1)
    } else {
      setCarouselIndex(0)
    }
  }

  const onClickLeftArrows = () => {
    if (carouselIndex !== 0) {
      setCarouselIndex(carouselIndex - 1)
    } else {
      setCarouselIndex(cardInfo.length - 1)
    }
  }

  return cardInfo.length > 0 ? (
    <>
      <ValuePropsContentContainer backgroundColor={backgroundColor || theme.colors['primary-300'] || defaultTheme.colors['primary-300']} key={title}>
        <Spacer size='2xl' />
        <ValuePropsTitle variant='5xl' textColor={titleTextBackgroundColor}>
          {title}
        </ValuePropsTitle>
        <Spacer size='2xl' />
        <ValuePropsCardInfoContainer>
          {isLargerThanMobile && (
            <ArrowLeftCircle
              color={secondaryBackgroundColor}
              cursor={'pointer'}
              size={'50px'}
              onClick={() => onClickLeftArrows()}
              style={{ alignSelf: 'center' }}
            />
          )}
          {cardInfo.slice(carouselIndex * CAROUSEL_ITEMS, carouselIndex * CAROUSEL_ITEMS + CAROUSEL_ITEMS).map((item, index) => (
            <Fragment key={index}>
              <InfoCard
                key={item.fields.orderIdentifier}
                {...item.fields}
                height={isLargerThanMobile ? '358px' : '340px'}
                width={isLargerThanMobile ? '60%' : '100%'}
                topAvatar={false}
                headerSize={'4xl'}
              />
              {!(index === CAROUSEL_ITEMS - 1) && <Spacer size='2xl' />}
            </Fragment>
          ))}
          {isLargerThanMobile && (
            <ArrowRightCircle
              color={secondaryBackgroundColor}
              cursor={'pointer'}
              size={'50px'}
              onClick={() => onClickRightArrows()}
              style={{ alignSelf: 'center' }}
            />
          )}
        </ValuePropsCardInfoContainer>
        {cardInfo.length > CAROUSEL_ITEMS && (
          <SectionDots>
            {new Array(Math.ceil(cardInfo.length / CAROUSEL_ITEMS)).fill(0).map((_, index) => (
              <DotIndicator
                key={index}
                onClick={() => onClickSectionCarousel(index)}
                selected={carouselIndex === index}
                vpTheme={theme}
              ></DotIndicator>
            ))}
          </SectionDots>
        )}
        <Spacer size='4xl' />
      </ValuePropsContentContainer>
    </>
  ) : (
    <Text>Loading...</Text>
  )
}

export default ValuePropsSection
