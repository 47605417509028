import { Text } from '@truepill/react-capsule'
import { ReactElement } from 'react'

import { useContentfulTheme } from '../../../hooks'
import { BorderBox, BorderBoxContent, BorderBoxHeader } from './styledComponents'

interface HealthInformationItemProps {
  label: string
  text: string
  testId: string
}

export const HealthInformationItem = ({ label, text, testId }: HealthInformationItemProps): ReactElement => {
  const { theme } = useContentfulTheme()
  return (
    <BorderBox vpTheme={theme}>
      <BorderBoxHeader color={theme.colors['typography-dark']} background={theme.colors['gray-300']} variant='2xl' data-testid={testId + '-label'}>
        {label}
      </BorderBoxHeader>
      <BorderBoxContent>
        <Text data-testid={testId + '-text'}>{text}</Text>
      </BorderBoxContent>
    </BorderBox>
  )
}
