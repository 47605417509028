// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Button } from '@truepill/react-capsule'
import { ButtonRadiusType, VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from './types'

export const ThemedButton = styled(Button)<ThemedComponent & { danger?: boolean; dangerOutline?: boolean }>`
  ${({ disabled, variant, vpTheme, danger, dangerOutline }) => `
  border: ${getBorderByVariant({ variant: variant as string, vpTheme, disabled: !!disabled, hover: false, danger, dangerOutline })};
  border-radius: ${getBorderRadius({ type: vpTheme.buttonBorderRadius })};
  background-color: ${getBackgroundColorByVariant({
    variant: variant as string,
    vpTheme,
    disabled: !!disabled,
    hover: false,
    danger,
    dangerOutline,
  })};
  background: ${getBackgroundColorByVariant({
    variant: variant as string,
    vpTheme,
    disabled: !!disabled,
    hover: false,
    danger,
    dangerOutline,
  })} !important;
  color: ${getColorByVariant({ variant: variant as string, vpTheme, disabled: !!disabled, hover: false, danger, dangerOutline })} !important;

  @font-face {
    font-family: 'button font';
    src: url(${vpTheme.customFonts?.button_font}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  font-family: ${getFontFamilyByVariant({ vpTheme, variant: variant as string })};

  opacity: 1 !important;
  filter: opacity(1);
  transition: all 0.2s;
  &:hover {
  border: ${getBorderByVariant({ variant: variant as string, vpTheme, disabled: !!disabled, hover: false, danger, dangerOutline })} !important;
    color: ${getColorByVariant({ variant: variant as string, vpTheme, disabled: !!disabled, hover: true, danger, dangerOutline })} !important;
    filter: ${getHoverOpacityByVariant(variant as string, disabled, dangerOutline)} !important;
    background: ${getBackgroundColorByVariant({
      variant: variant as string,
      vpTheme,
      disabled: !!disabled,
      hover: true,
      danger,
      dangerOutline,
    })} !important;
    background-color: ${getBackgroundColorByVariant({
      variant: variant as string,
      vpTheme,
      disabled: !!disabled,
      hover: true,
      danger,
      dangerOutline,
    })};
  }
`}
`

const getHoverOpacityByVariant = (variant: string, disabled?: boolean, dangerOutline?: boolean) => {
  if (disabled || dangerOutline) return 'opacity(1)'

  switch (variant) {
    case 'primary-outline':
    case 'secondary-outline':
    case 'dark-text':
      return 'opacity(1)'
    case 'secondary':
    case 'primary-text':
    case 'secondary-text':
    default:
      return 'opacity(0.8)'
  }
}

interface ColorPicker {
  variant: string
  vpTheme: VpTheme
  disabled: boolean
  hover?: boolean
  danger?: boolean
  dangerOutline?: boolean
}

const getColorByVariant = ({ variant, vpTheme, disabled, hover, danger, dangerOutline }: ColorPicker) => {
  if (disabled) {
    return vpTheme.colors.buttonDisabledLabel
  }

  if (danger) {
    return vpTheme.colors.grayWhite
  }

  if (dangerOutline) {
    return hover ? vpTheme.colors.grayWhite : vpTheme.colors['functional-error-dark']
  }

  switch (variant) {
    case 'primary-outline':
      return hover ? vpTheme.colors.buttonPrimaryLabel : vpTheme.colors.buttonPrimary
    case 'secondary-outline':
      return hover ? vpTheme.colors.buttonSecondaryLabel : vpTheme.colors.buttonSecondary
    case 'dark-text':
      return vpTheme.colors['typography-dark']
    case 'secondary':
      return vpTheme.colors.buttonSecondaryLabel
    case 'primary-text':
      return vpTheme.colors.buttonPrimary
    case 'secondary-text':
      return vpTheme.colors.buttonSecondary
    default:
      return vpTheme.colors.buttonPrimaryLabel
  }
}

const getBackgroundColorByVariant = ({ variant, vpTheme, disabled, hover, danger, dangerOutline }: ColorPicker) => {
  if (disabled) {
    if (danger) return vpTheme.colors['functional-error-light']

    return vpTheme.colors.buttonDisabled
  }

  if (danger) {
    return vpTheme.colors['functional-error-dark']
  }
  if (dangerOutline) {
    return hover ? vpTheme.colors['functional-error-dark'] : 'transparent'
  }
  switch (variant) {
    case 'primary-text':
    case 'secondary-text':
    case 'dark-text':
      return 'transparent'
    case 'secondary':
      return vpTheme.colors.buttonSecondary
    case 'primary-outline':
      return hover ? vpTheme.colors.buttonPrimary : 'transparent'
    case 'secondary-outline':
      return hover ? vpTheme.colors.buttonSecondary : 'transparent'
    default:
      return vpTheme.colors.buttonPrimary
  }
}

const getBorderByVariant = ({ variant, vpTheme, disabled, dangerOutline }: ColorPicker) => {
  if (disabled) {
    if (dangerOutline) return `2px solid ${vpTheme.colors['functional-error-light']}`

    return `2px solid ${vpTheme.colors.buttonDisabled}`
  }

  if (dangerOutline) return `2px solid ${vpTheme.colors['functional-error-dark']}`

  switch (variant) {
    case 'primary':
    case 'primary-outline':
      return `2px solid ${vpTheme.colors.buttonPrimary}`
    case 'secondary':
    case 'secondary-outline':
      return `2px solid ${vpTheme.colors.buttonSecondary}`
    case 'primary-text':
    case 'secondary-text':
    case 'dark-text':
    default:
      return 'none'
  }
}

const getBorderRadius = ({ type }: { type: ButtonRadiusType }) => {
  switch (type) {
    case 'smooth':
      return '0.5rem'
    case 'round':
      return '3.125rem'
    case 'square':
      return '0px'
  }
  return 'smooth'
}

interface GetFontFamilyByVariantArgs {
  variant?: string
  vpTheme: VpTheme
}

/**
 * Buttons that have a background or outline get the new font, not the others
 * @param param0
 * @returns font family to apply
 */
const getFontFamilyByVariant = ({ variant = 'primary', vpTheme }: GetFontFamilyByVariantArgs) => {
  const variantsToApplyCustomFontTo: string[] = ['primary', 'primary-outline', 'secondary', 'secondary-outline']

  return variantsToApplyCustomFontTo.includes(variant) && vpTheme.customFonts?.button_font ? 'button font' : vpTheme.fonts?.base
}
