import { Text, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

interface CustomerCopyChangeProps {
  copyChangeText: string
}

const CustomerCopyChange = ({ copyChangeText }: CustomerCopyChangeProps): React.ReactElement => (
  <CustomerCopyChangeContainer>
    <CustomerCopyChangeText dangerouslySetInnerHTML={{ __html: copyChangeText }} />
  </CustomerCopyChangeContainer>
)

const CustomerCopyChangeContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
`

const CustomerCopyChangeText = styled(Text)`
  color: ${theme.colors['gray-700'].computedValue};
`

export default CustomerCopyChange
