import { Header, Spacer } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { ITwoColumnsFields } from '../../types/generated/contentful'
import {
  BrandLogoContainer,
  TextItem,
  TextItemIcon,
  TextItemsContainer,
  TwoColumnsBody,
  TwoColumnsContainer,
  TwoColumnsContentContainer,
  TwoColumnsImageContainer,
  TwoColumnsLogoContainer,
  TwoColumnsLogosContainer,
  TwoColumnsLogosSection,
  TwoColumnsSubtitle,
} from './styledComponents'

// Note: This component's colors are set in the "Two Columns"  Contentful model

const TwoColumns: React.FC<ITwoColumnsFields> = ({
  title,
  mainLogo,
  mainLogoLink,
  subtitle,
  body,
  backgroundColor,
  displayBulletPoints,
  bulletPointOne,
  bulletPointTwo,
  bulletPointThree,
  image,
  displayLogosSection,
  logosSectionTitle,
  logoOne,
  logoOneLink,
  logoTwo,
  logoTwoLink,
  logoThree,
  logoThreeLink,
}) => {
  const { isLargerThanTablet } = useWindowDimensions()
  const hasMainLogo = mainLogo?.fields.file.url
  const { trackLinkClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  return (
    <TwoColumnsContainer>
      {/* First column */}
      <TwoColumnsImageContainer imageUrl={`https:${image.fields.file.url}`} />

      {/* Second column */}
      <TwoColumnsContentContainer backgroundColor={backgroundColor || theme.colors['primary-300'] || defaultTheme.colors['primary-300']}>
        <>
          <Spacer size='3xl' />
          <Header variant='5xl'>{title}</Header>

          {mainLogo && (
            <TwoColumnsLogoContainer>
              <a
                href={mainLogoLink}
                target='_blank'
                rel='noreferrer'
                onClick={() => trackLinkClickEvent('two_columns_main_logo_click', mainLogoLink, LinkOrigin.Header)}
              >
                <img src={`http:${mainLogo.fields.file.url}`} alt='Logo' />
              </a>
            </TwoColumnsLogoContainer>
          )}

          {subtitle && (
            <TwoColumnsSubtitle
              variant={isLargerThanTablet && !hasMainLogo ? '4xl' : '2xl'}
              css={{ lineHeight: `${hasMainLogo ? '1.875rem' : 'auto'} ` }}
            >
              {subtitle}
            </TwoColumnsSubtitle>
          )}

          {body && <TwoColumnsBody>{body}</TwoColumnsBody>}

          {displayBulletPoints && (
            <TextItemsContainer>
              {bulletPointOne && (
                <TextItem variant='2xl'>
                  <TextItemIcon vpTheme={theme} /> {bulletPointOne}
                </TextItem>
              )}
              {bulletPointTwo && (
                <TextItem variant='2xl'>
                  <TextItemIcon vpTheme={theme} /> {bulletPointTwo}
                </TextItem>
              )}
              {bulletPointThree && (
                <TextItem variant='2xl'>
                  <TextItemIcon vpTheme={theme} /> {bulletPointThree}
                </TextItem>
              )}
            </TextItemsContainer>
          )}

          {displayLogosSection && (
            <TwoColumnsLogosSection>
              {logosSectionTitle && <TwoColumnsSubtitle variant={isLargerThanTablet ? '4xl' : '2xl'}>{logosSectionTitle}</TwoColumnsSubtitle>}
              <TwoColumnsLogosContainer>
                {logoOne && (
                  <BrandLogoContainer>
                    <a
                      href={logoOneLink}
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => trackLinkClickEvent('two_columns_seal_click', logoOneLink, LinkOrigin.Header)}
                    >
                      <img src={`http:${logoOne.fields.file.url}`} alt='Logo' />
                    </a>
                  </BrandLogoContainer>
                )}
                {logoTwo && (
                  <BrandLogoContainer>
                    <a
                      href={logoTwoLink}
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => trackLinkClickEvent('two_columns_seal_click', logoTwoLink, LinkOrigin.Header)}
                    >
                      <img src={`http:${logoTwo.fields.file.url}`} alt='Logo' />
                    </a>
                  </BrandLogoContainer>
                )}
                {logoThree && (
                  <BrandLogoContainer>
                    <a
                      href={logoThreeLink}
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => trackLinkClickEvent('two_columns_seal_click', logoThreeLink, LinkOrigin.Header)}
                    >
                      <img src={`http:${logoThree.fields.file.url}`} alt='Logo' />
                    </a>
                  </BrandLogoContainer>
                )}
              </TwoColumnsLogosContainer>
            </TwoColumnsLogosSection>
          )}

          <Spacer size='3xl' />
        </>
      </TwoColumnsContentContainer>
    </TwoColumnsContainer>
  )
}

export default TwoColumns
