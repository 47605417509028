import { Spacer } from '@truepill/react-capsule'
import AccordionForm from 'Components/Body/AccordionForm'
import { FormMode } from 'interfaces'
import React, { createElement, Fragment, useCallback } from 'react'

export interface AccordionStepForm {
  body: any
  data: AccordionStepFormProps
  formProps?: any
}

export interface AccordionStepFormProps {
  id: string
  name: string
  isEditDisabled?: boolean
  nameOnEdit?: string
  isOpen: boolean
  mode: FormMode
  subheader?: string
}

interface MultiStepAccordionFormProps {
  forms: AccordionStepForm[]
  setForms: React.Dispatch<React.SetStateAction<AccordionStepForm[]>>
}

const MultiStepAccordionForm = ({ forms, setForms }: MultiStepAccordionFormProps): React.ReactElement => {
  const onCompleteForm = useCallback(
    (id: string) => {
      // Set current form to view mode and open next one, unless it's the last
      setForms((prevState) => {
        const index = prevState.findIndex((form) => form.data.id === id)
        const isLast = index === prevState.length - 1
        const newForms = [...prevState]
        newForms[index].data.mode = FormMode.View
        newForms[index].data.isOpen = false
        if (!isLast) {
          newForms[index + 1].data.mode = FormMode.Edit
          newForms[index + 1].data.isOpen = true
        }
        return newForms
      })
    },
    [setForms],
  )

  const editFormHandler = (id: string) => {
    const index = forms.findIndex((form) => form.data.id === id)

    setForms((prevForms) => {
      const newForm = [...prevForms]
      newForm[index].data.mode = FormMode.Edit
      newForm[index].data.isOpen = true
      return newForm
    })
  }

  return (
    <>
      {forms.map((item) => {
        const { id, mode, isOpen, name, nameOnEdit, subheader } = item.data

        return (
          <Fragment key={id}>
            <AccordionForm
              id={id}
              isSubmitted={mode === FormMode.View}
              onEdit={editFormHandler}
              header={mode === FormMode.Edit && nameOnEdit ? nameOnEdit : name}
              subheader={subheader}
              isOpen={isOpen}
            >
              {createElement(item.body, {
                ...item.data,
                ...item.formProps,
                onCompleteForm,
              })}
            </AccordionForm>
            <Spacer />
          </Fragment>
        )
      })}
    </>
  )
}

export default MultiStepAccordionForm
