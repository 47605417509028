import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import { Asset } from 'contentful'
import { useCallback, useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'

export type ReturnValues = {
  theme: VpTheme
  logo?: Asset['fields']
  logoWhite?: Asset['fields']
  poweredByTruepillLogo?: Asset['fields']
  poweredByTruepillLogoWhite?: Asset['fields']
  logoHeight?: number
  logoHeightMobile?: number
}

export const useContentfulTheme = (): ReturnValues => {
  const [theme, setTheme] = useState<ReturnValues>({ theme: defaultTheme })
  const { pharmCustomer } = useCustomerConfigContext()

  const getTheme = useCallback(() => {
    try {
      const contentfulTheme = pharmCustomer?.themeConfiguration?.fields
      const componentConfig = pharmCustomer?.componentConfiguration?.fields
      if (contentfulTheme) {
        const customColors = {
          'primary-100': contentfulTheme.primary100,
          'primary-300': contentfulTheme.primary300,
          'primary-500': contentfulTheme.primary500,
          'primary-700': contentfulTheme.primary700,
          'primary-900': contentfulTheme.primary900,
          'gray-100': contentfulTheme.gray100,
          'gray-300': contentfulTheme.gray300,
          'gray-500': contentfulTheme.gray500,
          'gray-700': contentfulTheme.gray700,
          'functional-info-dark': contentfulTheme.functionalInfoDark,
          'functional-info-light': contentfulTheme.functionalInfoLight,
          'functional-warning-dark': contentfulTheme.functionalWarningDark,
          'functional-warning-light': contentfulTheme.functionalWarningLight,
          'functional-success-dark': contentfulTheme.functionalSuccessDark,
          'functional-success-light': contentfulTheme.functionalSuccessLight,
          'functional-error-dark': contentfulTheme.functionalErrorDark,
          'functional-error-light': contentfulTheme.functionalErrorLight,
          'typography-dark': contentfulTheme.typographyDark,
          'typography-medium': contentfulTheme.typographyMedium,
          grayWhite: contentfulTheme.grayWhite,
          pageBackground: contentfulTheme.pageBackground,
          iconDefault: contentfulTheme.iconDefault,
          brandedTab: contentfulTheme.brandedTab,
          rxmHeroBackground: contentfulTheme.rxmHeroBackground,
          rxmSavingsCardChipLabel: contentfulTheme.rxmSavingsCardChipLabel,
          rxmSavingsCardChipBackground: contentfulTheme.rxmSavingsCardChipBackground,
          rxmSavingsCardRibbon: contentfulTheme.rxmSavingsCardRibbon,
          rxmSavingsCardRibbonBackground: contentfulTheme.rxmSavingsCardRibbonBackground,
          sitewideBannerText: contentfulTheme.sitewideBannerText,
          sitewideBannerBackground: contentfulTheme.sitewideBannerBackground,
          navBackground: contentfulTheme.navBackground,
          navItem: contentfulTheme.navItem,
          navAvatarBg: contentfulTheme.navAvatarBg,
          navAvatarText: contentfulTheme.navAvatarText,
          footerBackground: contentfulTheme.footerBackground,
          footerBorder: contentfulTheme.footerBorder,
          footerTextMain: contentfulTheme.footerTextMain,
          fieldBgDefault: contentfulTheme.fieldBgDefault,
          fieldBgComplete: contentfulTheme.fieldBgComplete,
          fieldBorderDefault: contentfulTheme.fieldBorderDefault,
          fieldBorderComplete: contentfulTheme.fieldBorderComplete,
          buttonPrimary: contentfulTheme.buttonPrimary,
          buttonPrimaryLabel: contentfulTheme.buttonPrimaryLabel,
          buttonSecondary: contentfulTheme.buttonSecondary,
          buttonSecondaryLabel: contentfulTheme.buttonSecondaryLabel,
          buttonDisabled: contentfulTheme.buttonDisabled,
          buttonDisabledLabel: contentfulTheme.buttonDisabledLabel,
        }
        const customFonts = contentfulTheme.customFonts

        const colors = Object.fromEntries(Object.entries(customColors).filter(([, v]) => !!v))

        setTheme({
          logo: componentConfig?.logo.fields,
          logoWhite: componentConfig?.logoWhite.fields,
          logoHeight: componentConfig?.logoHeight,
          logoHeightMobile: componentConfig?.logoHeightMobile,
          poweredByTruepillLogo: componentConfig?.poweredByTruepillLogo?.fields,
          poweredByTruepillLogoWhite: componentConfig?.poweredByTruepillLogoWhite?.fields,
          theme: {
            ...defaultTheme,
            colors: { ...defaultTheme.colors, ...colors },
            customFonts: {
              header_font: customFonts?.header_font,
              button_font: customFonts?.button_font,
            },
            buttonBorderRadius: contentfulTheme.buttonBorderRadius || 'smooth',
          },
        })
      }
    } catch (e) {
      setTheme({ theme: defaultTheme })
    }
  }, [pharmCustomer])

  useEffect(() => {
    getTheme()
  }, [getTheme])

  return theme
}
