import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const HeadsetIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    stroke={vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']}
    strokeWidth='1.5'
    strokeMiterlimit='10'
  >
    <path d='M14 23h5a3 3 0 0 0 3-3v-3' strokeLinecap='square' />
    <path d='M7 12H2v5a2 2 0 0 0 2 2h3v-7zm15 0h-5v7h3a2 2 0 0 0 2-2v-5z' />
    <path d='M22 12v-1a10 10 0 1 0-20 0v1' strokeLinecap='square' />
  </svg>
)
