import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'

interface SelfEnrollmentButtonProps {
  isDisabled: boolean
  buttonText: string
}

const SelfEnrollmentButton = ({ isDisabled, buttonText }: SelfEnrollmentButtonProps): JSX.Element => {
  const { theme } = useContentfulTheme()
  return (
    <StyledSelfEnrollmentButtonContainer>
      <ThemedButton type='submit' disabled={isDisabled} vpTheme={theme}>
        {buttonText}
      </ThemedButton>
    </StyledSelfEnrollmentButtonContainer>
  )
}

const StyledSelfEnrollmentButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default SelfEnrollmentButton
