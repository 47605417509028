import { sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'
import { OrderMode } from '../persistRecoil'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface Address {
  address1: string
  city: string
  state: string
  zip: string
}

export class TaxService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async calculateTotalTax(
    prescriptionTokens: string[],
    address: Address,
    customerToken: string,
    patientToken: string,
    orderMode: OrderMode,
  ): Promise<number> {
    const url = `${this.baseUrl}/tax/tax_calculation`
    const config: AxiosRequestConfig = {
      headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
    }

    const response = await axiosAuth.post<number>(
      url,
      {
        medications: prescriptionTokens,
        address: address,
        orderMode,
      },
      config,
    )

    return response.data
  }
}
