import { Avatar, Spacer } from '@truepill/react-capsule'
import { selectedPatientTokenAtom, userProfileState } from 'persistRecoil'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { HamburgerIcon } from '../../assets/Icons'
import { useAuth } from '../../context/auth-context'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { StyledContainer, StyledProfileButton } from './styledComponents'

interface Props {
  isDarkMode?: boolean
  onClick: () => void
}

interface ProfileNavBarIconProps {
  onClick: () => void
  isDarkMode?: boolean
  initials: string
}

const ProfileNavBarIconV2Improved = ({ onClick, initials }: ProfileNavBarIconProps): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const { trackABTestingRenderEvent, trackButtonClickEvent } = useAnalytics()

  const BUTTON_NAME = 'navbar_profile_icon_improved'

  const handleClick = () => {
    trackButtonClickEvent(BUTTON_NAME, 'Show Account Menu', 'Improved Profile icon to show account menu clicked')
    onClick()
  }

  useEffect(() => {
    trackABTestingRenderEvent('Navbar profile icon improvement AB Test', 'Improved Navbar Profile Icon')
  }, [trackABTestingRenderEvent])

  return (
    <StyledContainer vpTheme={theme} aria-label='open account management menu' onClick={handleClick}>
      <HamburgerIcon vpTheme={theme} />
      <Spacer size='sm' />
      <StyledProfileButton vpTheme={theme}>
        <Avatar size='sm' initials={initials} />
      </StyledProfileButton>
    </StyledContainer>
  )
}

const ProfileButton = ({ onClick }: Props): React.ReactElement => {
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const {
    authState: { isAuthenticated, isLoading },
  } = useAuth()

  const selectedPatient = useRecoilValue(userProfileState)?.authorizedPatients?.[selectedPatientToken]
  const initials = selectedPatient ? selectedPatient.firstName.charAt(0) + selectedPatient.lastName.charAt(0) : ''

  if (isLoading) {
    // TODO: add real loading component
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <></>
  }

  return <ProfileNavBarIconV2Improved initials={initials} onClick={onClick} />
}

export default ProfileButton
