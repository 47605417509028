import { atom } from 'recoil'

export interface CartPreview {
  visible: boolean
  addedMedicationInfo: {
    daysSupply?: string
    name: string
    price: string
    quantity: number
    quantityUnits: string
  }
}

export const cartPreviewState = atom<CartPreview>({
  key: 'cartPreview',
  default: {
    visible: false,
    addedMedicationInfo: {
      daysSupply: '',
      name: '',
      price: '',
      quantity: 0,
      quantityUnits: '',
    },
  },
})
