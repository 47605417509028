import { Spacer } from '@truepill/react-capsule'
import { CloseButton as CloseButtonIcon } from 'assets/Icons'
import MobileDrawerModal from 'Components/MobileDrawerModal'
import { ABOUT_PATH, CONTACT_PATH, FAQ_PATH, LOGIN_PATH, SELF_ENROLLMENT_PATH } from 'constants/navigation-links'
import { MobileNavigationDrawerProps, NavigationLink } from 'interfaces/Header'
import { ReactElement } from 'react'
import { Info, Mail, MessageSquare, User, UserPlus } from 'react-feather'

import { useContentfulTheme } from '../../hooks'
import { useCustomerConfigContext } from '../CustomerConfigProvider'
import AccountNavigation from './AccountNavigation'
import NavigationLinks from './NavigationLinks'
import OrdersNavigation from './OrdersNavigation'
import PrescriptionManagementNavigation from './PrescriptionManagementNavigation'
import { StyledCloseButton, StyledCloseButtonWrapper } from './styledComponents'

const MobileNavigationDrawer = ({
  isOpen,
  isAuthenticated,
  onDismiss,
  isCustomerTerminated,
  allowSelfEnrollment,
  background,
  itemColor,
}: MobileNavigationDrawerProps): ReactElement => {
  const { pharmCustomer } = useCustomerConfigContext()
  const displayAboutLink = pharmCustomer?.componentConfiguration?.fields?.footer?.fields.displayAboutLink
  const displayFaqLink = pharmCustomer?.componentConfiguration?.fields?.footer?.fields.displayFaqLink
  const faqLink = pharmCustomer?.customerSupport?.fields?.faqLink
  const { theme } = useContentfulTheme()

  const signedOutLinks: NavigationLink[] = [
    {
      type: 'NavigationPath',
      name: 'Sign In',
      path: LOGIN_PATH,
      icon: <User color={theme.colors.iconDefault} />,
    },
  ]

  if (allowSelfEnrollment) {
    signedOutLinks.unshift({
      type: 'NavigationPath',
      name: 'Sign Up',
      path: SELF_ENROLLMENT_PATH,
      icon: <UserPlus color={theme.colors.iconDefault} />,
    })
  }

  if (displayAboutLink) {
    signedOutLinks.push({
      type: 'NavigationPath',
      name: 'About',
      path: ABOUT_PATH,
      icon: <Info color={theme.colors.iconDefault} />,
    })
  }

  if (displayFaqLink) {
    signedOutLinks.push({
      type: 'NavigationPath',
      name: 'Help Center',
      path: faqLink || FAQ_PATH,
      target: faqLink ? '_blank' : '_self',
      icon: <MessageSquare color={theme.colors.iconDefault} />,
    })
  }

  signedOutLinks.push({
    type: 'NavigationPath',
    name: 'Contact',
    path: CONTACT_PATH,
    icon: <Mail color={theme.colors.iconDefault} />,
  })

  if (isCustomerTerminated) {
    signedOutLinks.shift()
  }

  const PrescriptionManagerOrderHistory = () => (
    <>
      <PrescriptionManagementNavigation onLinkClick={onDismiss} itemColor={itemColor} />
      <>
        <Spacer />
        <OrdersNavigation onLinkClick={onDismiss} itemColor={itemColor} />
      </>
    </>
  )

  return (
    <MobileDrawerModal isOpen={isOpen} onDismiss={onDismiss} background={background}>
      <StyledCloseButtonWrapper>
        <StyledCloseButton onClick={onDismiss}>
          <CloseButtonIcon />
        </StyledCloseButton>
      </StyledCloseButtonWrapper>
      <Spacer size='lg' />
      {isAuthenticated && (
        <>
          <PrescriptionManagerOrderHistory />
          <Spacer />
          <AccountNavigation onLinkClick={onDismiss} itemColor={itemColor} />
        </>
      )}
      {!isAuthenticated && (
        <>
          <NavigationLinks itemColor={itemColor} onLinkClick={onDismiss} links={signedOutLinks} />
        </>
      )}
    </MobileDrawerModal>
  )
}

export { MobileNavigationDrawer }
