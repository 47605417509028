import { Text } from '@truepill/react-capsule'
import { PlusCircle } from 'react-feather'
import styled from 'styled-components/macro'

import { Modal } from '../../common/styledComponents/StyledModal'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

const StyledPlusCircle = styled(PlusCircle)<ThemedComponent>`
  width: 22.5px;
  margin-right: 10px;
  top: 6px;
  position: relative;
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
  display: inline-block;
`

const StyledShowMoreCardsButton = styled(ThemedButton)`
  padding: 0;
  margin: 0;
`

const PaymentModalWrapper = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 2rem;
    right: 0.8rem;
  }
  ${mediaLargerThan.tablet} {
    [data-reach-dialog-content] {
      max-width: 45rem;
    }
  }
`

const ModalWrapper = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 2rem;
    right: 0.8rem;
  }
`

const StyledHeader = styled(ThemedHeader)<ThemedComponent>`
  max-width: 360px;
  ${mediaLargerThan.tablet} {
    max-width: 420px;
  }
`

const StyledActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  > *:first-child {
    margin-top: 0.5rem;
  }
  @media (min-width: 540px) {
    flex-flow: row;
    flex-wrap: nowrap;
    > *:first-child {
      margin-top: 0;
      margin-right: 1.5rem;
    }
    justify-content: flex-end;
  }
`

const PaymentCard = styled.div<ThemedComponent>`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
`

const PaymentIcon = styled.img`
  min-width: 32px;
  max-height: 20px;
  width: 2rem;
`

const PaymentDeleteText = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
`

export {
  ModalWrapper,
  PaymentCard,
  PaymentDeleteText,
  PaymentIcon,
  PaymentModalWrapper,
  StyledActionContainer,
  StyledHeader,
  StyledPlusCircle,
  StyledShowMoreCardsButton,
}
