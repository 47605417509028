import { Avatar, Box, Header, Spacer, Text } from '@truepill/react-capsule'
import { HeaderVariant } from '@truepill/react-capsule/dist/types'
import { defaultTheme } from '@vpharm-platform/shared'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { IInfoCardFields } from '../../types/generated/contentful'
import { IconContainer } from './styledComponents'

// Note: This component's colors are set in the "Info Card"  Contentful model

interface IInfoCardProps extends IInfoCardFields {
  height: string
  width: string
  topAvatar: boolean
  headerSize?: HeaderVariant
}

const InfoCard: React.FC<IInfoCardProps> = ({
  title,
  description,
  icon,
  orderIdentifier,
  backgroundColor,
  identifierBackgroundColor,
  textColor,
  identifierTextColor,
  height,
  width,
  topAvatar,
  headerSize,
}) => {
  const { isLargerThanMobile } = useWindowDimensions()
  const { theme: vpTheme } = useContentfulTheme()

  return (
    <Box
      css={{
        padding: '$md',
        borderRadius: '20px',
        backgroundColor: backgroundColor,
        color: vpTheme.colors.grayWhite || defaultTheme.colors.grayWhite,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center',
        height: height,
        width: width,
      }}
    >
      {topAvatar && (
        <Avatar
          initials={orderIdentifier}
          size='lg'
          css={{
            backgroundColor: identifierBackgroundColor || vpTheme.colors['primary-300'] || defaultTheme.colors['primary-300'],
            textColor: identifierTextColor || vpTheme.colors['primary-300'] || defaultTheme.colors['primary-300'],
            marginTop: isLargerThanMobile ? '-43px' : '-45px',
          }}
        />
      )}
      <Spacer size={isLargerThanMobile ? '2xl' : 'xl'} />
      <IconContainer
        src={`https:${icon.fields.file.url}`}
        alt={title}
        backgroundColor={backgroundColor || vpTheme.colors['primary-300'] || defaultTheme.colors['primary-300']}
      />
      <Spacer size={isLargerThanMobile ? 'xl' : 'lg'} />
      <Header
        variant={headerSize || 'xl'}
        css={{
          color: textColor,
        }}
      >
        {title}
      </Header>
      <Spacer size={isLargerThanMobile ? 'xl' : 'lg'} />
      <Text
        variant='body-sm'
        css={{
          display: 'flex',
          width: '90%',
          color: textColor,
          justifyContent: 'center',
          userSelect: 'none',
        }}
      >
        {description}
      </Text>
      <Spacer size={isLargerThanMobile ? '2xl' : 'xl'} />
    </Box>
  )
}

export default InfoCard
