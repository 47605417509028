import { LAPTOP_BREAK_POINT, MOBILE_BREAK_POINT, TABLET_BREAK_POINT } from 'common/styles/variables'
import { useEffect, useState } from 'react'

interface ReturnValues {
  width: number
  height: number
  isLargerThanTablet: boolean
  isLargerThanLaptop: boolean
  isLargerThanMobile: boolean
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = (): ReturnValues => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    ...windowDimensions,
    isLargerThanTablet: windowDimensions.width >= TABLET_BREAK_POINT,
    isLargerThanLaptop: windowDimensions.width >= LAPTOP_BREAK_POINT,
    isLargerThanMobile: windowDimensions.width >= MOBILE_BREAK_POINT,
  }
}
