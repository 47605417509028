import { theme } from '@truepill/react-capsule'
import { OrderDetails } from '@vpharm-platform/shared'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { media } from '../../../common/styles/variables'

type Props = {
  status: OrderDetails['status']
  orderId: string
  onClick?: () => void
}

const OrderReceiptStatus = ({ status, orderId, onClick }: Props): JSX.Element => {
  switch (status) {
    case 'SUCCESS':
      return (
        <ViewOrderDetailsLink to={`/orders/${orderId}`} data-testid='order-card-details-nav' onClick={onClick}>
          View order receipt
        </ViewOrderDetailsLink>
      )
    default:
      return <></>
  }
}

const ViewOrderDetailsLink = styled(Link)`
  color: ${theme.colors['functional-info-dark'].computedValue};
  line-height: 24px;
  font-weight: 400;
  margin-right: 16px;
  text-underline-offset: 1px;
  @media (max-width: ${media.tablet}) {
    margin-left: 16px;
  }
`

export default OrderReceiptStatus
