export enum PatientRegistrationSessionKeys {
  RegistrationToken = 'registrationToken',
  VpharmCustomerToken = 'vpharmCustomerToken',
  IdentityVerificationToken = 'identityVerificationToken',
  JwtToken = 'jwtToken',
}

export enum ContentfulSessionKeys {
  ContentfulPreviewToken = 'contentfulPreviewToken',
}
