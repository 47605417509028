import { Document } from '@contentful/rich-text-types'
import { Text, theme } from '@truepill/react-capsule'
import React from 'react'
import styled from 'styled-components'

import RichTextRenderer from '../RichTextRenderer'

interface BannerProps {
  text?: string
  richText?: Document
}

const WarningBanner: React.FunctionComponent<BannerProps> = ({ text, richText }: BannerProps) => {
  const getDisplayText = () => {
    if (richText)
      return <RichTextRenderer document={richText} css={{ fontSize: '0.875rem', color: theme.colors['functional-warning-dark'].computedValue }} />
    else return <Text css={{ fontSize: '0.875rem', color: `${theme.colors['functional-warning-dark'].computedValue}` }}>{text}</Text>
  }

  return <BannerContainer>{getDisplayText()}</BannerContainer>
}

const BannerContainer = styled.div`
  display: flex;
  padding: 1rem 1rem 1rem 0.7rem;
  align-items: center;
  border-left: solid 4px ${theme.colors['functional-warning-dark'].computedValue};
  color: ${theme.colors['functional-warning-dark'].computedValue};
  max-width: 743px;
  border-radius: 8px;
  background: ${theme.colors['functional-warning-light'].computedValue};
  flex: none;
`

export default WarningBanner
