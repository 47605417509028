import { Toast } from '@truepill/react-capsule'
import { ToastProps } from '@truepill/react-capsule/dist/components/toast/Toast'
import { ReactElement } from 'react'
import styled from 'styled-components/macro'

import { breakpoint } from '../../common/styles/variables'

const ToastMessage = ({ children, className: rootClassName, ...props }: ToastProps): ReactElement => (
  <StyledToast
    borderLeft={true}
    color='pastel'
    icon={true}
    position={{ vertical: 'top', horizontal: 'center' }}
    {...props}
    rootClassName={rootClassName}
  >
    {children}
  </StyledToast>
)

const StyledToastMessage = styled(ToastMessage)`
  width: 744px;
  margin-top: 5rem;

  ${breakpoint.tablet} {
    width: 100%;
    padding: 0 24px;
  }
`

const StyledToast = styled(Toast)`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
`

export default StyledToastMessage
