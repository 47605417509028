import { SEO } from '@truepill/react-capsule'
import { CreateSurveyRequest } from '@vpharm-platform/shared'
import MultiStepAccordionForm, { AccordionStepForm } from 'Components/Body/MultiStepAccordionForm'
import {
  COMPLETED_HEALTH_QUESTION_LABEL,
  COMPLETED_HEALTH_QUESTION_PLACEHOLDER,
  HEALTH_QUESTION_INSTRUCTIONS,
  LACTATION_ANSWER,
  PATIENT_HEALTH_QUESTION,
  PATIENT_HEALTH_QUESTION_TYPE,
  PregnancyStatus,
  PROCREATIVE_MANAGEMENT_ANSWER,
} from 'constants/patientHealthSurvey'
import { useContentfulTheme, useCustomerProfile, usePatientProfile, useShowError } from 'hooks'
import { CheckoutFlowData, FormMode, MultiStepData } from 'interfaces'
import AccordionQuestion from 'pages/CheckoutFlow/AccordionQuestion'
import CheckoutPage from 'pages/CheckoutFlow/CheckoutPage'
import { selectedPatientTokenAtom } from 'persistRecoil'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { surveyService } from 'services'
import { HealthHistoryOptions, PostHealthHistoryDto } from 'services/dto/health-history.dto'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import LoadingAnimation from '../../Components/LoadingAnimation'
import { SEPARATE_URAC_SURVEY_PREGNANCY_FIELD } from '../../constants'
import { useAnalytics } from '../../hooks/analytics-context'
import { useHealthSurvey } from '../../hooks/useHealthSurvey'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import PregnancyStatusQuestion from './PregnancyStatusQuestion'
import { StyledSubmitButtonContainer } from './styledComponents'

const parseUracData = (data: PostHealthHistoryDto, separateUracSurveyPregnancyFieldFF: boolean): CreateSurveyRequest => {
  if (separateUracSurveyPregnancyFieldFF) {
    let conditions = data.healthConditions === HealthHistoryOptions.Yes ? data.healthConditionsDetails : []

    const specialConditions = [LACTATION_ANSWER, PROCREATIVE_MANAGEMENT_ANSWER]
    conditions = conditions.filter((condition) => !specialConditions.includes(condition))

    const pregnant = data.pregnancyStatus?.includes(PregnancyStatus.Pregnant)
    const lactating = data.pregnancyStatus?.includes(PregnancyStatus.Lactating)
    const planning = data.pregnancyStatus?.includes(PregnancyStatus.Planning)

    if (lactating) {
      conditions.push(LACTATION_ANSWER)
    }

    if (planning) {
      conditions.push(PROCREATIVE_MANAGEMENT_ANSWER)
    }

    return {
      medications: data.medicationsAndSupplements === HealthHistoryOptions.Yes ? data.medicationsAndSupplementsDetails.join(',') : '',
      allergies: data.allergies === HealthHistoryOptions.Yes ? data.allergiesDetails.join(',') : '',
      health_conditions: conditions.join(','),
      pregnancy: pregnant ? HealthHistoryOptions.Yes : HealthHistoryOptions.No,
    }
  } else {
    return {
      medications: data.medicationsAndSupplements === HealthHistoryOptions.Yes ? data.medicationsAndSupplementsDetails.join(',') : '',
      allergies: data.allergies === HealthHistoryOptions.Yes ? data.allergiesDetails.join(',') : '',
      health_conditions: data.healthConditions === HealthHistoryOptions.Yes ? data.healthConditionsDetails.join(',') : '',
      pregnancy: data.pregnantOrLactating || HealthHistoryOptions.No,
    }
  }
}

const PatientHealthSurvey: React.FC = () => {
  const { customerProfile } = useCustomerProfile()
  const { patientProfile } = usePatientProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { healthSurvey, setHealthSurvey, isLoading } = useHealthSurvey(customerProfile.vpharmCustomerToken, selectedPatientToken)
  const { showError } = useShowError()
  const { patientHealthSurvey } = healthSurvey
  const isFirstTimeVisit = patientHealthSurvey === null
  const { trackButtonClickEvent } = useAnalytics()
  const { [SEPARATE_URAC_SURVEY_PREGNANCY_FIELD]: separateUracSurveyPregnancyFieldFF } = useLDFlagsWithLocalStorage([
    SEPARATE_URAC_SURVEY_PREGNANCY_FIELD,
  ])
  const { theme } = useContentfulTheme()
  const { pharmCustomer } = useCustomerConfigContext()

  const history = useHistory()
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const saveAnswerHandler = (id: string, details: string[], shortAnswer: HealthHistoryOptions) => {
    if (!healthQuestions) return
    const index = healthQuestions.findIndex((q: MultiStepData) => q.data.id === id)

    setHealthQuestions((prevList: MultiStepData[]) => {
      const survey = [...prevList]
      const formProps = survey[index].formProps
      survey[index].data.name = COMPLETED_HEALTH_QUESTION_LABEL[id]
      if (formProps) {
        formProps.data.answerDetails = details
        formProps.data.answer = shortAnswer
      }
      return survey
    })

    if (isFirstTimeVisit) {
      trackButtonClickEvent('URAC save answer', 'save', `urac_first_time_visit_${id}_question_answered`)
    }
  }

  const savePregnancyAnswerHandler = (id: string, answer: string[]) => {
    if (!healthQuestions) return
    const index = healthQuestions.findIndex((q: MultiStepData) => q.data.id === id)

    setHealthQuestions((prevList: MultiStepData[]) => {
      const survey = [...prevList]
      const formProps = survey[index].formProps
      survey[index].data.name = COMPLETED_HEALTH_QUESTION_LABEL[id]
      if (formProps) {
        formProps.data.answer = answer as PregnancyStatus[]
      }
      return survey
    })

    if (isFirstTimeVisit) {
      trackButtonClickEvent('URAC save answer', 'save', `urac_first_time_visit_${id}_question_answered`)
    }
  }

  const baseSurvey: MultiStepData[] = [
    {
      body: AccordionQuestion,
      data: {
        id: PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES,
        name: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES],
        nameOnEdit: PATIENT_HEALTH_QUESTION[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES],
        mode: isFirstTimeVisit ? FormMode.Edit : FormMode.View,
        isOpen: isFirstTimeVisit,
      },
      formProps: {
        onSaveAnswer: saveAnswerHandler,
        isDisabled: setSubmitDisabled,
        data: {
          id: PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES,
          answerLabel: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES],
          inputPlaceholder: COMPLETED_HEALTH_QUESTION_PLACEHOLDER[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES],
          pleaseListText: HEALTH_QUESTION_INSTRUCTIONS[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES],
          answer: patientHealthSurvey?.allergies,
          answerOptions: [
            { label: 'Yes', value: HealthHistoryOptions.Yes },
            { label: 'No', value: HealthHistoryOptions.No },
          ],
          answerDetails: patientHealthSurvey?.allergiesDetails ?? [],
          showFormWhenOptionSelected: [HealthHistoryOptions.Yes],
        },
      },
    },
    {
      body: AccordionQuestion,
      data: {
        id: PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS,
        name: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS],
        nameOnEdit: PATIENT_HEALTH_QUESTION[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS],
        mode: isFirstTimeVisit ? FormMode.Edit : FormMode.View,
        isOpen: false,
      },
      formProps: {
        onSaveAnswer: saveAnswerHandler,
        isDisabled: setSubmitDisabled,
        data: {
          id: PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS,
          answerLabel: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS],
          inputPlaceholder: COMPLETED_HEALTH_QUESTION_PLACEHOLDER[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS],
          pleaseListText: HEALTH_QUESTION_INSTRUCTIONS[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS],
          answer: patientHealthSurvey?.healthConditions,
          answerOptions: [
            { label: 'Yes', value: HealthHistoryOptions.Yes },
            { label: 'No', value: HealthHistoryOptions.No },
          ],
          answerDetails: patientHealthSurvey?.healthConditionsDetails ?? [],
          showFormWhenOptionSelected: [HealthHistoryOptions.Yes],
        },
      },
    },
    {
      body: AccordionQuestion,
      data: {
        id: PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS,
        name: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS],
        nameOnEdit: PATIENT_HEALTH_QUESTION[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS],
        mode: isFirstTimeVisit ? FormMode.Edit : FormMode.View,
        isOpen: false,
      },
      formProps: {
        onSaveAnswer: saveAnswerHandler,
        isDisabled: setSubmitDisabled,
        data: {
          id: PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS,
          answerLabel: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS],
          inputPlaceholder: COMPLETED_HEALTH_QUESTION_PLACEHOLDER[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS],
          pleaseListText: HEALTH_QUESTION_INSTRUCTIONS[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS],
          answer: patientHealthSurvey?.medicationsAndSupplements,
          answerOptions: [
            { label: 'Yes', value: HealthHistoryOptions.Yes },
            { label: 'No', value: HealthHistoryOptions.No },
          ],
          answerDetails: patientHealthSurvey?.medicationsAndSupplementsDetails ?? [],
          showFormWhenOptionSelected: [HealthHistoryOptions.Yes],
        },
      },
    },
  ]

  const pregnancyQuestion = [
    {
      body: AccordionQuestion,
      data: {
        id: PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY,
        name: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY],
        nameOnEdit: PATIENT_HEALTH_QUESTION[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY],
        mode: isFirstTimeVisit ? FormMode.Edit : FormMode.View,
        isOpen: false,
      },
      formProps: {
        onSaveAnswer: saveAnswerHandler,
        isDisabled: setSubmitDisabled,
        data: {
          id: PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY,
          answerLabel: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY],
          inputPlaceholder: COMPLETED_HEALTH_QUESTION_PLACEHOLDER[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY],
          pleaseListText: HEALTH_QUESTION_INSTRUCTIONS[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY],
          answer: patientHealthSurvey?.pregnantOrLactating,
          answerOptions: [
            {
              label: 'Yes',
              value: HealthHistoryOptions.Yes,
              desc: '',
            },
            {
              label: 'No',
              value: HealthHistoryOptions.No,
              desc: null,
            },
            {
              label: 'Not sure',
              value: HealthHistoryOptions.NotSure,
              desc: '',
            },
          ],
          answerDetails: [],
          showFormWhenOptionSelected: [],
        },
      },
    },
  ]

  const pregnancyStatusQuestion = [
    {
      body: PregnancyStatusQuestion,
      data: {
        id: PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS,
        name: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS],
        nameOnEdit: PATIENT_HEALTH_QUESTION[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS],
        mode: isFirstTimeVisit ? FormMode.Edit : FormMode.View,
        isOpen: false,
        subheader: 'Select all that apply',
      },
      formProps: {
        onSaveAnswer: savePregnancyAnswerHandler,
        isDisabled: setSubmitDisabled,
        data: {
          id: PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS,
          answerLabel: COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS],
          inputPlaceholder: COMPLETED_HEALTH_QUESTION_PLACEHOLDER[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS],
          pleaseListText: HEALTH_QUESTION_INSTRUCTIONS[PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS],
          answer: patientHealthSurvey?.pregnancyStatus,
          answerOptions: [
            {
              label: 'I am pregnant',
              value: PregnancyStatus.Pregnant,
              desc: '',
            },
            {
              label: 'I am lactating',
              value: PregnancyStatus.Lactating,
              desc: null,
            },
            {
              label: 'I am planning to get pregnant in the next 90 days',
              value: PregnancyStatus.Planning,
              desc: null,
            },
            {
              label: 'None of the above',
              value: PregnancyStatus.None,
              desc: null,
            },
          ],
          answerDetails: [],
          showFormWhenOptionSelected: [],
        },
      },
    },
  ]

  const pregnancySection = separateUracSurveyPregnancyFieldFF ? pregnancyStatusQuestion : pregnancyQuestion

  const multiStepSurvey: MultiStepData[] = [...baseSurvey, ...(patientProfile?.showPregnancyQuestion ? pregnancySection : [])]

  const [healthQuestions, setHealthQuestions] = useState<AccordionStepForm[]>(multiStepSurvey)

  useEffect(() => {
    setHealthQuestions(multiStepSurvey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientHealthSurvey])

  const submitHandler = () => {
    trackButtonClickEvent('checkout', 'Proceed to checkout')
    const initialUracData = {}

    const uracData = healthQuestions.reduce((obj, question) => {
      const id = question.data.id

      const simpleAnswerQuestions = [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY, PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS]
      if (simpleAnswerQuestions.includes(id)) {
        return {
          ...obj,
          [question.data.id]: question.formProps?.data.answer,
        }
      }

      return {
        ...obj,
        [question.data.id]: question.formProps?.data.answer,
        [`${question.data.id}Details`]: question.formProps?.data.answerDetails,
      }
    }, initialUracData)

    if (isFirstTimeVisit) trackButtonClickEvent('urac proceed to checkout', 'Proceed to Checkout', 'urac_first_time_visit_proceed_to_checkout')

    surveyService
      .createPatientSurvey(
        parseUracData(uracData as PostHealthHistoryDto, separateUracSurveyPregnancyFieldFF),
        customerProfile.vpharmCustomerToken,
        selectedPatientToken,
      )
      .then((data) => {
        if (data) {
          setHealthSurvey((prevState: CheckoutFlowData) => ({
            ...prevState,
            patientHealthSurvey: uracData as PostHealthHistoryDto,
          }))
          history.push('/prescription-management/checkout')
        } else {
          showError('urac_proceed_to_checkout_button_click')
        }
      })
      .catch(() => {
        showError('urac_proceed_to_checkout_button_click')
      })
  }

  const isValid = useMemo(() => !healthQuestions.find((q) => q.formProps?.data.answer === undefined), [healthQuestions])

  const someFormsInProgress = healthQuestions.some((form) => form.data.isOpen === true)

  if (isLoading) {
    return <LoadingAnimation size='sm' />
  }

  return (
    <CheckoutPage
      header={isFirstTimeVisit ? 'Complete your health profile' : 'Has your health changed since your last refill?'}
      instructions='We use this information to make sure that this medication is safe for you to take.'
    >
      <SEO title={`${pharmCustomer?.displayName} - URAC`} useDefaults />
      <div>
        <div>
          <MultiStepAccordionForm forms={healthQuestions} setForms={setHealthQuestions} />
        </div>
        <StyledSubmitButtonContainer>
          <ThemedButton onClick={submitHandler} disabled={!isValid || submitDisabled || someFormsInProgress} vpTheme={theme}>
            Proceed to checkout
          </ThemedButton>
        </StyledSubmitButtonContainer>
      </div>
    </CheckoutPage>
  )
}

export default PatientHealthSurvey
