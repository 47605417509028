import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const EligibilityContainer = styled.div<ThemedComponent>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  cursor: pointer;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
  border-bottom: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-radius: 0.5rem 0.5rem 0 0;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  > svg {
    width: 1rem;
    height: 1rem;
  }
`

export const StyledModal = {
  ActionContainer,
}

export const StyledAutoRefillEligibilityStatus = {
  EligibilityContainer,
  StatusContainer,
}
