import { GridItem, Header, theme } from '@truepill/react-capsule'
import { ArrowRight } from 'react-feather'
import styled from 'styled-components/macro'

export interface OutlinedCardProps {
  title?: string
  subtitle?: string
  bodyTextOne?: string
  bodyTextTwo?: string
  buttonText?: string
  buttonLink?: string
}

const OutlinedCard = ({ title, subtitle, bodyTextOne, bodyTextTwo, buttonText, buttonLink }: OutlinedCardProps): React.ReactElement => {
  return (
    <Container mobile={4} desktop={6}>
      {title && <Header variant='4xl'>{title}</Header>}
      {subtitle && <Subtitle variant='xl'>{subtitle}</Subtitle>}
      {bodyTextOne && (
        <BodyTextOne variant='xl' bold>
          {bodyTextOne}
        </BodyTextOne>
      )}
      {bodyTextTwo && (
        <BodyTextTwo variant='xl' bold>
          {bodyTextTwo}
        </BodyTextTwo>
      )}
      {!!(buttonLink && buttonLink) && (
        <Link href={buttonLink} target='_blank' rel='noreferrer'>
          <Action variant='xl' bold>
            {buttonText}
            <ArrowRight />
          </Action>
        </Link>
      )}
    </Container>
  )
}

const Container = styled(GridItem)`
  border: 1px solid ${theme.colors['gray-500'].computedValue};
  border-radius: 1rem;
  padding: 2rem 1rem;
`

const Subtitle = styled(Header)`
  margin-top: 1.5rem;
  color: ${theme.colors['typography-medium'].computedValue};
  -webkit-text-fill-color: ${theme.colors['typography-medium'].computedValue};
  font-weight: 400;
`

const BodyTextOne = styled(Header)`
  margin-top: 1.5rem;
`

const BodyTextTwo = styled(Header)`
  margin-top: 0.25rem;
  color: ${theme.colors['typography-medium'].computedValue};
  -webkit-text-fill-color: ${theme.colors['typography-medium'].computedValue};
`

const Link = styled.a`
  text-decoration: none;
`

const Action = styled(Header)`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  color: ${theme.colors['functional-info-dark'].computedValue};
  -webkit-text-fill-color: ${theme.colors['functional-info-dark'].computedValue};
  cursor: pointer;
`

export default OutlinedCard
