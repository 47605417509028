import { Spacer, Toggle } from '@truepill/react-capsule'
import { Simulation } from '@vpharm-platform/shared'
import React, { Fragment, PropsWithChildren, ReactElement, useEffect, useState } from 'react'

export interface IOrderSimulation {
  orderSimulationChecked: boolean
  orderErrorChecked: boolean
  simulation?: Simulation
}

export const INITIAL_SIMULATION: IOrderSimulation = {
  orderSimulationChecked: false,
  orderErrorChecked: false,
  simulation: {},
}

interface UseOrderSimulation {
  simulation: IOrderSimulation
  setOrderSimulationChecked: (orderSimulationChecked: boolean) => void
  setOrderErrorChecked: (orderErrorChecked: boolean) => void
}

export const useOrderSimulation = (): UseOrderSimulation => {
  const [orderSimulationChecked, setOrderSimulationChecked] = useState(false)
  const [orderErrorChecked, setOrderErrorChecked] = useState(false)
  const [simulation, setSimulation] = useState(INITIAL_SIMULATION)

  useEffect(() => {
    setSimulation({
      orderSimulationChecked,
      orderErrorChecked,
      simulation: {
        ...(orderErrorChecked && { order: { error: 'R051' } }),
      },
    })
  }, [orderSimulationChecked, orderErrorChecked])

  return { simulation, setOrderSimulationChecked, setOrderErrorChecked }
}

export const OrderSimulation = (simulationProps: PropsWithChildren<UseOrderSimulation>): ReactElement => {
  const { simulation: simulationInfo, setOrderSimulationChecked, setOrderErrorChecked } = simulationProps

  return (
    <Fragment>
      <Toggle label='Enable Order Simulation' withText={true} checked={simulationInfo.orderSimulationChecked} onChange={setOrderSimulationChecked} />
      {simulationInfo.orderSimulationChecked && (
        <>
          <Spacer size='xs' />
          <Toggle label='Simulate Order Error' withText={true} checked={simulationInfo.orderErrorChecked} onChange={setOrderErrorChecked} />
        </>
      )}
    </Fragment>
  )
}
