/**
 * Interface representing a feature flag switch reader.
 */
export interface FFSwitchReader {
  /**
   * The default value of the feature flag (when the hook did not retrieve a value).
   */
  readonly defaultValue: boolean

  /**
   * Returns a hook to access the feature flag.
   * @return {() => boolean} Hook to get the feature flag.
   */
  getFFReaderHook: () => () => boolean
}

/**
 * A higher-order component that conditionally renders one of two components based on a feature flag value.
 *
 * @template T - The props type for the components (auto inferred from args).
 * @param {React.FC<T>} WhenTrue - The component to render when the feature flag is true.
 * @param {React.FC<T>} WhenFalse - The component to render when the feature flag is false.
 * @param {FFSwitchReader} reader - An instance that provides a hook to read the feature flag.
 * @returns {React.FC<T & FFSwitchProps>} - A component that renders either `WhenTrue` or `WhenFalse` based on the feature flag value.
 */
export function FFSwitch<T>(WhenTrue: React.FC<T>, WhenFalse: React.FC<T>, reader: FFSwitchReader): React.FC<T> {
  const useGetFFValue = reader.getFFReaderHook()
  const FFComponent: React.FC<T> = (props) => {
    const ffValue = useGetFFValue() ?? reader.defaultValue
    return ffValue ? <WhenTrue {...props} /> : <WhenFalse {...props} />
  }
  return FFComponent
}
