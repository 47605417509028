import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import React from 'react'

export const HamburgerIcon = ({ vpTheme }: { vpTheme?: VpTheme }): React.ReactElement => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 12H14M2 8H14M2 4H14'
      stroke={vpTheme?.colors.navAvatarBg ?? defaultTheme?.colors.navAvatarBg}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
