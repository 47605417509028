import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const CheckIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width={20} height={20}>
      <circle cx={10} cy={10} r={10} fill={vpTheme.colors['functional-success-dark'] ?? defaultTheme.colors['functional-success-dark']} />
      <path
        d='M14 7.25l-5.5 5.5-2.5-2.5'
        fill='none'
        stroke={vpTheme.colors.grayWhite}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
