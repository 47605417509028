import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

export const IconCoupon = ({ color }: { color?: string }): React.ReactElement => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 4.5V10.5858C3 10.851 3.10536 11.1054 3.29289 11.2929L13.4393 21.4393C14.0251 22.0251 14.9749 22.0251 15.5607 21.4393L21.4393 15.5607C22.0251 14.9749 22.0251 14.0251 21.4393 13.4393L11.2929 3.29289C11.1054 3.10536 10.851 3 10.5858 3H4.5C3.67157 3 3 3.67157 3 4.5ZM7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9Z'
        fill={color || defaultTheme.colors['primary-700']}
      />
    </svg>
  )
}
