import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'

const MainContainer = styled.div`
  width: 100%;
`

const HeaderLinks = styled.div`
  display: flex;
  padding: 1rem 1.5rem 0rem;
  font-size: 2rem;
  font-weight: 500;
  width: 100%;

  button {
    padding: 2rem 1rem 0.5rem;
  }

  ${mediaLargerThan.tablet} {
    button {
      padding: 1rem 1rem 1.5rem;
    }
    padding: 1rem 8rem 0rem;
  }
`

const HeaderLink = styled(ThemedButton)<{ selected?: boolean }>`
  color: ${({ vpTheme }) => vpTheme.colors.buttonSecondary ?? defaultTheme.colors.buttonSecondary};
  ${({ selected, vpTheme }) =>
    selected &&
    `border-bottom: 2px solid ${vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']}; color: ${
      vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']
    };`}
  border-radius: 0;
  font-size: 1.25rem;

  ${mediaLargerThan.tablet} {
    display: flex;
    margin-right: 1.5rem;
    align-items: center;
    padding-bottom: 1rem;
    font-size: 1.75rem;

    color: ${({ vpTheme }) => vpTheme.colors.buttonSecondary ?? defaultTheme.colors};
    ${({ selected, vpTheme }) =>
      selected &&
      `border-bottom: 2px solid ${vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']}; color: ${
        vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']
      };`}
    border-radius: .15rem;
  }
`

const StyledHeader = styled(ThemedHeader)<ThemedComponent>`
  font-size: 2.75rem;
  line-height: 2.875rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  display: flex;
  justify-content: center;
  padding: 2.5rem 1.5rem 0;

  ${mediaLargerThan.tablet} {
    padding: 11rem 8rem 0;
    font-size: 3rem;
    line-height: 3.75rem;
  }
`

const StyledSubHeader = styled.div<ThemedComponent>`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  font-weight: 400;
  display: flex;
  justify-content: center;
  padding: 1.5rem 1.5rem 0;

  ${mediaLargerThan.tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-top: 1rem;
    padding-left: 18rem;
    padding-right: 18rem;
    padding-bottom: 5rem;
    text-align: center;
    font-weight: 300;
  }
`

const ContactSection = styled.div`
  // TODO: Theme color doesnt exist
  background-image: linear-gradient(to right, #00255f, #4f12d1);
  height: 14rem;
  border-radius: 0;
  padding: 1.5rem 2rem 2rem;
  margin: 0;
  align-self: center;
  align-content: center;
  justify-content: space-between;

  ${mediaLargerThan.tablet} {
    display: grid;
    grid-template-columns: 3fr 2fr;
    margin: 5.75rem 4.75rem 11rem;
    border-radius: 1rem;
    padding: 1.5rem 2rem 2rem;
    height: 9rem;
  }
`

const ContactText = styled.div<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors.grayWhite ?? defaultTheme.colors.grayWhite};
  font-size: 1.25rem;
  font-weight: 700;
  margin-right: 2rem;
  margin-left: 2.75rem;
  align-self: center;
  padding-bottom: 1.5rem;
  text-align: center;

  ${mediaLargerThan.tablet} {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    padding-bottom: 0;
  }
`

const ContactButtonContainer = styled.div<ThemedComponent>`
  align-self: center;
  text-align: center;

  a {
    width: 100%;
  }

  button {
    width: 10rem;
    background-color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']};
    border: none;
  }

  ${mediaLargerThan.tablet} {
    display: flex;

    padding-right: 0rem;
    justify-content: right;
    padding-right: 1.5rem;
    justify-self: right;
  }
`

const VideosGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  padding: 4rem 0rem 3rem;

  button {
    width: 18rem;
    height: 10rem;
  }

  ${mediaLargerThan.tablet} {
    button {
      width: 26rem;
      height: 15rem;
    }
  }

  ${mediaLargerThan.desktopSm} {
    grid-template-columns: 1fr 1fr;
    row-gap: 3.5rem;

    button {
      width: 22rem;
      height: 14rem;
    }
  }
  ${mediaLargerThan.desktopLg} {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 3.5rem;
  }
`
const TutorialAccordion = styled(Accordion)<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  // TODO: Theme color doesnt exist
  border-image: linear-gradient(to right, #00255f, #4f12d1) 100;
  border-bottom: 1px solid !important;
  ${mediaLargerThan.tablet} {
  }
`

const StyledTutorialAccordion = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  padding: 2rem 2rem 5rem;
  ${mediaLargerThan.tablet} {
    padding: 3.5rem 8rem 5rem;
  }
`

const TutorialAccordionItem = styled(AccordionItem)`
  h3 {
    // TODO: Theme color doesnt exist
    border-image: linear-gradient(to right, #00255f, #4f12d1) 100;
    border-top: 1px solid;
  }
`

const TutorialAccordionTrigger = styled(AccordionTrigger)<ThemedComponent>`
  padding: 2rem 1.5rem 2rem 0;
  font-size: 1.25rem;
  font-weight: 700;

  &:active {
    background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']} !important;
  }

  ${mediaLargerThan.tablet} {
    font-size: 1.75rem;
    font-weight: 600;
  }
`

const TutorialAccordionContent = styled(AccordionContent)`
  padding: 0rem 0rem 2rem;
`

export {
  ContactButtonContainer,
  ContactSection,
  ContactText,
  HeaderLink,
  HeaderLinks,
  MainContainer,
  StyledHeader,
  StyledSubHeader,
  StyledTutorialAccordion,
  TutorialAccordion,
  TutorialAccordionContent,
  TutorialAccordionItem,
  TutorialAccordionTrigger,
  VideosGrid,
}
