import { Document } from '@contentful/rich-text-types'
import { useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { IErrorMessages, IErrorMessagesFields, IResource, IResourceFields, IResourceSet } from '../../types/generated/contentful'
import { MicroCopyResourceType } from './types/microcopy'

interface ReturnValueMicroCopy<T> {
  isError: boolean
  isLoadingContent: boolean
  microcopy: T | undefined
}

interface ReturnValueMicrocopies<T> {
  isError: boolean
  isLoadingContent: boolean
  microcopies: T | undefined
}

function isIResource(obj: IResourceFields | IErrorMessagesFields) {
  return 'key' in obj && ('value' in obj || 'richValue' in obj || 'referenceValue' in obj)
}

const mapReducer = (microFields: Record<string, string | Document | IResource[] | undefined>, currentResource: IResource | IErrorMessages) => {
  if (isIResource(currentResource.fields)) {
    const fields = currentResource.fields as IResourceFields
    if (fields.referenceValue) {
      microFields[fields.key] = mapReferenceValuesToFields(fields.referenceValue)
      return microFields
    } else if (fields.richValue) {
      microFields[fields.key] = fields.richValue
      return microFields
    } else {
      microFields[fields.key] = fields.value
    }
  }

  return microFields
}

export const mapReferenceValuesToFields = (referenceValues: IResource[]): any => {
  return referenceValues.reduce(mapReducer, {})
}

const mapResourceSetToFields = (resourceSet: IResourceSet | undefined): any => {
  if (!resourceSet) {
    return
  }

  return resourceSet.fields.resources?.reduce(mapReducer, {})
}

export const useContentfulMicrocopy = <T>(type: MicroCopyResourceType): ReturnValueMicroCopy<T> => {
  const [microcopy, setMicrocopy] = useState<T | undefined>()
  const { pharmCustomer, error, loading: isLoadingContent } = useCustomerConfigContext()

  useEffect(() => {
    if (pharmCustomer) {
      const targetMicrocopy = pharmCustomer.microcopy?.find((resourceSet) => resourceSet.fields.type === type)
      setMicrocopy(mapResourceSetToFields(targetMicrocopy) as T)
    }
  }, [pharmCustomer, type])

  return {
    isLoadingContent,
    microcopy,
    isError: error,
  }
}

export const useContentfulMicrocopyByNameAndType = <T>(type: MicroCopyResourceType, name: string): ReturnValueMicroCopy<T> => {
  const [microcopy, setMicrocopy] = useState<T | undefined>()
  const { pharmCustomer, error, loading: isLoadingContent } = useCustomerConfigContext()

  useEffect(() => {
    if (pharmCustomer) {
      const targetMicrocopy = pharmCustomer.microcopy?.find((resourceSet) => resourceSet.fields.type === type && resourceSet.fields.name === name)
      setMicrocopy(mapResourceSetToFields(targetMicrocopy) as T)
    }
  }, [pharmCustomer, type, name])

  return {
    isLoadingContent,
    microcopy,
    isError: error,
  }
}

export const useContentfulMicrocopies = <T>(types: MicroCopyResourceType[]): ReturnValueMicrocopies<T> => {
  const [microcopies, setMicrocopies] = useState<T | undefined>()
  const { pharmCustomer, error, loading: isLoadingContent } = useCustomerConfigContext()

  useEffect(() => {
    if (pharmCustomer) {
      const microcopies = types.map((type) => {
        const targetMicrocopy = pharmCustomer.microcopy?.find((resourceSet) => resourceSet.fields.type === type)
        return mapResourceSetToFields(targetMicrocopy)
      }) as unknown

      setMicrocopies(microcopies as T)
    }
  }, [pharmCustomer, types])

  return {
    isLoadingContent,
    microcopies,
    isError: error,
  }
}
