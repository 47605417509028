import { Spacer } from '@truepill/react-capsule'
import { CartIcon } from 'assets/Icons/CartIcon'
import { ProfileButton } from 'Components/Auth'
import { CART_PATH, PRESCRIPTION_MANAGEMENT_PATH } from 'constants/navigation-links'
import { ReactElement } from 'react'

import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import AccountNavigation from './AccountNavigation'
import DropdownNavigation from './DropdownNavigation'
import { StyledLink, StyledNavItem } from './styledComponents'

interface SignedInNavLinksProps {
  numberOfCartItems: number
  onCartClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
  background: string
  itemColor: string
}

export const SignedInNavLinks = ({ numberOfCartItems, onCartClick, background, itemColor }: SignedInNavLinksProps): ReactElement => {
  const { trackLinkClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const PrescriptionManagementNavItem = () => (
    <StyledNavItem>
      <StyledLink
        onClick={() => trackLinkClickEvent('Prescription Manager', PRESCRIPTION_MANAGEMENT_PATH, LinkOrigin.Header)}
        to={PRESCRIPTION_MANAGEMENT_PATH}
        data-testid='prescription-manager-nav-url'
      >
        Prescription Manager
      </StyledLink>
    </StyledNavItem>
  )

  const OrdersNavItem = () => (
    <StyledNavItem>
      <StyledLink onClick={() => trackLinkClickEvent('Orders', '/orders', LinkOrigin.Header)} to={'/orders'} data-testid='orders-nav-url'>
        Orders
      </StyledLink>
    </StyledNavItem>
  )

  return (
    <>
      <PrescriptionManagementNavItem />
      <OrdersNavItem />
      <StyledLink
        id='header-cart-link'
        aria-label={`Cart has ${numberOfCartItems} ${numberOfCartItems === 1 ? 'item' : 'items'}`}
        onClick={onCartClick}
        to={CART_PATH}
        data-testid='cart-nav-url'
      >
        <span>Cart&nbsp;{`(${numberOfCartItems})`}</span>
        <Spacer size='xs' />
        <CartIcon vpTheme={theme} />
      </StyledLink>
      <StyledNavItem>
        <Spacer size='2xl' />
        <DropdownNavigation Button={ProfileButton} NavComponent={AccountNavigation} background={background} itemColor={itemColor} />
      </StyledNavItem>
    </>
  )
}
