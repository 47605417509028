import { Header, Spacer, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { StyledAutoRefillDisenrollModal } from './styledComponents'

export interface AutoRefillDisenrollModalProps {
  isOpen: boolean
  onDismiss: () => void
  disenrollAutoRefill: () => Promise<void>
  isSubmitting: boolean
  theme: VpTheme
}

const AutoRefillDisenrollModal = ({
  isOpen,
  isSubmitting,
  theme,
  onDismiss,
  disenrollAutoRefill,
}: AutoRefillDisenrollModalProps): React.ReactElement => {
  return (
    <StyledAutoRefillDisenrollModal.DisenrollModal isOpen={isOpen} onDismiss={onDismiss}>
      <Header variant='2xl' css={{ marginRight: '2rem' }}>
        Are you sure?
      </Header>
      <Spacer size='md' />
      <Text>
        By disabling automatic refills, your medication will no longer be automatically refilled and shipped to you. You will need to manually refill
        your prescription each time.
      </Text>
      <Spacer size='md' />
      <Text>If you still wish to proceed, click below</Text>
      <Spacer size='xl' />
      <StyledAutoRefillDisenrollModal.ButtonContainer>
        <ThemedButton role='button' disabled={isSubmitting} onClick={disenrollAutoRefill} vpTheme={theme}>
          Disable auto refill
        </ThemedButton>
      </StyledAutoRefillDisenrollModal.ButtonContainer>
    </StyledAutoRefillDisenrollModal.DisenrollModal>
  )
}

export default AutoRefillDisenrollModal
