import { Text } from '@truepill/react-capsule'
import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

const MainContainer = styled.div`
  width: 100%;
  max-width: 520px;
  height: calc(100vh - 4.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  input[type='password'] {
    -webkit-appearance: none;
    appearance: none;
  }
`

const Form = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

const SignInButtonContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`

const MainHeader = styled(ThemedHeader)<ThemedComponent>`
  margin: 0 0 0.75rem 0;
  color: ${({ vpTheme }) => vpTheme?.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 32px;

  ${mediaLargerThan.tablet} {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Subtext = styled(Text)<{ vpTheme?: VpTheme }>`
  color: ${({ vpTheme }) => vpTheme?.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  margin: 1rem 0;
  font-weight: 400;
`

const ErrorContainer = styled.div<{ vpTheme?: VpTheme }>`
  color: ${({ vpTheme }) => vpTheme?.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};
  background-color: ${({ vpTheme }) => vpTheme?.colors['functional-error-light'] ?? defaultTheme.colors['functional-error-light']};
  // TODO: Theme color does not exist - slightly darker version of functional-error-light
  border: 1px solid #ebccd1;
  padding: 1rem;
`

const LinkStyles = (vpTheme?: VpTheme): any => ({
  color: `${vpTheme?.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']}`,
  textDecoration: 'underline',
  FontWeight: 'bold',
})

export { ErrorContainer, Form, LinkStyles, MainContainer, MainHeader, SignInButtonContainer, Subtext }
