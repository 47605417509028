import { useStripe } from '@stripe/react-stripe-js'
import { Checkbox, Spacer } from '@truepill/react-capsule'
import {
  BaseOrderParams,
  MixedOrderParams,
  OrderParams,
  PrescriptionCopayStatusType,
  ShippingAddressParams,
  SubmitOrderSuccessResponse,
} from '@vpharm-platform/shared'
import { useCustomerProfile, usePatientProfile } from 'hooks'
import { mixedCartAtom, OrderMode, selectedPatientTokenAtom } from 'persistRecoil'
import React, { FormEvent, Fragment, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { governmentIDImage } from 'recoil/atoms'
import { OrderPaymentBadRequestError, orderService, OrderSubmitBadRequest } from 'services'
import { v4 as uuidv4 } from 'uuid'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { VP_5756_DISPLAY_APPLE_PAY } from '../../../constants'
import { useAnalytics } from '../../../hooks/analytics-context'
import { CheckoutPageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../../hooks/contentful/useGetPageContent'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import AccordionForm from '../../Body/AccordionForm'
import { useCheckoutContext } from '../CheckoutProvider'
import ApplePay from '../Payment/ApplyPay'
import { OrderSimulation, useOrderSimulation } from './OrderSimulation'
import { AcceptTermsContainer, PatientConsentContainer, SubmitButtonContainer } from './styledComponents'

export interface Props {
  onSubmitOrder: (orderTokens: string[]) => void
  onShowErrorMessage: (message?: string) => void
  setIsSubmittingOrder: (value: boolean) => void
}

const CheckoutOrderReview: React.FC<Props> = ({ onSubmitOrder, onShowErrorMessage, setIsSubmittingOrder }) => {
  const stripe = useStripe()
  const { trackButtonClickEvent, trackErrorShownEvent, trackOrderPlacedEvent, trackPrescriptionCheckedOutEvent } = useAnalytics()
  const [hasConsented, setHasConsented] = useState(false)
  const simulationInfo = useOrderSimulation()
  const { customerProfile } = useCustomerProfile()
  const { patientProfile } = usePatientProfile()
  const { content, theme } = useGetPageContent<CheckoutPageMicrocopy>('checkoutPage')
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const image = useRecoilValue(governmentIDImage)
  const isMixedCartEnabled = useRecoilValue(mixedCartAtom)
  const { checkoutStep, cartItems, selectedAddress, selectedPayment, orderMode, priceDetails, selectedShippingMethod, setSelectedShippingMethod } =
    useCheckoutContext()
  const { [VP_5756_DISPLAY_APPLE_PAY]: displayApplePay } = useLDFlagsWithLocalStorage([VP_5756_DISPLAY_APPLE_PAY])

  const shouldShowSimulation = useMemo(() => process.env.REACT_APP_VPP_ENV && process.env.REACT_APP_VPP_ENV !== 'production', [])

  const preparePayload = (): BaseOrderParams => {
    if (!selectedAddress) {
      throw new Error('Missing shipping address')
    }

    if (!selectedShippingMethod) {
      throw new Error('Missing shipping method')
    }

    if (!selectedPayment && priceDetails.orderTotal !== 0) {
      throw new Error('Missing payment method')
    }

    const shippingAddress: ShippingAddressParams = {
      existing_address_id: selectedAddress.id ?? undefined,
      name: selectedAddress.name,
      address1: selectedAddress.address1,
      address2: selectedAddress.address2 ?? undefined,
      zip: selectedAddress.zip,
      state: selectedAddress.state,
      city: selectedAddress.city,
    }

    let idempotencyKey = localStorage.getItem('idempotencyKey')
    if (!idempotencyKey) {
      localStorage.setItem('idempotencyKey', uuidv4())
      idempotencyKey = localStorage.getItem('idempotencyKey')
    }

    if (idempotencyKey === null) {
      throw new Error('Missing itempotencyKey')
    }

    const data: BaseOrderParams = {
      shipping: {
        shipping_state_customer_id: selectedShippingMethod.id,
        shipping_address: shippingAddress,
      },
      ...(priceDetails.orderTotal > 0 && {
        payment: {
          payment_method_id: selectedPayment?.paymentMethodId,
        },
      }),
      contact: {
        email: patientProfile?.email || '',
        phone_number: patientProfile?.phone || '',
      },
      idempotencyKey: idempotencyKey,
    }

    if (image && image !== '') {
      data.patient = { patient_government_id: image }
    }

    if (shouldShowSimulation && simulationInfo.simulation.orderSimulationChecked) {
      data.simulation = simulationInfo.simulation.simulation
    }

    return data
  }

  const submitOrder = async (data: OrderParams) => {
    let response = await orderService.submitOrder(customerProfile.vpharmCustomerToken, selectedPatientToken, data)

    while (!('orderToken' in response)) {
      if (!stripe) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Stripe not defined')
      }
      const actionResult = await stripe.handleCardAction(response.clientSecret)
      response = await orderService.submitOrder(customerProfile.vpharmCustomerToken, selectedPatientToken, {
        ...data,
        payment: {
          ...data.payment,
          payment_intent_id: actionResult.paymentIntent?.id,
        },
      })
    }

    return [response]
  }

  const submitMixedOrder = async (data: MixedOrderParams) => {
    let response = await orderService.submitMixedOrder(customerProfile.vpharmCustomerToken, selectedPatientToken, data)
    let keepRetrying = response.some((res) => !('orderToken' in res))

    while (keepRetrying) {
      const paymentIntents: { cash: string | undefined; insurance: string | undefined } = { cash: undefined, insurance: undefined }
      for (const res of response) {
        if (!('orderToken' in res)) {
          if (!stripe) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Stripe not defined')
          }
          if (res.data.requiresAction) {
            const actionResult = await stripe.handleCardAction(res.data.clientSecret)
            paymentIntents[res.type] = actionResult.paymentIntent?.id
          } else {
            paymentIntents[res.type] = res.data.paymentIntentId
          }
        }
      }
      response = await orderService.submitMixedOrder(customerProfile.vpharmCustomerToken, selectedPatientToken, {
        ...data,
        payment: {
          ...data.payment,
          cash_payment_intent_id: paymentIntents.cash,
          insurance_payment_intent_id: paymentIntents.insurance,
        },
      })
      keepRetrying = response.some((res) => !('orderToken' in res))
    }

    return response as SubmitOrderSuccessResponse[]
  }

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    window.scroll(0, 0)
    setIsSubmittingOrder(true)
    e.preventDefault()
    trackButtonClickEvent('confirm_order', 'Confirm and pay', 'place order')

    const data = preparePayload()
    let res: SubmitOrderSuccessResponse[]

    try {
      if (isMixedCartEnabled) {
        const prescriptions = cartItems.medications
          .filter((m) => !!m.prescriptionToken && m.prescriptionToken.trim() !== '')
          .map((m) => ({
            token: m.prescriptionToken,
            selectedPriceOption: m.selectedPriceOption as 'cash' | 'insurance',
          }))
        const payload: MixedOrderParams = { ...data, prescriptions }
        res = await submitMixedOrder(payload)
      } else {
        const prescriptionTokens = cartItems.medications.map((m) => m.prescriptionToken).filter((token) => !!token && token.trim() !== '')
        const payload: OrderParams = {
          ...data,
          prescriptions: prescriptionTokens,
          orderType: orderMode === OrderMode.INSURANCE ? 'insurance' : 'cash',
        }
        res = await submitOrder(payload)
      }

      const orderTokens = res.map((r) => r.orderToken).filter((token) => !!token && token.trim() !== '')
      if (orderTokens.length === 0) {
        throw new Error('There was an issue. Please refresh your browser and try checking out again.')
      }

      setSelectedShippingMethod(null)
      onSubmitOrder(orderTokens)

      let copayAmounts = 0
      cartItems.medications.forEach((med) => {
        copayAmounts += med.copay?.type === PrescriptionCopayStatusType.SUCCESS ? med.copay?.amount : 0

        trackPrescriptionCheckedOutEvent({
          copayAmount: (med.copay?.type === PrescriptionCopayStatusType.SUCCESS && med.copay?.amount) || 0,
          medicationName: med.brandDisplayName,
          prescriptionToken: med.prescriptionToken,
          rxNumber: med.rxNumber,
          strength: med.medicationStrength,
          ndc: med.prescriptionNdc || '',
          totalAmount: (med.copay?.type === PrescriptionCopayStatusType.SUCCESS && med.copay?.amount) || med.totalPriceAfterSavings || 0,
          selectedPriceOption: med.selectedPriceOption,
        })
      })

      const someMedWithCash = cartItems.medications.some((item) => item.selectedPriceOption === 'cash')
      const someMedWithInsurance = cartItems.medications.some((item) => item.selectedPriceOption === 'insurance')
      const isMixedCartFlow =
        (someMedWithCash && someMedWithInsurance) ||
        (someMedWithCash && orderMode === OrderMode.INSURANCE) ||
        (someMedWithInsurance && orderMode === OrderMode.CASH)

      trackOrderPlacedEvent({
        numberOfMedications: cartItems.medications.length,
        copayAmount: copayAmounts,
        shippingAmount: selectedShippingMethod?.shipping_price,
        shippingMethod: selectedShippingMethod?.shipping_name,
        shippingState: data.shipping.shipping_address.state,
        taxAmount: priceDetails.estimatedTax,
        totalAmount: priceDetails.orderTotal,
        isMixedCart: isMixedCartFlow,
      })
    } catch (err) {
      let message
      if (err instanceof OrderPaymentBadRequestError) {
        localStorage.setItem('idempotencyKey', uuidv4())
        message = err.message
      } else if (err instanceof OrderSubmitBadRequest) {
        message = err.message
        if (message.indexOf('Cash order for patient with insurance') > -1) {
          message = 'There was an issue. Please refresh your browser and try checking out again.'
        }
      } else {
        if (err instanceof Error) {
          message = err.message
        }
      }

      trackErrorShownEvent(message || 'no message', 'checkout_submit_button_click')
      onShowErrorMessage(message)
      setIsSubmittingOrder(false)
    }
  }

  const isOpen = checkoutStep === 'review'
  const isSubmitDisabled = !hasConsented || !selectedAddress || !selectedShippingMethod || (!selectedPayment && priceDetails.orderTotal !== 0)

  return (
    <AccordionForm id={'ORDER_REVIEW'} header={'Confirm'} isSubmitted={false} isOpen={isOpen}>
      <form onSubmit={submitHandler}>
        <>
          <AcceptTermsContainer>
            <PatientConsentContainer>
              <Checkbox
                checked={hasConsented}
                onCheckedChange={() => setHasConsented(!hasConsented)}
                css={{ marginRight: '8px' }}
                data-testid='order-review__agree-terms__checkbox'
                label={
                  content?.patientConsentCheckboxText ??
                  'I authorize Truepill to bill my insurance and I agree to pay the applicable copay amount. I authorize Truepill to dispense and ship my prescriptions.'
                }
              />
            </PatientConsentContainer>
          </AcceptTermsContainer>
          <Spacer size='lg' />
          {shouldShowSimulation && (
            <Fragment>
              <OrderSimulation {...simulationInfo} />
              <Spacer size='lg' />
            </Fragment>
          )}
          {displayApplePay ? (
            <ApplePay customerToken={customerProfile.vpharmCustomerToken} patientToken={selectedPatientToken}></ApplePay>
          ) : (
            <SubmitButtonContainer>
              <ThemedButton type='submit' disabled={isSubmitDisabled} vpTheme={theme}>
                {content?.placeOrderButtonText || 'Confirm and pay'}
              </ThemedButton>
            </SubmitButtonContainer>
          )}
        </>
      </form>
    </AccordionForm>
  )
}

export default CheckoutOrderReview
