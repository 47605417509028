// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Select } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from './types'

const ThemedSelect = styled(Select)<ThemedComponent & { [key: string]: any }>`
  button {
    ${({ vpTheme, state = '' }) => `
      --cap--select-field-outline-focus: 3px solid ${selectBorderColor(vpTheme, state)};
        ${selectBackgroundColor(vpTheme, state)}
        border: 1px solid ${selectBorderColor(vpTheme, state)};
        &:focus, &:focus-visible, &:active {
          border: 3px solid ${selectBorderColor(vpTheme, state)};
        }
      `}
  }
`

const selectBorderColor = (vpTheme: VpTheme, state: string) => {
  switch (state) {
    case 'complete':
      return `${vpTheme.colors.fieldBorderComplete};`
    case 'default':
      return `${vpTheme.colors.fieldBorderDefault};`
    case 'error':
      return `${vpTheme.colors['functional-error-dark']};`
    default:
      return ''
  }
}

const selectBackgroundColor = (vpTheme: VpTheme, state: string) => {
  switch (state) {
    case 'complete':
      return `background-color: ${vpTheme.colors.fieldBgComplete};`
    case 'default':
      return `background-color: ${vpTheme.colors.fieldBgDefault};`
    case 'error':
      return `background-color: ${vpTheme.colors['functional-error-light']};`
    default:
      return ''
  }
}

export { ThemedSelect }
