import { Header, Spacer, Text } from '@truepill/react-capsule'
import ToastMessage from 'Components/ToastMessage'
import { upperFirst } from 'lodash'
import { DateTime } from 'luxon'

import { Modal } from '../../common/styledComponents/StyledModal'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'
import { SelfEnrollmentState } from './index'
import { ButtonContainer, EditButton } from './styledComponents'
import { useSelfEnrollment } from './useSelfEnrollment'

interface SelfEnrollmentModalProps {
  isOpen: boolean
  onDismiss: () => void
  selfEnrollmentData: SelfEnrollmentState
  captchaToken: string | null
}

const SelfEnrollmentModal = ({ isOpen, onDismiss, selfEnrollmentData, captchaToken }: SelfEnrollmentModalProps): JSX.Element | null => {
  const { theme } = useContentfulTheme()

  const { submitError, setSubmitError, handleSelfEnrollmentSubmit, isSubmittingEnrollment } = useSelfEnrollment()
  const { firstName, lastName, dob, gender, middleInitial, suffix } = selfEnrollmentData
  if (!dob.month || !dob.year || !dob.day || !gender || !captchaToken) {
    return null
  }

  const handleEdit = (): void => {
    onDismiss()
  }

  const handleModalDismiss = (): void => {
    if (isSubmittingEnrollment) {
      return
    }

    onDismiss()
  }

  return (
    <>
      <Modal isOpen={isOpen} onDismiss={handleModalDismiss} aria-labelledby='confirmInformation'>
        <Header variant='3xl' id='confirmInformation'>
          Confirm your information
        </Header>
        <Spacer size='md' />
        <Text>
          It’s important that this information is accurate so that we will be able to match it with your prescriptions. Please check the information
          below and ensure that it is correct.
        </Text>
        <Spacer size='lg' />
        <Text bold>Legal name</Text>
        <Text>{`${upperFirst(firstName)} ${upperFirst(middleInitial) || ''}  ${upperFirst(lastName)} ${upperFirst(suffix) || ''}`}</Text>
        <Spacer size='md' />
        <Text bold>Date of birth</Text>
        <Text>{DateTime.local(dob.year, dob.month, dob.day).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</Text>
        <Spacer size='md' />
        <Text bold>Sex at birth</Text>
        <Text>{upperFirst(gender)}</Text>

        <ButtonContainer>
          <EditButton disabled={isSubmittingEnrollment} onClick={handleEdit} variant={'dark-text'} vpTheme={theme}>
            Edit
          </EditButton>
          <ThemedButton
            onClick={() => handleSelfEnrollmentSubmit(selfEnrollmentData, captchaToken)}
            disabled={isSubmittingEnrollment}
            vpTheme={theme}
          >
            Submit
          </ThemedButton>
        </ButtonContainer>
      </Modal>
      {submitError && (
        <ToastMessage
          borderLeft={true}
          color='pastel'
          className='toast-message'
          icon={true}
          onDismiss={() => setSubmitError(null)}
          position={{ vertical: 'top', horizontal: 'center' }}
          state={'error'}
          visible={!!submitError}
          timeout={5000}
          onTimeout={() => setSubmitError(null)}
        >
          {submitError}
        </ToastMessage>
      )}
    </>
  )
}

export default SelfEnrollmentModal
