import React, { PropsWithChildren } from 'react'
import SlidingPane from 'react-sliding-pane'
import styled from 'styled-components/macro'

import { mediaLargerThan } from '../../common/styles/variables'

interface MobileDrawerModalProps {
  isOpen: boolean
  onDismiss: () => void
  background: string
}

/**
 * MobileDrawerModal component that opens a navigation drawer when clicked on the menu button
 * in navbar.
 */
const MobileDrawerModal = ({ children, isOpen, onDismiss, background }: PropsWithChildren<MobileDrawerModalProps>): React.ReactElement => {
  return (
    <StyledPane
      from='right'
      title='title'
      overlayClassName='sliding-cart-overlay'
      className='menu-sliding-pane'
      hideHeader
      isOpen={isOpen}
      onRequestClose={onDismiss}
      background={background}
    >
      {children}
    </StyledPane>
  )
}

const StyledPane = styled(SlidingPane)<{ background: string }>`
  position: relative;
  ${({ background }) => `background: ${background};`}

  ${mediaLargerThan.mobile} {
    width: 40% !important;
  }

  > .slide-pane__content {
    padding: 32px 0;
  }

  @media print {
    display: none;
  }
`
export default MobileDrawerModal
