export default class PrescriptionInsuranceInfo {
  prescriptionCopayId!: number
  prescriptionId!: number
  patientId!: number | null
  prescriptionToken!: string | null
  prescriptionStatus!: string
  copayStatus!: string | null
  copayRejectReason!: string | null
  copayAmount!: number | null
  copayOrigin!: string | null
  insuranceToken!: string | null
  quantity?: number | null
}
