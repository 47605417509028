import { Checkbox } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import React, { useState } from 'react'

import { LinkButton } from '../../../Components/LinkButton'
import { useContentfulTheme } from '../../../hooks'
import PhoneNumberModal from '../PhoneNumberModal'
import { CheckboxContainer, PhoneNumber, PhoneNumberLabel, PhoneNumberWrapper, StyledModal } from '../styledComponents'

interface SmsCheckboxProps {
  smsOptIn: boolean
  phoneNumber?: string
  handleSmsOptInChange: (smsOptIn: boolean) => void
  onSubmitPhoneNumber: (phoneNumber: string, smsOptIn: boolean) => void
}

const SmsCheckboxV2: React.FC<SmsCheckboxProps> = ({ smsOptIn, phoneNumber, handleSmsOptInChange, onSubmitPhoneNumber }) => {
  const { theme } = useContentfulTheme()
  const [numberModalOpen, setNumberModalOpen] = useState(false)
  const [showNumberModalSubtitle, setShowNumberModalSubtitle] = useState(false)

  const onCheckedClicked = () => {
    if (!smsOptIn && !phoneNumber) {
      setShowNumberModalSubtitle(true)
      setNumberModalOpen(true)
    } else {
      handleSmsOptInChange(!smsOptIn)
    }
  }

  const onModalClose = () => {
    setNumberModalOpen(false)
    setShowNumberModalSubtitle(false)
  }

  return (
    <>
      <CheckboxContainer vpTheme={theme}>
        <Checkbox
          checked={smsOptIn}
          onCheckedChange={onCheckedClicked}
          data-testid='order-review__agree-terms__checkbox'
          aria-label={'Send me SMS updates about my prescriptions'}
        />
        <PhoneNumberWrapper>
          <PhoneNumberLabel>
            {phoneNumber ? (
              <>
                Send me SMS updates about my prescriptions to
                <PhoneNumber>{formatToPhone(phoneNumber)}</PhoneNumber>
              </>
            ) : (
              'Send me SMS updates about my prescriptions'
            )}
          </PhoneNumberLabel>

          <LinkButton fontSize='1rem' onClick={() => setNumberModalOpen(true)}>
            {phoneNumber ? 'Modify Number' : 'Add Number'}
          </LinkButton>
        </PhoneNumberWrapper>
      </CheckboxContainer>
      <StyledModal isOpen={numberModalOpen} onDismiss={() => setNumberModalOpen(false)} aria-label='add / update phone number modal'>
        <PhoneNumberModal
          initialNumber={formatToPhone(phoneNumber || '')}
          dismissModal={onModalClose}
          handleSaveNumber={onSubmitPhoneNumber}
          smsOptIn={showNumberModalSubtitle}
        />
      </StyledModal>
    </>
  )
}

export default SmsCheckboxV2
