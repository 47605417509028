import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

import { genericError } from '../recoil/atoms/error'

export const useShowError = (): { show: boolean; showError: (action?: string) => void; hideError: () => void } => {
  const [error, setError] = useRecoilState(genericError)

  const showError = useCallback(() => {
    setError({ show: true })
  }, [setError])

  const hideError = useCallback(() => {
    setError({ show: false })
  }, [setError])

  return { show: error.show, showError, hideError }
}
