import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const ArrowUpIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 1L5 5L9 1'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
