import { Spacer, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { IHowToPageFields } from '../../types/generated/contentful'
import InfoCard from './InfoCard'
import {
  CardInfoContainer,
  DotIndicator,
  HowToButtonContainer,
  HowToContentContainer,
  HowToDescription,
  HowToTitle,
  SectionDots,
} from './styledComponents'

// Note: This component's colors are set in the "How to Page"  Contentful model

const HowToSection: React.FC<IHowToPageFields> = ({
  title,
  description,
  cardInfo,
  buttonText,
  buttonLink,
  backgroundColor,
  buttonBackgroundColor,
  buttonTextBackgroundColor,
  titleTextBackgroundColor,
  showButton,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const CAROUSEL_ITEMS = 3

  const { trackButtonClickEvent, trackLinkClickEvent } = useAnalytics()

  const { isLargerThanMobile } = useWindowDimensions()

  const { theme: vpTheme } = useContentfulTheme()

  const onClickSectionCarousel = (index: number) => {
    setCarouselIndex(index)
    trackButtonClickEvent('how_to_section_carousel', `carousel_index: ${index}`, 'page carousel')
  }

  return cardInfo.length > 0 ? (
    <>
      <HowToContentContainer backgroundColor={backgroundColor || vpTheme.colors['primary-300'] || defaultTheme.colors['primary-300']} key={title}>
        <Spacer size='2xl' />
        <HowToTitle variant='5xl' textColor={titleTextBackgroundColor || vpTheme.colors['primary-100'] || defaultTheme.colors['primary-100']}>
          {title}
        </HowToTitle>
        <Spacer size='2xl' />
        <HowToDescription textColor={titleTextBackgroundColor || vpTheme.colors['primary-100'] || defaultTheme.colors['primary-100']}>
          {description}
        </HowToDescription>
        <Spacer size='2xl' />
        <CardInfoContainer>
          {cardInfo.slice(carouselIndex * CAROUSEL_ITEMS, carouselIndex * CAROUSEL_ITEMS + CAROUSEL_ITEMS).map((item, index) => (
            <Fragment key={index}>
              <InfoCard
                key={item.fields.orderIdentifier}
                {...item.fields}
                height={isLargerThanMobile ? '358px' : '340px'}
                width={isLargerThanMobile ? '374px' : '100%'}
                topAvatar={true}
              />
              {!(index === CAROUSEL_ITEMS - 1) && <Spacer size='2xl' />}
            </Fragment>
          ))}
        </CardInfoContainer>
        {cardInfo.length > CAROUSEL_ITEMS && (
          <SectionDots>
            {new Array(Math.ceil(cardInfo.length / CAROUSEL_ITEMS)).fill(0).map((_, index) => (
              <DotIndicator
                key={index}
                onClick={() => onClickSectionCarousel(index)}
                selected={carouselIndex === index}
                vpTheme={vpTheme}
              ></DotIndicator>
            ))}
          </SectionDots>
        )}
        {showButton && (
          <HowToButtonContainer>
            <Link to={buttonLink}>
              <ThemedButton
                vpTheme={vpTheme}
                onClick={() => trackLinkClickEvent(buttonText, buttonLink, LinkOrigin.Body)}
                css={{
                  backgroundColor: buttonBackgroundColor ?? vpTheme.colors['primary-300'] ?? defaultTheme.colors['primary-300'],
                  color: buttonTextBackgroundColor ?? vpTheme.colors['primary-100'] ?? defaultTheme.colors['primary-100'],
                }}
              >
                {buttonText}
              </ThemedButton>
            </Link>
          </HowToButtonContainer>
        )}
      </HowToContentContainer>
    </>
  ) : (
    <Text>Loading...</Text>
  )
}

export default HowToSection
