import { yupResolver } from '@hookform/resolvers/yup'
import { Spacer, Text } from '@truepill/react-capsule'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useAuth } from '../../../context/auth-context'
import { useContentfulTheme } from '../../../hooks'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { PasswordCheck } from '../../../utils/validation'
import { ErrorContainer, PasswordButtonsContainer, PasswordFormWrapper, StyledText } from './styledComponents'

interface PasswordCheckFormFields {
  password: string
}

interface Props {
  onSuccess: () => void
}

const PasswordCheckForm = ({ onSuccess }: Props): React.ReactElement => {
  const { checkPassword } = useAuth()
  const [isError, setIsError] = useState(false)
  const { theme } = useContentfulTheme()

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields, isSubmitting, isValid },
  } = useForm<PasswordCheckFormFields>({
    resolver: yupResolver(PasswordCheck),
    reValidateMode: 'onChange',
    mode: 'onChange',
    delayError: 200,
  })

  const handleCheckPassword = async (loginFormValues: PasswordCheckFormFields) => {
    setIsError(false)
    try {
      await checkPassword(loginFormValues.password)
      onSuccess()
    } catch (e) {
      setIsError(true)
    }
  }

  return (
    <>
      <ThemedHeader vpTheme={theme} variant='4xl'>
        Confirm your password
      </ThemedHeader>
      <Spacer size='lg' />
      <StyledText color={theme.colors['typography-medium']}>Please enter your password to continue making changes to your account.</StyledText>
      <Spacer size='lg' />
      <PasswordFormWrapper onSubmit={handleSubmit(handleCheckPassword)}>
        <ThemedTextField
          type='password'
          placeholder='Password'
          required
          showRequiredIndicator
          state={parseTextFieldStateForCapsule(errors.password, dirtyFields.password)}
          helperText={errors.password?.message ?? ''}
          {...register('password')}
          vpTheme={theme}
        />
        {isError && (
          <>
            <Spacer />
            <ErrorContainer color={theme.colors['functional-error-dark']} background={theme.colors['functional-error-light']}>
              <Text>Wrong password</Text>
            </ErrorContainer>
          </>
        )}
        <PasswordButtonsContainer>
          <ThemedButton type='submit' disabled={!isValid || isSubmitting} vpTheme={theme}>
            Confirm
          </ThemedButton>
        </PasswordButtonsContainer>
      </PasswordFormWrapper>
    </>
  )
}

export default PasswordCheckForm
