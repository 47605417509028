import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const SmallButtonArrow = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16 15' fill='none'>
      <g clipPath='url(#clip0_233_4771)'>
        <path
          d='M3.83301 8H13.1663M8.49976 3.33337L13.1664 8.00004L8.49976 12.6667'
          stroke={vpTheme.colors.buttonPrimaryLabel}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_233_4771'>
          <rect width='16' height='16' fill={vpTheme.colors.buttonPrimaryLabel} transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}
