import { Spacer, Text } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { useContentfulTheme } from '../../../hooks'
import { StyledDeleteActionContainer, Wrapper } from './styledComponents'

interface Props {
  ndc?: string | null
  isDeletingSavingsCard: boolean
  medicationName: string
  cancel: () => void
  handleDeleteSavingsCard: (ndc: string | null) => Promise<void>
}

const DeleteSavingsCard = ({ ndc, isDeletingSavingsCard, cancel, handleDeleteSavingsCard, medicationName }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleDelete = useCallback(() => {
    if (ndc) {
      handleDeleteSavingsCard(ndc)
    }
  }, [ndc, handleDeleteSavingsCard])

  return (
    <>
      <ThemedHeader vpTheme={theme} variant='3xl' css={{ paddingRight: '2rem' }}>
        Are you sure you would like to remove your savings card for {medicationName}?
      </ThemedHeader>
      <Spacer size='lg' />
      <Wrapper data-testid='savings_card-to-be-deleted'>
        <Text>This will remove any available savings applied to your prescription price.</Text>
      </Wrapper>
      <Spacer size='md' />
      <StyledDeleteActionContainer>
        <ThemedButton
          variant='primary-text'
          data-testid='delete-savings-card-btn-cancel'
          size='lg'
          onClick={handleCancel}
          disabled={isDeletingSavingsCard}
          vpTheme={theme}
        >
          Go back
        </ThemedButton>
        <Spacer />
        <ThemedButton
          danger={true}
          data-testid='delete-savings_card-btn-confirm'
          size='lg'
          onClick={handleDelete}
          disabled={isDeletingSavingsCard}
          vpTheme={theme}
        >
          Remove savings card
        </ThemedButton>
      </StyledDeleteActionContainer>
    </>
  )
}

export default DeleteSavingsCard
