import { GetHealthHistoryDto } from '../services/dto'

export enum FormMode {
  View = 'VIEW',
  Edit = 'EDIT',
}

export interface CheckoutFlowData {
  patientHealthSurvey: GetHealthHistoryDto | null
}
