import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { LOGIN_PATH } from '../../constants'
import { useAuth } from '../../context/auth-context'

function withAuthRequired<P>(OriginalComponent: React.ComponentType<P>): React.ComponentType<P> {
  const EnhancedComponent = (props: P) => {
    const { pathname } = useLocation()
    const {
      isLogoutTrigger,
      authState: { isAuthenticated },
    } = useAuth()

    if (!isAuthenticated) {
      return <Redirect to={{ pathname: LOGIN_PATH, state: { redirectTo: isLogoutTrigger ? undefined : pathname } }} />
    }

    return <OriginalComponent {...props} />
  }

  return EnhancedComponent
}

export default withAuthRequired
