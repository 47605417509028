import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAnalytics } from '../hooks/analytics-context'

interface PageLoadEventHOCParams {
  page: string
}

// HOC which accepts page name as parameter and calls the page load event on component mount.
const withPageLoadEvent =
  ({ page }: PageLoadEventHOCParams) =>
  (Component: React.FC<Record<string, unknown>>) =>
  // eslint-disable-next-line react/display-name
  (props: Record<string, unknown>): any => {
    const history = useHistory()
    const { trackPageViewEvent } = useAnalytics()
    useEffect(() => {
      trackPageViewEvent(page)
    }, [history.location, props, trackPageViewEvent])

    return <Component {...props} />
  }

export default withPageLoadEvent
