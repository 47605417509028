import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { debounce } from 'lodash'
import { ClearIndicatorProps, components, ControlProps, GroupBase, MenuListProps, OptionProps, OptionsOrGroups, PlaceholderProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectComponents } from 'react-select/dist/declarations/src/components'

import { CloseButton, SearchIcon } from '../../../assets/Icons'
import { useContentfulTheme } from '../../../hooks'
import { SelectedPharmacy } from '../useTransferOut'
import { StyledPharmacyFinder } from './styledComponents'
import { PharmacyLocation, usePharmacyFinder } from './usePharmacyFinder'

const Placeholder = (props: PlaceholderProps<PharmacyLocation, false, GroupBase<PharmacyLocation>>) => {
  return (
    <components.Placeholder {...props}>
      <StyledPharmacyFinder.PlaceholderContainer>
        <Text>e.g. CVS, Walgreens</Text>
      </StyledPharmacyFinder.PlaceholderContainer>
    </components.Placeholder>
  )
}

const Option = (props: OptionProps<PharmacyLocation, false, GroupBase<PharmacyLocation>>) => {
  return (
    <components.Option {...props}>
      <StyledPharmacyFinder.OptionContainer>
        <Text>
          {props.data.name} - {props.data.address}
        </Text>
      </StyledPharmacyFinder.OptionContainer>
    </components.Option>
  )
}

const MenuList = (props: MenuListProps<PharmacyLocation, false, GroupBase<PharmacyLocation>>) => (
  <components.MenuList {...props}>
    <Text style={{ margin: 0 }}>{props.children}</Text>
  </components.MenuList>
)

const ClearIndicator = (props: ClearIndicatorProps<PharmacyLocation, false, GroupBase<PharmacyLocation>>) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseButton />
    </components.ClearIndicator>
  )
}

const formatOptionLabel = (data: PharmacyLocation) => (
  <StyledPharmacyFinder.LabelContainer>
    <Text bold>{data.name}</Text>
    <StyledPharmacyFinder.GroupedLabel>
      <Text>{data.address}</Text>
      {data.phoneNumber && <Text>{data.phoneNumber}</Text>}
    </StyledPharmacyFinder.GroupedLabel>
  </StyledPharmacyFinder.LabelContainer>
)

const Control = ({ children, ...props }: ControlProps<PharmacyLocation, false, GroupBase<PharmacyLocation>>) => (
  <components.Control {...props}>
    {!props.hasValue && (
      <StyledPharmacyFinder.SearchIconContainer>
        <SearchIcon />
      </StyledPharmacyFinder.SearchIconContainer>
    )}
    {children}
  </components.Control>
)

export interface PharamacyFinderProps {
  onPharmacySelect: (pharmacy: SelectedPharmacy | null) => void
}

const PharmacyFinder = ({ onPharmacySelect }: PharamacyFinderProps): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const { fetchPharmacyLocations, handlePharmacySelect, pharmacyLocation } = usePharmacyFinder({ onPharmacySelect })

  const loadOptions = (inputString: string, rsCallback: (options: OptionsOrGroups<PharmacyLocation, GroupBase<PharmacyLocation>>) => void): void => {
    fetchPharmacyLocations(inputString).then((data) => rsCallback(data))
  }

  const memoizedLoadOptions = debounce(loadOptions, 300)

  const componentConfig: Partial<SelectComponents<PharmacyLocation, false, GroupBase<PharmacyLocation>>> = {
    DropdownIndicator: null,
    Placeholder,
    Option,
    Control,
    MenuList,
    ClearIndicator,
  }

  const gray = theme.colors['gray-700'] ?? defaultTheme.colors['gray-700']
  const blue = theme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']

  return (
    <StyledPharmacyFinder.Container>
      <AsyncSelect
        aria-label='Find a pharmacy'
        components={componentConfig}
        onChange={handlePharmacySelect}
        loadOptions={memoizedLoadOptions}
        value={pharmacyLocation}
        isClearable
        isSearchable
        formatOptionLabel={formatOptionLabel}
        styles={{
          input: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
          }),
          control: (base, state) => ({
            ...base,
            padding: '0.5rem 0.75rem',
            fontFamily: 'Lato, sans-serif',
            border: state.isFocused ? `1px solid ${blue}` : `1px solid ${gray}`,
            borderRadius: '8px',
            boxShadow: state.isFocused ? `0 0 0 2px ${blue}` : 'none',
          }),
          option: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
          }),
          valueContainer: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
          }),
          menuList: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
            boxShadow: `0px 6px 8px 0px ${theme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}`,
            borderRadius: '8px',
          }),
        }}
      />
      <div id='googlemaps-attribution-container'></div>
    </StyledPharmacyFinder.Container>
  )
}

export default PharmacyFinder
