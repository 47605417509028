import { BannerAlert, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const SpecificationsWrapper = styled.div`
  display: flex;

  svg {
    flex-shrink: 0;
    margin-right: 0.75rem;
  }
`

const ShippingMethodContainer = styled.div<{ ischecked: boolean; checkedColor?: string; checkedBorder?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ ischecked, checkedColor }) => (ischecked ? checkedColor ?? defaultTheme.colors['primary-100'] : defaultTheme.colors.white)};
  border: 1px solid
    ${({ ischecked, checkedBorder }) => (ischecked ? checkedBorder ?? defaultTheme.colors['primary-500'] : defaultTheme.colors['gray-500'])};
`

const ShippingMethodDetails = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 150%;
  width: 100%;
  justify-content: space-between;
`

const ShippingPrice = styled.div`
  display: flex;
`

const ShippingMethodItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
  }
`

const DeliveryDaysText = styled(Text)<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
  margin-left: 2.1rem;

  ${mediaLargerThan.tablet} {
    margin-left: 0.3rem;
  }
`

const AddressViewMode = styled.div<ThemedComponent>`
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-500'] ?? defaultTheme.colors['gray-500']};
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  margin-bottom: 2rem;
`

const EditButton = styled(Text)`
  position: absolute;
  cursor: pointer;
  right: 1rem;
`

const StyledText = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
`

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`

const StyledBannerAlert = styled(BannerAlert)`
  padding: 1rem;
`

export {
  AddressViewMode,
  DeliveryDaysText,
  EditButton,
  ShippingMethodContainer,
  ShippingMethodDetails,
  ShippingMethodItem,
  ShippingPrice,
  SpecificationsWrapper,
  StyledBannerAlert,
  StyledText,
  SubmitButtonContainer,
}
