export enum RoutePaths {
  ABOUT_PATH = '/about-us',
  ACCOUNT_ADDRESSES_PATH = '/account/addresses',
  ACCOUNT_CREATION_PATH = '/account-creation',
  ACCOUNT_HEALTH_PROFILE_PATH = '/account/health-profile',
  ACCOUNT_INSURANCE_PATH = '/account/insurance',
  ACCOUNT_PAYMENTS_PATH = '/account/payments',
  ACCOUNT_SETTINGS_PATH = '/account/settings',
  ADMIN_PATH = '/admin',
  CART_PATH = '/cart',
  CHECKOUT_PATH = `/prescription-management/checkout`,
  CONTACT_PATH = '/contact',
  DEMO_PATH = '/demo',
  FAQ_PATH = '/faq',
  FORGOT_PASSWORD_PATH = '/forgot-password',
  HIPAA_PRACTICES_PATH = '/hipaa-practices',
  IDENTITY_VERIFICATION = '/patient/identity-verification',
  ID_UPLOAD_PATH = '/checkout/upload-government-id',
  INFORMED_CONSENT_PATH = '/informed-consent-pharmacy-services',
  LOGIN_PATH = '/login',
  MOCKRX_PATH = '/mockrx',
  NOT_FOUND_PATH = '/not-found',
  ORDERS_PATH = '/orders',
  ORDER_CONFIRMATION_PATH = '/checkout/order-confirmation/:order_id',
  ORDER_DETAILS_PATH = '/orders/:order_id',
  OUTGOING_INFO_PATH = '/outgoing-info',
  PARENT_RELATIONSHIP_CONFIRMATION = '/parent-relationship-confirmation',
  PATIENT_ACCOUNT_LINKED_PATH = '/account-linked',
  PATIENT_RECORD_VERIFICATION_PATH = '/patient-record-verification',
  PRESCRIPTION_CONFIRMATION_PATH = '/prescription-confirmation',
  PRESCRIPTION_MANAGEMENT_PATH = '/prescription-management',
  PRIVACY_POLICY_PATH = '/privacy-policy',
  RESET_PASSWORD_PATH = '/reset-password',
  ROOT_PATH = '/',
  SELF_ENROLLMENT_PATH = '/sign-up',
  TERMS_OF_SERVICE_PATH = '/terms-of-service',
  TRANSFERS_PATH = '/transfers/details',
  TRANSFER_REQUEST_PATH = '/transfers/request',
  URAC_PATH = '/checkout/patient-urac',
  EMAIL_UPDATING_CONFIRMATION_PATH = '/email-updating-confirmation',
  TRANSFERS_OUT_PATH = '/transfers-out',
  ACTION_CONFIRMATION_PATH = '/action-confirmation',
  AUTO_REFILLS_PATH = '/auto-refills',
}
