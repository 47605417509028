import { Text } from '@truepill/react-capsule'

import ContactChannels from '../../pages/ContactUs/sections/ContactChannels'
import ContactUsForm from '../../pages/ContactUs/sections/ContactUsForm'
import {
  IContactChannelsSectionFields,
  IContactFormSectionFields,
  IFaqSectionFields,
  IGalleryFields,
  IHeroFields,
  IHorizontalImageFields,
  IHowToPageFields,
  INotFoundPageFields,
  IRibbonFields,
  ITextWithHeaderFields,
  ITruepillAccreditationsFields,
  ITruepillPartnerFields,
  ITwoColumnsFields,
  IValuePropsBlocksFields,
} from '../../types/generated/contentful'
import FAQSection from './FAQSection'
import Gallery from './Gallery/Gallery'
import Hero from './Hero'
import HorizontalImage from './HorizontalImage'
import HowToSection from './HowToSection'
import NotFound from './NotFound'
import Partner from './Partner'
import TextWithHeader from './TextWithHeader'
import TruepillAccreditations from './TruepillAccreditations'
import TwoColumns from './TwoColumns'
import ValuePropsSection from './ValuePropsBlockSection'

interface RibbonProps {
  component: IRibbonFields['component']
}

const Ribbon: React.FC<RibbonProps> = ({ component }) => {
  const { fields } = component
  const type = component.sys.contentType.sys.id

  switch (type) {
    case 'hero':
      return <Hero {...(fields as IHeroFields)} />
    case 'faqSection':
      return <FAQSection {...(fields as IFaqSectionFields)} />
    case 'twoColumns':
      return <TwoColumns {...(fields as ITwoColumnsFields)} />
    case 'howToPage':
      return <HowToSection {...(fields as IHowToPageFields)} />
    case 'valuePropsBlocks':
      return <ValuePropsSection {...(fields as IValuePropsBlocksFields)} />
    case 'notFoundPage':
      return <NotFound {...(fields as INotFoundPageFields)} />
    case 'textWithHeader':
      return <TextWithHeader {...(fields as ITextWithHeaderFields)} />
    case 'gallery':
      return <Gallery {...(fields as IGalleryFields)} />
    case 'horizontalImage':
      return <HorizontalImage {...(fields as IHorizontalImageFields)} />
    case 'contactChannelsSection':
      return <ContactChannels {...(fields as IContactChannelsSectionFields)} />
    case 'truepillAccreditations':
      return <TruepillAccreditations {...(fields as ITruepillAccreditationsFields)} />
    case 'truepillPartner':
      return <Partner {...(fields as ITruepillPartnerFields)} />
    case 'contactFormSection':
      return <ContactUsForm {...(fields as IContactFormSectionFields)} />
    default:
      return <Text bold css={{ textAlign: 'center' }}>{`Matching component type ${type} not implemented`}</Text>
  }
}

export default Ribbon
