import { Loader } from '@googlemaps/js-api-loader'
import { AxiosRequestHeaders } from 'axios'

export interface ILocationService {
  getPharmacySuggestions: (location: string) => Promise<PharmacyInfo[]>
  getPharmacyDetails: (placeId: string, callback: (err: string | null, placeResult: PharmacyLocationDetails) => void) => void
}

export interface PharmacyInfo {
  id: string
  name: string
  address: string
}

export interface PharmacyAddress {
  street1: string
  street2: string | null
  city: string
  state: string
  zip: string
}

export interface PharmacyLocationDetails {
  address: string
  pharmacyAddress: PharmacyAddress
  phoneNumber?: string
  id: string
}

let sessionToken: google.maps.places.AutocompleteSessionToken | undefined

export class LocationService implements ILocationService {
  readonly baseUrl = 'https://maps.googleapis.com'
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  private static instance: LocationService

  private constructor(private googleApiClient: typeof google) {}

  static async getInstance(): Promise<LocationService> {
    if (LocationService.instance) {
      return LocationService.instance
    }

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_TOKEN as string,
      version: 'weekly',
      libraries: ['places'],
    })

    const googleApiClient = await loader.load()

    LocationService.instance = new LocationService(googleApiClient)
    return LocationService.instance
  }

  async getPharmacySuggestions(location: string): Promise<PharmacyInfo[]> {
    const google = this.googleApiClient

    const autoCompleteService = new google.maps.places.AutocompleteService()

    if (!sessionToken) {
      sessionToken = new google.maps.places.AutocompleteSessionToken()
    }

    const autoCompleteResponse = await autoCompleteService.getPlacePredictions({
      input: location,
      sessionToken,
      types: ['pharmacy'],
      componentRestrictions: { country: 'us' },
    })

    return autoCompleteResponse.predictions.map((p) => ({
      name: p.structured_formatting.main_text,
      address: p.structured_formatting.secondary_text,
      id: p.place_id,
    }))
  }

  getPharmacyDetails(placeId: string, callback: (err: string | null, placeResult: PharmacyLocationDetails) => void): void {
    const token = sessionToken
    sessionToken = undefined

    const attributionDetailNode = document.getElementById('googlemaps-attribution-container') as HTMLDivElement

    if (!attributionDetailNode) {
      throw new Error('Unable to fetch pharmacy details without attribution details node for google')
    }

    const places = new google.maps.places.PlacesService(attributionDetailNode)

    places.getDetails(
      { placeId, fields: ['formatted_address', 'formatted_phone_number', 'adr_address', 'address_components'], sessionToken: token },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (place?.formatted_address) {
            callback(null, {
              id: placeId,
              address: place.formatted_address,
              phoneNumber: place.formatted_phone_number,
              pharmacyAddress: this.parseAddress(place.address_components),
            })
          }
        }
      },
    )
  }

  private parseAddress(addressComponents?: google.maps.GeocoderAddressComponent[]): PharmacyAddress {
    if (!addressComponents) {
      throw new Error('Missing critical address components')
    }

    let street1 = ''
    let street2 = ''
    let city = ''
    let state = ''
    let zip = ''

    addressComponents.forEach((component) => {
      switch (component.types[0]) {
        case 'street_number': {
          street1 = component.long_name + ' ' + street1
          break
        }
        case 'route': {
          street1 = street1 + component.long_name
          break
        }
        case 'subpremise': {
          street2 = component.long_name
          break
        }
        case 'locality':
        case 'sublocality_level_1': {
          city = component.long_name
          break
        }
        case 'administrative_area_level_1': {
          state = component.short_name
          break
        }
        case 'postal_code': {
          zip = component.long_name
          break
        }
      }
    })

    if (!(street1 && city && state && zip)) {
      throw new Error('Missing critical address components')
    }

    return {
      street1,
      street2,
      city,
      state,
      zip,
    }
  }
}
