import { ReactElement } from 'react'

import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { NavigationLink, NavigationPath } from '../../interfaces/Header'
import { StyledLink, StyledLinkWrapper, StyledList, StyledSpan } from './styledComponents'
import { useSideNavigation } from './useSideNavigation'

export interface NavigationLinksProps {
  links: NavigationLink[]
  onLinkClick?: () => void
}

const SideNavigation = ({ links }: NavigationLinksProps): ReactElement => {
  const sideNavigation = useSideNavigation()
  const { trackLinkClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const setTestId = (link: string): string => {
    return 'am-link-' + link.replace(/\s/g, '-').toLowerCase()
  }

  return (
    <StyledList>
      {sideNavigation.isAuthenticated && links.length > 0 && (
        <>
          {links.map((navigationLink: NavigationLink) => {
            const link = navigationLink as NavigationPath
            return (
              <StyledLinkWrapper key={link.name} aria-selected={link.selected} data-testid={setTestId(link.name)} vpTheme={theme}>
                <StyledLink to={link.path} onClick={() => trackLinkClickEvent(link.name, `direct user to ${link.path}`, LinkOrigin.SideBar)}>
                  <>
                    {link.icon}
                    <StyledSpan vpTheme={theme}>{link.name}</StyledSpan>
                  </>
                </StyledLink>
              </StyledLinkWrapper>
            )
          })}
        </>
      )}
    </StyledList>
  )
}

export default SideNavigation
