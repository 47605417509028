enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Unknown = 'Unknown',
}

const MobileRegex = /Android|iPad|iPhone/
const DesktopRegex = /Linux|Macintosh|Windows/

const getUserAgent = (): string => navigator?.userAgent || ''

export const getDeviceType = (): DeviceType => {
  const userAgent = getUserAgent()

  if (MobileRegex.exec(userAgent)) {
    return DeviceType.Mobile
  }

  if (DesktopRegex.exec(userAgent)) {
    return DeviceType.Desktop
  }

  return DeviceType.Unknown
}
