import { StyledCartBanner } from './styledComponent'

export type CartBannerProps = {
  customerName: string
  cartBannerLogo?: string
  cartBannerSubtext?: string
}

const CartBanner = ({ cartBannerLogo, cartBannerSubtext, customerName }: CartBannerProps): React.ReactElement => {
  return (
    <StyledCartBanner.Container>
      <StyledCartBanner.MainImage alt={`${customerName} Logo`} src={cartBannerLogo} />
      <StyledCartBanner.Subtext dangerouslySetInnerHTML={{ __html: cartBannerSubtext }} />
    </StyledCartBanner.Container>
  )
}

export default CartBanner
