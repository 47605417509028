import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  top: 0;
  min-height: 90vh;
  align-content: flex-start;
  flex-wrap: wrap;
  max-width: 900px;
  width: 100%;
  @media (min-width: 720px) {
    min-height: unset;
  }
`

const AlertContainer = styled.div<{ border?: string; background?: string }>`
  margin-top: 1rem;
  display: flex;
  padding: 1rem 1rem 1rem 0.7rem;
  align-items: center;
  border-left: solid 0.25rem ${({ border }) => border ?? defaultTheme.colors['functional-info-dark']};
  max-width: 743px;
  border-radius: 8px;
  background: ${({ background }) => background ?? defaultTheme.colors['functional-info-light']};
  flex: none;
`

const StyledHeader = styled.div`
  width: 100%;
`

export { AlertContainer, Container, StyledHeader }
