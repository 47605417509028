import { defaultTheme, VpTheme } from '@vpharm-platform/shared'

export const SearchIcon = ({ vpTheme }: { vpTheme?: VpTheme }): JSX.Element => (
  <svg width={20} height={20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.9999 19L14.6499 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
      stroke={vpTheme?.colors['primary-700'] ?? defaultTheme?.colors['primary-700']}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
