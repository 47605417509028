import React from 'react'

export const HealthStoreIcon = (): React.ReactElement => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10 22V17H14V22' stroke='black' strokeWidth='1.5' strokeMiterlimit='10' />
    <path d='M4 13.5V22H20V13.5' stroke='black' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='square' />
    <path
      d='M6.75 4H5L1 10C1 11.5 1.6 13.25 4 13.25C5.75 13.25 6.5 12 6.75 11.5C7 12 8 13.25 9.5 13.25C11 13.25 11.75 12 12 11.5C12.25 12 13.15 13.25 14.75 13.25C16.35 13.25 17 12 17.25 11.5C17.75 12.25 18.5 13.25 20 13.25C22 13.25 23 11.5 23 10.25C23 9.65 20.3333 5.83333 19 4H17.25'
      stroke='black'
      strokeWidth='1.5'
    />
    <path
      d='M15.75 3H13.5V0.75H10.5V3H8.25V6H10.5V8.25H13.5V6H15.75V3Z'
      stroke='black'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='square'
    />
  </svg>
)
