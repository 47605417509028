export enum CardType {
  AMEX = 'amex',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  UNIONPAY = 'unionpay',
  UNKNOWN = 'unknown',
}
