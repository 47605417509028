import { Text, theme } from '@truepill/react-capsule'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import SupportLink from '../../Components/SupportLink'
import { ACCOUNT_CREATION_PATH, LOGIN_PATH } from '../../constants'
import { useCustomerProfile } from '../../hooks'
import { patientService } from '../../services'
import { SelfEnrollmentAlreadyEnrolledError, SelfEnrollmentEligibilityError } from './errors'
import { SelfEnrollmentState } from './index'

interface UseSelfEnrollment {
  handleSelfEnrollmentSubmit: (selfEnrollmentData: SelfEnrollmentState, captchaToken: string) => Promise<void>
  isSubmittingEnrollment: boolean
  submitError: JSX.Element | null
  setSubmitError: React.Dispatch<React.SetStateAction<JSX.Element | null>>
  isModalOpen: boolean
  setIsModalOpen: (open: boolean) => void
}

const createErrorComponent = (): JSX.Element => {
  return (
    <Text>
      The connection to our database has failed, please{' '}
      <TextLink
        href=''
        onClick={() => {
          window.location.reload()
        }}
      >
        refresh the page
      </TextLink>{' '}
      and try again later or alternatively email <SupportLink style={{ color: theme.colors['functional-info-dark'].computedValue }} />.
    </Text>
  )
}

export const useSelfEnrollment = (): UseSelfEnrollment => {
  const history = useHistory()
  const { customerProfile } = useCustomerProfile()
  const [isSubmittingEnrollment, setIsSubmittingEnrollment] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [submitError, setSubmitError] = useState<JSX.Element | null>(null)

  const handleSelfEnrollmentSubmit = async (selfEnrollmentData: SelfEnrollmentState, captchaToken: string) => {
    setIsSubmittingEnrollment(true)
    try {
      const { identityVerificationToken } = await patientService.enrollPatient(selfEnrollmentData, captchaToken, customerProfile.vpharmCustomerToken)
      history.replace(`${ACCOUNT_CREATION_PATH}/?registration_token=${identityVerificationToken}`)
    } catch (e) {
      if (e instanceof SelfEnrollmentAlreadyEnrolledError) {
        history.push(LOGIN_PATH)
      } else if (e instanceof SelfEnrollmentEligibilityError) {
        // TODO: Handle Eligibility Error?
      } else {
        setIsSubmittingEnrollment(false)
        setSubmitError(createErrorComponent())
        throw e
      }
    }
  }

  return {
    handleSelfEnrollmentSubmit,
    isSubmittingEnrollment,
    submitError,
    setSubmitError,
    isModalOpen,
    setIsModalOpen,
  }
}

const TextLink = styled.a`
  color: ${theme.colors['functional-info-dark'].computedValue};
  cursor: pointer;
`
