import { SEO } from '@truepill/react-capsule'
import Ribbon from 'Components/MarketingPages/Ribbon'
import StyledToastMessage from 'Components/ToastMessage'
import { useCustomerProfile, useQuery } from 'hooks'
import { useContentfulDynamicPage } from 'hooks/contentful/useContentfulDynamicPage'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useLDContextWithLocalStorage } from '../../Components/LDProvider'
import { LOGIN_PATH, SELF_ENROLLMENT_PATH, VP1192_DISPLAY_HOMEPAGE } from '../../constants'
import { useAnalytics } from '../../hooks/analytics-context'

const LandingPage: React.FC = () => {
  const history = useHistory()
  const query = useQuery()
  // TODO: Convert flag to customer config
  const { [VP1192_DISPLAY_HOMEPAGE]: displayHomepage } = useLDContextWithLocalStorage()
  const { pageContent, error } = useContentfulDynamicPage('landing')
  const {
    customerProfile: { allowSelfEnrollment },
  } = useCustomerProfile()
  const { trackErrorShownEvent } = useAnalytics()
  const redirect_to = query.get('redirect_to')
  const redirect = query.get('redirect')

  const replaceRedirectParams = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('redirect_to')
    searchParams.set('redirect', redirect_to || '')

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  useEffect(() => {
    if (error) {
      trackErrorShownEvent('landing_page_load')
    }
  }, [error, trackErrorShownEvent])

  useEffect(() => {
    if (displayHomepage === false) {
      if (allowSelfEnrollment) {
        history.replace(SELF_ENROLLMENT_PATH)
      } else {
        if (redirect) {
          history.replace(redirect)
        } else {
          if (redirect_to) {
            replaceRedirectParams()
          }
          history.replace(LOGIN_PATH)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowSelfEnrollment, displayHomepage, history])

  if (!displayHomepage) return <></>

  return (
    <>
      <StyledToastMessage state={'error'} visible={error}>
        <div>An error occurred while loading the site. Please try again later.</div>
      </StyledToastMessage>
      {pageContent && (
        <MainContainer data-testid='marketingpage-container'>
          {pageContent.seo?.title && <SEO title={pageContent.seo.title} description={pageContent.seo?.description} useDefaults />}
          {pageContent.content.map(({ component }, index) => (
            <Ribbon key={`ribbon-${index}`} component={component} />
          ))}
        </MainContainer>
      )}
    </>
  )
}

const MainContainer = styled.div`
  align-self: start;
  width: 100%;
  min-height: calc(100vh - 9.5rem);
`

export default LandingPage
