import { BannerAlert } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { media } from '../../../common/styles/variables'

const StyledBannerAlert = styled(BannerAlert)`
  padding: 1rem;
`

const StyledActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const ToggleAutoCompleteAddressEntry = styled(ThemedButton)`
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`

const AddressAutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
`

const DetailWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: ${media.tablet}) {
    flex-wrap: nowrap;
  }
`

const CityWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  @media (min-width: ${media.tablet}) {
    width: 45%;
    padding-bottom: 0;
    margin-right: 1rem;
  }
`

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: ${media.tablet}) {
    width: 65%;
  }
`

const Wrapper = styled.div`
  &:last-child {
    margin-left: 1rem;
  }
  @media (min-width: ${media.tablet}) {
    &:last-child {
      margin-left: 1rem;
    }
    margin-left: 1rem;
    width: 80%;
  }
`

const Hideable = styled.div<{ hidden?: boolean }>`
  ${({ hidden }) => hidden && 'height: 0; overflow: hidden;'}
`

const ToggleShowAddressLine2 = styled(ThemedButton)<{ hidden?: boolean }>`
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  ${({ hidden }) => hidden && 'display: none;'}
`

export {
  AddressAutocompleteContainer,
  CityWrapper,
  DetailWrapper,
  Hideable,
  MainWrapper,
  StyledActionContainer,
  StyledBannerAlert,
  ToggleAutoCompleteAddressEntry,
  ToggleShowAddressLine2,
  Wrapper,
}
