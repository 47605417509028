import { PropsWithChildren, ReactElement } from 'react'

import { ThemedComponent } from '../../../common/styledComponents/types'
import { StripeElementLabelProps, StripeElementLabelStyle } from './styledComponents'

const StripeElementLabel = (props: PropsWithChildren<StripeElementLabelProps & ThemedComponent>): ReactElement => {
  return (
    <StripeElementLabelStyle required={props.required} bold variant='body' vpTheme={props.vpTheme}>
      {props.children}
    </StripeElementLabelStyle>
  )
}

export default StripeElementLabel
