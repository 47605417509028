import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { ACCOUNT_INSURANCE_PATH, OUTGOING_INFO_PATH, PRESCRIPTION_MANAGEMENT_PATH } from '../constants'
import { useCustomerProfile } from '../hooks'

// HOC which has redirection rules
const withRedirect =
  (Component: React.FC<Record<string, unknown>>) =>
  // eslint-disable-next-line react/display-name
  (props: Record<string, unknown>): any => {
    const { customerProfile } = useCustomerProfile()
    const { pathname } = useLocation()

    if (customerProfile.isBeingTerminated && pathname !== OUTGOING_INFO_PATH) {
      return <Redirect to={OUTGOING_INFO_PATH} />
    }

    if (customerProfile.cashOnly && pathname === ACCOUNT_INSURANCE_PATH) {
      return <Redirect to={PRESCRIPTION_MANAGEMENT_PATH} />
    }

    return <Component {...props} />
  }

export default withRedirect
