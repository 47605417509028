import { Header, Text, Toggle } from '@truepill/react-capsule'
import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import moment from 'moment'

import { AutoRefillPageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'
import PrescriptionCardIcon from '../../PrescriptionManagement/PrescriptionCard/PrescriptionCardIcon'
import AutoRefillDisenrollModal from '../AutoRefillDisenrollModal'
import AutoRefillTermsConditionModal from '../AutoRefillTermsConditionModal'
import { StyledAutoRefillCard } from './styledComponents'
import { useAutoRefillCard } from './useAutoRefillCard'

export interface AutoRefillCardMedInfo {
  brandName: string
  genericName: string | null
  rxNumber: string
  medStrength: string
  daysSupply: number | null
  medPrice: number | null
  nextFillDate: string | null
  refillsRemaining: number
  isAutoRefillOn: boolean
  form: string | null
  ndc: string
}

export interface AutoRefillCardProps {
  medInfo: AutoRefillCardMedInfo
  submitEnrollAutoRefill: (prescriptionToken: string) => Promise<void>
  submitDisenrollAutoRefill: (prescriptionToken: string) => Promise<void>
  theme: VpTheme
  autoRefillMicrocopy?: AutoRefillPageMicrocopy
  prescriptionToken: string
  isToggleDisabled: boolean
  isOnlyCashMode: boolean
}

interface AutoRefillStatusTextProps {
  isAutoRefillOn: boolean
  nextFillDate: string | null
  isLargerThanTablet: boolean
  theme: VpTheme
}

const AutoRefillCard = ({
  medInfo,
  theme,
  autoRefillMicrocopy,
  prescriptionToken,
  submitDisenrollAutoRefill,
  submitEnrollAutoRefill,
  isToggleDisabled,
  isOnlyCashMode,
}: AutoRefillCardProps): React.ReactElement => {
  const {
    isTermsConditionModalOpen,
    handleTermsConditionModalState,
    handleToggleAutoRefill,
    enrollAutoRefill,
    isSubmitting,
    isDisenrollModalOpen,
    disenrollAutoRefill,
    handleDisenrollModalState,
  } = useAutoRefillCard({
    isAutoRefillEnabled: medInfo.isAutoRefillOn,
    prescriptionToken,
    submitEnrollAutoRefill,
    submitDisenrollAutoRefill,
    ndc: medInfo.ndc,
  })
  const { isLargerThanTablet } = useWindowDimensions()

  return (
    <>
      <StyledAutoRefillCard.Container backgroundColor={theme.colors['gray-100']}>
        <StyledAutoRefillCard.HeaderToggleContainer>
          <StyledAutoRefillCard.HeaderIconContainer>
            <StyledAutoRefillCard.HeaderContainer>
              <StyledAutoRefillCard.MedInfoText>
                Rx #{medInfo.rxNumber} • {medInfo.refillsRemaining} {medInfo.refillsRemaining > 1 ? 'refills left' : 'refill left'}{' '}
                {medInfo.daysSupply ? `• ${medInfo.daysSupply} ${medInfo.daysSupply > 1 ? 'days supply' : 'day supply'}` : ''}
              </StyledAutoRefillCard.MedInfoText>
              <StyledAutoRefillCard.MedNameArrowContainer>
                <Header variant='2xl'>{medInfo.brandName}</Header>
              </StyledAutoRefillCard.MedNameArrowContainer>
              {medInfo.genericName && (
                <Text style={{ color: theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium'] }}>
                  Generic: {medInfo.genericName}
                </Text>
              )}
              <StyledAutoRefillCard.PriceWithNextFillDate>
                {medInfo.medPrice && isLargerThanTablet && <Text>${medInfo.medPrice.toFixed(2)}</Text>}
                {isLargerThanTablet && (
                  <AutoRefillStatusWithNextFillDate
                    isAutoRefillOn={medInfo.isAutoRefillOn}
                    isLargerThanTablet={isLargerThanTablet}
                    nextFillDate={medInfo.nextFillDate}
                    theme={theme}
                  />
                )}
              </StyledAutoRefillCard.PriceWithNextFillDate>
            </StyledAutoRefillCard.HeaderContainer>
            <StyledAutoRefillCard.MedIconContainer>
              <PrescriptionCardIcon form={medInfo.form} />
              {medInfo.medPrice && !isLargerThanTablet && <Text>${medInfo.medPrice.toFixed(2)}</Text>}
            </StyledAutoRefillCard.MedIconContainer>
          </StyledAutoRefillCard.HeaderIconContainer>
          <StyledAutoRefillCard.ToggleContainer>
            {!isLargerThanTablet && (
              <AutoRefillStatusWithNextFillDate
                isAutoRefillOn={medInfo.isAutoRefillOn}
                isLargerThanTablet={isLargerThanTablet}
                nextFillDate={medInfo.nextFillDate}
                theme={theme}
              />
            )}
            <Toggle disabled={isSubmitting || isToggleDisabled} checked={medInfo.isAutoRefillOn} onChange={handleToggleAutoRefill} withText />
          </StyledAutoRefillCard.ToggleContainer>
        </StyledAutoRefillCard.HeaderToggleContainer>
      </StyledAutoRefillCard.Container>
      <AutoRefillTermsConditionModal
        termsAndCondition={isOnlyCashMode ? autoRefillMicrocopy?.autoRefillTermsConditionCash : autoRefillMicrocopy?.autoRefillTermsCondition}
        isOpen={isTermsConditionModalOpen}
        isSubmitting={isSubmitting}
        enrollAutoRefill={enrollAutoRefill}
        onDismiss={() => handleTermsConditionModalState(false)}
        theme={theme}
      />
      <AutoRefillDisenrollModal
        isOpen={isDisenrollModalOpen}
        disenrollAutoRefill={disenrollAutoRefill}
        isSubmitting={isSubmitting}
        onDismiss={() => handleDisenrollModalState(false)}
        theme={theme}
      />
    </>
  )
}

const AutoRefillStatusWithNextFillDate = ({ isAutoRefillOn, isLargerThanTablet, nextFillDate, theme }: AutoRefillStatusTextProps) => (
  <StyledAutoRefillCard.StatusContainer>
    <StyledAutoRefillCard.StatusText isAutoRefillOn={isAutoRefillOn} vpTheme={theme}>
      Auto refill {isAutoRefillOn ? 'ON' : 'OFF'}
    </StyledAutoRefillCard.StatusText>
    {isLargerThanTablet && nextFillDate && <Text color={theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']}>•</Text>}
    {nextFillDate && (
      <Text bold style={{ color: theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium'] }}>
        Next fill date: {moment(nextFillDate).format('MMMM DD, YYYY')}
      </Text>
    )}
  </StyledAutoRefillCard.StatusContainer>
)

export default AutoRefillCard
