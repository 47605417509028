import { Accordion, AccordionItem } from '@truepill/react-capsule'
import { useContentfulDynamicPage } from 'hooks'
import React, { useMemo } from 'react'

import RichTextRenderer from '../../../Components/RichTextRenderer'
import { useAnalytics } from '../../../hooks/analytics-context'
import { IFaqSectionFields } from '../../../types/generated/contentful'
import { StyledTransferConfirmation } from './styledComponent'

const FaqAccordion: React.FC = () => {
  const { pageContent } = useContentfulDynamicPage('faq')
  const { trackAccordionChangeEvent } = useAnalytics()

  const onToggleQuestion = (question: string) => {
    const isOpen = !!question
    trackAccordionChangeEvent('transfer_request_confirmation_faq_question_toggle', question, isOpen)
  }

  const questionFields = useMemo(
    () => pageContent?.content.find((content) => content.name === 'FAQ Transfers Section')?.component.fields as IFaqSectionFields,
    [pageContent?.content],
  )

  return (
    <StyledTransferConfirmation.FaqSection>
      <Accordion type='single' collapsible onValueChange={(question) => onToggleQuestion(question)}>
        {questionFields?.questions.map((questionItem) => (
          <AccordionItem value={questionItem.fields.question.substring(0, 50).trim()} key={questionItem.sys.id}>
            <StyledTransferConfirmation.StyledAccordionTrigger>
              <StyledTransferConfirmation.StyledAccordionHeader>{questionItem.fields.question}</StyledTransferConfirmation.StyledAccordionHeader>
            </StyledTransferConfirmation.StyledAccordionTrigger>
            <StyledTransferConfirmation.StyledAccordionContent>
              <RichTextRenderer document={questionItem.fields.answer} />
            </StyledTransferConfirmation.StyledAccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </StyledTransferConfirmation.FaqSection>
  )
}

export default FaqAccordion
