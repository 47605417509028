import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { StyledEmptyCartMessage, StyledEmptyCartMessageContainer, StyledLink } from './styledComponents'

interface EmptyCardProps {
  medicationsLink: string
}

const EmptyCart = ({ medicationsLink }: EmptyCardProps): React.ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <StyledEmptyCartMessageContainer vpTheme={theme}>
      <StyledEmptyCartMessage data-testid='empty-cart-message' vpTheme={theme}>
        Your cart is empty
      </StyledEmptyCartMessage>
      <StyledLink data-testid='empty-cart-add-meds' to={medicationsLink} vpTheme={theme}>
        Add available prescriptions
      </StyledLink>
    </StyledEmptyCartMessageContainer>
  )
}

export default EmptyCart
