import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { FFSwitchReader } from './FFSwitch'

/**
 * Class representing a LaunchDarkly context reader for feature flags (reads from LDContextWithLocalStorage).
 * Implements the FFSwitchReader interface.
 */
export class LDContextReader implements FFSwitchReader {
  /**
   * Creates an instance of LDContextReader.
   * @param ffKey - The key of the feature flag to read.
   * @param defaultValue - The default value of the feature flag if not found. Defaults to false.
   */
  constructor(private readonly ffKey: string, readonly defaultValue = false) {}

  getFFReaderHook(): () => boolean {
    const useFFReader = () => {
      const featureFlags = useLDFlagsWithLocalStorage([this.ffKey])
      return featureFlags[this.ffKey]
    }
    return useFFReader
  }
}
