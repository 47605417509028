import { Chip, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../../common/styles/variables'
import { TransferMedicationStatus } from '../types'

const Container = styled.div<{ borderColor?: string; boxShadowColor?: string }>`
  border: 1px solid ${({ borderColor }) => borderColor ?? defaultTheme.colors['gray-300']};
  border-radius: 8px;
  box-shadow: 0px 4px 4px -2px ${({ boxShadowColor }) => boxShadowColor ?? defaultTheme.colors['gray-300']};
`

const HeaderContainer = styled.div`
  padding: 1rem 1.5rem;
`

const MedNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const MedInfoTextStyle = styled(Text)`
  font-size: 0.875rem;
`

const StatusChip = styled(Chip)<{
  status: TransferMedicationStatus
  color?: string
  $backgroundPendingColor?: string
  $backgroundCompletedColor?: string
  $backgroundRejectedColor?: string
}>`
  color: ${({ color }) => color ?? defaultTheme.colors.white};
  border-radius: 20px;
  padding: 0.95rem 0.25rem;
  min-width: 95px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'PENDING':
        return props.$backgroundPendingColor ?? defaultTheme.colors['functional-info-dark']
      case 'COMPLETED':
        return props.$backgroundCompletedColor ?? defaultTheme.colors['functional-success-dark']
      case 'REJECTED':
        return props.$backgroundRejectedColor ?? defaultTheme.colors['functional-error-dark']
    }
  }};

  ${mediaLargerThan.tablet} {
    padding: 1.25rem 0.625rem;
  }
`

const TransferCardTextStyle = styled(Text)<{ fontWeight?: string; fontSize?: string; lineHeight?: string; marginBottom?: string }>`
  font-size: ${({ fontSize }) => fontSize ?? '0.75rem'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
  line-height: ${({ lineHeight }) => lineHeight ?? '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0'};
`

const PharmacyInfo = styled.div<{ borderColor?: string; fillColor?: string }>`
  padding: 1rem 1.5rem;
  border: 1px solid ${({ borderColor }) => borderColor ?? defaultTheme.colors['gray-300']};
  border-style: solid none;
  svg {
    height: 16px;
    width: 24px;
  }
  svg path {
    fill: ${({ fillColor }) => fillColor ?? defaultTheme.colors['typography-dark']};
  }
`

const TypeText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const PharmacyNumber = styled.a<{ color?: string }>`
  text-decoration: none;
  color: ${({ color }) => color ?? defaultTheme.colors['functional-info-dark']};
`

const MedInfoContainer = styled.div<{ displayAllColumns: boolean }>`
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;

  ${mediaLargerThan.tablet} {
    grid-template-columns: ${(props) => {
      if (props.displayAllColumns) return '1fr 1fr 1fr 1fr'
      return '1fr 1fr'
    }};
  }
`

const MedInfo = styled.div<{ color?: string }>`
  & p:first-child {
    color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
    font-weight: 700;
  }
`

export const StyledPrescriptionTransferCard = {
  TransferCardTextStyle,
  Container,
  HeaderContainer,
  MedNameContainer,
  StatusChip,
  PharmacyInfo,
  TypeText,
  PharmacyNumber,
  MedInfoContainer,
  MedInfo,
  MedInfoTextStyle,
}
