import { TransferInfoStatus } from '@vpharm-platform/shared'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { TRANSFER_REQUEST_PATH, TRANSFERS_OUT_PATH } from '../../../constants'
import { useCustomerProfile } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { selectedPatientTokenAtom } from '../../../persistRecoil'
import { transferService } from '../../../services'
import { TransferMedication, TransferMedicationStatus } from './types'

interface UsePrescriptionTransferReturnValues {
  transferMedications: TransferMedication[]
  handleTransferOutSuccessToast: (show: boolean) => void
  showTransferOutSuccessToast: boolean
  error: string
  loading: boolean
  dismissError: () => void
  handleTransferIn: () => void
  handleTransferOut: () => void
}

const mapTransferInfoStatus = (status: TransferInfoStatus): TransferMedicationStatus => {
  switch (status) {
    case 'COMPLETED':
      return 'COMPLETED'
    case 'FAILED':
      return 'REJECTED'
    case 'PENDING':
      return 'PENDING'
  }
}

export const usePrescriptionTransfer = (): UsePrescriptionTransferReturnValues => {
  const location = useLocation<{ fromTransferIn?: boolean; fromTransferOut?: boolean }>()
  const [loading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [transferMedications, setTransferMedications] = useState<TransferMedication[]>([])
  const { customerProfile } = useCustomerProfile()
  const patientToken = useRecoilValue(selectedPatientTokenAtom)
  const [showTransferOutSuccessToast, setShowTransferOutSuccessToast] = useState(false)
  const { trackButtonClickEvent, trackErrorShownEvent } = useAnalytics()
  const history = useHistory()

  useEffect(() => {
    const getTransferMedications = async () => {
      try {
        const transfers = await transferService.fetchTransfers(customerProfile.vpharmCustomerToken, patientToken)
        const transferMedications: TransferMedication[] = transfers.map((t) => ({
          medicationName: t.medicationName as string,
          genericName: t.genericName,
          transferToken: t.transferToken as string,
          transferDate: DateTime.fromISO(t.createdAt).toJSDate(),
          status: mapTransferInfoStatus(t.status),
          type: t.type,
          quantity: t.quantity,
          strength: t.strength,
          prescriberName: t.prescriberName,
          prescriberPhone: t.prescriberPhone,
          pharmacy: {
            address: t.pharmacy.address,
            name: t.pharmacy.name,
            phone: t.pharmacy.phoneNumber,
          },
        }))
        setTransferMedications(transferMedications)
      } catch (e) {
        trackErrorShownEvent('Unable to load prescription transfers')
        setError('Something went wrong fetching transfers')
      } finally {
        setIsLoading(false)
      }
    }

    if (loading) {
      getTransferMedications()
    }
  }, [loading, patientToken, customerProfile.vpharmCustomerToken, trackErrorShownEvent])

  const handleTransferOutSuccessToast = (show: boolean) => {
    setShowTransferOutSuccessToast(show)
  }

  const dismissError = () => {
    setError('')
  }

  const handleTransferIn = () => {
    trackButtonClickEvent('request_transfer_button', 'Start a transfer in', 'Request Transfer Button Clicked')
    history.push(TRANSFER_REQUEST_PATH)
  }

  const handleTransferOut = () => {
    trackButtonClickEvent('request_transfer_out_button', 'Start a transfer out', 'Request Transfer Out Button Clicked')
    history.push(TRANSFERS_OUT_PATH)
  }

  useEffect(() => {
    if (location.state?.fromTransferIn || location.state?.fromTransferOut) {
      setShowTransferOutSuccessToast(true)
    }
  }, [location.state?.fromTransferIn, location.state?.fromTransferOut])

  return {
    transferMedications,
    showTransferOutSuccessToast,
    loading,
    error,
    dismissError,
    handleTransferOutSuccessToast,
    handleTransferIn,
    handleTransferOut,
  }
}
