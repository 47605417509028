import { FormGroup, Spacer, Text } from '@truepill/react-capsule'
import { AvailableShippingOptionsResponse } from '@vpharm-platform/shared'
import { Shipping } from 'assets/Icons'

import { useContentfulTheme } from '../../../hooks'
import ColdChainNotification from '../../ColdChainNotification'
import { useCheckoutContext } from '../CheckoutProvider'
import ShippingMethodSelector from './ShippingMethodSelector'
import { SpecificationsWrapper } from './styledComponents'

interface Props {
  shippingOptions: AvailableShippingOptionsResponse[]
}

const ShippingMethods: React.FC<Props> = ({ shippingOptions }) => {
  const { selectedShippingMethod, orderHasColdChainMedication, orderHasControlledSubstance, setSelectedShippingMethod } = useCheckoutContext()
  const { theme } = useContentfulTheme()

  return (
    <>
      <FormGroup label='Shipping method' required>
        {orderHasColdChainMedication && <ColdChainNotification />}
        <ShippingMethodSelector
          shippingMethods={shippingOptions}
          selectedMethodId={selectedShippingMethod?.id}
          onSelectedMethod={setSelectedShippingMethod}
        />
      </FormGroup>
      {orderHasControlledSubstance && (
        <>
          <Spacer />
          <SpecificationsWrapper>
            <Shipping vpTheme={theme} />
            <Text>You have a controlled substance in your Cart and we must ship this to you via Expedited or Overnight shipping.</Text>
          </SpecificationsWrapper>
        </>
      )}
    </>
  )
}

export default ShippingMethods
