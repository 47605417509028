import React from 'react'

import { useAnalytics } from '../../../hooks/analytics-context'
import { useUserAddresses } from '../../../hooks/useUserAddresses'
import AccordionForm from '../../Body/AccordionForm'
import { useCheckoutContext } from '../CheckoutProvider'
import { EditMode } from './EditMode'
import { ViewMode } from './ViewMode'

const CheckoutShipping: React.FC = () => {
  const { addressList, isLoading, addNewAddress, updateAddress, updateDefaultAddress, deleteAddress } = useUserAddresses()
  const { checkoutStep, selectedAddress, selectedShippingMethod, selectedPayment, priceDetails, setCheckoutStep, calculateTotalTax } =
    useCheckoutContext()
  const { trackButtonClickEvent } = useAnalytics()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const enableEditMode = (_: string) => {
    setCheckoutStep('shipping')
  }

  const onCompleteSection = () => {
    trackButtonClickEvent('checkout_shipping_section_confirm', 'Confirm')
    calculateTotalTax()
    const isReadyForReview = !!selectedPayment || priceDetails.orderTotal === 0
    setCheckoutStep(isReadyForReview ? 'review' : 'payment')
  }

  const isCompleted = checkoutStep !== 'shipping' && !!selectedAddress && !!selectedShippingMethod

  return (
    <AccordionForm id={'SHIPPING'} header={'Shipping'} isOpen isSubmitted={isCompleted} editVisibility={isCompleted} onEdit={enableEditMode}>
      {isCompleted ? (
        <ViewMode />
      ) : (
        <EditMode
          addressList={addressList}
          isLoading={isLoading}
          addNewAddress={addNewAddress}
          updateAddress={updateAddress}
          updateDefaultAddress={updateDefaultAddress}
          deleteAddress={deleteAddress}
          onConfirm={onCompleteSection}
        />
      )}
    </AccordionForm>
  )
}

export default CheckoutShipping
