import { Spacer } from '@truepill/react-capsule'
import { CameraIcon, IdCardIcon, UploadIcon } from 'assets/Icons'
import { useRef, useState } from 'react'
import Webcam from 'react-webcam'

import { useContentfulTheme } from '../../hooks'
import { processImageCompression } from '../../utils'
import { BannerUploadIdProps } from './BannerUploadId'
import { IdDocumentContainer, PhotoButton, PhotoButtonContainer, UploadedImage } from './styledComponents'

export const UploadImageOrTakePhoto = ({
  image,
  setImage,
  bannerUploadIdProps,
}: {
  image: string
  setImage: React.Dispatch<React.SetStateAction<string>>
  bannerUploadIdProps: BannerUploadIdProps
}): JSX.Element => {
  const webcamRef = useRef<Webcam>(null)
  const fileUploadRef = useRef<HTMLInputElement>(null)

  const [takePhoto, setTakePhoto] = useState(false)

  const { theme } = useContentfulTheme()

  const uploadPhotoButton = () => {
    if (fileUploadRef.current) {
      setTakePhoto(false)
      fileUploadRef.current.click()
    }
  }

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0]

      try {
        setImage(await processImageCompression(file))
        bannerUploadIdProps.setIsBannerVisible(true)
        bannerUploadIdProps.setBannerText('Image successfully uploaded! ')
        bannerUploadIdProps.setBannerState('success')
      } catch (e) {
        bannerUploadIdProps.setIsBannerVisible(true)
        bannerUploadIdProps.setBannerText('Error uploading image')
        bannerUploadIdProps.setBannerState('warning')
      }
    }
  }

  const takePhotoButton = () => {
    setImage('')
    if (takePhoto) {
      bannerUploadIdProps.setIsBannerVisible(true)
      bannerUploadIdProps.setBannerText('Image successfully uploaded! ')
      bannerUploadIdProps.setBannerState('success')
      setImage(webcamRef.current?.getScreenshot() || '')
    }
    setTakePhoto(!takePhoto)
  }

  return (
    <>
      <input hidden type='file' accept='image/png, image/jpeg' ref={fileUploadRef} id='upload-button' onChange={handleInputChange} />
      <IdDocumentContainer vpTheme={theme}>
        {image === '' ? (
          takePhoto ? (
            <Webcam style={{ maxHeight: '30vh' }} ref={webcamRef} />
          ) : (
            <IdCardIcon vpTheme={theme} />
          )
        ) : (
          <UploadedImage src={image} />
        )}
      </IdDocumentContainer>
      <Spacer size='md' />
      <PhotoButtonContainer>
        <PhotoButton hidden={takePhoto} size='sm' variant='primary-outline' onClick={uploadPhotoButton} vpTheme={theme}>
          {image === '' ? 'Upload' : 'Upload a new'} photo
          <Spacer size='sm' axis='horizontal' />
          <UploadIcon vpTheme={theme} />
        </PhotoButton>
        <Spacer hidden={takePhoto} size='sm' axis='horizontal' />
        <PhotoButton size='sm' onClick={takePhotoButton} vpTheme={theme}>
          {image === '' ? 'Take' : 'Take a new'} photo
          <Spacer size='sm' axis='horizontal' />
          <CameraIcon vpTheme={theme} />
        </PhotoButton>
      </PhotoButtonContainer>
    </>
  )
}
