import { usePatientProfile, useUserProfile } from 'hooks'
import { addAccessTokenInterceptor } from 'httpClient'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useAuth } from '../../context/auth-context'
import { adminLogin, selectedPatientTokenAtom } from '../../persistRecoil'
import AdminSearchBar from '../AdminSearchBar'
import LoadingAnimation from '../LoadingAnimation'

const Profile: React.FunctionComponent = ({ children }) => {
  const { getAccessTokenSilently } = useAuth()

  addAccessTokenInterceptor(getAccessTokenSilently)
  const { loading } = useUserProfile()
  const { authState } = useAuth()
  usePatientProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const [isAdminLogin] = useRecoilState(adminLogin)

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently)
  }, [getAccessTokenSilently, selectedPatientToken])

  if (loading && authState.isAuthenticated) {
    return <LoadingAnimation />
  }

  return (
    <>
      {isAdminLogin && <AdminSearchBar />}
      {children}
    </>
  )
}

export default Profile
