import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'
import { media } from '../../common/styles/variables'

const StyledLoaderWrapper = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
`

const StyledContainer = styled.div.attrs((props: { processingText: string; subDescription: string } & ThemedComponent) => props)`
  text-align: center;
  position: absolute;
  z-index: 2;
  height: ${(props) => (props.processingText || props.subDescription ? '100%' : '60px')};
  width: ${(props) => (props.processingText || props.subDescription ? '100%' : '60px')};
  background: ${({ vpTheme }) => vpTheme.colors.pageBackground};

  @media (min-width: ${media.laptop}) {
    padding: 10em 1.5rem;
  }
`

const StyledProcessingText = styled(ThemedHeader)<ThemedComponent>`
  && {
    line-height: 32px;

    @media (min-width: ${media.laptop}) {
      line-height: 40px;
    }
  }
`

const StyledText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-medium']};
  font-family: Lato, serif;
`

export { StyledContainer, StyledLoaderWrapper, StyledProcessingText, StyledText }
