import { isAnEmptyString } from '@vpharm-platform/shared'

import { PatientAddress, SavedPatientAddress } from '../../../../interfaces'
import { userService } from '../../../../services'

export async function onEditSubmit(address: SavedPatientAddress, customerToken: string): Promise<void> {
  if (!validateAddress(address)) {
    return
  }

  await userService.updateUserAddress(address, customerToken)
}

function validateAddress(address: PatientAddress): boolean {
  if (
    isAnEmptyString(address.name) ||
    isAnEmptyString(address.address1) ||
    isAnEmptyString(address.city) ||
    isAnEmptyString(address.state) ||
    isAnEmptyString(address.zip)
  ) {
    return false
  }

  return true
}

export function sortAddresses(addresses: PatientAddress[]): PatientAddress[] {
  const sortedList = addresses
    ?.sort((x, y) => {
      if (x.name < y.name) return -1
      if (x.name > y.name) return 1
      return 0
    })
    .sort(function (x: PatientAddress, y: PatientAddress) {
      return x.isDefault === y.isDefault ? 0 : x.isDefault ? -1 : 1
    })

  return sortedList
}

export const formatAddressToSingleLine = (address: PatientAddress): string => {
  if (address.address1.length > 0 || !!address.address2 || address.city.length > 0 || address.state.length > 0 || address.zip.length > 0) {
    return `${address.address1} ${address.address2 ?? ''} ${address.city}, ${address.state}, ${address.zip}`
  }
  return ''
}
