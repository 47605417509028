import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const MenuIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18H21'
        stroke={vpTheme.colors.navAvatarBg ?? defaultTheme.colors.navAvatarBg}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 12H21'
        stroke={vpTheme.colors.navAvatarBg ?? defaultTheme.colors.navAvatarBg}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 6H21'
        stroke={vpTheme.colors.navAvatarBg ?? defaultTheme.colors.navAvatarBg}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
