import { Text, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'

const MainContainer = styled.div`
  width: 100%;
  max-width: 520px;
  height: calc(100vh - 4.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`

const Form = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

const Subtext = styled(Text)`
  color: ${theme.colors['typography-medium'].computedValue};
  margin: 1.5rem 0;
`

const Logo = styled.img`
  max-width: 250px;
`

const MainHeader = styled(ThemedHeader)<ThemedComponent>`
  margin: 0 0 0.75rem 0;
`

const ForgotPasswordButtonContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`

export const StyledForgotPassword = {
  MainContainer,
  Form,
  Subtext,
  Logo,
  MainHeader,
  ForgotPasswordButtonContainer,
}
