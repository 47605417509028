import { LoadingSpinner } from '@truepill/react-capsule'
import React from 'react'

import { ISpinnerProps, LoadingSpinnerWrapper } from './styledComponents'

const LoadingAnimation: React.FC<ISpinnerProps> = (props: ISpinnerProps) => {
  return (
    <LoadingSpinnerWrapper
      wrapperStyle={{ height: props.wrapperStyle?.height, width: props.wrapperStyle?.width }}
      backgroundColor={props.backgroundColor}
    >
      <LoadingSpinner {...props} />
    </LoadingSpinnerWrapper>
  )
}

export default LoadingAnimation
