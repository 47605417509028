import React from 'react'

import useUserPayments from '../../../hooks/useUserPayments'
import AccordionForm from '../../Body/AccordionForm'
import { useCheckoutContext } from '../CheckoutProvider'
import { EditMode } from './EditMode'
import { ViewMode } from './ViewMode'

const CheckoutPayment: React.FC = () => {
  const { checkoutStep, selectedPayment, priceDetails, setCheckoutStep } = useCheckoutContext()
  const { paymentMethods, isLoading, addPaymentMethod, setDefaultPaymentMethod, deletePaymentMethod } = useUserPayments()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const enableEditMode = (_: string) => {
    setCheckoutStep('payment')
  }

  const onCompleteSection = () => {
    setCheckoutStep('review')
  }

  const isOpen = checkoutStep === 'payment'
  const isCompleted = checkoutStep === 'review' && (!!selectedPayment || priceDetails.orderTotal === 0)
  const isEditable = isCompleted && priceDetails.orderTotal !== 0

  return (
    <AccordionForm id={'PAYMENT'} header={'Payment'} isOpen={isOpen} isSubmitted={isCompleted} editVisibility={isEditable} onEdit={enableEditMode}>
      {isCompleted ? (
        <ViewMode />
      ) : (
        <EditMode
          paymentMethods={paymentMethods}
          isLoading={isLoading}
          addPaymentMethod={addPaymentMethod}
          setDefaultPaymentMethod={setDefaultPaymentMethod}
          deletePaymentMethod={deletePaymentMethod}
          onConfirm={onCompleteSection}
        />
      )}
    </AccordionForm>
  )
}

export default CheckoutPayment
