import { PrescriptionAvailabilityResponse, PrescriptionDetail, sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { axiosAuth } from 'httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface PrescriptionService {
  fetchPrescriptionsGroupedByAvailability(vpharmCustomerToken: string, truepillPatientToken: string): Promise<PrescriptionAvailabilityResponse>
  fetchPrescription(prescriptionToken: string, truepillPatientToken: string, vpharmCustomerToken: string): Promise<PrescriptionAvailabilityResponse>
  fetchPrescriptionStatus(prescriptionToken: string, truepillPatientToken: string, vpharmCustomerToken: string): Promise<PrescriptionDetail>
  quantityWithUnits(quantity?: number, quantityUnits?: string): string
}

class PrescriptionApiServer implements PrescriptionService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/prescriptions`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async fetchPrescriptionsGroupedByAvailability(
    vpharmCustomerToken: string,
    truepillPatientToken: string,
  ): Promise<PrescriptionAvailabilityResponse> {
    const url = `${this.baseUrl}/group/availability`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: truepillPatientToken,
      },
    }

    try {
      const response = await axiosAuth.get<PrescriptionAvailabilityResponse>(url, config)
      return response.data || <PrescriptionAvailabilityResponse>{}
    } catch (err) {
      throw new Error(`Error in ${url}`)
    }
  }

  async fetchPrescription(
    prescriptionToken: string,
    truepillPatientToken: string,
    vpharmCustomerToken: string,
  ): Promise<PrescriptionAvailabilityResponse> {
    const url = `${this.baseUrl}/${prescriptionToken}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: truepillPatientToken,
      },
    }

    try {
      const response = await axiosAuth.get<PrescriptionAvailabilityResponse>(url, config)
      return response.data
    } catch (err) {
      throw new Error(`Unable to fetch prescription info`)
    }
  }

  async fetchPrescriptionStatus(prescriptionToken: string, truepillPatientToken: string, vpharmCustomerToken: string): Promise<PrescriptionDetail> {
    const url = `${this.baseUrl}/status/${prescriptionToken}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: truepillPatientToken,
      },
    }

    try {
      const response = await axiosAuth.get<PrescriptionDetail>(url, config)
      return response.data
    } catch (err) {
      throw new Error(`Unable to fetch prescription info`)
    }
  }

  quantityWithUnits(quantity?: number, quantityUnits?: string): string {
    if (!quantity) return ''

    return `${quantity} ${quantityUnits}`.trim()
  }
}

export const prescriptionService: PrescriptionService = new PrescriptionApiServer()
