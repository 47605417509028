import { Header, Spacer } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import { useContentfulTheme } from '../../hooks'
import { SelfEnrollmentBannerMicrocopy } from '../../hooks/contentful/types/microcopy'
import {
  ContentContainer,
  ContentHeader,
  ContentStepContainer,
  ContentSubtext,
  CtaButton,
  CtaContainer,
  HeaderContainer,
  Rectangle,
  SelfEnrollmentBannerContainer,
  StyledInfoOne,
  StyledMainHeader,
  StyledSubHeader,
  TextContainer,
} from './styledComponents'

interface SelfEnrollmentBannerProps extends SelfEnrollmentBannerMicrocopy {
  bannerScrollRef: React.MutableRefObject<HTMLDivElement | null>
}

const ContentStep = ({ stepHeader, stepSubtext, vpTheme }: { stepHeader: string; stepSubtext?: string; vpTheme: VpTheme }) => (
  <ContentStepContainer vpTheme={vpTheme}>
    {stepHeader && <Header>{stepHeader}</Header>}
    {stepSubtext && <ContentSubtext>{stepSubtext}</ContentSubtext>}
  </ContentStepContainer>
)

const SelfEnrollmentBanner = ({
  mainHeader,
  subHeader,
  infoOne,
  contentHeader,
  stepOne,
  stepOneSubtext,
  stepTwo,
  stepTwoSubtext,
  stepThree,
  stepThreeSubtext,
  stepFour,
  stepFourSubtext,
  ctaButtonText,
  bannerScrollRef,
}: SelfEnrollmentBannerProps): React.ReactElement => {
  const { theme } = useContentfulTheme()
  return (
    <SelfEnrollmentBannerContainer>
      <HeaderContainer vpTheme={theme}>
        <TextContainer>
          {mainHeader && <StyledMainHeader variant='3xl'>{mainHeader}</StyledMainHeader>}
          {subHeader && (
            <StyledSubHeader variant='xl' vpTheme={theme}>
              {subHeader}
            </StyledSubHeader>
          )}
          {infoOne && (
            <>
              <Spacer size='md' />
              <StyledInfoOne variant='xl' vpTheme={theme}>
                {infoOne}
              </StyledInfoOne>
            </>
          )}
        </TextContainer>
        <Rectangle />
      </HeaderContainer>
      <ContentContainer vpTheme={theme}>
        {contentHeader && (
          <ContentHeader variant='2xl' vpTheme={theme}>
            {contentHeader}
          </ContentHeader>
        )}
        {stepOne && <ContentStep stepHeader={stepOne} stepSubtext={stepOneSubtext} vpTheme={theme} />}
        {stepTwo && <ContentStep stepHeader={stepTwo} stepSubtext={stepTwoSubtext} vpTheme={theme} />}
        {stepThree && <ContentStep stepHeader={stepThree} stepSubtext={stepThreeSubtext} vpTheme={theme} />}
        {stepFour && <ContentStep stepHeader={stepFour} stepSubtext={stepFourSubtext} vpTheme={theme} />}
        {ctaButtonText && (
          <CtaContainer>
            <CtaButton onClick={() => bannerScrollRef.current?.scrollIntoView({ behavior: 'smooth' })} vpTheme={theme}>
              {ctaButtonText}
            </CtaButton>
          </CtaContainer>
        )}
      </ContentContainer>
    </SelfEnrollmentBannerContainer>
  )
}

export default SelfEnrollmentBanner
