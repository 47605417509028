import { ReactElement, useState } from 'react'

import { assetStorageUrl, vppStaticAssetsPath } from '../../../constants/gcpAssets'
import { useContentfulTheme } from '../../../hooks'
import { InfoBox, SampleCardLink, SampleInsuranceCardModal } from './styledComponents'

export const SampleInsuranceCard = (): ReactElement => {
  const [showSampleInsuranceCard, setShowSampleInsuranceCard] = useState(false)
  const { theme: vpTheme } = useContentfulTheme()

  const handleShowSampleInsuranceCard = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowSampleInsuranceCard(true)
  }

  return (
    <>
      <InfoBox vpTheme={vpTheme}>
        <h4>Where can I find this?</h4>
        <p>
          Typically your prescription insurance card will have an “Rx” symbol, and “BIN” and “PCN” numbers.
          {assetStorageUrl && vppStaticAssetsPath ? (
            <SampleCardLink onClick={handleShowSampleInsuranceCard} vpTheme={vpTheme}>
              Show sample card
            </SampleCardLink>
          ) : (
            <></>
          )}
        </p>
      </InfoBox>
      <SampleInsuranceCardModal
        isOpen={showSampleInsuranceCard}
        onDismiss={() => setShowSampleInsuranceCard(false)}
        aria-label={'sample insurance card'}
      >
        <img src={assetStorageUrl + vppStaticAssetsPath + '/sample_insurance_card.png'} alt='Sample insurance card' />
      </SampleInsuranceCardModal>
    </>
  )
}
