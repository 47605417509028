import axios from 'axios'

export interface AuthService {
  sendPasswordResetEmail(tenantUrl: string, clientId: string, email: string): Promise<void>
}

class AuthApiService implements AuthService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/auth`

  async sendPasswordResetEmail(tenantUrl: string, clientId: string, email: string): Promise<void> {
    const url = `https://${tenantUrl}/dbconnections/change_password`
    await axios.post(
      url,
      {
        client_id: clientId,
        email,
        connection: 'Username-Password-Authentication',
      },
      {},
    )
  }
}

export const authService: AuthService = new AuthApiService()
