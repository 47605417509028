import { InsurancePrescriptionsResponse } from '@vpharm-platform/shared'

export const insuranceMockData: InsurancePrescriptionsResponse[] = [
  {
    memberId: 'fakecardholderid103',
    pcn: 'fakepcn',
    rxBin: '000002',
    rxGroup: 'fakegroup5',
  },
]
