import { Spacer, Text } from '@truepill/react-capsule'
import { isPostOfficeBoxAddress } from '@vpharm-platform/shared'
import React, { useEffect, useState } from 'react'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { useShippingMethods } from '../../../hooks/useShippingMethods'
import { Result } from '../../../hooks/useUserAddresses'
import { PatientAddress } from '../../../interfaces'
import { AddAddressResponse } from '../../../services'
import AddressForm from '../../Addresses/AddressForm'
import SavedAddresses from '../../Addresses/SavedAddresses'
import { useCheckoutContext } from '../CheckoutProvider'
import { AddressInfoView } from './AddressInfoView'
import ShippingMethods from './ShippingMethods'
import { AddressViewMode, EditButton, StyledBannerAlert, StyledText, SubmitButtonContainer } from './styledComponents'

interface Props {
  addressList: PatientAddress[]
  isLoading: boolean
  addNewAddress: (address: PatientAddress) => Promise<Result<AddAddressResponse>>
  updateAddress: (address: PatientAddress) => Promise<Result<boolean | null>>
  updateDefaultAddress: (address: PatientAddress) => Promise<Result<boolean>>
  deleteAddress: (address: PatientAddress) => Promise<Result<boolean>>
  onConfirm: () => void
}

export const EditMode: React.FC<Props> = ({
  addressList,
  isLoading,
  addNewAddress,
  updateAddress,
  updateDefaultAddress,
  deleteAddress,
  onConfirm,
}) => {
  const [isAddressSelected, setIsAddressSelected] = useState(false)
  const [warningAlert, setWarningAlert] = useState<string | null>(null)
  const { shippingOptions, isLoading: isLoadingShippingOptions, fetchShippingOptions } = useShippingMethods()
  const {
    selectedAddress,
    selectedShippingMethod,
    orderHasColdChainMedication,
    orderHasControlledSubstance,
    medicationsInCart,
    setSelectedAddress,
    setSelectedShippingMethod,
  } = useCheckoutContext()
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  useEffect(() => {
    const defaultAddress = addressList.find((address) => address.isDefault)
    if (defaultAddress && !selectedAddress) {
      setSelectedAddress(defaultAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList.length])

  const isPoBoxRestricted = (address1: string) => {
    return (orderHasColdChainMedication || orderHasControlledSubstance) && isPostOfficeBoxAddress(address1)
  }

  const enableEditAddress = () => {
    trackButtonClickEvent('checkout_shipping_section_edit', 'Edit')
    setIsAddressSelected(false)
  }

  const handleAddAddress = async (address: PatientAddress): Promise<Result<AddAddressResponse>> => {
    trackButtonClickEvent('checkout_shipping_section_add_address', 'Add Address')
    if (isPoBoxRestricted(address.address1)) {
      return { error: 'Some medications in your cart do not allow shipping to PO BOX addresses.' }
    }
    const result = await addNewAddress(address)
    if (result.data) {
      const newAddress = { ...address, id: result.data.createResult.addressId }
      setSelectedAddress(newAddress)
      handleConfirmSelectedAddress(newAddress)
    }
    return result
  }

  const handleSetDefaultAddress = async (address: PatientAddress): Promise<Result<boolean>> => {
    const result = await updateDefaultAddress(address)
    if (result.data) {
      handleSelectAddress(address)
    }
    return result
  }

  const handleDeleteAddress = async (address: PatientAddress): Promise<Result<boolean>> => {
    const result = await deleteAddress(address)
    if (result.data && selectedAddress?.id === address.id) {
      setSelectedAddress(null)
    }
    return result
  }

  const handleEditAddress = async (address: PatientAddress) => {
    const result = await updateAddress(address)
    setSelectedAddress(null)
    return result
  }

  const handleSelectAddress = (address: PatientAddress) => {
    if (isPoBoxRestricted(address.address1)) {
      setWarningAlert('Some medications in your cart do not allow shipping to PO BOX addresses.')
      setSelectedAddress(null)
      return
    }
    setSelectedAddress(address)
  }

  const handleConfirmSelectedAddress = (address: PatientAddress | null) => {
    if (!address) {
      return
    }

    const { address1, address2, state, city, zip } = address

    if (isPoBoxRestricted(address1)) {
      setWarningAlert('Some medications in your cart do not allow shipping to PO BOX addresses.')
      return
    }

    setIsAddressSelected(true)
    setSelectedShippingMethod(null)
    fetchShippingOptions({
      controlled_substance: orderHasControlledSubstance,
      delivery_address: `${address1} ${address2 ? `${address2} ${zip}` : zip} ${state} ${city}`,
      delivery_state_code: state.trim().toUpperCase(),
      cold_chain: orderHasColdChainMedication,
      prescription_tokens: medicationsInCart.map((med) => med.prescriptionToken),
    })
  }

  if (isLoading) {
    return (
      <div>
        <Text>Loading addresses...</Text>
      </div>
    )
  }

  return (
    <>
      {warningAlert && (
        <>
          <StyledBannerAlert state={'warning'} timeout={5000} visible={!!warningAlert} onTimeout={() => setWarningAlert(null)}>
            {warningAlert}
          </StyledBannerAlert>
          <Spacer />
        </>
      )}
      {isAddressSelected ? (
        <>
          <AddressViewMode vpTheme={theme}>
            <EditButton bold variant='body' className='underline' onClick={enableEditAddress}>
              Edit
            </EditButton>
            <AddressInfoView />
          </AddressViewMode>
          {isLoadingShippingOptions ? <Text>Loading shipping methods...</Text> : <ShippingMethods shippingOptions={shippingOptions} />}
          <Spacer size='xl' />
          <SubmitButtonContainer>
            <ThemedButton
              onClick={() => {
                trackButtonClickEvent('checkout_shipping_section_confirm', 'Confirm')
                onConfirm()
              }}
              disabled={!selectedShippingMethod}
              vpTheme={theme}
            >
              Confirm
            </ThemedButton>
          </SubmitButtonContainer>
        </>
      ) : addressList.length === 0 ? (
        <AddressForm onSubmit={handleAddAddress} askShouldSave />
      ) : (
        <>
          <StyledText vpTheme={theme}>A shipping address is required for delivery. Select or add a new address below.</StyledText>
          <Spacer />
          <SavedAddresses
            isCheckoutFlow
            addressList={addressList}
            maxAddressesDisplayed={3}
            selectedAddress={selectedAddress}
            onSelectedAddress={handleSelectAddress}
            onAddAddress={handleAddAddress}
            onEditAddress={handleEditAddress}
            onSetDefaultAddress={handleSetDefaultAddress}
            onDeleteAddress={handleDeleteAddress}
          />
          <Spacer size='lg' />
          <SubmitButtonContainer>
            <ThemedButton onClick={() => handleConfirmSelectedAddress(selectedAddress)} disabled={!selectedAddress} vpTheme={theme}>
              Confirm
            </ThemedButton>
          </SubmitButtonContainer>
        </>
      )}
    </>
  )
}
