import { SEO, Spacer } from '@truepill/react-capsule'
import ToastMessage from 'Components/ToastMessage'
import { useContentfulTheme, useCustomerProfile } from 'hooks/'
import { selectedPatientTokenAtom } from 'persistRecoil'
import { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'

import { Modal } from '../../../common/styledComponents/StyledModal'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { AccountLayout } from '../../../Components/PageLayoutWithSidebar'
import { AccountPage } from '../../../constants'
import { useAnalytics } from '../../../hooks/analytics-context'
import Container from '../Container'
import StyledHeader from '../StyledHeader'
import AccountEditEmailModalContent from './AccountEditEmailModalContent'
import AccountEditPhoneModalContent from './AccountEditPhoneModalContent'
import AccountEmailContent from './AccountEmailContent'
import AccountInformationContent from './AccountInformationContent'
import AccountTextSmsContent from './AccountTextSmsContent'
import { StyledModal } from './styledComponents'
import useAccountSettings from './useAccountSettings'

const AccountSettings = (): ReactElement => {
  const { customerProfile } = useCustomerProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { trackButtonClickEvent, trackToggleEvent } = useAnalytics()
  const { pharmCustomer } = useCustomerConfigContext()
  const { theme } = useContentfulTheme()

  // Provide customer and patient token you want to act under.
  const {
    isLoading,
    accountProfile,
    updateCommunicationOptIn,
    bannerState,
    setBannerState,
    sendPasswordResetEmail,
    error,
    setError,
    modalState,
    setPhoneModalState,
    setEmailModalState,
    updatePhoneNumber,
    emailUpdatingRequest,
    isEmailAddressAvailable,
  } = useAccountSettings(customerProfile.vpharmCustomerToken, selectedPatientToken)

  if (isLoading) {
    return <LoadingAnimation />
  }

  const communicationSuccessBannerMessage = accountProfile?.communicationOptIn
    ? 'You have successfully opted in to receive SMS updates about your prescriptions'
    : 'You have successfully opted out of SMS updates about your prescriptions'

  return (
    <>
      <AccountLayout selected={AccountPage.Settings}>
        <SEO title={`${pharmCustomer?.displayName} - Account Management`} useDefaults />
        <Container>
          <StyledHeader>
            <ThemedHeader vpTheme={theme} variant='4xl' data-testid='address-title'>
              Settings
            </ThemedHeader>
          </StyledHeader>
          <Spacer size='xl' />
          <AccountInformationContent
            email={accountProfile?.email}
            phoneNumber={accountProfile?.phone}
            changePasswordHandler={() => {
              trackButtonClickEvent('send_new_password_email', 'Change password', 'sends a change password email')
              sendPasswordResetEmail(accountProfile?.email as string)
            }}
            contentfulPhoneToolTipInfo={'We will only use this to contact you in case there is an urgent issue with your prescriptions or orders.'}
            editPhoneNumberHandler={() => {
              trackButtonClickEvent('open_edit_phone_modal', 'Edit', 'opens edit phone modal')
              setPhoneModalState(true)
            }}
            editEmailHandler={() => {
              setEmailModalState(true)
              trackButtonClickEvent('open_edit_email_modal', 'Edit email', 'opens edit email modal')
            }}
          />
          <Spacer size='xl' />
          <AccountEmailContent
            email={accountProfile?.email}
            contentfulEmailInfo={'Emails about your account and orders are important. We send those even if you have opted out of marketing emails.'}
          />
          <Spacer size='xl' />
          <AccountTextSmsContent
            isOptIn={accountProfile?.communicationOptIn || false}
            handleCommunicationOptIn={(optIn: boolean) => {
              const sms_opt_in_out_event = optIn ? 'sms_opt_in' : 'sms_opt_out'
              trackToggleEvent(sms_opt_in_out_event, optIn)
              updateCommunicationOptIn(optIn)
            }}
            contentfulSmsToggleInfo={'Receive account, prescription, and order updates by text message '}
          />
        </Container>
      </AccountLayout>
      {bannerState.showSuccessCommunicationBanner && !error && (
        <ToastMessage
          state='success'
          timeout={5000}
          icon={true}
          className='toast-message'
          position={{ vertical: 'top', horizontal: 'center' }}
          color='pastel'
          borderLeft={true}
          visible={bannerState.showSuccessCommunicationBanner}
          onTimeout={() => setBannerState((prev) => ({ ...prev, showSuccessCommunicationBanner: false }))}
          onDismiss={() => setBannerState((prev) => ({ ...prev, showSuccessCommunicationBanner: false }))}
        >
          {communicationSuccessBannerMessage}
        </ToastMessage>
      )}

      {bannerState.showSuccessPasswordResetBanner && !error && (
        <ToastMessage
          state='success'
          timeout={5000}
          icon={true}
          className='toast-message'
          position={{ vertical: 'top', horizontal: 'center' }}
          color='pastel'
          borderLeft={true}
          visible={bannerState.showSuccessPasswordResetBanner}
          onTimeout={() => setBannerState((prev) => ({ ...prev, showSuccessPasswordResetBanner: false }))}
          onDismiss={() => setBannerState((prev) => ({ ...prev, showSuccessPasswordResetBanner: false }))}
        >
          {'Successfully sent a password reset email. Please check your email to reset your password.'}
        </ToastMessage>
      )}

      {bannerState.showSuccessPhoneNumberBanner && !error && (
        <ToastMessage
          state='success'
          timeout={5000}
          icon={true}
          className='toast-message'
          position={{ vertical: 'top', horizontal: 'center' }}
          color='pastel'
          borderLeft={true}
          visible={bannerState.showSuccessPhoneNumberBanner}
          onTimeout={() => setBannerState((prev) => ({ ...prev, showSuccessPhoneNumberBanner: false }))}
          onDismiss={() => setBannerState((prev) => ({ ...prev, showSuccessPhoneNumberBanner: false }))}
        >
          You have successfully changed your phone number
        </ToastMessage>
      )}
      {bannerState.showSuccessEmailUpdatingRequestBanner && !error && (
        <ToastMessage
          state='success'
          timeout={8000}
          icon={true}
          className='toast-message'
          position={{ vertical: 'top', horizontal: 'center' }}
          color='pastel'
          borderLeft={true}
          visible={bannerState.showSuccessEmailUpdatingRequestBanner}
          onTimeout={() => setBannerState((prev) => ({ ...prev, showSuccessEmailUpdatingRequestBanner: false }))}
          onDismiss={() => setBannerState((prev) => ({ ...prev, showSuccessEmailUpdatingRequestBanner: false }))}
        >
          An email change verification has been sent. Please follow the instructions in the email to confirm.
        </ToastMessage>
      )}
      {error && (
        <ToastMessage
          borderLeft={true}
          color='pastel'
          className='toast-message'
          icon={true}
          onDismiss={() => setError('')}
          position={{ vertical: 'top', horizontal: 'center' }}
          state={'error'}
          visible={!!error}
          timeout={5000}
          onTimeout={() => setError('')}
        >
          {error}
        </ToastMessage>
      )}
      <StyledModal
        isOpen={modalState.isPhoneNumberModalOpen}
        onDismiss={() => {
          trackButtonClickEvent('close_edit_phone_modal', 'x', 'closes edit phone modal')
          setPhoneModalState(false)
        }}
        showCloseButton
        aria-label='edit phone number'
      >
        <AccountEditPhoneModalContent
          onPhoneNumberEditSubmit={updatePhoneNumber}
          dismissModal={() => {
            trackButtonClickEvent('cancel_edit_phone', 'cancel', 'closes edit phone modal')
            setPhoneModalState(false)
          }}
          phoneNumber={accountProfile?.phone}
        />
      </StyledModal>
      <Modal
        isOpen={modalState.isEmailModalOpen}
        onDismiss={() => {
          setEmailModalState(false)
          trackButtonClickEvent('close_edit_email_modal', 'x', 'closes edit email modal')
        }}
        showCloseButton
        aria-label='edit email'
      >
        <AccountEditEmailModalContent
          onEmailEditSubmit={emailUpdatingRequest}
          isEmailAddressAvailable={isEmailAddressAvailable}
          dismissModal={() => {
            trackButtonClickEvent('cancel_edit_email', 'cancel', 'closes edit email modal')
            setEmailModalState(false)
          }}
          email={accountProfile?.email}
        />
      </Modal>
    </>
  )
}

export default AccountSettings
