import SpinnerScreen from 'Components/SpinnerScreen'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { LOGIN_PATH } from '../../constants'

const ResetPassword: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    const redirectToSignIn = () => {
      history.push({ pathname: LOGIN_PATH, state: { fromResetPassword: true } })
    }
    redirectToSignIn()
  }, [history])

  return <SpinnerScreen />
}

export default ResetPassword
