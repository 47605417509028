import { Header, Spacer, Text } from '@truepill/react-capsule'
import { ReactElement } from 'react'
import styled from 'styled-components/macro'

import { mediaLargerThan } from '../common/styles/variables'

export const ErrorComponent = (): ReactElement => {
  return (
    <TopContainer>
      <ShadowedContainer>
        <Header>There was an issue</Header>
        <Spacer size='lg' />
        <Text style={{ color: '#AF5304', fontWeight: '700' }}>Please give us a few moments</Text>
        <Text>Please try refreshing your browser. If you continue to see the issue, please contact our support team.</Text>
      </ShadowedContainer>
    </TopContainer>
  )
}

const ShadowedContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0 8px 16px -6px #18274b14, 10px 6px 8px #18274b1f;
  left: 50%;
  margin: auto;
  padding: 24px;
  position: absolute;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 85%;

  ${mediaLargerThan.tablet} {
    top: 40%;
    width: 460px;
  }
`

const TopContainer = styled.div`
  min-height: calc(100vh - 4.5rem);
  position: relative;
  width: 100%;
`
