import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import User, { UserPatientWithUsers } from '../interfaces/User'

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
})

export interface SelectedUserPatientWithUsersState {
  patientWithUsers: UserPatientWithUsers | undefined
  selectedUser: User | undefined
}

export const patientWithUsers = atom<SelectedUserPatientWithUsersState | undefined>({
  key: 'patientWithUsers',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
})
