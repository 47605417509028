import { GetTransferPharmaciesResponse } from '@vpharm-platform/shared'
import { useCallback, useEffect, useState } from 'react'

import { transferOutService } from '../services/transferOutService'
import { useShowError } from './useShowError'

interface ReturnValues {
  loading: boolean
  transferInPharmacies?: GetTransferPharmaciesResponse[]
}

export const useTransferInPharmacy = (customerToken: string): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showError } = useShowError()
  const [transferInPharmacies, setTransferInPharmacies] = useState<GetTransferPharmaciesResponse[]>()

  const getTransferRequestList = useCallback(async () => {
    try {
      setLoading(true)
      const pharmacies = await transferOutService.getTransferInPharmacies(customerToken)

      setTransferInPharmacies(pharmacies)
    } catch (e) {
      showError()
    } finally {
      setLoading(false)
    }
  }, [customerToken, showError])

  useEffect(() => {
    getTransferRequestList()
  }, [getTransferRequestList])

  return {
    transferInPharmacies: transferInPharmacies,
    loading,
  }
}
