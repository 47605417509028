import { MedicationInfo } from '../prescriptions/medicationInfo'

export default class InsurancePrescriptionsResponse {
  insuranceName?: string | null
  insuranceToken?: string | null
  memberId!: string
  rxGroup!: string
  rxBin!: string
  pcn!: string
  prescriptions?: MedicationInfo[] | null
}
