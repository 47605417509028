import { Header, Spacer, Text } from '@truepill/react-capsule'
import { ReactElement } from 'react'
import styled from 'styled-components/macro'

import { mediaLargerThan } from '../common/styles/variables'

export const BigErrorComponent = (): ReactElement => {
  return (
    <TopContainer>
      <CenteredContainer>
        <Illustration alt='System issue illustration' src='https://storage.googleapis.com/vpp-static-assets/system_issue_illustration.svg' />
        <Header>We need a few moments</Header>
        <Spacer size='lg' />
        <Text style={{ textAlign: 'center' }}>
          We’re currently experiencing a technical issue. Our team is actively working to resolve it, and we expect the site to be fully operational
          again shortly.
          <br />
          <br />
          Please refresh the page or check back a little later today.
          <br />
          <br />
          Sorry for this inconvenience.
        </Text>
      </CenteredContainer>
    </TopContainer>
  )
}

const Illustration = styled.img`
  width: 150px;

  ${mediaLargerThan.tablet} {
    width: 192px;
  }
`

const CenteredContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px;
  top: 20%;
  width: 87%;

  ${mediaLargerThan.tablet} {
    width: 460px;
  }
`

const TopContainer = styled.div`
  min-height: calc(100vh - 4.5rem);
  position: relative;
  width: 100%;
`
