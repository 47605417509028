import styled from 'styled-components'

import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

const FormContainer = styled.div`
  min-height: 850px;

  ${mediaLargerThan.tablet} {
    padding: 0 1.5rem 0 1.5rem;
    max-width: 800px;
    width: 100%;
  }
`

const LoadingIndicatorWrapper = styled.div<ThemedComponent>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  opacity: 0.2;
  z-index: 1000;
`

export { FormContainer, LoadingIndicatorWrapper }
