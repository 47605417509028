import { GetSavingCardResponse, PrescriptionAvailabilityResponse } from '@vpharm-platform/shared'
import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import { patientInsurance, patientSavingsCards, selectedPatientTokenAtom } from 'persistRecoil'
import { useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilStateLoadable, useRecoilValue } from 'recoil'
import { patientInsuranceService } from 'services/patientInsuranceService'
import { savingsCardService } from 'services/SavingsCardService'

import { orderModeAtom } from '../persistRecoil/orderMode'
import { prescriptionsByAvailability } from '../recoil/atoms'
import { HydratedSavingsCard, hydrateSavingsCard } from '../utils/savingsCardUtils'
import { useCustomerProfile, usePatientProfile, useRefreshPrescriptionList, useUpdateCart } from '.'

export interface UsePatientInsurance {
  isErrorLoading: boolean
  isLoadingInsurance: boolean
  isDeletingInsurance: boolean
  patientInsuranceList?: PatientInsurance[]
  savingsCardList?: HydratedSavingsCard[]
  submitPatientInsurance: (values: PatientInsuranceSubmitPayload, id?: string) => Promise<boolean>
  deletePatientInsurance: (id: string) => Promise<boolean>
  setShouldRefetchPatientInsurance: () => void
  prescriptions: PrescriptionAvailabilityResponse
}

export const usePatientInsurance = (): UsePatientInsurance => {
  const updateCart = useUpdateCart()
  const { customerProfile } = useCustomerProfile()
  const { resetPatientProfile } = usePatientProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const [patientInsuranceList, setPatientInsuranceList] = useRecoilState<PatientInsurance[] | undefined>(patientInsurance)
  const [savingsCardList, setSavingsCardList] = useRecoilState<GetSavingCardResponse[] | undefined>(patientSavingsCards)
  const orderModeRefresh = useRecoilRefresher_UNSTABLE(orderModeAtom)
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const [prescriptionListLoadable] = useRecoilStateLoadable(prescriptionsByAvailability)
  const [isLoadingInsurance, setIsLoadingInsurance] = useState<boolean>(false)
  const [isDeletingInsurance, setIsDeletingInsurance] = useState<boolean>(false)
  const [shouldRefetchPatientInsurance, setShouldRefetchPatientInsurance] = useState<boolean>(false)
  const [isErrorLoading, setIsErrorLoading] = useState<boolean>(false)

  const prescriptions = prescriptionListLoadable.contents as PrescriptionAvailabilityResponse

  const hydratedSavingsCard = hydrateSavingsCard(savingsCardList, prescriptions)

  const submitPatientInsurance = async (values: PatientInsuranceSubmitPayload, truepillInsuranceToken?: string): Promise<boolean> => {
    let result = true
    try {
      await patientInsuranceService.submitPatientInsurance(customerProfile.vpharmCustomerToken, selectedPatientToken, values, truepillInsuranceToken)
      setShouldRefetchPatientInsurance(true)
      updateCart.clearCart()
      resetPatientProfile()
    } catch (e) {
      result = false
    } finally {
      orderModeRefresh()
      refreshPrescriptionList()
    }
    return result
  }

  const deletePatientInsurance = async (id: string): Promise<boolean> => {
    let result = true
    setIsDeletingInsurance(true)
    try {
      await patientInsuranceService.deletePatientInsurance(customerProfile.vpharmCustomerToken, selectedPatientToken, id)
      setShouldRefetchPatientInsurance(true)
      updateCart.clearCart()
      resetPatientProfile()
    } catch (e) {
      result = false
    } finally {
      orderModeRefresh()
      refreshPrescriptionList()
      setIsDeletingInsurance(false)
    }
    return result
  }

  useEffect(() => {
    async function fetchPatientInsurance() {
      setShouldRefetchPatientInsurance(false)
      setIsErrorLoading(false)
      if (selectedPatientToken && !isLoadingInsurance) {
        try {
          setIsLoadingInsurance(true)
          const patientInsuranceResponse = await patientInsuranceService.getPatientInsurances(
            customerProfile.vpharmCustomerToken,
            selectedPatientToken,
          )
          const patientSavingsCards = await savingsCardService.getAllSavingsCards(selectedPatientToken, customerProfile.vpharmCustomerToken)
          setPatientInsuranceList(patientInsuranceResponse)
          setSavingsCardList(patientSavingsCards)
        } catch (e) {
          setIsErrorLoading(true)
          setPatientInsuranceList([])
        } finally {
          setIsLoadingInsurance(false)
          setShouldRefetchPatientInsurance(false)
        }
      }
    }

    if (typeof patientInsuranceList === 'undefined' || shouldRefetchPatientInsurance) {
      fetchPatientInsurance()
    }
  }, [
    isLoadingInsurance,
    patientInsuranceList,
    selectedPatientToken,
    shouldRefetchPatientInsurance,
    customerProfile.vpharmCustomerToken,
    setPatientInsuranceList,
    setSavingsCardList,
  ])

  return {
    isErrorLoading,
    isLoadingInsurance,
    isDeletingInsurance,
    patientInsuranceList,
    submitPatientInsurance,
    deletePatientInsurance,
    savingsCardList: hydratedSavingsCard,
    prescriptions,
    setShouldRefetchPatientInsurance: () => setShouldRefetchPatientInsurance(true),
  }
}
