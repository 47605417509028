import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const ArrowDownIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 1L7 7L13 1'
        stroke={vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
