import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { Modal } from '../../../common/styledComponents/StyledModal'

const ConfirmModal = styled(Modal)`
  && > div {
    max-width: 460px;
  }

  [data-cap-modal-close-button='true'] {
    top: 1.6rem;
  }

  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const WarningText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['functional-warning-dark']};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonContainerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledModalConfirmation = {
  WarningText,
  ButtonContainer,
  ConfirmModal,
  ButtonContainerWrapper,
}
