import { Document } from '@contentful/rich-text-types'
import { Accordion, AccordionItem, Text } from '@truepill/react-capsule'
import RichTextRenderer from 'Components/RichTextRenderer'
import { ReactElement, useState } from 'react'
import { Element } from 'react-scroll'

import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { IResourceSet, IRibbonFields } from '../../types/generated/contentful'
import {
  FaqContent,
  SectionAccordion,
  SectionAccordionContent,
  SectionAccordionItem,
  SectionAccordionTrigger,
  SectionTitle,
  StyledAccordionContent,
  StyledAccordionTrigger,
  StyledTutorialAccordion,
} from './styledComponents'

interface FaqQuestion {
  fields: {
    name?: string
    value?: string
    richValue?: Document
  }
}

interface MultilevelAccordionProps {
  sections: IRibbonFields[]
}

const MultilevelAccordion = ({ sections }: MultilevelAccordionProps): ReactElement => {
  const [lastOpenedQuestion, setLastOpenedQuestion] = useState<Record<string, string>>({})
  const { trackAccordionChangeEvent } = useAnalytics()
  const { theme: vpTheme } = useContentfulTheme()

  const onToggleCategory = (category: string) => {
    const isOpen = !!category
    trackAccordionChangeEvent('faq_page_category_toggle', category, isOpen)
  }

  const onToggleQuestion = (question: string, section: string) => {
    const isOpen = !!question
    trackAccordionChangeEvent('faq_page_question_toggle', question, isOpen)
    if (isOpen) {
      setLastOpenedQuestion({ ...lastOpenedQuestion, [section]: question })
    }
  }

  const mapFieldsToQuestions = (component: IResourceSet) => {
    return component.fields.resources.map((question: FaqQuestion, qIndex: number) => (
      <AccordionItem value={question.fields.name || ''} key={`key-${qIndex}`}>
        <StyledAccordionTrigger vpTheme={vpTheme}>{question.fields.name}</StyledAccordionTrigger>
        <StyledAccordionContent>
          {question.fields.richValue ? <RichTextRenderer document={question.fields.richValue} /> : <Text>{question.fields.value}</Text>}
        </StyledAccordionContent>
      </AccordionItem>
    ))
  }

  return (
    <StyledTutorialAccordion vpTheme={vpTheme}>
      <SectionAccordion onValueChange={onToggleCategory} vpTheme={vpTheme} type='single' collapsible>
        {sections.map((section: IRibbonFields, index) => (
          <SectionAccordionItem value={section.name} key={index}>
            <Element key={index} name={`FAQ_section_${section.name}`}>
              <FaqContent vpTheme={vpTheme}>
                <SectionAccordionTrigger vpTheme={vpTheme}>
                  <SectionTitle>{section.name}</SectionTitle>
                </SectionAccordionTrigger>
                <SectionAccordionContent>
                  <Accordion type='single' collapsible onValueChange={(question) => onToggleQuestion(question, section.name)}>
                    {mapFieldsToQuestions(section.component as IResourceSet)}
                  </Accordion>
                </SectionAccordionContent>
              </FaqContent>
            </Element>
          </SectionAccordionItem>
        ))}
      </SectionAccordion>
    </StyledTutorialAccordion>
  )
}

export default MultilevelAccordion
