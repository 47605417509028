import { AccordionItem, Header } from '@truepill/react-capsule'
import RichTextRenderer from 'Components/RichTextRenderer'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { IFaqContent } from '../../types/generated/contentful'
import { StyledAccordionContent, StyledAccordionTrigger } from './styledComponents'

const FAQQuestion: React.FC<IFaqContent> = (props) => {
  const { fields } = props
  const { theme: vpTheme } = useContentfulTheme()

  /** Useful for analytics to log the question being expanded or hidden */
  const questionValue = fields.question.substring(0, 50).trim()

  return (
    <AccordionItem value={questionValue}>
      <StyledAccordionTrigger vpTheme={vpTheme}>
        <Header>{fields.question}</Header>
      </StyledAccordionTrigger>
      <StyledAccordionContent>
        <RichTextRenderer document={fields.answer} />
      </StyledAccordionContent>
    </AccordionItem>
  )
}

export default FAQQuestion
