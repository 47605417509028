import { Checkbox, Text } from '@truepill/react-capsule'
import React from 'react'

import { VP_4954_ALLOW_PHONE_NUMBER_UPDATE } from '../../../constants'
import { useContentfulTheme } from '../../../hooks'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { CheckboxContainer } from '../styledComponents'
import SmsCheckboxV2 from './SmsCheckboxV2'

interface SmsCheckboxProps {
  smsOptIn: boolean
  phoneNumber?: string
  setSmsOptIn: (smsOptIn: boolean) => void
  onSubmitPhoneNumber: (phoneNumber: string, smsOptIn: boolean) => void
}

const SmsOptInCheckbox: React.FC<SmsCheckboxProps> = ({ smsOptIn, phoneNumber, setSmsOptIn, onSubmitPhoneNumber }) => {
  const { theme } = useContentfulTheme()
  const { [VP_4954_ALLOW_PHONE_NUMBER_UPDATE]: phoneNumberUpdate } = useLDFlagsWithLocalStorage([VP_4954_ALLOW_PHONE_NUMBER_UPDATE])

  return phoneNumberUpdate ? (
    <SmsCheckboxV2
      smsOptIn={smsOptIn}
      phoneNumber={phoneNumber}
      handleSmsOptInChange={(smsOptIn) => setSmsOptIn(smsOptIn)}
      onSubmitPhoneNumber={onSubmitPhoneNumber}
    />
  ) : (
    <CheckboxContainer vpTheme={theme}>
      <Checkbox
        checked={smsOptIn}
        onCheckedChange={() => setSmsOptIn(!smsOptIn)}
        data-testid='order-review__agree-terms__checkbox'
        aria-label={'Send me SMS updates about my prescriptions'}
      />
      <Text>Send me SMS updates about my prescriptions</Text>
    </CheckboxContainer>
  )
}

export default SmsOptInCheckbox
