import { BannerAlert, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { Modal } from '../../common/styledComponents/StyledModal'
import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { ThemedComponent } from '../../common/styledComponents/types'
import { media } from '../../common/styles/variables'

const StyledBannerAlert = styled(BannerAlert)`
  max-width: 46.5rem;
  margin: 0.5rem;
  padding: 1rem;
  position: fixed;
  top: 10%;
  z-index: 100;
`

const ModalContainer = styled(Modal)`
  > div:first-child {
    max-width: 42rem;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
`

const ShowMoreAddresses = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['primary-700']};
  font-weight: 700;
  cursor: pointer;
`

const Wrapper = styled.div<{ background?: string }>`
  padding: 1rem;
  background: ${({ background }) => background ?? defaultTheme.colors['gray-100']};
  border-radius: 0.5rem;
  > div {
    display: flex;
    > p {
      margin-right: 4px;
    }
  }
`

const StyledHeader = styled(ThemedHeader)<ThemedComponent>`
  max-width: 360px;
  @media (min-width: ${media.tablet}) {
    max-width: 420px;
  }
`

const StyledActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  > *:first-child {
    margin-top: 0.5rem;
  }
  @media (min-width: 540px) {
    flex-flow: row;
    flex-wrap: nowrap;
    > *:first-child {
      margin-top: 0;
      margin-right: 1.5rem;
    }
    justify-content: flex-end;
  }
`

const AddressDeleteText = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
`

export { AddressDeleteText, ModalContainer, ShowMoreAddresses, StyledActionContainer, StyledBannerAlert, StyledHeader, Wrapper }
