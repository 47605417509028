import { GetSavingCardResponse, MedicationInfo, PrescriptionAvailabilityResponse } from '@vpharm-platform/shared'

export const canAddSavingsCard = ({ savingsCard }: MedicationInfo): boolean =>
  !savingsCard.appliedSavingsCard &&
  !!(savingsCard.savingsCardConfig?.savingsCardCashEnabled || savingsCard.savingsCardConfig?.savingsCardInsuranceEnabled)

export const isSavingsCardAvailable = ({ savingsCard }: MedicationInfo, patientHasInsurance: boolean): boolean =>
  !!(savingsCard.savingsCardConfig?.savingsCardCashEnabled || (patientHasInsurance && savingsCard.savingsCardConfig?.savingsCardInsuranceEnabled))

export interface HydratedSavingsCard extends GetSavingCardResponse {
  prescription?: MedicationInfo
}

export const hydrateSavingsCard = (
  savingsCards: GetSavingCardResponse[] | undefined,
  prescriptions: PrescriptionAvailabilityResponse,
): HydratedSavingsCard[] | undefined => {
  const flatPrescriptions =
    prescriptions?.availableToOrder && prescriptions?.unavailableToOrder
      ? [...prescriptions.availableToOrder, ...prescriptions.unavailableToOrder]
      : undefined
  if (flatPrescriptions && savingsCards) {
    return savingsCards.map((card) => ({
      ...card,
      prescription: flatPrescriptions.find(({ prescriptionNdc }) => prescriptionNdc === card.ndc),
    }))
  }
}
