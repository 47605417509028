export const PATIENT_HEALTH_QUESTION_TYPE = {
  ALLERGIES: 'allergies',
  HEALTH_CONDITIONS: 'healthConditions',
  MEDICATIONS: 'medicationsAndSupplements',
  PREGNANCY: 'pregnantOrLactating',
  PREGNANCY_STATUS: 'pregnancyStatus',
}

export const LACTATION_ANSWER = 'lactation'
export const PROCREATIVE_MANAGEMENT_ANSWER = 'procreative management'

export enum PregnancyStatus {
  Pregnant = 'pregnant',
  Lactating = 'lactating',
  Planning = 'planning',
  None = 'none',
}

export const PATIENT_HEALTH_QUESTION = {
  [PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES]: 'Do you have any allergies?',
  [PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS]: 'Do you have any existing medical conditions?',
  [PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS]: 'Are you taking any medications, vitamins, or supplements?',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY]: 'Are you pregnant, lactating, or planning to become pregnant in the next 90 days?',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS]: 'Pregnancy Status',
}

export const COMPLETED_HEALTH_QUESTION_LABEL = {
  [PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES]: 'Allergies',
  [PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS]: 'Medical conditions',
  [PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS]: 'Medications, vitamins, or supplements',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY]: 'Are you pregnant, lactating, or planning to become pregnant in the next 90 days?',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS]: 'Pregnancy Status',
}

export const PREGNANCY_STATUS_LABELS = {
  [PregnancyStatus.Pregnant]: 'I am pregnant',
  [PregnancyStatus.Lactating]: 'I am lactating',
  [PregnancyStatus.Planning]: 'I am planning to get pregnant in the next 90 days',
  [PregnancyStatus.None]: 'I am NOT pregnant',
}

export const COMPLETED_HEALTH_QUESTION_PLACEHOLDER = {
  [PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES]: 'e.g. latex',
  [PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS]: 'e.g. high blood pressure',
  [PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS]: 'e.g. multivitamin',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY]: '',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY_STATUS]: '',
}

export const HEALTH_QUESTION_INSTRUCTIONS = {
  [PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES]: 'Please list all allergies',
  [PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS]: 'Please list all medical conditions',
  [PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS]: 'Please list all medications, vitamins, or supplements you are taking',
  [PATIENT_HEALTH_QUESTION_TYPE.PREGNANCY]: '',
}
