import { GetPaymentResponse, sharedConstants } from '@vpharm-platform/shared'
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'
import { PatientAddress, SavedPatientAddress } from '../interfaces'

// TODO: Move to shared package
export interface AddAddressResponse {
  updateResult: {
    count: number
  }
  createResult: {
    addressId: number
  }
}

export interface UserService {
  getUserAddress(customerToken: string): Promise<SavedPatientAddress[]>
  addUserAddress(address: PatientAddress, customerToken: string): Promise<AddAddressResponse>
  deleteUserAddress(address: PatientAddress, customerToken: string): Promise<void>
  getUserPayments(customerToken: string): Promise<GetPaymentResponse>
  setDefaultPaymentMethod(customerToken: string, paymentMethodId: string): Promise<void>
  updateUserAddress(address: PatientAddress, customerToken: string): Promise<void>
  setDefaultAddress(address: PatientAddress, customerToken: string): Promise<void>
  deletePaymentMethod(customerToken: string, paymentId: string): Promise<void>
  createPaymentMethod(customerToken: string, paymentId: string, isDefault: boolean, securityKey: string, save: boolean): Promise<void>
}

const { VPHARM_CUSTOMER_HEADER } = sharedConstants

class UserApiService implements UserService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async getUserAddress(customerToken: string): Promise<SavedPatientAddress[]> {
    try {
      const url = `${this.baseUrl}/addresses`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      const result = await axiosAuth.get<SavedPatientAddress[]>(url, config)

      return result.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async addUserAddress(address: PatientAddress, customerToken: string): Promise<AddAddressResponse> {
    try {
      const url = `${this.baseUrl}/address`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      const result = await axiosAuth.post<AddAddressResponse>(
        url,
        {
          name: address.name,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          default: address.isDefault,
          is_saved: address.isSaved ?? false,
        },
        config,
      )

      return result.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async getUserPayments(customerToken: string): Promise<GetPaymentResponse> {
    try {
      const url = `${this.baseUrl}/user/payment_methods`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      const result = await axiosAuth.get<GetPaymentResponse>(url, config)

      return result.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async setDefaultPaymentMethod(customerToken: string, paymentMethodId: string): Promise<void> {
    try {
      const url = `${this.baseUrl}/user/stripe_user`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      await axiosAuth.post<void>(url, { default_payment_method: paymentMethodId }, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async updateUserAddress(address: PatientAddress, customerToken: string): Promise<void> {
    try {
      const url = `${this.baseUrl}/address/${address.id}`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      await axiosAuth.put<void>(
        url,
        {
          name: address.name,
          address1: address.address1,
          address2: address.address2,
          zip: address.zip,
          state: address.state,
          city: address.city,
          default: address.isDefault,
          is_saved: address.isSaved ?? false,
        },
        config,
      )
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async setDefaultAddress(address: PatientAddress, customerToken: string): Promise<void> {
    try {
      const url = `${this.baseUrl}/address/default/${address.id}`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      await axiosAuth.put<void>(url, {}, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async deleteUserAddress(address: PatientAddress, customerToken: string): Promise<void> {
    try {
      const url = `${this.baseUrl}/address/${address.id}`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      await axiosAuth.delete<void>(url, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async deletePaymentMethod(customerToken: string, paymentId: string): Promise<void> {
    try {
      const url = `${this.baseUrl}/user/payment_methods/${paymentId}`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }

      await axiosAuth.delete(url, config)
    } catch (err) {
      throw new Error((err as Error).message)
    }
  }

  async createPaymentMethod(customerToken: string, paymentId: string, isDefault: boolean, securityKey: string, saved: boolean): Promise<void> {
    try {
      const url = `${this.baseUrl}/user/payment_methods`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken },
      }
      const body = {
        payment_id: paymentId,
        is_default: isDefault,
        security_key: securityKey,
        saved,
      }

      await axiosAuth.post<void>(url, body, config)
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const axiosError = err as AxiosError<string>
        throw new Error(axiosError.response?.data)
      }
      throw new Error((err as Error).message)
    }
  }
}

export const userService: UserService = new UserApiService()
