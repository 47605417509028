import { insuranceMockData, prescriptionMockData } from 'mocks'
import { atom } from 'recoil'

export const patientOrderState = atom({
  key: 'patientOrderState',
  default: {
    prescriptionList: prescriptionMockData,
    insuranceList: insuranceMockData,
  },
})
