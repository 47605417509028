import { SEO } from '@truepill/react-capsule'
import Ribbon from 'Components/MarketingPages/Ribbon'
import StyledToastMessage from 'Components/ToastMessage'
import { useContentfulDynamicPage } from 'hooks/contentful'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { useAnalytics } from '../../hooks/analytics-context'

const ContactUs: React.FC = () => {
  const { pageContent, error } = useContentfulDynamicPage('contact-us')
  const { pharmCustomer } = useCustomerConfigContext()
  const { trackErrorShownEvent } = useAnalytics()

  useEffect(() => {
    if (error) {
      trackErrorShownEvent('contact_us_page_load')
    }
  }, [error, trackErrorShownEvent])

  return (
    <>
      <StyledToastMessage state={'error'} visible={error}>
        <div>An error occurred while loading the site. Please try again later.</div>
      </StyledToastMessage>
      {pageContent && (
        <MainContainer>
          {pageContent.seo?.title && (
            <SEO title={`${pharmCustomer?.companyName || ''} - ${pageContent.seo.title}`} description={pageContent.seo?.description} useDefaults />
          )}
          {pageContent.content.map(({ component }, index) => (
            <Ribbon key={`ribbon-${index}`} component={component} />
          ))}
        </MainContainer>
      )}
    </>
  )
}

const MainContainer = styled.div`
  align-self: start;
  width: 100%;
  min-height: calc(100vh - 9.5rem);
`

export default ContactUs
