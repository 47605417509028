import { sharedConstants } from '@vpharm-platform/shared'
import GenericErrorToast from 'Components/GenericErrorToast'
import { RumRoute } from 'datadog-react'
import { compose } from 'lodash/fp'
import IdentityVerification from 'pages/AccountCreation/IdentityVerification/IdentityVerification'
import AccountAddresses from 'pages/AccountManagement/Addresses/index'
import AccountHealthProfile from 'pages/AccountManagement/HealthProfile'
import AccountInsurance from 'pages/AccountManagement/Insurance/index'
import OrderDetails from 'pages/AccountManagement/Orders/OrderDetails'
import AccountPayments from 'pages/AccountManagement/Payments'
import AccountSettings from 'pages/AccountManagement/Settings'
import Admin from 'pages/Admin'
import Cart from 'pages/Cart/Cart'
import CheckoutPageV2 from 'pages/Checkout'
import ParentRelationshipConfirmation from 'pages/CheckoutFlow/ParentRelationshipConfirmation'
import PatientHealthSurvey from 'pages/CheckoutFlow/PatientHealthSurvey'
import Compliance from 'pages/Compliance/Compliance'
import FAQPage from 'pages/FAQ'
import GovernmentUploadId from 'pages/GovernmentUploadId/GovernmentUploadId'
import HIPAAPractices from 'pages/HIPAAPractices/HIPAAPractices'
import LandingPage from 'pages/LandingPage'
import NotFound from 'pages/NotFound'
import Orders from 'pages/Orders'
import PrescriptionManagement from 'pages/PrescriptionManagement'
import OrderConfirmation from 'pages/PrescriptionManagement/Checkout/OrderConfirmation/OrderConfirmation'
import ResetPassword from 'pages/ResetPassword/'
import TransferRequestDetails from 'pages/TransferRequest'
import React from 'react'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'Routes/ProtectedRoute'

import { useCustomerConfigContext } from '../Components/CustomerConfigProvider'
import withPageLoadEvent from '../Components/withPageLoadEvent'
import withRedirect from '../Components/withRedirect'
import AboutUs from '../pages/AboutUs'
import PatientRecordVerification from '../pages/AccountCreation/PatientRecordVerification'
import ActionConfirmation from '../pages/ActionConfirmation'
import AutoRefill from '../pages/AutoRefill/AutoRefill'
import ContactUs from '../pages/ContactUs'
import EmailUpdating from '../pages/EmailUpdating'
import ForgotPassword from '../pages/ForgotPassword'
import Login from '../pages/Login'
import { OutgoingInfo } from '../pages/OutgoingInfo'
import PrescriptionTransferManager from '../pages/PrescriptionManagement/PrescriptionTransferV2/PrescriptionTransferManager'
import SelfEnrollment from '../pages/SelfEnrollment'
import SignUp from '../pages/SignUp'
import TransferOut from '../pages/TransferOut/TransferOut'

const { RoutePaths } = sharedConstants

export const RoutesList = {
  auth: [
    {
      id: 'cart',
      component: Cart,
      path: RoutePaths.CART_PATH,
      exact: true,
    },
    {
      id: 'prescription-management',
      component: PrescriptionManagement,
      path: RoutePaths.PRESCRIPTION_MANAGEMENT_PATH,
      exact: true,
    },
    {
      id: 'prescription-management-checkout',
      component: CheckoutPageV2,
      path: RoutePaths.CHECKOUT_PATH,
      exact: true,
    },
    {
      id: 'checkout-patient-urac',
      component: PatientHealthSurvey,
      path: RoutePaths.URAC_PATH,
      exact: true,
    },
    {
      id: 'upload-government-id',
      component: GovernmentUploadId,
      path: RoutePaths.ID_UPLOAD_PATH,
      exact: true,
    },
    {
      id: 'account-insurance',
      component: AccountInsurance,
      path: RoutePaths.ACCOUNT_INSURANCE_PATH,
    },
    {
      id: 'account-addresses',
      component: AccountAddresses,
      path: RoutePaths.ACCOUNT_ADDRESSES_PATH,
    },
    {
      id: 'account-payments',
      component: AccountPayments,
      path: RoutePaths.ACCOUNT_PAYMENTS_PATH,
    },
    {
      id: 'account-settings',
      component: AccountSettings,
      path: RoutePaths.ACCOUNT_SETTINGS_PATH,
    },
    {
      id: 'checkout-order-confirmation',
      component: OrderConfirmation,
      path: RoutePaths.ORDER_CONFIRMATION_PATH,
      exact: true,
    },
    {
      id: 'health-profile-settings',
      component: AccountHealthProfile,
      path: RoutePaths.ACCOUNT_HEALTH_PROFILE_PATH,
    },
    {
      id: 'orders',
      component: Orders,
      path: RoutePaths.ORDERS_PATH,
      exact: true,
    },
    {
      id: 'order-details',
      component: OrderDetails,
      path: RoutePaths.ORDER_DETAILS_PATH,
      exact: true,
    },
    {
      id: 'transfer-details',
      component: PrescriptionTransferManager,
      path: RoutePaths.TRANSFERS_PATH,
    },
    {
      id: 'transfer-request',
      component: TransferRequestDetails,
      path: RoutePaths.TRANSFER_REQUEST_PATH,
      exact: true,
    },
    {
      id: 'transfers-out',
      component: TransferOut,
      path: RoutePaths.TRANSFERS_OUT_PATH,
      exact: true,
    },
    {
      id: 'auto-refills',
      component: AutoRefill,
      path: RoutePaths.AUTO_REFILLS_PATH,
      exact: true,
    },
  ],
  open: [
    {
      id: 'login',
      component: Login,
      path: RoutePaths.LOGIN_PATH,
      exact: true,
    },
    {
      id: 'forgot-password',
      component: ForgotPassword,
      path: RoutePaths.FORGOT_PASSWORD_PATH,
      exact: true,
    },
    {
      id: 'account-creation',
      component: SignUp,
      path: RoutePaths.ACCOUNT_CREATION_PATH,
      exact: true,
    },
    {
      id: 'admin',
      component: Admin,
      path: RoutePaths.ADMIN_PATH,
      exact: true,
    },
    {
      id: 'landing-page',
      component: LandingPage,
      path: RoutePaths.ROOT_PATH,
      exact: true,
    },
    {
      id: 'identity-verification',
      component: IdentityVerification,
      path: RoutePaths.IDENTITY_VERIFICATION,
      exact: true,
    },
    {
      id: 'terms-of-service',
      component: Compliance,
      path: RoutePaths.TERMS_OF_SERVICE_PATH,
      exact: true,
    },
    {
      id: 'hipaa-practices',
      component: HIPAAPractices,
      path: RoutePaths.HIPAA_PRACTICES_PATH,
      exact: true,
    },
    {
      id: 'privacy-policy',
      component: Compliance,
      path: RoutePaths.PRIVACY_POLICY_PATH,
      exact: true,
    },
    {
      id: 'informed-consent-pharmacy-services',
      component: Compliance,
      path: RoutePaths.INFORMED_CONSENT_PATH,
      exact: true,
    },
    {
      id: 'reset-password',
      component: ResetPassword,
      path: RoutePaths.RESET_PASSWORD_PATH,
      exact: true,
    },
    {
      id: 'parent-relationship-confirmation',
      component: ParentRelationshipConfirmation,
      path: RoutePaths.PARENT_RELATIONSHIP_CONFIRMATION,
      exact: true,
    },
    {
      id: 'contact-us',
      component: ContactUs,
      path: RoutePaths.CONTACT_PATH,
      exact: true,
    },
    {
      id: 'sign-up',
      component: SelfEnrollment,
      path: RoutePaths.SELF_ENROLLMENT_PATH,
      exact: true,
    },
    {
      id: 'outgoing-info',
      component: OutgoingInfo,
      path: RoutePaths.OUTGOING_INFO_PATH,
      exact: true,
    },
    {
      id: 'patient-record-verification',
      component: PatientRecordVerification,
      path: RoutePaths.PATIENT_RECORD_VERIFICATION_PATH,
      exact: true,
    },
    {
      id: 'email-updating-confirmation',
      component: EmailUpdating,
      path: `${RoutePaths.EMAIL_UPDATING_CONFIRMATION_PATH}/:token`,
      exact: true,
    },
    {
      id: 'action-confirmation',
      component: ActionConfirmation,
      path: `${RoutePaths.ACTION_CONFIRMATION_PATH}/:action`,
      exact: true,
    },
  ],
}

const Routes = (): React.ReactElement => {
  const { open, auth } = RoutesList
  const { pharmCustomer } = useCustomerConfigContext()
  const displayAboutLink = pharmCustomer?.componentConfiguration?.fields?.footer?.fields.displayAboutLink
  const displayFaqLink = pharmCustomer?.componentConfiguration?.fields?.footer?.fields.displayFaqLink
  const faqLink = pharmCustomer?.customerSupport?.fields?.faqLink

  if (displayFaqLink && !faqLink) {
    RoutesList.open.push({
      id: 'faq',
      component: FAQPage,
      path: RoutePaths.FAQ_PATH,
      exact: true,
    })
  }

  if (displayAboutLink) {
    RoutesList.open.push({
      id: 'about-us',
      component: AboutUs,
      path: RoutePaths.ABOUT_PATH,
      exact: true,
    })
  }

  return (
    <>
      <Switch>
        {open.map(({ id, component, path, exact }) => (
          <RumRoute key={id} path={path} exact={exact} component={compose(withRedirect, withPageLoadEvent({ page: path }))(component)} />
        ))}
        {auth.map(({ id, component, path, exact }) => (
          <ProtectedRoute key={id} path={path} exact={exact} component={compose(withRedirect, withPageLoadEvent({ page: path }))(component)} />
        ))}
        <RumRoute path='*' key='not-found' component={compose(withRedirect, withPageLoadEvent({ page: 'not-found' }))(NotFound)} />
        <GenericErrorToast />
      </Switch>
    </>
  )
}

export default Routes
