import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { mediaLargerThan } from '../../common/styles/variables'

interface PillBannerLayoutProps {
  backgroundColor?: string
  maxWidth?: string
  isPillReversed?: boolean
}

const PillBannerLayout = ({ backgroundColor, maxWidth, children, isPillReversed }: PropsWithChildren<PillBannerLayoutProps>): React.ReactElement => {
  return (
    <PillBannerContainer backgroundColor={backgroundColor} maxWidth={maxWidth}>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Rectangle isPillReversed={isPillReversed} />
    </PillBannerContainer>
  )
}

const PillBannerContainer = styled.div<{ backgroundColor?: string; maxWidth?: string }>`
  padding: 2.25rem 1.375rem 1.625rem 1.5rem;
  position: relative;
  overflow: hidden;

  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}

  ${mediaLargerThan.tablet} {
    padding: 1rem 1.375rem 1rem 1.5rem;
  }
`

const ChildrenWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const Rectangle = styled.div<{ isPillReversed?: boolean }>`
  width: 245px;
  height: 318px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  border-radius: 77px;
  z-index: 0;
  transform: rotate(46.6deg);
  top: -6rem;
  left: -4rem;

  ${mediaLargerThan.tablet} {
    ${(props) =>
      props.isPillReversed &&
      `
    left: auto;
    right: -4rem;
    top: -5rem;
    transform: rotate(-46.6deg);
  `}
  }
`

export default PillBannerLayout
