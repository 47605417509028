import { Spacer } from '@truepill/react-capsule'
import { LoginIcon } from 'assets/Icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { mediaLargerThan } from '../../common/styles/variables'
import { LOGIN_PATH } from '../../constants'
import { useContentfulTheme } from '../../hooks'

interface Props {
  onClick?: () => void
}

const LoginButton: React.FC<Props> = () => {
  const history = useHistory()
  const { theme } = useContentfulTheme()

  const handleClick = () => {
    history.push(LOGIN_PATH)
  }

  return (
    <StyledButton onClick={handleClick}>
      Sign in
      <Spacer size='xs' />
      <LoginIcon vpTheme={theme} />
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: none;
  ${mediaLargerThan.laptop} {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
  }
`

export default LoginButton
