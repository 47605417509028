import { useCustomerProfile } from 'hooks'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import { PatientProfile } from '../interfaces'
import { patientProfileAtom, selectedPatientTokenAtom } from '../persistRecoil'
import { PatientUpdatePayload, pharmacyService } from '../services/pharmacyService'
import { useAnalytics } from './analytics-context'

interface ReturnValues {
  error?: boolean
  loading: boolean
  patientProfile: PatientProfile | undefined
  resetPatientProfile: () => void
  updatePatientProfile: (patientProfilePayload: Partial<PatientUpdatePayload>) => Promise<void>
}

export const usePatientProfile = (): ReturnValues => {
  const { customerProfile } = useCustomerProfile()

  const [selectedPatientToken, setSelectedPatientTokenAndUpdateProfile] = useRecoilState<string>(selectedPatientTokenAtom)
  const patientProfile = useRecoilValue<PatientProfile | undefined>(patientProfileAtom)
  const resetPatientProfile = useResetRecoilState(patientProfileAtom)

  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { identifyAnalyticsUser } = useAnalytics()

  const updatePatientProfile = async (patientProfilePayload: Partial<PatientUpdatePayload>) => {
    try {
      setLoading(true)
      const customerToken = customerProfile.vpharmCustomerToken

      await pharmacyService.updatePatient(patientProfilePayload, customerToken, selectedPatientToken)

      setSelectedPatientTokenAndUpdateProfile(selectedPatientToken)
      identifyAnalyticsUser(selectedPatientToken)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const shouldClearPatientData = !selectedPatientToken && patientProfile
    if (shouldClearPatientData) {
      resetPatientProfile()
    }
  }, [patientProfile, resetPatientProfile, selectedPatientToken])

  return {
    error,
    loading,
    updatePatientProfile,
    resetPatientProfile,
    patientProfile,
  }
}
