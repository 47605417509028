import { Header, Spacer } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { ITruepillPartnerFields } from '../../types/generated/contentful'
import { PartnerButtonContainer, PartnerContainer, PartnerImageContainer, PartnerLogoContainer, StyledPartnerImage } from './styledComponents'

// Note: This component's colors are set in the "Truepill Partner"  Contentful model

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDynamicalHeight = (isLargerThanLaptop: boolean, isLargerThanTablet: boolean, isLargerThanMobile: boolean, width: number) => {
  switch (true) {
    case isLargerThanTablet:
      return '550px'
    case isLargerThanMobile:
      return '500px'
    default:
      return '470px'
  }
}

const Partner: React.FC<ITruepillPartnerFields> = ({
  image,
  logo,
  header,
  subHeader,
  startFadeColor,
  endFadeColor,
  textColor,
  buttonBackgroundColor,
  buttonTextColor,
  buttonText,
}) => {
  const { theme } = useContentfulTheme()
  const { isLargerThanMobile, isLargerThanTablet, isLargerThanLaptop, width } = useWindowDimensions()
  const { trackLinkClickEvent } = useAnalytics()
  return (
    <PartnerContainer>
      <StyledPartnerImage
        height={getDynamicalHeight(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
        src={image.fields.file.url}
        alt='Partner'
      />
      <PartnerImageContainer
        startFadeColor={startFadeColor}
        endFadeColor={endFadeColor}
        height={getDynamicalHeight(isLargerThanLaptop, isLargerThanTablet, isLargerThanMobile, width)}
      >
        <Spacer size={isLargerThanMobile ? '2xl' : 'lg'} />
        <PartnerLogoContainer>
          <img src={logo.fields.file.url} alt='White Logo' />
        </PartnerLogoContainer>
        <Spacer size={isLargerThanMobile ? '2xl' : 'lg'} />
        <Header
          variant='4xl'
          css={{
            color: textColor,
          }}
        >
          {header}
        </Header>
        <Spacer size={isLargerThanMobile ? '2xl' : 'lg'} />
        <Header
          css={{
            color: textColor,
          }}
        >
          {subHeader}
        </Header>
        <Spacer size={'4xl'} />
        <PartnerButtonContainer>
          {/* Remove commented code when we have a link to the partner page */}
          {/* <Link to={''}> */}
          <ThemedButton
            vpTheme={theme}
            onClick={() => trackLinkClickEvent('truepill_partner_cta_click', 'no link')}
            css={{
              backgroundColor: buttonBackgroundColor || theme.colors['primary-300'] || defaultTheme.colors['primary-300'],
              color: buttonTextColor || theme.colors['primary-100'] || defaultTheme.colors['primary-100'],
              borderColor: buttonBackgroundColor || theme.colors['primary-300'] || defaultTheme.colors['primary-300'],
              borderRadius: '4px',
            }}
          >
            {buttonText}
          </ThemedButton>
          {/* </Link> */}
        </PartnerButtonContainer>
      </PartnerImageContainer>
    </PartnerContainer>
  )
}

export default Partner
