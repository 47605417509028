export enum PrescriptionDisplayStatus {
  COPAY_PRICE_AVAILABLE = 'COPAY_PRICE_AVAILABLE',
  FILL_IN_PROGRESS = 'FILL_IN_PROGRESS',
  PENDING_REFILL = 'PENDING_REFILL',
  OUT_OF_REFILL = 'OUT_OF_REFILL',
  SCRIPT_EXPIRED = 'SCRIPT_EXPIRED',
  COPAY_ERROR = 'COPAY_ERROR',
  COPAY_IN_PROGRESS = 'COPAY_IN_PROGRESS',
  COPAY_EXPIRED = 'COPAY_EXPIRED',
  TRANSFER_IN_PROGRESS = 'TRANSFER_IN_PROGRESS',
  FILL_ERROR = 'FILL_ERROR',
  INITIALIZED = 'INITIALIZED',
  INSURANCE_UNAVAILABLE = 'INSURANCE_UNAVAILABLE',
  PA_REJECTED = 'PA_REJECTED',
  PA_IN_PROGRESS = 'PA_IN_PROGRESS',
  OUT_OF_FORMULARY = 'OUT_OF_FORMULARY',
  ON_HOLD = 'ON_HOLD',
  CASH_ORDERABLE = 'CASH_ORDERABLE',
  DISCONTINUED_TRANSFERRED = 'DISCONTINUED_TRANSFERRED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  TRANSFER_OUT_IN_PROGRESS = 'TRANSFER_OUT_IN_PROGRESS',
}
