import { datadogRum } from '@datadog/browser-rum'
import { theme } from '@truepill/react-capsule'
import { SignOut } from 'assets/Icons'
import React from 'react'
import styled from 'styled-components/macro'

import { useAuth } from '../../context/auth-context'
import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { useResetBeforeLogout } from '../../hooks/useResetBeforeLogout'

interface LogoutButtonProps {
  onClick?: () => void
}

const LogoutButton = ({ onClick }: LogoutButtonProps): React.ReactElement => {
  const { logout } = useAuth()
  const { resetUserAnalyticState, trackLinkClickEvent } = useAnalytics()
  const { resetBeforeLogout } = useResetBeforeLogout()
  const { theme } = useContentfulTheme()

  const handleClick = () => {
    trackLinkClickEvent('Sign out', 'log out user', LinkOrigin.Header)
    resetUserAnalyticState()
    datadogRum.clearUser()
    resetBeforeLogout()
    logout()
    onClick && onClick()
  }

  return (
    <StyledLogoutButton role='button' onClick={handleClick} mobile>
      <SignOut vpTheme={theme} />
      <StyledSpan>Sign out</StyledSpan>
    </StyledLogoutButton>
  )
}

const StyledLogoutButton = styled.button<{ mobile?: boolean }>`
  border: none;
  color: inherit;
  background: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: ${({ mobile }) => mobile && '8px 28px'};

  svg path {
    stroke: ${theme.colors['functional-error-dark'].computedValue} !important;
  }
`

const StyledSpan = styled.span`
  margin-left: 20px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors['functional-error-dark'].computedValue};
`

export default LogoutButton
