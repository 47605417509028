import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { getCustomer, useGetCustomerNameFromSubdomain } from '../../hooks/contentful'
import { ICustomerFields } from '../../types/generated/contentful'

export interface CustomerConfigProviderProps {
  loading: boolean
  error: boolean
  pharmCustomer?: ICustomerFields
}

export const CustomerConfigContext = createContext<CustomerConfigProviderProps>({
  loading: false,
  error: false,
})

export const useCustomerConfigContext = (): CustomerConfigProviderProps => {
  const cxt = useContext(CustomerConfigContext)

  if (cxt === undefined) {
    throw new Error('Attempting to read CustomerConfigContext outside a Provider heirarchy')
  }

  return cxt
}

const CustomerConfigProvider: React.FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [pharmCustomer, setPharmCustomer] = useState<ICustomerFields>()
  const customerName = useGetCustomerNameFromSubdomain()

  const getCustomerContent = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const customer = await getCustomer(customerName)
      setPharmCustomer(customer?.fields)
      if (customer?.fields) {
        document.title = customer?.fields.companyName
      }
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [customerName])

  useEffect(() => {
    getCustomerContent()
  }, [customerName, getCustomerContent])

  const value = {
    loading,
    error,
    pharmCustomer,
  }

  return <CustomerConfigContext.Provider value={value}>{children}</CustomerConfigContext.Provider>
}

export default CustomerConfigProvider
