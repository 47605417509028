import { Spacer } from '@truepill/react-capsule'
import { useContentfulTheme, useCustomerProfile, useUserProfile } from 'hooks'
import { selectedPatientTokenAtom } from 'persistRecoil'
import React, { Fragment, PropsWithChildren, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { PRESCRIPTION_MANAGEMENT_PATH } from '../../../constants'
import { useAnalytics } from '../../../hooks/analytics-context'
import { usePatientTransfer } from '../../../hooks/usePatientTransfer'
import TransferHeaderCard from '../../PrescriptionManagement/PrescriptionTransfer/TransferHeaderCard/TransferHeaderCard'
import FaqAccordion from './FaqAccordion'
import { StyledTransferConfirmation } from './styledComponent'

interface Props {
  transferToken: string
}

const TransferRequestConfirmation: React.FC<PropsWithChildren<Props>> = ({ transferToken }) => {
  const history = useHistory()
  const { customerProfile } = useCustomerProfile()
  const { authorizedPatients } = useUserProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const firstName = authorizedPatients?.[selectedPatientToken].firstName
  const { transferDetails, loading } = usePatientTransfer(transferToken, customerProfile.vpharmCustomerToken, selectedPatientToken)
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const handleRxManagerButtonClick = useCallback(() => {
    trackButtonClickEvent('transfer_request_confirmation_return_to_rxm', 'Return to Prescription Manager', 'Directs user to RxM')
    setTimeout(() => history.push(PRESCRIPTION_MANAGEMENT_PATH))
  }, [history, trackButtonClickEvent])

  if (loading) {
    return <LoadingAnimation size='sm' />
  }

  return (
    <StyledTransferConfirmation.Container>
      <StyledTransferConfirmation.Title variant='4xl'>Thank you, {firstName}!</StyledTransferConfirmation.Title>
      <StyledTransferConfirmation.Subtitle variant='body'>
        Your transfer request has been received. You’ll get a confirmation email shortly.
      </StyledTransferConfirmation.Subtitle>
      <StyledTransferConfirmation.TransferTokenContainer>
        <StyledTransferConfirmation.TransferToken>TRANSFER BATCH ID #{transferToken.toUpperCase()}</StyledTransferConfirmation.TransferToken>
      </StyledTransferConfirmation.TransferTokenContainer>
      <StyledTransferConfirmation.TransferDetailsContainer>
        <StyledTransferConfirmation.FlexContainer>
          <StyledTransferConfirmation.StyledHeader variant='xl'>Transfer details</StyledTransferConfirmation.StyledHeader>
        </StyledTransferConfirmation.FlexContainer>
        {transferDetails?.map((item) => {
          return (
            <Fragment key={item.transferToken}>
              <TransferHeaderCard medication={item} />
              <Spacer size='xl' />
            </Fragment>
          )
        })}
        <ThemedButton
          size='sm'
          variant={'primary'}
          onClick={handleRxManagerButtonClick}
          css={{
            backgroundColor: theme.colors['primary-700'],
            border: 'none',
            height: '48px',
            fontSize: '14px',
          }}
          vpTheme={theme}
        >
          Return to Prescription Manager
        </ThemedButton>
      </StyledTransferConfirmation.TransferDetailsContainer>
      <FaqAccordion />
    </StyledTransferConfirmation.Container>
  )
}

export default TransferRequestConfirmation
