import { CorePatient } from '@vpharm-platform/shared'

import { DATE_FORMAT_CORE_API, DATE_FORMAT_UI } from '../../../../constants/dateConstants'
import { toUtcString } from '../../../../utils/dateUtilities'
import { capitalizeFirstLetter } from '../../../../utils/stringUtilities'

export type PatientSettings = {
  legalName: string
  dob: string
  gender: 'Male' | 'Female' | ''
}

export function buildPatientSettings(patient: CorePatient | null): PatientSettings {
  if (!patient) {
    return buildEmptyResult()
  }

  const patientDob = toUtcString(patient.dob, DATE_FORMAT_UI, DATE_FORMAT_CORE_API)

  return {
    legalName: toTitleCase(patient.first_name) + ' ' + toTitleCase(patient.last_name),
    dob: patientDob,
    gender: capitalizeFirstLetter(patient.gender),
  } as PatientSettings
}

function buildEmptyResult(): PatientSettings {
  return {
    legalName: '',
    dob: '',
    gender: '',
  }
}

/**
 * Make each word start with uppercase.
 */
function toTitleCase(text: string): string {
  const splitCharacter = text.includes('-') ? '-' : ' '

  const result = text
    .split(splitCharacter)
    .map((t) => capitalizeFirstLetter(t))
    .join(splitCharacter)

  return result
}
