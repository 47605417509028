import { PatientSavingsCardSubmitPayload } from '../../../interfaces/PatientSavingsCard'
import SavingCardForm from './SavingsCardForm'
import { StyledModal } from './styledComponents'

interface EditSavingCardModalProps {
  isOpen: boolean
  onDismiss: () => void
  modalHeaderName: string
  ndc?: string | null
  setError: (error: string) => void
  handleSubmitForm: (val: PatientSavingsCardSubmitPayload) => void
  uploadSavingCardImage: (image: File) => Promise<string>
  isUploadingSavingCardImage: boolean
  isSubmittingForm: boolean
  prescriptionToken: string
  savingsCardUrl?: string | null
}

const EditSavingCardModal = ({
  isOpen,
  onDismiss,
  modalHeaderName,
  setError,
  ndc,
  prescriptionToken,
  handleSubmitForm,
  savingsCardUrl,
  uploadSavingCardImage,
  isUploadingSavingCardImage,
  isSubmittingForm,
}: EditSavingCardModalProps): React.ReactElement => (
  <StyledModal isOpen={isOpen} onDismiss={onDismiss} aria-label='Edit Savings Card'>
    <SavingCardForm
      savingsCardUrl={savingsCardUrl}
      prescriptionToken={prescriptionToken}
      handleSubmitForm={handleSubmitForm}
      uploadSavingCardImage={uploadSavingCardImage}
      handleCancelForm={onDismiss}
      isUploadingSavingCardImage={isUploadingSavingCardImage}
      savingCardModalHeaderName={modalHeaderName}
      isEditMode
      prescriptionNdc={ndc}
      isSubmittingForm={isSubmittingForm}
      setError={setError}
    />
  </StyledModal>
)

export default EditSavingCardModal
