import { yupResolver } from '@hookform/resolvers/yup'
import { BannerAlert, Spacer, Text, Theme } from '@truepill/react-capsule'
import { InsuranceType, VpTheme } from '@vpharm-platform/shared'
import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import capitalize from 'lodash/capitalize'
import React, { ReactElement, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { ThemedSelect } from '../../../common/styledComponents/ThemedSelect'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { InsurancePageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { usePatientInsurance } from '../../../hooks/usePatientInsurance'
import PageAlert from '../../../pages/AccountManagement/PageAlert'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { InsuranceFieldValidationSchema, InsuranceWithTypeFieldValidationSchema } from '../../../utils/validation'
import { useLDContextWithLocalStorage } from '../../LDProvider'
import LoadingAnimation from '../../LoadingAnimation'
import WarningBanner from '../../WarningBanner/warningBanner'
import { InsuranceImageUpload } from './InsuranceImageUpload'
import { SampleInsuranceCard } from './SampleInsuranceCard'
import { FormGrid, InsuranceTypeContainer, LoadingAnimationWrapper, StyledActionContainer } from './styledComponents'
import { InsuranceFormFields } from './types'
import { useInsuranceImageUpload } from './useInsuranceImageUpload'

export interface InsuranceFormProps {
  pageContent?: InsurancePageMicrocopy
  theme: VpTheme
  currentInsurance: PatientInsurance | null
  formErrorMessage?: string | null
  handleSubmitForm: (val: PatientInsuranceSubmitPayload) => Promise<void>
  handleCancelForm: () => void
}

const InsuranceForm = ({
  pageContent,
  theme,
  currentInsurance,
  formErrorMessage,
  handleSubmitForm,
  handleCancelForm,
}: InsuranceFormProps): ReactElement => {
  const [isInsuranceImageLoading, setIsInsuranceImageLoading] = useState(false)
  const { isUploadingInsuranceImage, uploadInsuranceImage, customerProfile } = useInsuranceImageUpload()
  const { patientInsuranceList } = usePatientInsurance()

  const featureFlags = useLDContextWithLocalStorage()
  const isAutoRefillEnabled = featureFlags.autoRefills && customerProfile.autoRefills
  const insuranceTypeEnabled = featureFlags.vp5903InsuranceMgmtUpdate

  const form = useForm<InsuranceFormFields>({
    resolver: yupResolver(insuranceTypeEnabled && !currentInsurance ? InsuranceWithTypeFieldValidationSchema : InsuranceFieldValidationSchema),
    mode: 'onChange',
  })

  const {
    control,
    formState: { errors, dirtyFields, isValid, isSubmitting, touchedFields },
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
  } = form
  const [group, pcn] = watch(['rx_group', 'pcn'])

  useEffect(() => {
    if (currentInsurance) {
      reset(currentInsurance)
    }
  }, [currentInsurance, reset])

  const relationshipOptions: ('Cardholder' | 'Spouse' | 'Child' | 'Other')[] = ['Cardholder', 'Spouse', 'Child', 'Other']

  const insuranceTypes: ('Primary (default for most patients)' | 'Secondary')[] = ['Primary (default for most patients)', 'Secondary']

  const optionalLabelText = '(if included on card)'

  const fieldConfig = {
    cardholderId: {
      label: 'Cardholder ID',
      placeholder: 'B03100136180',
      required: true,
    },
    rxBIN: {
      label: 'Rx BIN',
      placeholder: '601341',
      required: true,
    },
    rxPCN: {
      label: 'Rx PCN',
      placeholder: 'OHCP',
      required: false,
    },
    rxGroup: {
      label: 'Rx Group',
      placeholder: 'OH9007041',
      required: false,
    },
    relationshipToCardholder: {
      label: 'Relationship to Cardholder',
      placeholder: 'Select',
      required: true,
    },
    type: {
      label: 'Insurance type',
      placeholder: 'Select',
      required: insuranceTypeEnabled,
    },
  }

  const handleCancelFormWrapper = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCancelForm()
  }

  const sanitizeValue = (field: string): string => {
    return field && capitalize(getInsuranceTypeValue(field))
  }

  const getInsuranceType = (type: string): InsuranceType => {
    const insuranceType = sanitizeValue(type)
    return insuranceType && insuranceType === 'Primary' ? InsuranceType.PRIMARY : InsuranceType.SECONDARY
  }

  const getInsuranceTypeValue = (value: string): string => {
    const regex = /^(\S+)/
    const match = regex.exec(value)
    return match ? match[1] : ''
  }

  const handleSubmitFormWrapper = async (values: InsuranceFormFields) => {
    const insuranceSubmitPayload: PatientInsuranceSubmitPayload = {
      cardholder_id: values.cardholder_id,
      rx_bin: values.rx_bin,
      pcn: values.pcn,
      rx_group: values.rx_group,
      insurance_image_name: values.insurance_image_name,
      relationship_to_primary_cardholder: values.relationship_to_primary_cardholder,
      type: values.type ? getInsuranceType(values.type) : undefined,
    }
    await handleSubmitForm(insuranceSubmitPayload)
  }

  return (
    <>
      {isSubmitting && (
        <LoadingAnimationWrapper vpTheme={theme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      <ThemedHeader vpTheme={theme} id='insurance-modal-title' variant='4xl'>
        {currentInsurance ? 'Edit pharmacy insurance' : 'Add pharmacy insurance'}
      </ThemedHeader>
      <Spacer size='lg' />
      {pageContent?.insuranceModalDisclaimer && <WarningBanner richText={pageContent?.insuranceModalDisclaimer} />}
      {isAutoRefillEnabled ? (
        <PageAlert
          text={
            'Modifying an existing insurance OR adding a new insurance may change the prices for any medications which are currently available to order, and will disable any existing prescription auto refills.'
          }
        />
      ) : (
        <PageAlert
          text={
            'Modifying an existing insurance OR adding a new insurance may change the prices for any medications which are currently available to order.'
          }
        />
      )}

      <Spacer size='sm' />
      <Text css={{ color: theme.colors['typography-medium'] }}>
        {currentInsurance ? 'Any new edits will replace existing insurance information.' : ''}
      </Text>
      <Spacer size='md' />
      {formErrorMessage && (
        <>
          <BannerAlert state='error'>
            <Text>{formErrorMessage}</Text>
          </BannerAlert>
          <Spacer size='lg' />
        </>
      )}
      <Theme theme={theme}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleSubmitFormWrapper)}>
            <FormGrid>
              {insuranceTypeEnabled && (
                <InsuranceTypeContainer
                  primary={patientInsuranceList?.some((i) => i.type === 'PRIMARY')}
                  secondary={patientInsuranceList?.some((i) => i.type === 'SECONDARY')}
                >
                  <Controller
                    name='type'
                    control={control}
                    render={({ field }) => (
                      <ThemedSelect
                        disabled={!!currentInsurance}
                        required
                        showRequiredIndicator
                        label={`${fieldConfig.type.label}`}
                        value={sanitizeValue(field?.value as string)}
                        options={insuranceTypes}
                        onChange={(value: string | undefined) => {
                          if (value) {
                            const val = getInsuranceTypeValue(value)
                            setValue('type', val, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            })
                          }
                        }}
                        placeholder={fieldConfig.type.placeholder}
                        state={parseTextFieldStateForCapsule(errors.type, dirtyFields.type)}
                        className={`${fieldConfig.type.label} ${!fieldConfig.type.required ? optionalLabelText : ''}`}
                        vpTheme={theme}
                      />
                    )}
                  />
                </InsuranceTypeContainer>
              )}
              <div data-testid='relationship-to-cardholder-container'>
                <Controller
                  name='relationship_to_primary_cardholder'
                  control={control}
                  render={({ field }) => (
                    <ThemedSelect
                      required
                      showRequiredIndicator
                      label={`${fieldConfig.relationshipToCardholder.label} ${
                        !fieldConfig.relationshipToCardholder.required ? optionalLabelText : ''
                      }`}
                      value={relationshipOptions.find((option) => option === field.value) || ''}
                      options={relationshipOptions}
                      onChange={(value: string | undefined) => {
                        if (value) {
                          setValue('relationship_to_primary_cardholder', value, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          })
                        }
                      }}
                      placeholder={fieldConfig.relationshipToCardholder.placeholder}
                      state={parseTextFieldStateForCapsule(errors.relationship_to_primary_cardholder, dirtyFields.relationship_to_primary_cardholder)}
                      className={`${fieldConfig.relationshipToCardholder.label} ${
                        !fieldConfig.relationshipToCardholder.required ? optionalLabelText : ''
                      }`}
                      vpTheme={theme}
                    />
                  )}
                />
              </div>
              {!insuranceTypeEnabled && <Spacer size='xl' />}
              <ThemedTextField
                label={`${fieldConfig.cardholderId.label} ${!fieldConfig.cardholderId.required ? optionalLabelText : ''}`}
                {...register('cardholder_id')}
                placeholder={fieldConfig.cardholderId.placeholder}
                helperText={dirtyFields.cardholder_id && touchedFields.cardholder_id ? errors.cardholder_id?.message : undefined}
                state={parseTextFieldStateForCapsule(errors.cardholder_id, dirtyFields.cardholder_id && touchedFields.cardholder_id)}
                aria-label={`${fieldConfig.cardholderId.label} ${!fieldConfig.cardholderId.required ? optionalLabelText : ''}`}
                required={fieldConfig.cardholderId.required}
                showRequiredIndicator
                vpTheme={theme}
              />
              <ThemedTextField
                label={`${fieldConfig.rxBIN.label} ${!fieldConfig.rxBIN.required ? optionalLabelText : ''}`}
                {...register('rx_bin')}
                css={{
                  '.test::after': {
                    content: 'Type your text here',
                    display: 'block',
                  },
                }}
                className='test'
                placeholder={fieldConfig.rxBIN.placeholder}
                helperText={dirtyFields.rx_bin && touchedFields.rx_bin ? errors.rx_bin?.message : undefined}
                state={parseTextFieldStateForCapsule(errors.rx_bin, dirtyFields.rx_bin && touchedFields.rx_bin)}
                aria-label={`${fieldConfig.rxBIN.label} ${!fieldConfig.rxBIN.required ? optionalLabelText : ''}`}
                required={fieldConfig.rxBIN.required}
                showRequiredIndicator
                maxLength={6}
                vpTheme={theme}
              />
              <ThemedTextField
                label={`${fieldConfig.rxPCN.label} ${!fieldConfig.rxPCN.required ? optionalLabelText : ''}`}
                {...register('pcn')}
                placeholder={fieldConfig.rxPCN.placeholder}
                helperText={dirtyFields.pcn && touchedFields.rx_bin ? errors.pcn?.message : undefined}
                state={parseTextFieldStateForCapsule(errors.pcn, dirtyFields.pcn && touchedFields.pcn && !!pcn)}
                aria-label={`${fieldConfig.rxPCN.label} ${!fieldConfig.rxPCN.required ? optionalLabelText : ''}`}
                required={fieldConfig.rxPCN.required}
                showRequiredIndicator
                vpTheme={theme}
              />
              <ThemedTextField
                label={`${fieldConfig.rxGroup.label} ${!fieldConfig.rxGroup.required ? optionalLabelText : ''}`}
                {...register('rx_group')}
                placeholder={fieldConfig.rxGroup.placeholder}
                helperText={dirtyFields.rx_group && touchedFields.rx_group ? errors.rx_group?.message : undefined}
                state={parseTextFieldStateForCapsule(errors.rx_group, dirtyFields.rx_group && touchedFields.rx_group && !!group)}
                aria-label={`${fieldConfig.rxGroup.label} ${!fieldConfig.rxGroup.required ? optionalLabelText : ''}`}
                required={fieldConfig.rxGroup.required}
                showRequiredIndicator
                vpTheme={theme}
              />
            </FormGrid>
            <Spacer size='xl' />
            <SampleInsuranceCard />
            <Spacer size='xl' />
            <InsuranceImageUpload
              insuranceCardImageUrl={currentInsurance?.insurance_card_image_url}
              isUploadingInsuranceImage={isUploadingInsuranceImage}
              uploadInsuranceImage={uploadInsuranceImage}
              setIsInsuranceImageLoading={setIsInsuranceImageLoading}
            />
            <StyledActionContainer>
              <ThemedButton
                disabled={isUploadingInsuranceImage || isSubmitting}
                variant='primary-text'
                onClick={(e) => handleCancelFormWrapper(e)}
                vpTheme={theme}
                role='button'
              >
                {'Cancel'}
              </ThemedButton>
              <ThemedButton role='button' type='submit' disabled={!isValid || isSubmitting || isInsuranceImageLoading} vpTheme={theme}>
                {insuranceTypeEnabled && !currentInsurance ? 'Add card' : 'Save'}
              </ThemedButton>
            </StyledActionContainer>
          </form>
        </FormProvider>
      </Theme>
    </>
  )
}

export default InsuranceForm
