import { Spacer, Text } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { AUTO_REFILLS } from '../../constants'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import { Payment } from '../../hooks/useUserPayments'
import { decideWhichIcon } from '../../utils'
import { capitalizeFirstLetter } from '../../utils/stringUtilities'
import { PaymentCard, PaymentDeleteText, PaymentIcon, StyledActionContainer, StyledHeader } from './styledComponents'

interface Props {
  selectedPayment: Payment
  cancel: () => void
  handleDeletePayment: (paymentMethodId: string) => void
}

const PaymentDelete = ({ cancel, selectedPayment, handleDeletePayment }: Props): ReactElement => {
  const { theme } = useContentfulTheme()
  const { customerProfile } = useCustomerProfile()
  const featureFlags = useLDFlagsWithLocalStorage([AUTO_REFILLS])
  const autoRefillsEnabled = featureFlags.autoRefills && customerProfile.autoRefills

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleDelete = useCallback(() => {
    handleDeletePayment(selectedPayment.paymentMethodId)
  }, [selectedPayment, handleDeletePayment])

  return (
    <>
      <StyledHeader vpTheme={theme} variant='4xl'>
        Are you sure you want to delete this payment method?
      </StyledHeader>
      <Spacer size='lg' />
      <PaymentCard vpTheme={theme}>
        <PaymentIcon src={decideWhichIcon(selectedPayment.brand)} />
        <Spacer size='xs' />
        <Text bold>
          {capitalizeFirstLetter(selectedPayment.brand)} ending in {selectedPayment.last4}
        </Text>
      </PaymentCard>
      <Spacer size='md' />
      <PaymentDeleteText vpTheme={theme}>
        {autoRefillsEnabled && selectedPayment.default
          ? 'Deleting this payment method will remove it from your account and may impact previously scheduled auto refills. This action cannot be undone.'
          : 'Deleting this payment method will remove it from your account.'}
      </PaymentDeleteText>
      <Spacer size='lg' />
      <StyledActionContainer>
        <ThemedButton data-testid='delete-payment-btn-cancel' variant='dark-text' size='lg' onClick={handleCancel} vpTheme={theme}>
          No, keep it
        </ThemedButton>
        <ThemedButton danger={true} data-testid='delete-payment-btn-confirm' size='lg' onClick={handleDelete} vpTheme={theme}>
          Yes, delete
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default PaymentDelete
