import { Header, Spacer, Text } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import { useContentfulCustomerSupport, useContentfulTheme } from 'hooks/contentful'
import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { HeadsetIcon, MaintenanceIcon } from '../../assets/Icons'
import { PhoneIcon } from '../../assets/Icons/PhoneIcon'
import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { employerDetails } from '../../recoil/atoms'
import {
  ContactContainer,
  ContactDescription,
  ContactOption,
  ContactOptionContainer,
  ContactTitle,
  Description,
  MainContainer,
  StyledFooter,
  StyledHeader,
  StyledLogo,
  StyledReservedRights,
  StyledTitle,
} from './styledComponents'

const MaintenanceHeader: React.FC = () => {
  const { employerName, employerLogo } = useRecoilValue(employerDetails)
  const { logoWhite, logo, logoHeight, logoHeightMobile, theme } = useContentfulTheme()

  return (
    <StyledHeader vpTheme={theme}>
      <StyledLogo
        alt={employerName}
        src={logoWhite?.file.url || logo?.file.url || employerLogo}
        maxHeight={logoHeight}
        maxHeightMobile={logoHeightMobile}
      />
    </StyledHeader>
  )
}

const MaintenanceFooter: React.FC = () => {
  const { pharmCustomer } = useCustomerConfigContext()
  const { theme } = useContentfulTheme()

  const currentYear = new Date().getUTCFullYear()
  const copyrightYear =
    pharmCustomer?.initialCopyrightYear && pharmCustomer?.initialCopyrightYear < currentYear
      ? `${pharmCustomer?.initialCopyrightYear}-${currentYear}`
      : currentYear

  return (
    <StyledFooter vpTheme={theme}>
      <StyledReservedRights>
        <Text>© {copyrightYear} Truepill. All rights reserved.</Text>
      </StyledReservedRights>
    </StyledFooter>
  )
}

const MaintenancePage: React.FC = () => {
  const { content: customerSupport } = useContentfulCustomerSupport()
  const { theme } = useContentfulTheme()

  useEffect(() => {
    const meta = document.createElement('meta')
    meta.name = 'robots'
    meta.content = 'noindex'
    document.getElementsByTagName('head')[0]?.appendChild(meta)
  }, [])
  return (
    <>
      <MaintenanceHeader />
      <StyledTitle>
        <Spacer size='2xl' />
        <Header variant='5xl'>Page under maintenance</Header>
      </StyledTitle>
      <MainContainer>
        <Spacer size='2xl' />
        <MaintenanceIcon vpTheme={theme} />
        <Spacer size='xl' />
        <Header variant='4xl'>We’ll be back soon</Header>
        <Spacer size='lg' />
        <Description vpTheme={theme}>
          Sorry for the inconvenience. This page is down for scheduled maintenance at the moment. Check back again soon.
        </Description>
        <Spacer size='3xl' />
        {customerSupport?.email && (
          <ContactContainer vpTheme={theme}>
            <ContactTitle bold vpTheme={theme}>
              Need to get in touch?
            </ContactTitle>
            <Spacer size='lg' />
            <ContactDescription vpTheme={theme}>Contact us and we’ll respond as soon as we can.</ContactDescription>
            <Spacer size='2xl' />
            <ContactOptionContainer>
              <HeadsetIcon vpTheme={theme} />
              <ContactOption href={`mailto:${customerSupport.email}`} vpTheme={theme}>
                {customerSupport.email}
              </ContactOption>
            </ContactOptionContainer>
            <Spacer />
            {customerSupport?.displaySupportNumber && (
              <ContactOptionContainer>
                <PhoneIcon vpTheme={theme} />
                <ContactOption href={`tel:${customerSupport.phone}`} vpTheme={theme}>
                  {formatToPhone(customerSupport.phone)}
                </ContactOption>
              </ContactOptionContainer>
            )}
          </ContactContainer>
        )}
        <Spacer size='4xl' />
      </MainContainer>
      <MaintenanceFooter />
    </>
  )
}

export default MaintenancePage
