import { sharedConstants } from '@vpharm-platform/shared'
import { useHistory } from 'react-router-dom'

import { useAnalytics } from '../../../../hooks/analytics-context'

interface UseSavingsCardBannerReturnValue {
  onBannerClick: () => void
}

const { RoutePaths } = sharedConstants

export const useSavingsCardBanner = (): UseSavingsCardBannerReturnValue => {
  const { trackButtonClickEvent } = useAnalytics()
  const history = useHistory()

  const onBannerClick = () => {
    history.push(RoutePaths.PRESCRIPTION_MANAGEMENT_PATH)
    trackButtonClickEvent('insurance_management_savings_card_banner_rxm_link', 'Prescription Manager')
  }

  return {
    onBannerClick,
  }
}
