import { defaultTheme } from '@vpharm-platform/shared'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

const Container = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  padding: 1.5rem 1.5rem 0.5rem;
  border-radius: 0.5rem;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    justify-content: space-between;
    max-width: 85%;
  }
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TextButton = styled(ThemedButton)<{ textColor?: string }>`
  color: ${(props) => props.textColor ?? defaultTheme.colors['primary-500']} !important;
  padding: 0;
  display: inline;
  text-decoration: underline;
  text-underline-offset: 4px;
`

const EmptyState = styled.div<ThemedComponent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium']};
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-300']};

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    padding: 2.25rem 1rem;
    text-align: left;
    > svg {
      flex-shrink: 0;
      width: 2.75rem;
      height: 2.75rem;
    }
  }
`

const EmptyStateContent = styled.div`
  margin-top: 0.5rem;

  ${mediaLargerThan.tablet} {
    margin-top: 0;
    margin-left: 1rem;
  }
`

const StyledLink = styled(Link)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
`

export const StyledAutoRefill = {
  Container,
  List,
  InfoContainer,
  TextButton,
  EmptyState,
  EmptyStateContent,
  StyledLink,
}
