import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const Container = styled.div``

const OptionContainerBase = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  box-shadow: 0px 4px 4px -2px ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  border-width: 2px;
  padding: 1.5rem;
  border-radius: 8px;

  > svg {
    display: none;
  }

  :has(button:nth-of-type(1):hover) {
    border-color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  }

  ${mediaLargerThan.tablet} {
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      display: block;
    }
  }
`

const TransferOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.625rem;

  ${mediaLargerThan.tablet} {
    margin-bottom: 7rem;
  }
`

const TransferInOptionContainer = styled(OptionContainerBase)``

const TransferOutOptionContainer = styled(OptionContainerBase)``

const TransferButton = styled(ThemedButton)<{ reversed?: boolean; $backgroundColor?: string; $borderColor?: string; $hoverColor?: string }>`
  display: flex;
  flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  align-items: center;
  gap: 0.5rem;
  min-width: 180px;

  background-color: ${({ $backgroundColor }) => $backgroundColor ?? defaultTheme.colors['primary-700']};
  border-color: ${({ $borderColor }) => $borderColor ?? defaultTheme.colors['primary-700']};

  &&:hover {
    background-color: ${({ $hoverColor }) => $hoverColor ?? defaultTheme.colors['primary-500']};
    border-color: ${({ $hoverColor }) => $hoverColor ?? defaultTheme.colors['primary-500']};
  }
`

const NoTransfersContainer = styled.div<ThemedComponent>`
  padding: 1.5rem;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  border-radius: 0.5rem;
`

export const StyledPrescriptionTransferV2 = {
  Container,
  TransferContainer,
  OptionContainerBase,
  TransferInOptionContainer,
  TransferButton,
  TransferOutOptionContainer,
  TransferOptionContainer,
  NoTransfersContainer,
}
