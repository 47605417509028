import { Spacer, Text } from '@truepill/react-capsule'
import { InsuranceInfo } from '@vpharm-platform/shared'
import { useState } from 'react'

import { useContentfulTheme } from '../../../hooks'
import { ExpanderContainer, ShowLessContainer, ShowMoreContainer, ShowMoreLessButton } from './styledComponents'

export interface InsuranceSwapTagProps {
  insurances?: InsuranceInfo[]
  savingCards?: InsuranceInfo[]
}

export const InsuranceSwapTag = ({ insurances, savingCards }: InsuranceSwapTagProps): React.ReactElement => {
  const [showAll, setShowAll] = useState(false)

  const { theme } = useContentfulTheme()

  const showAllMessage = () => {
    setShowAll(!showAll)
  }

  return (
    <ExpanderContainer>
      {!showAll ? (
        <ShowMoreContainer vpTheme={theme}>
          <Text style={{ display: 'inline' }} variant='body-sm'>
            The insurance or savings card details you provided were incorrect.
          </Text>
          <ShowMoreLessButton type='button' variant='primary-text' onClick={showAllMessage} vpTheme={theme}>
            <Text style={{ paddingLeft: '0.1rem' }} variant='body-sm'>
              {showAll ? 'Less..' : 'More...'}
            </Text>
          </ShowMoreLessButton>
        </ShowMoreContainer>
      ) : (
        <ShowLessContainer vpTheme={theme}>
          <Text variant='body-sm'>
            The insurance or savings card details you provided were incorrect. We&apos;ve updated your information and processed your copay price
            request with the new details.
            <ShowMoreLessButton type='button' variant='primary-text' onClick={showAllMessage} data-testid='transfer-card-more-button' vpTheme={theme}>
              <Text style={{ paddingLeft: '0.1rem' }} variant='body-sm'>
                Less...
              </Text>
            </ShowMoreLessButton>
          </Text>
          <Spacer size='md' />

          {insurances?.map((insurance, index) => (
            <VerifyInsuranceInfo key={`insurance-${index}`} data={insurance} type={'Insurance'} />
          ))}
          {savingCards?.map((savingsCard, index) => (
            <VerifyInsuranceInfo key={`savings-card-${index}`} data={savingsCard} type={'Savings Card'} />
          ))}
        </ShowLessContainer>
      )}
    </ExpanderContainer>
  )
}

const VerifyInsuranceInfo = ({ data, type }: { data: InsuranceInfo; type: string }) => (
  <>
    {' '}
    <Text variant='body-sm'>Please review the details below for accuracy:</Text>
    <Spacer size='md' />
    <Text variant='body-sm'>
      <ul>
        <li style={{ fontWeight: '700' }}>Type: {type}</li>
        <li>Cardholder ID: {data.cardholder_id}</li>
        <li>Rx BIN: {data.rx_bin}</li>
        <li>Rx PCN: {data.pcn}</li>
        <li>Rx Group: {data.rx_group}</li>
      </ul>
    </Text>
  </>
)

export default InsuranceSwapTag
