import { GetSavingCardResponse, MedicationInfo, PrescriptionDisplayStatus, PutSavingCardParams } from '@vpharm-platform/shared'
import axios from 'axios'
import { useCallback, useState } from 'react'
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil'

import { CustomerProfile, PatientInsuranceSubmitPayload } from '../interfaces'
import { prescriptionsByAvailability } from '../recoil/atoms'
import { SavingsCardService } from '../services/SavingsCardService'
import StorageService from '../services/StorageService'
import { useCustomerProfile, useUpdateCart } from '.'
import { usePatientToken } from './usePatientToken'

interface UseSavingsCard {
  fetchSavingsCard: (ndc: string) => Promise<GetSavingCardResponse>
  deleteSavingCard: (prescriptionToken: string, ndc: string) => Promise<void>
  submitSavingCard: (savingCardPayload: PutSavingCardParams, prescriptionToken: string) => Promise<void>
  signUpSavingCard: (ndc: string) => Promise<void>
  signUpSavingCardWithNewInsurance: (ndc: string, newPatientInsurance: PatientInsuranceSubmitPayload) => Promise<void>
  toastMessage: string
  uploadSavingCardImage: (image: File) => Promise<string>
  isUploadingSavingCardImage: boolean
  customerProfile: CustomerProfile
}

export const useSavingsCard = (): UseSavingsCard => {
  const { customerProfile } = useCustomerProfile()
  const patientToken = usePatientToken()
  const [toastMessage, setToastMessage] = useState('')
  const [isUploadingSavingCardImage, setIsUploadingImage] = useState<boolean>(false)
  const [prescriptionListLoadable] = useRecoilStateLoadable(prescriptionsByAvailability)
  const setPrescriptionState = useSetRecoilState(prescriptionsByAvailability)
  const updateCart = useUpdateCart()

  const fetchSavingsCard = useCallback(
    (ndc: string): Promise<GetSavingCardResponse> => {
      const paymentService = new SavingsCardService()
      try {
        return paymentService.getSavingCard(ndc, patientToken, customerProfile.vpharmCustomerToken)
      } catch (e) {
        throw new Error('Unable to fetch savings card')
      }
    },
    [customerProfile.vpharmCustomerToken, patientToken],
  )

  const uploadSavingCardImage = async (savingCardImageFile: File): Promise<string> => {
    const storageService = new StorageService()
    const form = new FormData()
    setIsUploadingImage(true)
    try {
      const fileExtension = savingCardImageFile.type.split('/')[1] as 'jpeg' | 'png' | 'jpg'
      form.append('Content-Type', savingCardImageFile.type)
      const savingCardUploadInfo = await storageService.requestSavingCardUpload(fileExtension, patientToken, customerProfile.vpharmCustomerToken)
      const savingCardImageKey = savingCardUploadInfo.fields.key.split('/')[3]

      Object.entries(savingCardUploadInfo.fields).forEach(([key, val]) => {
        form.append(key, val)
      })

      form.append('file', savingCardImageFile)

      await axios.post(savingCardUploadInfo.url, form)
      return savingCardImageKey
    } catch (e) {
      throw new Error('Unable to upload saving card image')
    } finally {
      setIsUploadingImage(false)
    }
  }

  const submitSavingCard = async (savingCardPayload: PutSavingCardParams, prescriptionToken: string): Promise<void> => {
    const savingCardService = new SavingsCardService()
    try {
      await savingCardService.addSavingCard(customerProfile.vpharmCustomerToken, patientToken, savingCardPayload)
      setToastMessage('Your savings card has been added.')
      updateCart.deleteByPrescriptionToken(prescriptionToken)
      const updatedUnavailableToOrder = prescriptionListLoadable.contents.unavailableToOrder.map((medication: MedicationInfo): MedicationInfo => {
        if (medication.prescriptionToken === prescriptionToken) {
          return {
            ...medication,
            prescriptionStatus: PrescriptionDisplayStatus.COPAY_IN_PROGRESS,
            savingsCard: {
              ...medication.savingsCard,
              appliedSavingsCard: {
                isSavingsCardEditable: true,
              },
            },
          }
        }

        return medication
      })

      const updatedAvailableToOrder = prescriptionListLoadable.contents.availableToOrder.map((medication: MedicationInfo): MedicationInfo => {
        if (medication.prescriptionToken === prescriptionToken) {
          return {
            ...medication,
            prescriptionStatus: PrescriptionDisplayStatus.COPAY_IN_PROGRESS,
            savingsCard: {
              ...medication.savingsCard,
              appliedSavingsCard: {
                isSavingsCardEditable: true,
              },
            },
          }
        }

        return medication
      })

      setPrescriptionState({ unavailableToOrder: updatedUnavailableToOrder, availableToOrder: updatedAvailableToOrder })
    } catch (e) {
      setToastMessage('An unexpected error occurred, unable to create saving card.')
      throw new Error('Unable to create saving card')
    }
  }

  const signUpSavingCard = async (ndc: string): Promise<void> => {
    const savingCardService = new SavingsCardService()
    try {
      await savingCardService.savingCardSignUp(customerProfile.vpharmCustomerToken, patientToken, ndc)
      updateCart.deleteByNdc(ndc)
    } catch (e) {
      console.error('Error applying saving card sign up: ', e)
      setToastMessage('There was a problem creating your savings card. Please')
      throw new Error('Unable to create saving card')
    }
  }

  const signUpSavingCardWithNewInsurance = async (ndc: string, newPatientInsurance: PatientInsuranceSubmitPayload): Promise<void> => {
    const savingCardService = new SavingsCardService()
    try {
      await savingCardService.savingCardSignUpWithNewInsurance(customerProfile.vpharmCustomerToken, patientToken, ndc, newPatientInsurance)
      updateCart.deleteByNdc(ndc)
    } catch (e) {
      setToastMessage('There was a problem creating your insurance or savings card. Please')
      throw new Error('Unable to create saving card')
    }
  }

  const deleteSavingCard = async (prescriptionToken: string, ndc: string): Promise<void> => {
    const savingCardService = new SavingsCardService()
    try {
      await savingCardService.deleteSavingCard(customerProfile.vpharmCustomerToken, patientToken, ndc)
      setToastMessage('Your savings card has been deleted successfully.')
      updateCart.deleteByPrescriptionToken(prescriptionToken)

      const updatedUnavailableToOrder = prescriptionListLoadable.contents.unavailableToOrder.map((medication: MedicationInfo): MedicationInfo => {
        if (medication.prescriptionToken === prescriptionToken) {
          return {
            ...medication,
            savingsCard: {
              ...medication.savingsCard,
              appliedSavingsCard: null,
            },
          }
        }

        return medication
      })

      const updatedAvailableToOrder = prescriptionListLoadable.contents.availableToOrder.map((medication: MedicationInfo): MedicationInfo => {
        if (medication.prescriptionToken === prescriptionToken) {
          return {
            ...medication,
            savingsCard: {
              ...medication.savingsCard,
              appliedSavingsCard: null,
            },
          }
        }

        return medication
      })
      setPrescriptionState({ unavailableToOrder: updatedUnavailableToOrder, availableToOrder: updatedAvailableToOrder })
    } catch (e) {
      setToastMessage('An unexpected error occurred, unable to delete the saving card.')
      throw new Error('Unable to delete saving card.')
    }
  }

  return {
    fetchSavingsCard,
    submitSavingCard,
    signUpSavingCard,
    signUpSavingCardWithNewInsurance,
    toastMessage,
    uploadSavingCardImage,
    isUploadingSavingCardImage,
    deleteSavingCard,
    customerProfile,
  }
}
