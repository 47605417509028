import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { NavigationClick as NavigationClickType } from '../../interfaces/Header'
import { StyledButton, StyledLinkWrapper, StyledSpan } from './styledComponents'

interface NavigationClickProps {
  link: NavigationClickType
}

export const NavigationClick = ({ link }: NavigationClickProps): JSX.Element => {
  const { trackLinkClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  return (
    <StyledLinkWrapper vpTheme={theme}>
      <StyledButton
        onClick={() => {
          trackLinkClickEvent(link.name, link.name, LinkOrigin.Header)
          link.onClick()
        }}
        vpTheme={theme}
      >
        {link.icon}
        <StyledSpan vpTheme={theme}>{link.name}</StyledSpan>
      </StyledButton>
    </StyledLinkWrapper>
  )
}
