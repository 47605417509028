import { MenuIcon } from 'assets/Icons'
import { LAPTOP_BREAK_POINT } from 'common/styles/variables'
import { useCustomerProfile } from 'hooks'
import { useContentfulTheme } from 'hooks/contentful'
import { useMatchMinMediaQuery } from 'hooks/useMatchMediaQuery'
import { lazy, ReactElement, Suspense, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { IDENTITY_VERIFICATION } from '../../constants'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { LogoProps } from '../../interfaces/Header'
import { MobileNavigationDrawer } from './MobileNavigationDrawer'
import { SignedInNavLinks } from './SignedInNavLinks'
import { SignedOutNav } from './SignedOutNav'
import * as StyledHeader from './styledComponents'
import { useHeader } from './useHeader'

const CartPreview = lazy(() => import('./CartPreview'))
const HeaderBanner = lazy(() => import('Components/HeaderBanner'))

const redirectUrls = [IDENTITY_VERIFICATION.toString()]

const Logo = ({ employerName, employerLogo }: LogoProps): ReactElement => {
  const { logo, logoWhite, logoHeight, logoHeightMobile } = useContentfulTheme()
  const { pathname, search } = useLocation()
  const { trackLinkClickEvent } = useAnalytics()

  const redirectTo = redirectUrls.includes(pathname) ? `/?redirect_to=${pathname + search}` : '/'

  return (
    <Link onClick={() => trackLinkClickEvent('Logo', '/', LinkOrigin.Header)} to={redirectTo} data-testid='logo-nav-url'>
      <div style={{ display: 'flex' }}>
        <StyledHeader.StyledLogo
          alt={employerName}
          src={logoWhite?.file.url || logo?.file.url || employerLogo}
          maxHeight={logoHeight}
          maxHeightMobile={logoHeightMobile}
        />
      </div>
    </Link>
  )
}

const Header = (): ReactElement | null => {
  const isDesktop = useMatchMinMediaQuery(LAPTOP_BREAK_POINT)
  const { customerProfile } = useCustomerProfile()
  const { theme } = useContentfulTheme()

  const {
    cartPreview,
    employerInfo,
    handleHeaderBannerClosed,
    handleCheckoutButtonClick,
    handleNavigationDrawerToggle,
    handlePreviewCartModalDismiss,
    handleViewCartButtonClick,
    headerBanner,
    isNavigationDrawerOpen,
    numberOfCartItems,
    resetCartPreview,
    setCartPreview,
    handleCartClick,
    isAuthenticated,
  } = useHeader()

  const {
    visible: cartPreviewVisible,
    addedMedicationInfo: {
      name: addedMedicationName,
      quantity: addedMedicationQuantity,
      quantityUnits,
      daysSupply: addedMedicationDaysSupply,
      price: addedMedicationPrice,
    },
  } = cartPreview

  // auto-closes the cart modal
  useEffect(() => {
    const timerId = setTimeout(() => resetCartPreview(), 10000)

    return () => clearTimeout(timerId)
  }, [cartPreviewVisible, resetCartPreview, setCartPreview])

  return (
    <>
      <StyledHeader.StyledHeader vpTheme={theme} hasBoxShadow>
        <Logo employerName={employerInfo.name} employerLogo={employerInfo.logo} />
        <StyledHeader.StyledNav>
          {isAuthenticated ? (
            <SignedInNavLinks
              numberOfCartItems={numberOfCartItems}
              onCartClick={handleCartClick}
              background={theme.colors.navBackground ?? theme.colors.white}
              itemColor={theme.colors.navItem ?? theme.colors.white}
            />
          ) : (
            <SignedOutNav isSelfEnrollmentEnabled={customerProfile.allowSelfEnrollment} isCustomerTerminated={customerProfile.isBeingTerminated} />
          )}
          <StyledHeader.StyledMenuButton onClick={handleNavigationDrawerToggle} aria-label='Menu'>
            <MenuIcon vpTheme={theme} />
          </StyledHeader.StyledMenuButton>
        </StyledHeader.StyledNav>
      </StyledHeader.StyledHeader>

      <Suspense fallback={<></>}>
        <HeaderBanner
          isOpen={headerBanner.showHeaderBanner()}
          handleClose={handleHeaderBannerClosed}
          mobileText={headerBanner.mobileText}
          desktopText={headerBanner.desktopText}
          screenBreakPoint={903}
        />
      </Suspense>

      <Suspense fallback={<></>}>
        <CartPreview
          onModalDismiss={handlePreviewCartModalDismiss}
          onViewCartButtonClick={handleViewCartButtonClick}
          onCheckoutButtonClick={handleCheckoutButtonClick}
          visible={cartPreviewVisible}
          medicationName={addedMedicationName}
          quantityAmount={addedMedicationQuantity}
          quantityUnits={quantityUnits}
          daysSupply={addedMedicationDaysSupply}
          price={addedMedicationPrice}
          cartCount={numberOfCartItems}
        />
      </Suspense>
      {!isDesktop ? (
        <MobileNavigationDrawer
          isOpen={isNavigationDrawerOpen}
          isAuthenticated={isAuthenticated}
          onDismiss={handleNavigationDrawerToggle}
          isCustomerTerminated={customerProfile.isBeingTerminated}
          allowSelfEnrollment={customerProfile.allowSelfEnrollment}
          background={theme.colors.navBackground ?? theme.colors.white}
          itemColor={theme.colors.navItem ?? theme.colors['typography-dark']}
        />
      ) : null}
    </>
  )
}

export default Header
