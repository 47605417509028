import { Text } from '@truepill/react-capsule'
import { Link } from 'react-router-dom'

import { SELF_ENROLLMENT_PATH } from '../../constants'
import { StyledSelfEnrollmentLink } from './styledComponents'

const SelfEnrollmentLink = (): React.ReactElement => (
  <StyledSelfEnrollmentLink.MainContainer>
    <StyledSelfEnrollmentLink.LinkContainer>
      <Text>Don&apos;t have an account yet?</Text>
      <Link to={SELF_ENROLLMENT_PATH}>Create one here</Link>
    </StyledSelfEnrollmentLink.LinkContainer>
  </StyledSelfEnrollmentLink.MainContainer>
)

export default SelfEnrollmentLink
