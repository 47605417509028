import { Text, theme } from '@truepill/react-capsule'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../common/styles/variables'

const Container = styled.div`
  border-radius: 8px;
  background: linear-gradient(180deg, #f6f3ee 0%, rgba(255, 255, 255, 0) 100%);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${mediaLargerThan.laptop} {
    max-width: 360px;
  }
`

const MainImage = styled.img`
  display: block;
`

const Subtext = styled(Text)`
  max-width: 300px;
  color: ${theme.colors['typography-medium'].computedValue};
  text-align: center;
  margin-top: 0.65rem;
`

export const StyledCartBanner = {
  Container,
  MainImage,
  Subtext,
}
