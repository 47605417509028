import { PaymentMethod, Stripe, StripeCardNumberElement } from '@stripe/stripe-js'
import { GetPaymentResponse } from '@vpharm-platform/shared'

import { UserService } from './userService'

class PaymentService {
  constructor(private stripe: Stripe, private userService: UserService, private customerToken: string) {}

  private async attachPaymentToCustomer(paymentMethodId: string, isDefault: boolean, securityKey: string, save: boolean): Promise<void> {
    await this.userService.createPaymentMethod(this.customerToken, paymentMethodId, isDefault, securityKey, save)
  }

  private async createCardPaymentMethod(cardholderName: string, zip: string, card: StripeCardNumberElement): Promise<PaymentMethod> {
    const securityKey = Math.random().toString(36).slice(2)
    const paymentMethodResult = await this.stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        name: cardholderName,
        address: { postal_code: zip },
      },
      metadata: {
        securityKey,
      },
    })

    if (!paymentMethodResult || !paymentMethodResult.paymentMethod) {
      throw new Error('Failed to create payment method.')
    }

    return { ...paymentMethodResult.paymentMethod, metadata: { securityKey } }
  }

  async createAndAttachCardPaymentMethod(
    cardholderName: string,
    zip: string,
    isDefault: boolean,
    save: boolean,
    card: StripeCardNumberElement,
  ): Promise<PaymentMethod> {
    const paymentMethodResult = await this.createCardPaymentMethod(cardholderName, zip, card)
    await this.attachPaymentToCustomer(paymentMethodResult.id, isDefault, paymentMethodResult.metadata.securityKey, save)
    return paymentMethodResult
  }

  getPaymentMethods(): Promise<GetPaymentResponse> {
    return this.userService.getUserPayments(this.customerToken)
  }

  async setPaymentAsDefault(paymentMethodId: string): Promise<void> {
    await this.userService.setDefaultPaymentMethod(this.customerToken, paymentMethodId)
  }

  async deletePaymentMethod(paymentMethodId: string): Promise<void> {
    await this.userService.deletePaymentMethod(this.customerToken, paymentMethodId)
  }
}

export default PaymentService
