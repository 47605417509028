export interface PrescriptionCardLabels {
  refillsRemaining: string | undefined
  lastOrderedOn?: string | undefined
  prescribedBy: string | undefined
  prescribedOn: string | undefined
  rxNumber: string | undefined
  quantity: string | undefined
  form: string | undefined
  strength: string | undefined
  expiresOn: string | undefined
}

export enum TakenBeforeStatus {
  Yes = 'yes',
  No = 'no',
  Unsure = 'unsure',
}
