import { useUpdateCart } from 'hooks'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'

const CartExpirationCheck = (): React.ReactElement => {
  const { cart, remove, deleteRemovedItems } = useUpdateCart()

  const checkExpiredMedications = useCallback(() => {
    cart.medications.forEach((m) => {
      if (m.prescriptionExpirationDate) {
        const expDate = moment(m.prescriptionExpirationDate)
        const today = moment()

        if (today.diff(expDate, 'seconds') > 0) {
          remove(m.rxNumber)
        }
      }
    })

    deleteRemovedItems()
  }, [cart.medications, deleteRemovedItems, remove])

  useEffect(() => {
    const interval = setInterval(checkExpiredMedications, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [checkExpiredMedications])

  return <></>
}

export default CartExpirationCheck
