import { VpTheme } from '@vpharm-platform/shared'

import { PrescriptionOptionText } from './styledComponents'

export interface BaseOptions {
  label: string
  vpTheme?: VpTheme
}

export interface OptionComponentProps<T extends BaseOptions> {
  option: T
  isHighlighted: boolean
  isDisabled: boolean
}

function OptionComponent<T extends BaseOptions>({ option, isDisabled }: OptionComponentProps<T>): React.ReactElement {
  return (
    <PrescriptionOptionText isDisabled={isDisabled} vpTheme={option.vpTheme}>
      {option.label}
    </PrescriptionOptionText>
  )
}

export default OptionComponent
