import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const StepTwoIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill={vpTheme.colors['primary-500']} />
      <path
        d='M16.02 9.38C16.6867 9.38 17.2967 9.48 17.85 9.68C18.4033 9.87333 18.8767 10.15 19.27 10.51C19.6633 10.87 19.97 11.3033 20.19 11.81C20.41 12.3167 20.52 12.88 20.52 13.5C20.52 14.0333 20.44 14.5267 20.28 14.98C20.1267 15.4333 19.9167 15.8667 19.65 16.28C19.39 16.6933 19.0833 17.0933 18.73 17.48C18.3767 17.8667 18.0067 18.26 17.62 18.66L14.36 21.99C14.68 21.8967 14.9933 21.8267 15.3 21.78C15.6067 21.7267 15.9 21.7 16.18 21.7H19.89C20.1567 21.7 20.3667 21.7767 20.52 21.93C20.68 22.0767 20.76 22.2733 20.76 22.52V24H10.84V23.18C10.84 23.0133 10.8733 22.84 10.94 22.66C11.0133 22.48 11.1267 22.3133 11.28 22.16L15.67 17.76C16.0367 17.3867 16.3633 17.03 16.65 16.69C16.9433 16.35 17.1867 16.0133 17.38 15.68C17.58 15.3467 17.73 15.01 17.83 14.67C17.9367 14.3233 17.99 13.96 17.99 13.58C17.99 13.2333 17.94 12.93 17.84 12.67C17.74 12.4033 17.5967 12.18 17.41 12C17.2233 11.82 17 11.6867 16.74 11.6C16.4867 11.5067 16.2 11.46 15.88 11.46C15.2867 11.46 14.7967 11.61 14.41 11.91C14.03 12.21 13.7633 12.6133 13.61 13.12C13.5367 13.3733 13.4267 13.5567 13.28 13.67C13.1333 13.7767 12.9467 13.83 12.72 13.83C12.62 13.83 12.51 13.82 12.39 13.8L11.09 13.57C11.19 12.8767 11.3833 12.27 11.67 11.75C11.9567 11.2233 12.3133 10.7867 12.74 10.44C13.1733 10.0867 13.6667 9.82333 14.22 9.65C14.78 9.47 15.38 9.38 16.02 9.38Z'
        fill={vpTheme.colors.grayWhite}
      />
    </svg>
  )
}
