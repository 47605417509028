import { useContentfulTheme } from '../../hooks'
import { StyledSuggestion } from './styledComponents'

interface SuggestionProps<T> {
  suggestionFormatter: (suggestion: T) => string
  suggestion: T
  handleSuggestionSelect: (e: React.SyntheticEvent, suggestion: T) => void
}

const Suggestion = <T,>({ suggestion, handleSuggestionSelect, suggestionFormatter }: SuggestionProps<T>): React.ReactElement => {
  const { theme } = useContentfulTheme()
  return (
    <StyledSuggestion
      onClick={(e) => handleSuggestionSelect(e, suggestion)}
      role='option'
      aria-selected='true'
      tabIndex={0}
      data-testid='autocomplete-entry'
      vpTheme={theme}
    >
      <span>{suggestionFormatter(suggestion)}</span>
    </StyledSuggestion>
  )
}

export default Suggestion
