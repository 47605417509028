/* eslint-disable import/no-duplicates */
import { TransferMedicationDetail } from '@vpharm-platform/shared'
import format from 'date-fns/format'
import enUS from 'date-fns/locale/en-US'
import React from 'react'

import { HealthStoreIcon } from '../../../../assets/Icons'
import { TRANSFER_IN_PAGE_ENHANCEMENTS } from '../../../../constants/feature-flags'
import { useLDFlagsWithLocalStorage } from '../../../../hooks/useLDFlagsWithLocalStorage'
import { StyledTransferDetailsCard } from './styledComponents'

interface Props {
  medication: TransferMedicationDetail
}

const TransferDetailsCard: React.FC<Props> = (props) => {
  const { medication } = props
  const { [TRANSFER_IN_PAGE_ENHANCEMENTS]: enhancementsFeatureFlag } = useLDFlagsWithLocalStorage([TRANSFER_IN_PAGE_ENHANCEMENTS])

  return (
    <>
      <StyledTransferDetailsCard.StyledHeaderCard shadow='sm' data-testid='transfer-card-detail-header'>
        Transfer #{medication.transferToken} • {format(new Date(medication.createdAt), 'dd MMM yyyy', { locale: enUS })}
      </StyledTransferDetailsCard.StyledHeaderCard>
      <StyledTransferDetailsCard.StyledBodyCard shadow='sm'>
        <StyledTransferDetailsCard.PharmacyInfo>
          <HealthStoreIcon />
          <StyledTransferDetailsCard.StyledSpan data-testid='transfer-card-pharmacy'>
            Pharmacy: {medication.pharmacyName} {medication.pharmacyPhone && `• ${medication.pharmacyPhone}`}
          </StyledTransferDetailsCard.StyledSpan>
        </StyledTransferDetailsCard.PharmacyInfo>
        <StyledTransferDetailsCard.TransferItemGrid displayAllColumns={!enhancementsFeatureFlag}>
          {!enhancementsFeatureFlag && (
            <>
              <StyledTransferDetailsCard.TransferItem>
                <StyledTransferDetailsCard.TransferDetailsLabel data-testid='transfer-card-qty-label'>
                  Quantity
                </StyledTransferDetailsCard.TransferDetailsLabel>
                <StyledTransferDetailsCard.TransferDetailsValue data-testid='transfer-card-qty'>
                  {medication.quantity ?? '-'}
                </StyledTransferDetailsCard.TransferDetailsValue>
              </StyledTransferDetailsCard.TransferItem>
              <StyledTransferDetailsCard.TransferItem>
                <StyledTransferDetailsCard.TransferDetailsLabel data-testid='transfer-card-strength-label'>
                  Strength
                </StyledTransferDetailsCard.TransferDetailsLabel>
                <StyledTransferDetailsCard.TransferDetailsValue data-testid='transfer-card-strength'>
                  {medication.strength}
                </StyledTransferDetailsCard.TransferDetailsValue>
              </StyledTransferDetailsCard.TransferItem>
            </>
          )}
          <StyledTransferDetailsCard.TransferItem>
            <StyledTransferDetailsCard.TransferDetailsLabel data-testid='transfer-card-prescriber-name-label'>
              Prescriber
            </StyledTransferDetailsCard.TransferDetailsLabel>
            <StyledTransferDetailsCard.TransferDetailsValue data-testid='transfer-card-prescriber-name'>
              {medication.prescriberName}
            </StyledTransferDetailsCard.TransferDetailsValue>
          </StyledTransferDetailsCard.TransferItem>
          <StyledTransferDetailsCard.TransferItem>
            <StyledTransferDetailsCard.TransferDetailsLabel data-testid='transfer-card-prescriber-phone-label'>
              Prescriber Phone
            </StyledTransferDetailsCard.TransferDetailsLabel>
            <StyledTransferDetailsCard.TransferDetailsValue data-testid='transfer-card-prescriber-phone'>
              {medication.prescriberPhone}
            </StyledTransferDetailsCard.TransferDetailsValue>
          </StyledTransferDetailsCard.TransferItem>
        </StyledTransferDetailsCard.TransferItemGrid>
      </StyledTransferDetailsCard.StyledBodyCard>
    </>
  )
}

export default TransferDetailsCard
