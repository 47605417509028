import { Divider, Header, Spacer, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { mediaLargerThan } from 'common/styles/variables'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { ITextWithHeaderFields } from '../../types/generated/contentful'
import RichTextRenderer from '../RichTextRenderer'
import { DividerContainer, InfoTextContainer, InfoTextImageContainer, TextWithoutImageContainer } from './styledComponents'

// Note: This component's colors are set in the "Text with Header"  Contentful model

const TextWithHeader: React.FC<ITextWithHeaderFields> = ({
  name,
  backgroundColor,
  header,
  subtitle,
  title,
  titleSubtitleTextColor,
  image,
  headerTextColor,
  imagePositionLeft,
}) => {
  const { theme } = useContentfulTheme()

  return !image ? (
    <InfoTextContainer backgroundColor={backgroundColor} vpTheme={theme}>
      <InfoTextWithoutImage
        header={header}
        title={title}
        subtitle={subtitle}
        name={name}
        headerTextColor={headerTextColor}
        titleSubtitleTextColor={titleSubtitleTextColor}
        image={image}
      />
    </InfoTextContainer>
  ) : (
    <InfoTextContainer backgroundColor={backgroundColor} vpTheme={theme}>
      <InfoTextWithImage
        header={header}
        title={title}
        subtitle={subtitle}
        name={name}
        headerTextColor={headerTextColor}
        image={image}
        imagePositionLeft={imagePositionLeft}
      />
    </InfoTextContainer>
  )
}

const InfoTextWithoutImage: React.FC<ITextWithHeaderFields> = ({ header, subtitle, title, headerTextColor, titleSubtitleTextColor, image }) => {
  const { theme } = useContentfulTheme()

  return (
    <TextWithoutImageContainer image={!!image}>
      {header && (
        <>
          <Text bold css={{ color: headerTextColor }}>
            {header}
          </Text>
          <DividerContainer>
            <Divider size={'2px'} css={{ borderColor: headerTextColor }} />
          </DividerContainer>
          <Spacer size='xl' />
        </>
      )}
      <>
        <Header css={{ color: titleSubtitleTextColor || theme.colors.black || defaultTheme.colors.black, marginBottom: '-30px' }} variant='5xl'>
          {title}
        </Header>
        <Spacer size='2xl' />
      </>
      <RichTextRenderer document={subtitle} />
    </TextWithoutImageContainer>
  )
}

const InfoTextWithImage: React.FC<ITextWithHeaderFields> = ({
  name,
  header,
  subtitle,
  title,
  image,
  imagePositionLeft,
  headerTextColor,
  titleSubtitleTextColor,
}) => {
  return imagePositionLeft ? (
    <>
      <InfoTextImageContainer imageUrl={`https:${image?.fields.file.url}`} />
      <Spacer size={mediaLargerThan.mobile ? 'xl' : '2xl'} />
      <InfoTextWithoutImage
        name={name}
        header={header}
        title={title}
        subtitle={subtitle}
        headerTextColor={headerTextColor}
        titleSubtitleTextColor={titleSubtitleTextColor}
      />
    </>
  ) : (
    <>
      <InfoTextWithoutImage
        name={name}
        header={header}
        title={title}
        subtitle={subtitle}
        headerTextColor={headerTextColor}
        titleSubtitleTextColor={titleSubtitleTextColor}
      />
      <Spacer size={mediaLargerThan.mobile ? 'xl' : '2xl'} />
      <InfoTextImageContainer imageUrl={`https:${image?.fields.file.url}`} />
    </>
  )
}

export default TextWithHeader
