import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { MARKETING_PAGES, PRODUCT_FLOW_PAGES } from '../constants'

interface ReturnValues {
  isMarketingPage: boolean
  isProductFlowPage: boolean
}

export const usePageType = (): ReturnValues => {
  const { pathname, key: locationKey } = useLocation()
  const isPageNotFound = !locationKey

  const [isMarketingPage, setIsMarketingPage] = useState(false)
  const [isProductFlowPage, setIsProductFlowPage] = useState(false)

  useEffect(() => {
    setIsMarketingPage(MARKETING_PAGES.includes(pathname) || isPageNotFound)
    setIsProductFlowPage(PRODUCT_FLOW_PAGES.includes(pathname))
  }, [pathname, isPageNotFound])

  return {
    isMarketingPage,
    isProductFlowPage,
  }
}
