import { Spacer } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import React, { Fragment } from 'react'

import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { ITruepillAccreditationsFields } from '../../types/generated/contentful'
import { AccreditationsContainer, AccreditationsImageContainer, AccreditationsLogosContainer } from './styledComponents'

const TruepillAccreditations: React.FC<ITruepillAccreditationsFields> = ({ logos, links, backgroundColor }) => {
  const { theme } = useContentfulTheme()
  const { trackLinkClickEvent } = useAnalytics()

  return (
    <AccreditationsContainer backgroundColor={backgroundColor ?? theme.colors['gray-100'] ?? defaultTheme.colors['gray-100']}>
      <AccreditationsLogosContainer>
        {logos.map((logo, index) => (
          <Fragment key={index}>
            <AccreditationsImageContainer>
              <a
                href={links[index]}
                target='_blank'
                rel='noreferrer'
                onClick={() => trackLinkClickEvent('truepill_accreditations_logo_click', links[index])}
              >
                <img src={logo.fields.file.url} alt={logo.fields.title} />
              </a>
            </AccreditationsImageContainer>
            {index < logos.length - 1 && <Spacer />}
          </Fragment>
        ))}
      </AccreditationsLogosContainer>
    </AccreditationsContainer>
  )
}

export default TruepillAccreditations
