import { Header } from '@truepill/react-capsule'
import styled from 'styled-components'

import { ThemedComponent } from './types'

/**
 * Header that uses the custom font defined in the theme, use as top-level header
 */
export const ThemedHeader = styled(Header)<ThemedComponent>`
  @font-face {
    font-family: 'header font';
    src: url(${({ vpTheme }) => vpTheme.customFonts?.header_font}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  font-family: ${({ vpTheme }) => (vpTheme.customFonts?.header_font ? 'header font' : vpTheme.fonts?.header)};
`
