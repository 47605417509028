import { VpTheme } from '@vpharm-platform/shared'
import { useEffect, useState } from 'react'

import { IPageConfigurationFields, ISeo } from '../../types/generated/contentful'
import { getPageConfiguration } from './contentfulService'
import { mapReferenceValuesToFields } from './useContentfulMicrocopy'
import { useContentfulTheme } from './useContentfulTheme'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

export type ContentfulPageReturnValues<T> = {
  loading: boolean
  error: boolean
  theme: VpTheme
  content?: T | undefined
  seo?: ISeo
}

export const useGetPageContent = <T>(
  pageName: keyof Omit<
    IPageConfigurationFields,
    'name' | 'slug' | 'accountManagementInsurance' | 'accountManagementPayments' | 'accountManagementSettings' | 'transferHistory'
  >,
): ContentfulPageReturnValues<T> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [content, setContent] = useState<T | undefined>()
  const [seo, setSeo] = useState<ISeo | undefined>()
  const customerName = useGetCustomerNameFromSubdomain()
  const { theme } = useContentfulTheme()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const page = await getPageConfiguration(customerName, pageName)
        const microcopy = page?.fields.microcopy && mapReferenceValuesToFields(page?.fields.microcopy)
        setContent(microcopy as T)
        setSeo(page?.fields.seo as ISeo)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [customerName, pageName])

  return {
    loading,
    error,
    content,
    seo,
    theme,
  }
}
