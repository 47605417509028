const resizeImage = (image: HTMLImageElement): string => {
  const canvas = document.createElement('canvas')
  const MAX_IMAGE_HEIGHT = 500
  const MAX_IMAGE_WIDTH = 500

  const imageRatio = image.width / image.height
  let imageWidth = image.width
  let imageHeight = image.height

  if (imageWidth > imageHeight) {
    if (imageWidth > MAX_IMAGE_WIDTH) {
      imageWidth = MAX_IMAGE_WIDTH
      imageHeight = imageWidth / imageRatio
    }
  } else {
    if (imageHeight > MAX_IMAGE_HEIGHT) {
      imageHeight = MAX_IMAGE_HEIGHT
      imageWidth = imageHeight * imageRatio
    }
  }

  canvas.width = imageWidth
  canvas.height = imageHeight
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.drawImage(image, 0, 0, imageWidth, imageHeight)
  }
  return canvas.toDataURL('image/jpeg', 0.7)
}

export const processImageCompression = (imageFile: File): Promise<string> => {
  const processFilePromise = new Promise<string>((resolve, reject) => {
    if (!/image/i.test(imageFile.type)) {
      reject(new Error('File is not an image'))
    }

    // Read the file
    const reader = new FileReader()
    reader.readAsArrayBuffer(imageFile)
    reader.onload = function (event: ProgressEvent<FileReader>) {
      if (event.target?.result) {
        const blob = new Blob([event.target.result])
        window.URL = window.URL || window.webkitURL
        const blobUrl = window.URL.createObjectURL(blob)

        const image = new Image()
        image.src = blobUrl
        image.onload = function () {
          const resized = resizeImage(image)
          resolve(resized)
        }
      }
    }
  })

  return processFilePromise
}

export const imageFileToBase64 = (imageFile: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise<string>((resolve, reject) => {
    if (!/image/i.test(imageFile.type)) {
      reject(new Error('File is not an image'))
    }
    reader.readAsDataURL(imageFile)
    reader.onloadend = function () {
      resolve(reader.result as string)
    }
  })
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  return new File([blob], fileName, { type: 'image/jpeg' })
}
