import { AuthService } from './authService'
import { PharmacyService } from './pharmacyService'
import { ProfileService } from './profileService'

interface UpdateAccountSettingParams {
  communicationOptIn?: boolean
  email?: string
  phone?: string
}

export interface UserSetting {
  communicationOptIn: boolean
  phoneNumber: string
}

interface Result {
  success: boolean
  message?: string
}

class AccountService {
  constructor(private pharmacyService: PharmacyService, private profileService: ProfileService, private authService: AuthService) {}

  async getAccountSettings(vpharmCustomerToken: string, patientToken: string): Promise<UserSetting> {
    const patientProfile = await this.profileService.fetchPatientProfile(vpharmCustomerToken, patientToken)

    return {
      communicationOptIn: patientProfile.communicationsOptIn,
      phoneNumber: patientProfile.phone,
    }
  }

  async updateAccountSettings(accountUpdates: UpdateAccountSettingParams, vpharmCustomerToken: string, patientToken: string): Promise<void> {
    await this.pharmacyService.updatePatient(
      {
        communications_opt_in: accountUpdates.communicationOptIn,
        email: accountUpdates.email,
        phone: accountUpdates.phone,
      },
      vpharmCustomerToken,
      patientToken,
    )
  }

  async sendResetPasswordEmail(tenantUrl: string, clientId: string, email: string): Promise<void> {
    await this.authService.sendPasswordResetEmail(tenantUrl, clientId, email)
  }

  async isEmailAddressAvailable(newEmailAddress: string, vpharmCustomerToken: string, patientToken: string): Promise<boolean> {
    return this.pharmacyService.isEmailAddressAvailable(newEmailAddress, vpharmCustomerToken, patientToken)
  }

  async emailUpdatingRequest(newEmailAddress: string, vpharmCustomerToken: string, patientToken: string): Promise<Result> {
    try {
      await this.pharmacyService.emailUpdatingRequest(newEmailAddress, vpharmCustomerToken, patientToken)
    } catch (error) {
      let message = ''
      const response = (error as any).response
      if (response && response.data && response.data.message) {
        message = response.data.message as string
      }
      return { success: false, message }
    }

    return { success: true }
  }

  async emailUpdatingConfirmation(token: string, dob: string, vpharmCustomerToken: string): Promise<Result> {
    try {
      await this.pharmacyService.emailUpdatingConfirmation(token, dob, vpharmCustomerToken)
    } catch (error) {
      let message = ''
      const response = (error as any).response
      if (response && response.data && response.data.message) {
        message = response.data.message as string
      }
      return { success: false, message }
    }

    return { success: true }
  }
}

export default AccountService
