import { InsuranceUploadParams, InsuranceUploadResponse, sharedConstants } from '@vpharm-platform/shared'
import { axiosAuth } from 'httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

class StorageApiService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/storage`

  async requestInsuranceCardUpload(
    fileExtension: 'jpeg' | 'jpg' | 'png',
    patientToken: string,
    customerToken: string,
  ): Promise<InsuranceUploadResponse> {
    const url = `${this.baseUrl}/insurance`
    const insuranceUploadParams: InsuranceUploadParams = {
      fileExtension,
    }

    try {
      const response = await axiosAuth.post<InsuranceUploadResponse>(url, insuranceUploadParams, {
        headers: {
          [VPHARM_CUSTOMER_HEADER]: customerToken,
          [VPHARM_PATIENT_HEADER]: patientToken,
        },
      })

      return response.data
    } catch (e) {
      throw new Error('Unable to request insurance card upload information')
    }
  }

  async requestSavingCardUpload(
    fileExtension: 'jpeg' | 'jpg' | 'png',
    patientToken: string,
    customerToken: string,
  ): Promise<InsuranceUploadResponse> {
    const url = `${this.baseUrl}/savings_card`
    const insuranceUploadParams: InsuranceUploadParams = {
      fileExtension,
    }

    try {
      const response = await axiosAuth.post<InsuranceUploadResponse>(url, insuranceUploadParams, {
        headers: {
          [VPHARM_CUSTOMER_HEADER]: customerToken,
          [VPHARM_PATIENT_HEADER]: patientToken,
        },
      })

      return response.data
    } catch (e) {
      throw new Error('Unable to request saving card upload information')
    }
  }
}

export default StorageApiService
