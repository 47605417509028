import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const CartIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1668:52975)'>
        <path
          d='M0.666626 0.666656H3.33329L5.11996 9.59332C5.18092 9.90025 5.3479 10.176 5.59166 10.3722C5.83541 10.5684 6.14042 10.6727 6.45329 10.6667H12.9333C13.2462 10.6727 13.5512 10.5684 13.7949 10.3722C14.0387 10.176 14.2057 9.90025 14.2666 9.59332L15.3333 3.99999H3.99996M14 13.9998C14 14.368 13.7015 14.6665 13.3333 14.6665C12.9651 14.6665 12.6666 14.368 12.6666 13.9998C12.6666 13.6316 12.9651 13.3332 13.3333 13.3332C13.7015 13.3332 14 13.6316 14 13.9998ZM6.66646 13.9998C6.66646 14.368 6.36799 14.6665 5.9998 14.6665C5.63161 14.6665 5.33313 14.368 5.33313 13.9998C5.33313 13.6316 5.63161 13.3332 5.9998 13.3332C6.36799 13.3332 6.66646 13.6316 6.66646 13.9998Z'
          stroke={vpTheme.colors['primary-700']}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1668:52975'>
          <rect width='16' height='16' fill={vpTheme.colors.grayWhite} />
        </clipPath>
      </defs>
    </svg>
  )
}
