import { BannerAlert, BannerStateNoError, Text } from '@truepill/react-capsule'
import { media } from 'common/styles/variables'
import React from 'react'
import styled from 'styled-components/macro'

export interface BannerUploadIdProps {
  isBannerVisible: boolean
  setIsBannerVisible: React.Dispatch<React.SetStateAction<boolean>>
  bannerText: string
  setBannerText: React.Dispatch<React.SetStateAction<string>>
  bannerState: BannerStateNoError
  setBannerState: React.Dispatch<React.SetStateAction<BannerStateNoError>>
}

export const BannerUploadId = ({ isBannerVisible, setIsBannerVisible, bannerText, bannerState }: BannerUploadIdProps): JSX.Element => {
  return (
    <BannerContainer>
      <BannerInnerContainer>
        <BannerAlert
          state={bannerState}
          visible={isBannerVisible}
          onTimeout={() => setIsBannerVisible(false)}
          onDismiss={() => setIsBannerVisible(false)}
        >
          {' '}
          <Text>{bannerText}</Text>
        </BannerAlert>
      </BannerInnerContainer>
    </BannerContainer>
  )
}

const BannerContainer = styled.div`
  width: 46.5rem;
  position: absolute;
  @media (max-width: ${media.tablet}) {
    width: 90vw;
  }
`
const BannerInnerContainer = styled.div`
  width: 100%;
  position: relative;
  top: 10px;
`
