import styled from 'styled-components/macro'

// Top-level cards used on Prescription Management (RxM) page
export const RxmCardContainer = styled.div`
  > div {
    padding: 1.5rem;
  }
  box-shadow: 0 2px 4px 1px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  margin: 1rem 0 1.5rem 0;
`
