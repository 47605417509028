import { useRecoilValue } from 'recoil'

import { CustomerProfile } from '../interfaces'
import { customerProfileAtom } from '../persistRecoil/customerProfile'

interface ReturnValues {
  customerProfile: CustomerProfile
}

export const useCustomerProfile = (): ReturnValues => {
  const customerProfile = useRecoilValue(customerProfileAtom)

  return { customerProfile }
}
