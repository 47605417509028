import { Header, Spacer, Text, Toggle } from '@truepill/react-capsule'
import { ReactElement } from 'react'

import { useContentfulTheme } from '../../../hooks'
import StyledHeader from '../StyledHeader'
import { AccountSmsContainer } from './styledComponents'

interface AccountTextSmsContentProps {
  isOptIn: boolean
  handleCommunicationOptIn: (isOptIn: boolean) => void
  contentfulSmsToggleInfo: string | undefined
}

const AccountTextSmsContent = ({ isOptIn, handleCommunicationOptIn, contentfulSmsToggleInfo }: AccountTextSmsContentProps): ReactElement => {
  const smsToggleInfo = contentfulSmsToggleInfo || 'Receive account, prescription, and order updates by text message'
  const { theme } = useContentfulTheme()
  return (
    <>
      <StyledHeader>
        <Header bold variant='xl' data-testid='address-title'>
          Text / SMS
        </Header>
      </StyledHeader>
      <Spacer size='md' />
      <AccountSmsContainer vpTheme={theme}>
        <Text>{smsToggleInfo}</Text>
        <Toggle checked={isOptIn} onChange={() => handleCommunicationOptIn(!isOptIn)} withText />
      </AccountSmsContainer>
    </>
  )
}

export default AccountTextSmsContent
