import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'
import { breakpoint, media } from '../../common/styles/variables'

const SideBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 230px;
  width: 100%;
  margin-left: 1.25rem;

  ${breakpoint.laptop} {
    display: none;
  }
`

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 744px;
  width: 100%;
  padding-top: 5px;

  @media (max-width: ${media.tablet}) {
    margin: 1rem 0 1rem 0;
    padding: 0 1.5rem;
  }

  @media (min-width: ${media.laptop}) {
    margin: 0 0 0 1.5rem;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  min-height: calc(100vh - 8rem - 9.5rem);

  @media (min-width: ${media.tablet}) {
    margin: 4rem auto;
  }

  @media (max-width: ${media.tablet}) {
    max-width: none;
  }
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  max-width: 360px;
  width: 100%;
`

const selectedStyle = (vpTheme: VpTheme): string => `
  background-color: ${vpTheme.colors['primary-100'] ?? defaultTheme.colors['primary-100']};
  border-left-color: ${vpTheme.colors.brandedTab ?? defaultTheme.colors.brandedTab};
  border-left-width: 0.5rem;
  border-left-style: solid;
  padding: 0.5rem 1.25rem;
  white-space: nowrap;
`

const StyledLinkWrapper = styled.li<ThemedComponent>`
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  ${(props) => (props['aria-selected'] === true ? selectedStyle(props.vpTheme) : 'padding: 0.5rem 1.75rem;')}

  &:hover {
    background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  svg {
    flex-shrink: 0;
  }
`

const StyledSpan = styled.span<ThemedComponent>`
  margin-left: 1.25rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  font-family: Lato, serif;
  font-size: 1rem;
  line-height: 1.5rem;
`

export { selectedStyle, SideBarDiv, StyledLink, StyledLinkWrapper, StyledList, StyledMain, StyledRow, StyledSpan }
