import { ComponentType } from 'react'

export const withEmailVerified =
  <P extends Record<string, unknown>>(OriginalComponent: ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (originalProps: P): React.ReactElement => {
    return (
      <>
        <OriginalComponent {...originalProps} />
      </>
    )
  }
