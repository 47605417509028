import { OrderDetails } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../../common/styledComponents/types'
import { breakpoint, media } from '../../../common/styles/variables'
import { parseColorFromStatus } from '../../../constants/orders'

const OrderCardContainer = styled.div<ThemedComponent>`
  border-radius: 8px;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  margin-bottom: 1em;
`
const OrderCardHeaderContainer = styled.div<ThemedComponent>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
  box-sizing: border-box;
  border-bottom: 1px solid #e9ecf0;
  border-radius: 8px 8px 0 0;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 16px 0;

  @media (max-width: ${media.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
`

type OrderHeaderProps = {
  status: OrderDetails['status']
}

const OrderCardHeader = styled.div<OrderHeaderProps>`
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  color: ${({ status }) => parseColorFromStatus(status)};
`

const OrderCardMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const OrderMessageContainer = styled.div`
  margin-left: 16px;
`

const OrderCardShippingContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  @media (max-width: ${media.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`

const CancelOrderContainer = styled.div``

const OrderCardShippingETA = styled.div`
  width: 100%;
  justify-content: space-between;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin: 0 0 0 16px;
  @media (max-width: ${media.tablet}) {
    margin: 0 16px;
    gap: 5rem;
  }
  h5 {
    line-height: 1.5rem;
  }

  ${breakpoint.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 0;
  }
`
const OrderCardShippingTracking = styled.div`
  justify-content: flex-end;
  @media (max-width: ${media.tablet}) {
    margin: 0 16px 0 16px;
  }
`

const TrackingLink = styled.a<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
  line-height: 24px;
  font-weight: 700;
  text-underline-offset: 1px;
  margin: 0 16px 0 0;
`

const OrderDetailError = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 0 16px 16px 16px;
`

const EtaHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const IconContainer = styled.div`
  margin-left: 6px;
`

export {
  CancelOrderContainer,
  EtaHeader,
  IconContainer,
  OrderCardContainer,
  OrderCardHeader,
  OrderCardHeaderContainer,
  OrderCardMessage,
  OrderCardShippingContainer,
  OrderCardShippingETA,
  OrderCardShippingTracking,
  OrderDetailError,
  OrderMessageContainer,
  TrackingLink,
}
