import { theme } from '@truepill/react-capsule'
import { ReactElement, ReactNode } from 'react'
import { PlusCircle as UnstyledPlusCircle } from 'react-feather'
import styled from 'styled-components/macro'

interface Props {
  text: string
  onClick?: () => void
  icon?: ReactNode
  ariaLabel?: string
  className?: string
}

export const ButtonCard = ({ text, icon, ariaLabel, onClick }: Props): ReactElement => {
  return (
    <StyledButton role='button' data-testid='button-card' aria-label={ariaLabel} onClick={onClick}>
      <StyledSpan className='m-auto font-bold'>
        {icon}&nbsp;{text}
      </StyledSpan>
    </StyledButton>
  )
}

// Icon for adding new element
export const PlusCircle = styled(UnstyledPlusCircle)`
  width: 22.5px;
  margin-right: 10px;
  top: 6px;
  position: relative;
  color: ${theme.colors['typography-medium'].computedValue};
  display: inline-block;
`

const StyledButton = styled.button`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 1px dashed ${theme.colors['gray-500'].computedValue};
  border-radius: 0.5rem;
  background-color: ${theme.colors['gray-100'].computedValue};
  min-height: 245.5px;
  margin-bottom: 1.5rem;
  @media (min-width: 720px) {
    margin-right: 1.5rem;
  }
`

const StyledSpan = styled.span`
  margin: auto;
  font-weight: 700;
  color: ${theme.colors['typography-medium'].computedValue};

  font-size: 1rem;
`
