import { Spacer } from '@truepill/react-capsule'
import { SignUpIcon } from 'assets/Icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { mediaLargerThan } from '../../common/styles/variables'
import { SELF_ENROLLMENT_PATH } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { SelfEnrollmentSections } from '../../pages/SelfEnrollment/sections/types'

const SignupButton: React.FC = (): JSX.Element => {
  const history = useHistory()
  const { theme } = useContentfulTheme()

  const handleClick = () => {
    history.push(SELF_ENROLLMENT_PATH.replace(':sectionId', SelfEnrollmentSections.PATIENT_INFO))
  }

  return (
    <StyledButton onClick={handleClick}>
      Sign up
      <Spacer size='xs' />
      <SignUpIcon vpTheme={theme} />
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: none;
  ${mediaLargerThan.laptop} {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
  }
`

export default SignupButton
