import { useState } from 'react'

import { LocationService } from '../../../services/LocationService'
import { SelectedPharmacy } from '../useTransferOut'

export interface PharmacyLocation {
  value: string
  name: string
  address: string
  phoneNumber?: string
  isDisabled: boolean
}

interface UsePharmacyFinderReturnValues {
  fetchPharmacyLocations: (location: string) => Promise<PharmacyLocation[]>
  pharmacyLocation: PharmacyLocation | null
  handlePharmacySelect: (location: PharmacyLocation | null) => Promise<void>
}

interface UseFinderProps {
  onPharmacySelect: (pharmacy: SelectedPharmacy | null) => void
}

export const usePharmacyFinder = ({ onPharmacySelect }: UseFinderProps): UsePharmacyFinderReturnValues => {
  const [pharmacyLocation, setPharmacyLocation] = useState<PharmacyLocation | null>(null)

  const fetchPharmacyLocations = async (location: string): Promise<PharmacyLocation[]> => {
    const locationService = await LocationService.getInstance()
    const pharmacyLocations = await locationService.getPharmacySuggestions(location)
    return pharmacyLocations.map((location) => ({
      value: location.id,
      name: location.name,
      address: location.address,
      isDisabled: false,
    }))
  }

  const handlePharmacySelect = async (pharmacy: PharmacyLocation | null) => {
    const locationService = await LocationService.getInstance()

    if (!pharmacy) {
      onPharmacySelect(null)
      setPharmacyLocation(null)
      return
    }

    locationService.getPharmacyDetails(pharmacy.value, (err, place) => {
      if (place) {
        setPharmacyLocation({
          isDisabled: false,
          address: place.address,
          name: pharmacy.name,
          value: place.id,
          phoneNumber: place.phoneNumber,
        })
        // Set parent component state
        onPharmacySelect({
          street1: place.pharmacyAddress.street1,
          street2: place.pharmacyAddress.street2 ?? undefined,
          city: place.pharmacyAddress.city,
          name: pharmacy.name,
          state: place.pharmacyAddress.state,
          zip: place.pharmacyAddress.zip,
          phone: place.phoneNumber,
        })
      }

      if (err) {
        console.error('Something went wrong fetching pharmacy details')
      }
    })
  }

  return {
    fetchPharmacyLocations,
    pharmacyLocation,
    handlePharmacySelect,
  }
}
