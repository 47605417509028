export enum GenderResponses {
  MALE = 'male',
  FEMALE = 'female',
}

export enum SelfEnrollmentSections {
  PATIENT_INFO = 'patient-info',
  CONTACT_INFO = 'contact-info',
}

export interface SelfEnrollmentSectionProps {
  targetSlug: SelfEnrollmentSections
  sectionHeader: string
  sectionSubHeader: string
}

export interface SectionProps {
  isLastSection: boolean
  handleNextStep: () => void
}
