import { atom } from 'recoil'

export const employerDetails = atom({
  key: 'employerDetails',
  default: {
    employerName: 'Truepill',
    employerLogo: 'https://s3.amazonaws.com/onboarding-assets.truepill.com/truepill-blue.png',
    poweredByLogo: {
      default: 'https://images.ctfassets.net/rggy7jnq8h4z/36TeUAhR0vh5LvHvoZLkY0/a82e520d0669b6312a48e08da154eae8/powered_by_TP.png',
      white: 'https://images.ctfassets.net/rggy7jnq8h4z/47CICZ07wky2k6t8zmYqjY/46b8feddf8752a0037d8aaf3724eab19/powered_by_TP_white.png',
    },
  },
})
