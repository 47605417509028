import {
  CreateTransferOutParams,
  GetEligibleTransferOutPrescriptionsResponse,
  GetTransferPharmaciesResponse,
  sharedConstants,
  TransferOutPrescription,
  VerifyPharmacyParams,
  VerifyPharmacyResponse,
} from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export interface TransferOutService {
  verifyTransferOutPharmacy(verifyPhamacyParams: VerifyPharmacyParams, customerToken: string): Promise<string>
  getEligibleTransferOutPrescriptions(patientToken: string, customerToken: string): Promise<TransferOutPrescription[]>
  createTransferOut(transferOutParams: CreateTransferOutParams, customerToken: string, patientToken: string): Promise<void>
  getTransferInPharmacies(vpharmCustomerToken: string): Promise<GetTransferPharmaciesResponse[]>
}

class TransferOutApiServer implements TransferOutService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/transfer_out`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async verifyTransferOutPharmacy(verifyPharmacyParams: VerifyPharmacyParams, customerToken: string): Promise<string> {
    const url = new URL(`${this.baseUrl}/verify_pharmacy`)
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
      },
    }

    url.searchParams.append('street1', verifyPharmacyParams.street1)
    url.searchParams.append('city', verifyPharmacyParams.city)
    url.searchParams.append('state', verifyPharmacyParams.state)
    url.searchParams.append('zip', verifyPharmacyParams.zip)

    if (verifyPharmacyParams.street2) {
      url.searchParams.append('street2', verifyPharmacyParams.street2)
    }

    const response = await axiosAuth.get<VerifyPharmacyResponse>(url.toString(), config)

    return response.data.externalLocationId
  }

  async getEligibleTransferOutPrescriptions(patientToken: string, customerToken: string): Promise<TransferOutPrescription[]> {
    const url = new URL(`${this.baseUrl}/prescriptions`)
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    const response = await axiosAuth.get<GetEligibleTransferOutPrescriptionsResponse>(url.toString(), config)
    return response.data.prescriptions
  }

  async getTransferInPharmacies(customerToken: string): Promise<GetTransferPharmaciesResponse[]> {
    const url = new URL(`${this.baseUrl}/pharmacies`)
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
      },
    }

    const response = await axiosAuth.get<GetTransferPharmaciesResponse[]>(url.toString(), config)
    return response.data
  }

  async createTransferOut(transferOutParams: CreateTransferOutParams, customerToken: string, patientToken: string): Promise<void> {
    const url = new URL(`${this.baseUrl}`)

    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    await axiosAuth.post(url.toString(), transferOutParams, config)
  }
}

export const transferOutService: TransferOutService = new TransferOutApiServer()
