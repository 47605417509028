import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { PatientProfile } from '../interfaces'
import { profileService } from '../services/profileService'
import { customerProfileAtom } from './customerProfile'
import { selectedPatientTokenAtom } from './selectedPatientToken'

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
})

// Do not set directly; updating selected patient with selectedPatientTokenAtom
// will auto-refresh this profile
const patientProfileQuery = selector({
  key: 'patientProfileQuery',
  get: async ({ get }): Promise<PatientProfile | undefined> => {
    const { vpharmCustomerToken } = get(customerProfileAtom)
    const selectedPatientToken = get(selectedPatientTokenAtom)
    if (vpharmCustomerToken && selectedPatientToken) {
      try {
        const patientProfile = await profileService.fetchPatientProfile(vpharmCustomerToken, selectedPatientToken)
        return patientProfile
      } catch (err) {
        return undefined
      }
    }
    return undefined
  },
})

export const patientProfileAtom = atom<PatientProfile | undefined>({
  key: 'patientProfileAtom',
  default: patientProfileQuery,
  effects_UNSTABLE: [persistAtom],
})
