import { Text, Toast } from '@truepill/react-capsule'
import { breakpoint } from 'common/styles/variables'
import { useShowError } from 'hooks'
import styled from 'styled-components/macro'

import SupportLink from '../SupportLink'

interface Props {
  className?: string
}

const GenericError = ({ className }: Props): React.ReactElement => {
  const { show, hideError } = useShowError()
  const handleDismiss = () => {
    hideError()
  }

  return (
    <StyledToast
      onDismiss={handleDismiss}
      position={{ vertical: 'top', horizontal: 'center' }}
      state='error'
      color='pastel'
      icon
      visible={show}
      rootClassName={className}
      style={{ marginTop: 60 }}
    >
      <Text>
        {'Oops! An error occurred. Please try again or'}&nbsp;
        <SupportLink>contact Patient Support</SupportLink>.
      </Text>
    </StyledToast>
  )
}

const StyledToast = styled(Toast)`
  border-top: 1px solid var(--toast-border);
  border-bottom: 1px solid var(--toast-border);
  border-right: 1px solid var(--toast-border);
`

const CustomWidthToast = styled(GenericError)`
  width: 744px;
  ${breakpoint.tablet} {
    width: 100%;
    padding: 0 24px;
  }
`

export default CustomWidthToast
