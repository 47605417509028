import React from 'react'
import { Link } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'
import { LinkOrigin, useAnalytics } from '../../hooks/analytics-context'
import { ButtonContainer } from './styledComponents'

interface Props {
  caption: string
  path: string
  variant?: 'primary' | 'primary-outline' | 'primary-text' | 'dark-text' | 'secondary' | 'secondary-outline' | 'secondary-text'
  fullWidth?: boolean
  usePadding?: boolean
}

const FAQActionButton: React.FC<Props> = (props) => {
  const { caption, path, variant, fullWidth = true, usePadding = true } = props
  const { trackLinkClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  return (
    <ButtonContainer fullWidth={fullWidth} usePadding={usePadding} variant={variant}>
      <Link to={path}>
        <ThemedButton variant={variant} onClick={() => trackLinkClickEvent(caption, path, LinkOrigin.Body)} vpTheme={theme}>
          {caption}
        </ThemedButton>
      </Link>
    </ButtonContainer>
  )
}

export default FAQActionButton
