import { theme } from '@truepill/react-capsule'
import styled from 'styled-components'

const MainContainer = styled.div``

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: ${theme.colors['gray-300'].computedValue};
  padding: 1rem 1rem;
  border-radius: 0.25rem;
`

export const StyledSelfEnrollmentLink = {
  MainContainer,
  LinkContainer,
}
