import { Header, Spacer, Text } from '@truepill/react-capsule'
import ToastMessage from 'Components/ToastMessage'
import React from 'react'
import styled from 'styled-components/macro'

import { Modal } from '../../common/styledComponents/StyledModal'
import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../hooks'
import { useSessionTimeout } from './useSessionTimeout'

const SessionTimeout: React.FC = () => {
  const { showModal, showToastMessage, closeModalHandler, closeToastMessage } = useSessionTimeout()
  const { theme } = useContentfulTheme()

  return (
    <>
      <TimeoutModal isOpen={showModal} onDismiss={closeModalHandler}>
        <>
          <ModalTitle>Your session is about to expire</ModalTitle>
          <Spacer />
          <ModalText className='cap-typography-medium'>
            Looks like you’ve been inactive on this page for a while, so we’ll automatically end this session to protect your privacy.
          </ModalText>
          <Spacer size='lg' />
          <ModalButton>
            <ThemedButton onClick={closeModalHandler} vpTheme={theme}>
              Stay signed in
            </ThemedButton>
          </ModalButton>
        </>
      </TimeoutModal>
      {showToastMessage && (
        <ToastMessage
          borderLeft={true}
          color='pastel'
          className='toast-message'
          icon={true}
          onTimeout={closeToastMessage}
          onDismiss={closeToastMessage}
          position={{ vertical: 'top', horizontal: 'center' }}
          state={'info'}
          visible={showToastMessage}
          timeout={5000}
        >
          <Text>You have been logged out due to inactivity.</Text>
        </ToastMessage>
      )}
    </>
  )
}

const TimeoutModal = styled(Modal)`
  div button svg {
    margin-top: 100% !important;
  }
`

const ModalTitle = styled(Header)`
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
`

const ModalText = styled(Text)`
  line-height: 24px;
`

const ModalButton = styled.div`
  display: flex;
  justify-content: end;
`

export default SessionTimeout
