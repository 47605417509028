import styled from 'styled-components'

import { mediaLargerThan } from '../../../common/styles/variables'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
`

const PhoneNumberActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaLargerThan.mobile} {
    justify-content: flex-end;
    button {
      width: 50%;
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: flex-end;
`

export { ButtonsContainer, PhoneNumberActionContainer, Wrapper }
