import { GetSavingCardResponse, PutSavingCardParams, PutSavingCardResponse, sharedConstants } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'
import { PatientInsuranceSubmitPayload } from '../interfaces'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

export class SavingsCardService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async getSavingCard(prescriptionNdc: string, patientToken: string, customerToken: string): Promise<GetSavingCardResponse> {
    const url = `${this.baseUrl}/saving_card/${prescriptionNdc}`
    const config: AxiosRequestConfig = {
      headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
    }

    const response = await axiosAuth.get<GetSavingCardResponse>(url, config)
    return response.data
  }

  async getAllSavingsCards(patientToken: string, customerToken: string): Promise<GetSavingCardResponse[]> {
    const url = `${this.baseUrl}/saving_card`
    const config: AxiosRequestConfig = {
      headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
    }

    const response = await axiosAuth.get<GetSavingCardResponse[]>(url, config)
    return response.data
  }

  async addSavingCard(customerToken: string, patientToken: string, savingCardDetails: PutSavingCardParams): Promise<void> {
    try {
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      const url = `${this.baseUrl}/saving_card`
      await axiosAuth.put<PutSavingCardResponse>(url, savingCardDetails, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async deleteSavingCard(customerToken: string, patientToken: string, ndc: string | null): Promise<void> {
    try {
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      const url = `${this.baseUrl}/saving_card/${ndc}`
      await axiosAuth.delete<void>(url, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async savingCardSignUp(customerToken: string, patientToken: string, ndc: string): Promise<void> {
    try {
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      const url = `${this.baseUrl}/saving_card/sign_up/${ndc}`
      await axiosAuth.post(url, {}, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async savingCardSignUpWithNewInsurance(
    customerToken: string,
    patientToken: string,
    ndc: string,
    newPatientInsurance: PatientInsuranceSubmitPayload,
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
    }

    const url = `${this.baseUrl}/saving_card/sign_up_with_new_insurance/${ndc}`
    await axiosAuth.post(url, { patient_insurance: newPatientInsurance }, config)
  }
}

export const savingsCardService: SavingsCardService = new SavingsCardService()
