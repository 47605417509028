import { useState } from 'react'

import { useAnalytics } from '../../../hooks/analytics-context'

export interface UseAutoRefillCardReturnValues {
  isTermsConditionModalOpen: boolean
  isDisenrollModalOpen: boolean
  handleTermsConditionModalState: (isOpen: boolean) => void
  handleDisenrollModalState: (isOpen: boolean) => void
  handleToggleAutoRefill: () => void
  isSubmitting: boolean
  enrollAutoRefill: () => Promise<void>
  disenrollAutoRefill: () => Promise<void>
}

interface UseAutoRefillCardProps {
  isAutoRefillEnabled: boolean
  prescriptionToken: string
  ndc: string
  submitEnrollAutoRefill: (prescriptionToken: string) => Promise<void>
  submitDisenrollAutoRefill: (prescriptionToken: string) => Promise<void>
}

export const useAutoRefillCard = ({
  isAutoRefillEnabled,
  prescriptionToken,
  submitEnrollAutoRefill,
  submitDisenrollAutoRefill,
  ndc,
}: UseAutoRefillCardProps): UseAutoRefillCardReturnValues => {
  const [isTermsConditionModalOpen, setIsTermsConditionModalOpen] = useState(false)
  const [isDisenrollModalOpen, setIsDisenrollModalOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { trackAutoRefillStatusUpdateEvent, trackToggleEvent } = useAnalytics()

  const handleTermsConditionModalState = (isOpen: boolean) => {
    setIsTermsConditionModalOpen(isOpen)
  }

  const handleDisenrollModalState = (isOpen: boolean) => {
    setIsDisenrollModalOpen(isOpen)
  }

  const handleToggleAutoRefill = () => {
    if (isAutoRefillEnabled) {
      trackToggleEvent('auto-refill_enrollment', false, ndc)
      setIsDisenrollModalOpen(true)
    } else {
      trackToggleEvent('auto-refill_enrollment', true, ndc)
      setIsTermsConditionModalOpen(true)
    }
  }

  const disenrollAutoRefill = async () => {
    setIsSubmitting(true)

    try {
      trackAutoRefillStatusUpdateEvent('auto_refill_disable', ndc)
      await submitDisenrollAutoRefill(prescriptionToken)
    } finally {
      setIsSubmitting(false)
      setIsDisenrollModalOpen(false)
    }
  }

  const enrollAutoRefill = async () => {
    setIsSubmitting(true)
    trackAutoRefillStatusUpdateEvent('auto_refill_enable', ndc)

    try {
      await submitEnrollAutoRefill(prescriptionToken)
    } finally {
      setIsSubmitting(false)
      setIsTermsConditionModalOpen(false)
    }
  }

  return {
    isTermsConditionModalOpen,
    handleTermsConditionModalState,
    handleToggleAutoRefill,
    isSubmitting,
    enrollAutoRefill,
    isDisenrollModalOpen,
    handleDisenrollModalState,
    disenrollAutoRefill,
  }
}
