import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Spacer, Text } from '@truepill/react-capsule'
import { PatientIdentityFormValues, sharedConstants } from '@vpharm-platform/shared'
import ToastMessage from 'Components/ToastMessage'
import { PRESCRIPTION_MANAGEMENT_PATH } from 'constants/navigation-links'
import React, { useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { authService } from 'services/authService'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import DOBFormControl from '../../Components/DOBFormControl'
import LoadingAnimation from '../../Components/LoadingAnimation'
import { useAuth } from '../../context/auth-context'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { useAnalytics, VpAnalyticsEventType } from '../../hooks/analytics-context'
import { pharmacyService, profileService } from '../../services'
import AccountService from '../../services/AccountService'
import { PatientIdentityValidationSchema } from '../../utils/validation'
import { ButtonContainer, Container, LoadingSpinnerWrapper } from './styledComponents'

const { RoutePaths } = sharedConstants

const EmailUpdating: React.FC = () => {
  const history = useHistory()
  const { authState } = useAuth()
  const { params } = useRouteMatch<{ token: string }>()
  const { customerProfile } = useCustomerProfile()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const form = useForm<PatientIdentityFormValues>({
    resolver: yupResolver(PatientIdentityValidationSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })
  const { trackButtonClickEvent, trackEvent, trackErrorShownEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  if (!params.token) {
    return <Redirect to={PRESCRIPTION_MANAGEMENT_PATH} />
  }

  const handleSubmit = async (formValues: PatientIdentityFormValues) => {
    const { year, month, day } = formValues
    const dob = `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`
    const emailUpdatingToken = params.token

    if (!emailUpdatingToken) {
      setErrorMessage('Invalid email verification token. Unable to update email.')
      trackErrorShownEvent('edit_email_updating_error_invalid_token', 'user submitted email update')
      return
    }

    setErrorMessage('')
    const accountService = new AccountService(pharmacyService, profileService, authService)
    const result = await accountService.emailUpdatingConfirmation(emailUpdatingToken, dob, customerProfile.vpharmCustomerToken)

    if (result.success) {
      setIsSuccess(true)
      trackEvent(VpAnalyticsEventType.EmailUpdated)
    } else {
      setErrorMessage(`An error occurred updating the email. ${result.message ?? ''}`)
      trackErrorShownEvent('edit_email_updating_error', 'user submitted email update')
    }
  }

  const handleContinue = () => {
    trackButtonClickEvent('edit_email_updating_continue', 'Continue')
    history.replace(RoutePaths.ACCOUNT_SETTINGS_PATH)
    if (authState.isAuthenticated) {
      window.location.reload()
    }
  }

  if (isSuccess) {
    return (
      <Container>
        <Header variant='4xl'>Email updated successfully</Header>
        <Spacer size='lg' />
        <Text>Now you can start using your new email address to log in and receive future communications.</Text>
        <Spacer size='2xl' />
        <ButtonContainer>
          <ThemedButton variant='primary' onClick={handleContinue} vpTheme={theme}>
            Continue
          </ThemedButton>
        </ButtonContainer>
      </Container>
    )
  }

  return (
    <>
      <ToastMessage
        timeout={8000}
        state={'error'}
        visible={!!errorMessage}
        onDismiss={() => setErrorMessage(null)}
        onTimeout={() => setErrorMessage(null)}
      >
        <Text>{errorMessage}</Text>
      </ToastMessage>
      <Container>
        <Header variant='4xl'>Date of birth</Header>
        <Spacer size='lg' />
        <Text>Please confirm your date of birth before continuing with the email update.</Text>
        <Spacer size='2xl' />
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <DOBFormControl form={form as UseFormReturn<PatientIdentityFormValues, Record<string, unknown>>} />
          <Spacer size='2xl' />
          <ButtonContainer>
            <ThemedButton
              role='button'
              type='submit'
              disabled={form.formState.isSubmitting || !form.formState.isValid}
              onClick={() => trackButtonClickEvent('change-email', 'Change Email')}
              variant='primary'
              vpTheme={theme}
            >
              Change email
            </ThemedButton>
          </ButtonContainer>
        </form>
        {form.formState.isSubmitting && (
          <LoadingSpinnerWrapper vpTheme={theme}>
            <div className='backdrop'></div>
            <LoadingAnimation size='sm' />
          </LoadingSpinnerWrapper>
        )}
      </Container>
    </>
  )
}

export default EmailUpdating
