import React from 'react'

import PrescriptionTransferV2 from './PrescriptionTransfer'
import { PrescriptionTransferV2Props } from './types'
import { usePrescriptionTransfer } from './usePrescriptionTransfer'

const PrescriptionTransferManager = (): React.ReactElement<PrescriptionTransferV2Props> => {
  const hookProps = usePrescriptionTransfer()

  return <PrescriptionTransferV2 {...hookProps} />
}

export default PrescriptionTransferManager
