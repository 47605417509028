import { Header, Spacer, Text } from '@truepill/react-capsule'
import { ReactElement } from 'react'

import StyledHeader from '../StyledHeader'
import { UserEmailContainer } from './styledComponents'

interface AccountEmailContentProps {
  email: string | undefined
  contentfulEmailInfo: string | undefined
}

const AccountEmailContent = ({ email, contentfulEmailInfo }: AccountEmailContentProps): ReactElement => {
  const emailInfo =
    contentfulEmailInfo || 'Emails about your account and orders are important. We send those even if you have opted out of marketing emails.'
  return (
    <>
      <StyledHeader>
        <Header bold variant='xl' data-testid='address-title'>
          Emails
        </Header>
      </StyledHeader>
      <Spacer size='md' />
      <Text>{emailInfo}</Text>
      <Spacer size='md' />
      <UserEmailContainer>
        <Text>We send those to</Text>
        &nbsp;
        <Text bold>{email}</Text>
      </UserEmailContainer>
    </>
  )
}

export default AccountEmailContent
