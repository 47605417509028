import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'

const StyledSuggestion = styled.button<ThemedComponent>`
  all: unset;
  &:focus-visible {
    outline: auto 0.5px -webkit-focus-ring-color;
  }
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  line-height: 24px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1px;
  display: inline-block;
  width: 100%;
  &:hover {
    background-color: ${({ vpTheme }) => vpTheme.colors['primary-300']};
  }
  span {
    padding-left: 1rem;
    display: block;
  }
`

const StyledSuggestions = styled.div<ThemedComponent>`
  position: absolute;
  width: 100%;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  border-radius: 0.5rem;
  box-shadow: 0 5px 5px ${({ vpTheme }) => vpTheme.colors['gray-300']}, 0px -1px 3px ${({ vpTheme }) => vpTheme.colors['gray-300']};
  z-index: 100;
  max-height: 15.625rem;
  overflow-y: scroll;
  margin-top: 0.5rem;
`

export { StyledSuggestion, StyledSuggestions }
