export function isNullOrUndefined<T>(objectToCheck: T): boolean {
  return objectToCheck === null || objectToCheck === undefined
}

export function isNotNullOrUndefined<T>(objectToCheck: T): boolean {
  return !isNullOrUndefined(objectToCheck)
}

export function isAPopulatedArray<T>(objectToCheck: Array<T>): boolean {
  return isNotNullOrUndefined(objectToCheck) && objectToCheck.length > 0
}

export function isANonPopulatedArray<T>(objectToCheck: Array<T>): boolean {
  return isNotNullOrUndefined(objectToCheck) && objectToCheck.length === 0
}

export function isAnEmptyString(stringToCheck: string): boolean {
  return isNotNullOrUndefined(stringToCheck) && stringToCheck.length === 0
}

export function isNotAnEmptyString(stringToCheck: string): boolean {
  return !isAnEmptyString(stringToCheck)
}
