import { Chip, Header, Spacer, Text } from '@truepill/react-capsule'
import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { COMPLETED_HEALTH_QUESTION_LABEL, PATIENT_HEALTH_QUESTION_TYPE, PregnancyStatus } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { AnswerOption, FormMode, PatientHealthSurveyQuestion } from '../../interfaces'
import { HealthHistoryOptions } from '../../services/dto/health-history.dto'
import { capitalizeFirstLetter } from '../../utils/stringUtilities'
import {
  chipStyle,
  StyledAddAnswerContainer,
  StyledAddButtonContainer,
  StyledAnswerButton,
  StyledAnswerDetailsContainer,
  StyledAnswerListContainer,
  StyledCompletedAnswerContainer,
  StyledSaveAnswersContainer,
  StyledSaveButtonContainer,
  StyledShortAnswer,
  StyledShortAnswerButtons,
  StyledValidationErrorContainer,
} from './styledComponents'

export interface AccordionQuestionProps {
  data: PatientHealthSurveyQuestion
  mode: FormMode
  onSaveAnswer: (id: string, answerDetails: Array<string>, shortAnswer: HealthHistoryOptions) => void
  onCompleteForm: (id: string) => void
  onChangeMode?: (id: string) => void
  isDisabled: (mode: boolean) => void
}

const AccordionQuestion: React.FC<AccordionQuestionProps> = ({
  data,
  mode = FormMode.Edit,
  onSaveAnswer,
  onCompleteForm,
  onChangeMode,
  isDisabled,
}) => {
  const { answer, answerLabel, inputPlaceholder, answerOptions, answerDetails: details, id, pleaseListText, showFormWhenOptionSelected } = data
  const [answerInput, setAnswerInput] = useState('')
  const [inputErrorMessage, setInputErrorMessage] = useState('')
  const [answerDetails, setAnswerDetails] = useState(details)
  const [allergiesExceedLimit, setAllergiesExceedLimit] = useState(false)
  const [healthConditionsExceedLimit, setHealthConditionsExceedLimit] = useState(false)
  const [medicationsExceedLimit, setMedicationsExceedLimit] = useState(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [selectedOption, setSelectedOption] = useState<AnswerOption>()
  const [count, setCount] = useState(0)
  const { theme } = useContentfulTheme()

  useEffect(() => {
    setAnswerDetails(details)
  }, [details])

  useEffect(() => {
    const dirtyFields = allergiesExceedLimit || healthConditionsExceedLimit || medicationsExceedLimit
    if (dirtyFields) {
      isDisabled(true)
    } else {
      isDisabled(false)
    }
  }, [allergiesExceedLimit, healthConditionsExceedLimit, medicationsExceedLimit, isDisabled])

  useEffect(() => {
    const defaultOption = answerOptions.find((item) => item.value === answer)
    setSelectedOption(defaultOption)
  }, [answerOptions, answer])

  const getCharacterLimit = (answerLabel: string): number => {
    switch (answerLabel) {
      case COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES]:
        return 1200
      case COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS]:
        return 5000
      case COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS]:
        return 1500
      default:
        return 0
    }
  }

  const hasInputExceedTheLimit = (answerLabel: string, characterLimit: number, input: string): boolean => {
    switch (answerLabel) {
      case COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.ALLERGIES]:
        if (characterLimit !== 0 && input.length >= characterLimit) {
          setAllergiesExceedLimit(true)
          return true
        }
        return false
      case COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.HEALTH_CONDITIONS]:
        if (characterLimit !== 0 && input.length >= characterLimit) {
          setHealthConditionsExceedLimit(true)
          return true
        }
        return false
      case COMPLETED_HEALTH_QUESTION_LABEL[PATIENT_HEALTH_QUESTION_TYPE.MEDICATIONS]:
        if (characterLimit !== 0 && input.length >= characterLimit) {
          setMedicationsExceedLimit(true)
          return true
        }
        return false
      default:
        return false
    }
  }

  const isHealthHistoryOption = (value: HealthHistoryOptions | PregnancyStatus): value is HealthHistoryOptions => {
    return value === HealthHistoryOptions.No || value === HealthHistoryOptions.Yes || value === HealthHistoryOptions.NotSure
  }

  const changeSelectedOption = (selectedOption: AnswerOption) => {
    setSelectedOption(selectedOption)

    if (
      showFormWhenOptionSelected.length === 0 ||
      (showFormWhenOptionSelected.length &&
        selectedOption.value &&
        isHealthHistoryOption(selectedOption.value) &&
        !showFormWhenOptionSelected.includes(selectedOption.value))
    ) {
      if (selectedOption.value === HealthHistoryOptions.No) setAnswerDetails([])
      submitAnswer(selectedOption)
    }
  }

  const addAnswer = (event?: React.FormEvent) => {
    setCount(0)
    event && event.preventDefault()
    const answerInputIsNotEmpty = answerInput.trim().length > 0
    const answerInputExists = answerDetails.includes(answerInput.toLowerCase())
    const isValid = answerInputIsNotEmpty && !answerInputExists

    if (isValid) {
      setAnswerDetails((prevAnswerList) => {
        const characterLimit = getCharacterLimit(answerLabel)
        if (hasInputExceedTheLimit(answerLabel, characterLimit, [...prevAnswerList, answerInput.toLowerCase()].join())) {
          setInputErrorMessage(`Your entries have exceeded the ${characterLimit} character limit. Reduce the number of characters to continue.`)
        }
        return [...prevAnswerList, answerInput.toLowerCase()]
      })
      setAnswerInput('')
    }
  }

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>, answerLabel: string) => {
    setCount(e.target.value.length)

    validateInput(e, answerLabel)
    setAnswerInput(e.target.value)
  }

  const removeAnswer = (index: number) => {
    setAnswerDetails((prevAnswerList) => {
      const characterLimit = getCharacterLimit(answerLabel)
      if (hasInputExceedTheLimit(answerLabel, characterLimit, prevAnswerList.filter((answer, i) => i !== index).join())) {
        setSaveButtonDisabled(true)
        setInputErrorMessage(`Your entries have exceeded the ${characterLimit} character limit. Reduce the number of characters to continue.`)
      } else {
        setInputErrorMessage('')
        isDisabled(false)
        setSaveButtonDisabled(false)
        switch (characterLimit) {
          case 1200:
            setAllergiesExceedLimit(false)
            break
          case 5000:
            setHealthConditionsExceedLimit(false)
            break
          case 1500:
            setMedicationsExceedLimit(false)
            break
        }
      }

      return prevAnswerList.filter((answer, i) => i !== index)
    })
  }

  const validateInput = (e: React.ChangeEvent<HTMLInputElement>, answerLabel: string) => {
    const allergiesField = answerLabel.toLowerCase().includes('allergies')
    const healthConditionsField = answerLabel.toLowerCase().includes('conditions')
    const medicationsField = answerLabel.toLowerCase().includes('medications')

    const errorMessage = 'Each entry should not exceed more than 50 characters.'
    if (allergiesField) {
      if (e.target.value.length > 50) {
        setAllergiesExceedLimit(true)
        setInputErrorMessage(errorMessage)
      } else {
        setAllergiesExceedLimit(false)
        setInputErrorMessage('')
      }
    } else if (healthConditionsField) {
      if (e.target.value.length > 50) {
        setHealthConditionsExceedLimit(true)
        setInputErrorMessage(errorMessage)
      } else {
        setHealthConditionsExceedLimit(false)
        setInputErrorMessage('')
      }
    } else if (medicationsField) {
      if (e.target.value.length > 50) {
        setMedicationsExceedLimit(true)
        setInputErrorMessage(errorMessage)
      } else {
        setMedicationsExceedLimit(false)
        setInputErrorMessage('')
      }
    }
  }

  const submitAnswer = (selectedOption?: AnswerOption) => {
    if (selectedOption && selectedOption.value && isHealthHistoryOption(selectedOption.value)) {
      onSaveAnswer(id, selectedOption.value === HealthHistoryOptions.No ? [] : answerDetails, selectedOption.value)
      onChangeMode && onChangeMode(id)
      onCompleteForm(id)
    }
  }

  let fullAnswerDetail = ''

  if (selectedOption?.desc) {
    fullAnswerDetail = selectedOption.desc
  } else {
    switch (selectedOption?.value) {
      case HealthHistoryOptions.No:
        fullAnswerDetail = 'None'
        break
      case HealthHistoryOptions.Yes:
        fullAnswerDetail = answerDetails.map((answer) => capitalizeFirstLetter(answer)).join(', ')
        break
    }
  }

  const showForm = useMemo(
    () =>
      showFormWhenOptionSelected.length &&
      selectedOption?.value &&
      isHealthHistoryOption(selectedOption.value) &&
      showFormWhenOptionSelected.includes(selectedOption.value),
    [showFormWhenOptionSelected, selectedOption?.value],
  )

  return (
    <Fragment>
      {mode === FormMode.View ? (
        <StyledCompletedAnswerContainer>
          <StyledShortAnswer>{`${selectedOption?.label}`}&nbsp;</StyledShortAnswer>
          {selectedOption?.desc !== null && fullAnswerDetail && <span>{`- ${fullAnswerDetail}`}</span>}
        </StyledCompletedAnswerContainer>
      ) : (
        <Fragment>
          <StyledShortAnswerButtons>
            {answerOptions.map((option, index) => {
              return (
                <Fragment key={`${id}-${index}`}>
                  <StyledAnswerButton
                    data-testid={`${option.value}_answer_button`}
                    onClick={() => changeSelectedOption(option)}
                    variant={selectedOption?.value === option.value ? 'secondary' : 'secondary-outline'}
                    vpTheme={theme}
                  >
                    {option.label}
                  </StyledAnswerButton>
                </Fragment>
              )
            })}
          </StyledShortAnswerButtons>
          {showForm ? (
            <StyledAnswerDetailsContainer>
              <Spacer size='xl' />
              <div>
                <Header bold variant='xl'>
                  {pleaseListText}
                </Header>
                <Text>Click add after each entry</Text>
                <Spacer size='md' />
                <StyledAddAnswerContainer onSubmit={addAnswer} noValidate>
                  <ThemedTextField
                    data-testid='answer_textfield'
                    required
                    showRequiredIndicator
                    placeholder={inputPlaceholder}
                    label={answerLabel}
                    onChange={(e) => handleAnswerChange(e, answerLabel)}
                    value={answerInput}
                    vpTheme={theme}
                  />
                  <Spacer size='xs' axis='horizontal' />
                  <StyledAddButtonContainer>
                    <ThemedButton
                      data-testid='add_answer_button'
                      onClick={addAnswer}
                      disabled={allergiesExceedLimit || healthConditionsExceedLimit || medicationsExceedLimit}
                      vpTheme={theme}
                    >
                      Add
                    </ThemedButton>
                  </StyledAddButtonContainer>
                </StyledAddAnswerContainer>
                <div style={{ paddingLeft: '0.5rem', paddingTop: '0.5rem' }}>{count}/50</div>
                <Spacer size='lg' />
                {answerLabel.toLowerCase().includes('allergies') && allergiesExceedLimit && (
                  <StyledValidationErrorContainer vpTheme={theme}>{inputErrorMessage}</StyledValidationErrorContainer>
                )}
                {answerLabel.toLowerCase().includes('conditions') && healthConditionsExceedLimit && (
                  <StyledValidationErrorContainer vpTheme={theme}>{inputErrorMessage}</StyledValidationErrorContainer>
                )}
                {answerLabel.toLowerCase().includes('medications') && medicationsExceedLimit && (
                  <StyledValidationErrorContainer vpTheme={theme}>{inputErrorMessage}</StyledValidationErrorContainer>
                )}
                <StyledSaveAnswersContainer>
                  <StyledAnswerListContainer>
                    {answerDetails.map((answer, answerIndex) => {
                      return (
                        <Chip state='info' key={answerIndex} onDismiss={() => removeAnswer(answerIndex)} css={chipStyle}>
                          {answer}
                        </Chip>
                      )
                    })}
                  </StyledAnswerListContainer>
                  <StyledSaveButtonContainer>
                    <ThemedButton
                      data-testid='save_continue_button'
                      disabled={
                        saveButtonDisabled ||
                        answerDetails.length === 0 ||
                        allergiesExceedLimit ||
                        healthConditionsExceedLimit ||
                        medicationsExceedLimit
                      }
                      onClick={() => submitAnswer(selectedOption)}
                      vpTheme={theme}
                    >
                      Save
                    </ThemedButton>
                  </StyledSaveButtonContainer>
                </StyledSaveAnswersContainer>
              </div>
            </StyledAnswerDetailsContainer>
          ) : (
            <></>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default AccordionQuestion
