import { CreateSurveyRequest, sharedConstants, SurveyResponse, SurveyResult } from '@vpharm-platform/shared'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants
export interface SurveyService {
  createPatientSurvey(survey: CreateSurveyRequest, customerToken: string, patientToken: string): Promise<boolean>
  getPatientSurvey(customerToken: string, patientToken: string): Promise<SurveyResult | null>
}

class SurveyApiService implements SurveyService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/patient/patient_survey`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async createPatientSurvey(survey: CreateSurveyRequest, customerToken: string, patientToken: string): Promise<boolean> {
    if (!customerToken || !patientToken) {
      return false
    }

    const url = `${this.baseUrl}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    try {
      await axiosAuth.post<boolean>(url, { ...survey }, config)

      return true
    } catch (err) {
      return false
    }
  }

  async getPatientSurvey(customerToken: string, patientToken: string): Promise<SurveyResult | null> {
    const url = `${this.baseUrl}`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: customerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    const res = await axiosAuth.get<SurveyResponse>(url, config)
    if (res.data.latest_survey && Object.keys(res.data.latest_survey).length > 0) {
      return res.data.latest_survey
    } else {
      return null
    }
  }
}

export const surveyService: SurveyService = new SurveyApiService()
