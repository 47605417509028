import { useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import type { ICustomerSupportFields } from '../../types/generated/contentful'

type ReturnValue = {
  loading: boolean
  error: boolean
  content?: ICustomerSupportFields
}

export const useContentfulCustomerSupport = (): ReturnValue => {
  const [content, setContent] = useState<ICustomerSupportFields | undefined>()
  const { pharmCustomer, error, loading } = useCustomerConfigContext()

  useEffect(() => {
    const data = pharmCustomer?.customerSupport?.fields
    setContent(data)
  }, [pharmCustomer])

  return { loading, error, content }
}
export default useContentfulCustomerSupport
