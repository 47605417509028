export enum CopayErrorCode {
  MAIL_ORDER_FILLS_EXCEEDED = 'Mail Order Fills Exceeded',
  AUTO_REVERSAL_REQUIRED = 'Auto Reversal Required',
  COPAY_REQUEST_ABANDONED = 'Copay Request Abandoned',
  COVERAGE_TERMINATED = 'Coverage Terminated',
  NOT_COVERED_BY_INSURANCE = 'Not covered by insurance',
  OUT_OF_NETWORK = 'Out of Network',
  PHARMACY_NOT_ENROLLED_IN_MEDICAID_PROGRAM = 'Pharmacy Not Enrolled in State Medicaid Program',
  PRESCRIPTION_NO_MORE_REFILLS = 'Prescription Has No More Refills',
  PRESCRIPTION_TRANSFERRED = 'Prescription Transferred',
  PRIMARY_INSURANCE_REQUIRED = 'Primary Insurance Required',
  QUANTITY_LIMITED_BY_INSURANCE = 'Quantity Limited by Insurance',
  REFILL_TOO_SOON = 'Refill too soon',
  OTHER = 'Other',
  HIGH_COPAY = 'High Copay',
  INSURANCE_ISSUE = 'Insurance Issue',
  MISSING_INSURANCE_INFORMATION = 'Missing / Invalid Insurance Information',
  PRESCRIPTION_ISSUE = 'Prescription Issue',
  PRIOR_AUTHORIZATION_DENIED = 'Prior Authorization Denied',
  PRIOR_AUTHORIZATION_REQUIRED = 'Prior Authorization Required',
  DUPLICATE_REQUEST = 'Duplicate Request',
  MAXIMUM_BENEFITS_REACHED = 'Maximum benefits reached',
}
