import { Spacer } from '@truepill/react-capsule'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { PlusCircleSmall } from '../../../assets/Icons'
import { ThemedAutoComplete } from '../../../common/styledComponents/ThemedAutoComplete'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../../hooks'
import { PatientAddress } from '../../../interfaces'
import { formatAddressToSingleLine } from '../../../pages/AccountManagement/Addresses/data/addressSettings'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { SHIPPING_STATES } from '../../../utils/states'
import { CityWrapper, DetailWrapper, Hideable, MainWrapper, ToggleShowAddressLine2, Wrapper } from './styledComponents'

interface Props {
  isEditMode: boolean
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>
}

const ManualAddressFields = ({ isEditMode, setIsModified }: Props): ReactElement => {
  const {
    register,
    trigger,
    setValue,
    getValues,
    clearErrors,
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<PatientAddress>()

  const { address2 } = getValues()
  const [showAddressLine2, setShowAddressLine2] = useState(!!address2)

  const { theme } = useContentfulTheme()

  const setFullAddress = useCallback(() => {
    setIsModified(true)
    const formValues = getValues()
    if (formValues.address1 && formValues.city && formValues.state && formValues.zip) {
      const fullAddress = formatAddressToSingleLine(formValues)
      setValue('fullAddress', fullAddress, { shouldValidate: true })
    }
  }, [getValues, setValue, setIsModified])

  useEffect(() => {
    setFullAddress()
    isEditMode && setIsModified(false)
  }, [isEditMode, setFullAddress, setIsModified])

  return (
    <>
      <ThemedTextField
        data-testid='manual-add-address1'
        label='Address line 1'
        {...register('address1', {
          onChange: () => {
            clearErrors('address1')
            setFullAddress()
          },
        })}
        placeholder='Address line 1'
        helperText={errors.address1 ? 'Please enter street address' : ''}
        state={parseTextFieldStateForCapsule(errors.address1, dirtyFields.address1)}
        aria-label='Street address'
        required
        showRequiredIndicator
        vpTheme={theme}
      />
      <Spacer />
      <Hideable hidden={!isEditMode && !showAddressLine2}>
        <ThemedTextField
          data-testid='manual-add-address2'
          label='Address line 2'
          {...register('address2', {
            onChange: () => {
              clearErrors('address2')
              setFullAddress()
            },
          })}
          placeholder='Address line 2'
          state={parseTextFieldStateForCapsule(errors.address2, dirtyFields.address2)}
          aria-labelledby='address line 2'
          vpTheme={theme}
        />
        <Spacer size='sm' />
      </Hideable>
      {!isEditMode && (
        <>
          <ToggleShowAddressLine2
            type='button'
            variant='primary-text'
            onClick={() => setShowAddressLine2(true)}
            hidden={showAddressLine2}
            vpTheme={theme}
          >
            <PlusCircleSmall vpTheme={theme} />
            <Spacer size='sm' />
            Add address line 2
          </ToggleShowAddressLine2>
          <Spacer size='lg' />
        </>
      )}
      <DetailWrapper>
        <CityWrapper>
          <ThemedTextField
            id='city'
            data-testid='city-input'
            label='City'
            placeholder='City'
            helperText={errors.city?.message}
            state={parseTextFieldStateForCapsule(errors.city, dirtyFields.city)}
            required
            showRequiredIndicator
            {...register('city', {
              required: true,
              onChange: () => {
                clearErrors('city')
                setFullAddress()
              },
            })}
            vpTheme={theme}
          />
        </CityWrapper>
        <MainWrapper>
          <Controller
            name='state'
            control={control}
            render={({ field }) => (
              <ThemedAutoComplete
                id='state'
                data-testid='state-select'
                clearable={false}
                options={SHIPPING_STATES}
                label='State'
                helperText={errors.state?.message}
                state={parseTextFieldStateForCapsule(errors.state, dirtyFields.state)}
                required
                showRequiredIndicator
                value={field.value}
                placeholder='State'
                onChange={(option: string | null) => {
                  field.onChange(option)
                  setFullAddress()
                }}
                onInputValueChange={async (value: string) => {
                  await trigger('state')
                  if (!value) {
                    field.onChange('')
                  }
                }}
                noResultsText='No results.'
                vpTheme={theme}
              />
            )}
          />
          <Wrapper>
            <ThemedTextField
              id='zip'
              data-testid='zip-input'
              label='ZIP code'
              helperText={errors.zip?.message}
              placeholder='12345'
              state={parseTextFieldStateForCapsule(errors.zip, dirtyFields.zip)}
              maxLength={5}
              required
              showRequiredIndicator
              {...register('zip', {
                required: true,
                onChange: () => {
                  clearErrors('zip')
                  setFullAddress()
                },
              })}
              vpTheme={theme}
            />
          </Wrapper>
        </MainWrapper>
      </DetailWrapper>
    </>
  )
}

export default ManualAddressFields
