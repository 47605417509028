import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  position: relative;
`

const LoadingAnimationWrapper = styled.div<{ background?: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ background }) => background ?? defaultTheme.colors.white};
  opacity: 0.2;
  z-index: 1000;
`

const StyledText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color ?? defaultTheme.colors['typography-dark']};
`

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`

export { LoadingAnimationWrapper, StyledText, SubmitButtonContainer, Wrapper }
