import { useRecoilRefresher_UNSTABLE, useResetRecoilState } from 'recoil'

import { fetchPrescriptionsByAvailability, prescriptionsByAvailability } from '../recoil/atoms'

/*
  Force a refresh of prescriptions list for Prescription Management

  The prescriptionsByAvailability atom's value is dynamically synced when its default async selector changes
  *until* the atom's value is manually set - then the atom value becomes static.

  This hook will set the atom back to dynamic and refetch.
*/
export const useRefreshPrescriptionList = (): { refreshPrescriptionList: () => void } => {
  const resetAtom = useResetRecoilState(prescriptionsByAvailability)
  const resetSelector = useRecoilRefresher_UNSTABLE(fetchPrescriptionsByAvailability)
  const refreshPrescriptionList = () => {
    resetAtom()
    resetSelector()
  }
  return {
    refreshPrescriptionList,
  }
}
