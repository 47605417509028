// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TextField } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

import { ThemedComponent } from './types'

const ThemedTextField = styled(TextField)<ThemedComponent>`
  ${({ vpTheme, state }) => `
      ${state === 'complete' ? `background-color: ${vpTheme.colors.fieldBgComplete};` : ''}
      ${state === 'default' ? `background-color: ${vpTheme.colors.fieldBgDefault};` : ''}
      ${state === 'complete' ? `box-shadow: inset 0px 0px 0px 1px ${vpTheme.colors.fieldBorderComplete};` : ''}
      ${state === 'default' ? `box-shadow: inset 0px 0px 0px 1px ${vpTheme.colors.fieldBorderDefault};` : ''}
      &:focus {
        ${state === 'complete' ? `box-shadow: inset 0px 0px 0px 3px ${vpTheme.colors.fieldBorderComplete};` : ''}
        ${state === 'default' ? `box-shadow: inset 0px 0px 0px 3px ${vpTheme.colors.fieldBorderDefault};` : ''}
      }
    `}
`

export { ThemedTextField }
