import { useCallback, useEffect, useState } from 'react'

import type { ICompliancePage, ICompliancePageFields } from '../../types/generated/contentful'
import { getPage } from './contentfulService'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

type ReturnValues = {
  loading: boolean
  error: boolean
  pageContent: ICompliancePageFields | undefined
}

export const useCompliance = (pageType: string): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [pageContent, setPageContent] = useState<ICompliancePageFields>()
  const customerName = useGetCustomerNameFromSubdomain()

  const getComplianceData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const page = await getPage<ICompliancePage>(pageType, customerName, 'compliancePage')
      setPageContent(page.content.fields)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [customerName, pageType])

  useEffect(() => {
    if (pageType) getComplianceData()
  }, [customerName, getComplianceData, pageType])

  return {
    loading,
    error,
    pageContent,
  }
}
