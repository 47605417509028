import { useCallback, useEffect, useState } from 'react'

import type { IRibbonFields } from '../../types/generated/contentful'
import { ContentfulPage, getDynamicPage } from './contentfulService'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

export type ContentfulDynamicPageReturnValues = {
  error: boolean
  loading: boolean
  pageContent: ContentfulPage<IRibbonFields[]> | undefined
}

export const useContentfulDynamicPage = (pageName: string): ContentfulDynamicPageReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [pageContent, setPageContent] = useState<ContentfulPage<IRibbonFields[]>>()
  const customerName = useGetCustomerNameFromSubdomain()

  const getContentfulData = useCallback(
    async (pageName: string): Promise<void> => {
      try {
        setLoading(true)
        setPageContent(await getDynamicPage(pageName, customerName))
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    [customerName],
  )

  useEffect(() => {
    getContentfulData(pageName)
  }, [customerName, getContentfulData, pageName])

  return {
    error,
    loading,
    pageContent,
  }
}
