import { Header, SEO, Spacer } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import React, { Fragment, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useRecoilValue } from 'recoil'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import LoadingAnimation from '../../Components/LoadingAnimation'
import ToastMessage from '../../Components/ToastMessage'
import { useCustomerProfile } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { OrderPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import { useOrders } from '../../hooks/useOrders'
import { selectedPatientTokenAtom } from '../../persistRecoil'
import OrderItem from './OrderItems/OrderItem'
import { ContentWrapper, FetchMoreLoader, OrdersContainer, TryAgainButton } from './styledComponents'

const ErrorSection: React.FC<{ theme: VpTheme; onRetry: () => void; showErrorToast: boolean; onDismiss: () => void }> = ({
  theme,
  onRetry,
  showErrorToast,
  onDismiss,
}) => {
  return (
    <>
      <Header variant='xl' data-testid='empty-orders-error-message'>
        There has been an error retrieving the orders for this account.
      </Header>
      <TryAgainButton onClick={onRetry} data-testid='try-again-button' vpTheme={theme}>
        Try again
      </TryAgainButton>
      {showErrorToast ? (
        <ToastMessage
          onDismiss={onDismiss}
          position={{ vertical: 'top', horizontal: 'center' }}
          state='error'
          visible={showErrorToast}
          onTimeout={onDismiss}
        >
          There has been an error retrieving the orders for this account.
        </ToastMessage>
      ) : null}
    </>
  )
}

const OrderList: React.FC<{
  orders: any[]
  selectedPatientToken: string
  customerToken: string
}> = ({ orders, selectedPatientToken, customerToken }) => (
  <>
    {orders.map((orderItem, index) => (
      <OrderItem
        key={`${orderItem.identifier}-${index}`}
        patientName={orderItem.patientName}
        createdAt={orderItem.createdAt}
        items={orderItem.medications}
        order_identifier={orderItem.identifier}
        shipping={orderItem.shippingInfo}
        status={orderItem.status}
        triageInfo={orderItem.triageInfo}
        rejectionInfo={orderItem.rejectionInfo}
        patientToken={selectedPatientToken}
        customerToken={customerToken}
      />
    ))}
  </>
)

const Orders: React.FunctionComponent = () => {
  const { customerProfile } = useCustomerProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { theme, seo } = useGetPageContent<OrderPageMicrocopy>('orderPage')
  const { trackButtonClickEvent } = useAnalytics()

  const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: false })

  const { orders, totalOrders, isLoading, isFetchingMore, showErrorToast, showError, setShowErrorToast, fetchMoreOrders, handleRetryButtonClick } =
    useOrders({
      customerToken: customerProfile.vpharmCustomerToken,
      patientToken: selectedPatientToken,
    })

  useEffect(() => {
    if (inView && orders.length < totalOrders && !isFetchingMore) {
      fetchMoreOrders()
    }
  }, [inView, fetchMoreOrders, orders.length, totalOrders, isFetchingMore])

  if (isLoading) {
    return <LoadingAnimation />
  }

  const onRetryFetch = () => {
    trackButtonClickEvent('orders_try_again_button', 'try again', 'reload orders page')
    handleRetryButtonClick()
  }

  const hasOrders = orders.length > 0

  return (
    <Fragment>
      <OrdersContainer id={`container-order-${customerProfile.vpharmCustomerToken}`}>
        {seo?.fields.title && <SEO title={seo.fields.title || customerProfile.customerName} description={seo.fields.description} useDefaults />}
        <Spacer size='3xl' />
        <ThemedHeader vpTheme={theme} bold variant='4xl' data-testid='header-orders'>
          Orders
        </ThemedHeader>

        <ContentWrapper>
          {hasOrders ? (
            <OrderList orders={orders} selectedPatientToken={selectedPatientToken} customerToken={customerProfile.vpharmCustomerToken} />
          ) : (
            <>
              {showError ? (
                <ErrorSection onRetry={onRetryFetch} theme={theme} onDismiss={() => setShowErrorToast(false)} showErrorToast={showErrorToast} />
              ) : (
                <Header variant='xl' data-testid='empty-orders'>
                  No orders have been placed at the moment
                </Header>
              )}
            </>
          )}
          <FetchMoreLoader ref={ref} data-testid='order-infinite-scroll-wrapper'>
            {isFetchingMore && orders.length < totalOrders && <LoadingAnimation />}
          </FetchMoreLoader>
        </ContentWrapper>
      </OrdersContainer>
    </Fragment>
  )
}

export default Orders
