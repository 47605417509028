import { useCustomerProfile, usePatientProfile } from 'hooks'
import { useCallback, useEffect, useState } from 'react'

import { useAuth } from '../../../context/auth-context'
import AccountService from '../../../services/AccountService'
import { authService } from '../../../services/authService'
import { pharmacyService } from '../../../services/pharmacyService'
import { profileService } from '../../../services/profileService'

interface UseAccountSettings {
  accountProfile: AccountUserSettings | null
  bannerState: BannerState
  isLoading: boolean
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>
  fetchAccountProfile: () => Promise<void>
  updateCommunicationOptIn: (isOptedIn: boolean) => Promise<void>
  sendPasswordResetEmail: (email: string) => Promise<void>
  setBannerState: React.Dispatch<React.SetStateAction<BannerState>>
  modalState: ModalState
  setPhoneModalState: (isOpen: boolean) => void
  setEmailModalState: (isOpen: boolean) => void
  updatePhoneNumber: (phoneNumber: string) => Promise<void>
  emailUpdatingRequest: (newEmailAddress: string) => Promise<void>
  isEmailAddressAvailable: (emailAddress: string) => Promise<boolean>
}

interface AccountUserSettings {
  email?: string
  phone?: string
  communicationOptIn?: boolean
}

interface BannerState {
  showSuccessCommunicationBanner: boolean
  showSuccessPasswordResetBanner: boolean
  showSuccessPhoneNumberBanner: boolean
  showSuccessEmailUpdatingRequestBanner: boolean
}

interface ModalState {
  isPhoneNumberModalOpen: boolean
  isEmailModalOpen: boolean
}

const useAccountSettings = (vpharmCustomerToken: string, patientToken: string): UseAccountSettings => {
  const { customerProfile } = useCustomerProfile()
  const { patientProfile } = usePatientProfile()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modalState, setModalState] = useState({
    isPhoneNumberModalOpen: false,
    isEmailModalOpen: false,
  })
  const {
    authState: { user },
  } = useAuth()
  const [bannerState, setBannerState] = useState<BannerState>({
    showSuccessCommunicationBanner: false,
    showSuccessPasswordResetBanner: false,
    showSuccessPhoneNumberBanner: false,
    showSuccessEmailUpdatingRequestBanner: false,
  })
  const [accountProfile, setAccountProfile] = useState<AccountUserSettings | null>(null)
  const [error, setError] = useState('')

  const setPhoneModalState = useCallback((isOpen: boolean) => {
    setModalState((prevState) => ({ ...prevState, isPhoneNumberModalOpen: isOpen }))
  }, [])

  const setEmailModalState = useCallback((isOpen: boolean) => {
    setModalState((prevState) => ({ ...prevState, isEmailModalOpen: isOpen }))
  }, [])

  const fetchAccountProfile = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    setError('')
    try {
      const accountService = new AccountService(pharmacyService, profileService, authService)
      const accountSetting = await accountService.getAccountSettings(vpharmCustomerToken, patientToken)
      // This isn't ideal, the assumption is that patient profile and auth0 are aligned one to one on communications.
      setAccountProfile({
        communicationOptIn: accountSetting.communicationOptIn,
        phone: accountSetting.phoneNumber,
        email: patientProfile?.email ?? user?.email,
      })
    } catch (e) {
      setError('Unable to fetch account settings.')
    } finally {
      setIsLoading(false)
    }
  }, [vpharmCustomerToken, patientToken, patientProfile?.email, user?.email])

  const updateCommunicationOptIn = useCallback(
    async (isOptIn: boolean): Promise<void> => {
      setIsLoading(true)
      setError('')
      try {
        const accountService = new AccountService(pharmacyService, profileService, authService)
        await accountService.updateAccountSettings({ communicationOptIn: isOptIn }, vpharmCustomerToken, patientToken)
        setBannerState((prevState) => ({ ...prevState, showSuccessCommunicationBanner: true }))
        await fetchAccountProfile()
      } catch (e) {
        setError('Unable to update communication settings.')
      } finally {
        setIsLoading(false)
      }
    },
    [fetchAccountProfile, patientToken, vpharmCustomerToken],
  )

  const sendPasswordResetEmail = useCallback(
    async (email: string) => {
      setIsLoading(true)
      setError('')
      try {
        const accountService = new AccountService(pharmacyService, profileService, authService)
        await accountService.sendResetPasswordEmail(customerProfile.auth0Domain, customerProfile.auth0ClientId, email)
        setBannerState((prevState) => ({ ...prevState, showSuccessPasswordResetBanner: true }))
      } catch (e) {
        setError('Unable to send reset password email')
      } finally {
        setIsLoading(false)
      }
    },
    [customerProfile.auth0ClientId, customerProfile.auth0Domain],
  )

  const updatePhoneNumber = useCallback(
    async (updatedPhoneNumber: string) => {
      setError('')
      try {
        const accountService = new AccountService(pharmacyService, profileService, authService)
        await accountService.updateAccountSettings({ phone: updatedPhoneNumber }, vpharmCustomerToken, patientToken)
        await fetchAccountProfile()
        setBannerState((prevState) => ({ ...prevState, showSuccessPhoneNumberBanner: true }))
      } catch (e) {
        setError('Unable to update phone number')
      }
    },
    [patientToken, vpharmCustomerToken, fetchAccountProfile],
  )

  const isEmailAddressAvailable = useCallback(
    async (emailAddress: string) => {
      try {
        const accountService = new AccountService(pharmacyService, profileService, authService)
        const result = await accountService.isEmailAddressAvailable(emailAddress, vpharmCustomerToken, patientToken)
        return result
      } catch (e) {
        return Promise.resolve(true)
      }
    },
    [patientToken, vpharmCustomerToken],
  )

  const emailUpdatingRequest = useCallback(
    async (newEmailAddress: string) => {
      setError('')
      setIsLoading(true)
      const accountService = new AccountService(pharmacyService, profileService, authService)
      const result = await accountService.emailUpdatingRequest(newEmailAddress, vpharmCustomerToken, patientToken)
      if (result.success) {
        setBannerState((prevState) => ({ ...prevState, showSuccessEmailUpdatingRequestBanner: true }))
      } else {
        setError(`Unable to send verification email. ${result.message ?? ''}`)
      }
      setIsLoading(false)
    },
    [patientToken, vpharmCustomerToken],
  )

  useEffect(() => {
    fetchAccountProfile()
  }, [fetchAccountProfile])

  return {
    accountProfile,
    bannerState,
    setBannerState,
    isLoading,
    error,
    setError,
    fetchAccountProfile,
    updateCommunicationOptIn,
    sendPasswordResetEmail,
    modalState,
    setPhoneModalState,
    setEmailModalState,
    updatePhoneNumber,
    emailUpdatingRequest,
    isEmailAddressAvailable,
  }
}

export default useAccountSettings
