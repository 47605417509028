import { Spacer, Text } from '@truepill/react-capsule'
import moment from 'moment'
import React from 'react'

import { useCheckoutContext } from '../CheckoutProvider'
import { AddressInfoView } from './AddressInfoView'

export const ViewMode: React.FC = () => {
  const { selectedShippingMethod } = useCheckoutContext()

  return (
    <>
      <AddressInfoView />
      <Spacer size='lg' />
      <Text bold>Shipping method</Text>
      <Text>{selectedShippingMethod?.shipping_name}</Text>
      <Spacer size='lg' />
      <Text bold>Expected delivery date</Text>
      <Text>{moment(selectedShippingMethod?.estimated_time_arrivals).format('ddd, MMM. D')}</Text>
    </>
  )
}
