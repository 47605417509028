import { Chip } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { media, mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { RxmCardContainer } from '../../../common/styledComponents/RxmCardContainer'
import { Modal } from '../../../common/styledComponents/StyledModal'
import { ThemedComponent } from '../../../common/styledComponents/types'

const StyledHeaderBrandName = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;

  ${mediaLargerThan.tablet} {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.875rem;
  }
`

const PrescriptionDetails = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  @media (max-width: ${media.tablet}) {
    flex-direction: column;
  }

  @media (min-width: ${media.tablet}) {
    gap: 0.3rem;
  }
`

const StyledModal = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const AddSavingsCard = styled.div<{ background: string; color: string; borderColor?: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1.5625rem;
  color: ${({ color }) => color};
  background-color: ${({ background }) => background};
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 1.2875rem;
    height: 1.2875rem;
  }

  ${mediaLargerThan.tablet} {
    max-width: 744px;
  }
`

const SavingsCardFormModal = styled(StyledModal)`
  [data-reach-dialog-content] {
    max-width: 800px;
  }
`

const PrescriptionHeaderContainer = styled.div<ThemedComponent & { borderRadius?: boolean }>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
  }
  border-bottom: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
  border-radius: ${({ borderRadius }) => (borderRadius ? '0.5rem 0.5rem 0 0' : 'unset')};
`

const PrescriptionName = styled.div`
  width: 18.75rem;
  p:first-child {
    margin-bottom: 0.25em;
  }
`

const PriceBreakdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-top: 0.875rem;

  ${mediaLargerThan.laptop} {
    margin-top: 1.5rem;
  }
`

const PriceBreakdownLine = styled.div`
  display: flex;
  justify-content: space-between;
`

const PrescriptionImage = styled.div`
  svg {
    width: 7.1875rem;
    height: 4.375rem;
  }
`

const RemoveContainer = styled.div`
  background-color: ${defaultTheme.colors['gray-100']};
  max-height: 3.4374;
  line-height: 1.25rem;
  margin-top: 0.875rem;

  p {
    display: inline-block;
  }
`

const PrescriptionNameContainer = styled.div<ThemedComponent>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
  border-radius: 8px 8px 0 0;

  ${mediaLargerThan.tablet} {
    border-right: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
    border-bottom: none;
    padding-bottom: 0;
  }
`

const PrescriptionStatusText = styled.div<ThemedComponent>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
`

const PrescriptionStatusContainer = styled.div<ThemedComponent>`
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite ?? defaultTheme.colors.grayWhite};
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const PrescriptionShowMoreContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite ?? defaultTheme.colors.grayWhite};
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']};
  border-radius: 0 0 0.5rem 0.5rem;
`

const SavingsCardChip = styled(Chip)<{ color: string; background: string }>`
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 100px;
  height: 2rem;
  max-width: 185px;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};

  p {
    white-space: nowrap;
    font-size: 0.7rem;
  }

  ${mediaLargerThan.tablet} {
    margin-bottom: 0.4375rem;
  }
`

const PreventLineBreak = styled.span`
  white-space: nowrap;
`

const PrescriptionImageContainer = styled.div`
  width: 8.125rem;
  display: flex;
  align-items: start;
`

const ClickableChipWrapper = styled.div<{ pointerOnHover: boolean }>`
  ${({ pointerOnHover }) => pointerOnHover && 'cursor: pointer;'}
`

const PricingInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 0.875rem;
  width: 100%;

  ${mediaLargerThan.tablet} {
    padding-left: 1.75rem;
    padding-top: 0;
    align-items: start;
  }
`

const PricingInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`

const BaseSpan = styled.span`
  font-size: 1rem;
  line-height: 1.25rem;
`

const StyledSpan = styled(BaseSpan)`
  @media (max-width: ${media.tablet}) {
    &:nth-child(even):before {
      content: ' • ';
    }
  }

  @media (min-width: ${media.tablet}) {
    &:before {
      content: ' • ';
    }
  }
`

const StyledHeader = styled.header<{ priceTransparencyFlag?: boolean }>`
  font-size: 1.5rem;
  font-weight: bold;

  ${mediaLargerThan.tablet} {
    font-size: ${(props) => (props.priceTransparencyFlag ? '1.5rem' : '2.25rem')};
  }
`

const RemoveContainerDesktop = styled(RemoveContainer)`
  display: none;
  ${mediaLargerThan.tablet} {
    display: block;
  }
`

const RemoveContainerMobile = styled(RemoveContainer)`
  display: block;
  ${mediaLargerThan.tablet} {
    display: none;
  }
`

const TransferOutModal = styled(Modal)`
  [data-reach-dialog-content] {
    max-width: 50rem;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.4rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const SpanWholeLineInMobile = styled(BaseSpan)``

export const StyledPrescriptionCard = {
  PrescriptionImageContainer,
  PreventLineBreak,
  SavingsCardChip,
  PrescriptionShowMoreContainer,
  PrescriptionStatusContainer,
  PrescriptionStatusText,
  PrescriptionNameContainer,
  PrescriptionImage,
  PrescriptionName,
  PrescriptionHeaderContainer,
  SavingsCardFormModal,
  TransferOutModal,
  AddSavingsCard,
  PrescriptionCardContainer: RxmCardContainer,
  ClickableChipWrapper,
  RemoveContainer,
  PriceBreakdown,
  PriceBreakdownLine,
  PricingInfo,
  PricingInfoHeader,
  StyledHeaderBrandName,
  PrescriptionDetails,
  StyledSpan,
  StyledHeader,
  RemoveContainerDesktop,
  RemoveContainerMobile,
  SpanWholeLineInMobile,
}
