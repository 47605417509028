import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { AlertContainer } from './styledComponents'

interface AlertProps {
  text: string
}

const PageAlert: React.FunctionComponent<AlertProps> = ({ text }: AlertProps) => {
  const { theme } = useContentfulTheme()

  return (
    <AlertContainer border={theme.colors['functional-info-dark']} background={theme.colors['functional-info-light']}>
      <Text css={{ fontSize: '0.875rem', color: `${theme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']}` }}>
        <b>Please note:</b> {text}
      </Text>
    </AlertContainer>
  )
}

export default PageAlert
