import styled from 'styled-components'

import { Modal } from '../../../common/styledComponents/StyledModal'
import { mediaLargerThan } from '../../../common/styles/variables'

const DisenrollModal = styled(Modal)`
  [data-reach-dialog-content] {
    max-width: 50rem;
  }
  [data-cap-modal-close-button='true'] {
    top: 1.2rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }

  ${mediaLargerThan.tablet} {
    [data-reach-dialog-content] {
      max-width: 500px;
    }
    [data-cap-modal-close-button='true'] {
      top: 2em;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`

export const StyledAutoRefillDisenrollModal = {
  DisenrollModal,
  ButtonContainer,
}
