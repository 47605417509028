import { DateTime } from 'luxon'

export const isValidDate = (year: number, month: number, day: number): boolean => {
  const today = DateTime.now()
  const testDate = DateTime.local(year, month, day)

  if (testDate.invalidReason) {
    return false
  }

  return testDate <= today
}

export const isOver18 = (year: number, month: number, day: number): boolean => {
  const today = DateTime.now()
  const testDate = DateTime.local(year, month, day)
  const diffInYears = today.diff(testDate, 'years').years

  // Under 18
  if (diffInYears < 18) {
    return false
  }

  return true
}

export const isValidYear = (year: number, month?: number, day?: number): boolean => {
  const today = DateTime.now()

  // selected year cannot be in the future
  if (year > today.year) {
    return false
  }

  // Check if date is valid
  if (year && month && day) {
    return isValidDate(year, month, day)
  }

  return true
}

export const isValidMonth = (month: number, year?: number, day?: number): boolean => {
  const today = DateTime.now()

  // selected month cannot be in the future
  if (month && year) {
    return DateTime.local(year, month) <= today
  }

  if (month && year && day) {
    return isValidDate(year, month, day)
  }

  return true
}

export const isValidDay = (day: number, year?: number, month?: number): boolean => {
  if (day && month && year) {
    return isValidDate(year, month, day)
  }

  return true
}

export const isValidEmail = (email: string): boolean => {
  const match = email.match(/^[\w.!#$%&'*+/=?^`{|}~-]+@([\w-]+\.)+[\w-]{2,4}$/g)
  return match !== null
}
