import { Spacer, Toggle } from '@truepill/react-capsule'
import React, { Fragment, PropsWithChildren, ReactElement, useEffect, useState } from 'react'

import { TransferSimulationState } from '../../../interfaces'

export interface UseTransferSimulation {
  transferSimulation: TransferSimulationState
  setTransferErrorChecked: (val: boolean) => void
  setTransferSimulationChecked: (val: boolean) => void
}

const INITIAL_TRANSFER_SIMULATION: TransferSimulationState = {
  transferSimulationChecked: false,
  transferErrorChecked: false,
  simulation: {},
}

export const useTransferSimulation = (): UseTransferSimulation => {
  const [transferSimulationChecked, setTransferSimulationChecked] = useState(false)
  const [transferErrorChecked, setTransferErrorChecked] = useState(false)
  const [transferSimulation, setTransferSimulation] = useState(INITIAL_TRANSFER_SIMULATION)

  useEffect(() => {
    if (transferErrorChecked) {
      setTransferSimulation({
        transferSimulationChecked,
        transferErrorChecked,
        simulation: {
          error: {
            reject_reason: 'Transfer Simulation Error',
          },
        },
      })
    } else {
      setTransferSimulation({
        transferSimulationChecked,
        transferErrorChecked,
        simulation: {},
      })
    }
  }, [transferSimulationChecked, transferErrorChecked, setTransferSimulation])

  return { transferSimulation, setTransferSimulationChecked, setTransferErrorChecked }
}

export const TransferSimulation = (props: PropsWithChildren<UseTransferSimulation>): ReactElement => {
  const { transferSimulation: simulationInfo, setTransferSimulationChecked, setTransferErrorChecked } = props

  return (
    <Fragment>
      <Spacer />
      <Toggle
        label='Enable Transfer Simulation'
        withText={true}
        checked={simulationInfo.transferSimulationChecked}
        onChange={setTransferSimulationChecked}
      />
      {simulationInfo.transferSimulationChecked && (
        <Toggle label='Simulate Transfer Error' withText={true} checked={simulationInfo.transferErrorChecked} onChange={setTransferErrorChecked} />
      )}
    </Fragment>
  )
}
