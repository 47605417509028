import { Popover, PopoverContent, PopoverTrigger, Spacer } from '@truepill/react-capsule'
import { TooltipPosition } from '@truepill/react-capsule/dist/components/tooltip/Tooltip'
import { VpTheme } from '@vpharm-platform/shared'
import { ReactElement, useState } from 'react'

import { InfoCircleIcon, WarningIcon } from '../../assets/Icons'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { truepillPharmacyDetails } from '../../pages/TransferRequest/TransferDetailsForm/TransferInClosedPharmacy'
import {
  ContentTextContainer,
  DetailsTextStyle,
  IconWrapper,
  StyledDesktopTooltip,
  StyledMobileTooltip,
  StyledMobileTooltipContent,
  StyledMobileTooltipDetails,
  StyledMobileTooltipHeader,
  StyledModal,
  StyledTooltipContent,
  StyledToolTipLabel,
  TooltipContainer,
} from './styledComponents'

interface InfoTooltipProps {
  label: string
  text: string | JSX.Element
  position: TooltipPosition
  testId: string
  ariaLabel?: string
  iconType?: string
  truepillPharmacyInfo?: truepillPharmacyDetails
  displayLabel?: boolean
}

interface InfoIconProps {
  testId?: string
  ariaLabel?: string
  iconType?: string
  vpTheme: VpTheme
}

interface Props {
  header: boolean
}

export const InfoTooltip = ({
  label,
  text,
  position,
  testId,
  ariaLabel,
  iconType,
  truepillPharmacyInfo,
  displayLabel,
}: InfoTooltipProps): ReactElement => {
  const { trackTooltipOpenEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const TooltipContent = (): ReactElement =>
    truepillPharmacyInfo ? (
      <TooltipContainer>
        <StyledTooltipContent vpTheme={theme}>{text}</StyledTooltipContent>
        <TruepillPharmacyInfo header={true} />
      </TooltipContainer>
    ) : (
      <StyledTooltipContent vpTheme={theme}>{text}</StyledTooltipContent>
    )

  const trackTooltipAnalyticsEvent = () => trackTooltipOpenEvent(label)

  const DesktopTooltip = (): ReactElement => (
    <StyledDesktopTooltip onMouseEnter={trackTooltipAnalyticsEvent}>
      <Popover>
        <PopoverTrigger css={{ borderStyle: 'none', backgroundColor: 'inherit', textDecoration: 'none' }}>
          <StyledToolTipLabel vpTheme={theme}>
            {displayLabel && label}
            <InfoIcon testId={testId} ariaLabel={ariaLabel} iconType={iconType} vpTheme={theme} />
          </StyledToolTipLabel>
        </PopoverTrigger>
        <PopoverContent side={position} variant='primary' css={{ width: '300px' }}>
          <TooltipContent />
        </PopoverContent>
      </Popover>
    </StyledDesktopTooltip>
  )

  const TruepillPharmacyInfo = ({ header }: Props): ReactElement => {
    return (
      <>
        <DetailsTextStyle fontWeight={'700'} header={header}>
          Truepill Pharmacy
        </DetailsTextStyle>
        <DetailsTextStyle>{truepillPharmacyInfo?.address}</DetailsTextStyle>
        <ContentTextContainer paddingTop={'20px'}>
          <DetailsTextStyle fontWeight={'700'}>NPI:</DetailsTextStyle>
          <DetailsTextStyle>{truepillPharmacyInfo?.npi}</DetailsTextStyle>
        </ContentTextContainer>
        <ContentTextContainer>
          <DetailsTextStyle fontWeight={'700'}>NCPDP:</DetailsTextStyle>
          <DetailsTextStyle>{truepillPharmacyInfo?.ncpdp}</DetailsTextStyle>
        </ContentTextContainer>
      </>
    )
  }

  const MobileTooltip = (): ReactElement => {
    const [isOpen, setIsOpen] = useState(false)
    const { theme } = useContentfulTheme()
    const setModalVisibility = (visible: boolean) => () => {
      visible && trackTooltipAnalyticsEvent()
      setIsOpen(visible)
    }

    return (
      <StyledMobileTooltip onClick={setModalVisibility(true)}>
        {displayLabel && label}
        <InfoIcon testId={testId} ariaLabel={ariaLabel} iconType={iconType} vpTheme={theme} />
        {isOpen && (
          <StyledModal isOpen={isOpen} onDismiss={setModalVisibility(false)}>
            <StyledMobileTooltipHeader variant='4xl'>{label}</StyledMobileTooltipHeader>
            {truepillPharmacyInfo && <Spacer size='xl' />}
            <StyledMobileTooltipContent vpTheme={theme}>{text}</StyledMobileTooltipContent>
            {truepillPharmacyInfo && (
              <StyledMobileTooltipDetails vpTheme={theme}>
                <TruepillPharmacyInfo header={false} />
              </StyledMobileTooltipDetails>
            )}
          </StyledModal>
        )}
      </StyledMobileTooltip>
    )
  }

  return (
    <>
      <DesktopTooltip />
      <MobileTooltip />
    </>
  )
}

const InfoIcon = ({ testId, ariaLabel, iconType, vpTheme }: InfoIconProps): ReactElement => (
  <IconWrapper data-testid={testId + '-info'}>
    {iconType === 'alert' ? <WarningIcon vpTheme={vpTheme} /> : <InfoCircleIcon ariaLabel={ariaLabel} vpTheme={vpTheme} />}
  </IconWrapper>
)
