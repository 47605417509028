import { createGlobalStyle } from 'styled-components/macro'

export default createGlobalStyle`
  /* This is our global css stylesheet */
  
  /* Stupid Last Pass styling */
  #__lpform_username, #__lpform_password, #__lpform_newPassword, #__lpform_confirmNewPassword {
    img {
      width: auto !important;
    }
  }
  /* iOS styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button p,
  h3 div {
    -webkit-text-fill-color: var(--cap-colors-typography-dark);
  }
  input[type='text'],
  button {
    -webkit-appearance: none;
  }
  button span svg polyline {
    stroke:  var(--cap-colors-typography-white);
  }
  /* brand colors */
  .cap-primary-100 {
    color: var(--cap-colors-primary-100);
  }
  .cap-primary-300 {
    color: var(--cap-colors-primary-300);
  }
  .cap-primary-500 {
    color: var(--cap-colors-primary-500);
  }
  .cap-primary-700 {
    color: var(--cap-colors-primary-700);
  }
  .cap-primary-900 {
    color: var(--cap-colors-primary-900);
  }

  .cap-secondary-100 {
    color: var(--cap-colors-secondary-100);
  }
  .cap-secondary-300 {
    color: var(--cap-colors-secondary-300);
  }
  .cap-secondary-500 {
    color: var(--cap-colors-secondary-500);
  }
  .cap-secondary-700 {
    color: var(--cap-colors-secondary-700);
  }
  .cap-secondary-900 {
    color: var(--cap-colors-secondary-900);
  }

  /* functional colors */
  .cap-functional-dark-info {
    color: var(--cap-colors-functional-info-dark);
  }
  .cap-functional-light-info {
    color: var(--cap-colors-functional-info-light);
  }
  .cap-functional-dark-warning {
    color: var(--cap-colors-functional-warning-dark);
  }
  .cap-functional-light-warning {
    color: var(--cap-colors-functional-warning-light);
  }
  .cap-functional-dark-success {
    color: var(--cap-colors-functional-success-dark);
  }
  .cap-functional-light-success {
    color: var(--cap-colors-functional-success-light);
  }
  .cap-functional-dark-error {
    color: var(--cap-colors-functional-error-dark);
  }
  .cap-functional-light-error {
    color: var(--cap-colors-functional-error-light);
  }

  /* buttons */
  .cap-btn-primary-color {
    color: var(--cap-btn-primary-color);
  }
  .cap-btn-primary-background-hover {
    color: var(--cap-btn-primary-background-hover);
  }
  .cap-btn-primary-background {
    color: var(--cap-btn-primary-background);
  }

  .cap-btn-secondary-color {
    color: var(--cap-btn-secondary-color);
  }
  .cap-btn-secondary-background-hover {
    color: var(--cap-btn-secondary-background-hover);
  }
  .cap-btn-secondary-background {
    color: var(--cap-btn-secondary-background);
  }

  /* typography */
  .cap-typography-dark {
    color: var(--cap-colors-typography-dark);
  }
  .cap-typography-medium {
    color: var(--cap-colors-typography-medium);
  }
  .cap-typography-light {
    color: var(--cap-colors-typography-light);
  }
  .cap-typography-white {
    color: var(--cap-colors-typography-white);
  }

  /* grays */
  .cap-white {
    color: var(--cap-white);
  }
  .cap-black {
    color: var(--cap-black);
  }
  .cap-gray-100 {
    color: var(--cap-colors-gray-100);
  }
  .cap-gray-300 {
    color: var(--cap-colors-gray-300);
  }
  .cap-gray-500 {
    color: var(--cap-colors-gray-500);
  }
  .cap-gray-700 {
    color: var(--cap-colors-gray-700);
  }
  .cap-gray-900 {
    color: var(--cap-colors-gray-900);
  }

  /* text fields  */
  .cap-field-background-default {
    background-color: var(--cap-field-background-default);
  }
  .cap-field-outline-default {
    background-color: var(--cap-field-outline-default);
  }
  .cap-field-background-complete {
    background-color: var(--cap-field-background-complete);
  }
  .cap-field-outline-complete {
    background-color: var(--cap-field-outline-complete);
  }
  .cap-field-background-active {
    background-color: var(--cap-field-background-active);
  }
  .cap-field-outline-active {
    background-color: var(--cap-field-outline-active);
  }
  .cap-field-background-error {
    background-color: var(--cap-field-background-error);
  }
  .cap-field-outline-error {
    color: var(--cap-field-outline-error);
  }

  /* element default overrides */
  .cap-chip-info-border-color {
    borderColor: var(--cap-chip-info-border-borderColor);
  }
  .cap-chip-primary-color {
    background-color: var(--cap-chip-primary-color);
  }
  .cap-chip-primary-border-color {
    borderColor: var(--cap-chip-primary-border-color);
  }

  /* fonts  */
  /* <Header /> component  */
  .cap-header-8xl-font-size {
    fontSize: var(--cap-header-8xl-font-size);
  }
  .cap-header-8xl-line-height {
    fontSize: var(--cap-header-8xl-line-height);
  }
  .cap-header-8xl-font-size-tablet {
    fontSize: var(--cap-header-8xl-font-size-tablet);
  }
  .cap-header-8xl-line-height-tablet {
    fontSize: var(--cap-header-8xl-line-height-tablet);
  }
  .cap-header-8xl-font-size-desktop {
    fontSize: var(--cap-header-8xl-font-size-desktop);
  }
  .cap-header-8xl-line-height-desktop {
    fontSize: var(--cap-header-8xl-line-height-desktop);
  }

  .cap-header-7xl-line-height {
    line-height: var(--cap-header-7xl-line-height);
  }
  .cap-header-7xl-line-height-tablet {
    line-height: var(--cap-header-7xl-line-height-tablet);
  }
  .cap-header-7xl-line-height-desktop {
    line-height: var(--cap-header-7xl-line-height-desktop);
  }

  .cap-header-6xl-line-height {
    line-height: var(--cap-header-6xl-line-height);
  }
  .cap-header-6xl-line-height-tablet {
    line-height: var(--cap-header-6xl-line-height-tablet);
  }
  .cap-header-6xl-line-height-desktop {
    line-height: var(--cap-header-6xl-line-height-desktop);
  }

  .cap-header-5xl-line-height {
    line-height: var(--cap-header-5xl-line-height);
  }
  .cap-header-5xl-line-height-tablet {
    line-height: var(--cap-header-5xl-line-height-tablet);
  }
  .cap-header-5xl-line-height-desktop {
    line-height: var(--cap-header-5xl-line-height-desktop);
  }

  .cap-header-4xl-line-height {
    line-height: var(--cap-header-4xl-line-height);
  }
  .cap-header-4xl-line-height-tablet {
    line-height: var(--cap-header-4xl-line-height-tablet);
  }
  .cap-header-4xl-line-height-desktop {
    line-height: var(--cap-header-4xl-line-height-desktop);
  }

  .cap-header-3xl-line-height {
    line-height: var(--cap-header-3xl-line-height);
  }
  .cap-header-3xl-line-height-desktop {
    line-height: var(--cap-header-3xl-line-height-desktop);
  }

  .cap-header-2xl-line-height {
    line-height: var(--cap-header-2xl-line-height);
  }
  .cap-header-2xl-line-height-tablet {
    line-height: var(--cap-header-2xl-line-height-tablet);
  }
  .cap-header-2xl-line-height-desktop {
    line-height: var(--cap-header-2xl-line-height-desktop);
  }

  .cap-header-xl-line-height-desktop {
    line-height: var(--cap-header-xl-line-height-desktop);
  }

  /* <Text /> component  */
  .cap-text-body-line-height {
    line-height: var(--cap-text-body-line-height);
  }
  .cap-text-caption-font-size {
    fontSize: var(--cap-text-caption-font-size);
  }

  /* <Chip /> component  */
  .cap-chip-font-weight {
    font-weight: var(--cap-chip-font-weight);
  }
  .cap-chip-primary-background {
    background-color: var(--cap-chip-primary-background);
  }

  /* <Toggle /> component */
  .cap-toggle-circle-background-color {
    background-color: var(--cap-toggle-circle-background-color);
  }

  .underline {
    text-decoration-line: underline;
  }

  .block {
    display: block;
  }
  
  .hidden {
    display: none;
  }

  .full-width {
    width: 100%;
  }

  .sliding-cart-overlay{
    z-index: 10;
  }

  p {
  line-height: 1.5em;
}
`
