import { Header, Spacer, Text } from '@truepill/react-capsule'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SmallButtonArrow, TransferInArrow, TransferOutArrow } from '../../../assets/Icons'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { useLDContextWithLocalStorage } from '../../../Components/LDProvider'
import { PrescriptionManagementLayout } from '../../../Components/PageLayoutWithSidebar'
import StyledToastMessage from '../../../Components/ToastMessage'
import { PRESCRIPTION_MANAGEMENT_PATH, PrescriptionManagementPage, retrieveNavigationLinks } from '../../../constants'
import { ToastState } from '../../../constants/toastConstants'
import { useContentfulTheme, useCustomerProfile } from '../../../hooks'
import PrescriptionTransferCard from './PrescriptionTransferCard/PrescriptionTransferCard'
import { StyledPrescriptionTransferV2 } from './styledComponent'
import { PrescriptionTransferV2Props } from './types'

const PrescriptionTransferV2 = ({
  transferMedications,
  showTransferOutSuccessToast,
  handleTransferOutSuccessToast,
  error,
  dismissError,
  handleTransferIn,
  handleTransferOut,
}: PrescriptionTransferV2Props): React.ReactElement => {
  const { customerProfile } = useCustomerProfile()
  const history = useHistory()
  const { theme } = useContentfulTheme()
  const { prescriptionTransfersV2, autoRefills, transfersOut: transfersOutFFEnabled } = useLDContextWithLocalStorage()
  const someTransferTypesEnabled = customerProfile.allowPrescriptionTransfers || (customerProfile.transferOut && transfersOutFFEnabled)

  useEffect(() => {
    // If customer doesn't have transfer in and transfer out allowed, send them back
    if (!customerProfile.allowPrescriptionTransfers && !customerProfile.transferOut) {
      history.replace(PRESCRIPTION_MANAGEMENT_PATH)
    }
  }, [customerProfile, history])

  return (
    <PrescriptionManagementLayout
      selected={PrescriptionManagementPage.PrescriptionTransfer}
      navigationLinks={retrieveNavigationLinks(customerProfile, someTransferTypesEnabled, autoRefills, theme)}
    >
      <StyledPrescriptionTransferV2.Container>
        <ThemedHeader vpTheme={theme} bold variant='4xl'>
          Prescription transfer
        </ThemedHeader>
        <Spacer size='md' />
        <Text>Transfer your prescriptions and view the status of transfer requests.</Text>
        <Spacer size='lg' />
        <StyledPrescriptionTransferV2.TransferOptionContainer>
          {customerProfile.transferOut && transfersOutFFEnabled && (
            <StyledPrescriptionTransferV2.TransferOutOptionContainer vpTheme={theme}>
              <div>
                <Header variant='xl'>Transfer prescriptions to another pharmacy</Header>
                <Text>Pick up your prescriptions in person at a retail pharmacy</Text>
                <Spacer size='md' />
                <StyledPrescriptionTransferV2.TransferButton
                  role='button'
                  onClick={handleTransferOut}
                  $backgroundColor={theme.colors['primary-700']}
                  $borderColor={theme.colors['primary-700']}
                  $hoverColor={theme.colors['primary-500']}
                  reversed
                  vpTheme={theme}
                >
                  <SmallButtonArrow vpTheme={theme} />
                  Transfer out
                </StyledPrescriptionTransferV2.TransferButton>
              </div>
              <TransferOutArrow vpTheme={theme} />
            </StyledPrescriptionTransferV2.TransferOutOptionContainer>
          )}
          {customerProfile.allowPrescriptionTransfers && prescriptionTransfersV2 && (
            <StyledPrescriptionTransferV2.TransferInOptionContainer vpTheme={theme}>
              <div>
                <Header variant='xl'>Transfer prescriptions to {customerProfile.customerName} powered by Truepill</Header>
                <Text>Move prescriptions from your current pharmacy</Text>
                <Spacer size='md' />
                <StyledPrescriptionTransferV2.TransferButton
                  role='button'
                  onClick={handleTransferIn}
                  $backgroundColor={theme.colors['primary-700']}
                  $borderColor={theme.colors['primary-700']}
                  $hoverColor={theme.colors['primary-500']}
                  vpTheme={theme}
                >
                  <SmallButtonArrow vpTheme={theme} />
                  Transfer in
                </StyledPrescriptionTransferV2.TransferButton>
              </div>
              <TransferInArrow vpTheme={theme} />
            </StyledPrescriptionTransferV2.TransferInOptionContainer>
          )}
        </StyledPrescriptionTransferV2.TransferOptionContainer>
      </StyledPrescriptionTransferV2.Container>
      <Spacer size='2xl' />
      <Header variant='2xl'>Current transfer requests</Header>
      <Spacer size='md' />
      <StyledPrescriptionTransferV2.TransferContainer>
        {transferMedications.length > 0 ? (
          transferMedications.map((med) => <PrescriptionTransferCard key={med.transferToken} med={med} theme={theme} />)
        ) : (
          <StyledPrescriptionTransferV2.NoTransfersContainer vpTheme={theme}>
            <Text>No transfers in progress</Text>
          </StyledPrescriptionTransferV2.NoTransfersContainer>
        )}
      </StyledPrescriptionTransferV2.TransferContainer>

      <StyledToastMessage
        visible={showTransferOutSuccessToast}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={ToastState.SUCCESS}
        onTimeout={() => handleTransferOutSuccessToast(false)}
        timeout={5000}
      >
        Your prescription transfer request has been received. Find status and details below.
      </StyledToastMessage>

      <StyledToastMessage
        visible={!!error}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={ToastState.ERROR}
        onTimeout={dismissError}
        timeout={5000}
      >
        {error}
      </StyledToastMessage>
    </PrescriptionManagementLayout>
  )
}

export default PrescriptionTransferV2
