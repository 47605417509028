import { Spacer } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../../hooks'
import { CancelOrderConfirmationText, StyledActionContainer, StyledHeader } from './styledComponent'

interface Props {
  cancel: () => void
}

const CancelOrderSucess = ({ cancel }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  return (
    <>
      <StyledHeader vpTheme={theme} variant='4xl'>
        Your order has been cancelled
      </StyledHeader>
      <Spacer size='lg' />
      <CancelOrderConfirmationText vpTheme={theme}>
        This confirms that your order has been cancelled successfully, and the status will be reflected in your Order History shortly. To place a new
        order, return to Prescription Manager and add items to your cart.
      </CancelOrderConfirmationText>
      <Spacer size='lg' />
      <CancelOrderConfirmationText vpTheme={theme}>
        Please contact our Support Team, or visit the Help Center if you have any additional questions.
      </CancelOrderConfirmationText>
      <Spacer size='2xl' />
      <StyledActionContainer>
        <ThemedButton data-testid='cancel-order-success-btn' size='lg' onClick={handleCancel} vpTheme={theme}>
          Close
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default CancelOrderSucess
