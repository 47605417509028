import { Radio, Spacer, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import { ReactElement, useState } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import { RoutingOptionContainer, RoutingOptionDetails } from '../../pages/SavingsCardSignUp/screens/RadioRoutingOptions/styledComponents'
import { OptionsContainer } from './styledComponents'

interface Props {
  theme: VpTheme
  handleContinue: (option: YesOrNo) => void
}

export enum YesOrNo {
  YES = 'YES',
  NO = 'NO',
}

const HaveInsuranceForm = ({ theme, handleContinue }: Props): ReactElement => {
  const [selectedOption, setSelectedOption] = useState<YesOrNo>()

  const yesChecked = selectedOption === YesOrNo.YES
  const noChecked = selectedOption === YesOrNo.NO

  return (
    <>
      <ThemedHeader variant='2xl' style={{ marginBottom: '16px' }} vpTheme={theme}>
        Do you have insurance?
      </ThemedHeader>
      <Text style={{ marginBottom: '24px' }}>If you have insurance, add your insurance BEFORE adding savings cards.</Text>
      <OptionsContainer>
        <RoutingOptionContainer
          onClick={() => {
            setSelectedOption(YesOrNo.YES)
          }}
          vpTheme={theme}
          ischecked={yesChecked}
        >
          <Radio
            aria-labelledby={`option-yes`}
            value={selectedOption}
            checked={yesChecked}
            onChange={() => {
              setSelectedOption(YesOrNo.YES)
            }}
          />
          <Spacer size='sm' axis='horizontal' />
          <RoutingOptionDetails>
            <Text id={`option-no`}>Yes, add my insurance</Text>
          </RoutingOptionDetails>
        </RoutingOptionContainer>
        <RoutingOptionContainer
          onClick={() => {
            setSelectedOption(YesOrNo.NO)
          }}
          vpTheme={theme}
          ischecked={noChecked}
        >
          <Radio
            aria-labelledby={`option-no`}
            value={selectedOption}
            checked={noChecked}
            onChange={() => {
              setSelectedOption(YesOrNo.NO)
            }}
          />
          <Spacer size='sm' axis='horizontal' />
          <RoutingOptionDetails>
            <Text id={`option-no`}>No, skip insurance for now</Text>
          </RoutingOptionDetails>
        </RoutingOptionContainer>
        <ThemedButton disabled={!selectedOption} vpTheme={theme} onClick={() => selectedOption && handleContinue(selectedOption)}>
          Continue
        </ThemedButton>
      </OptionsContainer>
    </>
  )
}

export default HaveInsuranceForm
