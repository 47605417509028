import { Card, Text } from '@truepill/react-capsule'
import { MedicationStatus } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedButton } from '../../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../../common/styledComponents/types'
import { media } from '../../../../common/styles/variables'
import { BarProps } from './TransferHeaderCard'

const StyledCard = styled(Card)<ThemedComponent & BarProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: ${({ open }) => {
    if (open) {
      return '0.5rem 0.5rem 0 0'
    }
    return '0.5rem'
  }};
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: ${({ status, vpTheme }) => {
    switch (status) {
      case MedicationStatus.PENDING:
        return vpTheme.colors['functional-info-light']
      case MedicationStatus.SUCCESS:
        return vpTheme.colors['functional-success-light']
      case MedicationStatus.REJECTED:
        return vpTheme.colors['functional-error-light']
    }
  }};

  @media (max-width: ${media.tablet}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.1rem;
`

const SubTitle = styled(Text)`
  @media (max-width: ${media.tablet}) {
    display: none;
  }
`

const ShowMoreButton = styled(ThemedButton)`
  color: ${({ vpTheme }) => vpTheme.colors['primary-700']};
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0;
  font-size: 0.85rem;
  font-weight: 400;
  padding-left: 1.5rem;
  width: 10rem;

  @media (max-width: ${media.tablet}) {
    padding-left: 0.5rem;
  }
`

export const StyledTransferHeaderCard = {
  StyledCard,
  TitleContainer,
  SubTitle,
  ShowMoreButton,
}
