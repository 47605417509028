import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

export const InsuranceIcon = ({ color }: { color: string }): React.ReactElement => (
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M24.1667 16.1666V28.1666M18.1667 22.1666H30.1667'
      stroke={color ?? defaultTheme.colors['primary-700']}
      strokeWidth='3.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24 44C24 44 40 36 40 24V10L24 4L8 10V24C8 36 24 44 24 44Z'
      stroke={color ?? defaultTheme.colors['primary-700']}
      strokeWidth='3.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
