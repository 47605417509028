import { PaymentMethod } from '@stripe/stripe-js'
import { Spacer } from '@truepill/react-capsule'
import React, { useEffect } from 'react'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import type { CreatePaymentData, Payment } from '../../../hooks/useUserPayments'
import LoadingAnimation from '../../LoadingAnimation'
import PaymentForm from '../../Payments/PaymentForm'
import SavedPaymentMethods from '../../Payments/SavedPaymentMethods'
import { useCheckoutContext } from '../CheckoutProvider'
import { LoadingAnimationWrapper, StyledText, SubmitButtonContainer, Wrapper } from './styledComponents'

interface Props {
  isLoading: boolean
  paymentMethods: Payment[]
  addPaymentMethod: (data: CreatePaymentData) => Promise<PaymentMethod | undefined>
  setDefaultPaymentMethod: (paymentMethodId: string) => Promise<boolean>
  deletePaymentMethod: (paymentMethodId: string) => Promise<boolean>
  onConfirm: () => void
}

export const EditMode: React.FC<Props> = ({
  isLoading,
  paymentMethods,
  addPaymentMethod,
  setDefaultPaymentMethod,
  deletePaymentMethod,
  onConfirm,
}) => {
  const { selectedPayment, setSelectedPaymentMethod } = useCheckoutContext()
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  useEffect(() => {
    const defaultPayment = paymentMethods.find((payment) => payment.default)
    if (defaultPayment && !selectedPayment) {
      setSelectedPaymentMethod({ ...defaultPayment, saved: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods.length])

  const handleSelectPaymentMethod = (payment: Payment) => {
    trackButtonClickEvent('checkout_payment_select_payment_method', '', 'User selected existing payment method')
    setSelectedPaymentMethod({ ...payment, saved: true })
  }

  const handleAddPaymentMethod = async (data: CreatePaymentData) => {
    trackButtonClickEvent('checkout_payment_add_payment_method', 'Add New Payment Method', 'User added new payment method')
    const paymentMethod = await addPaymentMethod(data)
    if (paymentMethod?.card) {
      setSelectedPaymentMethod({
        paymentMethodId: paymentMethod.id,
        name: data.cardholderName,
        last4: paymentMethod.card.last4,
        expirationMonth: paymentMethod.card.exp_month,
        expirationYear: paymentMethod.card.exp_year,
        brand: paymentMethod.card.brand,
        fingerprint: paymentMethod.card?.fingerprint ?? '',
        postalCode: data.zip,
        default: data.isDefault,
        saved: data.isSaved,
      })
      onConfirm()
    }
    return paymentMethod
  }

  const handleSetDefaultPaymentMethod = async (payment: Payment) => {
    trackButtonClickEvent('checkout_payment_set_default_payment_method', 'Set as Default', 'User set default payment method')
    const result = await setDefaultPaymentMethod(payment.paymentMethodId)
    setSelectedPaymentMethod(result ? { ...payment, default: true, saved: true } : null)
  }

  const handleDeletePaymentMethod = async (id: string) => {
    trackButtonClickEvent('checkout_payment_delete_payment_method', 'Delete', 'User deleted payment method')
    const result = await deletePaymentMethod(id)
    if (result && selectedPayment?.paymentMethodId === id) {
      setSelectedPaymentMethod(null)
    }
  }

  const hasSavedPaymentMethods = paymentMethods.length > 0
  const isConfirmDisabled = isLoading || !selectedPayment

  return (
    <>
      {hasSavedPaymentMethods ? (
        <>
          <Wrapper>
            {isLoading && (
              // TODO: Design will be adding a new background theme color to use instead
              <LoadingAnimationWrapper background={theme.colors.white}>
                <LoadingAnimation />
              </LoadingAnimationWrapper>
            )}
            <StyledText color={theme.colors['typography-dark']}>Please select or add a new payment method to continue.</StyledText>
            <Spacer />
            <SavedPaymentMethods
              isCheckoutFlow
              paymentMethods={paymentMethods}
              maxCardsDisplayed={3}
              selectedPaymentMethod={selectedPayment}
              onSelectedPaymentMethod={handleSelectPaymentMethod}
              onAddPaymentMethod={handleAddPaymentMethod}
              onSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
              onDeletePaymentMethod={handleDeletePaymentMethod}
            />
          </Wrapper>
          <Spacer size='lg' />
          <SubmitButtonContainer>
            <ThemedButton
              data-testid='payment-form-confirm'
              disabled={isConfirmDisabled}
              onClick={() => {
                trackButtonClickEvent('checkout_payment_section_confirm', 'Confirm', 'User confirmed payment method')
                onConfirm()
              }}
              vpTheme={theme}
            >
              Confirm
            </ThemedButton>
          </SubmitButtonContainer>
        </>
      ) : (
        <PaymentForm onSubmit={handleAddPaymentMethod} askShouldSave forceDefault />
      )}
    </>
  )
}
