import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const WarningIcon = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 5.00024V7.66691M8 10.3335H8.00667M6.86001 1.57347L1.21335 11.0001C1.09693 11.2018 1.03533 11.4303 1.03467 11.6631C1.03402 11.896 1.09434 12.1249 1.20963 12.3272C1.32492 12.5294 1.49116 12.698 1.69182 12.816C1.89247 12.9341 2.12055 12.9976 2.35335 13.0001H13.6467C13.8795 12.9976 14.1076 12.9341 14.3082 12.816C14.5089 12.698 14.6751 12.5294 14.7904 12.3272C14.9057 12.1249 14.966 11.896 14.9654 11.6631C14.9647 11.4303 14.9031 11.2018 14.7867 11.0001L9.14001 1.57347C9.02117 1.37754 8.85383 1.21555 8.65414 1.10313C8.45446 0.990702 8.22917 0.931641 8.00001 0.931641C7.77086 0.931641 7.54557 0.990702 7.34588 1.10313C7.1462 1.21555 6.97886 1.37754 6.86001 1.57347V1.57347Z'
        stroke={vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
