import { sharedConstants } from '@vpharm-platform/shared'
import axios, { AxiosRequestConfig } from 'axios'

const { VPHARM_CUSTOMER_HEADER } = sharedConstants

export interface ContactService {
  contactPatient(contactParams: ContactParams, customerToken: string): Promise<void>
}

export interface ContactParams {
  name: string
  email: string
  subject: string
  phone?: string
  message: string
  topic: string
  patientToken?: string
}

class ContactApiService implements ContactService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`

  async contactPatient(contactParams: ContactParams, customerToken: string): Promise<void> {
    const url = `${this.baseUrl}/contact_us`
    const config: AxiosRequestConfig = {
      headers: {
        [VPHARM_CUSTOMER_HEADER]: customerToken,
      },
    }

    const patientContactBody: ContactParams = {
      name: contactParams.name,
      email: contactParams.email,
      subject: contactParams.subject,
      message: contactParams.message,
      topic: contactParams.topic,
      ...(contactParams.patientToken && { patientToken: contactParams.patientToken }),
      ...(contactParams.phone && { phone: contactParams.phone }),
    }

    try {
      await axios.post(url, patientContactBody, config)
    } catch (err) {
      throw new Error((err as Error).message)
    }
  }
}

export const contactService: ContactService = new ContactApiService()
