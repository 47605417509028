export default class AvailableShippingOptionsResponse {
  id!: number
  controlled_substance!: boolean
  cold_chain!: boolean
  post_office!: boolean
  min_delivery_days!: number
  max_delivery_days!: number
  shipping_price!: number | null
  estimated_time_arrivals!: number
  shipping_name!: string
  shipping_code!: string
  marketing_price!: number | null
}
