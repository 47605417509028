import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { useAuth } from '../../context/auth-context'
import { useCustomerProfile } from '../../hooks'
import { CustomerProfile } from '../../interfaces'
import { adminLogin } from '../../persistRecoil'
import { LoginFormFields } from './types'

export interface UseLogin {
  customerProfile: CustomerProfile
  signIn: (loginFormValues: LoginFormFields) => void
  isAuthenticated: boolean
  loginErrorMsg: string
  handleInfoToastMsgChange: (newToastState: ToastState) => void
  toastState: ToastState
  isAdminLogin: boolean
  redirectTo?: string
}

interface LoginLocationState {
  fromIdentityVerification?: boolean
  fromResetPassword?: boolean
  redirectTo?: string
}

interface InfoToastParams {
  fromIdentityVerfication: boolean
  fromResetPassword: boolean
}

interface ToastState {
  toastMessage: string
  type: 'info' | 'success'
}

const defaultInfoToastMsg = (infoToastParams: InfoToastParams): ToastState => {
  if (infoToastParams.fromIdentityVerfication) {
    return { type: 'info', toastMessage: 'Your account has already been created. Please sign in below to continue.' }
  }

  if (infoToastParams.fromResetPassword) {
    return { type: 'success', toastMessage: "You've successfully reset your password." }
  }

  return DEFAULT_TOAST_STATE
}

export const DEFAULT_TOAST_STATE: ToastState = { toastMessage: '', type: 'info' }

export const useLogin = (): UseLogin => {
  const location = useLocation<LoginLocationState>()
  const { authState, login } = useAuth()
  const [isAdminLogin] = useRecoilState(adminLogin)

  const { customerProfile } = useCustomerProfile()
  const [loginErrorMsg, setLoginErrorMsg] = useState('')
  const [toastState, setToastState] = useState(
    defaultInfoToastMsg({
      fromIdentityVerfication: !!location?.state?.fromIdentityVerification,
      fromResetPassword: !!location?.state?.fromResetPassword,
    }),
  )
  const signIn = async (loginFormValues: LoginFormFields) => {
    setLoginErrorMsg('')
    try {
      await login(loginFormValues.email, loginFormValues.password)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoginErrorMsg(e.message)
      }
    }
  }

  const handleInfoToastMsgChange = (toastState: ToastState) => {
    setToastState(toastState)
  }

  return {
    customerProfile,
    loginErrorMsg,
    signIn,
    isAuthenticated: authState.isAuthenticated,
    redirectTo: location?.state?.redirectTo,
    handleInfoToastMsgChange,
    toastState,
    isAdminLogin,
  }
}
